import apiTrolleyItems from 'api/definitions/trolleyItems';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';

export const ADD_TROLLEY_ITEMS_PENDING = 'waitrose/trolley/ADD_TROLLEY_ITEMS_PENDING';
export const ADD_TROLLEY_ITEMS_FULFILLED = 'waitrose/trolley/ADD_TROLLEY_ITEMS_FULFILLED';
export const ADD_TROLLEY_ITEMS_REJECTED = 'waitrose/trolley/ADD_TROLLEY_ITEMS_REJECTED';

export function pushTrolleyItemsToApi(trolleyItems) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TROLLEY_ITEMS_PENDING });
    return apiTrolleyItems
      .bulkUpdate({ body: trolleyItems })({ dispatch, getState })
      .then(response => {
        const { data: { updateTrolleyItems } = {}, errors } = response;

        if (errors || !updateTrolleyItems || updateTrolleyItems.failures) {
          let error = new Error('Graph call succeeded but returned no data or an unknown error');
          dispatch({
            error: 'Graph call succeeded but returned no data or an unknown error',
            type: ADD_TROLLEY_ITEMS_REJECTED,
          });

          if (errors?.length) {
            [error] = errors;
          }

          if (updateTrolleyItems?.failures?.length) {
            const { failures } = updateTrolleyItems;
            [error] = failures;
          }
          return handleUpdateTrolleyErrors(dispatch, getState)(error);
        }

        return dispatch({
          type: ADD_TROLLEY_ITEMS_FULFILLED,
        });
      })
      .catch(err => {
        dispatch({
          error: 'Update trolley items call to graph failed',
          type: ADD_TROLLEY_ITEMS_REJECTED,
        });
        return handleUpdateTrolleyErrors(dispatch, getState)(err);
      });
  };
}
