import type initialState from './initial-state';

type InitialStateType = typeof initialState;

export const confirmInstantCheckout: WtrReducer<InitialStateType, undefined> = state => {
  return {
    ...state,
    confirming: true,
  };
};

export const startInstantCheckout: WtrReducer<InitialStateType, undefined> = state => {
  return {
    ...state,
    inProgress: true,
  };
};

export const finishedInstantCheckout: WtrReducer<InitialStateType, undefined> = state => {
  return {
    ...state,
    confirming: false,
    inProgress: false,
  };
};
