import cmsHybridRecipesPage from 'api/definitions/cms-hybrid-recipes-page';
import recipe from 'api/definitions/recipes/recipe';
import { DEFAULT_SORT_BY } from 'redux/modules/recipes/constants';

import {
  GET_CMS_HYBRID_RECIPES_PAGE,
  GET_CMS_TAGGED_RECIPES_LOADING,
  GET_CMS_TAGGED_RECIPES_SUCCESS,
  GET_CMS_TAGGED_RECIPES_FAILURE,
} from 'redux/modules/cms-hybrid-recipes-page/actions/types';

import { GET_CMS_PAGE_NOT_FOUND } from 'redux/modules/cms-page/actions/types';

export default (contentUrl: string, customerId: string) => async (dispatch: WtrDispatch) => {
  const notFoundStatuses = [400, 404];

  return dispatch<Promise<void>>({
    apiCall: cmsHybridRecipesPage.put({
      body: {
        contentUrl: `/${contentUrl}`,
        customerId,
        customerSearchRequest: {
          queryParams: {},
        },
      },
      endpoint: contentUrl,
      iHandleStatusCodes: notFoundStatuses,
    }),
    contentUrl,
    types: GET_CMS_HYBRID_RECIPES_PAGE,
  }).catch(error => {
    if (notFoundStatuses.includes(error.status)) {
      return dispatch({ type: GET_CMS_PAGE_NOT_FOUND });
    }
    throw error;
  });
};

export const fetchTaggedRecipes = (
  contentUrl: string,
  tags = [],
  filters = [],
  currentPage: number,
  start = 0,
  size = 48,
  sortBy = DEFAULT_SORT_BY,
  search = '',
) => ({
  apiCall: recipe.post({
    body: {
      filters,
      search: {
        term: search,
        tags,
      },
      preferences: {
        size,
        sortBy,
        start,
      },
    },
    endpoint: 'recipes/summary',
    iHandleStatusCodes: [400, 404],
  }),
  contentUrl,
  currentPage,
  filterParams: {
    sortBy,
  },
  filters,
  req: {
    start,
    size,
    search: {
      term: search,
      tags,
    },
  },
  types: [
    GET_CMS_TAGGED_RECIPES_LOADING,
    GET_CMS_TAGGED_RECIPES_SUCCESS,
    GET_CMS_TAGGED_RECIPES_FAILURE,
  ],
});
