import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCookies } from 'redux/modules/cookies/selectors';
import {
  acceptAll,
  rejectAll,
  viewPreferences,
  updatePreferences,
} from 'redux/modules/cookies/actions';

import InitialModal from 'components/CookieAlert/InitialModal';
import FullModal from 'components/CookieAlert/FullModal';
import { CookiesScripts } from './CookiesScripts';

// Useful Page:
// https://www.cookiebot.com/en/gdpr-cookies/?gclid=EAIaIQobChMIi-6wwNTf5QIVVeDtCh0cxgGjEAAYAiAAEgJG__D_BwE

const CookieAlert = () => {
  const {
    marketing: marketingAllowed,
    analytics: analyticsAllowed,
    functional: functionalAllowed,
    returnUrl,
    showPrivacyControls,
    showInitialModal,
  } = useSelector(getCookies);
  const dispatch = useDispatch();

  const initialModalProps = {
    acceptAll: () => dispatch(acceptAll()),
    rejectAll: () => dispatch(rejectAll()),
    showInitialModal,
    viewPreferences: () => dispatch(viewPreferences()),
  };

  const fullModalProps = {
    cookies: {
      marketing: marketingAllowed === null ? 'false' : `${marketingAllowed}`,
      analytics: analyticsAllowed === null ? 'false' : `${analyticsAllowed}`,
      functional: functionalAllowed === null ? 'false' : `${functionalAllowed}`,
      showPrivacyControls,
    },
    returnUrl,
    updatePreferences: updatePreferences(dispatch),
  };

  return (
    <Fragment>
      <InitialModal {...initialModalProps} />
      <FullModal {...fullModalProps} />
      <CookiesScripts />
    </Fragment>
  );
};

CookieAlert.propTypes = {};

export default CookieAlert;
