import { useState, useCallback } from 'react';

const useMenus = () => {
  const [isMegaMenuOpen, setMegaMenuIsOpen] = useState(false);
  const [isSeasonalMenuOpen, setSeasonalIsOpen] = useState(false);

  const toggleSeasonalMenu = useCallback(
    () => setSeasonalIsOpen(!isSeasonalMenuOpen),
    [isSeasonalMenuOpen],
  );

  const toggleMegaMenu = useCallback(() => setMegaMenuIsOpen(!isMegaMenuOpen), [isMegaMenuOpen]);

  return {
    isMegaMenuOpen,
    toggleMegaMenu,
    isSeasonalMenuOpen,
    toggleSeasonalMenu,
  };
};

export default useMenus;
