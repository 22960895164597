import { brokenTrolleyCustomerIdMatches } from 'redux/modules/trolley/selectors/get-broken-trolley';
import { isSignedOnAsCustomer } from 'redux/modules/sessions/selectors';
import { getTotalNumberOfItems } from 'redux/modules/trolley/selectors/number-of-items';

export const hasBrokenTrolleyToRestore = state =>
  !!(
    isSignedOnAsCustomer(state) &&
    getTotalNumberOfItems(state) === 0 &&
    brokenTrolleyCustomerIdMatches(state)
  );
