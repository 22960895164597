import React, { memo, PropsWithChildren } from 'react';

import styles from './SkipLink.scss';

type SkipLinkProps = {
  destination: string;
};

const SkipLink = memo(({ destination, children = null }: PropsWithChildren<SkipLinkProps>) => (
  <a className={styles.skipLink} data-testid="skipLink" href={destination}>
    {children}
  </a>
));

export default SkipLink;

SkipLink.displayName = 'SkipLink';
