import root from 'window-or-global';

/**
 * Returns whether the current environment has access to the internet.
 *
 * Server-side this is always set to `true`.
 *
 * @returns {boolean} the current online status.
 */
export const isOnline = () => {
  if (__SERVER__) {
    return true;
  }

  return root.navigator.onLine;
};
