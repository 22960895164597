const namespace = 'waitrose';

const request = 'request';
const success = 'success';
const failure = 'failure';

type MethodTypes =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'
  | 'options'
  | (string & Record<never, never>);

export const apiPromiseTypes = <Api extends string, Method extends MethodTypes>(
  api: Api,
  method: Method,
) => {
  const typeBuilder = <Step extends string>(step: Step) =>
    `${namespace}/api/${api}/${method}/${step}` as const;

  return {
    request: typeBuilder(request),
    success: typeBuilder(success),
    failure: typeBuilder(failure),
    get triplet() {
      return [this.request, this.success, this.failure] as const;
    },
  };
};
