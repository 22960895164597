import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { getPromotionDescription } from 'redux/modules/entities/selectors/promotions';

import isAmendOrder from 'redux/modules/trolley/selectors/amending-order';

import { isDefined } from 'utils/validation';

const isWeightedProductQuantityDelta = (quantityAmount, reservedQuantity) => {
  if (quantityAmount < reservedQuantity) return -1;

  if (quantityAmount > reservedQuantity) return 1;

  return 0;
};

const yesOrNo = expression => (expression ? 'YES' : 'NO');

const trolleyItemToAnalyticsProduct = state => trolleyItem => {
  const {
    lineNumber,
    quantity: { amount: quantityAmount, uom: retailUomType } = {},
    reservedQuantity,
  } = trolleyItem || {};

  const { price: { amount: retailUomPrice } = {} } = trolleyItem?.retailPrice || {};

  const isWeighted = retailUomType !== 'C62';
  const {
    brandName,
    categories = [],
    name,
    productType,
    promotions = [],
    currentSaleUnitPrice: { price: { amount: pricePerUnit } = {} } = {},
  } = getProductByLineNumber(state, lineNumber) ?? {};

  const promotionDescription =
    promotions.map(promotionId => getPromotionDescription(state, promotionId))?.[0] || undefined;
  const customerFavourite = getIsFavourite(state, lineNumber);
  const favourite = isDefined(customerFavourite) ? yesOrNo(customerFavourite) : undefined;

  let quantityDelta = quantityAmount - reservedQuantity;
  const delta = isWeighted
    ? isWeightedProductQuantityDelta(quantityAmount, reservedQuantity)
    : quantityDelta;

  const isAmend = isAmendOrder(state);

  if (retailUomType === 'GRM') {
    quantityDelta /= 1000;
  }

  const absolutePrice = Math.abs(retailUomPrice * quantityDelta);
  // eslint-disable-next-line no-restricted-globals
  const priceIfWeighted = isNaN(absolutePrice) ? undefined : absolutePrice;

  return {
    id: lineNumber,
    name,
    category: categories?.map(category => category.name),
    variant: productType,
    brand: brandName,
    favourite,
    quantity: isWeighted ? 1 : quantityAmount,
    price: isWeighted ? priceIfWeighted : pricePerUnit,
    offer: promotionDescription,
    amendment_quantity_delta: isAmend ? delta : undefined,
  };
};

export default trolleyItemToAnalyticsProduct;
