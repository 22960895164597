const reducer = (state, action) => {
  const {
    giftVouchers = state?.giftVouchers ?? [],
    estimatedTotals = state?.order?.totals?.estimated ?? {},
  } = action;

  const newGiftVouchers = giftVouchers.map(({ status, serialNumber, value, amount }) => ({
    status,
    serialNumber,
    amount: amount ?? value.amount,
  }));

  return {
    ...state,
    giftVoucherToRemove: null,
    giftVouchers: newGiftVouchers,
    order: {
      ...state.order,
      totals: {
        ...state.order.totals,
        estimated: estimatedTotals,
      },
    },
    waiting: false,
  };
};

export default reducer;
