import { createSelector } from 'reselect';
import { getTrolleyConflicts } from 'redux/modules/conflicts/selectors';
import { isGroceriesOrSimilar } from 'constants/productTypes';
import { getProducts } from 'redux/modules/entities/selectors/products';
import { isItemEditable } from 'redux/modules/conflicts/utils';
import { getTrolleyItems } from './get-items';

export const getTrolley = state => state?.trolley;

const EMPTY_ARRAY = [];

export const getOptimisticTrolleyItems = createSelector(
  getTrolley,
  ({ optimisticTrolleyItems } = { optimisticTrolleyItems: EMPTY_ARRAY }) => optimisticTrolleyItems,
);

export const getOrderedOptimisticTrolleyItems = createSelector(getOptimisticTrolleyItems, items =>
  items.slice().reverse(),
);

const blendTrolleyItems = (trolleyItems, optimisticTrolleyItems) =>
  optimisticTrolleyItems.concat(
    trolleyItems.filter(
      trolleyItem => !optimisticTrolleyItems.find(oti => oti.lineNumber === trolleyItem.lineNumber),
    ),
  );

export const getBlendedOptimisticTrolleyItems = createSelector(
  [getTrolleyItems, getOptimisticTrolleyItems],
  blendTrolleyItems,
);

export const getOrderedBlendedOptimisticTrolleyItems = createSelector(
  [getBlendedOptimisticTrolleyItems],
  items =>
    items
      .filter(item => item.quantity.amount > 0)
      .slice()
      .sort((itemA, itemB) => itemB.trolleyItemId - itemA.trolleyItemId),
);

export const getAddedOrderedBlendedOptimisticTrolleyItems = createSelector(
  [getBlendedOptimisticTrolleyItems],
  items =>
    items
      .filter(item => item.quantity.amount > 0)
      .slice()
      .sort((itemA, itemB) => Math.abs(itemA.trolleyItemId) - Math.abs(itemB.trolleyItemId)),
);

export const getTrolleyItemsAllowedSub = createSelector(
  [getProducts, getBlendedOptimisticTrolleyItems, getTrolleyConflicts],
  (products, trolleyItems, conflicts) =>
    trolleyItems.filter(({ lineNumber, trolleyItemId }) => {
      const { substitutionsProhibited = false, productType = null } = products[lineNumber] ?? {};
      return (
        !substitutionsProhibited &&
        isGroceriesOrSimilar(productType) &&
        isItemEditable(conflicts, trolleyItemId)
      );
    }),
);
