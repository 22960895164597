import { useRef, useCallback } from 'react';
import { dataLayer } from 'analytics/data-layer';
import { pushMenuClickWithLevels } from 'utils/gtm';
import { KEY_RIGHT } from 'constants/keys';
import { useNavigation } from 'components/MegaMenu/NavigationProvider';

export const useMenuItemNavigation = ({
  display = true,
  id,
  isLink = true,
  hasDescendants,
  level,
  menus,
  name,
  onClick = () => {},
  toggleMobileMenu = () => {},
  newWindow,
}) => {
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useNavigation(ref, { id, skip: !display });

  const click = useCallback(
    event => {
      onClick();

      if (newWindow) {
        return;
      }

      if (isLink) {
        if (name !== ('Book slot' || 'Find a store' || 'Customer Service' || 'Home')) {
          pushMenuClickWithLevels({ level, name, menus });
        }

        toggleMobileMenu();
      } else {
        if (name === 'Groceries' || name === 'Christmas') {
          dataLayer.push({
            event: 'click_megamenu',
            mega_menu: {
              button_clicked: name,
            },
          });
        }

        event.preventDefault();
      }
    },
    [toggleMobileMenu, isLink, name, menus, level, newWindow, onClick],
  );

  const handleClickItem = useCallback(
    event => {
      handleClick(event);
      click(event);
    },
    [click, handleClick],
  );

  const handleKeyDownItem = useCallback(
    event => {
      if (event.keyCode === KEY_RIGHT && !hasDescendants) return;
      handleKeyDown(event);
    },
    [handleKeyDown, hasDescendants],
  );

  return {
    handleClickItem,
    handleKeyDownItem,
    ref,
    tabIndex,
  };
};
