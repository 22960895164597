export const getTrolleyItemFromGroups = groups => {
  const leafObjects = [];

  const traverse = node => {
    if (node === null || typeof node !== 'object') {
      // Base case: node is a leaf element
      return;
    }

    const values = Object.values(node);
    const allValuesAreLeafs = values.every(value => {
      return value === null || typeof value !== 'object';
    });

    if (allValuesAreLeafs) {
      // Base case: node is a leaf object
      leafObjects.push(node);
      return;
    }

    // Recursive case: node is an object
    values.forEach(value => {
      traverse(value);
    });
  };

  traverse(groups);
  return leafObjects;
};
