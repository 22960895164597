import get from 'lodash/get';
import initial from 'lodash/initial';
import { deepFreeze } from 'utils/deepFreeze';

import {
  GET_CMS_PAGE_LOADING,
  GET_CMS_PAGE_SUCCESS,
  GET_CMS_PAGE_FAILURE,
  GET_CMS_PAGE_NOT_FOUND,
} from 'redux/modules/cms-page/actions/types';
import { LOCATION_CHANGE } from 'redux-first-history';

export const initialState = deepFreeze({
  error: false,
  loading: false,
  pageNotFound: false,
  breadcrumbs: [],
  showPageTitle: true,
  metaData: {},
  pageTitle: {},
});

/*

  WARNING
  This file is not independent of the src/redux/modules/content redux module as the ContentLocation
  is a connected component that uses that section of the redux modules to pull the content locations. Care whilst adding
  to this file is advised to make sure that responsibilities are not duplicated with the other almost identical
  redux modules.

*/

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CMS_PAGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CMS_PAGE_SUCCESS: {
      // if this is authorable breadcrumb - drop last item
      const isAuthorableBreadcrumb = get(action, 'result.authorableBreadcrumb', false);
      const notIndexed = get(action, 'result.metaData.turnOffIndexing', false);

      // handle AEM paths
      const breadcrumbs = get(action, 'result.breadcrumbs', []).map(({ path, ...rest }) => {
        const newPath =
          path && path.includes('/content/waitrosegroceriescms/en')
            ? path.split('/content/waitrosegroceriescms/en/')[1]
            : path;

        return {
          ...rest,
          path: `/ecom/${newPath}`,
        };
      });

      return {
        ...state,
        breadcrumbs: isAuthorableBreadcrumb ? initial(breadcrumbs) : breadcrumbs,
        contentUrl: action?.contentUrl,
        error: false,
        loading: false,
        metaData: { ...get(action, 'result.metaData', null), notIndexed },
        pageTitle: get(action, 'result.pageTitle', {}),
        showPageTitle: get(action, 'result.showPageTitle', true),
      };
    }
    case GET_CMS_PAGE_NOT_FOUND: {
      return {
        ...state,
        pageNotFound: true,
      };
    }
    case GET_CMS_PAGE_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case LOCATION_CHANGE: {
      return action.payload.initialPageLoad
        ? state
        : {
            ...state,
            pageNotFound: false,
            error: false,
          };
    }
    default:
      return state;
  }
};
