import {
  getCommonModalConflictState,
  getCommonModalMessageText,
} from 'redux/modules/common-modal/selectors';

const CAPPING_MESSAGE = 'you can only order';

export default state => {
  const conflict = getCommonModalConflictState(state);

  if (conflict) {
    const messageText = getCommonModalMessageText(state);

    if (messageText.some(messageLine => messageLine.toLowerCase().includes(CAPPING_MESSAGE))) {
      return {
        event: 'capping_lightbox_displayed',
        eventAction: 'Basket Error',
        eventCategory: 'Error Tracking',
        eventLabel: 'Product Cap reached',
      };
    }
  }

  return null;
};
