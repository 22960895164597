import orderGiftVouchers from 'api/definitions/orderGiftVouchers';
import get from 'lodash/get';
import handleErrors from 'redux/errors/handle-errors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { handleTimeoutError } from './errors';
import handleGiftVoucherError from './errors/handle-gift-voucher-error';
import { ADD_GIFT_VOUCHER } from './types';

const handleResponse = dispatch => result => {
  const failures = get(result, 'data.setGiftVouchers.failures', null);

  if (failures) {
    const failureType = get(failures, '[0].type', null);

    const errorResponse = {
      status: failures.statusCode,
      response: {
        body: {
          type: 'ADD_GIFT_VOUCHER_ERROR',
          message: failures[0]?.type,
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftVoucherError]));
    return { error: failureType };
  }

  dispatch(handleErrors(result, [handleTimeoutError]));

  return { success: true };
};

const addGiftVoucher =
  ({ securityCode, serialNumber }) =>
  (dispatch, getState) => {
    const currentGiftVouchers = getState().order.giftVouchers.map(item => ({
      serialNumber: item.serialNumber,
    }));

    const customerOrderId = getCustomerOrderId(getState());
    const allGiftVouchers = [...currentGiftVouchers, { serialNumber, scratchCode: securityCode }];

    const responseHandler = handleResponse(dispatch);

    return dispatch({
      apiCall: orderGiftVouchers.post({
        args: {
          customerOrderId,
          GiftVoucherInput: allGiftVouchers,
        },
      }),
      types: ADD_GIFT_VOUCHER.triplet,
    }).then(responseHandler, responseHandler);
  };

export default addGiftVoucher;
