import orderPartnerDiscount from 'api/definitions/orderPartnerDiscount';

import { showSnackbar } from 'redux/modules/common-snackbar/actions';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

import { partnerDiscountRemovedAtCheckout } from 'constants/snackbars';

import { REMOVE_PARTNER_DISCOUNT } from './types';
import { handleTimeoutError } from './errors';

const removePartnerDiscount =
  ({ displaySnackbar = false }) =>
  async (dispatch, getState) => {
    try {
      const customerOrderId = getCustomerOrderId(getState());

      await dispatch({
        apiCall: orderPartnerDiscount.delete({
          customerOrderId,
        }),
        types: REMOVE_PARTNER_DISCOUNT.triplet,
      });

      if (displaySnackbar) {
        dispatch(showSnackbar(partnerDiscountRemovedAtCheckout));
      }
    } catch (error) {
      if (!dispatch(handleTimeoutError(error))) {
        throw error;
      }
    }
  };

export default removePartnerDiscount;
