import { createSelector } from 'reselect';

export const getExperiments = ({ experiments }) => experiments;
export const getDecisionId = (state, id) => id;

export const getHasDecisions = createSelector(
  getExperiments,
  ({ hasDecisions = false }) => hasDecisions,
);

// WARNING!!! Do not use this selector directly. Use the useExperiments() custom hook instead
// If you must use this selector, make sure you also take care of tracking with trackExperimentView()
export const getAllDecisions = createSelector([getExperiments], ({ decisions }) => decisions || {});
export const getAllPayloads = createSelector([getExperiments], ({ payloads }) => payloads || null);

export const getAllImpressions = createSelector(
  [getExperiments],
  ({ impressions }) => impressions || null,
);

export const getViewedExperiments = createSelector(
  [getExperiments],
  ({ viewedOnPage }) => viewedOnPage || [],
);

export const getExperimentValueById = createSelector(
  [getAllDecisions, (state, experimentId) => experimentId],
  (experiments, experimentId) => experiments[experimentId],
);

export const isExperimentLive = (state, id) => {
  const experiments = getAllDecisions(state) || {};
  return Object.keys(experiments).includes(id);
};

export const getPayloadById = createSelector(
  [getAllPayloads, (state, id) => id],
  (payloads, id) => payloads?.podSlidedownAdvert && payloads?.podSlidedownAdvert[id],
);

export const getIsMonetateBypassed = createSelector(
  [getExperiments],
  ({ bypassMonetate }) => bypassMonetate,
);

export const getMonetatePreview = createSelector([getExperiments], ({ preview }) => preview);

export const getMonetateIpAddress = createSelector([getExperiments], ({ ipAddress }) => ipAddress);
