import { getCategoryNameById } from 'redux/modules/taxonomy/selectors';

const favouritesCategoryFilterImpressions = (
  state: WtrState,
  { payload: { categoryId } }: { payload: { categoryId: string } },
) => {
  return {
    event: 'favourites_filter',
    // @ts-expect-error selector has not been typed
    filter_selection: getCategoryNameById(state, categoryId),
  };
};

export default favouritesCategoryFilterImpressions;
