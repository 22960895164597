import React from 'react';
import { Accordion } from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import type { RecipeSummaryItem } from '../../../../api/definitions/recipes';
import styles from './index.scss';
import RecipeAccordionItem from './RecipeAccordionItem';
import { SetSelectedRecipesIds, RecipeIdList } from './index';

export interface RecipeAccordionProps {
  recipes: (RecipeSummaryItem | null)[];
  selectedRecipesIds: RecipeIdList;
  setSelectedRecipesIds: SetSelectedRecipesIds;
}

const RecipesAccordion = ({
  recipes,
  selectedRecipesIds,
  setSelectedRecipesIds,
}: RecipeAccordionProps) => {
  if (!recipes.length) return null;

  return (
    <Accordion
      scrollToItemWhenOpened
      theme="chevronRight"
      className={styles.recipesAccordion}
      data-testid="recipes-accordion"
    >
      {recipes.filter(Boolean).map(recipe => (
        <RecipeAccordionItem
          key={recipe.id}
          recipe={recipe}
          selectedRecipesIds={selectedRecipesIds}
          setSelectedRecipesIds={setSelectedRecipesIds}
          data-testid={`recipe-accordion-item-${recipe.id}`}
        />
      ))}
    </Accordion>
  );
};

export default RecipesAccordion;
