import promoImpression from 'analytics/promo-impressions';
import { CITRUS_AD } from 'constants/analytics';
import { usePodObserver } from './use-pod-observer';

type UsePromoImpression = {
  componentId?: string;
  analytics?: {
    promoName?: string;
  };
  position?: string;
  products?: {
    id: string;
  }[];
};

export function usePromoImpression({
  componentId,
  analytics,
  position,
  products,
}: UsePromoImpression) {
  return usePodObserver(() => {
    if (analytics?.promoName === CITRUS_AD) {
      promoImpression({
        id: componentId,
        name: CITRUS_AD,
        position,
        products,
      });
    }
  });
}
