import queryString from 'query-string';
import locator from 'utils/locator';
import urls from 'constants/urls';

export const checkResetPasswordExpiryDate = () => {
  const queryKeys = queryString.parse(locator.search);
  const forgotPasswordRedirectLink = urls.forgotPasswordExpired;

  if (locator.pathname === urls.resetPassword) {
    if (queryKeys && queryKeys.exp) {
      const now = new Date();
      const expiryDate = new Date(queryKeys.exp);

      if (expiryDate.getTime() < now.getTime()) {
        locator.href = forgotPasswordRedirectLink;
      }
    } else {
      locator.href = forgotPasswordRedirectLink;
    }
  }
};
