import customerOrderOrchestration from 'api/definitions/customer-order-orchestration';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { CLEAR_CUSTOMER_ORDER_STATS, FETCH_CUSTOMER_ORDER_STATS } from './types';

export const fetchCustomerOrderStats = () => async (dispatch, getState) => {
  if (isUserLoggedIn(getState())) {
    await dispatch({
      types: FETCH_CUSTOMER_ORDER_STATS,
      apiCall: customerOrderOrchestration.getStats({
        iHandleStatusCodes: [401, 403, 404],
      }),
    });
  } else {
    await dispatch({ type: CLEAR_CUSTOMER_ORDER_STATS });
  }
};
