import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const ResolutionMessage = ({ text }) => <span className={styles.resolution}>{text}</span>;

ResolutionMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ResolutionMessage;
