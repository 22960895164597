import { FORMAT, dayjs } from 'utils/date';

export const capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const capitaliseWords = (string, { ignore = [] } = {}) => {
  if (!string) {
    return '';
  }

  const isIgnored =
    typeof ignore === 'string' ? word => word === ignore : word => ignore.includes(word);

  return string
    .split(' ')
    .map(word => (isIgnored(word) ? word : capitaliseFirstLetter(word)))
    .join(' ');
};

export const formatGroceriesMenuNavigation = menuArray => {
  const separator = '|';

  if (!menuArray || menuArray.length === 0) return 'Shop';

  return menuArray.map((item, idx) => (idx === 0 ? `${item}` : `G${idx}:${item}`)).join(separator);
};

export const pluralize = (quantity = 1, singular, includeQuantity = true) => {
  let result;

  if (quantity === 1) {
    result = `${singular}`;
  } else {
    switch (singular) {
      case 'person':
        result = 'people';
        break;
      default:
        result = `${singular}s`;
        break;
    }
  }

  if (includeQuantity) {
    result = `${quantity} ${result}`;
  }

  return result;
};

export const removeDiacriticsFromLetters = string => {
  if (!string) return '';

  const diacritics = 'ÀàÁáÄäÂâÈèÉéËëÊêÌìÍíÏïÎîÒòÓóÖöÔôÙùÚúÜüÛûÑñÇç·/_,:;';
  const nonDiacritics = 'AaAaAaAaEeEeEeEeIiIiIiIiOoOoOoOoUuUuUuUuNnCc------';
  let newString = string;

  for (let idx = 0, len = diacritics.length; idx < len; idx += 1) {
    newString = newString.replace(
      new RegExp(diacritics.charAt(idx), 'g'),
      nonDiacritics.charAt(idx),
    );
  }

  return newString;
};

export const sanitizeString = (string, keepDiacritics) => {
  const html = /(<([^>]+)>)/gi;
  const specialCharacters = /[^\w\s&'+-]|_/gi;
  const specialCharactersNoDiacritics =
    /[^\w\s\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u02af\u1d00-\u1d25\u1d62-\u1d65\u1d6b-\u1d77\u1d79-\u1d9a\u1e00-\u1eff\u2090-\u2094\u2184-\u2184\u2488-\u2490\u271d-\u271d\u2c60-\u2c7c\u2c7e-\u2c7f\ua722-\ua76f\ua771-\ua787\ua78b-\ua78c\ua7fb-\ua7ff\ufb00-\ufb06&'+-]|_/gi;
  const iphoneSpace = '\u2006';
  const sanitizedString = (string || '').replace(html, '').replace(iphoneSpace, ' ');
  return keepDiacritics
    ? sanitizedString.replace(specialCharactersNoDiacritics, '')
    : sanitizedString.replace(specialCharacters, '');
};

export const categoryNameToUrl = name =>
  (name || '')
    .replace(/\s+/g, '_')
    .replace(/&/g, 'and')
    .replace(/[^\w-]/g, '');

export function formatDate(value) {
  if (!value) return null;
  return dayjs.utc(value, FORMAT.DAY_MONTH_2YEAR).format();
}

export const delimitWordsWithSpaces = title => title.replace(/-/g, ' ').replace(/_/g, ' ');
