import { getInterstialsPageNumber } from 'redux/modules/interstitials/selectors';
import { getProductGridItems } from 'redux/modules/product-grid/selectors';

import productGridImpressions from 'redux/middleware/analytics/actions-to-track/products-and-components/product-grid-impressions';

export default productGridImpressions({
  fetch: getProductGridItems,
  list: 'Interstitials',
  taxonomy: state => `I${getInterstialsPageNumber(state)}`,
});
