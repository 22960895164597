import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import headConfig from 'constants/head-config';
import { homepageTitle } from 'constants/page-titles';
import { getShortPageTitle } from 'redux/middleware/analytics/actions-to-track/page/get-page-title';

export const Head = () => {
  const pageTitle = useSelector(getShortPageTitle);

  return (
    <Helmet
      {...headConfig.meta.head}
      title={pageTitle}
      titleTemplate={pageTitle === homepageTitle ? '' : headConfig.meta.head.titleTemplate}
    />
  );
};
