import hash from 'object-hash';
import { cleanCache } from './clean-cache';
import {
  CacheEntryForCallback,
  CacheEntryForHash,
  Callback,
  PendingCacheEntryForHash,
  SetCacheEntryForHash,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const INTERVAL_FROM_ENV = parseInt(process.env.ASYNC_CACHE_CLEANUP_INTERVAL!, 10);
const CLEANUP_INTERVAL =
  !Number.isNaN(INTERVAL_FROM_ENV) && INTERVAL_FROM_ENV > 0 ? INTERVAL_FROM_ENV : 60000;

const cache = new Map<Callback, CacheEntryForCallback>();

// Deletes dangling expired cache entries every CLEANUP_INTERVAL milliseconds
const cacheCleanupTimeout = setInterval(() => cleanCache(cache), CLEANUP_INTERVAL);

export const shutdown = () => {
  clearInterval(cacheCleanupTimeout);
};

const isSetCacheEntry = (
  cacheEntryForHash?: CacheEntryForHash,
): cacheEntryForHash is SetCacheEntryForHash => typeof cacheEntryForHash?.expires === 'number';

const isPendingCacheEntry = (
  cacheEntryForHash?: CacheEntryForHash,
): cacheEntryForHash is PendingCacheEntryForHash =>
  !!cacheEntryForHash && 'pendingValue' in cacheEntryForHash;

const isFreshCacheEntry = (now: number, cacheEntryForHash?: CacheEntryForHash) =>
  isSetCacheEntry(cacheEntryForHash) && cacheEntryForHash.expires > now;

const updateCacheEntry = async <Args extends unknown[] = unknown[]>({
  args,
  cacheEntryForCallback,
  cacheEntryForHash,
  cacheHash,
  callback,
  maxAge,
  now,
  onCallThrough,
  onPendingCallThroughHit,
}: {
  args: Args;
  cacheEntryForCallback?: CacheEntryForCallback;
  cacheEntryForHash?: CacheEntryForHash;
  cacheHash: string;
  callback: Callback<Args>;
  maxAge: number;
  now: number;
  onCallThrough?: () => void;
  onPendingCallThroughHit?: () => void;
}) => {
  if (isPendingCacheEntry(cacheEntryForHash)) {
    const value = await cacheEntryForHash.pendingValue;

    onPendingCallThroughHit?.();

    return value;
  }

  const pendingValue = callback(...args);

  cache.set(callback, {
    ...cacheEntryForCallback,
    [cacheHash]: {
      ...cacheEntryForHash,
      pendingValue,
    },
  });

  let value;

  try {
    value = await pendingValue;
  } catch (error) {
    if (cacheEntryForCallback) {
      cache.set(callback, cacheEntryForCallback);
    } else {
      cache.delete(callback);
    }

    throw error;
  }

  const expires = now + maxAge;
  const refreshedCacheEntryForCallback = cache.get(callback);

  cache.set(callback, {
    ...refreshedCacheEntryForCallback,
    [cacheHash]: {
      expires,
      value,
    },
  });

  onCallThrough?.();

  return value;
};

const staleWhileRevalidateCacheEntry = async <Args extends unknown[] = unknown[]>({
  args,
  cacheEntryForCallback,
  cacheEntryForHash,
  cacheHash,
  callback,
  onCallThrough,
  onStaleCacheHit,
  maxAge,
  now,
}: {
  args: Args;
  cacheEntryForCallback: CacheEntryForCallback;
  cacheEntryForHash: CacheEntryForHash;
  cacheHash: string;
  callback: Callback<Args>;
  onCallThrough?: () => void;
  onStaleCacheHit?: () => void;
  maxAge: number;
  now: number;
}) => {
  onStaleCacheHit?.();

  if (!isPendingCacheEntry(cacheEntryForHash)) {
    updateCacheEntry({
      args,
      cacheEntryForCallback,
      cacheEntryForHash,
      cacheHash,
      callback,
      maxAge,
      now,
      onCallThrough,
    });
  }

  return cacheEntryForHash.value;
};

/**
 * Wrap your expensive functions in asyncCache() to use a short-term in-memory cache. Dangling cache entries are
 * cleaned every one minute. To change that, set the ASYNC_CACHE_CLEANUP_INTERVAL env variable to the desired amount of milliseconds.
 *
 * <pre><code>
 * function myExpensiveFunc(url) {
 *  return fetch(url); // Do expensive server call here
 * }
 *
 * const myExpensiveData = await asyncCache(myExpensiveFunc, ['http://some.url.somewhere/']);
 * </code></pre>
 *
 * @param {(...args: any) => any} callback - The function of which's return value we're caching
 * @param {any[]} args - An array of the arguments to apply to the given callback function
 * @param {Object} options - Cache options object
 * @param {number=} options.maxAge - Cache TTL value (time an object is stored in cache before it's discarded)
 * @param {() => void=} options.onCacheHit - Callback function to execute when a value is read from the cache
 * @param {() => void=} options.onStaleCacheHit - Callback function to execute when a stale value is read from the cache
 * @param {() => void=} options.onPendingCallThroughHit - Callback function to execute when a pending value is read from the cache
 * @param {() => void=} options.onCallThrough - Callback function to execute when a value is written into the cache
 * @param {any[]=} options.dependencies - If dependencies change, it means you want to call through, and not use the cached value (similarly to react hooks). If no dependencies are specified, the arguments will be used for the same.
 * @param {boolean=} options.staleWhileRevalidate - If a cache entry has expired, continue to use the expired "stale" value until a new value is available.
 */
const asyncCache = async <Args extends unknown[] = unknown[]>(
  callback: Callback<Args>,
  args: Args,
  {
    maxAge = 10000,
    onCacheHit,
    onStaleCacheHit,
    onPendingCallThroughHit,
    onCallThrough,
    dependencies,
    staleWhileRevalidate = false,
  }: {
    maxAge?: number;
    onCacheHit?: () => void;
    onStaleCacheHit?: () => void;
    onPendingCallThroughHit?: () => void;
    onCallThrough?: () => void;
    dependencies?: unknown[];
    staleWhileRevalidate?: boolean;
  } = {},
) => {
  const cacheHash = hash(dependencies || args);
  const now = new Date().getTime();
  const cacheEntryForCallback = cache.get(callback);
  const cacheEntryForHash = cacheEntryForCallback?.[cacheHash];

  if (isFreshCacheEntry(now, cacheEntryForHash)) {
    onCacheHit?.();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return cacheEntryForHash!.value;
  }

  if (staleWhileRevalidate && isSetCacheEntry(cacheEntryForHash)) {
    return staleWhileRevalidateCacheEntry({
      args,
      cacheEntryForCallback: cacheEntryForCallback as CacheEntryForCallback,
      cacheEntryForHash,
      cacheHash,
      callback,
      onCallThrough,
      onStaleCacheHit,
      maxAge,
      now,
    });
  }

  return updateCacheEntry({
    args,
    cacheEntryForCallback,
    cacheEntryForHash,
    cacheHash,
    callback,
    maxAge,
    now,
    onCallThrough,
    onPendingCallThroughHit,
  });
};

export default asyncCache;
