import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import Modal from 'react-modal';

import MyWaitrosePopOver from 'components/Product/MyWaitrosePopover';
import styles from 'components/PopOver/PopOver.scss';

const PopOver = ({ promotion }) => {
  // My Waitrose popover on product pod. Extends react-popover.
  // You can use either a modal or popover from this component and add custom content
  // Options:
  //    Create PopOver by using the <PopOver /> element
  //    type="myWaitrose" - sets a type in the props and is used to determine the popover/modal you want to render
  const ref = useRef();
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const togglePopover = () => {
    setPopOverOpen(!popOverOpen);
  };

  const toggleMdoal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className={styles.popOver}>
      <div className={`visible-sm-inline visible-md-inline visible-lg-inline visible-xl-inline`}>
        <Popover
          body={<MyWaitrosePopOver closeButton={togglePopover} promotion={promotion} />}
          className={styles.popoverContentright}
          isOpen={popOverOpen}
          onOuterAction={togglePopover}
          preferPlace={'row'}
          ref={ref}
          tipSize={10}
        >
          <div
            className={styles.myWpopOverBtn}
            onClick={togglePopover}
            data-testid="btn-mywaitrose-popover-desktop"
          />
        </Popover>
      </div>
      <div className={`visible-xs-inline`}>
        <div
          className={styles.myWpopOverBtn}
          onClick={toggleMdoal}
          data-testid="btn-mywaitrose-popover-mobile"
        />
        <Modal
          className={styles.modal}
          isOpen={modalOpen}
          onRequestClose={toggleMdoal}
          overlayClassName={styles.background}
          shouldCloseOnOverlayClick
        >
          <MyWaitrosePopOver closeButton={toggleMdoal} promotion={promotion} />
        </Modal>
      </div>
    </div>
  );
};

PopOver.propTypes = {
  promotion: PropTypes.shape({}).isRequired,
};

export default PopOver;
