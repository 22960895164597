import urls from 'constants/urls';
import history from 'utils/history';
import locator from 'utils/locator';
import { isInternal } from 'utils/is-internal';
import { matchPath } from 'react-router-dom';

const pathsRequiringPageReload = [
  urls.checkout,
  urls.loyaltyExperiences,
  urls.rewardsJohnLewis,
  urls.rewards,
  urls.myWaitroseMigrationVouchers,
  urls.resolveOrderPayment,
];

export const pathRequiresReload = (pathname: string) => {
  return pathsRequiringPageReload.some(path => matchPath(path, pathname));
};

export const goToInternalOrExternalLocation = (url: string) => {
  if (__SERVER__) {
    locator.href = url;
    return;
  }

  if (isInternal(url) && !pathRequiresReload(url)) {
    history.push(url);
  } else {
    locator.href = url;
  }
};
