interface FilterTag<GroupName> {
  count: number;
  group: GroupName;
  id: string;
  text: string;
  value: string;
}

interface Filter<GroupName> {
  applied: boolean;
  filterTag: FilterTag<GroupName>;
}

interface FilterGroup<GroupName extends string = string> {
  filters: Filter<GroupName>[];
  group: GroupName;
}

interface AppliedFilter {
  applied: boolean;
  id: string;
  group: string;
}

export const setFilterAppliedStatus = (
  filterGroups: FilterGroup[],
  { applied, id, group }: AppliedFilter,
) =>
  filterGroups.map(filterGroup => {
    if (filterGroup.group !== group) {
      return filterGroup;
    }

    return {
      ...filterGroup,
      filters: filterGroup.filters.map(filter => {
        if (filter.filterTag.id !== id) {
          return filter;
        }

        return {
          ...filter,
          applied,
        };
      }),
    };
  });
