import {
  deleteAdminAddress,
  updateAdminAddressee,
  saveAdminAddress,
} from 'redux/modules/address/actions';
import { cleanAddress } from 'redux/modules/address/actions/on-submit-edit';
import { validateUpdateAddress as validate } from 'components/Address/util';
import isPlainObject from 'lodash/isPlainObject';

const updateAdminAddressAction = async (dispatch, address) => {
  const updatedAddress = await dispatch(saveAdminAddress(address));
  await dispatch(deleteAdminAddress(address));
  return updatedAddress;
};

const updateAdminAddresseeAction = (dispatch, address) => dispatch(updateAdminAddressee(address));

export { updateAdminAddressAction };

export default address => {
  const { control = null } = address || {};
  if (!isPlainObject(control) || !isPlainObject(address)) {
    return () => null;
  }
  return async dispatch => {
    if (validate.addressRequiredFields(address) && validate.addressChanged(control, address)) {
      const updatedAddress = await updateAdminAddressAction(dispatch, address);
      return cleanAddress(updatedAddress);
    }
    if (validate.addresseeRequiredFields(address) && validate.addresseeChanged(control, address)) {
      const updatedAddressee = await updateAdminAddresseeAction(dispatch, address);
      const updatedAddress = { ...address, addressee: { ...updatedAddressee } };
      return cleanAddress(updatedAddress);
    }
    return null;
  };
};
