// The purpose of this file is to inject the very specific script tags into the head,
// hence the disabling of the linting. Whilst some of these things might be fixable, I'm
// trying to mess around with it as little as possible.

import React, { useCallback, useEffect, useState } from 'react';
import { func } from 'prop-types';
import { Helmet } from 'react-helmet-async';

// it would seem entirely natural to have each as a separate component, react-helmet doesn't
// support this. So here we are.
const MarketingScripts = ({ onInserted }) => {
  const [scriptsInserted, setScriptsInserted] = useState(false);

  const handleChangeClientState = useCallback((newState, { scriptTags }) => {
    if (scriptTags?.length) setScriptsInserted(true);
  }, []);

  useEffect(() => {
    if (scriptsInserted) onInserted();
  }, [scriptsInserted, onInserted]);

  return (
    <Helmet onChangeClientState={handleChangeClientState}>
      {/* Impact Radius affiliate analytics API */}
      <script>
        {`if (includeExternal) {
        (function (a, b, c, d, e, f, g) {
          e["ire_o"] = c;
          e[c] =
            e[c] ||
            function () {
              (e[c].a = e[c].a || []).push(arguments);
            };
          f = d.createElement(b);
          g = d.getElementsByTagName(b)[0];
          f.async = 1;
          f.src = a;
          g.parentNode.insertBefore(f, g);
        })(
          "//d.impactradius-event.com/A2439362-ca05-476f-b281-c6cb6ca5fae01.js",
          "script",
          "ire",
          document,
          window
        );
      }`}
      </script>
    </Helmet>
  );
};

MarketingScripts.propTypes = {
  onInserted: func.isRequired,
};

export default MarketingScripts;
