import { GET } from 'redux/modules/address/actions/types';
import initialState from 'redux/modules/address/state/initial-state';
import { sortAddresses } from 'redux/modules/address/util';

const addressesRequest = state => ({
  ...state,
  ...initialState,
  displayedAddressId: state.displayedAddressId,
  getAllAddressesRequestStatus: 'loading',
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const addressesSuccess = (state, action) => {
  const addresses =
    action.result &&
    action.result.data &&
    action.result.data.addresses &&
    Array.isArray(action?.result?.data?.addresses)
      ? action.result.data.addresses.slice()
      : state.addresses;
  const { selectedAddress: { id: selectedAddressId = undefined } = {} } = state;
  const sortedAddresses = sortAddresses(addresses, selectedAddressId);

  return {
    ...state,
    addresses: sortedAddresses,
    getAllAddressesRequestStatus: 'loaded',
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const addressesFailure = (state, action) => ({
  ...state,
  getAllAddressesRequestStatus: 'error',
  request: {
    loading: false,
    loaded: false,
    error: action.error,
  },
});

export default map => {
  map.set(GET.request, addressesRequest);
  map.set(GET.success, addressesSuccess);
  map.set(GET.failure, addressesFailure);
  return map;
};
