import { PUT_ADDRESSEE } from 'redux/modules/address/actions/types';
import { sortAddresses } from 'redux/modules/address/util';

const addresseeRequest = state => ({
  ...state,
  editAddressLoading: true,
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const addresseeSuccess = (state, action) => {
  const addressee = action.result || {};
  const { addresses = [], selectedAddress: { id: selectedAddressId = undefined } = {} } = state;
  const sortedAddresses = sortAddresses(addresses, selectedAddressId);

  const selectedAddressIndex = addresses.findIndex(address => address.id === selectedAddressId);

  if (selectedAddressIndex > -1) addresses[selectedAddressIndex].addressee = addressee;

  return {
    ...state,
    addressInError: null,
    editAddressLoading: false,
    addresses: sortedAddresses,
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const addresseeFailure = (state, action) => ({
  ...state,
  editAddressLoading: false,
  request: {
    loading: false,
    loaded: false,
    error: (action && action.error && action.error.status) || null,
  },
});

export default map => {
  map.set(PUT_ADDRESSEE.request, addresseeRequest);
  map.set(PUT_ADDRESSEE.success, addresseeSuccess);
  map.set(PUT_ADDRESSEE.failure, addresseeFailure);
  return map;
};
