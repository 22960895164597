import { UPDATE_NAVIGATION } from 'redux/modules/address-suggestion/actions/types';

const updateNavigation = (state, action) => {
  const { navigation = [] } = action;
  return {
    ...state,
    navigation,
  };
};

export default map => {
  map.set(UPDATE_NAVIGATION, updateNavigation);
  return map;
};
