import { matchPath } from 'react-router-dom';

import { getPathname } from 'redux/modules/routing/selectors';

import headConfig from 'constants/head-config';
import { exactMatches, startsWithMatches, homepageTitle } from 'constants/page-titles';
import { capitaliseFirstLetter, delimitWordsWithSpaces } from 'utils/format';

const getTitleFromPath = pathname => {
  let title = pathname.split('/');
  [title] = title.slice(-1);
  return delimitWordsWithSpaces(title);
};

export const getShortPageTitle = state => {
  const pathname = (getPathname(state) || '').toLowerCase();

  if (pathname) {
    const exactMatch = exactMatches.find(([path]) =>
      matchPath({ path: path.toLowerCase() }, pathname),
    );

    if (exactMatch) {
      return typeof exactMatch[1] === 'string' ? exactMatch[1] : exactMatch[1](pathname, state);
    }

    const startsWithMatch = startsWithMatches.find(([path]) =>
      pathname.startsWith(path.toLowerCase()),
    );

    if (startsWithMatch) {
      return (
        (typeof startsWithMatch[1] === 'string'
          ? startsWithMatch[1]
          : startsWithMatch[1](pathname, state)) ||
        capitaliseFirstLetter(getTitleFromPath(pathname))
      );
    }
  }

  return undefined;
};

export const getPageTitle = state => {
  const shortPageTitle = getShortPageTitle(state);

  if (!shortPageTitle) {
    return headConfig.meta.head.titleTemplate.replace('%s | ', '');
  }

  return shortPageTitle === homepageTitle
    ? shortPageTitle
    : headConfig.meta.head.titleTemplate.replace('%s', shortPageTitle);
};
