import trolleyItems from 'api/definitions/trolleyItems';

import {
  TROLLEY_QUEUE_ITEM_UPDATING,
  TROLLEY_QUEUE_ITEM_UPDATED,
  TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
} from 'redux/modules/trolley/actions/types';

import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';

export default ({
    lineNumber,
    noteToShopper,
    personalisedMessage,
    productId,
    quantity: rawQuantity,
    clickContext,
    productPosition,
    searchType,
  }) =>
  async dispatch => {
    const quantity = formatQuantity(rawQuantity);
    const newItem = {
      lineNumber,
      noteToShopper,
      personalisedMessage,
      productId,
      quantity,
      trolleyItemId: -Number.parseInt(lineNumber, 10),
    };

    const analytics = {
      decrement: false,
      lineNumber,
      uom: quantity.uom,
      clickContext,
      productPosition,
      searchType,
    };

    return dispatch({
      analytics,
      queue: true,
      lineNumber,
      types: [
        TROLLEY_QUEUE_ITEM_UPDATING,
        TROLLEY_QUEUE_ITEM_UPDATED,
        TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
      ],
      apiCall: trolleyItems.addItem({
        body: newItem,
      }),
      callback: {
        success: response => {
          const { data, errors } = response;
          const failures = data?.addItemToTrolley?.failures || [];
          if (errors) {
            throw new Error('Graph returned a add item to trolley error');
          }
          if (failures.length > 0) {
            const error = new Error(failures[0].message);
            error.type = failures[0].type;
            throw error;
          }

          return {
            ...data.addItemToTrolley,
          };
        },
      },
    });
  };
