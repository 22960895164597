/* eslint-disable @typescript-eslint/no-restricted-imports */
import React from 'react';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import { TypographySecondary } from '@johnlewispartnership/wtr-ingredients/foundations/typography/TypographySecondary';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography/Typography';
import { useSelector } from 'react-redux';
import { RecipesRoot } from 'redux/modules/recipes/reducers';
import styles from './index.scss';
import {
  getNumberOfSelectedShoppableProducts,
  getTotalPriceOfSelectedShoppableProducts,
} from '../../../../redux/modules/recipes/selectors';
import { formatAsPounds } from '../../../../utils/currency';
import { SetSelectedRecipesIds, RecipeIdList } from './index';

export interface AccordionRecipeTitleProps {
  recipeId: string;
  title: string;
  checked: boolean;
  setSelectedRecipesIds: SetSelectedRecipesIds;
  toggleIngredients?: (checked: boolean) => void;
}

const AccordionRecipeTitle = ({
  recipeId,
  title,
  checked = true,
  setSelectedRecipesIds,
  toggleIngredients,
}: AccordionRecipeTitleProps) => {
  const numberOfSelectedShoppableProducts = useSelector((state: RecipesRoot) =>
    getNumberOfSelectedShoppableProducts(state, recipeId),
  );

  const totalPriceOfSelectedShoppableProducts = useSelector((state: RecipesRoot) =>
    getTotalPriceOfSelectedShoppableProducts(state, recipeId),
  );

  return (
    <div
      className={styles.accordionTitleWrapper}
      data-testid={`accordion-recipe-title-${recipeId}`}
    >
      <div className={styles.recipeTitleWrapper}>
        <Checkbox
          checked={checked}
          className={styles.recipeCheckbox}
          onClick={(event: React.MouseEvent<HTMLInputElement>) => {
            event.stopPropagation();
          }}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            event.stopPropagation();
            const target = event.target as HTMLInputElement;
            if (target.checked) {
              setSelectedRecipesIds((prevSelectedRecipesIds: RecipeIdList) => [
                ...prevSelectedRecipesIds,
                recipeId,
              ]);
            } else {
              setSelectedRecipesIds((prevSelectedRecipesIds: RecipeIdList) =>
                prevSelectedRecipesIds.filter(id => id !== recipeId),
              );
            }
            if (toggleIngredients) {
              toggleIngredients(target.checked);
            }
          }}
        />
        <TypographySecondary component="h3" font="sebenta" size={14} className={styles.recipeTitle}>
          {title}
        </TypographySecondary>
      </div>
      <Typography className={styles.recipeIngredientsNumber}>
        {numberOfSelectedShoppableProducts} ingredients
      </Typography>
      <Typography className={styles.recipePrice}>
        {formatAsPounds(totalPriceOfSelectedShoppableProducts)}
      </Typography>
    </div>
  );
};

export default AccordionRecipeTitle;
