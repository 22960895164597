import type { Fetcher, FetcherArgs } from './fetcher-types';

export const conditional =
  <PathParamKey extends string = string>(
    condition: (args: FetcherArgs<PathParamKey>) => boolean,
    fetcher: Fetcher<PathParamKey>,
  ): Fetcher<PathParamKey> =>
  (args: FetcherArgs<PathParamKey>) =>
  (dispatch: WtrDispatch) => {
    if (condition(args)) {
      return dispatch(fetcher(args));
    }

    return Promise.resolve();
  };
