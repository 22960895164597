import apiClient from 'api/client';

import { apiCallProps } from 'api/props';
import { connectApiToState } from 'api/utils/connect-api-to-state';

import { getPageType } from 'utils/get-page-type';
import locator from 'utils/locator';
import { getFeatureFlagsToPassToAPI, featureFlagsToQueryString } from 'utils/feature-flags';

export const apiCall =
  ({
    api = {},
    args = {},
    apiOverrides = {},
    cookieHandler,
    getState,
    verb,
    successCb,
    getErrorFromResponse = () => null,
  }) =>
  () => {
    const connectedArgs = connectApiToState(args, getState());
    const { cookies, jwt, retry, withResponseBodyLogging } = connectedArgs;
    const features = getFeatureFlagsToPassToAPI();
    const breadcrumb = `${getPageType(locator.pathname)}-fe`;

    const apiProps = apiCallProps(api, connectedArgs);
    const { headers = {} } = apiProps || {};
    const { fileDownload = false } = api;

    const output = {
      ...apiProps,
      cookieHandler,
      cookies,
      fileDownload,
      jwt,
      headers: {
        ...headers,
        breadcrumb,
        features: featureFlagsToQueryString(features),
      },
      method: verb,
      timeout: apiOverrides.timeout ?? api.timeout,
      successCb,
      getErrorFromResponse,
      retry,
      withResponseBodyLogging,
    };

    return apiClient(output);
  };
