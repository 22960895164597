import env from 'env/env';
import { useSelector } from 'react-redux';
import usePrevious from 'hooks/use-previous';
import { useLocation } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
import { getCookies } from 'redux/modules/cookies/selectors';
import getLoadingAdd from 'redux/modules/trolley/selectors/get-loading-add';
import { getCustomerId, getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { useTrolleyAnalytics } from 'hooks/use-trolley-analytics';

type PreviousLocation =
  | {
      pathname: string;
      search: string;
    }
  | undefined;

const TrolleyAnalyticsReporter = () => {
  const location = useLocation();
  const { trackAbandonTrolleyUpdateEvent } = useTrolleyAnalytics();
  const trolleyAddLoading = useSelector(getLoadingAdd);
  const { analytics: hasAnalyticsCookie } = useSelector(getCookies);
  const customerId = useSelector(getCustomerId);
  const customerOrderId = useSelector(getCustomerOrderId);
  const [googleAnalytics, setGoogleAnalytics] = useState<{
    loaded: boolean;
    clientId: string | null;
    sessionId: string | null;
  }>({
    loaded: false,
    clientId: null,
    sessionId: null,
  });

  const waitForGoogleAnalytics = useCallback(() => {
    if (!window.gtag) {
      setTimeout(waitForGoogleAnalytics, 100);
      return;
    }

    setGoogleAnalytics(prevState => {
      return { ...prevState, loaded: true };
    });
  }, []);

  const reportAbandonTrolleyUpdate = useCallback(() => {
    if (document.visibilityState === 'hidden' && trolleyAddLoading) {
      trackAbandonTrolleyUpdateEvent(
        window.location.href,
        googleAnalytics.clientId,
        googleAnalytics.sessionId,
        customerId,
        customerOrderId,
      );
    }
  }, [
    customerId,
    customerOrderId,
    googleAnalytics.clientId,
    googleAnalytics.sessionId,
    trackAbandonTrolleyUpdateEvent,
    trolleyAddLoading,
  ]);

  useEffect(() => {
    if (__SERVER__) return;

    if (!hasAnalyticsCookie) return;

    const doEffect = async () => {
      window.gtag('get', env.googleAnalytics.measurementId, 'client_id', (clientId: string) => {
        setGoogleAnalytics(prevState => {
          return { ...prevState, clientId };
        });
      });

      window.gtag('get', env.googleAnalytics.measurementId, 'session_id', (sessionId: string) => {
        setGoogleAnalytics(prevState => {
          return { ...prevState, sessionId };
        });
      });
    };

    if (!googleAnalytics.loaded) {
      waitForGoogleAnalytics();
    } else if (!googleAnalytics.clientId && !googleAnalytics.sessionId) {
      doEffect();
    }

    document.addEventListener('visibilitychange', reportAbandonTrolleyUpdate);
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('visibilitychange', reportAbandonTrolleyUpdate);
    };
  }, [
    googleAnalytics.clientId,
    googleAnalytics.loaded,
    googleAnalytics.sessionId,
    hasAnalyticsCookie,
    reportAbandonTrolleyUpdate,
    waitForGoogleAnalytics,
  ]);

  const previousLocation = usePrevious(location) as unknown as PreviousLocation;
  useEffect(() => {
    if (!hasAnalyticsCookie) return;

    if (previousLocation && previousLocation.pathname !== location.pathname && trolleyAddLoading) {
      trackAbandonTrolleyUpdateEvent(
        window.location.origin + previousLocation.pathname + previousLocation.search,
        googleAnalytics.clientId,
        googleAnalytics.sessionId,
        customerId,
        customerOrderId,
      );
    }
  }, [
    customerId,
    customerOrderId,
    googleAnalytics.clientId,
    googleAnalytics.sessionId,
    hasAnalyticsCookie,
    location.pathname,
    previousLocation,
    trackAbandonTrolleyUpdateEvent,
    trolleyAddLoading,
  ]);

  return null;
};

export default TrolleyAnalyticsReporter;
