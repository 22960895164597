import urls from 'constants/urls';
import { fulfilmentTypes } from 'constants/fulfilmentTypes';
import history from 'utils/history';
import { groceriesItemInTrolley } from 'redux/modules/slot-booking/selectors/groceries-item-in-trolley';
import { entertainingItemInTrolley } from 'redux/modules/slot-booking/selectors';
import { getFulfilmentTypeForBookedSlot } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import { setFulfilmentType } from 'redux/modules/slot-booking/actions/set-fulfilment-type';
import { isUserLoggedIn } from '../redux/modules/sessions/selectors';

const excludeRedirectionUrls = [
  urls.bookGroceriesCollectionSlot,
  urls.bookEntertainingCollectionSlot,
  urls.bookGroceriesAndEntertainingCollectionSlot,
  urls.reselectCollectionService,
];

const getCampaignFromPathname = pathname => (pathname.endsWith('/christmas') ? '/christmas' : '');

export default ({ location: { pathname, search }, store: { dispatch, getState } }) => {
  if (
    pathname.startsWith(urls.bookslotCollection) &&
    isUserLoggedIn(getState()) &&
    !excludeRedirectionUrls.some(url => pathname.startsWith(url))
  ) {
    let fulfilmentType = null;
    const campaign = getCampaignFromPathname(pathname);

    if (groceriesItemInTrolley(getState()) && !entertainingItemInTrolley(getState())) {
      history.replace(`${urls.bookGroceriesCollectionSlot}${campaign}${search}`);
      fulfilmentType = fulfilmentTypes.GROCERIES_COLLECTION;
    }
    if (entertainingItemInTrolley(getState()) && groceriesItemInTrolley(getState())) {
      history.replace(`${urls.bookGroceriesAndEntertainingCollectionSlot}${campaign}${search}`);
      fulfilmentType = fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION;
    }
    if (
      getFulfilmentTypeForBookedSlot(getState()) ===
      fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION
    ) {
      history.replace(`${urls.bookGroceriesAndEntertainingCollectionSlot}${campaign}${search}`);
      fulfilmentType = fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION;
    }
    if (getFulfilmentTypeForBookedSlot(getState()) === fulfilmentTypes.ENTERTAINING_COLLECTION) {
      history.replace(`${urls.bookEntertainingCollectionSlot}${campaign}${search}`);
      fulfilmentType = fulfilmentTypes.ENTERTAINING_COLLECTION;
    }
    if (fulfilmentType) {
      dispatch(setFulfilmentType(fulfilmentType));
    } else {
      history.replace(`${urls.bookCollectionSlot}${campaign}${search}`);
    }
  }
  return true;
};
