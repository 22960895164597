import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import styles from 'components/HomePage/Header/Account/MyAccount/MyAccount.scss';
import urls from 'constants/urls';
import { dataLayer } from 'analytics/data-layer';

const MyAccount = ({ showContactAddressNotPresentNotification, loggedIn }) => {
  useEffect(() => {
    if (showContactAddressNotPresentNotification && loggedIn) {
      dataLayer.push({
        event: 'contact_address_red_badge_shown',
        temporary: true,
      });
    }
  }, [showContactAddressNotPresentNotification, loggedIn]);

  return loggedIn ? (
    <>
      <div
        className={classNames(styles.iconWrapper, {
          [styles.CANotPresent]: showContactAddressNotPresentNotification,
        })}
      >
        <User className={styles.signInIcon} />
      </div>
      <a className={styles.link} data-test="myaccount-link" href={urls.myAccount}>
        My Account
      </a>
    </>
  ) : null;
};

MyAccount.displayName = 'MyAccount';

MyAccount.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  showContactAddressNotPresentNotification: PropTypes.bool,
};

MyAccount.defaultProps = {
  showContactAddressNotPresentNotification: false,
};

export default MyAccount;
