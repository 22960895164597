import env from 'env/env';
import sortBy from 'lodash/sortBy';

export const composeTimeFractionString = (time, fraction) => {
  let timeString = '';
  if (time > 0) timeString = `${time} ${fraction}`;
  if (time > 1) timeString = `${timeString}s`;
  return timeString;
};

export const formatMinutesToString = minutes => {
  if (!minutes || Number.isNaN(minutes)) return '-';

  const hours = Math.trunc(minutes / 60);

  if (minutes > 72 * 60 - 1) {
    const days = Math.trunc(hours / 24);
    return composeTimeFractionString(days, 'day');
  }

  if (minutes > 59) {
    const remainingMinutes = minutes % 60;
    const hoursString = composeTimeFractionString(hours, 'hr');
    const minutesString = composeTimeFractionString(remainingMinutes, 'min');
    return `${hoursString} ${minutesString}`;
  }

  return composeTimeFractionString(minutes, 'min');
};

export const checkIfUppercaseWord = word => {
  const firstChar = word.charAt(0);
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(firstChar * 1)) {
    return true; // numeric first character so leave the word as is
  }
  if (word === word.toUpperCase()) {
    return true;
  }
  if (word === word.toLowerCase()) {
    return false;
  }

  return false;
};

export const formatRecipeMetaTitle = (title, appendRecipe = false, currentPage = 1) => {
  if (typeof title === 'undefined' || title === null || title.length === 0) return '';

  const titleArr = title.split(' ');

  if (
    titleArr[titleArr.length - 1].toLowerCase() === 'recipe' &&
    titleArr[titleArr.length - 2].toLowerCase() === 'recipe'
  ) {
    titleArr.pop();
  }

  if (appendRecipe && titleArr[titleArr.length - 1].toLowerCase() !== 'recipe') {
    titleArr.push('Recipe');
  }

  const capitalisedArr = titleArr.map(word => {
    if (word === 'RECIPE') return 'Recipe';

    if (checkIfUppercaseWord(word)) return word;

    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  if (currentPage > 1) {
    capitalisedArr.unshift(`Page ${currentPage.toLocaleString()} |`);
  }

  return capitalisedArr.join(' ');
};

export const formatDashForEmpty = value => (value ? `${value}` : '-');

const multiplierMap = {
  '1/8': '⅛',
  '1/4': '¼',
  '1/3': '⅓',
  '1/2': '½',
  '2/3': '⅔',
  '3/4': '¾',
};

export const formatMultiplier = multiplier => {
  if (!multiplier) {
    return '';
  }

  const fraction = multiplierMap[multiplier];

  if (fraction) {
    return fraction;
  }

  return multiplier.toLocaleString('en-GB');
};

const fractionMap = {
  0.13: '⅛',
  0.25: '¼',
  0.33: '⅓',
  '0.50': '½',
  0.66: '⅔',
  0.75: '¾',
};

/**
 * Pass a number/decimal and get a string number/fraction back
 * @param {number} quantity
 * @returns {string}
 */
const toFraction = quantity => {
  if (!quantity) {
    return '';
  }

  const fullNumber = Math.floor(quantity);
  const fraction = fractionMap[(quantity - fullNumber).toFixed(2)];

  if (fullNumber && fraction) {
    return `${fullNumber}${fraction}`;
  }

  if (fraction) {
    return fraction;
  }

  return quantity.toLocaleString('en-GB');
};

export const formatQuantity = toFraction;

const measurementsMap = {
  grams: 'g',
  kilograms: 'kgs',
  tablespoon: ' tbsp',
  teaspoon: ' tsp',
  kilos: 'kg',
  'litre/s': ' L',
  mls: 'ml',
  'cup/s': ' cups',
};

export const formatMeasurement = measurement =>
  measurementsMap[measurement?.toLowerCase()] || (measurement && ` ${measurement}`) || ''; // add correct spacing to ingredient quantity + measurement

export const unitIsNoSpace = unit => ['g', 'k/s', 'kgs', 'mls'].indexOf(unit) > -1;

export const selectImage = (recipe, image) => {
  if (recipe.isLegacy && recipe.url.includes('/content/waitrose/en/home/recipes')) {
    let src = '';
    if (image?.fileReference.includes('Recipe_image_on_its_way.jpg')) {
      src = `${env.cfDomain}${image?.fileReference}`;
    } else {
      src = `https://www.waitrose.com${image?.fileReference}.thumb.400.400.png`;
    }

    return src;
  }

  return image?.dynamicImages?.highQuality95 || `${env.cfDomain}${image?.fileReference}`;
};

export const selectLogo = (logos = []) => {
  if (!logos || logos.length === 0) return null;

  const logo = logos[0];
  const { dynamicImages, fileReference, alternativeText } = logo || {};
  const src =
    dynamicImages?.highQuality95 || (fileReference ? `${env.cfDomain}${fileReference}` : null);
  const alt = alternativeText || '';

  if (!src) return null;

  return { src: `${src}&fmt=png-alpha`, alt };
};

export const selectLandingPageImage = (image = {}) => {
  if (!image) return null;
  const { dynamicImages, fileReference } = image;
  const src =
    dynamicImages?.highQuality95 || (fileReference ? `${env.cfDomain}${fileReference}` : null);

  if (!src) return null;

  return src;
};

export const recipeURL = recipe =>
  recipe.isLegacy && recipe.url.includes('/content/waitrose/en/home/recipes')
    ? `https://www.waitrose.com${recipe.url}`
    : recipe.url;

export const recipeJsonLd = (
  cookingTime = 0,
  course = {},
  description = {},
  ingredientGroups = [],
  makes = 0,
  method = [],
  nutritional = {},
  preparationTime = 0,
  servingSize = 0,
  title = '',
  totalTime = 0,
  images = [],
  firstPublishedDate = '',
  tags = [],
  rating = {},
) => {
  const structIngredients = recipeIngredientGroups => {
    const ingredients = recipeIngredientGroups[0]?.ingredients;
    const ingredientsList = ingredients?.map(item => {
      const { name, preparationNotes, quantity, measurement } = item;
      const unit = formatMeasurement(measurement);
      return `${formatQuantity(quantity)} ${unit} ${preparationNotes || name || ''}`;
    });

    return ingredientsList;
  };

  const formatDuration = duration => {
    const remainder = duration % 60;
    if (duration > 60 && remainder > 0) {
      return `PT${Math.floor(duration / 60)}H${remainder}M`;
    }

    if (duration === 60) return `PT1H`;

    return `PT${remainder}M`;
  };

  const getImageUrl = mainImages => {
    if (mainImages?.dynamicImages) return mainImages?.dynamicImages?.defaultQuality;

    if (mainImages?.fileReference) return `${env.cfDomain}${mainImages?.fileReference}`;
    return null;
  };

  const formatTags = recipeTags => {
    if (recipeTags.length > 0)
      return recipeTags?.reduce((prev, curr) =>
        typeof prev === 'object' ? `${prev.title}, ${curr.title}` : `${prev}, ${curr.title}`,
      );

    return '';
  };

  const jsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: title,
    image: getImageUrl(images[0]),
    datePublished: firstPublishedDate?.split('T')[0],
    description: description?.plainText,
    prepTime: formatDuration(preparationTime),
    cookTime: formatDuration(cookingTime),
    totalTime: formatDuration(totalTime),
    keywords: formatTags(tags),
    recipeYield: servingSize || makes,
    recipeCategory: course.title,
    nutrition: {
      '@type': 'NutritionInformation',
      calories: `${nutritional?.energy?.kcals} calories`,
      carbohydrateContent: `${nutritional?.carbohydrates?.total} g`,
      fatContent: `${nutritional?.fat?.total} g`,
      fiberContent: `${nutritional?.fibre} g`,
      proteinContent: `${nutritional?.protein} g`,
      saturatedFatContent: `${nutritional?.fat?.saturated} g`,
      sodiumContent: `${nutritional?.salt} g`,
      sugarContent: `${nutritional?.carbohydrates?.sugars} g`,
    },
    recipeIngredient: structIngredients(ingredientGroups),
    recipeInstructions: method?.map((stepObj, index) => ({
      '@type': 'HowToStep',
      name: `Step ${index + 1}`,
      text: stepObj.step,
    })),
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: rating.average,
      ratingCount: rating.totalRatings,
    },
  };

  return jsonLd;
};

export const prepareRecipeTags = (dietaryTags = [], nutritional, nutritionalTags = []) => {
  const allergens = nutritional.allergens ?? [];
  const fiveNutritional =
    nutritionalTags.length > 5 ? nutritionalTags.slice(0, 5) : nutritionalTags;
  return [...sortBy(dietaryTags, 'title'), ...sortBy(allergens, 'title'), ...fiveNutritional];
};

export const recipeLandingJsonLd = recipes => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: recipes.map((recipe, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      url: `https://www.waitrose.com${recipe.url}`,
      name: recipe.title,
      image: selectImage(recipe, recipe.images?.[0]),
    })),
  };

  return jsonLd;
};

const flattenObj = ob => {
  const result = {};
  /* eslint-disable-next-line no-restricted-syntax */
  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      /* eslint-disable-next-line no-restricted-syntax, guard-for-in */
      for (const j in temp) {
        result[`${i}.${j}`] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const checkZeroNutritional = nutritional => {
  const flat = flattenObj(nutritional);
  delete flat.allergens;
  delete flat.notLegallyRequired;

  return !Object.values(flat).some(value => parseFloat(value) !== 0);
};
