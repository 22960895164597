import React, { HTMLAttributes, MutableRefObject, ReactNode } from 'react';
import classnames from 'classnames';
import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Clock } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { Stars } from '@johnlewispartnership/wtr-ingredients/ingredients/StarRating/Stars';
import AnchorLink from 'components/AnchorLink';
import { selectImage, recipeURL } from 'utils/format-recipe';
import { RecipeSummaryItem } from 'api/definitions/recipes/index.d';
import { analyticsTags } from 'components/Recipes/utils/analytics';
import styles from './index.scss';

const formatTime = (num: number) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const formattedHours = hours ? `${hours} hr${hours > 1 ? 's' : ''}` : '';
  const formattedMins = minutes ? `${minutes} mins` : '';
  return `${formattedHours}${formattedHours && formattedMins ? ' ' : ''}${formattedMins}`;
};

type RecipeCardProps = {
  recipe: RecipeSummaryItem;
  focusRef?: MutableRefObject<HTMLAnchorElement>;
  imgProps?: HTMLAttributes<HTMLImageElement>;
  headingLevel?: React.ElementType;
  children?: ReactNode;
  Before?: ReactNode;
  analytics: Record<string, string>;
  onClick: React.MouseEventHandler;
};

export const RecipeCard = ({
  recipe,
  focusRef,
  imgProps,
  headingLevel,
  children,
  Before,
  analytics,
  onClick,
  ...htmlAttrs
}: RecipeCardProps & React.HTMLAttributes<HTMLLIElement>) => {
  const image = recipe.images?.[0];
  const { className, ...attrs } = htmlAttrs;

  return (
    <li className={classnames(styles.recipe, className)} {...attrs}>
      <AnchorLink
        to={recipeURL(recipe)}
        className={styles.a}
        onClick={onClick}
        id={recipe.id}
        innerRef={focusRef}
        {...analyticsTags(analytics)}
      >
        {Before}
        <Card
          fullWidth
          fullHeight
          image={
            <img
              alt={image?.alternativeText || recipe.id}
              src={selectImage(recipe, image)}
              aria-hidden="true"
              {...imgProps}
            />
          }
          footer={
            <Typography
              noMargins
              styleAs="paragraphSmallLight"
              aria-hidden="true"
              className={styles.clock}
            >
              {recipe.totalTime ? (
                <>
                  <Clock data-testid="clock-icon" size="xsmall" />
                  {formatTime(recipe.totalTime)}
                </>
              ) : (
                ' '
              )}
            </Typography>
          }
        >
          <div className={styles.titleRating}>
            <Typography
              noMargins
              styleAs="sectionHeading"
              aria-label={`Recipe: ${recipe.title}`}
              className={styles.title}
              component={headingLevel || 'h3'}
            >
              {recipe.title}
            </Typography>
            {recipe.totalRatings > 0 && (
              <div className={styles.ratingSummary} data-testid="rating-summary">
                <Stars
                  starSize="xsmall"
                  rating={recipe.averageRating!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  aria-label={`${recipe.averageRating} out of 5`}
                />
                <Typography
                  noMargins
                  element="span"
                  styleAs="paragraphHeading"
                  aria-label={`${recipe.totalRatings} ratings`}
                >
                  ({recipe.totalRatings})
                </Typography>
              </div>
            )}
          </div>
        </Card>
      </AnchorLink>
      {children}
    </li>
  );
};
