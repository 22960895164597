import React, { memo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import urls from 'constants/urls';
import Logo from 'components/HeaderLogo/Logo';
import styles from 'components/HeaderLogo/index.scss';

export interface HeaderLogoProps {
  homepage?: boolean;
  noLink?: boolean;
  minimal?: boolean;
  small?: boolean;
  staticRender?: boolean;
}

const HeaderLogo = memo(
  ({
    homepage = false,
    minimal = false,
    noLink = false,
    small = false,
    staticRender = false,
  }: HeaderLogoProps) => (
    <div
      className={classNames(styles.wrapper, {
        [styles.minimal]: minimal,
      })}
    >
      {staticRender || homepage || noLink ? (
        <Logo homepage={homepage} minimal={minimal} small={small} />
      ) : (
        <Link
          className={styles.link}
          data-testid="header-logo-link"
          to={urls.groceriesHome}
          data-test="waitrose-logo"
        >
          <Logo homepage={homepage} minimal={minimal} small={small} />
        </Link>
      )}
    </div>
  ),
);

HeaderLogo.displayName = 'HeaderLogo';

export default HeaderLogo;
