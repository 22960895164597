import { addressRequestError } from 'redux/modules/address/selectors';

const buildAnalytics = (method, state) => {
  const error = addressRequestError(state);
  return {
    event: 'address_api_error',
    method,
    error,
  };
};

const getFailure = state => ({ ...buildAnalytics('GET', state) });

const getByIdFailure = state => ({ ...buildAnalytics('GETBYID', state) });

const postFailure = state => ({ ...buildAnalytics('POST', state) });

const deleteFailure = state => ({ ...buildAnalytics('DELETE', state) });

const optionsFailure = state => ({ ...buildAnalytics('OPTIONS', state) });

export { getFailure, getByIdFailure, postFailure, deleteFailure, optionsFailure };
