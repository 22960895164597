import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  account: {
    totalOrderCount: -1,
  },
  prompts: {
    isPendingContactAddressVerification: false,
  },
  request: {
    initialLoadComplete: false,
  },
});

export default initialState;
