import { addressSuggestionRequestError } from 'redux/modules/address-suggestion/selectors';
import { getCustomerId } from 'redux/modules/sessions/selectors';

const buildAnalytics = (method, state) => {
  const error = addressSuggestionRequestError(state);
  const customerId = getCustomerId(state);

  return {
    event: 'address_suggestion_api_error',
    method,
    error,
    customerId,
  };
};

const getFailure = state => ({ ...buildAnalytics('GET', state) });

const getByIdFailure = state => ({ ...buildAnalytics('GETBYID', state) });

export { getFailure, getByIdFailure };
