import { clone } from 'test-utils/clone';
import { removeEmpty } from 'utils/remove-empty';

const buildData = ({ endpoint } = {}, { body } = {}) => {
  // skip cleanup for the image upload service
  if (endpoint === 'care-cases/images') return body;
  return body ? removeEmpty(clone(body)) : undefined;
};

export default buildData;
