import { deepFreeze } from 'utils/deepFreeze';
import { SET_SUPPORTED_BROWSER_FLAG } from '../actions';

const initialState = deepFreeze({
  isSupportedBrowser: true,
});

export default (state = initialState, action) => {
  switch (action?.type) {
    case SET_SUPPORTED_BROWSER_FLAG:
      return { isSupportedBrowser: action.payload };
    default:
      return state;
  }
};
