import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

import { BREAKPOINTS } from 'constants/grid';
import { SSRMediaQuery } from 'components/SSRMediaQuery';

import styles from 'components/Trolley/TrolleySize/TrolleySize.scss';

import {
  TrolleyActive,
  TrolleyInactive,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';

const TrolleySize = ({ dataTestId, highlightValue, size }) => {
  const itemQty = size > 99 ? '99+' : size;

  const TrolleyIcon = size ? TrolleyActive : TrolleyInactive;

  return (
    <span
      data-testid="trolley-size-container"
      className={classNames(styles.trolleySize, {
        [styles.highlight]: highlightValue,
      })}
    >
      <SSRMediaQuery minWidth={BREAKPOINTS.sm} groupId="trolley-icon-min-sm">
        <div className={styles.icon}>
          <TrolleyIcon size="small" />
        </div>
      </SSRMediaQuery>

      <span className={styles.value} data-test-id={dataTestId}>
        {itemQty || null}
      </span>
    </span>
  );
};

TrolleySize.propTypes = {
  dataTestId: PropTypes.string,
  highlightValue: PropTypes.bool,
  size: PropTypes.number,
};

TrolleySize.defaultProps = {
  dataTestId: '',
  highlightValue: true,
  size: 50,
};

export default memo(TrolleySize);
