import { createSelector } from 'reselect';
import { getProductById } from 'redux/modules/entities/selectors/products';

const passState = state => state;
const getTrolleyErrors = ({ trolleyErrors } = {}) => trolleyErrors;

const getTrolleyErrorProducts = createSelector(
  [getTrolleyErrors],
  ({ conflictedProducts = [] } = {}) => conflictedProducts,
);

export const getConflictedProducts = createSelector(
  [passState, getTrolleyErrorProducts],
  (state, trolleyErrorProducts) =>
    Object.keys(trolleyErrorProducts).map(conflictedProductId => {
      const {
        messages: { longGroup, reason } = {},
        productId,
        lineNumber,
      } = trolleyErrorProducts[conflictedProductId];
      const {
        defaultQuantity: quantity,
        currentSaleUnitPrice,
        displayPriceQualifier: priceQualifier,
        name,
      } = getProductById(state, productId) || {};
      const message = reason || longGroup;

      return {
        conflict: true,
        message,
        products: [
          {
            name,
            price: currentSaleUnitPrice?.price?.amount,
            priceQualifier,
            productId,
            quantity,
            lineNumber,
          },
        ],
      };
    }),
);

const groupBy = (list, key) =>
  list.reduce((passedGroups, item) => {
    const groups = passedGroups;
    (groups[item[key]] = groups[item[key]] || []).push(item);
    return groups;
  }, {});

export const getGroupedConflictedProducts = createSelector(
  [getConflictedProducts],
  conflictedProducts => {
    const groups = groupBy(conflictedProducts, 'message');

    return Object.keys(groups).map(message => {
      const products = groups[message].reduce((productList, { products: groupProducts = [] }) => {
        groupProducts.forEach(product => productList.push(product));
        return productList;
      }, []);

      return {
        conflict: true,
        message,
        products,
      };
    });
  },
);
