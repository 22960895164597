import { compose } from 'redux';

import { REMOVE_ITEM } from 'constants/restrictions';

const ACTION_FIELDS = ['nextSlotDate'];

const filterProductResolutionActions = conflict => {
  const { resolutionActions } = conflict;
  return {
    ...conflict,
    resolutionActions: resolutionActions.filter(action => action !== REMOVE_ITEM),
  };
};

const pluckActionFields = conflict =>
  ACTION_FIELDS.reduce((acc, field) => {
    if (field in conflict) {
      acc[field] = conflict[field];
    }
    return acc;
  }, {});

const removeResolutionActionMessages = ({ messages, resolutionActions = [] }) => {
  const result = {};
  Object.keys(messages || {})
    .filter(prop => !resolutionActions.includes(prop))
    .forEach(prop => {
      result[prop] = messages[prop];
    });
  return result;
};

const convertResolutionActionsToObjects = conflict => {
  const { messages, resolutionActions = [] } = conflict;
  return resolutionActions.map(action => ({
    id: action,
    message: (messages && messages[action]) || '',
    ...pluckActionFields(conflict),
  }));
};

const commonTransform = conflict => ({
  ...conflict,
  messages: removeResolutionActionMessages(conflict),
  resolutionActions: convertResolutionActionsToObjects(conflict),
});

export const transformProductConflict = compose(commonTransform, filterProductResolutionActions);

export const transformTrolleyConflict = commonTransform;
