import React from 'react';
import propTypes from 'prop-types';
import { CmsComponentGrid } from '@johnlewispartnership/wtr-content-component-library';

const ContentLocation = ({ preloadImages = false, ...props }) => (
  <CmsComponentGrid {...props} asContentLocation preloadImages={preloadImages} />
);

ContentLocation.defaultProps = {
  name: 'content-location',
  preloadImages: false,
};

ContentLocation.propTypes = {
  name: propTypes.string,
  preloadImages: propTypes.bool,
};

export default ContentLocation;
