import { SCROLL_RESTORE_KEY } from 'constants/scroll-session-storage';
import { pathJoin } from 'utils/general';
import env from 'env/env';
import { matchPath } from 'react-router-dom';
import { session } from './storage';
import locator from './locator';

export type SessionScrollState = {
  searchAndBrowse: unknown;
  entities: unknown;
  taxonomy: unknown;
  content: unknown;
  page: unknown;
};

export type ScrollSessionStorage = {
  state: SessionScrollState;
  href: string;
  lineNumber: string;
};

export function getScrollStateFromSessionStorage() {
  return session.getJson<ScrollSessionStorage>(SCROLL_RESTORE_KEY);
}

export function setScrollStateInSessionStorage(
  scrollState: SessionScrollState,
  lineNumber: string,
) {
  session.setJson(SCROLL_RESTORE_KEY, {
    state: scrollState,
    href: locator.href,
    lineNumber,
  });
}

export function removeScrollStateFromSessionStorage() {
  session.remove(SCROLL_RESTORE_KEY);
}

export function canRestoreScrollStateFromSessionStorage() {
  if (__SERVER__) {
    return false;
  }
  const sessionScrollState = getScrollStateFromSessionStorage();
  return sessionScrollState && sessionScrollState.href === locator.href;
}

export const isScrollRestorePath = (pathname: string) => {
  const PDP_ROUTE = pathJoin(env.root, '/products/:productName/:productId');
  const PLP_ROUTE = pathJoin(env.root, '/shop/browse/*');
  const SEARCH_ROUTE = pathJoin(env.root, '/shop/search');
  const MULTISEARCH_ROUTE = pathJoin(env.root, '/shop/multi-search');

  const isPDP = !!matchPath({ path: PDP_ROUTE }, pathname);
  const isPLP = !!matchPath({ path: PLP_ROUTE }, pathname);
  const isSearch = !!matchPath({ path: SEARCH_ROUTE }, pathname);
  const isMultisearch = !!matchPath({ path: MULTISEARCH_ROUTE }, pathname);

  return isPDP || isPLP || isSearch || isMultisearch;
};
