import { ORDER_LOADING } from 'redux/modules/trolley/actions/types';
import { CUSTOMER_SLOT_REQUEST } from 'redux/modules/customer-slot/actions/types';
import { LOADING as PRODUCTS_LOADING } from 'redux/modules/search-and-browse/actions/types';
import { CLIENT_PAGE_LOADED } from 'redux/modules/page/actions/types';
import { LOAD_PREFERENCES_FULFILLED } from 'redux/modules/marketing-preferences/actions/types';
import { MARKETING_PREFERENCES_ACKNOWLEDGED } from 'redux/modules/my-account/actions/types';

export const MONETATE_ACTIONS = [
  CLIENT_PAGE_LOADED,
  ORDER_LOADING,
  PRODUCTS_LOADING,
  CUSTOMER_SLOT_REQUEST,
  LOAD_PREFERENCES_FULFILLED,
  MARKETING_PREFERENCES_ACKNOWLEDGED,
];
