import type { LoadableComponent } from '@loadable/component';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getClientPageIsLoaded } from 'redux/modules/page/selectors/get-client-page-is-loaded';
import { requestIdleCallback } from 'utils/settimeout-wrapper';

interface ComponentPreloaderProps {
  components: Array<LoadableComponent<unknown>>;
}

const triggerPreloadInSeries = async (components: ComponentPreloaderProps['components']) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const component of components) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise<void>(resolve => {
      requestIdleCallback(() => {
        component.preload();
        resolve();
      });
    });
  }
};

export const ComponentPreloader = ({ components }: ComponentPreloaderProps) => {
  const componentPreloadRef = useRef(false);
  const isClientPageIsLoaded = useSelector(getClientPageIsLoaded);

  useEffect(() => {
    if (componentPreloadRef.current) {
      return;
    }

    if (!components.length) {
      return;
    }

    if (!isClientPageIsLoaded) {
      return;
    }

    componentPreloadRef.current = true;

    triggerPreloadInSeries(components);
  }, [components, isClientPageIsLoaded]);

  return null;
};
