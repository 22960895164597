import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Spinner } from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import React from 'react';
import { isEmbeddedInWebView } from 'utils/webview';
import CheckoutHeader from './CheckoutHeader/CheckoutHeader';
import styles from './styles.scss';

export interface CheckoutWrapperProps {
  title?: string;
  children?: React.ReactNode;
}

export function CheckoutWrapper({ title = 'Checkout', children }: CheckoutWrapperProps) {
  const isWebview = isEmbeddedInWebView();

  return (
    <div className={styles.checkoutWrapper}>
      <CheckoutHeader />
      <main className={styles.background}>
        {!isWebview ? (
          <div className={styles.pageTitle}>
            <Typography styleAs="screenHeading" element="h1" noMargins>
              {title}
            </Typography>
          </div>
        ) : null}
        {children || <Spinner isActive className={styles.loadingIndicator} />}
      </main>
    </div>
  );
}
