import { GET_BY_ID } from 'redux/modules/address/actions/types';

const addressByIdRequest = state => ({
  ...state,
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const addressByIdSuccess = (state, action) => {
  const { addressee = {} } = action.result || {};
  const selectedAddress = {
    ...action.result,
    title: addressee.title,
    firstName: addressee.firstName,
    lastName: addressee.lastName,
    contactNumber: addressee.contactNumber,
  };
  return {
    ...state,
    selectedAddress,
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const addressByIdFailure = (state, action) => ({
  ...state,
  request: {
    loading: false,
    loaded: false,
    error: action.error,
  },
});

export default map => {
  map.set(GET_BY_ID.request, addressByIdRequest);
  map.set(GET_BY_ID.success, addressByIdSuccess);
  map.set(GET_BY_ID.failure, addressByIdFailure);
  return map;
};
