import { yieldToMain } from 'utils/yield-to-main';

/*
  Pushes simple key/value pair data to Google Tag Manager (GTM) data layer

  Generate and push object(s) to the GTM data layer derived from action payload
 */
const createMiddleware =
  (dataLayer, actionsToTrack = {}) =>
  store =>
  next =>
  async action => {
    next(action);

    await yieldToMain();

    if (!Object.keys(actionsToTrack).includes(action.type)) {
      return;
    }

    const state = store.getState();
    const transform = actionsToTrack[action.type];
    const data = transform(state, action);

    if (Array.isArray(data)) {
      data.forEach(dimension => dataLayer.push(dimension));
    } else if (data) {
      dataLayer.push(data);
    }
  };

export default createMiddleware;
