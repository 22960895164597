import recipeProducts from 'api/definitions/recipes/recipe-products';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { fetchMultipleProducts } from 'redux/modules/products/actions/fetch-multiple-products';
import { GET_ORDER_PRODUCTS } from 'redux/modules/orders/actions/types';
import { getFeatureFlags } from 'utils/feature-flags';

import { PRODUCTS_BY_RECIPE_ID } from './types';

const getProductsByRecipeIdAsync = params => ({
  types: PRODUCTS_BY_RECIPE_ID.triplet,
  apiCall: recipeProducts.post({
    body: params,
    iHandleStatusCodes: true,
  }),
  id: params.recipeId,
});

export const getProductsByRecipeId = recipeId => async (dispatch, state) => {
  let trolleyId = getCustomerOrderId(state());
  if (parseInt(trolleyId, 10) === 0) {
    // fix for PIMS service receiving 0
    trolleyId = null;
  }

  const includeDuplicates = !!getFeatureFlags().ShoppablePhase3;

  return dispatch(getProductsByRecipeIdAsync({ recipeId, trolleyId, includeDuplicates }))
    .then(({ ingredients }) => {
      const lineNumbers = ingredients
        .filter(ingredient => ingredient.products.length)
        .map(({ products }) => products[0].lineNumber);
      return dispatch(
        fetchMultipleProducts(GET_ORDER_PRODUCTS)(lineNumbers, { customerOrderId: trolleyId }),
      );
    })
    .catch(() => {});
};
