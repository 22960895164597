import { Slot, SlotsGreenVariant } from 'redux/modules/slots/state/initial-state';

const reducer = (
  state: WtrState,
  action: { payload: { slots: Slot[]; greenVariant: SlotsGreenVariant } },
) => ({
  ...state,
  loading: false,
  slots: action.payload.slots || [],
  greenVariant: action.payload.greenVariant,
  error: null,
});

export default reducer;
