import { createSelector } from 'reselect';
import { getFavourites, isFavouritesSuccessfullyLoaded } from './get-favourites';

const EMPTY_FAVOURITES_PRODUCTS_ARRAY: never[] = [];

export const getFavouritesProducts = createSelector([getFavourites], favourites =>
  isFavouritesSuccessfullyLoaded(favourites)
    ? favourites.componentsAndProducts
    : EMPTY_FAVOURITES_PRODUCTS_ARRAY,
);
