import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import locator from 'utils/locator';

import styles from './RefreshMessaging.scss';

const RefreshMessaging = () => (
  <div className={styles.refreshMessaging}>
    <Typography>Sorry, we&apos;re experiencing an unexpected error.</Typography>
    <Typography>
      These problems are normally temporary. Please refresh the page and try again.
    </Typography>
    <div className={styles.refreshButton}>
      <Button label="Refresh" onClick={locator.reload} />
    </div>
  </div>
);

export default RefreshMessaging;
