export const getSelectedAddress = state => state.slotBooking.selectedAddress;
export const isAddressSelected = state => !!getSelectedAddress(state);
export const isAddressANewAddress = address => !!address && address.id === '-1';
export const isSelectedAddressANewAddress = state => {
  const address = getSelectedAddress(state);
  return isAddressANewAddress(address);
};
export const isThisAddressSelectedSelector = (state, addressId) => {
  const { id } = getSelectedAddress(state) || {};
  return (id && id === addressId) || false;
};
