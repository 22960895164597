import { createSelector } from 'reselect';
import { SEASONAL_MENU } from 'constants/experience-fragments';
import { getExperienceFragmentByKey } from '.';

const getSeasonalMenuExperienceFragmentState = (state: WtrState) =>
  getExperienceFragmentByKey(state, SEASONAL_MENU.key);

export const getSeasonalMenuExperienceFragment = createSelector(
  [getSeasonalMenuExperienceFragmentState],
  seasonalMenuExperienceFragment => {
    const seasonalMenuTitle = seasonalMenuExperienceFragment?.pageTitle?.text;
    const seasonalMenuContent = seasonalMenuExperienceFragment?.locations?.[SEASONAL_MENU.location];

    return {
      seasonalMenuTitle,
      seasonalMenuContent,
    };
  },
);
