import { createSelector } from 'reselect';
import root from 'window-or-global';
import { getCustomerId, isUserLoggedIn } from 'redux/modules/sessions/selectors';

const getValuesFromLocalStorage = key => {
  try {
    const values = JSON.parse(root.localStorage.getItem(key));
    return values.every(value => typeof value === 'string') ? values : null;
  } catch {
    return null;
  }
};

const saveValuesToLocalStorage = (key, values) => {
  try {
    root.localStorage.setItem(key, JSON.stringify(values));
    return values.every(value => typeof value === 'string') ? values : null;
  } catch {
    return null;
  }
};

const anonymousOrAuthenticatedValue = (authenticated, valueAnon, valueAuth) => explicitAnon =>
  !authenticated || explicitAnon ? valueAnon : valueAuth;

export const getJotterStorage = createSelector(
  [isUserLoggedIn, getCustomerId],
  (authenticated, customerId) => {
    const key = anonymousOrAuthenticatedValue(
      authenticated,
      'multisearch:-1',
      `multisearch:${customerId}`,
    );

    return {
      clear: ({ anonymous } = {}) => saveValuesToLocalStorage(key(anonymous), []),
      set: ({ anonymous, values } = {}) => saveValuesToLocalStorage(key(anonymous), values),
      get: ({ anonymous } = {}) => getValuesFromLocalStorage(key(anonymous)),
    };
  },
);
