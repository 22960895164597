import env from 'env/env';
import interpolate from 'utils/interpolate';

export const buildPath = (
  { johnLewis = false, loyaltyExperiences = false, defaultVersion = 1, endpoint, service } = {},
  args,
) => {
  if (!endpoint || !service) return '/';

  const path = typeof endpoint === 'function' ? endpoint(args) : interpolate(endpoint, args);
  const { version = defaultVersion } = args;
  return johnLewis || loyaltyExperiences
    ? `/${service}/v${version}/${path}`
    : `/${service}-${env.apiEnv}/v${version}/${path}`;
};
