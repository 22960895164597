import { createSelector } from 'reselect';

const checkArray = array => !!array && Array.isArray(array) && !!array.length;
const loyaltyMemberships = state => state.marketingPreferences.membership;

export const hasGetMembershipStatusInitialLoadCompleted = state =>
  state.marketingPreferences.getMembershipStatusInitialLoadComplete;

export const membershipLoading = state => state.marketingPreferences.loading;

export const membershipStatusRetrieved = createSelector(
  [membershipLoading, loyaltyMemberships],
  (isMembershipLoading, memberships) => !isMembershipLoading && memberships !== null,
);

export const isJointPartnershipMember = createSelector(
  loyaltyMemberships,
  jointMemberships =>
    checkArray(jointMemberships) &&
    jointMemberships.some(membership => membership.type === 'myjohnlewismywaitrose'),
);

export const isMyWaitroseMember = createSelector(
  loyaltyMemberships,
  jointMemberships =>
    checkArray(jointMemberships) &&
    jointMemberships.some(membership => membership.type === 'mywaitrose'),
);

export const isJointOrMyWaitroseMember = state =>
  isMyWaitroseMember(state) || isJointPartnershipMember(state);

export const getMyWaitroseCardNumber = createSelector(loyaltyMemberships, membershipDetails => {
  const myWaitroseMembership =
    checkArray(membershipDetails) &&
    membershipDetails.find(membership => membership.type === 'mywaitrose');

  return myWaitroseMembership?.number;
});

export const getJointPartnershipCardNumber = createSelector(
  loyaltyMemberships,
  membershipDetails => {
    const jointPartnershipeMembership =
      checkArray(membershipDetails) &&
      membershipDetails.find(membership => membership.type === 'myjohnlewismywaitrose');

    return jointPartnershipeMembership?.number;
  },
);

export const getMembershipCardNumber = state => {
  const memberships = loyaltyMemberships(state);
  if (!checkArray(memberships)) {
    return undefined;
  }

  return isJointPartnershipMember(state)
    ? getJointPartnershipCardNumber(state)
    : getMyWaitroseCardNumber(state);
};
