import { REMOVE_SHELF_LIFE } from '../actions/types';

const products = (state = {}, action = {}) => {
  const { type } = action;

  if (type === REMOVE_SHELF_LIFE) {
    return Object.keys(state).reduce((previous, key) => {
      const { minimumLifeToCustomer, ...rest } = state[key];

      return { ...previous, [key]: { ...rest } };
    }, {});
  }
  return state;
};

export default products;
