import { apiCallback } from 'api';

import query from './address.graphql';
import addMutation from './addAddressMutation.graphql';

const getAddresses = {
  graph: true,
  query: () => query,
  variables: ({ sortBy }) => ({
    version: 2,
    sortBy,
  }),
  queryString: { tag: 'get-addresses' },
};

const addAddress = {
  graph: true,
  query: () => addMutation,
  variables: ({
    line1,
    line2,
    line3,
    town,
    region,
    country,
    postalCode,
    addressee,
    setContactAddress,
    suggestionId,
  }) => ({
    address: {
      line1,
      line2,
      line3,
      town,
      region,
      country,
      postalCode,
      addressee,
      suggestionId,
    },
    setContactAddress,
    version: 2,
  }),
};

export default {
  get: apiCallback(getAddresses, 'post'),
  post: apiCallback(addAddress, 'post'),
};
