import { openModal } from 'redux/modules/common-modal/actions';

const getDefaultErrorModal = orderErrorType => {
  const isAddError = orderErrorType === 'ADD_GIFT_VOUCHER_ERROR';

  return {
    titleText: isAddError ? 'Gift voucher not added' : 'Gift voucher not removed',
    messageText: [
      isAddError
        ? 'An error occurred while applying your gift voucher. Please try again'
        : 'Sorry, there’s been a problem removing your gift voucher.',
    ],
    buttons: [
      {
        buttonText: 'Try again',
        primary: true,
      },
    ],
    webview: {
      id: isAddError ? 'checkout-error-5' : 'checkout-error-6',
      ctas: [null],
    },
  };
};

const getErrorModal = (error, orderErrorType) => {
  const orderErrorMessage = error?.response?.body?.message;

  switch (orderErrorMessage) {
    case 'DUPLICATE':
      return {
        titleText: 'Gift voucher already applied',
        messageText: ['This gift voucher has already been applied.'],
        webview: {
          id: 'checkout-error-gift-voucher-duplicate',
        },
      };
    case 'INVALID':
      return {
        titleText: "That doesn't look quite right",
        messageText: ['Please check the gift voucher details you entered.'],
        webview: {
          id: 'checkout-error-gift-voucher-invalid',
        },
      };
    case 'REDEEMED':
      return {
        titleText: 'Gift voucher already redeemed',
        messageText: ['This gift voucher has already been redeemed'],
        webview: {
          id: 'checkout-error-gift-voucher-redeemed',
        },
      };
    default:
      return getDefaultErrorModal(orderErrorType);
  }
};

export default error => dispatch => {
  const orderErrorType = error?.response?.body?.type;

  if (
    orderErrorType !== 'ADD_GIFT_VOUCHER_ERROR' &&
    orderErrorType !== 'REMOVE_GIFT_VOUCHER_ERROR'
  ) {
    return false;
  }

  dispatch(
    openModal({
      useIngredientsModal: true,
      ...getErrorModal(error, orderErrorType),
      isOpen: true,
      id: 'checkout-error-gift-voucher',
      severity: 'conflict',
    }),
  );

  return true;
};
