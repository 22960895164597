import getOrderSlotTypeApi from 'api/definitions/order/orderSlotType';
import {
  GET_ORDER_SLOT_TYPE_LOADED,
  GET_ORDER_SLOT_TYPE_FAILED,
} from 'redux/modules/orders/actions/types';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

const getOrderSlotType = () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
  const customerOrderId = getCustomerOrderId(getState());

  return getOrderSlotTypeApi
    .post({
      orderId: customerOrderId,
    })({ dispatch, getState })
    .then(response => {
      if (!response) {
        dispatch({
          error: 'Graph returned an error: no response',
          type: GET_ORDER_SLOT_TYPE_FAILED,
        } as any);
      } else {
        const { data, errors } = response;

        if (errors || !data?.getOrderWithRecommendations?.order) {
          // failure action
          dispatch({
            error: 'Graph returned an error: no matching order found',
            type: GET_ORDER_SLOT_TYPE_FAILED,
          } as any);
        } else {
          // success action
          dispatch({
            type: GET_ORDER_SLOT_TYPE_LOADED,
            result: {
              ...data.getOrderWithRecommendations.order,
            },
          } as any);
        }
      }
    })
    .catch(error => {
      dispatch({
        error: `Graph returned an error: ${error.message}`,
        type: GET_ORDER_SLOT_TYPE_FAILED,
      } as any);
    });
};

export default getOrderSlotType;
