const parsePromotions = (trolleyItems, type) => {
  const promotions = {};

  trolleyItems.forEach(({ lineNumber, [type]: valid = [] }) => {
    if (!valid || valid.length < 1) return null;

    valid.forEach(promotionId => {
      const promotion = promotions[promotionId];

      if (promotion) {
        promotions[promotionId].products.push(lineNumber);
      } else {
        promotions[promotionId] = {
          promotionId,
          products: [lineNumber],
        };
      }
    });

    return true;
  });

  return promotions;
};

const buildPromotions = (data = {}) => {
  const trolleyItems = data?.trolley?.trolleyItems ?? [];

  return {
    ...data,
    triggeredPromotions: parsePromotions(trolleyItems, 'triggeredPromotions'),
    untriggeredPromotions: parsePromotions(trolleyItems, 'untriggeredPromotions'),
  };
};

export default buildPromotions;
