import isPlainObject from 'lodash/isPlainObject';
/**
 * @param {string} method The HTTP method to validate.
 * @returns {bool} Validation true or false the method is an option call.
 */
const isHTTPOption = method => {
  if (!method && typeof method !== 'string') {
    return false;
  }
  const trimmed = method.replace(/\s/g, '');
  return trimmed.toLowerCase() === 'options';
};

/**
 * @param {object} headers The headers in the option call.
 * @returns {array} Array of strings, acceptable methods.
 */
const getHTTPOptions = headers => {
  if (!isPlainObject(headers)) {
    return [];
  }
  const { allow = '' } = headers;
  return allow
    .split(',')
    .map(o => o.replace(/\s/g, ''))
    .filter(x => x.length !== 0);
};

export { isHTTPOption, getHTTPOptions };
