import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { CHANGE_SLOT_DATE, CHANGE_SLOT_TYPE } from 'constants/restrictions';
import { conflictType } from 'components/Conflict/propTypes';

import ChangeSlotDate from 'components/Conflict/Resolution/ChangeSlotDate';
import ChangeSlotType from 'components/Conflict/Resolution/ChangeSlotType';

const Resolution = ({ className, conflict, conflictMessage, onPDP, textOnly }) => {
  const { resolutionActions, slotOptionDates } = conflict;
  const props = {
    className,
    conflictMessage,
    onPDP,
  };
  const shouldRender = !!resolutionActions.find(
    ({ id }) => id === CHANGE_SLOT_TYPE || id === CHANGE_SLOT_DATE,
  );

  return shouldRender ? (
    <Fragment>
      {resolutionActions
        .map(action => {
          let resolutionAction = null;

          if (action.id === CHANGE_SLOT_TYPE) {
            resolutionAction = <ChangeSlotType {...props} conflict={conflict} key={action.id} />;
          } else if (action.id === CHANGE_SLOT_DATE) {
            resolutionAction = (
              <ChangeSlotDate
                {...props}
                key={action.id}
                resolutionAction={{
                  ...action,
                  slotOptionDates, // TODO: it needs to be refactored as it is an ugly way to inject slotOptionDates
                }}
                textOnly={textOnly}
              />
            );
          }

          return resolutionAction;
        })
        .filter(elm => elm !== null)}
    </Fragment>
  ) : null;
};

Resolution.propTypes = {
  className: PropTypes.string,
  conflict: conflictType.isRequired,
  conflictMessage: PropTypes.string,
  onPDP: PropTypes.bool,
  textOnly: PropTypes.bool,
};

Resolution.defaultProps = {
  className: null,
  conflictMessage: null,
  onPDP: false,
  textOnly: false,
};

export default Resolution;
