import { connect } from 'react-redux';

import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { getCustomerSearchTerm } from 'redux/modules/search-and-browse/selectors/search';
import { setClearValue, setMobileOverlay } from 'redux/modules/page/actions';

import SiteHeader from 'components/SiteHeader/SiteHeader';

export default connect(
  state => ({
    isLoggedIn: isUserLoggedIn(state),
    clearValue: state.page.isValueClear,
    initialValue: getCustomerSearchTerm(state),
    isOverlayOpen: state.page.isMobileOverlayOpen,
  }),
  {
    setMobileOverlay,
    setClearValue,
  },
)(SiteHeader);
