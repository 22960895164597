import { createSelector } from 'reselect';

import urls from 'constants/urls';

import { pathJoin } from 'utils/general';
import { getLocation } from 'redux/modules/routing/selectors';

import { getGridItemProducts } from 'redux/modules/search-and-browse/selectors';
import { getPromotionById } from 'redux/modules/entities/selectors/promotions';
import { getSearchAndBrowseSearchType } from 'redux/modules/search-and-browse/selectors/search';

export const getCurrentPromotionPage = createSelector(
  [state => getLocation(state)?.pathname, (_state, promotion) => promotion?.promotionId],
  (pathname, promotionId) =>
    promotionId ? pathname === pathJoin(urls.offerDetails, promotionId.toString()) : false,
);

export const getOfferDetailsPromotion = createSelector(
  [getSearchAndBrowseSearchType, getGridItemProducts, getPromotionById],
  (searchType, products, promotion) =>
    searchType === 'Offer' && products.length > 0 ? promotion : undefined,
);
