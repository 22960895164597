import update from 'immutability-helper';

import { ALL_CATEGORIES_VALUE } from 'constants/facets';

import {
  APPLYFILTER,
  APPLY_CATEGORY_FILTER,
  CLEAR,
  CLEARFILTERS,
  REMOVEFILTER,
  SETFILTERGROUP,
  SORTBY,
  UPDATESEARCH,
  UPDATESEARCHTAGS,
  UPDATESEARCHTERM,
  REMOVE_SEARCHTERM_ORIGIN,
} from 'redux/modules/search/actions/types';
import { LOADED as PRODUCTS_LOADED } from 'redux/modules/search-and-browse/actions/types';

import initialState from './initial-state';

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case APPLYFILTER:
      return {
        ...state,
        queryParams: update(state.queryParams, {
          filterTags: { $push: [action.appliedFilter] },
        }),
        origin: 'refined',
        filters: {
          stickyFilter: action.stickyFilter,
        },
      };
    case APPLY_CATEGORY_FILTER:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          category: action.payload === ALL_CATEGORIES_VALUE ? null : action.payload,
        },
        origin: 'refined',
        filters: {
          stickyFilter: action.stickyFilter,
        },
      };
    case CLEAR:
      return {
        ...state,
        activeFilterGroup: '',
        queryParams: initialState.queryParams,
        searchCleared: true,
        searchRulesets: undefined,
        relevancyWeightings: undefined,
      };
    case CLEARFILTERS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filterTags: [],
          category: null,
        },
        origin: 'refined',
      };
    case PRODUCTS_LOADED: {
      const { searchRulesets, relevancyWeightings } = action.result;

      return {
        ...state,
        relevancyWeightings,
        searchRulesets,
      };
    }
    case REMOVEFILTER: {
      const appliedFilters = state.queryParams.filterTags;
      const index = appliedFilters.findIndex(filter => filter.id === action.appliedFilter.id);

      return {
        ...state,
        queryParams: update(state.queryParams, {
          filterTags: {
            $splice: [[index, 1]],
          },
        }),
        origin: 'refined',
      };
    }
    case SETFILTERGROUP:
      return {
        ...state,
        activeFilterGroup: action.groupId,
      };
    case SORTBY:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sortBy: action.order,
        },
        origin: 'refined',
        filters: {
          stickyFilter: action.stickyFilter,
        },
      };
    case UPDATESEARCH:
      return {
        ...state,
        queryParams: action.params,
        searchCleared: false,
      };
    case UPDATESEARCHTAGS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          searchTags: action.searchTags,
          searchTerm: '',
        },
      };
    case UPDATESEARCHTERM:
      return {
        ...state,
        origin: action.origin,
        queryParams: {
          ...state.queryParams,
          category: null,
          filterTags: [],
          searchTags: [],
          searchTerm: action.searchTerm,
          sortBy: '',
        },
      };
    case REMOVE_SEARCHTERM_ORIGIN:
      return {
        ...state,
        origin: undefined,
      };
    default:
      return state;
  }
}
