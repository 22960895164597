import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import formatShopLink from 'utils/format-shop-link';
import { useNavigation } from 'components/MegaMenu/NavigationProvider';

import styles from './index.scss';

const SpecialistShopLink = ({ link }) => {
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useNavigation(ref);

  return (
    <a
      className={styles.link}
      href={formatShopLink(link)}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={ref}
      rel="noopener noreferrer"
      tabIndex={tabIndex}
      target="_blank"
    >
      {link.name}
    </a>
  );
};

SpecialistShopLink.displayName = 'SpecialistShopLink';

SpecialistShopLink.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tracking: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default SpecialistShopLink;
