import createReducer from 'redux/createReducer';

import {
  postForgotPasswordActionTypes,
  RESET_EMAIL,
} from 'redux/modules/forgot-password/actions/types';
import {
  fulfilled as forgotPasswordFulfilled,
  resetEmail,
} from 'redux/modules/forgot-password/reducer/forgot-password';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  email: '',
});

const actionTypeReducerMap = new Map([
  [postForgotPasswordActionTypes.success, forgotPasswordFulfilled],
  [RESET_EMAIL, resetEmail],
]);

export default createReducer(initialState, actionTypeReducerMap);
