export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] === null || typeof obj[key] === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
};
