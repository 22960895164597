import myWaitrose from 'api/definitions/mywaitrose';

import { POST_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { setAllMarketingConsents } from 'redux/modules/marketing-preferences/actions/set-marketing-consents';
import { updateContactAddressAsync } from 'redux/modules/address/actions';
import { getContactAddressId, getAddressChosenFromModal } from 'redux/modules/address/selectors';

import historyUtils from 'utils/history';
import locator from 'utils/locator';
import urls from 'constants/urls';

export const joinMyWaitrose =
  ({ mywaitrose }: { mywaitrose: boolean }): WtrThunkAction<Promise<unknown>> =>
  async (dispatch, getState) => {
    const contactAddressId = getContactAddressId(getState());
    const addressChosenFromModal = getAddressChosenFromModal(getState());

    if (!contactAddressId) {
      return Promise.resolve();
    }

    if (addressChosenFromModal) {
      await dispatch(updateContactAddressAsync({ contactAddressId }));
    }

    await dispatch(setAllMarketingConsents({ mywaitrose }));

    await dispatch({
      types: POST_MYWAITROSE_CARD.triplet,
      apiCall: myWaitrose.post(),
    });

    historyUtils.replace(`${urls.joinMyWaitroseSuccess}${locator.search}`);

    return Promise.resolve();
  };
