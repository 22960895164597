import { apiCallback } from 'api';

export default {
  post: apiCallback(
    {
      service: 'pims',
      endpoint: 'pims/alternatives-by-ingredient',
      verbs: ['post'],
      defaultVersion: 1,
    },
    'post',
  ),
};
