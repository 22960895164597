import getExperienceFragment from 'redux/modules/experience-fragments/actions/index';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { getHasLocationsByKey } from 'redux/modules/experience-fragments/selectors/get-has-locations-by-key';
import { Fetcher } from 'route-data/fetcher-types';

// the naming of applyColumnTransformation is deliberately specific, so that it can be
// tracked back easily. Some Experience Fragments use the HoC and shouldn't have the column
// transformation applied, where as others, e.g. myWaitrose are full page and need to have
// the transformation applied.

// ideally, in the future, the column transformation will not be required, as the full width image
// issue will have been resolved with in AEM, rather than this compensation applied in the frontend.
const fetchExperienceFragment =
  (
    experienceFragmentKey: string,
    { applyColumnTransformation = false, numberOfRetries = 0, cache = false } = {},
  ): Fetcher =>
  () =>
  (dispatch, getState) => {
    const state = getState();
    const customerId = getCustomerId(state);
    const isFragmentInStore = getHasLocationsByKey(state, experienceFragmentKey);

    return isFragmentInStore
      ? null
      : dispatch(
          getExperienceFragment(
            experienceFragmentKey,
            customerId,
            applyColumnTransformation,
            numberOfRetries,
            cache,
          ),
        );
  };

export default fetchExperienceFragment;
