import { SlotType } from 'api/definitions/slot-experience/types';
import { deepFreeze } from 'utils/deepFreeze';

export interface Address {
  id: string;
  line1: string;
  line2?: string;
  line3?: string;
  postalCode: string;
  town?: string;
  region?: string;
  country?: string;
}

export interface PromoCode {
  code: string;
  description: string;
  status:
    | 'ACTIVE'
    | 'APPLIED'
    | 'UNAVAILABLE'
    | 'ALREADY_REDEEMED'
    | 'THRESHOLD_NOT_MET'
    | 'PRODUCT_NOT_IN_TROLLEY'
    | 'EXPIRED'
    | 'INACTIVE'
    | 'UNAVAILABLE_FOR_BRANCH'
    | 'PENDING_QUALIFICATION_CHECK';
  type: 'VOUCHER' | 'COUPON' | 'COMBO' | null;
  value?: { amount: number; currencyCode: string } | null;
  failures?: unknown | null | undefined;
  endDate?: string | null | undefined;
  startDate?: string | null | undefined;
  marketingCampaignId?: number | null | undefined;
  applicationMethod?: 'AUTO' | 'MANUAL' | null;
}

export interface PromoCodes {
  promoCodesList: PromoCode[];
  isAdding: boolean;
  isRemoving: boolean;
  requestedPromoCode?: unknown;
  serverError?: unknown;
}

// See:
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-order-payment/-/blob/develop/order-payment-core/src/main/java/com/waitrose/ecomm/orderpayment/core/model/GiftVoucher.java
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-order-orchestration/-/blob/develop/order-orchestration-core/src/main/java/com/waitrose/ecomm/order/orchestration/core/model/GiftVoucher.java
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-order-entity/-/blob/develop/order-entity-core/src/main/java/com/waitrose/ecomm/order/entity/core/model/GiftVoucher.java
export const GIFT_VOUCHER_STATUS_REDEEMED = 'REDEEMED' as const;
export const GIFT_VOUCHER_STATUS_ACTIVE = 'ACTIVE' as const;
export const GIFT_VOUCHER_STATUS_VALIDATED = 'VALIDATED' as const;

// See:
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-order-payment/-/blob/develop/order-payment-core/src/main/java/com/waitrose/ecomm/orderpayment/core/model/GiftVoucherDetails.java
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-payment-entity/-/blob/master/payment-entity-core/src/main/java/com/waitrose/ecomm/payment/entity/core/model/GiftVoucherDetails.java
// https://gitlab.com/JohnLewisPartnership/WTReCom/buy/buy-checkout/wtr-ecomm-payment-entity/-/blob/develop/payment-entity-endpoints/src/main/java/com/waitrose/ecomm/payment/entity/api/response/GiftVoucherDetails.java
export const GiftVoucherStatusValues = [
  // Valid states
  GIFT_VOUCHER_STATUS_ACTIVE,
  GIFT_VOUCHER_STATUS_VALIDATED, // Appears in the other GiftVoucher
  // Error states
  'NOT_MATCHED',
  'NOT_YET_ACTIVE',
  'EXPIRED',
  'DEACTIVATED',
  GIFT_VOUCHER_STATUS_REDEEMED,
  'OTHER',
] as const;

export type GiftVoucherStatus = (typeof GiftVoucherStatusValues)[number];

export interface GiftVoucher {
  amount: number;
  serialNumber: string;
  status: GiftVoucherStatus;
}

export interface Price {
  amount: number;
  currencyCode: string;
}

export interface AmendOrderPaymentCard {
  addressId: string | undefined;
  cardholderName: string | undefined;
  expiryDate: string | undefined;
  id: string | undefined;
  maskedCardNumber: string | undefined;
}

export interface GiftCard {
  serialNumber: string;
  amountToDeduct?: Price;
  remainingBalance?: Price;
}

type SlotStatus = 'UNCONFIRMED' | 'CONFIRMED' | 'EXPIRED' | 'CANCELLED';

export interface Slot {
  branchId: string;
  type: SlotType;
  startDateTime: string;
  endDateTime: string;
  amendOrderCutoffDateTime?: string;
  shopByDateTime: string;
  price: Price;
  deliveryAddress: Address;
  branchAddress: Address;
  branchName: string;
  status: SlotStatus;
}

export interface EstimatedTotals {
  orderLines?: Price;
  carrierBagCharge?: Price;
  deliveryCharge?: Price;
  depositCharge?: Price;
  totalPrice?: Price;
  incentiveSavings?: Price;
  membershipSavings?: Price;
  offerSavings?: Price;
  orderLevelSavings?: Price;
  totalSavings?: Price;
  toPay?: Price;
  paymentCard?: Price;
  giftVouchers?: Price;
  giftCards?: Price;
}

export type OrderWarning = 'ADJUSTMENT_WARNING_001';

export interface OrderState {
  amendOrderPaymentCard: AmendOrderPaymentCard;
  contactNumber?: string;
  containsEntertainingLines: boolean;
  giftCards: GiftCard[];
  giftCardToRemove?: unknown;
  giftVouchers: GiftVoucher[];
  giftVoucherToRemove?: GiftVoucher | null;
  isAddGiftCardModalOpen: boolean;
  isAddGiftVoucherModalOpen: boolean;
  loaded: boolean;
  order: {
    deliveryNote: string | undefined;
    paperStatement: boolean;
    status: string | undefined;
    totals?: {
      estimated: EstimatedTotals;
    };
  };
  promoCodes: PromoCodes;
  requestTimedOut: boolean;
  serverError?: unknown;
  sessionTimedOut: boolean;
  slots: Slot[];
  waiting: boolean;
  customerOrderId: string | undefined;
  numberOfItems?: number;
  partnerDiscountCardNumber?: string;
  orderWarnings?: OrderWarning[];
}

const initialState: Readonly<OrderState> = deepFreeze({
  amendOrderPaymentCard: {
    addressId: undefined,
    cardholderName: undefined,
    expiryDate: undefined,
    id: undefined,
    maskedCardNumber: undefined,
  },
  contactNumber: undefined,
  containsEntertainingLines: false,
  customerOrderId: undefined,
  giftCards: [],
  giftCardToRemove: null,
  giftVouchers: [],
  giftVoucherToRemove: null,
  isAddGiftCardModalOpen: false,
  isAddGiftVoucherModalOpen: false,
  loaded: false,
  order: {
    deliveryNote: undefined,
    paperStatement: false,
    status: undefined,
  },
  partnerDiscountCardNumber: undefined,
  promoCodes: {
    promoCodesList: [],
    isAdding: false,
    isRemoving: false,
    requestedPromoCode: null,
    serverError: null,
  },
  requestTimedOut: false,
  serverError: null,
  sessionTimedOut: false,
  slots: [],
  waiting: false,
  orderWarnings: undefined,
});

export default initialState;
