import { apiCallback } from 'api';
import query from 'api/definitions/orderGiftCards/index.graphql';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';

const definition = {
  graph: true,
  query: () => query,
  variables: ({ customerOrderId, giftCards }) => ({ customerOrderId, giftCards }),
  queryString: { tag: 'set-giftCards' },
  timeout: TIMEOUT_CONFIG,
};

export default {
  post: apiCallback(definition, 'post'),
};
