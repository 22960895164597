import { dayjs } from 'utils/date';
import { isNumber } from 'utils/is-number';
import { waitFor } from 'utils/settimeout-wrapper';

import { handleGenericNetworkError } from 'api/actions/handle-generic-network-error';
import { MAX_503_RETRY_DELAY } from 'constants/settings';

const retryDelayInMilliSeconds = retryAfter => {
  if (isNumber(retryAfter)) {
    return parseInt(retryAfter, 10) * 1000;
  }

  if (dayjs(retryAfter).format() === 'Invalid Date') return 0;

  const diff = dayjs(retryAfter).diff(dayjs(), 'millisecond');
  return diff < 0 ? 0 : diff;
};

export const handle503 = async ({ apiToCall, dispatch, getState, err }) => {
  const shouldRetryAfter = err.header['retry-after'];

  if (!shouldRetryAfter) {
    const error = new Error('503 with no retry-after returned');
    handleGenericNetworkError({ dispatch, error });

    throw error;
  }

  const delay = retryDelayInMilliSeconds(shouldRetryAfter);
  if (delay > MAX_503_RETRY_DELAY) {
    const error = new Error('503 with retry-after more than 30 seconds returned');
    handleGenericNetworkError({ dispatch, error });

    throw error;
  }

  try {
    await waitFor(delay);

    return apiToCall(getState);
  } catch (error) {
    handleGenericNetworkError({ dispatch, error });

    throw error;
  }
};
