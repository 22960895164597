import { createHistory } from 'utils/create-history';
import { asyncLocalStorage } from 'server/utils/async-local-storage';

let history;

if (!__SERVER__) {
  // Create singleton for client
  history = createHistory();
}

// Singleton isn't created and should never used on server, so can be undefined
export const clientHistory = history;

const getHistory = () => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.get('history'); // Should be in requestContext already
  }

  return history;
};

const getReferrer = () => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.get('referer');
  }
  return document.referrer;
};

export default {
  getLocation: () => getHistory().location,
  isWaitroseReferrer: () => new RegExp('waitrose.com').test(getReferrer()),
  push: (...args) => getHistory().push(...args),
  replace: (...args) => getHistory().replace(...args),
};
