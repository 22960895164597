import {
  ActionArg,
  CloseButtonAction,
  CustomAction,
  ErrorModal,
  ErrorModalButton,
  ErrorModals,
} from 'constants/api-error-modals/schema';
import { IOpenModalArgs, openModal } from 'redux/modules/common-modal/actions';
import { getIsAnyCommonModalOpen } from 'redux/modules/common-modal/selectors';
import { preserveStubsUrl } from 'utils/general';
import history from 'utils/history';
import locator from 'utils/locator';

export interface CustomActionResult<TAction extends string> {
  custom: CustomAction<TAction>[];
}

type ReturnTypeOrValue<T> = T extends (...args: any[]) => infer ReturnType ? ReturnType : T;

export type GetModalResultType<
  TModalType extends (() => ErrorModals<string>) | ErrorModals<string>,
> = ReturnTypeOrValue<TModalType> extends ErrorModals<infer TCustomResult> ? TCustomResult : never;

export type CustomActionResultForModalHandler<
  TModalType extends (() => ErrorModals<string>) | ErrorModals<string>,
> = CustomActionResult<GetModalResultType<TModalType>>;

function getButtonAction<TAction extends string>(
  value: string | undefined,
  closeButtonAction: CloseButtonAction<TAction> | undefined,
  buttons: ErrorModalButton<TAction>[],
) {
  if (value && typeof value === 'string') {
    return buttons[+value]?.action;
  }

  if (closeButtonAction) {
    return closeButtonAction;
  }

  return buttons.length > 0 ? buttons.find(b => b.primary)?.action ?? buttons[0].action : undefined;
}

function showModal<TAction extends string>(errorModal: ErrorModal<TAction>, dispatch: WtrDispatch) {
  const {
    id,
    severity = 'conflict',
    titleText,
    messageText,
    useIngredientsModal = true,
    cannotClose = false,
    closeButtonAction,
    buttons,
    messageIsHtml = false,
    hasAppCustomerCareButton = false,
  } = errorModal;

  const webview = errorModal.webviewId
    ? ({
        id: errorModal.webviewId,
        ctas: errorModal.buttons.map(button => button.cta ?? null),
        hasCustomerCareButton: hasAppCustomerCareButton,
      } satisfies IOpenModalArgs['webview'])
    : undefined;

  return new Promise<true | CustomActionResult<TAction>>(resolve => {
    dispatch(
      openModal({
        id,
        severity,
        titleText,
        messageText,
        cannotClose,
        useIngredientsModal,
        messageIsHtml,
        buttons: buttons.map(({ buttonText, primary, secondary }, index) => ({
          buttonText,
          primary,
          secondary,
          buttonValue: index.toString(),
        })),
        webview,
        onCloseCallback: value => {
          const action = getButtonAction<TAction>(value, closeButtonAction, buttons);

          if (action) {
            if (action === 'reload') {
              locator.reload();
            } else if (action !== 'none') {
              if ('navigateTo' in action) {
                history.push(preserveStubsUrl(action.navigateTo));
              } else if ('custom' in action) {
                resolve(action);
                return;
              }
            }
          }

          resolve(true);
        },
      }),
    );
  });
}

export function openErrorModal<TAction extends string>(
  errorModals: ErrorModals<TAction>,
  httpStatus: number | null | undefined,
  errorCode: string | null | undefined,
): WtrThunkAction<Promise<boolean | CustomActionResult<TAction>>> {
  return (dispatch, getState) => {
    if (getIsAnyCommonModalOpen(getState() as { modal: unknown })) {
      return Promise.resolve(false);
    }
    const foundModal = errorModals.errorMap.find(modal => {
      // if the modal has httpStatuses, will check the httpStatus
      // if no httpStatus is missing, or not present in the array, skip this modal
      // else will check the errorCodes
      if (
        modal.httpStatuses &&
        modal.httpStatuses.length > 0 &&
        (!httpStatus || modal.httpStatuses.indexOf(httpStatus) === -1)
      ) {
        return false;
      }

      // if the error codes have any item
      // if no errorCode is missing, or not present in the array, skip this modal
      if (
        modal.errorCodes &&
        modal.errorCodes.length > 0 &&
        (!errorCode || modal.errorCodes.indexOf(errorCode) === -1)
      ) {
        return false;
      }

      // if it reached here, it means that:
      // - either no httpStatuses was missing from the modal, or it was found in the array
      // - either the errorCodes array was empty or the provided code was found in the array
      // This means that this modal matches all the conditions
      return true;
    });

    return showModal(foundModal ?? errorModals.defaultModal, dispatch);
  };
}

export interface ResponseError {
  status: number;
  response?: {
    body?: {
      code?: string;
    };
  };
}

function getResponseStatusAndCode(errorResponse: unknown): [number, string | null] | null {
  if (!errorResponse || typeof errorResponse !== 'object') return null;

  if ('status' in errorResponse && typeof errorResponse.status === 'number') {
    return [errorResponse.status, (errorResponse as ResponseError)?.response?.body?.code ?? null];
  }

  // Special case for timeout
  if ('timeout' in errorResponse && typeof errorResponse.timeout === 'number') {
    return [0, 'TIMEOUT'];
  }

  return null;
}

export function openErrorModalForResponse<TAction extends string>(
  errorModals: ErrorModals<TAction>,
  errorResponse: unknown | ResponseError,
): WtrThunkAction<Promise<boolean | CustomActionResult<TAction>>> {
  return (dispatch, getState) => {
    const statusAndCode = getResponseStatusAndCode(errorResponse);
    if (statusAndCode) {
      const [status, code] = statusAndCode;

      if (typeof status === 'number' && (!code || typeof code === 'string')) {
        return openErrorModal(errorModals, status, code)(dispatch, getState);
      }
    }
    return openErrorModal(errorModals, null, null)(dispatch, getState);
  };
}

export function reduceCustomActions<
  TModalType extends (() => ErrorModals<string>) | ErrorModals<string>,
>(
  modalResult: boolean | CustomActionResultForModalHandler<TModalType>,
  actions: Record<GetModalResultType<TModalType>, (args?: ActionArg[]) => void | Promise<void>>,
) {
  if (typeof modalResult === 'object') {
    return modalResult.custom.reduce(
      (previousPromise, custom) =>
        previousPromise.then(() => {
          const customActionResult = actions[custom.action](custom.args);
          return Promise.resolve(customActionResult);
        }),
      Promise.resolve(),
    );
  }

  return Promise.resolve();
}
