import get from 'lodash/get';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { removeDiacriticsFromLetters } from 'utils/format';

import compareLowerCase from 'utils/compare-lower-case';

export const getSearch = state => get(state, 'router.location.search', '');

export const getJotterValues = state => state.multisearch.jotterValues;

export const getMultiSearchCurrentValue = createSelector(
  [getSearch],
  search => queryString.parse(search).value || '',
);

export const getMultiSearchValues = state => state.multisearch.values;

export const getValueIsOrphan = createSelector(
  [getMultiSearchCurrentValue, getMultiSearchValues],
  (current, values) =>
    !(
      values && values.some(value => compareLowerCase(value, removeDiacriticsFromLetters(current)))
    ),
);
