import { bool, shape, string } from 'prop-types';

const schema = {
  line1: string.isRequired,
  line2: string,
  line3: string,
  town: string,
  region: string,
  country: string.isRequired,
  postalCode: string.isRequired,
  addressee: shape({
    title: string,
    firstName: string,
    lastName: string,
    contactAddressId: string,
    contactNumber: string,
  }),
  created: string,
  addressStatus: string,
  isContactAddress: bool,
};

const flatSchema = {
  line1: string,
  line2: string,
  line3: string,
  town: string,
  region: string,
  country: string,
  postalCode: string,
  title: string,
  firstName: string,
  lastName: string,
  contactAddressId: string,
  contactNumber: string,
  created: string,
  addressStatus: string,
  isContactAddress: bool,
};

const notRequiredAddressSchema = {
  line1: string,
  line2: string,
  line3: string,
  town: string,
  region: string,
  country: string,
  postalCode: string,
  addressee: shape({
    title: string,
    firstName: string,
    lastName: string,
    contactAddressId: string,
    contactNumber: string,
  }),
  created: string,
  addressStatus: string,
  isContactAddress: bool,
};

export { schema, flatSchema, notRequiredAddressSchema };
