import { processResult } from './process-result';

export default ({ dispatch }) =>
  next =>
  action => {
    const { result, schema, transform, ...rest } = action;
    const processedResult =
      result === undefined ? undefined : processResult({ result, dispatch, schema, transform });

    return next({ result: processedResult, ...rest });
  };
