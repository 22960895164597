import { getActiveAddresses, addressApiHasErrored } from 'redux/modules/address/selectors';

// TODO: [SSR][WARN] Does this impact SSR? Can this be updated to CSS only, i.e. do the props need to live here? Can the data be passed to the component and rendered there...?
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointDown } from 'utils/mediaQueries';

const getAddressBookState = state => state.addresses.addressBook;

const getAddressBookProps = state => {
  const foundAddresses = getActiveAddresses(state);
  const { collapsed } = getAddressBookState(state);
  const hasError = addressApiHasErrored(state);

  const activeAddresses =
    foundAddresses.length > 0 ? foundAddresses.map(a => ({ ...a, selected: false })) : [];
  const addressCount = activeAddresses.length;

  const collapsedAddresses = activeAddresses.slice(0, mediaBreakpointDown('md') ? 1 : 2);
  const addresses = activeAddresses.slice(mediaBreakpointDown('md') ? 1 : 2, addressCount);
  const showAddressBookList = addressCount !== 0;

  if (mediaBreakpointDown('sm') && addressCount <= 1) {
    return {
      hasError,
      addressCount,
      addresses,
      collapsed,
      collapsedAddresses,
      hideExpandCollapse: true,
      showAddressBookList,
    };
  }

  if (!mediaBreakpointDown('sm') && addressCount <= 2) {
    return {
      hasError,
      addressCount,
      addresses,
      collapsed,
      collapsedAddresses,
      hideExpandCollapse: true,
      showAddressBookList,
    };
  }

  return {
    hasError,
    addressCount,
    addresses,
    collapsed,
    collapsedAddresses,
    hideExpandCollapse: false,
    showAddressBookList,
  };
};

export { getAddressBookProps };
