export const dataAttributesGenerator = (props = {}) => ({
  ...Object.keys(props)
    .map(prop => ({
      [`data-${prop.toLowerCase()}`]: props[prop],
    }))
    .reduce((result, item) => {
      const key = Object.keys(item)[0];

      return {
        ...result,
        [key]: item[key],
      };
    }, {}),
});
