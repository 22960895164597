import { AMEND_FOUND_ADDRESSEE } from 'redux/modules/address-suggestion/actions/types';

const amendFoundAddressee = (state, action) => {
  const { addressee = {} } = action;
  const { address = {} } = state;

  return {
    ...state,
    address: {
      ...address,
      addressee,
      suggestionId: address.id,
    },
  };
};

export default map => {
  map.set(AMEND_FOUND_ADDRESSEE, amendFoundAddressee);
  return map;
};
