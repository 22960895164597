import { apiCallback } from 'api';

import query from 'api/definitions/slot/current-slot.graphql';

const definition = {
  graph: true,
  query: () => query,
  variables: ({ customerOrderId, customerId }) => ({
    currentSlotInput: {
      customerOrderId,
      customerId,
    },
  }),
  queryString: { tag: 'current-slot' },
};

export default {
  getCurrentSlot: apiCallback(definition, 'post'),
};
