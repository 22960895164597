import {
  SET_CONTACT_ADDRESS,
  SET_SELECTED_ADDRESS,
  SET_CHOOSEN_ADDRESS,
} from 'redux/modules/address/actions/types';

import { Address } from 'redux/modules/address/state/initial-state';

export const setSelectedAddress = (address: Address) => ({
  type: SET_SELECTED_ADDRESS,
  selectedAddress: address,
});

export const setChoosenAddress = (address: Address | null) => ({
  type: SET_CHOOSEN_ADDRESS,
  address,
});

export const setContactAddress = (address: Address) => ({
  type: SET_CONTACT_ADDRESS,
  address,
});
