import {
  SUB_ALL_UPDATING,
  SUB_ALL_UPDATED,
  SUB_ALL_UPDATE_FAILED,
} from 'redux/modules/trolley/actions/types';

import trolley from 'api/definitions/trolley';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

export const toggleSubAll = subPreference => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SUB_ALL_UPDATING });
  return trolley
    .updateTrolleySubPreference({
      orderId: getCustomerOrderId(state),
      subPreference,
    })({ dispatch, getState })
    .then(response => {
      const { data, errors } = response;
      const failures = data?.updateTrolleySubPreferences?.failures || [];

      if (errors || failures.length > 0) {
        let error = new Error('Graph call succeeded but returned no data or an unknown error');
        dispatch({
          error: 'Graph call succeeded but returned no data or an unknown error',
          type: SUB_ALL_UPDATE_FAILED,
        });

        if (errors?.length) {
          [error] = errors;
        }

        if (failures.length > 0) {
          [error] = failures;
        }
        return handleUpdateTrolleyErrors(dispatch, getState)(error);
      }

      return dispatch({
        type: SUB_ALL_UPDATED,
        allowSub: subPreference,
      });
    })
    .catch(err => {
      dispatch({
        error: 'Update trolley sub preferences call to graph failed',
        type: SUB_ALL_UPDATE_FAILED,
      });
      return handleUpdateTrolleyErrors(dispatch, getState)(err);
    });
};
