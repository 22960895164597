import { BundleResponse, BundleThresholds } from 'constants/bundle';
import { LegacyProductReference } from 'constants/products';
import mapProductsIntoLegacyFormat from './transform-product-into-legacy-format';
import { TransformedCriteria, TransformedConflict } from './types';

export interface TransformedBundleProductCollection<
  BundleFolder extends string,
  BundleLine extends string = string,
> {
  id: `${BundleFolder}/${BundleLine}`;
  conflicts: TransformedConflict[];
  criteria: TransformedCriteria[];
  products: LegacyProductReference[];
  thresholds: BundleThresholds;
  title: string;
  totalMatches: number;
}

export interface TransformedBundleResponse<BundleFolder extends string = string> {
  bundleFolder: BundleFolder;
  productCollections: TransformedBundleProductCollection<BundleFolder>[];
  title: string;
}

const transformBundleProductsIntoLegacyFormat = <BundleFolder extends string>(
  bundleResponse: BundleResponse<BundleFolder>,
): TransformedBundleResponse<BundleFolder> => {
  const productCollections = bundleResponse.productCollections.map(productCollection =>
    mapProductsIntoLegacyFormat(productCollection),
  ) as TransformedBundleProductCollection<BundleFolder>[];

  return {
    ...bundleResponse,
    productCollections,
  };
};

export default transformBundleProductsIntoLegacyFormat;
