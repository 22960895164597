import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import modalButton from 'constants/data-shapes/modal-button';
import { modalButtonOrientation } from 'constants/modal-button-orientation';
import { ModalCloseOptions } from 'constants/modalCloseOptions';

import { useResponse } from 'components/ResponseProvider';
import ModalCloseButton from 'components/wdx/buttons/ModalCloseButton';
import Buttons from 'components/Modal/Buttons/Buttons';
import FocusTrap from 'components/FocusTrap';
import MessageText from './MessageText';

import styles from './Modal.scss';

const Modal = ({
  asideText,
  buttonAutoFocus,
  buttonOrientation,
  buttons,
  cannotClose,
  children,
  closeModal,
  dataTest,
  flexButtons,
  isOpen,
  messageIsHtml,
  messageText,
  messageTextParams,
  responseStatus,
  titleText,
  webViewId,
}) => {
  const { setStatus } = useResponse();
  if (responseStatus) setStatus(responseStatus);

  const applyWebviewId = useCallback(
    ref => {
      if (!ref) return;
      if (webViewId) ref.setAttribute('data-webviewid', webViewId);
      else ref.removeAttribute('data-webviewid');
    },
    [webViewId],
  );

  return (
    <ReactModal
      className={styles.modal}
      contentLabel={titleText}
      overlayRef={applyWebviewId}
      isOpen={isOpen}
      onRequestClose={() => {
        if (cannotClose) return;
        closeModal(ModalCloseOptions.CANCEL);
      }}
      overlayClassName={{
        afterOpen: `${styles.modalOverlayOpen} ReactModal__Overlay`,
        base: styles.modalOverlay,
        beforeClose: '', // passing it as it is required in react-module package
      }}
      bodyOpenClassName={styles.modalBodyNoScroll}
      shouldCloseOnOverlayClick={!cannotClose}
      aria={{
        ...((children || messageText) && { describedby: 'modalDescription' }),
      }}
      preventScroll
    >
      <FocusTrap>
        <section className={styles.modalInnerContent} data-test={dataTest} data-webviewid="popup">
          <h3>{titleText}</h3>
          {!cannotClose && <ModalCloseButton close={closeModal} disabled={cannotClose} />}
          <section id="modalDescription" className={styles.modalText}>
            <MessageText isHtml={messageIsHtml} params={messageTextParams} text={messageText} />
            {children}
          </section>
          {!!buttons.length && (
            <Buttons
              {...{ buttonAutoFocus, buttonOrientation, buttons, flexButtons, closeModal }}
            />
          )}
          {asideText && <aside className={styles.asideText}>{asideText}</aside>}
        </section>
      </FocusTrap>
    </ReactModal>
  );
};

Modal.propTypes = {
  asideText: PropTypes.string,
  buttonAutoFocus: PropTypes.bool,
  buttonOrientation: PropTypes.oneOf([
    modalButtonOrientation.HORIZONTAL,
    modalButtonOrientation.VERTICAL,
  ]),
  buttons: PropTypes.arrayOf(modalButton()),
  cannotClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  closeModal: PropTypes.func,
  dataTest: PropTypes.string,
  flexButtons: PropTypes.bool,
  isOpen: PropTypes.bool,
  messageIsHtml: PropTypes.bool,
  messageText: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  messageTextParams: PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  }),
  responseStatus: PropTypes.number,
  titleText: PropTypes.string,
  webViewId: PropTypes.string,
};

Modal.defaultProps = {
  asideText: null,
  buttonAutoFocus: true,
  buttonOrientation: modalButtonOrientation.HORIZONTAL,
  buttons: [],
  cannotClose: false,
  children: null,
  closeModal: () => {},
  dataTest: null,
  flexButtons: false,
  isOpen: false,
  messageIsHtml: false,
  messageText: [],
  messageTextParams: null,
  responseStatus: null,
  titleText: null,
  webViewId: null,
};

export default Modal;
