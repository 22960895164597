import { matchPath } from 'react-router-dom';

import { rootPath } from 'utils/root-path';
import { getNavigationHistory } from 'redux/modules/page/selectors/get-navigation-history';

const PRODUCT_DETAILS_ROUTE = rootPath('/products/:productName/:productId');

export const getWasProductDetour = state => {
  const [current, previous] = getNavigationHistory(state);

  return !!(
    previous &&
    current.action === 'POP' &&
    previous.action === 'PUSH' &&
    matchPath({ path: PRODUCT_DETAILS_ROUTE }, previous.pathname)
  );
};
