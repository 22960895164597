import { createSelector } from 'reselect';

import urls from 'constants/urls';

import historyUtil from 'utils/history';
import { inTrolleyPage } from 'redux/modules/checkout/utils/in-trolley-page';

import { getMinimumSpendThresholdMet } from 'redux/modules/trolley/selectors/get-totals';
import { hasAnyHardTrolleyConflicts } from 'redux/modules/conflicts/selectors';
import { hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import { isInterstitials } from 'redux/modules/interstitials/selectors';

export const CHECKOUT_CONDITIONS = {
  HAS_HARD_CONFLICTS: 'HAS_HARD_CONFLICTS',
  MINIMUM_SPEND_NOT_MET: 'MINIMUM_SPEND_NOT_MET',
};

export const checkoutDestination = state => {
  const queryParams = historyUtil.getLocation().search;

  if (!inTrolleyPage() && hasAnyHardTrolleyConflicts(state)) {
    return urls.trolleyPage;
  }

  if (!hasBookedSlot(state)) {
    return urls.serviceSelection;
  }

  if (!isInterstitials()) {
    return `${urls.interstitials}${queryParams || ''}`;
  }

  return urls.checkout;
};

export const checkoutConditions = createSelector(
  [inTrolleyPage, hasAnyHardTrolleyConflicts, getMinimumSpendThresholdMet],
  (isInTrolleyPage, hasConflicts, minimumSpendThresholdMet) => [
    ...(isInTrolleyPage && hasConflicts ? [CHECKOUT_CONDITIONS.HAS_HARD_CONFLICTS] : []),
    ...(!minimumSpendThresholdMet ? [CHECKOUT_CONDITIONS.MINIMUM_SPEND_NOT_MET] : []),
  ],
);

export const checkoutDisabled = state => checkoutConditions(state).length > 0;
