const substitutionsLoaded = (
  _state,
  { result: { substitutions }, analytics: { lineNumber, origin } },
) => ({
  event: 'swap_modal_loaded',
  ...(origin === 'next_item_btn' && { action: 'Next Item clicked' }),
  unavailable: {
    product_id: lineNumber,
  },
  swap_alternatives_available: substitutions.length > 0 ? 'yes' : 'no',
  product_impressions: substitutions.map(sub => ({
    id: sub.lineNumber,
  })),
});

export default substitutionsLoaded;
