import urls from 'constants/urls';

import setCurrentPageDepthFromPathname from 'redux/modules/page/actions/set-page-depth-using-pathname';

export default ({ location: { pathname }, store: { dispatch } }) => {
  if (pathname.startsWith(urls.browse)) {
    dispatch(setCurrentPageDepthFromPathname(pathname));
  }
  return true;
};
