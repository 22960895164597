export default state => {
  const { pathname } = state.router.location;
  const filterEvent = pathname.includes('browse') ? 'browse_filter' : 'search_filter';

  const filters = state.search.queryParams.filterTags;
  const latestFilter = filters[filters.length - 1];
  const { stickyFilter } = state.search.filters || {};

  return {
    event: filterEvent,
    filter_label: latestFilter.group,
    filter_selection: latestFilter.text,
    filter_location: stickyFilter ? 'sticky' : 'normal',
    filter_version: 'new',
  };
};
