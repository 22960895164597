import { AlertPage, AlertPosition } from 'api/definitions/slot-experience/types';
import { deepFreeze } from 'utils/deepFreeze';

export enum AlertTypes {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export type SlotExperienceAlert = {
  alertPage: AlertPage;
  alertPosition: AlertPosition;
  alertType: AlertTypes;
  id: string;
  richTextMessage: Record<string, unknown>;
  title: Record<string, unknown>;
};

export interface SlotExperienceState {
  alerts: SlotExperienceAlert[];
}

const initialState: Readonly<SlotExperienceState> = deepFreeze({
  alerts: [],
});

export { AlertPage, AlertPosition } from 'api/definitions/slot-experience/types';
export default initialState;
