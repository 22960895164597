import { forceReloadOnCheckoutFactory } from './force-reload-on-checkout';

// The contract for an "action factory" is as follows:
//
// - it receives the react-router location object and store
// - it returns null or thunk (arg-less effect fn) to be called after render (in useEffect)
//
// The "makeActions" helper calls all factories and returns not null results
// It is used to get effect fns during rendering RouteChange component
// If the returned array contains effects then the render is blocked (returns null)
//
// IMPORTANT: factory fn must be pure function; if you need to call effect use "action" (see actions.js)

const actionFactories = [forceReloadOnCheckoutFactory];

export const makeActions = (...args) =>
  actionFactories.map(factory => factory(...args)).filter(Boolean);
