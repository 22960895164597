import createReducer from 'redux/createReducer';

import {
  CUSTOMER_ADDRESS_REQUEST,
  CUSTOMER_ADDRESS_REQUEST_SUCCESS,
  CUSTOMER_ADDRESS_REQUEST_FAIL,
} from 'redux/modules/customer-address/actions/types';
import { GET_BY_ID } from 'redux/modules/address/actions/types';

import customerAddressesReceived from 'redux/modules/customer-address/reducers/customer-addresses-received';
import customerAddressesRequestFailed from 'redux/modules/customer-address/reducers/customer-addresses-request-failed';
import customerAddressReceivedById from 'redux/modules/customer-address/reducers/customer-address-received-by-id';
import requestCustomerAddresses from 'redux/modules/customer-address/reducers/request-customer-addresses';

import initialState from '../state/initial-state';

const actionTypeReducerMap = [
  [CUSTOMER_ADDRESS_REQUEST, requestCustomerAddresses],
  [CUSTOMER_ADDRESS_REQUEST_SUCCESS, customerAddressesReceived],
  [CUSTOMER_ADDRESS_REQUEST_FAIL, customerAddressesRequestFailed],
  [GET_BY_ID.success, customerAddressReceivedById],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
