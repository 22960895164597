import termSuggest from 'api/definitions/termSuggest';
import debounce from 'lodash/debounce';

import {
  AUTOSUGGEST_FULFILLED,
  AUTOSUGGEST_PENDING,
  AUTOSUGGEST_REJECTED,
} from 'redux/modules/auto-suggest/actions/types';

export const autoSuggestAction = (searchTerm: string) => {
  const term = searchTerm.trim();

  return term.length
    ? {
        types: [AUTOSUGGEST_PENDING, AUTOSUGGEST_FULFILLED, AUTOSUGGEST_REJECTED] as const,
        searchTerm,
        apiCall: termSuggest.get({
          term,
          iHandleStatusCodes: true,
        }),
        callback: {
          failure: () => {},
        },
      }
    : null;
};

const autoSuggestSearchDebouncer = debounce(
  (dispatch: WtrDispatch, searchTerm: string) => {
    const action = autoSuggestAction(searchTerm);

    if (action) dispatch(action);
  },
  300,
  { leading: true, maxWait: 600 },
);

export default (searchTerm: string) => (dispatch: WtrDispatch) =>
  autoSuggestSearchDebouncer(dispatch, searchTerm);
