import {
  ADD_ALL_ITEMS_PENDING,
  ADD_ALL_ITEMS_FULFILLED,
  ADD_ALL_ITEMS_REJECTED,
} from 'redux/modules/add-all-items/actions/types';
import {
  CLEAR_TROLLEY_ERRORS,
  ITEMS_SUCCESSFULLY_ADDED,
} from 'redux/modules/trolley-errors/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  successfulCount: 0,
});

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export default function reducer(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case ADD_ALL_ITEMS_PENDING:
      return {
        ...state,
        successfulCount: 0,
        updatingStatus: PENDING,
      };
    case CLEAR_TROLLEY_ERRORS:
      return {
        ...state,
        successfulCount: 0,
        updatingStatus: null,
      };
    case ADD_ALL_ITEMS_REJECTED:
      return {
        ...state,
        updatingStatus: REJECTED,
      };
    case ADD_ALL_ITEMS_FULFILLED:
      return {
        ...state,
        updatingStatus: FULFILLED,
      };
    case ITEMS_SUCCESSFULLY_ADDED: {
      const { payload: { count: successfulCount } = {} } = action;

      return {
        ...state,
        successfulCount,
      };
    }
    default:
      return state;
  }
}
