import { matchPath } from 'react-router-dom';
import { routes } from 'route-data/routes';

export const findRouteMatch = pathname => {
  let found;
  for (let index = 0; index < routes.length; index += 1) {
    const { fetch, clientFetch, path, preload } = routes[index];

    const match = matchPath(
      {
        path,
      },
      pathname,
    );

    if (match) {
      found = {
        fetch,
        clientFetch,
        index,
        match,
        preload,
      };
      break;
    }
  }
  return found;
};
