import { openModal } from 'redux/modules/common-modal/actions';

const showErrorModalValueReachedGiftItems = () =>
  openModal({
    id: 'checkout-order-total-covered',
    severity: 'info',
    messageText: ['Your gift vouchers or gift cards exceed the estimated order total.'],
    titleText: 'Estimated order total covered',
    webview: {
      id: 'checkout-error-order-total-covered',
    },
  });

export default showErrorModalValueReachedGiftItems;
