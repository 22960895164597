import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';

import { openModal } from 'redux/modules/common-modal/actions';

import locator from 'utils/locator';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  if (status === 409 && code === 'ORDER_ORCHESTRATION_044') {
    const onCloseCallback = () => {
      locator.href = urls.serviceSelection;
    };

    dispatch(
      openModal({
        id: 'checkout-error-slot-confirmation',
        severity: 'conflict',
        buttons: [
          {
            buttonText: 'Book a slot',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
        ],
        cannotClose: true,
        messageText: ['These issues are usually temporary. Please try again.'],
        titleText: 'Sorry, there was a problem confirming your slot',
        webview: {
          id: 'checkout-error-14',
          ctas: [urls.serviceSelection],
        },
        useIngredientsModal: false,
        onCloseCallback,
      }),
    );

    return true;
  }

  return false;
};
