import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const GET_INCENTIVES = apiPromiseTypes('incentive-offers', 'get');

export const GET_INCENTIVES_PRODUCTS_REQUEST =
  'waitrose/incentives/GET_INCENTIVES_PRODUCTS_REQUEST' as const;
export const GET_INCENTIVES_PRODUCTS_SUCCESS =
  'waitrose/incentives/GET_INCENTIVES_PRODUCTS_SUCCESS' as const;
export const GET_INCENTIVES_PRODUCTS_FAILURE =
  'waitrose/incentives/GET_INCENTIVES_PRODUCTS_FAILURE' as const;
export const GET_INCENTIVES_PRODUCTS = [
  GET_INCENTIVES_PRODUCTS_REQUEST,
  GET_INCENTIVES_PRODUCTS_SUCCESS,
  GET_INCENTIVES_PRODUCTS_FAILURE,
] as const;

export const SET_INCENTIVE_LOADING_STATUS =
  'waitrose/incentives/SET_INCENTIVE_LOADING_STATUS' as const;
export const SET_INCENTIVE_UNLOADING_STATUS =
  'waitrose/incentives/SET_INCENTIVE_UNLOADING_STATUS' as const;
export const LOAD_INCENTIVE = apiPromiseTypes('incentive-offer-load', 'patch');
export const UNLOAD_INCENTIVE = apiPromiseTypes('incentive-offer-unload', 'patch');
