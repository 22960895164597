import { Action } from 'redux';
import { RecipeSummaryItem, RecipeFilter } from 'api/definitions/recipes/index.d';
import { MealPlannerState } from '../index.d';
import { MEAL_PLANNER_SUCCESS, MEAL_PLANNER_FAILURE, MEAL_PLANNER_UPDATE } from '../actions/types';

const SERVING_SIZE_FILTER_TITLE = 'Serving Size';
const DIETARY_REQUIREMENTS_FILTER_TITLE = 'Dietary';

const initialState: MealPlannerState = Object.freeze({
  error: false,
  loading: false,
  recipes: new Array(7).fill(undefined),
  servingSizes: [],
  dietaryRequirements: [],
  fewMatches: false,
});

const isType = <T>(x: T) => x;

type MealPlannerSuccessAction = Action & {
  result: {
    recipes: RecipeSummaryItem[];
    filters: RecipeFilter[];
  };
};

export type MealPlannerUpdateAction = Action & {
  recipes: RecipeSummaryItem[];
};

export default (
  state = initialState,
  action: Action | MealPlannerSuccessAction | MealPlannerUpdateAction,
) => {
  switch (action.type) {
    case MEAL_PLANNER_SUCCESS: {
      const result = (action as MealPlannerSuccessAction).result || {};
      const filters = result.filters || [];

      return isType<MealPlannerState>({
        ...state,
        fewMatches: result.recipes.length < 4,
        recipes: result.recipes.concat(Array(7 - result.recipes.length).fill(undefined)),
        servingSizes:
          filters?.find((filter: RecipeFilter) => filter.title === SERVING_SIZE_FILTER_TITLE)
            ?.values || [],
        dietaryRequirements:
          filters?.find(
            (filter: RecipeFilter) => filter.title === DIETARY_REQUIREMENTS_FILTER_TITLE,
          )?.values || [],
      });
    }

    case MEAL_PLANNER_FAILURE: {
      return isType<MealPlannerState>({
        ...state,
        error: true,
        loading: false,
      });
    }

    case MEAL_PLANNER_UPDATE: {
      return isType<MealPlannerState>({
        ...state,
        fewMatches: (action as MealPlannerUpdateAction).recipes.length < 4,
        recipes: (action as MealPlannerUpdateAction).recipes,
      });
    }

    default:
      return state;
  }
};
