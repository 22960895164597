import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import modalButton from 'constants/data-shapes/modal-button';
import { modalButtonOrientation } from 'constants/modal-button-orientation';
import { Link } from 'react-router-dom';

import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from 'components/Modal/Modal.scss';

const Buttons = ({ buttonAutoFocus, buttonOrientation, buttons, flexButtons, closeModal }) => {
  const verticalButtons = buttonOrientation === modalButtonOrientation.VERTICAL;
  const autoFocus = buttonAutoFocus;

  return (
    <ul
      className={classNames(styles.buttons, {
        [styles.flex]: flexButtons,
        [styles.vertical]: verticalButtons,
      })}
    >
      {buttons.map(
        (
          {
            accessibleText,
            buttonText: label = undefined,
            buttonValue,
            className,
            disabled,
            primary,
            secondary,
            theme,
            to,
            type,
          },
          index,
        ) => {
          const props = {
            'aria-label': accessibleText,
            autoFocus,
            disabled,
            label,
            onClick: () => closeModal(buttonValue),
            theme:
              theme ||
              (!primary && type !== 'text' && (secondary || index > 0) ? 'secondary' : 'primary'),
            width: verticalButtons ? 'full' : 'standard',
          };

          let button = <Button {...props} />;

          if (type === 'text') {
            button = <TextLink component="button" {...props} />;
          }

          if (to) {
            button = <LinkAsButton {...props} to={to} component={Link} />;
          }

          return (
            <li className={className} key={`${label}:${buttonValue}:${type}`}>
              {button}
            </li>
          );
        },
      )}
    </ul>
  );
};

Buttons.propTypes = {
  buttonAutoFocus: PropTypes.bool,
  buttonOrientation: PropTypes.oneOf([
    modalButtonOrientation.HORIZONTAL,
    modalButtonOrientation.VERTICAL,
  ]),
  buttons: PropTypes.arrayOf(modalButton()),
  closeModal: PropTypes.func,
  flexButtons: PropTypes.bool,
};

Buttons.defaultProps = {
  buttonAutoFocus: true,
  buttonOrientation: modalButtonOrientation.HORIZONTAL,
  buttons: [],
  closeModal: () => {},
  flexButtons: false,
};

export default Buttons;
