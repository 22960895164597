const BRAND = 'BRAND';
const CATEGORY = 'childCategories';
const COOKING_STATUS = 'COOKING_STATUS';
const DIETARY = 'DIETARY_AND_LIFESTYLE';
const LEAD_TIME = 'LEADTIME';
const OFFER = 'OFFER_TYPE';
const SEARCH_TAG = 'suggestedSearchTags';
const SERVING_SIZE = 'SERVING_SIZE';

export default {
  BRAND,
  CATEGORY,
  COOKING_STATUS,
  DIETARY,
  LEAD_TIME,
  OFFER,
  SEARCH_TAG,
  SERVING_SIZE,
};
