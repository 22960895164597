import { combineReducers } from 'redux';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { MERGE_ENTITIES } from 'redux/modules/entities/actions/types';
import { merge } from 'redux/modules/entities/reducer/merge';
import customerOrders from 'redux/modules/entities/reducer/customer-orders';
import orders from 'redux/modules/entities/reducer/orders';
import products from 'redux/modules/entities/reducer/products';
import promotions from 'redux/modules/entities/reducer/promotions';
import shoppingLists from 'redux/modules/entities/reducer/shopping-lists';
import productConflicts from 'redux/modules/conflicts/reducer';

// Add new normalised entity types here with either real
// or null reducer such as this: (state = {}) => state;
const entityReducers = combineReducers({
  customerOrders,
  orders,
  products,
  promotions,
  shoppingLists,
  productConflicts,
});

export default function entitiesReducer(state = {}, action = {}) {
  if (action.type === MERGE_ENTITIES) {
    const { products: productsObj } = action.entities;

    Object.keys(productsObj || []).forEach(product => {
      if (!productsObj[product].lineNumber) {
        productsObj[product].lineNumber = extractLineNumberFromSku(productsObj[product].id);
      }
    });

    return merge(state, action.entities);
  }
  return entityReducers(state, action);
}
