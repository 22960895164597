import { createSelector } from 'reselect';
import { getOrder } from './index';

export const getPartnerDiscountCardNumber = createSelector(
  getOrder,
  order => order.partnerDiscountCardNumber,
);

export const hasPartnerDiscount = createSelector(
  getPartnerDiscountCardNumber,
  partnerDiscountCardNumber => !!partnerDiscountCardNumber,
);
