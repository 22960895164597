import { local as storage } from 'utils/storage';
import { getCustomerId } from 'redux/modules/sessions/selectors';

export const getBrokenTrolleyObjectFromStorage = () => {
  const brokenTrolley = storage.get('brokenTrolley');

  if (brokenTrolley) {
    return JSON.parse(brokenTrolley);
  }

  return null;
};

export const brokenTrolleyCustomerIdMatches = state => {
  const brokenTrolley = getBrokenTrolleyObjectFromStorage();

  if (brokenTrolley) {
    return brokenTrolley.customerId === getCustomerId(state);
  }

  return false;
};
