import React from 'react';
import { bool, number, string } from 'prop-types';

import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import TrolleySize from 'components/Trolley/TrolleySize';
import styles from './TrolleyButtonContents.scss';

const TrolleyButtonContents = ({ estimatedCost, loadingStatus, totalNumberOfItems }) => (
  <div className={styles.buttonWrapper} data-trolley-loading={loadingStatus} aria-hidden="true">
    {loadingStatus && (
      <div className={styles.spinnerOverlay}>
        <Spinner isActive size="small" />
      </div>
    )}
    <span className={styles.trolleySizeWrapper}>
      <TrolleySize size={totalNumberOfItems} dataTestId="shopping-summary-total-item" />
    </span>
    <span className={styles.totalText}>Total</span>
    <span className={styles.pricing} data-testid="total-price-overview">
      {estimatedCost}
    </span>
  </div>
);

TrolleyButtonContents.propTypes = {
  estimatedCost: string,
  loadingStatus: bool,
  totalNumberOfItems: number,
};

TrolleyButtonContents.defaultProps = {
  estimatedCost: '',
  loadingStatus: false,
  totalNumberOfItems: 0,
};

export default TrolleyButtonContents;
