import { getAddressSearchResults } from './get-address-search-results';
import { foundAddress, hasFoundAddress } from './found-address';
import { getAddressBySuggestionCanNavigateBack } from './navigate-back';
import { addressSuggestionRequestError } from './request-error';

const searchParam = state => state.addressSuggestion.searchParam;
const searchValueSelector = state => state.addressSuggestion.searchValues;
const modifiedFieldsSelector = state => state.addressSuggestion.modifiedFields;
const addressSelector = state => state.addressSuggestion.address;
const searchLoadingSelector = state => state.addressSuggestion.searchLoading;
const getAddressLoadingSelector = state => state.addressSuggestion.getAddressLoading;
const getSuggestedAddressError = state => state.addressSuggestion.error;
const addressSuggestionNavigation = state => state.addressSuggestion.navigation;

export {
  addressSelector,
  addressSuggestionNavigation,
  getSuggestedAddressError,
  addressSuggestionRequestError,
  foundAddress,
  getAddressBySuggestionCanNavigateBack,
  getAddressLoadingSelector,
  getAddressSearchResults,
  hasFoundAddress,
  modifiedFieldsSelector,
  searchParam,
  searchValueSelector,
  searchLoadingSelector,
};
