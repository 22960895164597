import { MEGA_MENU } from 'constants/categoryIds';

import { getSubcategoriesById } from 'redux/modules/taxonomy/selectors';

import clearMegaMenuHistory from 'redux/modules/mega-menu/actions/clear-mega-menu-history';
import setMegaMenuActiveItem from 'redux/modules/mega-menu/actions/set-mega-menu-active-item';
import setMegaMenuRoot from 'redux/modules/mega-menu/actions/set-mega-menu-root';

export default () => (dispatch, getState) => {
  const [newActiveItem] = getSubcategoriesById(getState(), MEGA_MENU);

  // reset the mega-menu history
  dispatch(clearMegaMenuHistory());
  // reset the mega-menu menuRoot
  dispatch(setMegaMenuRoot(MEGA_MENU));
  // reset the mega-menu activeItem
  dispatch(setMegaMenuActiveItem(newActiveItem));
};
