import { Action } from 'redux';
import get from 'lodash/get';
import { WaitroseModelProps } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/cms-components/types.d';
import env from 'env/env';
import { DEFAULT_SORT_BY } from 'redux/modules/recipes/constants';
import initial from 'lodash/initial';
import { Recipe, RecipeSummaryItem, Tag, RecipeImage } from 'api/definitions/recipes/index.d';
import {
  GET_CMS_HYBRID_RECIPES_PAGE_LOADING,
  GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS,
  GET_CMS_HYBRID_RECIPES_PAGE_FAILURE,
  GET_CMS_TAGGED_RECIPES_LOADING,
  GET_CMS_TAGGED_RECIPES_SUCCESS,
  GET_CMS_TAGGED_RECIPES_FAILURE,
  GET_CMS_FILTERED_SORTED_RECIPES_SUCCESS,
  INCREMENT_CURRENT_RECIPES_PAGE,
  RESET_CURRENT_RECIPES_PAGE,
  RESET_FILTERED_SORTED_RECIPES,
  RECIPES_REMEMBER_FOCUS,
} from 'redux/modules/cms-hybrid-recipes-page/actions/types';
import { FifoBuffer, fifoBufferLookup, fifoBufferPush } from 'utils/fifo-buffer';
import addColumnComponentToContentResponse from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ContentLocation/add-column-component-to-content-response';
import { Filter, RecipeSortType } from 'services/recipes-landing-service';
import { getCacheKey } from '../utils/get-cache-key';

const isType = <T>(x: T) => x;

/*

  WARNING
  This file is not independent of the src/redux/modules/content redux module as the ContentLocation
  is a connected component that uses that section of the redux modules to pull the content locations. Care whilst adding
  to this file is advised to make sure that responsibilities are not duplicated with the other almost identical
  redux modules.

  WARNING 2
  It copies functionality from src/redux/modules/cms-page/reducers/index.js
  The whole thing is candidate for redesign due to that

*/

/* @ts-expect-error uses `any` type */
const definedProps = obj =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));

const mergeRecipes = (
  recipes: RecipeSummaryItem[],
  moreRecipes: RecipeSummaryItem[],
  start: number,
  currentPage: number,
) => {
  const newRecipes = [...recipes.slice(0, currentPage * env.recipesPerPage)];

  moreRecipes.forEach((recipe, i) => {
    newRecipes[start + i] = recipe;
  });

  return newRecipes;
};

export type RecipeCardsGridInfo = WaitroseModelProps & {
  defaultSortBy: RecipeSortType;
  tagList: Tag[];
  dietaryFilters: string[];
  gridType: string;
  gridTitle: string;
  gridSubtitle: string;
};

type Locations = {
  root: (WaitroseModelProps | RecipeCardsGridInfo)[];
};

type RecipeSummaryResponse = {
  recipes: RecipeSummaryItem[];
  filters: Filter[];
  totalMatches: number;
};

export type ContentResponse = {
  locations: Locations;
  authorableBreadcrumb?: boolean;
  image: RecipeImage;
  metaData: {
    keywords: string[];
    turnOffIndexing?: boolean;
  };
  pageTitle: {
    text: string;
    display: boolean;
  };
};

export type ContentAction = {
  type: string;
  contentUrl: string;
  result: ContentResponse;
};

export type RecipeSummaryAction = Action & {
  contentUrl: string;
  currentPage?: number;
  filterParams: {
    sortBy?: string;
  };
  filters: Filter[];
  req: {
    start: number;
    size: number;
    search: {
      term: string;
      tags: string[][];
    };
  };
  totalTaggedRecipes: number;
  result?: RecipeSummaryResponse;
};

export type FilteredSortedRecipesSuccessAction = Action & {
  recipesByTags: RecipeSummaryItem[];
  filters: Filter[];
  filterParams: {
    sortBy?: string;
  };
  req: {
    start: number;
  };
  totalTaggedRecipes: number;
};

type RecipeSummaryLoadingAction = Action & {
  contentUrl: string;
  filterParams: {
    filters: Filter[];
    sortBy: string;
    paginationPage: number;
    hasPageParam: boolean;
    startPage: number;
  };
};

type RememberFocusAction = Action & {
  id: string;
};

type Breadcrumb = {
  urlName?: string;
  name: string;
  path: string;
  internal?: boolean;
};

export type RecipeLandingState = {
  error?: boolean;
  loading: boolean;
  loadingCount: number;
  contentUrl: string;
  currentPage: number;
  recipesByTags?: RecipeSummaryItem[];
  filterParams: RecipeSummaryAction['filterParams'];
  recipes: RecipeSummaryItem[];
  totalMatches: number;
  filters: FifoBuffer<Filter[]>;
  responses: FifoBuffer<RecipeSummaryResponse>;
  locations: FifoBuffer<ContentResponse>;
  componentSortBy?: RecipeSortType;
  recipeTags: Tag[];
  totalTaggedRecipes: number;
  paginatingOperation: boolean;
  filteringOperation: boolean;
  breadcrumbs: Breadcrumb[];
  metaData: {
    description?: string;
    keywords: string[];
    notIndexed: boolean;
    turnOffIndexing?: boolean;
  };
  pageTitle: ContentResponse['pageTitle'];
  image: RecipeImage;
  showPageTitle: boolean;
  focusedRecipe: Recipe['id'];
};

const initialState: Partial<RecipeLandingState> = Object.freeze({
  contentUrl: '',
  error: false,
  loading: false,
  loadingCount: 0,
  currentPage: 1,
  filters: [],
  responses: [],
  locations: [],
  filterParams: {},
  pageTitle: {
    text: '',
    display: false,
  },
});

const extractTags = ({ locations }: { locations: Locations }) => {
  let tags: Tag[] = [];
  if (locations) {
    locations.root.forEach(element => {
      if (element.resourceType === 'waitrosegroceriescms/components/content/recipecardsgrid') {
        tags = (element as RecipeCardsGridInfo).tagList || [];
      }
    });
  }

  return tags;
};

export const mergeFilters = (originalFilters: Filter[] = [], updatedFilters: Filter[] = []) => {
  const inactiveFilters = originalFilters
    .filter(filter => !updatedFilters.find(updatedFilter => updatedFilter.title === filter.title))
    .map(filter => ({
      ...filter,
      values: filter.values.map(value => ({
        ...value,
        count: 0,
      })),
    }));

  const mergedFilters = updatedFilters.reduce((filters, filter) => {
    const originalFilter = originalFilters.find(origFilter => origFilter.title === filter.title);

    if (originalFilter) {
      const inactiveValues = originalFilter.values
        .filter(val => !filter.values.find(val2 => val2.name === val.name))
        .map(val => ({
          ...val,
          count: 0,
        }));

      filters.push({
        ...filter,
        values: [...filter.values, ...inactiveValues].sort((m, n) => (m.name > n.name ? 1 : -1)),
      });
    } else {
      filters.push(filter);
    }

    return filters;
  }, inactiveFilters);

  return mergedFilters;
};

export default (
  state: RecipeLandingState = initialState as RecipeLandingState,
  action: Action | RecipeSummaryAction | ContentAction | RememberFocusAction,
) => {
  switch (action.type) {
    case GET_CMS_HYBRID_RECIPES_PAGE_LOADING: {
      return {
        ...state,
        error: false,
        loading: true,
        loadingCount: (state.loadingCount || 0) + 1,
      };
    }

    case GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS: {
      const result: ContentResponse = (action as ContentAction).result || ({} as ContentResponse);

      // if this is authorable breadcrumb - drop last item
      const isAuthorableBreadcrumb = result.authorableBreadcrumb || false;

      const componentSortBy = (result.locations?.root as RecipeCardsGridInfo[])?.find(
        ({ defaultSortBy }) => !!defaultSortBy,
      )?.defaultSortBy;

      // handle AEM paths
      const breadcrumbs = get(action, 'result.breadcrumbs', []).map(
        ({ path, ...rest }: { path: string }) => {
          const newPath =
            path && path.includes('/content/waitrosegroceriescms/en')
              ? path.split('/content/waitrosegroceriescms/en/')[1]
              : path;

          return {
            ...(rest as Omit<Breadcrumb, 'path'>),
            path: `/ecom/${newPath}`,
          };
        },
      );

      const { contentUrl } = action as ContentAction;
      const content: ContentResponse = (
        addColumnComponentToContentResponse(result.locations ?? {}) as Locations
      )?.root as unknown as ContentResponse;
      const categoryChanged = contentUrl !== state.contentUrl;

      const newState: RecipeLandingState = {
        ...state,
        breadcrumbs: isAuthorableBreadcrumb ? initial(breadcrumbs) : breadcrumbs,
        contentUrl: (action as ContentAction).contentUrl,
        error: false,
        loading: false,
        loadingCount: state.loadingCount - 1,
        locations: fifoBufferPush<ContentResponse>(state.locations, contentUrl, content),
        metaData: { ...result.metaData, notIndexed: result.metaData?.turnOffIndexing || false },
        ...definedProps({
          pageTitle: result.pageTitle,
        }),
        image: result.image || null,
        showPageTitle: get(action, 'result.showPageTitle', true),
        recipeTags: extractTags(result),
        componentSortBy,
      };

      if (categoryChanged) {
        Object.assign(
          newState,
          isType<Partial<RecipeLandingState>>({
            recipesByTags: [],
            currentPage: 1,
            paginatingOperation: false,
            filters: [],
          }),
        );
      }

      return newState;
    }

    case GET_CMS_HYBRID_RECIPES_PAGE_FAILURE: {
      return isType<RecipeLandingState>({
        ...state,
        error: true,
        loading: false,
        loadingCount: (state.loadingCount || 1) - 1,
      });
    }

    case GET_CMS_TAGGED_RECIPES_LOADING: {
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          sortBy: (action as RecipeSummaryLoadingAction).filterParams?.sortBy,
        },
        loadingCount: state.loadingCount + 1,
      };
    }

    case GET_CMS_TAGGED_RECIPES_SUCCESS: {
      const result: RecipeSummaryAction['result'] =
        (action as RecipeSummaryAction).result || ({} as RecipeSummaryAction['result'])!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      const sort = (action as RecipeSummaryAction).filterParams?.sortBy;
      const filterParams = { sortBy: sort || DEFAULT_SORT_BY };
      // ignore recipes where the sort was not explicitly specified and the default is not being used
      const correctSortType = !(
        !sort &&
        state.filterParams.sortBy &&
        state.filterParams.sortBy !== DEFAULT_SORT_BY
      );

      const prevFilters =
        fifoBufferLookup<Filter[]>(state.filters, (action as RecipeSummaryAction).contentUrl) || [];

      return isType<RecipeLandingState>({
        ...state,
        filterParams: {
          ...state.filterParams,
          ...filterParams,
        },
        recipesByTags: correctSortType
          ? mergeRecipes(
              state.recipesByTags || [],
              result.recipes || [],
              (action as RecipeSummaryAction).req.start || 0,
              (action as RecipeSummaryAction).currentPage || 1,
            )
          : state.recipesByTags,
        filters: fifoBufferPush(
          state.filters,
          (action as RecipeSummaryAction).contentUrl,
          mergeFilters(prevFilters, result.filters),
        ),
        totalTaggedRecipes: result.totalMatches,
        currentPage: (action as RecipeSummaryAction).currentPage || 1,
        loadingCount: state.loadingCount - 1,
        paginatingOperation: false,
        responses: fifoBufferPush(
          state.responses,
          getCacheKey(
            (action as RecipeSummaryAction).filters,
            (action as RecipeSummaryAction).req.search,
            (action as RecipeSummaryAction).filterParams.sortBy,
            (action as RecipeSummaryAction).req.start,
            (action as RecipeSummaryAction).req.size,
          ),
          (({ recipes, filters, totalMatches }) => ({ recipes, filters, totalMatches }))(result),
        ),
      });
    }

    case GET_CMS_FILTERED_SORTED_RECIPES_SUCCESS: {
      const paramsChanged =
        ((action as RecipeSummaryAction).filterParams?.sortBy || DEFAULT_SORT_BY) !==
        (state.filterParams?.sortBy || DEFAULT_SORT_BY);

      return isType<RecipeLandingState>({
        ...state,
        recipesByTags: mergeRecipes(
          (!paramsChanged && state.recipesByTags) || [],
          (action as FilteredSortedRecipesSuccessAction).recipesByTags || [],
          (action as FilteredSortedRecipesSuccessAction).req.start,
          state.currentPage,
        ),
        filters: fifoBufferPush(
          state.filters,
          state.contentUrl,
          mergeFilters(
            fifoBufferLookup(state.filters, state.contentUrl),
            (action as FilteredSortedRecipesSuccessAction).filters,
          ),
        ),
        filterParams: (action as FilteredSortedRecipesSuccessAction).filterParams,
        totalTaggedRecipes: (action as FilteredSortedRecipesSuccessAction).totalTaggedRecipes,
        filteringOperation: false,
        paginatingOperation: false,
      });
    }

    case RESET_FILTERED_SORTED_RECIPES: {
      return isType<RecipeLandingState>({
        ...state,
        recipesByTags: [],
        filteringOperation: true,
        paginatingOperation: false,
        /* TODO apparently not used
        searchTerm: action?.payload?.searchTerm,
        */
      });
    }

    case INCREMENT_CURRENT_RECIPES_PAGE: {
      return {
        ...state,
        currentPage: (state.currentPage || 1) + 1,
        paginatingOperation: true,
      };
    }

    case RESET_CURRENT_RECIPES_PAGE: {
      return {
        ...state,
        currentPage: 1,
        paginatingOperation: false,
      };
    }

    case RECIPES_REMEMBER_FOCUS: {
      return isType<RecipeLandingState>({
        ...state,
        focusedRecipe: (action as RememberFocusAction).id,
      });
    }

    case GET_CMS_TAGGED_RECIPES_FAILURE: {
      return isType<RecipeLandingState>({
        ...state,
        error: true,
        loading: false,
        loadingCount: (state.loadingCount || 1) - 1,
      });
    }

    default:
      return state;
  }
};
