const reducer = (state, { result = {} }) => {
  const { containsEntertainingLines, slots } = result;

  return {
    ...state,
    containsEntertainingLines:
      containsEntertainingLines !== undefined
        ? containsEntertainingLines
        : state.containsEntertainingLines,
    slots,
  };
};

export default reducer;
