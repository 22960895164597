import { logoutFailedModal } from 'constants/modals';
import urls from 'constants/urls';
import history from 'utils/history';
import { COOKIE_BRANCH_ID } from 'constants/cookies';
import { removeCookie } from 'utils/cookie';

import { destroySession } from 'redux/modules/sessions/actions/destroy-session';
import { openModal } from 'redux/modules/common-modal/actions';

export const logoutUser = () => dispatch => {
  history.push(urls.homepage);
  removeCookie(COOKIE_BRANCH_ID);
  return dispatch(destroySession()).catch(() => dispatch(openModal({ ...logoutFailedModal })));
};
