import { POST_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { MyWaitroseState } from 'redux/modules/mywaitrose/state';

import { MyWaitrose } from 'constants/my-waitrose';
import { ApiError } from 'constants/errors';

const joinMyWaitroseRequest = (state: MyWaitroseState) => ({
  ...state,
  request: {
    isLoading: true,
    error: null,
  },
});

const joinMyWaitroseSuccess = (state: MyWaitroseState, action: { payload: MyWaitrose }) => ({
  ...state,
  ...action.payload,
  request: {
    isLoading: false,
    error: null,
  },
});

const joinMyWaitroseFailure = (state: MyWaitroseState, action: { payload: ApiError }) => ({
  ...state,
  request: {
    isLoading: false,
    error: action.payload,
  },
});

export default () =>
  [
    [POST_MYWAITROSE_CARD.request, joinMyWaitroseRequest],
    [POST_MYWAITROSE_CARD.success, joinMyWaitroseSuccess],
    [POST_MYWAITROSE_CARD.failure, joinMyWaitroseFailure],
  ] as const;
