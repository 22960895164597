/*
Generates an event which is fired upon loading of ProductDetails page

Used in analytics to provide context of user journeys

example:
```
{
  event: 'product_details',
  ecommerce: {
    detail: {
      actionField: {
        list: 'favourites'
      },
      products: [{
        category: 'Large_Smoothies',
        id: '45704',
        name: 'Nu Smoothie Apple, Cucumber & Kale',
        position: 1,
        price: '3.49',
        product_sku: '088903-45703-45704',
        uom: 'C62',
      }],
    },
  },
}
```
*/
import { removeCurrencyCharacters } from 'utils/parse-display-price';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { getProductCategoriesById } from 'redux/modules/product-details/selectors/product-category';

import { getPromotionDescription } from 'redux/modules/entities/selectors/promotions';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import {
  getProductDisplayPrice,
  getProductPromotions,
} from 'redux/modules/product-details/selectors/product-pricing';
import {
  getProductBrand,
  getProductName,
  getProductType,
} from 'redux/modules/product-details/selectors/product-properties';
import { getSelectedLineNumber } from 'redux/modules/product-details/selectors';

export default function transform(state) {
  const lineNumber = getSelectedLineNumber(state);
  const promotionDescriptions =
    getProductPromotions(state, lineNumber).map(id => getPromotionDescription(state, id))?.[0] ||
    '';
  const categoryNames = getProductCategoriesById(state, lineNumber).map(category => category.name);

  return {
    event: 'product_details',
    ecommerce: {
      detail: {
        actionField: {
          list: undefined, // TODO [BM]: update when available
        },
        products: [
          {
            brand: getProductBrand(state, lineNumber),
            category: categoryNames,
            favourite: getIsFavourite(state, lineNumber) ? 'YES' : 'NO',
            id: extractLineNumberFromSku(lineNumber),
            name: getProductName(state, lineNumber),
            offer: promotionDescriptions,
            price: removeCurrencyCharacters(getProductDisplayPrice(state, lineNumber)),
            variant: getProductType(state, lineNumber),
          },
        ],
      },
    },
  };
}
