import { CMS_LOADING, CMS_LOADED, CMS_FAILED } from 'redux/modules/cms/actions/types';
import { getCmsPageTitle, getCmsRelatedNavigation } from 'redux/modules/cms/reducer/utilities';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  components: null,
  loading: true,
  navigation: [],
  pageTitle: {},
});

export default function cms(state = initialState, action = {}) {
  switch (action.type) {
    case CMS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
        navigation: [],
        pageTitle: {},
        url: action.url,
      };
    case CMS_LOADED:
      return {
        ...state,
        components: action.result,
        error: false,
        loading: false,
        navigation: getCmsRelatedNavigation(action.result),
        pageTitle: getCmsPageTitle(action.result),
      };
    case CMS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
