import env from 'env/env';

import { pathJoin } from 'utils/general';

const formatClientApiUrl = path => pathJoin(env.apiPath, path);
const formatServerApiUrl = path => pathJoin(env.serverPath, path);

const formatServerLoyaltyExperiencesServerPath = path =>
  pathJoin(env.loyaltyExperiencesServerPath, path.replace('/loyalty/', '/'));

const formatJohnLewisServerUrl = path => pathJoin(env.johnLewisServerPath, path);

export const formatUrl = (url, server = __SERVER__) => {
  if (server) {
    return formatServerApiUrl(url);
  }

  return formatClientApiUrl(url);
};

export const formatLoyaltyExperiencesUrl = (url, server = __SERVER__) => {
  if (server) {
    return formatServerLoyaltyExperiencesServerPath(url);
  }

  return url;
};

export const formatJohnLewisUrl = url => formatJohnLewisServerUrl(url);
