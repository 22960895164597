import { getCustomerId } from 'redux/modules/sessions/selectors';
import { getPromoCodeByCode } from 'redux/modules/order/selectors/promo-codes';

export const addPromoCode = (state, code) => {
  const { status = '' } = getPromoCodeByCode(state, code) || {};
  return {
    customerId: getCustomerId(state),
    event: 'promo_code_added',
    promoCode: code,
    status,
  };
};

export const removePromoCode = (state, code) => ({
  customerId: getCustomerId(state),
  event: 'promo_code_removed',
  promoCode: code,
});

export const addPromoCodeApiError = (state, code) => ({
  customerId: getCustomerId(state),
  event: 'promo_code_api_error',
  promoCode: code,
  method: 'POST',
});

export const removePromoCodeApiError = (state, code) => ({
  customerId: getCustomerId(state),
  event: 'promo_code_api_error',
  promoCode: code,
  method: 'DELETE',
});
