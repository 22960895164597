import { getWasProductDetour } from 'redux/modules/page/selectors/get-was-product-detour';

export const exceptFromProduct = fetcher => args => {
  if (args.preloadOnly) {
    fetcher(args);
    return () => {};
  }
  return (dispatch, getState) =>
    getWasProductDetour(getState()) ? Promise.resolve() : fetcher(args)(dispatch, getState);
};
