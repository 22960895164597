import {
  SHOW_SNACKBAR,
  VIEWED_SNACKBAR,
  RESET_SNACKBAR,
  REMOVE_VIEWED_IDS,
} from 'redux/modules/common-snackbar/actions/types';

export const showSnackbar = snackbar => ({
  type: SHOW_SNACKBAR,
  snackbar,
});

export const viewedSnackbar = snackbarId => ({
  type: VIEWED_SNACKBAR,
  id: snackbarId,
});

export const resetSnackbar = () => ({
  type: RESET_SNACKBAR,
});

export const clearViewedIds = (removeIds = []) => ({
  type: REMOVE_VIEWED_IDS,
  removeIds,
});

export const handleCloseSnackbar = snackbar => dispatch => {
  if (snackbar.onCloseAction) {
    dispatch({ type: snackbar.onCloseAction, snackbar });
  }
  dispatch(viewedSnackbar(snackbar.id));
};
