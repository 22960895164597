import { deepFreeze } from 'utils/deepFreeze';

export type BranchContactDetails = {
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  country: string;
  phone: string;
};

export type BranchRefurbish = {
  endDate: string;
  replacementBranch: string;
  startDate: string;
  reason: string;
};

export type Branch = {
  id: string;
  defaultBranch: boolean;
  distance: number;
  carParkCollectionEnabled: boolean;
  name: string;
  type: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
  fastTrack: string;
  baglessDelivery: boolean;
  contactDetails: BranchContactDetails;
  branchRefurbish?: BranchRefurbish;
  cutoffs: {
    slotReservation: string;
    order: string;
    orderAmend: string;
  };
  services: string[];
};

export interface BranchesState {
  branches: Branch[];
  error: unknown;
  loading: boolean;
  isPostcodeInvalid: boolean;
}

const initialState: Readonly<BranchesState> = deepFreeze({
  branches: [],
  error: null,
  loading: false,
  isPostcodeInvalid: false,
});

export default initialState;
