import { apiCallback } from 'api';

const definitionPut = {
  service: 'content',
  endpoint: '${endpoint}',
  verbs: ['put'],
  query: {
    clientType: 'WEB_APP',
  },
  defaultVersion: 2,
};

export default {
  put: apiCallback(definitionPut, 'put'),
};
