import { SlotTypes } from 'constants/fulfilmentTypes';

const reducer = state => ({
  ...state,
  loading: true,
  addressId: null,
  addressName: '',
  branchId: null,
  slotDate: '',
  slotEndTime: '',
  slotExpiryTime: '',
  slotStartTime: '',
  slotType: SlotTypes.UNDEFINED,
  orderCutOff: '',
  amendOrderCutOff: '',
  greenSlot: false,
  error: null,
});

export default reducer;
