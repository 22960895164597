import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import { deepFreeze } from 'utils/deepFreeze';

import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_PENDING,
  INTERSTITIALS_REJECTED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';

const initialState = deepFreeze({
  empty: false,
  error: null,
  hash: null,
  loaded: false,
  loading: false,
  steps: [],
  valid: true, // have we been told there are no interstitials
});

const hashesAreNotEqual = (hash, newHash) => newHash !== hash;
const stepIsCurrent = (currentStep, { id }) => currentStep === id;
const interstitialsAreValid = (steps = []) => steps.length > 0;
const emptyOfContent = ({ result: { componentsAndProducts, locations } }) =>
  [componentsAndProducts, ...Object.values(locations ?? {})].every(isEmpty);

export default function interstitials(state = initialState, action = {}) {
  switch (action.type) {
    case INTERSTITIALS_FULFILLED: {
      const { result: { interstitials: { hash = null, steps = [], currentStep } = {} } = {} } =
        action;

      return {
        ...state,
        hash,
        loaded: true,
        loading: false,
        empty: emptyOfContent(action),
        steps: steps.map(step => ({
          ...step,
          current: stepIsCurrent(currentStep, step),
          visited: stepIsCurrent(currentStep, step),
        })),
        valid: interstitialsAreValid(steps),
      };
    }
    case INTERSTITIALS_UPDATED: {
      const {
        result: { interstitials: { currentStep, hash = null, steps: newSteps = [] } = {} } = {},
      } = action;

      if (hashesAreNotEqual(state.hash, hash)) {
        return {
          ...state,
          ...initialState,
          valid: false,
        };
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        empty: emptyOfContent(action),
        steps: update(state.steps, {
          $apply: steps =>
            steps.map(step => ({
              ...step,
              current: stepIsCurrent(currentStep, step),
              visited: step.visited ? true : stepIsCurrent(currentStep, step),
            })),
        }),
        valid: interstitialsAreValid(newSteps),
      };
    }

    case INTERSTITIALS_PENDING:
      return {
        ...state,
        error: null,
        loading: true,
        empty: false,
      };

    case INTERSTITIALS_REJECTED:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
        empty: false,
        valid: false,
      };
    default:
      return state;
  }
}
