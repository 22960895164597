import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import PhoneHandset from '@johnlewispartnership/wtr-ingredients/dist/Icon/PhoneHandset';

import styles from './ReturnToAdmin.scss';

const ReturnToAdmin = pure(({ isSignedOnAsCustomer, onClick }) =>
  isSignedOnAsCustomer ? (
    <div className={styles.returnToAdmin}>
      <Button startIcon={<PhoneHandset />} label="Return to admin" onClick={onClick} width="fit" />
    </div>
  ) : null,
);

ReturnToAdmin.propTypes = {
  isSignedOnAsCustomer: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ReturnToAdmin.displayName = 'ReturnToAdmin';

export default ReturnToAdmin;
