import React from 'react';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import styles from './index.scss';
import { ProductDataType } from '..';

export interface ProductDescriptionProps extends Pick<ProductDataType, 'name' | 'size'> {
  productUrl?: string;
}

const ProductDescription = ({ name, size, productUrl }: ProductDescriptionProps) => {
  return (
    <Typography
      styleAs="paragraphHeading"
      className={styles.description}
      data-testid="product-name"
    >
      <TextLink
        href={productUrl}
        target="_blank"
        rel="noreferrer"
        underline="hover"
        className={styles.textLink}
        data-testid="product-name-link"
      >
        {name}
      </TextLink>{' '}
      <Typography element="span" styleAs="paragraph" data-testid="product-size">
        {size}
      </Typography>
    </Typography>
  );
};

export default ProductDescription;
