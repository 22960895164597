import {
  PRODUCT_LOADING,
  PRODUCT_LOADED,
  PRODUCT_FAILED,
  PRODUCT_NOT_FOUND,
  PDP_RECOMMENDED_PRODUCTS_FULFILLED,
} from 'redux/modules/product-details/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialErrors = {
  error: false,
  errorResponse: null,
  notFound: false,
};

const initialState = deepFreeze({
  ...initialErrors,
  loading: false,
  productId: null,
});

export default function productDetails(state = initialState, action = {}) {
  switch (action.type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        ...initialErrors,
        loading: true,
      };
    case PRODUCT_LOADED: {
      return {
        ...state,
        ...initialErrors,
        loading: false,
        productId: action.result.componentsAndProducts[0].searchProduct,
      };
    }
    case PRODUCT_FAILED:
      return {
        ...state,
        error: true,
        errorResponse: action.error,
        loading: false,
        notFound: false,
        productId: null,
      };
    case PRODUCT_NOT_FOUND:
      return {
        ...state,
        ...initialErrors,
        notFound: true,
      };
    case PDP_RECOMMENDED_PRODUCTS_FULFILLED:
      return {
        ...state,
        recommendations: action.result.products,
        totalRecommendations: action.result.totalResults,
      };
    default:
      return state;
  }
}
