import { createSelector } from 'reselect';

const getAddAllItems = ({ addAllItems } = {}) => addAllItems;

export const getAddAllItemsSuccessfulCount = createSelector(
  [getAddAllItems],
  ({ successfulCount = 0 } = {}) => successfulCount,
);

export const getAddAllItemsStatus = ({ addAllItems } = {}) => addAllItems.updatingStatus;
