const reducer = (state, action) => {
  const { result, type } = action;

  const errorCode = result.conflicts ? result.conflicts.code : undefined;

  return {
    ...state,
    serverError: {
      response: {
        body: {
          code: errorCode,
          type,
        },
      },
      status: result.statusCode,
    },
    giftCardToRemove: null,
    waiting: false,
  };
};

export default reducer;
