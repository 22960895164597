import { INITIALISE_MISSED_OFFERS } from 'redux/modules/missed-offers/actions/types';

import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  items: [],
});

export default (state = initialState, action = {}) => {
  const { payload, type } = action;

  switch (type) {
    case INITIALISE_MISSED_OFFERS: {
      return {
        ...state,
        items: payload,
      };
    }
    default:
      return state;
  }
};
