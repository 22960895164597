import { generateSession } from 'redux/modules/sessions/actions/generate-session';
import { initiateSession } from 'redux/modules/sessions/actions/initiate-session';
import resetSession from 'redux/modules/sessions/actions/reset-session';
import { setAccessToken } from 'redux/modules/sessions/actions/set-access-token';
import { redirectToLogin } from 'utils/login';
import { getFeatureFlags } from 'utils/feature-flags';

export const handle401 = ({ api, apiToCall, dispatch, getState, args }) => {
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();

  if (
    OAuth2Enabled
      ? api.service === 'token-client' && api.endpoint === 'token'
      : api.queryKey === 'retailSessions'
  ) {
    redirectToLogin();

    return;
  }

  dispatch(setAccessToken());

  // eslint-disable-next-line consistent-return
  return dispatch(OAuth2Enabled ? initiateSession() : generateSession())
    .then(() => apiToCall(getState))
    .catch(error => {
      if (
        args?.iHandleStatusCodes &&
        error?.status &&
        args?.iHandleStatusCodes?.includes?.(error.status)
      ) {
        throw error;
      }

      dispatch(resetSession());
      redirectToLogin();

      if (args?.throwErrorOnUnauthorized) {
        throw error;
      }
    });
};
