import signOnAsCustomerActions from 'route-actions/sign-on-as-customer-actions';

import { createStoreSubscriber } from './create';
import { isNowTrue } from './matchers';

const onChange = (dispatch, getState) => {
  signOnAsCustomerActions(dispatch, getState());
};

const select = state => state.trolley.loaded;

export const addToTrolleyListener = store =>
  createStoreSubscriber({
    match: isNowTrue,
    once: true,
    onChange,
    select,
    store,
  });
