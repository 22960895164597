import { createSelector } from 'reselect';

const EMPTY_TROLLEY = [];

/**
 * @param {WtrState} state State
 * @returns {any} Trolley state
 */
export const getTrolley = ({ trolley } = {}) => trolley;

export const getTrolleyItems = createSelector(
  getTrolley,
  ({ trolleyItems } = {}) => trolleyItems || EMPTY_TROLLEY,
);

export const hasItems = state => !!getTrolleyItems(state).length;

export const getOrderedItems = createSelector(getTrolleyItems, items =>
  items.slice().sort((itemA, itemB) => itemB.trolleyItemId - itemA.trolleyItemId),
);

export const getOrderedItemsInTrolley = createSelector(getOrderedItems, items =>
  items.filter(item => item.quantity.amount !== 0),
);

export const getLineItemsForExpiredOffersCheck = createSelector(getTrolleyItems, items =>
  items.map(product => ({
    lineNumber: product && product.lineNumber,
    quantity: {
      amount:
        product && product.quantity && product.quantity.amount && `${product.quantity.amount}`,
      uom: product && product.quantity && product.quantity.uom,
    },
  })),
);
