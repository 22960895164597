import { axios } from 'utils/request';
import env from 'env/env';
import { usingStubs } from 'stubs';
import { Recipe, RecipeSummaryItem } from 'api/definitions/recipes/index.d';
import { buildPath } from '../api/props/api/build-path';

// request definitions
const definitionSummary = {
  service: 'recipes',
  endpoint: 'recipes/summary',
  verbs: ['post'],
  defaultVersion: 1,
};

export type RecipeSortType = 'LAST_PUBLISHED' | 'DEFAULT_SORT';

export type SummaryRequestParams = {
  preferences: { start: number; size?: number; sortBy: string };
  search: { term?: string; tags: string[] };
  filters?: {
    title: string;
    values: string[];
  }[];
  excludeRecipeIds?: Recipe['id'][];
};

export type Filter = {
  title: string;
  values: {
    name: string;
    count: number;
  }[];
};

type SummaryResponse = {
  status: number;
  data: {
    recipes: RecipeSummaryItem[];
    filters: Filter[];
    totalMatches: number;
  };
};

type StubFn = (req: { params: SummaryRequestParams }) => SummaryResponse['data'];

const args = {};
let stub: StubFn;

const emptyResponse: SummaryResponse['data'] = {
  recipes: [],
  filters: [],
  totalMatches: 0,
};

export const filteredRecipes = async (
  jwt: string,
  tags: string[],
  filters: SummaryRequestParams['filters'] = [],
  sortBy: RecipeSortType,
  searchTerm?: string,
  start = 0,
  size: number = env.recipesPerPage,
  excludeRecipeIds?: Recipe['id'][],
): Promise<SummaryResponse['data']> => {
  const payload = {
    headers: {
      token: jwt,
    },
    search: {
      term: searchTerm,
      tags,
    },
    filters,
    preferences: {
      size,
      sortBy,
      start,
    },
    excludeRecipeIds,
  };

  if (usingStubs()) {
    if (!stub) {
      stub = (
        await import(
          /* webpackChunkName: "stubs" */
          /* webpackMode: "lazy" */
          'stubs/services/recipes/recipes-summary'
        )
      ).default as StubFn;
    }

    return stub({
      params: payload,
    });
  }

  try {
    const response: { status: number; data: SummaryResponse['data'] } = await axios.post(
      `/api${buildPath(definitionSummary, args)}`,
      payload,
    );
    if (!response || response.status !== 200 || !response.data) {
      throw new Error('Got a non-200 response.');
    }

    return response.data;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_) {
    return emptyResponse;
  }
};
