import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { SmallButton } from '@johnlewispartnership/wtr-ingredients/ingredients/SmallButton';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { Snackbar } from '@johnlewispartnership/wtr-ingredients/ingredients/Snackbar';
import { Sharing } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { trackRecipeShare } from 'analytics/recipes-tracking';
import { recipeShareModal } from 'constants/modals';
import { trackModalOpened, trackModalClosed } from 'analytics/modal-tracking';
import env from 'env/env';
import Socials from './Socials';

import pageStyles from '../index.scss';
import styles from './index.scss';

const Share = ({ recipeName, recipeDescription, recipeImage }) => {
  const [showShare, setShowShare] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const canonicalHref = `https://${env.prodHost}${useLocation().pathname}`;

  const { id, title, severity } = recipeShareModal;

  const shareModal = {
    id,
    title,
    severity,
  };

  const handleOpen = () => {
    setShowShare(true);
    trackRecipeShare('Click');
    trackModalOpened(shareModal);
  };

  const handleClose = () => {
    setShowShare(false);
    trackRecipeShare('Close');
    trackModalClosed(shareModal);
  };

  const handleCopy = () => {
    if (navigator.clipboard)
      navigator.clipboard.writeText(canonicalHref).then(() => setShowSnackbar(true));
    setShowShare(false);
    trackRecipeShare('Copy');
    trackModalClosed(shareModal);
  };

  return (
    <>
      <SmallButton
        theme="secondary"
        label="Share"
        onClick={handleOpen}
        startIcon={<Sharing />}
        className={pageStyles.printButton}
      />
      {showShare && (
        <Modal
          className={styles.share}
          isOpen
          titleText="Share this recipe"
          handleModalClose={handleClose}
        >
          <Typography styleAs="paragraph">
            You can copy this link into emails, text messages or post it on socials.
          </Typography>
          <div className={styles.copyWrapper}>
            <div className={styles.copy}>
              <input
                type="text"
                value={canonicalHref}
                readOnly="readonly"
                aria-label="URL to share"
              />
              <SmallButton
                aria-label="Copy URL"
                theme="primary"
                label="Copy"
                onClick={handleCopy}
              />
            </div>
            <Socials
              recipeName={recipeName}
              recipeDescription={recipeDescription}
              canonicalHref={canonicalHref}
              recipeImage={recipeImage}
            />
          </div>
        </Modal>
      )}
      <Snackbar
        message="Link copied"
        type="success"
        open={showSnackbar}
        onClose={setShowSnackbar}
      />
    </>
  );
};

Share.propTypes = {
  recipeName: PropTypes.string,
  recipeDescription: PropTypes.string,
  recipeImage: PropTypes.string.isRequired,
};

Share.defaultProps = {
  recipeName: '',
  recipeDescription: '',
};

export default Share;
