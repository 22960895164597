import Cookies from 'universal-cookie';
import { asyncLocalStorage } from 'server/utils/async-local-storage';
import { COOKIE_CONSENT, COOKIE_FUNCTIONAL } from 'constants/cookies';

export const legacyClientSideCookies = [
  'ADRUM',
  'ADRUM_BT1',
  'ADRUM_BTa',
  'ADRUM_BTs',
  'wtr_ca',
  'wecInvalidation',
];

export const legacyHttpOnlyCookies = [
  'WC_ACTIVEPOINTER',
  'WC_PERSISTENT',
  'WC_USERACTIVITY_-1002',
  'WC_AUTHENTICATION_-1002',
  'WC_GENERIC_ACTIVITYDATA',
  'WC_SESSION_ESTABLISHED',
];

export const legacyUnconfirmedCookies = ['WTR_SSR_USER', 'wtr_status', 'wtr_wc_np'];

export const legacyClientSideAndUnconfirmedCookies = [
  ...legacyClientSideCookies,
  ...legacyUnconfirmedCookies,
];

export const legacyHttpOnlyAndUnconfirmedCookies = [
  ...legacyHttpOnlyCookies,
  ...legacyUnconfirmedCookies,
];

/**
 * Initialize a 'universal' cookie store.
 *
 * Client-side the cookie store is backed by browser cookie storage.
 *
 * Server-side the cookie store is backed by async local storage if available.
 *
 * **Note:** Server-side cookies set using this method are not currently sent
 * to the client.
 *
 * @private
 * @returns {object} Cookie store.
 */
function init() {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();

    return new Cookies(requestContext ? requestContext.get('cookies') : undefined);
  }

  return new Cookies();
}

/**
 * Saves a cookie.
 *
 * Client-side the cookie is stored using browser cookie storage.
 *
 * Server-side the cookie is store using async local storage if available.
 *
 * **Note:** Server-side cookies set using this method are not currently sent
 * to the client.
 *
 * All cookies are stored against the root `/` path unless the path value is passed in.
 *
 * @param {string} name Name of the cookie.
 * @param {string} value The value for the cookie.
 * @param {Date} [expires] Optional expiry for the cookie.
 * @param {string} [domain] Optional domain for the cookie.
 * @param {string} [path] Optional path for the cookie.
 * @returns {true}
 */
export function saveCookie(
  name: string,
  value: string,
  expires?: Date | null,
  domain?: string,
  path: string = '/',
) {
  const cookies = init();

  if (value) {
    cookies.set(name, value, { ...(expires && { expires }), ...(domain && { domain }), path });
  }

  return true;
}

/**
 * Get a cookie by name.
 *
 * Client-side the cookie is retrieved using browser cookie storage.
 *
 * Server-side the cookie is retrieved using async local storage if available.
 *
 * @param {string} name Name of the cookie.
 * @returns {string} The cookie value.
 */
export function getCookie(name: string) {
  const cookies = init();

  return cookies.get(name, { doNotParse: true });
}

/**
 * Get all cookies.
 *
 * Client-side the cookies are retrieved using browser cookie storage.
 *
 * Server-side the cookies are retrieved using async local storage if
 * available.
 *
 * @returns {object} Object of cookie name to cookie value key-value pairs.
 */
export function getAllCookies() {
  const cookies = init();

  return cookies.getAll({ doNotParse: true });
}

/**
 * Remove a cookie by name.
 *
 * Client-side the cookie is removed using browser cookie storage.
 *
 * Server-side the cookie is removed using async local storage if available.
 *
 * @param {string} name Name of the cookie.
 */
export function removeCookie(name: string) {
  const cookies = init();
  cookies.remove(name, { path: '/' });
}

interface CookiesRecord extends Record<string, string | undefined> {
  [COOKIE_CONSENT]?: string;
  [COOKIE_FUNCTIONAL]?: string;
}

/**
 * Determine if functional cookies have been consented to based on a provided
 * cookies object.
 *
 * See also the `getFunctionalCookieState(state)` selector in
 * `redux/modules/cookies/selectors` which is recommended for use-case where
 * you have access to Redux state and require reactivity to changes in consent.
 *
 * @param {object} cookies Object of cookie name to cookie value key-value pairs.
 * @returns {boolean} Whether functional cookies have been consented to.
 */
export const getIsFunctionalCookiesConsented = (cookies: CookiesRecord = {}) => {
  const consentCookie = cookies[COOKIE_CONSENT];
  const functionalCookie = cookies[COOKIE_FUNCTIONAL];

  return consentCookie === '1' && functionalCookie === '1';
};

export const removeClientSideLegacyCookies = () =>
  legacyClientSideAndUnconfirmedCookies.forEach(cookie => removeCookie(cookie));
