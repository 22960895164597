import { createSelector } from 'reselect';

const getSearch = ({ search } = {}) => search;

export const getSearchCleared = createSelector(
  [getSearch],
  ({ searchCleared = false } = {}) => searchCleared,
);

export const getSearchQueryParams = createSelector(
  [getSearch],
  ({ queryParams } = {}) => queryParams,
);

export const getSearchCategory = createSelector(
  [getSearchQueryParams],
  ({ category } = {}) => category,
);

export const getSearchTerm = createSelector(
  [getSearchQueryParams],
  ({ searchTerm } = '') => searchTerm,
);

export const getSearchOrigin = createSelector([getSearch], ({ origin = 'url' } = {}) => origin);

export const getSearchRulesets = createSelector(
  getSearch,
  ({ searchRulesets } = {}) => searchRulesets,
);

// [BM]: guards against `searchRulesets` being null
export const getSearchRulesetsContainsEntry = createSelector(
  [getSearchRulesets, (state, entry) => entry],
  (searchRulesets = [], entry) =>
    searchRulesets && searchRulesets.some(searchRuleset => searchRuleset === entry),
);

export const getSearchWeightings = createSelector(
  getSearch,
  ({ relevancyWeightings } = {}) => relevancyWeightings,
);

export const getSortByQueryParam = createSelector(
  getSearchQueryParams,
  ({ sortBy } = {}) => sortBy,
);
