import {
  HOMEPAGE_FAILED,
  HOMEPAGE_LOADED,
  HOMEPAGE_LOADING,
} from 'redux/modules/search-and-browse/actions/types';

import homepage from 'api/definitions/homepage';

import { getCustomerId } from 'redux/modules/sessions/selectors';
import { clearLocations } from 'redux/modules/content/actions/clear-locations';
import { clearSearch } from 'redux/modules/search/actions/clear-search';

export const fetchHomePage =
  (request = {}) =>
  (dispatch, getState) => {
    const { body = {}, properties, queryParams } = request;

    dispatch(clearSearch());

    dispatch(clearLocations());

    return dispatch({
      apiCall: homepage.put({
        body: {
          contentUrl: '/en',
          customerId: getCustomerId(getState()),
          customerSearchRequest: {
            queryParams: {
              ...(queryParams && { ...queryParams }),
            },
          },
          ...body,
        },
        ...properties,
      }),
      request: {
        name: 'Homepage',
      },
      types: [HOMEPAGE_LOADING, HOMEPAGE_LOADED, HOMEPAGE_FAILED],
    });
  };
