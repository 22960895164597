/* eslint-disable no-param-reassign */
import env from 'env/env';

export const getPathTaxonomyType = path => {
  if (path.includes('/browse/entertaining')) return 'E';
  if (path.includes('/lists')) return 'L';
  if (path.includes('/browse/offers')) return 'O';
  return 'G';
};

export const getPathDepth = path =>
  path
    .split(/[?#]/)
    .shift()
    .split('/')
    .filter(segment => segment && segment !== env.root).length;

export const getPositionsGenerator = () => {
  const breaks = ['xs', 'sm', 'md', 'lg', 'default'];
  const position = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    default: 0,
  };
  return cqResponsive => {
    const visible = {};
    if (cqResponsive && cqResponsive.deviceBreakpoints) {
      cqResponsive.deviceBreakpoints.forEach(breakpoint => {
        visible[breakpoint.name] = breakpoint.visible;
        position[breakpoint.name] += breakpoint.visible ? 1 : 0;
      });
    } else {
      breaks.forEach(name => {
        visible[name] = true;
        position[name] += 1;
      });
    }

    return {
      xs: visible.xs ? position.xs : null,
      sm: visible.sm ? position.sm : null,
      md: visible.md ? position.md : null,
      lg: visible.lg ? position.lg : null,
      xl: visible.default ? position.default : null,
    };
  };
};

const alwaysVisible = {
  deviceBreakpoints: [
    { name: 'xs', visible: true },
    { name: 'sm', visible: true },
    { name: 'md', visible: true },
    { name: 'lg', visible: true },
    { name: 'xl', visible: true },
  ],
};

export const calculateProductsGridPositions = items => {
  // it differs from the aboove it calculates only searchProducts
  const breakpointsPosMap = {};
  const itemsPosMap = {};
  items.forEach(({ searchProduct, cqResponsive = alwaysVisible }) => {
    if (searchProduct && cqResponsive?.deviceBreakpoints) {
      cqResponsive.deviceBreakpoints.forEach(({ name, visible }) => {
        if (name === 'default') name = 'xl';
        if (!breakpointsPosMap[name]) breakpointsPosMap[name] = 0;
        if (!itemsPosMap[searchProduct]) itemsPosMap[searchProduct] = {};
        if (visible) {
          breakpointsPosMap[name] += 1;
          itemsPosMap[searchProduct][name] = breakpointsPosMap[name];
        }
      });
    }
  });
  return itemsPosMap;
};
