import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';

import styles from 'components/Product/Promotion/Description/PromotionDescription.scss';

const IntroductoryOfferPromotionDescription = pure(({ price, underlineText }) => (
  <p>
    <span className={classNames(styles.offerDescription, { [styles.underline]: underlineText })}>
      Introductory Offer.
      <em>
        Will be
        {` ${price}`}
      </em>
    </span>
  </p>
));

IntroductoryOfferPromotionDescription.displayName = 'IntroductoryOfferPromotionDescription';

IntroductoryOfferPromotionDescription.propTypes = {
  price: PropTypes.string.isRequired,
};

export default IntroductoryOfferPromotionDescription;
