import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import classNames from 'classnames';

import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'constants/directions';
import { KEY_ENTER } from 'constants/keys';

import { ChevronRight, ChevronLeft } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './HorizontalSliderControl.scss';

const HorizontalSliderControl = pure(({ direction, display, hover, onClick }) => {
  const handleKeypress = event => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      onClick(direction);
    }
  };

  return (
    <span
      className={classNames({
        [styles.hover]: hover,
        [styles[`${direction}Control`]]: display,
        [styles[`${direction}ControlOff`]]: !display,
      })}
      id={`slide-${direction}`}
      onClick={() => onClick(direction)}
      onKeyDown={handleKeypress}
      role="button"
      tabIndex="0"
      title={`Scroll ${direction}`}
    >
      {direction === DIRECTION_LEFT ? (
        <ChevronLeft className={styles.icon} size="medium" />
      ) : (
        <ChevronRight className={styles.icon} size="medium" />
      )}
    </span>
  );
});

HorizontalSliderControl.displayName = 'HorizontalSliderControl';

HorizontalSliderControl.propTypes = {
  direction: PropTypes.oneOf([DIRECTION_LEFT, DIRECTION_RIGHT]),
  display: PropTypes.bool,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
};

HorizontalSliderControl.defaultProps = {
  direction: DIRECTION_RIGHT,
  display: false,
  hover: false,
  onClick: () => {},
};

export default HorizontalSliderControl;
