import { LOCATION_CHANGE } from 'redux-first-history';
import update from 'immutability-helper';

import { initialState } from 'redux/modules/page/state/initial-state';

import {
  CLIENT_PAGE_LOADED,
  CLIENT_PAGE_LOADING,
  PAGE_CLEAR_COOKIES,
  PAGE_CLEAR_PRELOADED,
  PAGE_CLEAR_SERVER_GTM_EVENTS,
  PAGE_CLEAR_SERVER_MONETATE_EVENTS,
  PAGE_SET_PRELOADED,
  PAGE_SET_REQUEST_COOKIES,
  PAGE_SET_RESPONSE_COOKIES,
  PAGE_SET_SERVER_GTM_EVENTS,
  PAGE_SET_SERVER_MONETATE_EVENTS,
  SETMEGAMENU,
  SET_MEGAMENU_ACTIVE_CATEGORY,
  SET_MEGAMENU_ROOT_CATEGORY,
  SETMEGAMENUACTIVELEVEL,
  SETMEGAMENUSTART,
  SETCLEARVALUE,
  SETMOBILEOVERLAY,
  SETPAGEDEPTH,
  TOGGLE_PIN_BAR_OPEN,
  PAGE_DATA_PRELOAD_IN_PROGRESS,
} from 'redux/modules/page/actions/types';

export default function page(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENT_PAGE_LOADED:
      return {
        ...state,
        isPageLoading: false,
        isClientPageLoaded: true,
        isInitialPageLoaded: true,
        isDataPreloadInProgress: false,
      };
    case CLIENT_PAGE_LOADING:
      return {
        ...state,
        isClientPageLoaded: false,
      };
    case PAGE_DATA_PRELOAD_IN_PROGRESS:
      return {
        ...state,
        isDataPreloadInProgress: true,
      };
    case PAGE_CLEAR_COOKIES:
      return {
        ...state,
        requestCookies: initialState.requestCookies,
        responseCookies: initialState.responseCookies,
      };
    case PAGE_CLEAR_PRELOADED:
      return {
        ...state,
        preloadedPath: '',
      };
    case PAGE_CLEAR_SERVER_GTM_EVENTS:
      return {
        ...state,
        serverGtmEvents: initialState.serverGtmEvents,
      };
    case PAGE_CLEAR_SERVER_MONETATE_EVENTS:
      return {
        ...state,
        serverMonetateEvents: initialState.serverMonetateEvents,
      };
    case LOCATION_CHANGE: {
      if (action.payload.initialPageLoad) {
        return state;
      }

      const isSamePathname =
        action.payload.location.pathname === action.payload.location.previousPaths.at(-1);

      return {
        ...state,
        isPageLoading: isSamePathname ? state.isPageLoading : true,
        navigation: update(state.navigation, {
          history: {
            $unshift: [
              {
                action: action.payload.action,
                pathname: action.payload.location.pathname,
              },
            ],
          },
        }),
      };
    }
    case PAGE_SET_PRELOADED:
      return {
        ...state,
        preloadedPath: action.payload,
      };
    case PAGE_SET_REQUEST_COOKIES: {
      if (!action.payload) {
        return state;
      }

      if (!state.requestCookies) {
        return {
          ...state,
          requestCookies: action.payload,
        };
      }

      return {
        ...state,
        requestCookies: `${state.requestCookies}; ${action.payload}`,
      };
    }
    case PAGE_SET_RESPONSE_COOKIES:
      return {
        ...state,
        responseCookies: [...state.responseCookies, ...action.payload.filter(item => item)],
      };
    case PAGE_SET_SERVER_GTM_EVENTS:
      return {
        ...state,
        serverGtmEvents: [...action.payload],
      };
    case PAGE_SET_SERVER_MONETATE_EVENTS:
      return {
        ...state,
        serverMonetateEvents: [...action.payload],
      };
    case SETMOBILEOVERLAY:
      return {
        ...state,
        isMobileOverlayOpen: action.isMobileOverlayOpen,
      };
    case SETCLEARVALUE:
      return {
        ...state,
        isValueClear: action.isValueClear,
      };
    case TOGGLE_PIN_BAR_OPEN:
      return {
        ...state,
        isPinBarOpen: !state.isPinBarOpen,
      };
    case SETMEGAMENU:
      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          menus: {
            $splice: [
              [
                action.level,
                state.megaMenu.menus.length,
                {
                  id: action.id,
                  name: action.name,
                  urlName: action.urlName,
                  path: action.path,
                  banner: action.banner,
                },
              ],
            ],
          },
        }),
      };
    case SET_MEGAMENU_ACTIVE_CATEGORY: {
      const { categoryId } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          activeItem: { $set: categoryId },
        }),
      };
    }
    case SET_MEGAMENU_ROOT_CATEGORY: {
      const { categoryId } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          rootCategory: { $set: categoryId },
        }),
      };
    }
    case SETMEGAMENUACTIVELEVEL: {
      const { level } = action;

      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          activeLevel: { $set: level },
        }),
      };
    }
    case SETMEGAMENUSTART:
      return {
        ...state,
        megaMenu: update(state.megaMenu, {
          menus: {
            $set: [
              {
                id: action.id,
                name: action.name,
                path: action.path,
                urlName: action.urlName,
              },
            ],
          },
          menuRoot: { $set: action.menuRoot },
          activeLevel: { $set: 0 },
          maxLength: { $set: action.maxLength },
        }),
      };
    case SETPAGEDEPTH:
      return {
        ...state,
        currentPageDepth: action.pageType + (action.pageDepth ? action.pageDepth - 1 : 0),
      };
    default:
      return state; // no match found, just return the state as is
  }
}
