import { MARKETING_PREFERENCES_ACKNOWLEDGED } from 'redux/modules/my-account/actions/types';

import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  preferencesAcknowledged: false,
});

export default function myAccount(state = initialState, action = {}) {
  switch (action.type) {
    case MARKETING_PREFERENCES_ACKNOWLEDGED:
      return {
        ...state,
        preferencesAcknowledged: true,
      };
    default:
      return state;
  }
}
