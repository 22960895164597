import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import PortalModal from 'components/PortalModal';
import FocusTrap from 'components/FocusTrap';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import urls from 'constants/urls';

import styles from '../CookieAlert.scss';

const InitialModal = ({ showInitialModal, acceptAll, rejectAll, viewPreferences }) => (
  <PortalModal
    anchoredBottomSmall
    closeOnOverlayClick={false}
    inline
    modalHeadingID="CookieAlertModalHeading"
    show={showInitialModal}
    spaceAroundLarge
    webViewIdForOverlay="cookie-overlay"
  >
    <FocusTrap
      active
      focusTrapOptions={{
        escapeDeactivates: false,
      }}
      className={styles.focusTrap}
    >
      <section className={styles.banner} data-testid="initial-waitrose-cookie-consent-banner">
        <h2 className={styles.privacyModalHeader} id="CookieAlertModalHeading">
          Our cookies
        </h2>
        <div className={styles.optionsContainer}>
          <p>
            Our website uses cookies to analyse traffic, personalise advertising and show you more
            of what you love. Please let us know you agree to all of our cookies. You can find out
            more on our{' '}
            <TextLink
              component={Link}
              to={urls.waitroseCookies}
              className={styles.waitroseCookiesLink}
            >
              cookies policy
            </TextLink>{' '}
            page any time.
          </p>
        </div>
        <div className={styles.cookiesCTA}>
          <Button
            autoFocus
            data-testid="accept-all"
            data-webviewid="accept-cookies"
            type="button"
            width="standard"
            theme="primary"
            onClick={acceptAll}
            className={styles.acceptAll}
          >
            Allow all
          </Button>
          <Button
            data-testid="reject-all"
            data-webviewid="reject-cookies"
            type="button"
            width="standard"
            theme="secondary"
            onClick={rejectAll}
            className={styles.rejectAll}
          >
            Reject all
          </Button>
          <TextLink
            component="button"
            className={styles.manageCookies}
            data-testid="manage-cookies"
            onClick={viewPreferences}
            underline="always"
          >
            Manage cookies
          </TextLink>
        </div>
      </section>
    </FocusTrap>
  </PortalModal>
);

InitialModal.propTypes = {
  showInitialModal: PropTypes.bool.isRequired,
  acceptAll: PropTypes.func.isRequired,
  rejectAll: PropTypes.func.isRequired,
  viewPreferences: PropTypes.func.isRequired,
};

export default InitialModal;
