const favouritesOfferFilterImpressions = (
  _state: WtrState,
  { payload: { onOffer } }: { payload: { onOffer: boolean } },
) => {
  return {
    event: 'favourites_filter',
    filter_selection: onOffer ? 'On Offer' : 'All',
  };
};

export default favouritesOfferFilterImpressions;
