import { createSelector } from 'reselect';

const getCustomerAccount = state => state?.accounts?.account;

const getCustomerEmail = createSelector([getCustomerAccount], account => account?.email);

const getCustomerTitleFirstNameLastName = createSelector(
  [getCustomerAccount],
  ({ title, firstName, lastName } = {}) => ({
    title,
    firstName,
    lastName,
  }),
);

const getCustomerSalutation = createSelector(
  getCustomerAccount,
  ({ firstName, lastName, title }) => {
    if (!!title && !!firstName && !!lastName) {
      return `${title} ${firstName} ${lastName}`;
    }

    if (!!title && !!lastName) {
      return `${title} ${lastName}`;
    }

    if (!!firstName && !!lastName) {
      return `${firstName} ${lastName}`;
    }

    return null;
  },
);

const getCustomerOrderTotal = createSelector(
  [getCustomerAccount],
  account => account?.totalOrderCount ?? 0,
);

const customerHasValidNameData = createSelector(
  getCustomerTitleFirstNameLastName,
  ({ firstName, lastName }) => !!firstName && !!lastName,
);

export {
  customerHasValidNameData,
  getCustomerAccount,
  getCustomerEmail,
  getCustomerOrderTotal,
  getCustomerSalutation,
  getCustomerTitleFirstNameLastName,
};
