import {
  featuredPromotions,
  introductoryOfferPromotions,
  percentOffPromotions,
  priceMatchPromotions,
} from 'constants/promotion-types';

export const brandPriceMatchPromotion = promotionTypeCode =>
  priceMatchPromotions.has(promotionTypeCode);

export const featuredPromotion = promotionTypeCode => featuredPromotions.has(promotionTypeCode);

export const introductoryOfferPromotion = promotionTypeCode =>
  introductoryOfferPromotions.has(promotionTypeCode);

export const percentageOffPromotion = promotionTypeCode =>
  percentOffPromotions.has(promotionTypeCode);
