import queryString from 'query-string';
import locator from 'utils/locator';
import historyUtils from 'utils/history';

export const queryStringToObject = () => queryString.parse(locator.search);
export const wrapParamInArray = param => (Array.isArray(param) ? param : [param]);
export const filterSearchQuery = filter => {
  const query = queryString.parse(historyUtils.getLocation().search);
  const filteredQuery = Object.entries(query).reduce(
    (acc, [key, value]) => (key === filter ? { ...acc, [key]: value } : acc),
    {},
  );
  return queryString.stringify(filteredQuery);
};

export const typedValueFromString = string => {
  if (string === undefined) {
    return true;
  }
  if (string === 'true' || string === 'false') {
    return string === 'true';
  }
  if (Number.isFinite(+string)) {
    return +string;
  }
  if (string === 'null') {
    return null;
  }
  if (string === 'undefined') {
    return undefined;
  }

  return string;
};
