export default (address = {}) => {
  const {
    id = '',
    isContactAddress,
    line1,
    line2,
    line3,
    region,
    town,
    country,
    postalCode,
    addressee,
  } = address;
  const { title, firstName, lastName, contactNumber } = addressee || {};
  return {
    id,
    isContactAddress,
    line1,
    line2,
    line3,
    region,
    town,
    country,
    postalCode,
    title,
    firstName,
    lastName,
    contactNumber,
  };
};
