import { fulfilmentTypes } from 'constants/fulfilmentTypes';
import { isEntertainingOrSimilar } from 'constants/productTypes';

import { fromDateUrlParam } from 'redux/modules/slot-booking/utils/date-url-params';

import { getProductById } from 'redux/modules/entities/selectors/products';
import {
  getFulfilmentType,
  getPathName,
} from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import { groceriesItemInTrolley } from 'redux/modules/slot-booking/selectors/groceries-item-in-trolley';

export const entertainingItemInTrolley = state => {
  const myTrolleyItems = state.trolley.trolleyItems.map(trolleyItems =>
    isEntertainingOrSimilar((getProductById(state, trolleyItems.productId) || {}).productType),
  );
  return myTrolleyItems.some(entertainingItem => entertainingItem === true);
};

export const entertainingItemInTrolleyAddedPreAmend = state => {
  const myTrolleyItems = state.trolley.trolleyItems
    .filter(entertainingItem => entertainingItem.reservedQuantity > 0)
    .map(trolleyItems =>
      isEntertainingOrSimilar((getProductById(state, trolleyItems.productId) || {}).productType),
    );
  return myTrolleyItems.some(entertainingItem => entertainingItem === true);
};

export const isTabCompatibleWithTrolleySelector = state => {
  const fulfilmentType = getFulfilmentType(state);

  return (
    fulfilmentType?.id === fulfilmentTypes.GROCERIES_COLLECTION.id ||
    fulfilmentType?.id === fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION.id ||
    (fulfilmentType?.id === fulfilmentTypes.ENTERTAINING_COLLECTION.id &&
      !groceriesItemInTrolley(state))
  );
};

export const showExpiredOffersModal = state => state.slotBooking.showExpiredOffersModal;

export const getProposedSlot = state => state.slotBooking.proposedSlot;

export const isNewSlotSelectedThisPageVisitSelector = state =>
  !!state.slotBooking.hasSelectedNewSlotThisPageVisit;

const getSeasonFromURL = campaigns => {
  const pathname = getPathName();
  let result;
  if (campaigns) {
    Object.keys(campaigns).forEach(key => {
      if (pathname.includes(key.toLowerCase())) {
        result = key;
      }
    });
  }

  return result;
};

export const createCollectionTabURL = (fulfilmentType, campaigns) => {
  const seasonFromURL = getSeasonFromURL(campaigns);
  if (seasonFromURL) {
    return `/ecom/bookslot/${
      fulfilmentType.path
    }/${seasonFromURL.toLowerCase()}${fromDateUrlParam()}`;
  }
  return `/ecom/bookslot/${fulfilmentType.path}${fromDateUrlParam()}`;
};

export const isPageFulfilmentTypeCollectionSelector = state =>
  getFulfilmentType(state)?.category === 'COLLECTION' ?? null;

export const getCollectionTypeUrl = (values, campaign) => {
  if (values.groceries === true) {
    return `/ecom/bookslot/collection/groceries${
      campaign ? `/${campaign}` : ''
    }${fromDateUrlParam()}`;
  }
  if (values.entertaining === true) {
    return `/ecom/bookslot/collection/entertaining${
      campaign ? `/${campaign}` : ''
    }${fromDateUrlParam()}`;
  }
  return false;
};
