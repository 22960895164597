const addressKeys = ['line1', 'line2', 'line3', 'town', 'region', 'country', 'postalCode'];
const addresseeKeys = ['title', 'firstName', 'lastName', 'contactNumber'];
const requiredAddressFields = ['line1', 'country', 'postalCode'];
const requiredAddresseeFields = ['firstName', 'lastName'];

const valueChanged = (control, value) =>
  (!!control && !value) || (!control && !!value) || (!!control && !!value && control !== value);

const validateUpdateAddress = {
  addressRequiredFields(address) {
    return requiredAddressFields.every(key => address[key] && address[key].length !== 0);
  },
  addresseeRequiredFields(addressee) {
    return requiredAddresseeFields.every(key => addressee[key] && addressee[key].length !== 0);
  },
  addressChanged(control, address) {
    return addressKeys.some(key => valueChanged(control[key], address[key]));
  },
  addresseeChanged(control, addressee) {
    return addresseeKeys.some(key => valueChanged(control[key], addressee[key]));
  },
};

const propertiesChanged = (control, address) => {
  const mappedFields = [
    ...addressKeys.map(key => (valueChanged(control[key], address[key]) ? key : undefined)),
    ...addresseeKeys.map(key => (valueChanged(control[key], address[key]) ? key : undefined)),
  ].reduce((acc, val) => acc.concat(val), []);
  return mappedFields.filter(Boolean);
};

const formPlaceholders = {
  uKPlaceholders: [
    'Address line 1',
    'Address line 2',
    'Address line 3',
    'Town',
    'County',
    'Postcode',
  ],
  internationalPlaceholders: [
    'Address line 1',
    'Address line 2',
    'Address line 3',
    'City',
    'State / Province / Region',
    'ZIP / Postal Code',
  ],
};

const selectTitles = [
  {
    label: 'Please select...',
    value: '',
  },
  {
    label: 'Mr',
    value: 'Mr',
  },
  {
    label: 'Mrs',
    value: 'Mrs',
  },
  {
    label: 'Miss',
    value: 'Miss',
  },
  {
    label: 'Ms',
    value: 'Ms',
  },
  {
    label: 'Mx',
    value: 'Mx',
  },
  {
    label: 'Dr',
    value: 'Dr',
  },
  {
    label: 'Lord',
    value: 'Lord',
  },
  {
    label: 'Lady',
    value: 'Lady',
  },
  {
    label: 'Prof',
    value: 'Prof',
  },
  {
    label: 'Reverend',
    value: 'Reverend',
  },
  {
    label: 'Sir',
    value: 'Sir',
  },
  {
    label: 'Dame',
    value: 'Dame',
  },
];

const manualAddressErrorOrder = [
  'addressApiError',
  'title',
  'contactNumber',
  'firstName',
  'lastName',
  'line1',
  'postalCode',
];

export {
  validateUpdateAddress,
  formPlaceholders,
  selectTitles,
  manualAddressErrorOrder,
  propertiesChanged,
};
