import { RESET_UPDATE_CONTACT_ADDRESS_ERROR } from '../actions/types';

const resetUpdateContactAddressError = state => ({
  ...state,
  updateContactAddressError: false,
});

export default map => {
  map.set(RESET_UPDATE_CONTACT_ADDRESS_ERROR, resetUpdateContactAddressError);
  return map;
};
