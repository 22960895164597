import React from 'react';
import PropTypes from 'prop-types';

import ConflictMessage from 'components/Conflict/ConflictMessage';
import ResolutionMessage from 'components/Conflict/Resolution/Message';

import { bookSlotQueryString } from 'utils/book-slot-query-string';

const ChangeSlotDate = ({
  className,
  conflictMessage,
  editSlotUrl,
  onPDP,
  resolutionAction: { message, slotOptionDates = [], nextSlotDate = '' },
  textOnly,
}) => {
  // TODO: remove when we switch to new trolley API and no longer use nextSlotDate
  const slotOptionDatesWithNextSlot =
    nextSlotDate?.length > 0
      ? [
          ...slotOptionDates,
          {
            type: 'ENFORCED_EARLIEST',
            date: nextSlotDate,
          },
        ]
      : [...slotOptionDates];

  const props = {
    className: className || '',
    onPDP,
    pathname: editSlotUrl,
    resolution: <ResolutionMessage text={message} />,
    search: bookSlotQueryString(editSlotUrl, slotOptionDatesWithNextSlot),
    text: conflictMessage,
    textOnly,
  };

  return <ConflictMessage {...props} />;
};

ChangeSlotDate.propTypes = {
  className: PropTypes.string,
  conflictMessage: PropTypes.string.isRequired,
  editSlotUrl: PropTypes.string.isRequired,
  onPDP: PropTypes.bool,
  resolutionAction: PropTypes.shape({
    message: PropTypes.string,
    nextSlotDate: PropTypes.string,
    slotOptionDates: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ),
  }),
  textOnly: PropTypes.bool,
};

ChangeSlotDate.defaultProps = {
  className: '',
  onPDP: false,
  resolutionAction: {},
  textOnly: false,
};

export default ChangeSlotDate;
