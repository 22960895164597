import { apiCallback } from 'api';

import cancelAmend from 'api/definitions/amendOrder/cancel-amend.graphql';
import startAmend from 'api/definitions/amendOrder/start-amend.graphql';

const definition = (query, tag) => ({
  graph: true,
  query: () => query,
  variables: ({ orderIdToAmend }) => ({ customerOrderId: orderIdToAmend }),
  queryString: { tag },
});

export default {
  cancel: apiCallback(definition(cancelAmend, 'cancel-amend-order'), 'post'),
  start: apiCallback(definition(startAmend, 'initiate-amend-order'), 'post'),
};
