export const CLEAR_LAST_REMOVED_TROLLEY_ITEM =
  'waitrose/trolley/CLEAR_LAST_REMOVED_TROLLEY_ITEM' as const;
export const CLEAR_ITEMS_SET_FOR_REMOVAL = 'waitrose/trolley/CLEAR_ITEMS_SET_FOR_REMOVAL' as const;
export const CREATE_NEW_TROLLEY_ITEM = 'waitrose/trolley/CREATE_NEW_TROLLEY_ITEM' as const;

export const FILTER_TROLLEY_ANALYTICS = 'waitrose/trolley/FILTER_TROLLEY_ANALYTICS' as const;

export const GET_MINIMUM_ORDER_VALUES = 'waitrose/trolley/GET_MINIMUM_ORDER_VALUES' as const;
export const GET_MINIMUM_ORDER_VALUES_SUCCESS =
  'waitrose/trolley/GET_MINIMUM_ORDER_VALUES_SUCCESS' as const;
export const GET_MINIMUM_ORDER_VALUES_FAILED =
  'waitrose/trolley/GET_MINIMUM_ORDER_VALUES_FAILED' as const;

export const GET_SUBSTITUTION_PRODUCTS = 'waitrose/trolley/GET_SUBSTITUTION_PRODUCTS' as const;
export const GET_SUBSTITUTION_PRODUCTS_SUCCESS =
  'waitrose/trolley/GET_SUBSTITUTION_PRODUCTS_SUCCESS' as const;
export const GET_SUBSTITUTION_PRODUCTS_FAILED =
  'waitrose/trolley/GET_SUBSTITUTION_PRODUCTS_FAILED' as const;

export const GET_TROLLEY_RECOMMENDATION_SUCCESS =
  'waitrose/trolley/GET_TROLLEY_RECOMMENDATION_SUCCESS' as const;

export const LOCK_TROLLEY = 'waitrose/trolley/LOCK_TROLLEY' as const;
export const UNLOCK_TROLLEY = 'waitrose/trolley/UNLOCK_TROLLEY' as const;

export const ORDER_LOADING = 'waitrose/trolley/ORDER_LOADING' as const;
export const ORDER_LOADED = 'waitrose/trolley/ORDER_LOADED' as const;
export const ORDER_LOAD_FAILED = 'waitrose/trolley/ORDER_LOAD_FAILED' as const;

export const REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY =
  'waitrose/trolley/REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY' as const;
export const REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS =
  'waitrose/trolley/REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS' as const;
export const REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED =
  'waitrose/trolley/REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED' as const;

export const REMOVE_ITEM_FROM_TROLLEY = 'waitrose/trolley/REMOVE_ITEM_FROM_TROLLEY' as const;

export const SET_UNAVAILABLE_ITEM_FOR_REMOVAL =
  'waitrose/trolley/SET_UNAVAILABLE_ITEM_FOR_REMOVAL' as const;

export const SUB_ALL_UPDATING = 'waitrose/trolley/SUB_ALL_UPDATING' as const;
export const SUB_ALL_UPDATED = 'waitrose/trolley/SUB_ALL_UPDATED' as const;
export const SUB_ALL_UPDATE_FAILED = 'waitrose/trolley/SUB_ALL_UPDATE_FAILED' as const;

export const TOGGLE_EMPTY_TROLLEY_MODAL = 'waitrose/trolley/TOGGLE_EMPTY_TROLLEY_MODAL' as const;
export const TOGGLE_PERSONALISED_MESSAGE_MODAL =
  'waitrose/trolley/TOGGLE_PERSONALISED_MESSAGE_MODAL' as const;

export const TOGGLE_SHOPPER_NOTES_MODAL = 'waitrose/trolley/TOGGLE_SHOPPER_NOTES_MODAL' as const;

export const TOGGLE_SUB_ALL_MODAL = 'waitrose/trolley/TOGGLE_SUB_ALL_MODAL' as const;

export const TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL =
  'waitrose/trolley/TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL' as const;

export const TRACK_VIEW_TROLLEY = 'waitrose/trolley/TRACK_VIEW_TROLLEY' as const;

export const TROLLEY_EMPTIED = 'waitrose/trolley/TROLLEY_EMPTIED' as const;

export const TROLLEY_ITEM_UPDATING = 'waitrose/trolley/TROLLEY_ITEM_UPDATING' as const;
export const TROLLEY_ITEM_UPDATED = 'waitrose/trolley/TROLLEY_ITEM_UPDATED' as const;
export const TROLLEY_ITEM_UPDATE_FAILED = 'waitrose/trolley/TROLLEY_ITEM_UPDATE_FAILED' as const;

export const TROLLEY_QUEUE_ITEM_UPDATING = 'waitrose/trolley/TROLLEY_QUEUE_ITEM_UPDATING';
export const TROLLEY_QUEUE_ITEM_UPDATED = 'waitrose/trolley/TROLLEY_QUEUE_ITEM_UPDATED';
export const TROLLEY_QUEUE_ITEM_UPDATE_FAILED = 'waitrose/trolley/TROLLEY_QUEUE_ITEM_UPDATE_FAILED';

export const UPDATE_CAN_SUBSTITUTE = 'waitrose/trolley/UPDATE_CAN_SUBSTITUTE';
export const UPDATE_PERSONALISED_MESSAGE = 'waitrose/trolley/UPDATE_PERSONALISED_MESSAGE';
export const UPDATE_SHOPPER_NOTES = 'waitrose/trolley/UPDATE_SHOPPER_NOTES';

export const UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY =
  'waitrose/trolley/UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY';
export const UPDATE_TROLLEY_ITEM = 'waitrose/trolley/UPDATE_TROLLEY_ITEM';

export const OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS =
  'waitrose/trolley/OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS';

export const OPTIMISTICALLY_UPDATE_TROLLEY_ITEM =
  'waitrose/trolley/OPTIMISTICALLY_UPDATE_TROLLEY_ITEM';

export const OPTIMISTICALLY_UNDO_REMOVE = 'waitrose/trolley/OPTIMISTICALLY_UNDO_REMOVE';

export const OPTIMISTICALLY_TOGGLE_SUB_ALL = 'waitrose/trolley/OPTIMISTICALLY_TOGGLE_SUB_ALL';

export const REMOVE_OPTIMISTIC_TROLLEY_ITEM = 'waitrose/trolley/REMOVE_OPTIMISTIC_TROLLEY_ITEM';
export const REMOVE_ALL_OPTIMISTIC_TROLLEY_ITEMS =
  'waitrose/trolley/REMOVE_ALL_OPTIMISTIC_TROLLEY_ITEMS';

export const REMOVE_MULTIPLE_OPTIMISTIC_TROLLEY_ITEMS =
  'waitrose/trolley/REMOVE_MULTIPLE_OPTIMISTIC_TROLLEY_ITEMS';

export const UPDATE_INSTANT_CHECKOUT_LOCATION = 'waitrose/trolley/UPDATE_INSTANT_CHECKOUT_LOCATION';

export const UNDO_REMOVE_TROLLEY_ITEM = 'waitrose/trolley/UNDO_REMOVE_TROLLEY_ITEM';
export const UNDO_REMOVE_TROLLEY_ITEM_SUCCESS = 'waitrose/trolley/UNDO_REMOVE_TROLLEY_ITEM_SUCCESS';
export const UNDO_REMOVE_TROLLEY_ITEM_FAILURE = 'waitrose/trolley/UNDO_REMOVE_TROLLEY_ITEM_FAILURE';

export const UPDATE_TROLLEY_SORT = 'waitrose/trolley/UPDATE_TROLLEY_SORT';
