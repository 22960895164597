import { CheckoutState } from '../state/initial-state';

interface Payload {
  placeOrderOrigin: CheckoutState['placeOrderOrigin'];
}

export const placeOrderOrigin: WtrReducer<CheckoutState, Payload> = (state, { payload }) => ({
  ...state,
  placeOrderOrigin: payload.placeOrderOrigin,
});
