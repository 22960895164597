import getCardNumber from 'redux/modules/checkout/selectors/get-card-number';
import { getIsConfirmingInstantCheckout } from 'redux/modules/instant-checkout/selectors';
import { isBookedSlotAnEntertainingCollectionSlot } from 'redux/modules/order/selectors/get-order-slot-selectors';
import { getEstimatedTotals } from 'redux/modules/order/selectors/get-order-totals';
import { getCardType } from 'utils/paymentCardUtils';

const getCardPaymentType = state => {
  const paymentCard = getCardNumber(state);
  const cardInitialDigits = paymentCard?.cardNumber?.substring(0, 6);
  const cardType = getCardType(cardInitialDigits);

  switch (cardType?.type) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'maestro':
      return 'Maestro';
    case 'american-express':
      return 'American Express';
    case 'business-account-card':
      return 'Business Account Card';
    default:
      return 'Unknown';
  }
};

export const getPaymentTypes = state => {
  const isInstantCheckout = getIsConfirmingInstantCheckout(state);
  if (isInstantCheckout) return undefined;

  if (isBookedSlotAnEntertainingCollectionSlot(state)) {
    return 'None';
  }

  const estimate = getEstimatedTotals(state);

  return (
    [
      estimate?.giftCards?.amount && 'Gift Card',
      estimate?.giftVouchers?.amount && 'Gift Voucher',
      estimate?.paymentCard?.amount && getCardPaymentType(state),
    ]
      .filter(Boolean)
      .join('/') || 'None'
  );
};
