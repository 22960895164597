import { pluralize } from 'utils/format';

const counts = state => state.trolley.trolleyTotals.trolleyItemCounts;

export const getNumberOfAvailableItems = state => {
  const { noConflicts = 0, softConflicts = 0 } = counts(state);

  return softConflicts + noConflicts;
};

export const getTotalNumberOfItems = state => {
  const noOfItems = getNumberOfAvailableItems(state);
  const { hardConflicts = 0 } = counts(state);

  return noOfItems + hardConflicts;
};

export const getNumberOfAvailableItemsTitle = state => {
  const noOfItems = getNumberOfAvailableItems(state);

  return pluralize(noOfItems, 'item');
};
