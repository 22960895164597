import get from 'lodash/get';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

const getAutoSuggest = state => state.autoSuggest;

const getAutoSuggestResults = createSelector([getAutoSuggest], autoSuggest =>
  get(autoSuggest, 'results', {}),
);

export const getAutoSuggestActive = createSelector(
  [getAutoSuggest],
  autoSuggest => get(autoSuggest, 'active', false),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const getAutoSuggestSuggestions = createSelector([getAutoSuggestResults], results =>
  get(results, 'terms', []),
);

export const getAutoSuggestSearchTerm = createSelector([getAutoSuggest], autoSuggest =>
  get(autoSuggest, 'searchTerm', null),
);
