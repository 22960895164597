import update from 'immutability-helper';

import { GET_ORDER } from 'redux/modules/order/actions/types';
import { GET_ORDER_LOADED, GET_ORDER_RECOMMENDATIONS } from 'redux/modules/orders/actions/types';

const orders = (state = {}, action = {}) => {
  const { type } = action;

  switch (type) {
    case GET_ORDER.success:
    case GET_ORDER_LOADED: {
      const { result: order = {} } = action;
      const { customerOrderId } = order;

      return update(state, {
        $merge: { [customerOrderId]: { ...state[customerOrderId], ...order } },
      });
    }
    case GET_ORDER_RECOMMENDATIONS.success: {
      const { customerOrderId, result: { products, totalResults } = {} } = action;

      return update(state, {
        $merge: {
          [customerOrderId]: {
            ...state[customerOrderId],
            recommendedProducts: { products, totalResults },
          },
        },
      });
    }
    default:
      return state;
  }
};

export default orders;
