import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FORMAT, format } from 'utils/date';

import env from 'env/env';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import CloseIcon from '@johnlewispartnership/wtr-ingredients/dist/Icon/Close';

import AnchorLink from 'components/AnchorLink';

import styles from './MyWaitrosePopover.scss';

const MyWaitrosePopOver = memo(({ closeButton, promotion }) => {
  const { promotionDescription, promotionExpiryDate, promotionId } = promotion;

  if (!promotionId) return null;

  const expiryMessage =
    promotionExpiryDate &&
    ` This myWaitrose saving is available for delivery or collection until ${format(
      promotionExpiryDate,
      FORMAT.DAY_MONTH_YEAR_SLASH,
    )}`;

  return (
    <div className={styles.wrapper}>
      {closeButton && (
        <div className={styles.closeButtonContainer}>
          <Button
            aria-label="Close tooltip"
            startIcon={<CloseIcon />}
            onClick={closeButton}
            theme="secondary"
            data-testid="close-button"
          />
        </div>
      )}
      {promotionDescription && <p className={styles.title}>{promotionDescription}</p>}
      <p className={styles.text}>Offer applies to myWaitrose members only.{expiryMessage}</p>
      <AnchorLink className={styles.link} to={`${env.root}/shop/offers/${promotionId}`}>
        See all products in this offer
      </AnchorLink>
    </div>
  );
});

MyWaitrosePopOver.propTypes = {
  closeButton: PropTypes.func,
  promotion: PropTypes.shape({
    promotionDescription: PropTypes.string,
    promotionExpiryDate: PropTypes.string,
    promotionId: PropTypes.number,
  }),
};

MyWaitrosePopOver.defaultProps = {
  closeButton: null,
  promotion: {},
};

MyWaitrosePopOver.displayName = 'MyWaitrosePopOver';

export default MyWaitrosePopOver;
