import { pathJoin } from 'utils/general';
import queryString from 'query-string';

import env from 'env/env';
import { usingStubs } from 'stubs';

const dataType = () => (usingStubs() ? 'stubs' : 'live');
const formatClientGraphUrl = path => pathJoin(env.apiPath, path);
const formatServerGraphUrl = path => pathJoin(env.serverPath, path);
const graphPath = () => pathJoin(`graphql-${env.apiEnv}`, 'graph', dataType());

export const buildGraphUrl = ({ query, server = __SERVER__ } = {}) => {
  const path = graphPath();

  const url = server ? formatServerGraphUrl(path) : formatClientGraphUrl(path);

  return query ? `${url}?${queryString.stringify({ ...query, clientType: 'WEB_APP' })}` : url;
};
