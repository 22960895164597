import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const getMembershipActionTypes = apiPromiseTypes('memberships', 'get');

export const LOAD_PREFERENCES_PENDING =
  'waitrose/marketing-preferences/LOAD_PREFERENCES_PENDING' as const;
export const LOAD_PREFERENCES_FULFILLED =
  'waitrose/marketing-preferences/LOAD_PREFERENCES_FULFILLED' as const;
export const LOAD_PREFERENCES_REJECTED =
  'waitrose/marketing-preferences/LOAD_PREFERENCES_REJECTED' as const;
export const LOAD_PREFERENCES_ACTIONS = [
  LOAD_PREFERENCES_PENDING,
  LOAD_PREFERENCES_FULFILLED,
  LOAD_PREFERENCES_REJECTED,
] as const;

export const SET_CONSENT_PENDING = 'waitrose/marketing-preferences/SET_CONSENT_PENDING' as const;
export const SET_CONSENT_FULFILLED =
  'waitrose/marketing-preferences/SET_CONSENT_FULFILLED' as const;
export const SET_CONSENT_REJECTED = 'waitrose/marketing-preferences/SET_CONSENT_REJECTED' as const;
export const SET_CONSENT_ACTIONS = [
  SET_CONSENT_PENDING,
  SET_CONSENT_FULFILLED,
  SET_CONSENT_REJECTED,
] as const;

export const REMOVE_MARKETING_PREFERENCES =
  'waitrose/marketing-preferences/REMOVE_MARKETING_PREFERENCES' as const;

export const REPORT_MARKETING_CONSENTS =
  'waitrose/marketing-preferences/REPORT_MARKETING_CONSENTS' as const;
