import { createSelector } from 'reselect';

export const getPage = state => state.page;

export const getCurrentPageDepth = createSelector(
  [getPage],
  ({ currentPageDepth = '0' } = {}) => currentPageDepth,
);

export const getPreloadedPath = createSelector([getPage], (page = {}) => page.preloadedPath);

export const getIsPreloaded = state => !!getPreloadedPath(state);

export const getRequestCookies = createSelector([getPage], (page = {}) => page.requestCookies);

export const getResponseCookies = createSelector([getPage], (page = {}) => page.responseCookies);

const getSessionCookieStr = str => str.match(/JSESSIONID_TC=[A-Za-z\d]+;/)?.[0];

export const getSessionCookie = createSelector([getResponseCookies], (responseCookies = []) => {
  const sessionCookie =
    responseCookies.length && responseCookies.find(cookie => getSessionCookieStr(cookie));

  if (sessionCookie) return getSessionCookieStr(sessionCookie);

  return undefined;
});

export const getServerGtmEvents = createSelector(
  [getPage],
  (page = {}) => page.serverGtmEvents || [],
);

export const getServerMonetateEvents = createSelector(
  [getPage],
  (page = {}) => page.serverMonetateEvents || [],
);

export const getPageLoading = createSelector([getPage], (page = {}) => !!page.isPageLoading);

export const getIsInitialPageLoaded = createSelector(
  [getPage],
  (page = {}) => page.isInitialPageLoaded,
);

export const getIsPageDataPreloadInProgress = createSelector(
  [getPage],
  (page = {}) => page.isDataPreloadInProgress,
);
