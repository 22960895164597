import {
  SHOW_ADD_MODAL,
  HIDE_ADD_MODAL,
  CANCEL_ADD_MODAL,
  SHOW_EDIT_MODAL,
  REPORT_ADDRESS_PROPERTIES_EDITED,
  CANCEL_EDIT_MODAL,
  SHOW_CHOOSE_ADDRESS_MODAL,
  HIDE_CHOOSE_ADDRESS_MODAL,
  CANCEL_CHOOSE_ADDRESS_MODAL,
  GET,
  GET_BY_ID,
  POST,
  DELETE_BY_ID,
  OPTIONS_BY_ID,
  REPORT_CHANGE_ADDRESS_CLICK,
} from 'redux/modules/address/actions/types';

import * as modalActions from './address-modal-actions';
import * as apiAction from './api-actions';
import { reportChangeAddressClick } from './change-address';

export default {
  [SHOW_ADD_MODAL]: modalActions.addAddressShown,
  [HIDE_ADD_MODAL]: modalActions.addAddressComplete,
  [CANCEL_ADD_MODAL]: modalActions.addAddressCancelled,

  [SHOW_EDIT_MODAL]: modalActions.editAddressShown,
  [REPORT_ADDRESS_PROPERTIES_EDITED]: modalActions.editAddressComplete,
  [CANCEL_EDIT_MODAL]: modalActions.editAddressCancelled,

  [SHOW_CHOOSE_ADDRESS_MODAL]: modalActions.chooseAddressShown,
  [HIDE_CHOOSE_ADDRESS_MODAL]: modalActions.chooseAddressCompleted,
  [CANCEL_CHOOSE_ADDRESS_MODAL]: modalActions.chooseAddressCancelled,

  [GET.failure]: apiAction.getFailure,
  [GET_BY_ID.failure]: apiAction.getByIdFailure,
  [POST.failure]: apiAction.postFailure,
  [DELETE_BY_ID.failure]: apiAction.deleteFailure,
  [OPTIONS_BY_ID.failure]: apiAction.optionsFailure,

  [REPORT_CHANGE_ADDRESS_CLICK]: reportChangeAddressClick,
};
