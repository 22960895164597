export type ActionArg = string | number | boolean;
export interface CustomAction<TAction extends string> {
  action: TAction;
  description: string;
  args?: ActionArg[];
}

export type ErrorButtonAction<TAction extends string> =
  | {
      /** The url to navigate to */
      navigateTo: string;
    }
  | 'reload' // Indicates that clicking the button should reload the page
  | {
      custom: CustomAction<TAction>[];
    };

export type CloseButtonAction<TAction extends string> = ErrorButtonAction<TAction> | 'none';

export type ErrorModalButton<TAction extends string> = {
  buttonText: string;
  /** The action to execute when the button is clicked */
  action?: ErrorButtonAction<TAction>;
  cta?: string | null;
} & ({ primary: boolean; secondary?: never } | { primary?: never; secondary: boolean });

export interface ErrorModal<TAction extends string = never> {
  httpStatuses?: number[];
  /**
   * The list of error codes this modal applies to.
   *
   * Add 'TIMEOUT' to the list to handle timeout errors (and do not add any {@link httpStatuses})
   */
  errorCodes?: LooseString<'TIMEOUT'>[];
  id: string;
  webviewId?: string;
  hasAppCustomerCareButton?: boolean;
  /**
   * The severity of the modal.
   * @default 'conflict'
   */
  severity?: 'info' | 'error' | 'conflict';
  titleText: string;
  messageText: string[];
  /**
   * If the ingredients modal should be used.
   * @default true
   */
  useIngredientsModal?: boolean;
  cannotClose?: boolean;
  closeButtonAction?: CloseButtonAction<TAction>;
  buttons: ErrorModalButton<TAction>[];
  messageIsHtml?: boolean;
}

export type ErrorModalListGetter<TAction extends string = never> = () => ErrorModal<TAction>[];

export type ErrorModals<TAction extends string = never> = {
  defaultModal: ErrorModal<TAction>;
  errorMap: ErrorModal<TAction>[];
};

export function inferModalType<TAction extends string = never>(
  defaultModal: ErrorModal<TAction>,
  errorModals: ErrorModal<TAction>[],
): ErrorModals<TAction> {
  return { defaultModal, errorMap: errorModals };
}
