import React, {
  createContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { func, node } from 'prop-types';

export const NavigationContext = createContext({
  focus: null,
  focusNext: null,
  focusPrevious: null,
  handleEnter: null,
  handleLeft: null,
  handleRight: null,
  register: null,
  selectedId: null,
  unregister: null,
});

const { Provider } = NavigationContext;

const NavigationProvider = forwardRef(({ children, onEnterKey, onLeftKey, onRightKey }, apiRef) => {
  const [selectedUid, setSelectedUid] = useState(null);
  const { current: refs } = useRef([]);

  const getRefByUid = useCallback(uid => refs.find(({ uid: refUid }) => refUid === uid), [refs]);

  const getRefIdIndex = useCallback(id => refs.findIndex(({ id: refId }) => refId === id), [refs]);

  const getRefUidIndex = useCallback(
    uid => refs.findIndex(({ uid: refUid }) => refUid === uid),
    [refs],
  );

  const register = useCallback(
    // "id" can be any value, is returned to the user in events, duplicates ok
    // "uid" must be a UNIQUE identifier and is used internally
    ({ id, uid, ref }) => {
      if (!refs.length) setSelectedUid(uid);
      refs.push({ id, uid, ref });
    },
    [refs],
  );

  const unregister = useCallback(
    uid => {
      const index = getRefUidIndex(uid);
      if (index > -1) refs.splice(index, 1);
    },
    [getRefUidIndex, refs],
  );

  const focusIndex = useCallback(
    index => {
      const { uid, ref } = refs[index];
      setSelectedUid(uid);
      ref.current.focus();
    },
    [refs, setSelectedUid],
  );

  const focus = useCallback(
    uid => {
      const index = getRefUidIndex(uid);
      if (index > -1) focusIndex(index);
    },
    [focusIndex, getRefUidIndex],
  );

  const focusFirst = useCallback(() => {
    if (refs.length) focusIndex(0);
  }, [focusIndex, refs]);

  const focusId = useCallback(
    id => {
      const index = getRefIdIndex(id);
      if (index > -1) focusIndex(index);
    },
    [focusIndex, getRefIdIndex],
  );

  const focusNext = useCallback(
    uid => {
      const index = getRefUidIndex(uid);
      const nextIndex = index === refs.length - 1 ? 0 : index + 1;
      focusIndex(nextIndex);
    },
    [focusIndex, getRefUidIndex, refs],
  );

  const focusPrevious = useCallback(
    uid => {
      const index = getRefUidIndex(uid);
      const nextIndex = index < 1 ? refs.length - 1 : index - 1;
      focusIndex(nextIndex);
    },
    [focusIndex, getRefUidIndex, refs],
  );

  const handleLeft = useCallback(
    uid => onLeftKey && onLeftKey(getRefByUid(uid)?.id),
    [getRefByUid, onLeftKey],
  );

  const handleRight = useCallback(
    uid => onRightKey && onRightKey(getRefByUid(uid)?.id),
    [getRefByUid, onRightKey],
  );

  const handleEnter = useCallback(
    uid => onEnterKey && onEnterKey(getRefByUid(uid)?.id),
    [getRefByUid, onEnterKey],
  );

  const unfocus = useCallback(() => {
    if (selectedUid !== null) {
      const ref = getRefByUid(selectedUid)?.ref;
      if (ref) ref.current.blur();
    }
  }, [getRefByUid, selectedUid]);

  const refocus = useCallback(
    delay => {
      const focusSelectedOrFirst = () => {
        if (selectedUid === null) focusFirst();
        else focus(selectedUid);
      };

      if (delay) setTimeout(focusSelectedOrFirst, delay);
      else focusSelectedOrFirst();
    },
    [focus, focusFirst, selectedUid],
  );

  useImperativeHandle(apiRef, () => ({
    focusFirst,
    focusId,
    refocus,
    unfocus,
  }));

  const value = useMemo(
    () => ({
      focus,
      focusFirst,
      focusNext,
      focusPrevious,
      handleEnter,
      handleLeft,
      handleRight,
      register,
      selectedUid,
      unregister,
    }),
    [
      focus,
      focusFirst,
      focusNext,
      focusPrevious,
      handleEnter,
      handleLeft,
      handleRight,
      register,
      selectedUid,
      unregister,
    ],
  );

  return <Provider value={value}>{children}</Provider>;
});

NavigationProvider.propTypes = {
  children: node.isRequired,
  onEnterKey: func,
  onLeftKey: func,
  onRightKey: func,
};

NavigationProvider.defaultProps = {
  onEnterKey: null,
  onLeftKey: null,
  onRightKey: null,
};

NavigationProvider.displayName = 'NavigationProvider';

export default NavigationProvider;
