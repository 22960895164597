import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RichText from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/RichText';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import classNames from 'classnames';
import styles from './index.scss';

const defaultData = {
  analytics: {},
};

const RecipesDescription = ({ value, maxParagraphs, disableMaxParagraphs }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShow = () => {
    setShowAll(!showAll);
  };

  const paragraphBlocks = value?.blocks?.filter(block => block.style === 'paragraph') || [];
  const blocksToShow =
    showAll || disableMaxParagraphs ? value?.blocks : paragraphBlocks?.slice(0, maxParagraphs);

  return (
    <>
      <RichText
        {...defaultData}
        {...value}
        blocks={blocksToShow}
        noMargin
        position="recipe-description"
        removeEmptyParagraphs
      />
      {paragraphBlocks.length > maxParagraphs && !disableMaxParagraphs && (
        <TextLink
          className={classNames('no-print', styles.showMoreLessToggle)}
          onClick={toggleShow}
          component="button"
          aria-label={showAll ? 'Collapse description' : 'Expand description'}
        >
          {showAll ? 'Show Less' : 'Show More'}
        </TextLink>
      )}
    </>
  );
};

RecipesDescription.displayName = 'RecipesDescription';

RecipesDescription.propTypes = {
  value: PropTypes.object,
  maxParagraphs: PropTypes.number,
  disableMaxParagraphs: PropTypes.bool,
};

RecipesDescription.defaultProps = {
  value: {},
  maxParagraphs: 1,
  disableMaxParagraphs: false,
};

export default RecipesDescription;
