import createReducer from 'redux/createReducer';
import initialState from './initial-state';
import createMyWaitroseAppleWalletItem from './create-apple-wallet-item';
import hideMyWaitroseAppleWalletError from './hide-myWaitrose-apple-wallet-error';
import removeMyWaitroseAppleWalletItem from './remove-mywaitrose-apple-wallet-item';

const actionTypeReducerMap = new Map();
createMyWaitroseAppleWalletItem(actionTypeReducerMap);
hideMyWaitroseAppleWalletError(actionTypeReducerMap);
removeMyWaitroseAppleWalletItem(actionTypeReducerMap);

export default createReducer(initialState, actionTypeReducerMap);
