import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

export const svgIcon = (svg, displayName = 'SvgIcon') => {
  const Icon = pure(props => React.cloneElement(svg, props));

  Icon.displayName = displayName;

  Icon.propTypes = {
    className: PropTypes.string,
  };

  Icon.defaultProps = {
    className: undefined,
  };

  return Icon;
};
