import { CampaignName, SlotDate, SlotGridType, SlotTime } from 'api/definitions/slotdates/types';
import { deepFreeze } from 'utils/deepFreeze';

export type Campaign = {
  seasonDescription: CampaignName;
  seasonalStartDate: string;
  seasonalEndDate: string;
  siteStartDate: string;
  siteEndDate: string;
};

export interface SlotDatesState {
  campaigns: Partial<Record<CampaignName, Campaign>>;
  dates: SlotDate[];
  error: string | null;
  isPriority?: boolean;
  loading: boolean;
  showPriorityOnlyMessage: boolean;
  hasCampaignDatesOnly: boolean;
  times: {
    slotGridType: SlotGridType;
    slotTimes: SlotTime[];
  }[];
}

export const initialState = deepFreeze({
  loading: false,
  dates: [],
  campaigns: {},
  error: null,
  showPriorityOnlyMessage: false,
  hasCampaignDatesOnly: false,
  times: [],
} as SlotDatesState);
