import {
  getAddressById,
  getAddresses,
  getAdminAddressById,
  setSelectedAddress,
} from 'redux/modules/address/actions';
import { getAllAddressesRequestStatus, isAdminEditAddress } from 'redux/modules/address/selectors';
import { resetMyWaitroseRequestError } from 'redux/modules/mywaitrose/actions';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { filterSearchQuery, queryStringToObject } from 'utils/query-string-params';

const newJoinerAddressesFetcher = () => dispatch => {
  dispatch(resetMyWaitroseRequestError());
  dispatch(getAddresses());
};

const addressesFetcher = () => (dispatch, getState) => {
  const state = getState();
  if (isUserLoggedIn(state)) {
    const allAddressesRequestStatus = getAllAddressesRequestStatus(state);
    if (allAddressesRequestStatus !== 'loaded' && allAddressesRequestStatus !== 'loading') {
      return dispatch(getAddresses());
    }
  }

  return Promise.resolve();
};

const addressByIdFetcher =
  ({ match }) =>
  dispatch => {
    const { addressId } = match.params;
    return addressId ? dispatch(getAddressById(addressId)) : Promise.resolve();
  };

const editAdminAddressFetcher = () => async dispatch => {
  if (isAdminEditAddress()) {
    const addressId = queryStringToObject(filterSearchQuery()).editAddressId;
    await dispatch(getAdminAddressById(addressId)).then(address => {
      dispatch(setSelectedAddress(address));
    });
  }
};

export { newJoinerAddressesFetcher, addressesFetcher, addressByIdFetcher, editAdminAddressFetcher };
