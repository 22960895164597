import { dataLayer } from 'analytics/data-layer';

// ✅ Choose Delivery (On Choose your Shopping Method page)
// ✅ Choose Click & Collect (On Choose your Shopping Method page)
// ✅ Trolley Details (On mini trolley)
// ✅ Add to Cart (The "+" sign, or the button on product details page)
// ✅ Write a Review (On product details page)
// ✅ Leave a Comment (On product details page)
// ✅ Add to Favourites (Click on the heart sign on the product image or on product details page)
// ✅ Add to List (On product details page)
// ✅ Sign up to MyWaitrose (On ecom/my-waitrose/become-a-member page)

export function trackPreLoginInteraction(interactionName) {
  if (interactionName)
    dataLayer.push({
      event: 'pre_login_interactions',
      pre_login_interactions: {
        button_clicked: interactionName,
      },
    });
}
