export type FifoBuffer<T> = [string, T][];

const BUFFER_SIZE = 8;

export const fifoBufferPush = <T>(buffer: FifoBuffer<T>, key: string, item: T): FifoBuffer<T> => {
  const items = buffer.filter(v => v[0] !== key);

  if (items.push([key, item]) > BUFFER_SIZE) {
    items.shift();
  }
  return items;
};

export const fifoBufferLookup = <T>(buffer: FifoBuffer<T>, key: string) =>
  buffer.find(v => v[0] === key)?.[1] as T;
