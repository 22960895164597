export const pending = state => ({
  ...state,
  loading: true,
  membership: [],
});

export const fulfilled = (state, action) => ({
  ...state,
  loading: false,
  getMembershipStatusInitialLoadComplete: true,
  membership: action.result,
  error: false,
});

export const rejected = (state, action) => ({
  ...state,
  loading: false,
  getMembershipStatusInitialLoadComplete: true,
  membership: [],
  error: action.error,
});

export const removeMarketingPreferences = state => ({
  ...state,
  membership: [],
});
