const reducer = (state, action) => {
  const { paymentCardId } = action;
  return {
    ...state,
    deletingCards: state.deletingCards.filter(id => id !== paymentCardId),
    paymentCards: state.paymentCards.filter(({ id }) => id !== paymentCardId),
  };
};

export default reducer;
