import {
  GET,
  GET_BY_ID,
  REPORT_ADDRESS_SUGGESTION_NO_RESULTSET,
  REPORT_ADDRESS_SUGGESTION_RESULTSET,
  REPORT_ADDRESS_SUGGESTION_MODIFIED,
} from 'redux/modules/address-suggestion/actions/types';

import * as apiAction from './api-actions';
import * as searchAction from './search-actions';

export default {
  [REPORT_ADDRESS_SUGGESTION_NO_RESULTSET]: searchAction.addressSuggestionSearch,
  [REPORT_ADDRESS_SUGGESTION_RESULTSET]: searchAction.addressSuggestionSearchResultSet,
  [GET_BY_ID.request]: searchAction.addressSuggestionRetrieve,
  [REPORT_ADDRESS_SUGGESTION_MODIFIED]: searchAction.addressSuggestionModified,
  [GET.failure]: apiAction.getFailure,
  [GET_BY_ID.failure]: apiAction.getByIdFailure,
};
