import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';
import waiting from 'redux/modules/checkout/actions/waiting';

import { openModal } from 'redux/modules/common-modal/actions';
import { preserveStubsUrl } from 'utils/general';

import locator from 'utils/locator';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;
  const shouldShowError = code === 'ORDER_ORCHESTRATION_058' || code === 'ORDER_ORCHESTRATION_029';

  if (status === 409 && shouldShowError) {
    const onCloseCallback = () => {
      // trigger waiting state when modal is closed and before redirect
      dispatch(waiting());
      locator.href = preserveStubsUrl(urls.serviceSelection);
    };

    dispatch(
      openModal({
        id: 'checkout-error-slot-expired',
        severity: 'conflict',
        useIngredientsModal: false,
        buttons: [
          {
            buttonText: 'OK',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
        ],
        cannotClose: true,
        messageText: [
          `You'll need to re-book your slot before you can check out. Your shopping is still in your trolley.`,
        ],
        titleText: 'Sorry, your slot has now expired.',
        webview: {
          id: 'checkout-error-slot-expired',
          ctas: [urls.serviceSelection],
        },
        onCloseCallback,
      }),
    );

    return true;
  }

  return false;
};
