import { connect } from 'react-redux';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';

import { readMonetateCookie } from 'redux/modules/cookies/actions';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { withRouteProps } from 'utils/with-route-props';

import App from './App';

export default withRouteProps(
  connect(
    state => ({
      hasSitePinBar: amendingOrder(state),
      isUserLoggedIn: isUserLoggedIn(state),
    }),
    { readMonetateCookie },
  )(App),
);
