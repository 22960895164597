import { BundleThresholds } from 'constants/bundle';
import { LegacyProductReference } from 'constants/products';
import { deepFreeze } from 'utils/deepFreeze';

export interface BundleGroupState {
  products: LegacyProductReference[];
  thresholds: BundleThresholds;
  title: string;
}

export interface BundleSuccessState {
  groups: BundleGroupState[];
  title: string;
}

export interface BundleErrorState {
  serverError: true;
}

export interface BundleNotFoundState {
  notFound: true;
}

export type BundleState =
  | Record<string, never>
  | BundleSuccessState
  | BundleErrorState
  | BundleNotFoundState;

export type UnknownBundleState = Partial<
  BundleSuccessState & BundleErrorState & BundleNotFoundState
>;

export const initialState: Readonly<BundleState> = deepFreeze({});
