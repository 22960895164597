import { buildGraphData } from './build-graph-data';
import { buildGraphUrl } from './build-graph-url';
import { graphFlags } from './graph-flags';

export const graphPropsBuilder = (api, args) => ({
  data: buildGraphData(api, args),
  headers: {
    graphflags: JSON.stringify(graphFlags()),
    ...api?.headers,
  },
  url: buildGraphUrl({ query: api?.queryString }),
});
