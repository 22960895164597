/**
 * Temporary mapping of tactical meal deal ids to their strategic equivalents
 * for use in Meal Deal route redirects following the go-live of the Strategic
 * Meal Deal feature.
 *
 * TODO: https://www.jlpit.com/jira/browse/WPIP-53717
 */
export const tacticalIdToStrategicIdRedirectMap: Record<string, string> = {
  '121': '88089',
  '129': '90550',
  '135': '90874',
  '144': '87428',
  '159': '90946',
  '162': '90969',
  '175': '90155',
  '181': '90758',
  '191': '89979',
  '197': '89977',
  '201': '90350',
};

type PreservedTacticalId = '109' | '114' | '150' | '185';

type NonEmptyArray<T> = [T, ...T[]];

interface StrategicMealDeal {
  id: string;
  startDate: string;
  endDate: string;
}

/**
 * Temporary mapping of tactical meal deal ids to all possible strategic
 * equivalents for "preserved" meal deals as agreed with the SEO Team.
 *
 * When briefed by the Online Merchandising Team per the
 * [Meal Deal Process Document](https://docs.google.com/document/d/1-7sk6UkpivFz4rHcT3zC-rxcdxSbVIe8CppFNa3iq_I)
 * update the map below for all active and upcoming PIC offers that are
 * configured against the 4 preserved tactical meal deal IDs we are supporting.
 *
 * TODO: Remove following phase 2 strategic solution where the BE supports
 * "persistent" meal deal ids that can abstract over multiple PIC offer IDs for
 * a long running or recurring meal deal offer.
 */
export const tacticalIdToStrategicFetcherMap: Record<string, NonEmptyArray<StrategicMealDeal>> = {
  '109': [
    {
      id: '90002',
      startDate: '2024-02-15',
      endDate: '2024-09-10',
    },
    {
      id: '91143',
      startDate: '2024-09-11',
      endDate: '2025-01-21',
    },
  ],
  '114': [
    {
      id: '90380',
      startDate: '2024-05-08',
      endDate: '2024-10-22',
    },
    {
      id: '91543',
      startDate: '2024-10-23',
      endDate: '2025-04-08',
    },
  ],
  '150': [
    {
      id: '87566',
      startDate: '2024-02-07',
      endDate: '2024-02-14',
    },
  ],
  '185': [
    {
      id: '90847',
      startDate: '2024-06-05',
      endDate: '2024-12-03',
    },
    {
      id: '91916',
      startDate: '2024-12-04',
      endDate: '2025-06-03',
    },
  ],
};

/**
 * Temporary mapping of strategic meal deal ids back to their tactical
 * equivalents for "preserved" meal deals as agreed with the SEO Team.
 *
 * TODO: Remove following phase 2 strategic solution where the BE supports
 * "persistent" meal deal ids that can abstract over multiple PIC offer IDs for
 * a long running or recurring meal deal offer.
 */
export const strategicIdToTacticalIdRedTextMap: Record<string, PreservedTacticalId> =
  Object.entries(tacticalIdToStrategicFetcherMap).reduce(
    (currentMap, [tacticalId, strategicMealDeal]) => {
      strategicMealDeal.forEach(({ id }) => {
        // eslint-disable-next-line no-param-reassign
        currentMap[id] = tacticalId as PreservedTacticalId;
      });

      return currentMap;
    },
    {} as Record<string, PreservedTacticalId>,
  );
