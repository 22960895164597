import { createSelector } from 'reselect';
import flattenDeep from 'lodash/flattenDeep';

import {
  getCustomerSlotBranchId,
  getCustomerSlotDate,
} from 'redux/modules/customer-slot/selectors';
import { getLocationSubCategories } from 'redux/modules/taxonomy/selectors';

const getNames = categories => categories.map(item => item.name).filter(item => item);

const getCategoryNames = createSelector([getLocationSubCategories], categories =>
  categories ? getNames(categories) : [],
);

export const getMonetateCategories = (state, location) =>
  flattenDeep([
    getCategoryNames(state, {
      location: {
        pathname: location,
      },
    }),
    `branchId-${getCustomerSlotBranchId(state)}`,
    `slotDate-${getCustomerSlotDate(state)}`,
  ]).filter(category => category);
