import React from 'react';
import { pure } from 'recompose';

import urls from 'constants/urls';
import wcsConfig from 'utils/wcs-config';

import styles from 'components/HomePage/Header/Services/Services.scss';
import {
  colourEarlGrey,
  colourWhite,
} from '@johnlewispartnership/wtr-ingredients/foundations/colours';

const Services = pure(() => (
  <div className={styles.links}>
    <svg
      className={styles.customerIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill={colourEarlGrey} fillRule="evenodd">
        <path d="M11.696 17.101h1.79v-1.79h-1.79zM15.051 17.101h1.79v-1.79h-1.79zM18.406 17.101h1.79v-1.79h-1.79zM11.696 20.288h1.79V18.5h-1.79zM15.051 20.288h1.79V18.5h-1.79zM18.406 20.288h1.79V18.5h-1.79zM11.696 23.425h1.79v-1.789h-1.79zM15.052 23.425h1.789v-1.789h-1.79zM18.406 23.425h1.79v-1.789h-1.79zM7 9v5.417h1.342v-4.075H23.55v4.075h1.342V9z" />
      </g>
    </svg>
    <a
      data-testid="customer-care-link"
      href={urls.customerServiceHomePage}
      rel="noopener noreferrer"
    >
      Customer Service
    </a>
    <svg
      className={styles.shopsIcon}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path id="a" d="M0 .127h17.613v21.248H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(7 5.436)">
          <mask id="b" fill={colourWhite}>
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={colourEarlGrey}
            d="M8.807 1.888c3.884 0 7.044 3.16 7.044 7.045a6.993 6.993 0 0 1-1.823 4.73l-.244.244-4.977 4.977-4.983-4.983-.232-.23a6.996 6.996 0 0 1-1.83-4.738 7.053 7.053 0 0 1 7.045-7.045m0-1.761A8.806 8.806 0 0 0 2.58 15.16l-.001-.014 6.228 6.23 6.223-6.224.005.006A8.806 8.806 0 0 0 8.807.126"
            mask="url(#b)"
          />
        </g>
        <path
          fill={colourEarlGrey}
          d="M15.807 16.717a2.348 2.348 0 1 1-.002-4.697 2.348 2.348 0 0 1 .002 4.697"
        />
      </g>
    </svg>
    <a data-testid="find-store-link" href={wcsConfig.branchFinderUrl()}>
      Find a store
    </a>
    <a data-testid="mywaitrose-link" href={urls.myWaitrosePage}>
      myWaitrose
    </a>
  </div>
));

Services.displayName = 'Services';

export default Services;
