import { GET_PROMPTS_FOR_CONTACT_ADDRESS } from 'redux/modules/accounts/actions/types';

const getPromptsForContactAddressRequest = state => ({
  ...state,
  prompts: {
    ...state.prompts,
    isPendingContactAddressVerification: false,
  },
});

const getPromptsForContactAddressSuccess = state => ({
  ...state,
  prompts: {
    ...state.prompts,
    isPendingContactAddressVerification: true, // if it returns response with 204 status code, it actually means there is a pending contact address verification
  },
});

const getPromptsForContactAddressFailure = state => ({
  ...state,
  prompts: {
    ...state.prompts,
    isPendingContactAddressVerification: false, // if it returns response with 404 status code, it actually means there is no pending contact address verification
  },
});

export default map => {
  map.set(GET_PROMPTS_FOR_CONTACT_ADDRESS.request, getPromptsForContactAddressRequest);
  map.set(GET_PROMPTS_FOR_CONTACT_ADDRESS.success, getPromptsForContactAddressSuccess);
  map.set(GET_PROMPTS_FOR_CONTACT_ADDRESS.failure, getPromptsForContactAddressFailure);

  return map;
};
