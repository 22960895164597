import { ADD_TO_TROLLEY_DESCRIPTION } from 'redux/middleware/analytics/actions-to-track/trolley/constants';
import changeCart from './change-cart';

const undoRemove = (state, { analytics }) => {
  const { lineNumber } = analytics;
  const addToTrolley = changeCart(state, [{ analytics }], 'add', ADD_TO_TROLLEY_DESCRIPTION);
  const undoRemoveEvent = {
    action: 'undo_remove_from_cart',
    event: 'click_undo',
    item_id: lineNumber,
  };

  return [...addToTrolley, undoRemoveEvent];
};

export default undoRemove;
