import { Branch } from 'api/definitions/branches/types';

const reducer = (state: WtrState, { result }: { result: Branch }) => ({
  ...state,
  loading: false,
  branches: [result],
  error: null,
});

export default reducer;
