import React from 'react';

import {
  Email,
  Facebook,
  Whatsapp,
  Twitter,
  Pinterest,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from './index.scss';

export type SocialsType = {
  canonicalHref: string;
  recipeName: string;
  recipeDescription: string;
  recipeImage: string;
};

type SocialProviders = {
  name: string;
  link: string;
  icon: JSX.Element;
  classes?: string;
};

const message = encodeURIComponent("I'm loving this Waitrose recipe!");

const Socials = ({ recipeName, recipeDescription, canonicalHref, recipeImage }: SocialsType) => {
  const socialProviders: SocialProviders[] = [
    {
      name: 'whatsapp',
      link: `https://wa.me/?text=${canonicalHref}`,
      icon: <Whatsapp />,
    },
    {
      name: 'facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${canonicalHref}`,
      icon: <Facebook />,
    },
    {
      name: 'twitter',
      link: `https://twitter.com/intent/tweet?text=${message}&url=${canonicalHref}`,
      icon: <Twitter />,
    },
    {
      name: 'pinterest',
      link: `https://pinterest.com/pin/create/button/?description=${message}&url=${canonicalHref}&media=${encodeURIComponent(
        recipeImage,
      )}`,
      icon: <Pinterest />,
    },
    {
      name: 'email',
      link: `mailto:?to=&subject=I%27m%20loving%20this%20Waitrose%20recipe%20-%20${recipeName}&body=${canonicalHref}%0D%0A${recipeDescription}`,
      icon: <Email />,
      classes: styles.icon,
    },
  ];

  return (
    <div className={styles.wrapper} data-testid="social-share">
      <Typography styleAs="paragraphHeading" className={styles.text}>
        Share now
      </Typography>
      <div className={styles.shareOptions}>
        {socialProviders.map(({ name, link, icon, classes }) => (
          <TextLink href={link} aria-label={name} target="_blank" className={classes} key={name}>
            {icon}
          </TextLink>
        ))}
      </div>
    </div>
  );
};

export default Socials;
