export const promotionCodeMapper = (promotionTypeCode: string) => {
  switch (promotionTypeCode) {
    case 'MV':
    case 'PMV':
      return 'multi-buy';
    case 'LI':
    case 'EXV':
      return 'meal-deal';
    case 'BHP':
    case 'HP':
      return 'half-price';
    case 'S13':
      return 'save-3rd';
    case 'WN':
    case 'NLP':
    case 'FER':
    case 'WRR':
      return 'save-pound';
    case 'MPO':
    case 'POF':
    case 'INT':
      return 'percent-off';
    default:
      return undefined;
  }
};
