import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import {
  editOrDeleteAddressModalOpening,
  updateContactAddressErrorSelector,
} from 'redux/modules/address/selectors';
import { getSlotDeliveryAddress } from 'redux/modules/order/selectors/get-order-slot-selectors';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

import { isValidUKCountryCode } from 'components/Address/util';

import { capitaliseWords } from 'utils/format';

const getAddresses = state => state.addresses.addresses;
const addressRequestSelector = state => state.addresses.request;
const selectedAddressSelector = state => state.addresses.selectedAddress;
const addAddressLoadingSelector = state => state.addresses.addAddressLoading;
const editAddressLoading = state => state.addresses.editAddressLoading;
const edittedAddressFields = state => state.addresses.editedFields;
const getAddressInError = state => state.addresses.addressInError;
const getAllAddressesRequestStatus = state => state.addresses.getAllAddressesRequestStatus;
const allAddressesLoading = state => state.addresses.getAllAddressesRequestStatus === 'loading';

const getContactAddressId = state => {
  const addresses = getAddresses(state);
  return addresses.find(address => address.isContactAddress === true)?.id;
};

const showContactAddressNotPresentNotification = state =>
  isUserLoggedIn(state) && !allAddressesLoading(state) && !getContactAddressId(state);

const getAddress = (state, id) => {
  const addresses = getAddresses(state);
  return addresses.find(address => address.id === id);
};

const addressesLoading = state => {
  const request = addressRequestSelector(state) || {};
  if (
    editOrDeleteAddressModalOpening(state) ||
    addAddressLoadingSelector(state) ||
    editAddressLoading(state)
  ) {
    return false;
  }

  return !request || request.loading || !request.loaded;
};

const getActiveAddresses = state => {
  const addresses = getAddresses(state);
  return addresses && Array.isArray(addresses) ? addresses : [];
};

const setSelectedActiveAddresses = (state, selectedIds) => {
  const addresses = getAddresses(state);
  const activeAddresses = addresses && Array.isArray(addresses) ? addresses : [];
  if (selectedIds && Array.isArray(selectedIds)) {
    const selected = [];
    const notSelected = [];
    activeAddresses.forEach(a => {
      if (selectedIds.includes(a.id)) {
        selected.push({
          ...a,
          selected: true,
        });
      } else {
        notSelected.push(a);
      }
    });
    return [...selected, ...notSelected];
  }
  return activeAddresses;
};

const getWCSAddedAddressId = state =>
  state.addresses.addresses[0] ? state.addresses.addresses[0].id : undefined;

// [BM]: this method is untested
const getMostRecentAddressPostCode = state => {
  const addresses = getAddresses(state);
  const recentAddress = addresses.length
    ? addresses
        .slice(0)
        .sort((address1, address2) => (address1.created > address2.created ? -1 : 1))
    : [{ postalCode: '' }];
  return recentAddress[0].postalCode;
};

const getAddresseeFormValues = (state, form) => {
  const selector = formValueSelector(form);
  return selector(state, 'title', 'firstName', 'lastName', 'contactNumber');
};

const addressRequestError = state => {
  const { error = {} } = addressRequestSelector(state) || {};
  const { resposnse = {} } = error;
  const { body = '' } = resposnse;
  return body;
};

const addressApiHasErrored = state => {
  const { error = null } = addressRequestSelector(state) || {};

  return updateContactAddressErrorSelector(state) || (error !== null && error.status !== 423);
};

const getDeliveryAddressNote = state => {
  const deliveryAddress = getSlotDeliveryAddress(state) || {};
  const fullDeliveryAddress = getAddress(state, deliveryAddress.id) || {};

  return fullDeliveryAddress?.delivery?.customerNote?.value;
};

const getContactAddress = state => {
  const contactAddressId = getContactAddressId(state);

  return getAddress(state, contactAddressId);
};

const getValidUKContactAddress = state => {
  const selectedContactAddress = getContactAddress(state);

  return isValidUKCountryCode(selectedContactAddress?.country) ? selectedContactAddress : undefined;
};

const getContactAddressAddressee = state => getContactAddress(state)?.addressee;

const getAddresseeTitleFirstNameLastName = createSelector(
  getContactAddressAddressee,
  ({ title, firstName, lastName } = {}) => ({
    title,
    firstName,
    lastName,
  }),
);

const addresseeHasValidNameData = createSelector(
  getAddresseeTitleFirstNameLastName,
  ({ firstName, lastName } = {}) => !!firstName && !!lastName,
);

const getAddresseeTitleAndLastName = createSelector(
  getContactAddressAddressee,
  ({ title, lastName } = {}) => (!!title && !!lastName ? `${title} ${lastName}` : null),
);

const getAddresseeSalutation = createSelector(
  getContactAddressAddressee,
  ({ firstName, lastName, title } = {}) => {
    if (!!title && !!firstName && !!lastName) {
      return capitaliseWords(`${title} ${firstName} ${lastName}`);
    }

    if (!!title && !!lastName) {
      return capitaliseWords(`${title} ${lastName}`);
    }

    if (!!firstName && !!lastName) {
      return capitaliseWords(`${firstName} ${lastName}`);
    }

    return null;
  },
);

export {
  addAddressLoadingSelector,
  addressApiHasErrored,
  getAddressInError,
  addressRequestError,
  addressRequestSelector as addressRequest,
  addressesLoading,
  getAllAddressesRequestStatus,
  allAddressesLoading,
  editAddressLoading,
  edittedAddressFields,
  getActiveAddresses,
  getAddress,
  getAddresseeFormValues,
  getAddresses,
  getDeliveryAddressNote,
  getMostRecentAddressPostCode,
  getWCSAddedAddressId,
  showContactAddressNotPresentNotification,
  selectedAddressSelector as getSelectedAddress,
  setSelectedActiveAddresses,
  getContactAddressId,
  getValidUKContactAddress,
  getContactAddress,
  getContactAddressAddressee,
  getAddresseeTitleFirstNameLastName,
  addresseeHasValidNameData,
  getAddresseeTitleAndLastName,
  getAddresseeSalutation,
};
