import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronRightBold } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useExperiments } from 'components/Experiment/useExperiments';
import { POD_WAS_PRICE } from 'constants/monetateExperiments';

import styles from 'components/Product/Promotion/Description/PromotionDescription.scss';

const PricePromotionDescription = ({
  description,
  price,
  underlineText,
  noMargin,
  backgroundColor,
  isLink,
  withChevron,
}) => {
  const { getDecisionById } = useExperiments();
  const showStrikethrough =
    getDecisionById(POD_WAS_PRICE.experiment).variant === POD_WAS_PRICE.variant.showStrikethrough;

  return (
    <p
      className={classNames({
        [styles.noMargin]: noMargin,
      })}
    >
      <span
        className={classNames(styles.offerDescription, {
          [styles.withBackground]: !!backgroundColor,
          [styles.green]: backgroundColor === 'green',
          [styles.underline]: underlineText && !showStrikethrough,
        })}
        data-testid="offer-description"
      >
        <span>
          {`${description}.`}
          <em
            className={classNames({ [styles.withStrikethrough]: showStrikethrough })}
            data-testid="was-price-description"
          >{` Was ${price}`}</em>
        </span>
        {backgroundColor && isLink && withChevron && (
          <ChevronRightBold size="xsmall" className={styles.chevronIcon} />
        )}
      </span>
    </p>
  );
};

PricePromotionDescription.displayName = 'PricePromotionDescription';

PricePromotionDescription.propTypes = {
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  underlineText: PropTypes.bool,
  isLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withChevron: PropTypes.bool,
};

PricePromotionDescription.defaultProps = {
  noMargin: false,
  underlineText: false,
  isLink: false,
  backgroundColor: undefined,
  withChevron: false,
};

export default memo(PricePromotionDescription);
