import urls from 'constants/urls';
import { currentlyAmendingOrder } from 'redux/modules/amend-order/selectors';
import {
  getSlotExpiryTime,
  getTimeTillSlotExpiry,
} from 'redux/modules/customer-slot/selectors/get-slot-expiry-time';
import clearCustomerSlot from 'redux/modules/customer-slot/actions/clear-customer-slot';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import hasTrolleyLoaded from 'redux/modules/trolley/selectors/get-loaded';

import {
  SLOT_EXPIRY_FINAL,
  SLOT_EXPIRY_INITIAL,
} from 'redux/modules/customer-slot/actions/slot-expiry-snackbar-constants';
import {
  showSlotSnackbar,
  resetSlotSnackbar,
} from 'redux/modules/customer-slot/actions/slot-expiry-snackbar';
import openSlotExpiredModal from 'redux/modules/customer-slot/actions/open-slot-expired-modal';
import locator from 'utils/locator';

let timeoutId;

const excludedUrls = [urls.checkout, urls.interstitials];
const isOnExcludedPage = pathname => excludedUrls.includes(pathname);

const minsToMilliseconds = mins => mins * 60 * 1000;

const startSlotTimeout = () => (dispatch, getState) => {
  const checkExpiry = () => {
    clearTimeout(timeoutId);

    const state = getState();

    if (!hasTrolleyLoaded(state)) {
      timeoutId = setTimeout(() => {
        checkExpiry();
      }, minsToMilliseconds(1));
      return;
    }

    const expiryTime = getSlotExpiryTime(state);
    const isAmendingOrder = amendingOrder(state) || currentlyAmendingOrder(state);

    if (expiryTime && !isAmendingOrder && !isOnExcludedPage(locator.pathname)) {
      const difference = getTimeTillSlotExpiry(state);

      if (difference > 0) {
        const minimumTimeout = minsToMilliseconds(1);
        const firstNotification = minsToMilliseconds(100); // with 100 mins to go (after 20 mins)
        const secondNotification = minsToMilliseconds(30); // with 30 mins to go (after 90 mins)

        let duration = minimumTimeout;

        if (difference <= secondNotification) {
          dispatch(showSlotSnackbar(SLOT_EXPIRY_FINAL));
          duration = difference;
        } else if (difference <= firstNotification) {
          dispatch(showSlotSnackbar(SLOT_EXPIRY_INITIAL));
          duration = difference - secondNotification;
        } else {
          duration = difference - firstNotification;
        }

        timeoutId = setTimeout(
          () => {
            checkExpiry();
          },
          duration < minimumTimeout ? minimumTimeout : duration,
        );
      } else {
        dispatch(clearCustomerSlot());
        dispatch(openSlotExpiredModal());
      }
    }
  };

  dispatch(resetSlotSnackbar());
  checkExpiry();
};

export default startSlotTimeout;
