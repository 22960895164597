import createReducer from 'redux/createReducer';

import initialState from './initial-state';
import getCustomerAccountsRequest from './get-accounts';
import { updateCustomerAccount } from './update-accounts';
import getPromptsForContactAddress from './get-prompts-for-contact-address';
import removePromptsForContactAddress from './remove-prompts-for-contact-address';

import { UPDATE_CUSTOMER_ACCOUNT_DETAILS } from '../actions/types';

const actionTypeReducerMap = new Map([[UPDATE_CUSTOMER_ACCOUNT_DETAILS, updateCustomerAccount]]);
getCustomerAccountsRequest(actionTypeReducerMap);
getPromptsForContactAddress(actionTypeReducerMap);
removePromptsForContactAddress(actionTypeReducerMap);

export default createReducer(initialState, actionTypeReducerMap);
