import { currentlyAmendingOrder } from 'redux/modules/amend-order/selectors';
import trolleyItemToAnalyticsProduct from '../utilities/trolley-item-to-analytics-product';

const amendOrderApiSuccess = (state, trolleyItems, orderId) => {
  const trolleyItemMapper = trolleyItemToAnalyticsProduct(state);
  const products = trolleyItems && trolleyItems.map(trolleyItemMapper);

  return {
    event: 'transaction_amend_started',
    ecommerce: {
      checkout: {
        actionField: {
          id: orderId,
          impressions: products,
        },
      },
    },
  };
};

const amendOrderApiError = error => ({
  event: 'transaction_amend_error',
  error,
});

export const checkAmendOrderWhenStartAmendFulfilled = (state, payload) => {
  const {
    result: {
      data: { amendOrder },
    },
  } = payload;

  if (amendOrder?.failures) return amendOrderApiError(amendOrder.failures);
  return false;
};

export const checkAmendOrderWhenOrderLoaded = (state, payload) => {
  const orderId = payload?.result?.trolley?.orderId ?? null;
  const trolleyItems = payload?.result?.trolley?.trolleyItems ?? [];
  const failures = payload?.result?.failures ?? [];
  const isAmendingPending = currentlyAmendingOrder(state);

  if (!isAmendingPending || failures.length > 0) return false;
  return amendOrderApiSuccess(state, trolleyItems, orderId);
};
