import React, { useRef } from 'react';
import { bool, func, string } from 'prop-types';

import { chainHandlers } from 'utils/chain-handlers';
import { useNavigation } from 'components/MegaMenu/NavigationProvider';

const SignOut = ({
  className,
  isSignedOnAsCustomer,
  loggedIn,
  onBlur,
  onClick,
  onFocus,
  onLogoutUser,
}) => {
  const visible = loggedIn && !isSignedOnAsCustomer;
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useNavigation(ref, {
    skip: !visible,
  });

  return visible ? (
    <button
      className={className}
      data-test="signOut"
      onBlur={onBlur}
      onClick={chainHandlers(handleClick, onClick, onLogoutUser)}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      ref={ref}
      tabIndex={tabIndex}
      type="button"
    >
      Sign out
    </button>
  ) : null;
};

SignOut.propTypes = {
  className: string,
  isSignedOnAsCustomer: bool.isRequired,
  loggedIn: bool.isRequired,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onLogoutUser: func.isRequired,
};

SignOut.defaultProps = {
  className: null,
  onBlur: null,
  onClick: null,
  onFocus: null,
};

SignOut.displayName = 'SignOut';

export default SignOut;
