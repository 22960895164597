import { normalize } from 'normalizr';
import get from 'lodash/get';

import { productConflictListSchema } from 'redux/schemas';

import { getConflicts } from './get-conflicts';
import { transformProductConflict } from './transform';

const extractNormalizedProductsFromData = (data, schema) =>
  normalize(data, schema).entities.products || {};

const getProductIds = data =>
  get(data, 'componentsAndProducts', [])
    .map(dataItem => dataItem.searchProduct)
    .filter(productId => productId);

const getNormalisedProductConflicts = (data, schema) => {
  // Create map of product conflicts in this data and combine with map of all products in the response,
  // so there is a 1:1 between conflicts and products, with { [productId]: undefined or [conflictObj], ... }
  //
  // We do this so that we can create keys with undefined value for products with no conflicts and merge
  // into the conflict store, removing those conflicts, without also nuking conflicts relating to products
  // not involved in this request

  const productsOnly = conflict => conflict.productId && !conflict.itemId;

  const productConflicts = getConflicts(data)
    .filter(productsOnly)
    .map(conflict => transformProductConflict(conflict));

  const conflicts =
    normalize(productConflicts, productConflictListSchema).entities.productConflicts || {};

  const nestedProductIds = Object.keys(extractNormalizedProductsFromData(data, schema));

  const productIds = getProductIds(data).concat(nestedProductIds);

  productIds.forEach(productId => {
    conflicts[productId] = conflicts[productId] || undefined;
  });

  return conflicts;
};

export default getNormalisedProductConflicts;
