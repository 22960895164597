export const ENTERTAINING = 'E';
export const GROCERIES = 'G';
export const WINE = 'W';
export const LOAN = 'L';

const entertainingOrSimilar = new Set([ENTERTAINING, LOAN]);

const groceriesOrSimilar = new Set([GROCERIES, WINE]);

export const isEntertainingOrSimilar = productType => entertainingOrSimilar.has(productType);
export const isGroceriesOrSimilar = productType => groceriesOrSimilar.has(productType);
