import env from 'env/env';
import { RecipeSummaryAction } from 'redux/modules/cms-hybrid-recipes-page/reducers/';

export const getCacheKey = (
  filters: RecipeSummaryAction['filters'],
  search: RecipeSummaryAction['req']['search'],
  sortBy: RecipeSummaryAction['filterParams']['sortBy'],
  start: number,
  size: number = env.recipesPerPage,
) => {
  return JSON.stringify({
    filters,
    search,
    sortBy,
    start,
    size,
  });
};
