import PropTypes from 'prop-types';

const messagesType = PropTypes.shape({
  aside: PropTypes.string,
  dismissal: PropTypes.string,
  longGroup: PropTypes.string,
  longItem: PropTypes.string,
  reason: PropTypes.string,
  shortItem: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.string,
});

const reservedQuantityType = PropTypes.shape({
  amount: PropTypes.number,
  uom: PropTypes.string,
});

export const resolutionActionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
});

export const conflictType = PropTypes.shape({
  messages: messagesType,
  outOfStock: PropTypes.bool,
  priority: PropTypes.number,
  productId: PropTypes.string,
  prohibitedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  reservedQuantity: reservedQuantityType,
  resolutionActions: PropTypes.arrayOf(resolutionActionType).isRequired,
  slotOptionDates: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
});
