import {
  PRODUCT_PICKER_LOADING,
  PRODUCT_PICKER_LOADED,
  PRODUCT_PICKER_FAILED,
  PRODUCT_PICKER_CLEAR,
} from 'redux/modules/product-picker/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  productIds: null,
  loading: false,
  error: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCT_PICKER_CLEAR:
      return {
        ...state,
        productIds: null,
        loading: false,
        error: false,
      };
    case PRODUCT_PICKER_LOADING:
      return {
        ...state,
        productIds: null,
        loading: true,
        error: false,
      };
    case PRODUCT_PICKER_LOADED:
      return {
        ...state,
        productIds: action.result.products,
        loading: false,
        error: false,
      };
    case PRODUCT_PICKER_FAILED:
      return {
        ...state,
        productIds: null,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
