import { CHOOSE_SLOT_BUTTON_VALUE, DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import urls from 'constants/urls';
import refreshAndInitialiseOrder from 'redux/modules/checkout/actions/refresh-and-initialise-order';
import { openModal } from 'redux/modules/common-modal/actions';
import getIsAmendingOrder from 'redux/modules/trolley/selectors/amending-order';
import locator from 'utils/locator';

export default errorResponse => (dispatch, getState) => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;
  const state = getState();
  const isAmending = getIsAmendingOrder(state);

  if (isAmending && status === 409 && code === 'ORDER_ORCHESTRATION_058') {
    const onCloseCallback = value => {
      if ([DEFAULT_BUTTON_VALUE, ModalCloseOptions.CANCEL].includes(value)) {
        dispatch(refreshAndInitialiseOrder());
        return;
      }

      locator.href = urls.serviceSelection;
    };

    dispatch(
      openModal({
        id: 'checkout-error-amend-slot-expired',
        severity: 'conflict',
        buttons: [
          {
            buttonText: 'Proceed with previous slot',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
          {
            buttonText: 'Choose a new slot',
            buttonValue: CHOOSE_SLOT_BUTTON_VALUE,
            secondary: true,
          },
        ],
        messageText: [
          `You will need to re-book if you want a new slot. We've saved your previous slot.`,
        ],
        titleText: 'Sorry, your slot has now expired',
        webview: {
          id: 'checkout-error-amend-slot-expired',
          ctas: [null, urls.serviceSelection],
        },
        useIngredientsModal: false,

        onCloseCallback,
      }),
    );

    return true;
  }

  return false;
};
