import history from 'utils/history';
import queryString from 'query-string';
import omit from 'lodash/omit';
import { setAccessToken } from 'redux/modules/sessions/actions/set-access-token';
import { initiateSoacSession } from 'redux/modules/sessions/actions/initiate-session';
import { getFeatureFlags } from 'utils/feature-flags';
import locator from 'utils/locator';

const allowedOriginValues = ['sign_on_as_customer'];

export const extractAccessTokenAndCustomerIdFromQueryString = () => {
  const queryAsString = history.getLocation().search;

  if (queryAsString) {
    const parsedQuery = queryString.parse(queryAsString);
    const result = {};

    if (parsedQuery.access_token) {
      result.accessToken = parsedQuery.access_token;
    }

    if (parsedQuery.customerId && /^-?\d+$/.test(parsedQuery.customerId)) {
      result.customerId = parsedQuery.customerId;
    }

    if (parsedQuery.origin && allowedOriginValues.includes(parsedQuery.origin)) {
      result.origin = parsedQuery.origin;
    }

    return result;
  }

  return {};
};

export const replaceCurrentUrlWithoutAccessTokenAndCustomerIdInQueryString = () => {
  const location = history.getLocation();
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();

  const newSearch = omit(queryString.parse(location.search), [
    'access_token',
    'customerId',
    'origin',
  ]);

  if (OAuth2Enabled) {
    const newQueryString = queryString.stringify(newSearch);
    locator.href = `${location.pathname}${newQueryString ? `?${newQueryString}` : ''}${
      location.hash ? `#${location.hash}` : ''
    }`;
  } else {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify(newSearch),
      hash: location.hash,
    });
  }
};

export const signOnAsCustomerFromUrlToken = async dispatch => {
  const { accessToken, customerId, origin } = extractAccessTokenAndCustomerIdFromQueryString();

  if (accessToken) {
    const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();

    if (OAuth2Enabled) {
      await initiateSoacSession(accessToken, customerId)(dispatch);
    } else {
      dispatch(setAccessToken(accessToken, customerId, origin));
    }

    replaceCurrentUrlWithoutAccessTokenAndCustomerIdInQueryString();
  }
};
