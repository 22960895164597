import env from 'env/env';

const breadcrumbs = () => ({
  breadcrumbs: [
    {
      name: 'Recipes',
      path: `${env.root}/recipes`,
    },
  ],
});

export default breadcrumbs;
