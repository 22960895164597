import React from 'react';
import PropTypes from 'prop-types';
import { conflictType } from 'components/Conflict/propTypes';
import ConflictMessage from 'components/Conflict/ConflictMessage';
import ResolutionMessage from 'components/Conflict/Resolution/Message';
import styles from './ChangeSlotType.scss';

const ChangeSlotType = ({ className, conflict, conflictMessage, onClick, onPDP }) => {
  const resolution = <ResolutionMessage text={conflict.messages.trigger} />;
  const messageProps = {
    onPDP,
    resolution,
    text: conflictMessage,
    textOnly: !onPDP,
    className,
  };
  const handleClick = () => onClick(conflict);

  return (
    <div>
      <button
        className={onPDP ? styles.pdpConflict : styles.conflict}
        onClick={handleClick}
        type="button"
      >
        <ConflictMessage {...messageProps} />
      </button>
    </div>
  );
};

ChangeSlotType.propTypes = {
  className: PropTypes.string,
  conflict: conflictType.isRequired,
  conflictMessage: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onPDP: PropTypes.bool.isRequired,
};

ChangeSlotType.defaultProps = {
  className: '',
};

export default ChangeSlotType;
