import { GET } from '../actions/types';

const getAutoLinkedMembershipSuccess = (state, action) => {
  const { hasLinkedMembership } = action?.result || { hasLinkedMembership: false };

  return {
    ...state,
    status: { hasLinkedMembership },
    request: {
      ...state.request,
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const getAutoLinkedMembershipFailure = state => {
  return {
    ...state,
    status: { hasLinkedMembership: false },
    request: {
      ...state.request,
      loading: false,
      loaded: false,
    },
  };
};

const getAutoLinkedMembershipRequest = state => ({
  ...state,
  request: {
    ...state.request,
    loading: true,
    loaded: false,
    error: null,
  },
});

export default map => {
  map.set(GET.request, getAutoLinkedMembershipRequest);
  map.set(GET.success, getAutoLinkedMembershipSuccess);
  map.set(GET.failure, getAutoLinkedMembershipFailure);
  return map;
};
