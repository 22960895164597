import { DELETE_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { MyWaitroseState } from 'redux/modules/mywaitrose/state';

import { ApiError } from 'constants/errors';

const leaveMyWaitroseRequest = (state: MyWaitroseState) => ({
  ...state,
  leftMyWaitrose: false,
  request: {
    isLoading: true,
    error: null,
  },
});

const leaveMyWaitroseSuccess = (state: MyWaitroseState) => ({
  ...state,
  leftMyWaitrose: true,
  cardNumber: null,
  cardStatus: null,
  modified: null,
  points: null,
  request: {
    isLoading: false,
    error: null,
  },
});

const leaveMyWaitroseFailure = (state: MyWaitroseState, action: { payload: ApiError }) => ({
  ...state,
  request: {
    isLoading: false,
    error: action.payload,
  },
});

export default () =>
  [
    [DELETE_MYWAITROSE_CARD.request, leaveMyWaitroseRequest],
    [DELETE_MYWAITROSE_CARD.success, leaveMyWaitroseSuccess],
    [DELETE_MYWAITROSE_CARD.failure, leaveMyWaitroseFailure],
  ] as const;
