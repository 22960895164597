import createReducer from 'redux/createReducer';
import initialState from '../state/initial-state';
import addRecieveAddresses from './recieve';
import addRecieveAddressById from './recieve-by-id';
import addRequestAddAddress from './request-add';
import deleteAddressById from './delete-by-id';
import editAddressByIdRequest from './edit-by-id';
import setSelectedAddress, { updateAddresses } from './set-selected-address';
import addRequestUpdateAddressee from './request-update-addressee';
import addAffectAddressModal from './affect-address-modal';
import addCanActionOnAddress from './can-action-on-address';
import setDisplayedAddressId from './set-displayed-address-id';
import reportAddressPropertiesUpdated from './report-address-properties-edited';
import apiErrorsFound from './api-error-found';
import updateContactAddress from './update-contact-address';
import resetUpdateContactAddressError from './reset-update-contact-address-error';

const actionTypeReducerMap = new Map();
addRecieveAddresses(actionTypeReducerMap);
addRequestAddAddress(actionTypeReducerMap);
addRecieveAddressById(actionTypeReducerMap);
addRequestUpdateAddressee(actionTypeReducerMap);
deleteAddressById(actionTypeReducerMap);
editAddressByIdRequest(actionTypeReducerMap);
setSelectedAddress(actionTypeReducerMap);
addAffectAddressModal(actionTypeReducerMap);
addCanActionOnAddress(actionTypeReducerMap);
setDisplayedAddressId(actionTypeReducerMap);
reportAddressPropertiesUpdated(actionTypeReducerMap);
apiErrorsFound(actionTypeReducerMap);
updateContactAddress(actionTypeReducerMap);
resetUpdateContactAddressError(actionTypeReducerMap);

export default createReducer(initialState, actionTypeReducerMap);

export { updateAddresses };
