import root from 'window-or-global';

import { log } from 'utils/error-logging';
import { storageAvailable } from 'utils/storage-available';

const sessionStorageAvailable = () => storageAvailable('sessionStorage');

export const stashObject = (key, value) => {
  try {
    if (!sessionStorageAvailable()) return;

    const jsonValue = JSON.stringify(value);
    root.sessionStorage.setItem(key, jsonValue);
  } catch (e) {
    log(`Error stashing object with key "${key}"`, {
      logger: 'utils-stash',
      section: 'stashObject',
    });
  }
};

export const unstashObject = (key, { preserve = false } = {}) => {
  try {
    if (!sessionStorageAvailable()) return null;

    const jsonValue = root.sessionStorage.getItem(key);
    if (!preserve) root.sessionStorage.removeItem(key);

    return jsonValue ? JSON.parse(jsonValue) : null;
  } catch (e) {
    log(`Error unstashing object with key "${key}"`, {
      logger: 'utils-stash',
      section: 'unstashObject',
    });
  }

  return null;
};
