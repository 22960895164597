import React from 'react';
import { loadable } from 'components/Skeleton/LoadableSkeleton'; // This HAS to be called `loadable` to work with SSR
import Skeleton from 'components/Skeleton';

export const LoadableFavouritesPage = loadable(() => import('components/Favourites/Page'));

export const LoadableListsPage = loadable(() => import('components/Lists/Page'));

export const LoadableMyDetailsPage = loadable(() => import('components/MyDetails'));

export const LoadableMyOrdersPage = loadable(() => import('components/MyOrders'));

export const LoadableMyWaitroseHubHomepage = loadable(
  () => import('components/MyWaitrose/MyWaitroseHubHomepage'),
);

export const LoadableServiceSelection = loadable(
  () => import('components/BookSlot/ServiceSelection'),
  {
    fallback: <Skeleton height="400px" />,
  },
);

export const LoadableTrolleyPage = loadable(() => import('components/Trolley'));
