import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import { formatMinutesToString, formatDashForEmpty } from 'utils/format-recipe';

import styles from './CourseDetails.scss';

const CourseDetails = ({
  cookingTime,
  courseTitle,
  makes,
  plusTimingNotes,
  preparationTime,
  servingSize,
  totalTime,
  servingNote,
}) => {
  const cookingTimeString = formatMinutesToString(cookingTime);
  const courseTitleString = formatDashForEmpty(courseTitle);
  const preparationTimeString = formatMinutesToString(preparationTime);
  const servingSizeString = formatDashForEmpty(makes || servingSize);
  const totalTimeString = formatMinutesToString(totalTime);
  const servingString = servingNote ? `${servingSizeString} ${servingNote}` : servingSizeString;

  const canShowHorizontally =
    cookingTimeString.length <= 13 &&
    preparationTimeString.length <= 13 &&
    totalTimeString.length <= 13;

  const isServingStringExtraLong = title =>
    canShowHorizontally && servingString.length > 13 && ['Makes', 'Serves'].includes(title);

  const fields = [
    [makes ? 'Makes' : 'Serves', servingString],
    ['Course', courseTitleString],
    ['Prepare', preparationTimeString],
    ['Cook', cookingTimeString],
    ['Total time', totalTimeString],
  ];

  if (plusTimingNotes) fields.push(['Plus', plusTimingNotes]);

  return (
    <div
      data-test="course-details"
      className={classNames(styles.container, {
        [styles.withExtraColumnContainer]: plusTimingNotes,
      })}
    >
      <ul
        className={classNames(styles.list, {
          [styles.horizontal]: canShowHorizontally && !plusTimingNotes,
          [styles.withExtraColumn]: plusTimingNotes,
        })}
      >
        {fields.map(([title, subline]) => (
          <li
            key={uuidv4()}
            className={classNames({
              // This is because servingString is bigger than 13 characters and we need to create space on the right
              [styles.servingStringExtraLong]: isServingStringExtraLong(title),
            })}
          >
            <Typography element="span" styleAs="paragraphHeading">
              {title}
            </Typography>

            <Typography element="span" styleAs="paragraph">
              {subline}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseDetails;

CourseDetails.propTypes = {
  courseTitle: PropTypes.string,
  cookingTime: PropTypes.number,
  makes: PropTypes.number,
  plusTimingNotes: PropTypes.string,
  preparationTime: PropTypes.number,
  servingSize: PropTypes.number,
  servingNote: PropTypes.string,
  totalTime: PropTypes.number,
};

CourseDetails.defaultProps = {
  cookingTime: 0,
  courseTitle: '-',
  makes: 0,
  plusTimingNotes: null,
  preparationTime: 0,
  servingSize: 0,
  servingNote: null,
  totalTime: 0,
};
