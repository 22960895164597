import React from 'react';
import SignIn from 'components/AuthenticationAction/SignIn';
import SignOut from 'components/AuthenticationAction/SignOut';
import MyAccount from 'components/HomePage/Header/Account/MyAccount';
import styles from 'components/HomePage/Header/Account/Account.scss';

const Account = () => (
  <div className={styles.account} data-test="header-account">
    <MyAccount />
    <SignIn className={styles.signIn} />
    <SignOut className={styles.signOut} />
  </div>
);

Account.displayName = 'Account';

export default Account;
