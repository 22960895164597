import env from 'env/env';

import { ModalCloseOptions } from 'constants/modalCloseOptions';
import {
  confirmRestoreBrokenTrolleyModal,
  restoreBrokenTrolleyPatchOrderItemsFailedModal,
} from 'constants/modals';

import { openModal } from 'redux/modules/common-modal/actions';

import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import { pushTrolleyItemsToApi } from 'redux/modules/trolley/actions/push-trolley-items-to-api';
import { getBrokenTrolleyObjectFromStorage } from 'redux/modules/trolley/selectors/get-broken-trolley';

import { goToInternalOrExternalLocation } from 'utils/go-to-internal-or-external-location';
import { local as storage } from 'utils/storage';

export function restoreBrokenTrolley() {
  return dispatch => {
    dispatch(
      openModal({
        ...confirmRestoreBrokenTrolleyModal,
        onCloseCallback: modalCloseOption => {
          if (modalCloseOption === ModalCloseOptions.OK) {
            const brokenTrolley = getBrokenTrolleyObjectFromStorage();

            if (
              brokenTrolley &&
              brokenTrolley.trolleyItems &&
              brokenTrolley.trolleyItems.length > 0
            ) {
              return dispatch(pushTrolleyItemsToApi(brokenTrolley.trolleyItems))
                .then(() => {
                  dispatch(getTrolley());

                  storage.remove('brokenTrolley');

                  goToInternalOrExternalLocation(`${env.root}/shop/trolley`);
                })
                .catch(() => {
                  dispatch(openModal({ ...restoreBrokenTrolleyPatchOrderItemsFailedModal }));
                });
            }
          }

          return false;
        },
      }),
    );
  };
}
