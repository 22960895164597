const reducer = state => ({
  ...state,
  partnerDiscountCardNumber: null,
  promoCodes: {
    ...state.promoCodes,
    isRemoving: false,
    requestedPromoCode: null,
  },
  waiting: false,
});

export default reducer;
