import { createSelector } from 'reselect';

import orderStatus from 'constants/orderStatus';
import { getOrderData } from './index';

export const getOrderStatus = createSelector(getOrderData, orderData => orderData?.status);

export const activeOrderIsAmending = createSelector(
  getOrderStatus,
  status => status === orderStatus.AMENDING,
);
