import update from 'immutability-helper';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import { deepFreeze } from 'utils/deepFreeze';

import {
  FAVOURITES_ALL_FULFILLED,
  FAVOURITES_ALL_PENDING,
} from 'redux/modules/favourites-products/actions/types';
import {
  FAVOURITE_FAILED,
  FAVOURITE_TOGGLED,
  FAVOURITE_TOGGLING,
} from 'redux/modules/search-and-browse/actions/types';

const initialState = deepFreeze({
  fetched: false,
  lineNumbers: {},
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FAVOURITES_ALL_FULFILLED:
      return {
        ...state,
        fetched: true,
        lineNumbers: mapValues(keyBy(action.result, 'lineNumber'), () => true),
      };
    case FAVOURITES_ALL_PENDING:
      return {
        ...state,
        fetched: false,
        lineNumbers: initialState.lineNumbers,
      };
    case FAVOURITE_TOGGLED: {
      const { lineNumber, result: { status } = {} } = action;
      const success = [200, 204].includes(status);
      const favourite = status === 200;
      if (!success) return state;

      return update(state, {
        lineNumbers: {
          [lineNumber]: {
            $set: favourite,
          },
        },
      });
    }
    case FAVOURITE_FAILED:
    case FAVOURITE_TOGGLING: {
      const { lineNumber } = action;

      return update(state, {
        lineNumbers: {
          [lineNumber]: {
            $set: !state.lineNumbers[lineNumber],
          },
        },
      });
    }
    default:
      return state;
  }
}
