import { productsSchema } from 'redux/schemas';
import getNormalisedProductConflicts from './get-normalised-product-conflicts';

const getNormalisedProductConflictsFromBundle = data =>
  getNormalisedProductConflicts(
    {
      conflicts: data.productCollections.flatMap(
        ({ conflicts: bundleConflicts }) => bundleConflicts,
      ),
    },
    productsSchema,
  );

export default getNormalisedProductConflictsFromBundle;
