import isEqual from 'lodash/isEqual';
import { findItemsByBatchId } from 'redux/modules/trolley/utils/findItemsByBatchId';
import { findItemByLineNumber } from 'redux/modules/trolley/utils/findItemByLineNumber';

export const removeLastUpdatedOptimisticItems = (
  lastUpdatedItem,
  items,
  removeOptimisticTrolleyItemAction,
  removeMultipleOptimisticTrolleyItems,
) => {
  if (lastUpdatedItem.batchId) {
    const batchTrolleyItems = items
      .filter(({ batchId, processing }) => batchId === lastUpdatedItem.batchId && processing)
      .map(({ lineNumber }) => ({ lineNumber }));
    removeMultipleOptimisticTrolleyItems(batchTrolleyItems);
  } else {
    removeOptimisticTrolleyItemAction(lastUpdatedItem.lineNumber);
  }
};

export const handleOptimisticSubAll = (
  nextItem,
  optimisticTrolleyItems,
  optimisticallyUpdateMultipleTrolleyItemsAction,
  toggleSubAll,
) => {
  const updatedOptimisticTrolleyItems = [];
  findItemsByBatchId(optimisticTrolleyItems, nextItem.batchId).forEach(({ batchId, ...rest }) => {
    updatedOptimisticTrolleyItems.push({
      batchId,
      ...rest,
      processing: true,
    });
  });
  optimisticallyUpdateMultipleTrolleyItemsAction(updatedOptimisticTrolleyItems);
  toggleSubAll(updatedOptimisticTrolleyItems[0].canSubstitute);
};

export const handleOptimisticSingleItemUpdates = (
  nextItem,
  trolleyItems,
  optimisticallyUpdateTrolleyItemAction,
  addNewProductToTrolleyAction,
  updateTrolleyItemAction,
  allowSubstitutions,
  saveShopperNotes,
) => {
  const trolleyItem = findItemByLineNumber(trolleyItems, nextItem.lineNumber);
  optimisticallyUpdateTrolleyItemAction({
    ...nextItem,
    processing: true,
  });
  if (!trolleyItem || trolleyItem.quantity.amount === 0) {
    addNewProductToTrolleyAction({
      lineNumber: nextItem.lineNumber,
      noteToShopper: nextItem.noteToShopper,
      personalisedMessage: nextItem.personalisedMessage,
      productId: nextItem.productId,
      quantity: nextItem.quantity,
      clickContext: nextItem.analytics.clickContext,
      productPosition: nextItem.analytics.productPosition,
      searchType: nextItem.analytics.searchType,
    });
  } else if (!isEqual(nextItem.quantity, trolleyItem.quantity)) {
    updateTrolleyItemAction({
      lineNumber: nextItem.lineNumber,
      newQuantity: nextItem.quantity,
      clickContext: nextItem.analytics.clickContext,
      canSubstitute: nextItem.canSubstitute,
      noteToShopper: nextItem.noteToShopper,
      productPosition: nextItem.analytics.productPosition,
      searchType: nextItem.analytics.searchType,
    });
  } else if (nextItem.canSubstitute !== !!trolleyItem.canSubstitute) {
    allowSubstitutions(trolleyItem.trolleyItemId, nextItem.canSubstitute);
  } else if (nextItem.noteToShopper !== trolleyItem.noteToShopper) {
    saveShopperNotes(trolleyItem.trolleyItemId, nextItem.noteToShopper);
  }
};
