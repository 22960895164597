import React from 'react';

import { useSelector } from 'react-redux';
import { getSeasonalMenuExperienceFragment } from 'redux/modules/experience-fragments/selectors/get-seasonal-menu-experience-fragment';
import useMenus from 'hooks/use-menus';
import SiteHeaderOverlay from 'components/SiteHeader/SiteHeaderOverlay/SiteHeaderOverlay';

import SeasonalMenuPanels from './SeasonalMenuPanels';

export const SeasonalMenu = () => {
  const { seasonalMenuTitle, seasonalMenuContent } = useSelector(getSeasonalMenuExperienceFragment);
  const { isSeasonalMenuOpen, toggleSeasonalMenu } = useMenus();

  const shouldShowSeasonalMenu = !!seasonalMenuTitle && !!seasonalMenuContent;

  if (!shouldShowSeasonalMenu) return null;

  return (
    <>
      <SeasonalMenuPanels
        isOpen={isSeasonalMenuOpen}
        onToggle={toggleSeasonalMenu}
        title={seasonalMenuTitle}
      />
      <SiteHeaderOverlay isActive={isSeasonalMenuOpen} />
    </>
  );
};

SeasonalMenu.displayName = 'SeasonalMenu';
