import { CampaignName, SlotDate, SlotGridType, SlotTime } from 'api/definitions/slotdates/types';
import { Campaign } from 'redux/modules/slot-dates/state/initial-state';

type Action = {
  payload: {
    slotDates: SlotDate[];
    slotTimes: {
      slotGridType: SlotGridType;
      slotTimes: SlotTime[];
    }[];
    campaigns: Partial<Record<CampaignName, Campaign>>;
    isPriority: boolean;
  };
};

const reducer = (state: WtrState, action: Action) => {
  const { slotDates, campaigns, slotTimes, isPriority } = action.payload;
  return {
    ...state,
    dates: slotDates,
    times: slotTimes,
    campaigns,
    isPriority,
    loading: false,
    error: null,
  };
};

export default reducer;
