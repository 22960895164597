import React from 'react';
import classNames from 'classnames';
import headConfig from 'constants/head-config';
import WaitroseLogo from 'components/WaitroseLogo';
import styles from 'components/HeaderLogo/Logo.scss';

export interface LogoProps {
  checkout?: boolean;
  homepage?: boolean;
  small?: boolean;
  minimal?: boolean;
}

const Logo = ({
  checkout = false,
  homepage = false,
  minimal = false,
  small = false,
}: LogoProps) => (
  <>
    {small ? (
      <WaitroseLogo small className={styles.logoSmall} />
    ) : (
      <WaitroseLogo
        className={classNames([
          {
            [styles.logoRegular]: !checkout,
            [styles.checkout]: checkout,
            [styles.homepage]: homepage,
            [styles.minimal]: minimal,
          },
        ])}
      />
    )}
    <span className="sr-only">{`Visit ${headConfig.meta.title}`}</span>
  </>
);

Logo.displayName = 'Logo';

export default Logo;
