import mapProductsIntoLegacyFormat from 'redux/transforms/transform-product-into-legacy-format';
import { Product } from 'constants/products';
import { TransformedCriteria, TransformedConflict, TransformedLegacyProduct } from './types';

type ProductsAndRecommendations<Data extends Record<string, unknown>> = Data & {
  recommendations: Product[];
} & (Data['products'] extends object
    ? { products: Product[] }
    : { componentsAndProducts: Product[] });

type TransformedProducts<Data extends Record<string, unknown>> = Omit<
  Data,
  'products' | 'componentsAndProducts'
> & {
  criteria: Data['links'] extends object ? TransformedCriteria[] : Data['criteria'];
  conflicts: TransformedConflict[];
  totalMatches: number;
} & (Data['products'] extends object
    ? { products: TransformedLegacyProduct[] }
    : { componentsAndProducts: TransformedLegacyProduct[] });

export type TransformedProductsAndRecommendations<Data extends Record<string, unknown>> =
  TransformedProducts<Omit<Data, 'recommendations'>> & {
    recommendations: TransformedLegacyProduct[];
  };

const mapProductsAndRecommendationsIntoLegacyFormat = <Data extends Record<string, unknown>>(
  data: ProductsAndRecommendations<Data>,
): TransformedProductsAndRecommendations<Data> => {
  const dataWithMappedProducts = mapProductsIntoLegacyFormat(data) as TransformedProducts<Data>;
  const { recommendations: products = [] } = data;
  const { products: recommendations } = mapProductsIntoLegacyFormat({
    products,
  }) as { products: TransformedLegacyProduct[] };

  return {
    ...dataWithMappedProducts,
    recommendations,
  };
};

export default mapProductsAndRecommendationsIntoLegacyFormat;
