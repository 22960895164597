import { useEffect } from 'react';
import { dataLayer } from 'analytics/data-layer';

// [SSR][OK] Only used onClick and won't impact SSR
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointCurrent } from 'utils/mediaQueries';
import { IngredientGroups } from 'redux/modules/recipes/index.d';
import { getKeyByValue } from 'redux/modules/recipes/utils/sort-by';

export function trackRecipeClick(id: string, name: string, position: string, url: string) {
  dataLayer.push({
    event: 'promo_click',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            breakpoint: mediaBreakpointCurrent(),
            assetModuleType: 'Recipes',
            enhancedEcommerceInternalPromotion: 'true',
            creative: 'Unassigned',
            analyticsComponentType: 'components/recipes/content/recipecardsgrid/card',
            altText: name,
            imageUrl: 'Unassigned',
            linkURL: url,
            id,
            name,
            position,
            target: '_self',
          },
        ],
      },
    },
  });
}

export function trackGridSort(sortMethod: string) {
  const label = getKeyByValue(sortMethod) || 'unknown';
  dataLayer.push({
    event: 'recipe_sort',
    sort_method: label,
  });
}

export function trackGridFilter(filters: string[]) {
  dataLayer.push({
    event: 'recipe_filter',
    filter_current_selection: filters?.sort().join(' | '),
  });
}

export function trackRecipeTabNavigation(activeTab: number) {
  let label = '';
  switch (activeTab) {
    case 0:
    default:
      label = 'Ingredients';
      break;
    case 1:
      label = 'Method';
      break;
    case 2:
      label = 'Nutrition';
      break;
  }
  dataLayer.push({
    event: 'recipe_tab_navigation',
    recipe_tab: label,
  });
}

export const useTrackRecipesSearchPageLoad = (
  loaded: boolean,
  searchTerm: string,
  numberOfResults: number,
) => {
  useEffect(() => {
    if (loaded && searchTerm) {
      dataLayer.push({
        event: 'recipe_search',
        search: {
          numberOfResults,
          searchTerm,
        },
      });
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps
};

export function trackViewShoppableRecipe(
  recipeTitle: string,
  recipePath: string,
  averageRating?: number,
) {
  dataLayer.push({
    event: 'view_shoppable_recipe',
    recipe: {
      name: recipeTitle,
      page_path: recipePath,
      average_rating: averageRating,
    },
  });
}

export function trackRecipeShare(actionType: string) {
  dataLayer.push({
    event: 'share_recipe',
    action: actionType,
  });
}

export const trackRecipe = (id: string, data: object) =>
  dataLayer.push({ ...data, recipe_name: id });

type TrackedProduct = {
  id: string;
  sponsored: string;
  index: string;
  pod_type: string;
  availability: string;
  sponsored_source: string;
};

export const trackShoppableBanner = (
  type: string,
  name: string,
  shoppableProducts: IngredientGroups[],
) => {
  const productsFormatter = (products: IngredientGroups[]) => {
    const defaultData = {
      index: '1',
      pod_type: 'ingredients',
      availability: 'available',
      sponsored_source: 'unknown',
    };

    return products.reduce<TrackedProduct[]>((flattenedProducts, { ingredients }) => {
      const { storeCupboard, nonStoreCupboard } = ingredients;

      if (storeCupboard && storeCupboard?.length > 0) {
        storeCupboard.forEach(({ isSponsored, lineNumber }) => {
          flattenedProducts.push({
            ...defaultData,
            id: lineNumber,
            sponsored: isSponsored ? 'YES' : 'NO',
          });
        });
      }

      if (nonStoreCupboard && nonStoreCupboard?.length > 0) {
        nonStoreCupboard.forEach(({ isSponsored, lineNumber }) => {
          flattenedProducts.push({
            ...defaultData,
            id: lineNumber,
            sponsored: isSponsored ? 'YES' : 'NO',
          });
        });
      }

      return flattenedProducts;
    }, []);
  };

  dataLayer.push({
    event: 'shop_ingredients',
    recipe_name: name,
    location: type,
    products: productsFormatter(shoppableProducts),
  });
};

export const trackShoppableModal = (type: string, name: string, id: string) =>
  dataLayer.push({
    event: 'navigate_ingredients_modal',
    action: type,
    recipe_name: name,
    product_id: id,
  });

export const trackShoppableSwap = (
  action: string,
  recipeId: string,
  lineNumber: string | undefined,
  swappedLineNumber?: string,
) => {
  dataLayer.push({
    event: 'swap_ingredients',
    action,
    recipe_name: recipeId,
    product_id: lineNumber,
    swapped_id: swappedLineNumber,
  });
};
