import { HARD_TYPE } from 'constants/restrictions';
import { deepFreeze } from 'utils/deepFreeze';

import {
  ADD_ALL_ITEMS_FULFILLED,
  ADD_ALL_ITEMS_PENDING,
} from 'redux/modules/add-all-items/actions/types';
import { CLEAR_TROLLEY_ERRORS } from 'redux/modules/trolley-errors/actions/types';

const initialState = deepFreeze({
  conflictedProducts: [],
});

export default function reducer(state = initialState, action = {}) {
  const { result = {}, type: actionType } = action;

  switch (actionType) {
    case ADD_ALL_ITEMS_FULFILLED: {
      const { trolley: { conflicts = [] } = {} } = result;

      return {
        ...state,
        conflictedProducts: conflicts.filter(
          ({ addToTrolley = true, type } = {}) => type === HARD_TYPE && !addToTrolley,
        ),
      };
    }
    case ADD_ALL_ITEMS_PENDING:
    case CLEAR_TROLLEY_ERRORS:
      return {
        ...state,
        conflictedProducts: [],
      };
    default:
      return state;
  }
}
