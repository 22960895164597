import { addEventListener, removeEventListener } from 'utils/event-listener-wrapper';

const singleEventWatcher = eventType => callback => {
  if (!eventType || !callback) return;

  const eventCallback = () => {
    callback();
    removeEventListener(eventType, eventCallback);
  };

  addEventListener(eventType, eventCallback);
};

export const singleResizeWatcher = singleEventWatcher('resize');
