export async function initApmRum({ isEnabled = false }: { isEnabled?: boolean } | undefined = {}) {
  if (!isEnabled) {
    return;
  }
  try {
    const { init } = await import('./apm-rum');
    init();
  } catch (error) {
    console.error('Failed to initialise APM RUM:', error);
  }
}
