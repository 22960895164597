import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

export const getExperienceFragmentByKey = (state: WtrState, experienceFragmentKey: string) =>
  getStateAtNamespaceKey(state, 'experienceFragments')[experienceFragmentKey];

export const hasExperienceFragmentByKey = (state: WtrState, experienceFragmentKey: string) =>
  !!getExperienceFragmentByKey(state, experienceFragmentKey);

export const getExperienceFragmentLocation = (
  state: WtrState,
  experienceFragmentKey: string,
  location: string,
) => {
  const { locations } = getExperienceFragmentByKey(state, experienceFragmentKey) || {};

  return locations?.[location];
};

export const hasExperienceFragmentLocation = (
  state: WtrState,
  experienceFragmentKey: string,
  location: string,
) => !!getExperienceFragmentLocation(state, experienceFragmentKey, location)?.length;

export const getExperienceFragmentPageTitle = (state: WtrState, experienceFragmentKey: string) =>
  getExperienceFragmentByKey(state, experienceFragmentKey)?.pageTitle?.text;

export default getExperienceFragmentLocation;
