import { connect } from 'react-redux';

import { customerSlotSelector, hasBookedSlot } from 'redux/modules/customer-slot/selectors';

import SlotButtonText from './SlotButtonText';

export default connect(state => ({
  isSlotBooked: hasBookedSlot(state),
  slotDetails: customerSlotSelector(state),
}))(SlotButtonText);
