import { GET_PROPOSITIONS } from 'redux/modules/incentive-offer-propositions/actions/types';
import { IncentiveOfferPropositionsState } from 'redux/modules/incentive-offer-propositions/state';

import { ApiError } from 'constants/errors';

const getPropositionsRequest = (
  state: IncentiveOfferPropositionsState,
): IncentiveOfferPropositionsState => ({
  ...state,
  isLoading: true,
  isLoaded: false,
  serverError: null,
});

const getPropositionsSuccess = (
  state: IncentiveOfferPropositionsState,
  action: {
    payload: {
      ipick: {
        remainingPicks: number;
        totalPicks: number;
        lockedOrUsedPicks: number;
      };
    };
  },
): IncentiveOfferPropositionsState => {
  const { ipick } = action.payload;

  return {
    ...state,
    propositionsList: {
      ...state.propositionsList,
      ipick,
    },
    isLoading: false,
    isLoaded: true,
  };
};

const getPropositionsFailure = (
  state: IncentiveOfferPropositionsState,
  action: { payload: ApiError },
): IncentiveOfferPropositionsState => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  serverError: action.payload,
});

export default () =>
  [
    [GET_PROPOSITIONS.request, getPropositionsRequest],
    [GET_PROPOSITIONS.success, getPropositionsSuccess],
    [GET_PROPOSITIONS.failure, getPropositionsFailure],
  ] as const;
