import { matchPath } from 'react-router-dom';
import queryString from 'query-string';
import { addYears } from 'utils/date';

import { getCookie, saveCookie } from 'utils/cookie';
import locator from 'utils/locator';
import { dataLayer } from 'analytics/data-layer';

import urls from 'constants/urls';
import {
  COOKIE_CONSENT,
  COOKIE_ADVERTISING,
  COOKIE_ANALYTICS,
  COOKIE_FUNCTIONAL,
  COOKIE_DATALAYER,
  COOKIE_EXPERIMENTAL_UI,
} from 'constants/cookies';

import {
  COOKIES_FORCE_SHOW_CONTROLS,
  COOKIES_INIT,
  COOKIES_ACCEPT_ALL,
  COOKIES_REJECT_ALL,
  COOKIES_UPDATE_PREFERENCES,
  COOKIES_VIEW_PREFERENCES,
  COOKIES_MONETATE,
} from 'redux/modules/cookies/actions/types';

const isDeclined = (newProp, oldProp) => !newProp && oldProp;

const nextYear = () => addYears(new Date(), 1);

const savePreferences = ({ analytics, functional, marketing }) => {
  if (marketing)
    dataLayer.push({
      event: COOKIE_DATALAYER,
      gdpr: { marketing: !!marketing },
    });

  if (analytics)
    dataLayer.push({
      event: COOKIE_DATALAYER,
      gdpr: { analytics: !!analytics },
    });

  if (functional)
    dataLayer.push({
      event: COOKIE_DATALAYER,
      gdpr: { functional: !!functional },
    });

  saveCookie(COOKIE_CONSENT, '1', nextYear());
  saveCookie(COOKIE_ADVERTISING, marketing ? '1' : '0', nextYear());
  saveCookie(COOKIE_ANALYTICS, analytics ? '1' : '0', nextYear());
  saveCookie(COOKIE_FUNCTIONAL, functional ? '1' : '0', nextYear());
};

const coerceCookieValue = (consented, cookieName) => {
  const cookieValue = getCookie(cookieName);

  if (consented) {
    return cookieValue !== '0';
  }

  return null;
};

export const readCookieConsentCookies = () => {
  const { showCookieModal, returnUrl } = queryString.parse(locator.search);

  const consented = getCookie(COOKIE_CONSENT) === '1';

  const marketing = coerceCookieValue(consented, COOKIE_ADVERTISING);
  const analytics = coerceCookieValue(consented, COOKIE_ANALYTICS);
  const functional = coerceCookieValue(consented, COOKIE_FUNCTIONAL);

  const preventModalAndLimitScripts = matchPath({ path: `${urls.checkout}/*` }, locator.pathname);

  if (preventModalAndLimitScripts) {
    // This both prevents the cookie modal being displayed on these pages (useful for the Apps WebView)
    // And prevents marketing and functional scripts from being loaded on these pages
    return {
      type: COOKIES_INIT,
      data: {
        consented: true,
        marketing: false,
        analytics,
        functional: false,
      },
    };
  }

  const preventModal =
    matchPath({ path: urls.orderConfirmation }, locator.pathname) ||
    matchPath({ path: urls.citrusAdsPreview }, locator.pathname);

  if (preventModal) {
    // This prevents the cookie modal being displayed on these pages (useful for the Apps WebView)
    // But it doesn't force any scripts off, it will retain the customer's choice if they made one
    return {
      type: COOKIES_INIT,
      data: {
        consented: true,
        marketing,
        analytics,
        functional,
      },
    };
  }

  dataLayer.push({
    event: COOKIE_DATALAYER,
    gdpr: { marketing: !!marketing, analytics: !!analytics, functional: !!functional },
  });

  if (showCookieModal !== undefined) {
    return {
      type: COOKIES_FORCE_SHOW_CONTROLS,
      data: {
        forceShowFullModal: true,
        returnUrl,
        marketing,
        analytics,
        functional,
      },
    };
  }

  const cookies = {
    analytics,
    consented,
    functional,
    marketing,
  };

  return {
    type: COOKIES_INIT,
    data: cookies,
  };
};

export const readMonetateCookie = () => {
  const experimental = !!getCookie(COOKIE_EXPERIMENTAL_UI);

  return { type: COOKIES_MONETATE, data: { experimental } };
};

export const acceptAll = () => {
  savePreferences({ analytics: true, functional: true, marketing: true });
  return { type: COOKIES_ACCEPT_ALL };
};

export const rejectAll = () => {
  savePreferences({ analytics: false, functional: false, marketing: false });
  return { type: COOKIES_REJECT_ALL };
};

export const viewPreferences = () => ({ type: COOKIES_VIEW_PREFERENCES });

export const updatePreferences = dispatch => (preferences, previousPreferences, returnUrl) => {
  const hasDeclinedMarketing = isDeclined(preferences.marketing, previousPreferences.marketing);
  const hasDeclinedAnalytics = isDeclined(preferences.analytics, previousPreferences.analytics);
  const hasDeclinedFunctional = isDeclined(preferences.functional, previousPreferences.functional);

  savePreferences(preferences);

  if (returnUrl) {
    locator.replace(returnUrl);
    return undefined;
  }

  if (hasDeclinedMarketing || hasDeclinedAnalytics || hasDeclinedFunctional) {
    locator.reload();
    return undefined;
  }

  return dispatch({ type: COOKIES_UPDATE_PREFERENCES, data: preferences });
};
