import root from 'window-or-global';
import castArray from 'lodash/castArray';

import { ADD_DELAYED_ACTION, CANCEL_DELAYED_ACTION } from './types';

/** @type {import('./index').TimerMap} */
export const timers = new Map();

let dispatchEvent;

function clearTimer(timerName) {
  const timer = timers.get(timerName);
  if (timer) {
    root.clearTimeout(timer.timerRef);
    timers.delete(timerName);
  }
}

/**
 *
 * @param {string} timerName
 * @param {import('./index').TimerConfig} timer
 */
const triggerTimerAction = (timerName, { action, actionType, actionPayload }) => {
  if (typeof action === 'function') {
    dispatchEvent(action);
  } else {
    dispatchEvent({
      type: actionType,
      payload: actionPayload,
    });
  }

  clearTimer(timerName);
};

/** @returns {import('./index').TimerConfig} */
function createTimer({
  /** action to be invoked */
  action = undefined,
  /** action payload that will be dispatched each timer interval */
  actionPayload = undefined,
  /** action type that will be dispatched each timer interval */
  actionType = undefined,
  /** timer name - need for search in timers obj */
  timerName,
  /** how many how long to wait in ms before dispatching the event */
  timeout,
}) {
  /** @type {import('./index').TimerConfig} */
  let timer;

  const timerRef = root.setTimeout(() => triggerTimerAction(timerName, timer), timeout);

  const startTime = Date.now();

  timer = {
    timerRef,
    timeout,
    startTime,
    endTime: startTime + timeout,
    action,
    actionType,
    actionPayload,
  };

  return timer;
}

export const startListeningToPageVisibilityEvents = () => {
  // this sets up an event listener (if supported), to recreate the timers when the document receives focus
  // this is to ensure that the event is not lost by the browser
  if (
    // this line tests if the document is available and if it has the addEventListener function.
    // If it doesn't it could be running in node, which doesn't support the API we need
    typeof root?.document?.addEventListener !== 'undefined' &&
    // This line tests if the Page Visibility API is available
    root.document.hidden !== undefined
  ) {
    root.document.addEventListener(
      'visibilitychange',
      () => {
        if (!root.document.hidden) {
          // not exactly my favourite syntax, but this is the only way eslint won't complain
          [...timers.entries()].forEach(([timerName, timer]) => {
            // ensures the event won't be triggered twice
            root.clearTimeout(timer.timerRef);

            // copies the object, just so eslint doesn't complain
            const newTimer = { ...timer };

            // sets a new timeout, and updates the timerRef
            newTimer.timerRef = root.setTimeout(
              () => triggerTimerAction(timerName, newTimer),
              newTimer.endTime - Date.now(),
            );

            // replaces the object in the map
            timers.set(timerName, newTimer);
          });
        }
      },
      false,
    );
  }
};

export default ({ dispatch }) =>
  next =>
  action => {
    if (!dispatchEvent) {
      dispatchEvent = dispatch;
      startListeningToPageVisibilityEvents();
    }
    const { type, payload } = action;

    switch (type) {
      case ADD_DELAYED_ACTION: {
        const { timerName } = payload;

        // if we start timer that already started
        if (timers.has(timerName)) {
          clearTimer(timerName);
        }

        // Setup the timer and get the reference to it
        const timerConfig = createTimer(payload);
        timers.set(timerName, timerConfig);

        break;
      }

      case CANCEL_DELAYED_ACTION: {
        const { timerName } = payload;
        castArray(timerName).forEach(item => clearTimer(item));
        break;
      }

      default:
        break;
    }

    return next(action);
  };
