import createReducer from 'redux/createReducer';
import initialState from './initial-state';
import searchAddresses from './search-addresses';
import recieveAddressBySearchId from './recieve-by-search-id';
import resetAddressSuggestion from './reset-address-suggestion';
import amendFoundAddressee from './amend-found-addressee';
import reportAddressSuggestion from './report-address-suggestions';
import updateNavigation from './update-navigation';

const actionTypeReducerMap = new Map();
searchAddresses(actionTypeReducerMap);
recieveAddressBySearchId(actionTypeReducerMap);
resetAddressSuggestion(actionTypeReducerMap);
amendFoundAddressee(actionTypeReducerMap);
reportAddressSuggestion(actionTypeReducerMap);
updateNavigation(actionTypeReducerMap);

export default createReducer(initialState, actionTypeReducerMap);
