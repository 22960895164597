import { deepFreeze } from 'utils/deepFreeze';

type Request =
  | {
      loading: boolean;
      loaded: boolean;
      error?: unknown;
    }
  | unknown;

interface Modal {
  isOpen: boolean;
}

interface ChooseAddressModal {
  address?: unknown;
  updated: boolean;
  choosenPosition?: unknown;
}

interface CannotAlterAddress {
  options: unknown[];
  addressId: string;
}

interface AddressBook {
  collapsed: boolean;
}

interface Addressee {
  contactNumber: string;
  firstName: string;
  lastName: string;
  modified?: boolean | unknown;
  title: string;
}

interface CustomerNote {
  value: string;
}

interface Delivery {
  customerNote: CustomerNote;
}

export interface Address {
  line1: string;
  line2?: string;
  line3?: string;
  delivery?: Delivery;
  town: string;
  region?: string;
  country: string;
  postalCode: string;
  id: string;
  addressStatus?: string;
  addressee?: Addressee;
  created?: string;
  isContactAddress?: boolean;
  myWaitrose?: boolean;
}

export interface AddressesState {
  addressInError?: unknown;
  addAddressLoading: boolean;
  addresses: Address[];
  addressBook: AddressBook;
  cannotAlterAddress: CannotAlterAddress;
  chooseAddressModal: ChooseAddressModal;
  editAddressLoading: boolean;
  getAllAddressesRequestStatus: 'pending' | 'loading' | 'loaded' | 'error';
  modal: Modal;
  request: Request;
  selectedAddress: unknown;
  editedFields: unknown[];
  updateContactAddressError: boolean;
  displayedAddressId: string | undefined;
}

const initialState: Readonly<AddressesState> = deepFreeze({
  addressInError: null,
  addAddressLoading: false,
  addresses: [],
  addressBook: {
    collapsed: true,
  },
  cannotAlterAddress: {
    options: [],
    addressId: '',
  },
  chooseAddressModal: {
    address: null,
    updated: false,
    choosenPosition: null,
  },
  displayedAddressId: undefined,
  editAddressLoading: false,
  getAllAddressesRequestStatus: 'pending',
  modal: {
    isOpen: false,
  },
  request: {},
  selectedAddress: {},
  editedFields: [],
  updateContactAddressError: false,
});

export default initialState;
