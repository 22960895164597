import { apiCallback } from 'api';

const definition = {
  service: 'address',
  endpoint: 'addresses/${addressId}/delivery',
  verbs: ['patch'],
  defaultVersion: 1,
};

export default {
  patch: apiCallback(definition, 'patch'),
};
