import { DIRECTION_LEFT } from 'constants/directions';

const BUTTON_WIDTH = 48; // $ingredients-unit * 12

export const getNewSliderScrollPosition = (slider, direction, buttonWidth = BUTTON_WIDTH) => {
  const targetScrollAmount = Math.round(slider.clientWidth * 0.8) - buttonWidth;

  if (direction === DIRECTION_LEFT) {
    return Math.max(0, slider.scrollLeft - targetScrollAmount);
  }

  const maxScroll = slider.scrollWidth - slider.clientWidth;
  const newPosition = targetScrollAmount + slider.scrollLeft;

  return newPosition < maxScroll ? newPosition : maxScroll;
};
