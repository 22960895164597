import React, { ElementType } from 'react';

import { TypographySecondary } from '@johnlewispartnership/wtr-ingredients/foundations/typography/TypographySecondary';

import styles from './index.scss';

const RecipesTitle = ({ value, headingLevel }: { value: string; headingLevel: number }) => (
  <div data-testid="recipes-title">
    {/* @ts-expect-error: size is set in css not as a prop */}
    <TypographySecondary
      component={`h${headingLevel}` as ElementType}
      font="sebenta"
      className={styles.h1}
    >
      {value}
    </TypographySecondary>
  </div>
);

RecipesTitle.displayName = 'RecipesTitle';

export default RecipesTitle;
