import { ApiError } from 'constants/errors';
import { deepFreeze } from 'utils/deepFreeze';

export interface IncentiveOfferPropositionsState {
  propositionsList: {
    ipick: {
      remainingPicks: number;
      totalPicks: number;
      lockedOrUsedPicks: number;
    };
  };
  isLoading: boolean;
  isLoaded: boolean;
  serverError: ApiError | null;
}

export const initialState: Readonly<IncentiveOfferPropositionsState> = deepFreeze({
  propositionsList: {
    ipick: {
      remainingPicks: 0,
      totalPicks: 0,
      lockedOrUsedPicks: 0,
    },
  },
  isLoading: false,
  isLoaded: false,
  serverError: null,
});

export default initialState;
