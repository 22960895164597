import React from 'react';
import FocusTrapComponent from 'focus-trap-react';
import PropTypes from 'prop-types';

class FocusTrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children, ...focusTrapProps } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return children;
    }

    return <FocusTrapComponent {...focusTrapProps}>{children}</FocusTrapComponent>;
  }
}

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FocusTrap;
