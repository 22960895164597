import { apiCallback } from 'api';

const defaultDefinition = {
  service: 'token-client',
  query: {
    clientType: 'WEB_APP',
  },
  defaultVersion: 1,
};

const csrfDefinition = {
  ...defaultDefinition,
  endpoint: 'csrf',
};

const logoutDefinition = {
  ...defaultDefinition,
  endpoint: 'logout',
};

const soacDefinition = {
  ...defaultDefinition,
  endpoint: 'soac',
};

const tokenDefinition = {
  ...defaultDefinition,
  endpoint: 'token',
};

export default {
  csrf: apiCallback(csrfDefinition, 'get'),
  logout: apiCallback(logoutDefinition, 'post'),
  soac: apiCallback(soacDefinition, 'post'),
  token: apiCallback(tokenDefinition, 'post'),
};
