import {
  ANDROID_APP_STORE_CLICKED,
  APPLE_APP_STORE_CLICKED,
  APPLE_WALLET_BUTTON_CLICKED,
  PUT_MYWAITROSE_CARD,
  GET_MYWAITROSE_CARD_BY_ID,
  DELETE_MYWAITROSE_CARD,
  POST_MYWAITROSE_CARD,
} from 'redux/modules/mywaitrose/actions/types';
import { HIDE_MYWAITROSE_APPLE_WALLET_ERROR } from 'redux/modules/apple-wallet/actions/types';

import * as apiAction from './api-actions';
import {
  androidAppStoreButtonClicked,
  appleAppStoreButtonClicked,
  appleWalletButtonClicked,
  appleWalletButtonErrored,
} from './app-store-actions';

export default {
  [ANDROID_APP_STORE_CLICKED]: androidAppStoreButtonClicked,
  [APPLE_APP_STORE_CLICKED]: appleAppStoreButtonClicked,
  [APPLE_WALLET_BUTTON_CLICKED]: appleWalletButtonClicked,
  [HIDE_MYWAITROSE_APPLE_WALLET_ERROR]: appleWalletButtonErrored,
  [DELETE_MYWAITROSE_CARD.failure]: apiAction.deleteCardFailure,
  [DELETE_MYWAITROSE_CARD.success]: apiAction.deleteCardSuccess,
  [GET_MYWAITROSE_CARD_BY_ID.failure]: apiAction.getCardByIdFailure,
  [POST_MYWAITROSE_CARD.failure]: apiAction.postCardFailure,
  [POST_MYWAITROSE_CARD.success]: apiAction.postCardSuccess,
  [PUT_MYWAITROSE_CARD.failure]: apiAction.putCardFailure,
  [PUT_MYWAITROSE_CARD.success]: apiAction.putCardSuccess,
};
