export const MY_WAITROSE_CARD_REPLACEMENT_AEM_TYPE = 'myWaitroseReplacementCard';
export const MY_WAITROSE_CARD_REPLACEMENT_NAME = 'MyWaitroseCardReplacement';
export const MY_WAITROSE_CARD_REPLACEMENT_SLUG = 'my-replacement-cards';

export const ONLINE_ORDER_ISSUE_AEM_TYPE = 'onlineOrderIssues';
export const ONLINE_ORDER_ISSUE_NAME = 'OnlineOrderIssue';
export const ONLINE_ORDER_ISSUE_SLUG = 'online-order-issues';

export const BRANCH_FEEDBACK_AEM_TYPE = 'branchFeedback';
export const BRANCH_FEEDBACK_NAME = 'BranchFeedback';
export const BRANCH_FEEDBACK_SLUG = 'branch-feedbacks';

export const PRODUCT_ISSUES_AEM_TYPE = 'productIssues';
export const PRODUCT_ISSUES_NAME = 'productIssues';
export const PRODUCT_ISSUES_SLUG = 'product-issues';

export const STOCK_QUERIES_AEM_TYPE = 'stockQueries';
export const STOCK_QUERIES_NAME = 'stockQueries';
export const STOCK_QUERIES_SLUG = 'stock-queries';
