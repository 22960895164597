import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadableComponent } from '@loadable/component';
import { loadableDefaultFallback } from 'components/Skeleton/LoadableSkeleton';
import { isUserLoggedIn as isUserLoggedInSelector } from 'redux/modules/sessions/selectors';
import { getIsInitialPageLoaded } from 'redux/modules/page/selectors';

export const withBlockingApis =
  <P extends object>(
    WrappedComponent: React.ComponentType<P> | LoadableComponent<P>,
    blockingApiInitialLoadingCompleteSelectors: ((state: WtrState) => boolean)[], // An array of selector functions which return true or false to flag loading complete status or not respectively
    fallbackOverride?: JSX.Element | null,
  ): React.FC<P> =>
  props => {
    const apisInitialLoadingComplete = blockingApiInitialLoadingCompleteSelectors.map(
      blockingApiInitialLoadingCompleteSelector =>
        useSelector(blockingApiInitialLoadingCompleteSelector),
    );
    const allApisInitialLoadComplete = apisInitialLoadingComplete.every(
      apiInitialLoadingComplete => apiInitialLoadingComplete,
    );
    const isUserLoggedIn = useSelector(isUserLoggedInSelector);
    const isInitialPageLoaded = useSelector(getIsInitialPageLoaded);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    useEffect(() => {
      // Avoid waiting for load of blocking APIs to start loading static assets of loadable component
      if ('preload' in WrappedComponent) {
        WrappedComponent.preload();
      }
    }, []);

    if (
      !initialLoadComplete &&
      (!isUserLoggedIn || allApisInitialLoadComplete || isInitialPageLoaded)
    ) {
      setInitialLoadComplete(true);
    }

    if (!initialLoadComplete) {
      // At time of writing, it is not possible to re-use the same loadable fallback override if specified
      // for a component without duplication.
      return fallbackOverride !== undefined ? fallbackOverride : loadableDefaultFallback;
    }

    return <WrappedComponent {...props} />;
  };
