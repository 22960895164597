import root from 'window-or-global';
import isError from 'lodash/isError';
import isString from 'lodash/isString';
import { getFeatureFlags } from 'utils/feature-flags';
import { consoleError } from 'utils/logging';
import { sentry } from 'utils/sentry-wrapper';

const mapSentryContextToElasticContext = ({ extra, tags, ...rest }) => ({
  ...rest,
  ...tags,
  ...extra,
});

export const log = (
  messageOrException,
  { context = {}, extra = {}, logger = 'generic', section = 'general', tags = {} } = {},
) => {
  const { apmRumErrorLogging } = getFeatureFlags();

  if (isString(messageOrException)) {
    const additionalContext = {
      ...context,
      extra,
      tags: {
        logger,
        section,
        ...tags,
      },
    };

    sentry?.captureMessage(messageOrException, additionalContext);

    if (apmRumErrorLogging) {
      try {
        throw new Error(messageOrException);
      } catch (error) {
        const errorWithContext = Object.assign(
          error,
          mapSentryContextToElasticContext(additionalContext),
        );

        root?.apm?.captureError(errorWithContext);
      }
    }
  } else if (isError(messageOrException) && messageOrException.status !== 409) {
    const additionalContext = {
      ...context,
      extra,
      tags: {
        correlationId:
          messageOrException?.header?.['wtr-correlation-id'] ??
          messageOrException?.header?.['x-amzn-requestid'],
        errorCode: messageOrException?.response?.body?.code,
        errorStatus: messageOrException?.status,
        logger,
        section,
        ...tags,
      },
    };

    sentry?.captureException(messageOrException, additionalContext);

    if (apmRumErrorLogging) {
      const errorWithContext = Object.assign(
        messageOrException,
        mapSentryContextToElasticContext(additionalContext),
      );

      root?.apm?.captureError(errorWithContext);
    }
  }

  consoleError(messageOrException);
};
