import { hasBookedSlot, isBookedSlotACollectionSlot } from 'redux/modules/customer-slot/selectors';
import { getDeliveryUrl } from 'redux/modules/slot-booking/selectors/get-delivery-url';
import { rootPath } from 'utils/root-path';

import urls from 'constants/urls';

export const getSlotTypeConflictUrl = state => {
  if (hasBookedSlot(state)) {
    return isBookedSlotACollectionSlot(state)
      ? rootPath('/bookslot/collection/groceriesandentertaining')
      : getDeliveryUrl(state);
  }
  return urls.serviceSelection;
};
