import { MyWaitrose } from 'constants/my-waitrose';
import { ApiError } from 'constants/errors';

import { deepFreeze } from 'utils/deepFreeze';

export interface MyWaitroseState extends MyWaitrose {
  request: {
    isLoading: boolean;
    error: ApiError | null;
  };
  leftMyWaitrose: boolean;
}

export const initialState: Readonly<MyWaitroseState> = deepFreeze({
  cardNumber: null,
  cardStatus: null,
  modified: null,
  points: null,
  request: {
    isLoading: false,
    error: null,
  },
  leftMyWaitrose: false,
});
