import { createSelector } from 'reselect';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

export const getOrder = (state: WtrState) => getStateAtNamespaceKey(state, 'order');

export const getOrderData = createSelector(getOrder, order => order?.order);

export const getCustomerOrderId = createSelector(getOrder, order => order?.customerOrderId);

export const getOrderIsWaiting = createSelector(getOrder, order => order?.waiting ?? false);

export const getOrderIsLoaded = createSelector(getOrder, order => order?.loaded ?? false);
