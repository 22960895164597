import createReducer from 'redux/createReducer';
import acknowledgeServerErrors from 'redux/modules/order/reducer/acknowledge-server-errors';
import addGiftCardSuccess from 'redux/modules/order/reducer/add-gift-card-success';
import addGiftVoucherFailure from 'redux/modules/order/reducer/add-gift-voucher-failure';
import addGiftVoucherSuccess from 'redux/modules/order/reducer/add-gift-voucher-success';
import addPartnerDiscountFailure from 'redux/modules/order/reducer/add-partner-discount-failure';
import addPartnerDiscountRequest from 'redux/modules/order/reducer/add-partner-discount-request';
import addPartnerDiscountSuccess from 'redux/modules/order/reducer/add-partner-discount-success';
import addPromoCodeFailure from 'redux/modules/order/reducer/add-promo-code-failure';
import addPromoCodeRequest from 'redux/modules/order/reducer/add-promo-code-request';
import addPromoCodeSuccess from 'redux/modules/order/reducer/add-promo-code-success';
import cancelRemoveGiftCard from 'redux/modules/order/reducer/cancel-remove-gift-card';
import cancelRemoveGiftVoucher from 'redux/modules/order/reducer/cancel-remove-gift-voucher';
import clearPromocodeError from 'redux/modules/order/reducer/clear-promo-code-error';
import getOrInitialiseOrderFailure from 'redux/modules/order/reducer/get-or-initialise-order-failure';
import getOrInitialiseOrderRequest from 'redux/modules/order/reducer/get-or-initialise-order-request';
import getOrInitialiseOrderSuccess from 'redux/modules/order/reducer/get-or-initialise-order-success';
import getOrderSlotTypeSuccess from 'redux/modules/order/reducer/get-order-slot-type-success';
import getOrderSlotTypeFailure from 'redux/modules/order/reducer/get-order-slot-type-failure';
import removeGiftCardSuccess from 'redux/modules/order/reducer/remove-gift-card-success';
import removeGiftVoucherFailure from 'redux/modules/order/reducer/remove-gift-voucher-failure';
import removeGiftVoucherSuccess from 'redux/modules/order/reducer/remove-gift-voucher-success';
import removePartnerDiscountFailure from 'redux/modules/order/reducer/remove-partner-discount-failure';
import removePartnerDiscountRequest from 'redux/modules/order/reducer/remove-partner-discount-request';
import removePartnerDiscountSuccess from 'redux/modules/order/reducer/remove-partner-discount-success';
import removePromoCodeFailure from 'redux/modules/order/reducer/remove-promo-code-failure';
import removePromoCodeRequest from 'redux/modules/order/reducer/remove-promo-code-request';
import removePromoCodeSuccess from 'redux/modules/order/reducer/remove-promo-code-success';
import setGiftCardToRemove from 'redux/modules/order/reducer/set-gift-card-to-remove';
import setGiftCardsRequest from 'redux/modules/order/reducer/set-gift-cards-request';
import setGiftVoucherToRemove from 'redux/modules/order/reducer/set-gift-voucher-to-remove';
import setGiftVouchersRequest from 'redux/modules/order/reducer/set-gift-vouchers-request';
import initialState from 'redux/modules/order/state/initial-state';
import {
  GET_ORDER_FAILED,
  GET_ORDER_LOADED,
  GET_ORDER_LOADING,
  GET_ORDER_SLOT_TYPE_LOADED,
  GET_ORDER_SLOT_TYPE_FAILED,
} from 'redux/modules/orders/actions/types';
import {
  ACKNOWLEDGE_SERVER_ERRORS,
  ADD_GIFT_CARD,
  ADD_GIFT_VOUCHER,
  ADD_PARTNER_DISCOUNT,
  ADD_PROMO_CODE,
  CANCEL_REMOVE_GIFT_CARD,
  CANCEL_REMOVE_GIFT_VOUCHER,
  CLEAR_PROMO_CODE_ERROR,
  GET_ORDER,
  INITIALISE_ORDER,
  REMOVE_GIFT_CARD,
  REMOVE_GIFT_VOUCHER,
  REMOVE_PARTNER_DISCOUNT,
  REMOVE_PROMO_CODE,
  SET_GIFT_CARD_TO_REMOVE,
  SET_GIFT_VOUCHER_TO_REMOVE,
} from '../actions/types';

const actionTypeReducerMap = new Map([
  [ACKNOWLEDGE_SERVER_ERRORS, acknowledgeServerErrors],
  [ADD_GIFT_CARD.request, setGiftCardsRequest],
  [ADD_GIFT_CARD.success, addGiftCardSuccess],
  [ADD_GIFT_VOUCHER.failure, addGiftVoucherFailure],
  [ADD_GIFT_VOUCHER.request, setGiftVouchersRequest],
  [ADD_GIFT_VOUCHER.success, addGiftVoucherSuccess],
  [ADD_PARTNER_DISCOUNT.failure, addPartnerDiscountFailure],
  [ADD_PARTNER_DISCOUNT.request, addPartnerDiscountRequest],
  [ADD_PARTNER_DISCOUNT.success, addPartnerDiscountSuccess],
  [ADD_PROMO_CODE.failure, addPromoCodeFailure],
  [ADD_PROMO_CODE.request, addPromoCodeRequest],
  [ADD_PROMO_CODE.success, addPromoCodeSuccess],
  [CLEAR_PROMO_CODE_ERROR, clearPromocodeError],
  [CANCEL_REMOVE_GIFT_CARD, cancelRemoveGiftCard],
  [CANCEL_REMOVE_GIFT_VOUCHER, cancelRemoveGiftVoucher],
  [GET_ORDER.success, getOrInitialiseOrderSuccess],
  [GET_ORDER.failure, getOrInitialiseOrderFailure],
  [GET_ORDER.request, getOrInitialiseOrderRequest],
  [GET_ORDER_FAILED, getOrInitialiseOrderFailure],
  [GET_ORDER_LOADING, getOrInitialiseOrderRequest],
  [GET_ORDER_LOADED, getOrInitialiseOrderSuccess],
  [GET_ORDER_SLOT_TYPE_LOADED, getOrderSlotTypeSuccess],
  [GET_ORDER_SLOT_TYPE_FAILED, getOrderSlotTypeFailure],
  [INITIALISE_ORDER.failure, getOrInitialiseOrderFailure],
  [INITIALISE_ORDER.request, getOrInitialiseOrderRequest],
  [INITIALISE_ORDER.success, getOrInitialiseOrderSuccess],
  [REMOVE_GIFT_CARD.request, setGiftCardsRequest],
  [REMOVE_GIFT_CARD.success, removeGiftCardSuccess],
  [REMOVE_GIFT_VOUCHER.failure, removeGiftVoucherFailure],
  [REMOVE_GIFT_VOUCHER.request, setGiftVouchersRequest],
  [REMOVE_GIFT_VOUCHER.success, removeGiftVoucherSuccess],
  [REMOVE_PARTNER_DISCOUNT.request, removePartnerDiscountRequest],
  [REMOVE_PARTNER_DISCOUNT.failure, removePartnerDiscountFailure],
  [REMOVE_PARTNER_DISCOUNT.success, removePartnerDiscountSuccess],
  [REMOVE_PROMO_CODE.failure, removePromoCodeFailure],
  [REMOVE_PROMO_CODE.request, removePromoCodeRequest],
  [REMOVE_PROMO_CODE.success, removePromoCodeSuccess],
  [SET_GIFT_CARD_TO_REMOVE, setGiftCardToRemove],
  [SET_GIFT_VOUCHER_TO_REMOVE, setGiftVoucherToRemove],
]);

export default createReducer(initialState, actionTypeReducerMap);
