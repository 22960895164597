import queryString from 'query-string';

import { FORMAT, dayjs, isValid } from 'utils/date';
import history from 'utils/history';

export const getFromDateFromUrl = () => {
  const { fromDate } = queryString.parse(history.getLocation().search);
  const isValidFromDate = isValid(dayjs(fromDate, FORMAT.ISO8601_DATE, true));
  return isValidFromDate ? fromDate : undefined;
};

export const fromDateUrlParam = () => {
  const urlFromDate = getFromDateFromUrl();
  if (urlFromDate) {
    return `?fromDate=${urlFromDate}`;
  }
  return '';
};

export const getStartDateFromUrl = () => {
  const { startDate } = queryString.parse(history.getLocation().search);
  const isValidStartDate = isValid(dayjs(startDate, FORMAT.ISO8601_DATE, true));
  return isValidStartDate ? startDate : undefined;
};
