import { HIDE_MYWAITROSE_APPLE_WALLET_ERROR } from 'redux/modules/apple-wallet/actions/types';

const hideMyWaitroseAppleWalletError = state => ({
  ...state,
  appleWalletApiError: false,
});

export default map => {
  map.set(HIDE_MYWAITROSE_APPLE_WALLET_ERROR, hideMyWaitroseAppleWalletError);
  return map;
};
