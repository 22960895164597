import React from 'react';
import PropTypes from 'prop-types';

import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import HeaderLogo from 'components/HeaderLogo';
import { LoginLink } from 'components/AuthenticationAction/LoginLink';
import { isEmbeddedInWebView } from 'utils/webview';

import styles from './SiteHeaderMinimal.scss';

const SiteHeaderMinimal = ({ showMinimalHeaderSignIn, staticRender }) => {
  const noLogoLink = isEmbeddedInWebView();

  return (
    <header>
      <HeaderLogo noLink={noLogoLink} minimal staticRender={staticRender} />
      {showMinimalHeaderSignIn && !staticRender && (
        <LoginLink className={styles.loginLink}>
          <User className={styles.loginLinkIcon} />
          Sign In
        </LoginLink>
      )}
    </header>
  );
};

SiteHeaderMinimal.propTypes = {
  showMinimalHeaderSignIn: PropTypes.bool,
  staticRender: PropTypes.bool,
};

SiteHeaderMinimal.defaultProps = {
  showMinimalHeaderSignIn: false,
  staticRender: false,
};

export default SiteHeaderMinimal;
