import {
  BOOK_SLOT,
  CHANGE_QUANTITY,
  CHANGE_SLOT_DATE,
  HARD_TYPE,
  REMOVE_ITEM,
  SOFT_TYPE,
} from 'constants/restrictions';

export const hasSoftConflict = (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (conflict && conflict.type === SOFT_TYPE) return true;

  return false;
};

export const hasHardConflict = (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (conflict && conflict.type === HARD_TYPE) return true;

  return false;
};

export const isItemEditable = (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (!conflict) return true;

  const { prohibitedActions } = conflict;

  if (prohibitedActions && prohibitedActions.includes(CHANGE_QUANTITY)) return false;

  return true;
};

export const isItemRemovable = (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (!conflict) return true;

  const { prohibitedActions } = conflict;

  if (prohibitedActions && prohibitedActions.includes(REMOVE_ITEM)) return false;

  return true;
};

export const isItemSwappable = (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (!conflict) return true;

  const { resolutionActions } = conflict;

  if (!resolutionActions) return true;

  return !resolutionActions.some(
    ({ id: resolutionId }) =>
      resolutionId === CHANGE_SLOT_DATE ||
      resolutionId === BOOK_SLOT ||
      resolutionId === CHANGE_QUANTITY,
  );
};
