import { useDispatch } from 'react-redux';

import { useMakeSelector } from 'hooks/use-make-selector';
import optimisticallyUpdateTrolleyItem from 'redux/modules/trolley/actions/optimistically-update-trolley-item';
import { makeGetOrderLinesByCustomerOrderId } from 'redux/modules/entities/selectors/customer-orders';

import { openModal } from 'redux/modules/common-modal/actions';
import { keepPreviousOrderShopperNotesModal } from 'constants/modals';
import { ModalCloseOptions } from 'constants/modalCloseOptions';

const useOpenPreviousOrderNotesModal = (searchType, orderId, lineNumber) => {
  const dispatch = useDispatch();

  const orderItems = useMakeSelector(makeGetOrderLinesByCustomerOrderId, orderId) ?? [];
  const { noteToShopper } = orderItems.find(item => item.lineNumber === lineNumber) ?? {};

  const openPreviousOrderNotesModal = (
    productPosition,
    clickContext,
    desiredAmount,
    desiredUom,
  ) => {
    dispatch(
      openModal({
        ...keepPreviousOrderShopperNotesModal,
        onCloseCallback: response => {
          if (response === ModalCloseOptions.CONFIRM)
            dispatch(
              optimisticallyUpdateTrolleyItem({
                lineNumber,
                quantity: {
                  amount: desiredAmount,
                  uom: desiredUom,
                },
                noteToShopper,
                productPosition,
                searchType,
                clickContext,
              }),
            );
          if (response === ModalCloseOptions.CLEAR)
            dispatch(
              optimisticallyUpdateTrolleyItem({
                lineNumber,
                quantity: {
                  amount: desiredAmount,
                  uom: desiredUom,
                },
                productPosition,
                searchType,
                clickContext,
              }),
            );
        },
      }),
    );
  };

  return {
    openPreviousOrderNotesModal,
    shouldOpenModal: searchType === 'Shop From Previous' && !!noteToShopper,
  };
};

export default useOpenPreviousOrderNotesModal;
