import superagent from 'superagent';

import stats from 'api/stats';
import { REQUEST_TIMEOUT_DEADLINE, REQUEST_TIMEOUT_RESPONSE } from 'constants/settings';
import { setupStubs } from 'stubs';
import { appendQueryString } from 'utils/append-query-string';
import asyncCache from 'utils/async-cache';
import { getHTTPOptions, isHTTPOption } from 'utils/http-method-options';
import { isValidJSON } from 'utils/is-JSON';
import {
  sanitiseUrl,
  ssrCacheHit,
  ssrCacheSet,
  ssrPendingCacheHit,
  ssrStaleCacheHit,
} from 'utils/logging';
import { httpAgent, httpsAgent } from 'utils/request';

const client = ({
  cookies,
  cookieHandler,
  data,
  debug,
  fileDownload,
  headers,
  jwt,
  method,
  params,
  timeout = {
    response: REQUEST_TIMEOUT_RESPONSE, // Wait x milliseconds for the server to send any response,
    deadline: REQUEST_TIMEOUT_DEADLINE, // but only allow y milliseconds for the response to finish downloading
  },
  url,
  successCb,
  retry,
  requestId,
  getErrorFromResponse = () => null,
  withCredentials = true,
  withRequestDataLogging = false,
  withResponseBodyLogging = false,
}) => {
  return new Promise((resolve, reject) => {
    const apiTime = stats.restTime();
    const teardownStubs = setupStubs();

    try {
      const formattedUrl = params ? appendQueryString(url, params) : url;
      const request = superagent[method === 'delete' ? 'del' : method](formattedUrl);

      if (__SERVER__) {
        request.agent(formattedUrl.startsWith('https:') ? httpsAgent : httpAgent);
      }

      if (withCredentials) {
        request.withCredentials();
      }

      request.timeout({
        response: timeout.response,
        deadline: timeout.deadline,
      });

      if (jwt) request.set('authorization', jwt);
      const { Cookie, ...otherHeaders } = headers || {};
      if (Object.keys(otherHeaders).length !== 0) request.set(otherHeaders);
      if (cookies || Cookie) request.set('Cookie', [cookies, Cookie].filter(Boolean).join(';'));
      if (retry) request.retry(retry.numberOfRetries || 1, retry.retryHandler);

      stats.restEntryMessage(
        request.url,
        method,
        requestId,
        withRequestDataLogging ? data : undefined,
      );

      stats.increaseRequestCounter();

      if (fileDownload) {
        request.responseType('blob');
      }

      if (data) request.send(data);

      /* eslint-disable no-param-reassign */
      request.end((err, response = {}) => {
        const { header, status, text } = response;

        apiTime(request.url, status);
        stats.decreaseRequestCounter();

        let { body } = response;
        const correlationId =
          header && (header['wtr-correlation-id'] || header['x-amzn-requestid']);

        err = err ?? getErrorFromResponse(body);

        if (err) {
          stats.restExitErrorMessage(
            request.url,
            method,
            err.status,
            text,
            correlationId,
            requestId,
            withResponseBodyLogging ? body : undefined,
          );

          Object.assign(err, sanitiseUrl(request.url));
          err.header ??= header;
          err.method ??= method;

          if (typeof status === 'number' && !Number.isNaN(status)) {
            err.status ??= status;
            err.errorStatus ??= status;
          }

          setTimeout(() => reject(err), err.stubsDelay || 0);

          return;
        }

        if (successCb) {
          successCb(response);
        }

        if (__SERVER__) {
          stats.restExitSuccessMessage(
            request.url,
            method,
            status,
            correlationId,
            requestId,
            withResponseBodyLogging ? body : undefined,
          );
          const setCookies = (header || {})['set-cookie'];

          if (setCookies) {
            cookieHandler(setCookies);
          }
        }

        if (fileDownload) {
          resolve(body);
          return;
        }

        body = isValidJSON(body) ? body : {};
        body.status = body.status || status;
        body.header = header;

        if (debug) {
          body.debug = { ...response };
        }

        if (isHTTPOption(method)) {
          body = {
            ...body,
            options: getHTTPOptions(header),
          };
        }

        resolve(body);
      });
    } finally {
      // Must _always_ remove stubs immediately after each request, otherwise
      // all user's requests to SSR server will receive stub data...
      if (teardownStubs) {
        teardownStubs();
      }
    }
  });
};

const defaultMaxAge = 60000;

// This function sets up an async cache per memoryCacheKey, so we can log cacheHits per memoryCacheKey
export default apiCallDescriptor => {
  if (__SERVER__ && apiCallDescriptor.memoryCacheKey) {
    return asyncCache(client, [apiCallDescriptor], {
      dependencies: [apiCallDescriptor.memoryCacheKey],
      maxAge: apiCallDescriptor.memoryCacheMaxAge ?? defaultMaxAge,
      staleWhileRevalidate: true,
      onCacheHit: () => {
        ssrCacheHit(apiCallDescriptor.memoryCacheKey);
      },
      onStaleCacheHit: () => {
        ssrStaleCacheHit(apiCallDescriptor.memoryCacheKey);
      },
      onPendingCallThroughHit: () => {
        ssrPendingCacheHit(apiCallDescriptor.memoryCacheKey);
      },
      onCallThrough: () => {
        ssrCacheSet(apiCallDescriptor.memoryCacheKey);
      },
    });
  }

  return client(apiCallDescriptor);
};
