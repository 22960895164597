import { createSelector } from 'reselect';

export const getTrolleyTotals = state => state?.trolley?.trolleyTotals;

export const getSavingsFromOffersAndIncentives = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.savingsFromOffersAndIncentives?.amount,
);

export const getTotalEstimatedCost = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.totalEstimatedCost?.amount,
);

export const getItemTotalEstimatedCost = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.itemTotalEstimatedCost?.amount,
);

export const getCollectionMinimumOrderValueAmount = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.collectionMinimumOrderValue?.amount,
);

export const getDeliveryMinimumOrderValueAmount = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.deliveryMinimumOrderValue?.amount,
);

export const getMinimumSpendThresholdMet = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.minimumSpendThresholdMet,
);

export const getDeliveryCharge = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.deliveryCharge?.amount,
);
const getTotalDepositCharge = createSelector(
  getTrolleyTotals,
  trolleyTotals => trolleyTotals?.totalDepositCharge?.amount ?? 0,
);

export const getSavingsAndEstimatedTotals = createSelector(
  [
    getTrolleyTotals,
    getTotalEstimatedCost,
    getSavingsFromOffersAndIncentives,
    getDeliveryCharge,
    getTotalDepositCharge,
  ],
  (
    trolleyTotals,
    estimatedTotal,
    savingsFromOffersAndIncentives,
    deliveryCharge,
    totalDepositCharge,
  ) => ({
    deliveryCharge,
    estimatedTotal,
    myWaitroseSavings: trolleyTotals?.savingsFromMyWaitrose?.amount,
    savingsFromOffers: trolleyTotals?.savingsFromOffers?.amount,
    subTotal: estimatedTotal + savingsFromOffersAndIncentives - deliveryCharge - totalDepositCharge,
    totalDepositCharge,
  }),
);
