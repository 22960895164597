import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';

import Account from 'components/HomePage/Header/Account';
import Services from 'components/HomePage/Header/Services';
import HeaderLogo from 'components/HeaderLogo';
import SearchForm from 'components/Search/SearchForm';
import PrinceOfWalesWarrant from 'components/Warrant/PrinceOfWales';
import QueenWarrant from 'components/Warrant/Queen';
import SlotButton from 'components/SiteHeader/SlotButton';

import styles from 'components/HomePage/Header/Header.scss';
import UnsupportedBrowserBanner from 'components/CommonSiteBanner/UnsupportedBrowserBanner';

const HomePageHeader = pure(({ staticRender, isSupportedBrowser }) => (
  <>
    <header className={styles.header} data-testid="homepage-header">
      <section className={styles.topNav}>
        <div className={styles.topLinks}>
          <Services />
        </div>
        {staticRender ? null : (
          <div className={styles.account}>
            <Account />
          </div>
        )}
      </section>
      <section className={styles.branding}>
        <div className={styles.warrants}>
          <QueenWarrant />
          <PrinceOfWalesWarrant />
        </div>
        <div className={styles.logo}>
          <HeaderLogo homepage />
        </div>
      </section>
      {staticRender ? null : (
        <section className={styles.search}>
          <div className={styles.searchWrapper}>
            <SearchForm homepage placeholder="Search groceries..." />
          </div>
          <div className={styles.slotWrapper}>
            <SlotButton testId="desktop" />
          </div>
        </section>
      )}
      <section className={styles.bottomNav}>
        <div className={styles.bottomLinks}>
          <Services />
        </div>
      </section>
    </header>
    {!isSupportedBrowser && UnsupportedBrowserBanner}
  </>
));

HomePageHeader.propTypes = {
  staticRender: PropTypes.bool,
  isSupportedBrowser: PropTypes.bool.isRequired,
};

HomePageHeader.defaultProps = {
  staticRender: false,
};

HomePageHeader.displayName = 'HomePageHeader';

export default HomePageHeader;
