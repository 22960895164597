import { IngredientGroups } from 'redux/modules/recipes/index.d';

export type OrderItem = {
  lineNumber: string;
  ingredientType: string;
  searchType: string;
  quantity: { uom: string; amount: number };
};

export type OrderItems = OrderItem[];

export const createOrderItems = (shoppableProducts: IngredientGroups[]): OrderItems => {
  const result: OrderItems = [];

  shoppableProducts.forEach(({ ingredients }) => {
    const { storeCupboard, nonStoreCupboard } = ingredients;

    if (storeCupboard && storeCupboard?.length > 0) {
      storeCupboard.forEach(({ amountSelected, lineNumber, type, uom }) => {
        if (amountSelected > 0 && lineNumber) {
          result.push({
            lineNumber,
            searchType: 'recipe',
            quantity: {
              uom,
              amount: amountSelected,
            },
            ingredientType: type,
          });
        }
      });
    }

    if (nonStoreCupboard && nonStoreCupboard?.length > 0) {
      nonStoreCupboard.forEach(({ lineNumber, uom, amountSelected, type }) => {
        if (amountSelected > 0 && lineNumber) {
          result.push({
            lineNumber,
            searchType: 'recipe',
            quantity: {
              uom,
              amount: amountSelected,
            },
            ingredientType: type,
          });
        }
      });
    }
  });

  return result.reduce((acc: OrderItems, curr) => {
    const matchingObj = acc.find(
      obj => obj.lineNumber === curr.lineNumber && obj.quantity.uom === curr.quantity.uom,
    );

    if (matchingObj) {
      matchingObj.quantity.amount += curr.quantity.amount;
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
};
