import { PersonalisationState } from '../state/initial-state';

interface SetTrafficSplitVariantPayload {
  experiment: string;
  variant: string | undefined | null;
}

export const setTrafficSplitVariant: WtrReducer<
  PersonalisationState,
  SetTrafficSplitVariantPayload
> = (state, { payload }) => {
  return {
    trafficSplitVariants: {
      ...state.trafficSplitVariants,
      [payload.experiment]: payload.variant,
    },
  };
};
