import {
  CANCEL_SLOT_REQUEST,
  CANCEL_SLOT_REQUEST_FAIL,
  CANCEL_SLOT_REQUEST_SUCCESS,
} from 'redux/modules/customer-slot/actions/types';

const reducer = (state, action = {}) => {
  switch (action.type) {
    case CANCEL_SLOT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_SLOT_REQUEST_FAIL:
    case CANCEL_SLOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
