import { createSelector } from 'reselect';

const getProductGrid = ({ productGrid } = {}) => productGrid;

export const getProductGridItems = createSelector([getProductGrid], ({ items = [] } = {}) => items);

export const getProductGridProducts = createSelector([getProductGridItems], items => [
  ...new Set(items.map(item => item.searchProduct).filter(lineNumber => lineNumber)),
]);

export const getProductGridProductsCount = createSelector(
  [getProductGridProducts],
  products => products.length,
);

export const productGridHasMore = createSelector(
  [getProductGrid],
  ({ hasMore = false } = {}) => hasMore,
);

const productGridLoading = createSelector([getProductGrid], ({ loading = false } = {}) => loading);

export const productGridLoadingMore = createSelector(
  [getProductGrid],
  ({ loadingMore = false } = {}) => loadingMore,
);

export const getProductGridLoading = createSelector(
  [productGridLoading, productGridLoadingMore],
  (loading, loadingMore) => loading || loadingMore,
);
