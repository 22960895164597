import { GET_MYWAITROSE_CARD_BY_ID } from 'redux/modules/mywaitrose/actions/types';
import { MyWaitroseState } from 'redux/modules/mywaitrose/state';

import { MyWaitrose } from 'constants/my-waitrose';
import { ApiError } from 'constants/errors';

const getMyWaitroseRequest = (state: MyWaitroseState) => ({
  ...state,
  cardNumber: null,
  cardStatus: null,
  modified: null,
  points: null,
  request: {
    isLoading: true,
    error: null,
  },
});

const getMyWaitroseSuccess = (state: MyWaitroseState, action: { payload: MyWaitrose }) => ({
  ...state,
  ...action.payload,
  request: {
    isLoading: false,
    error: null,
  },
});

const getMyWaitroseFailure = (state: MyWaitrose, action: { payload: ApiError }) => ({
  ...state,
  request: {
    isLoading: false,
    error: action.payload,
  },
});

export default () =>
  [
    [GET_MYWAITROSE_CARD_BY_ID.request, getMyWaitroseRequest],
    [GET_MYWAITROSE_CARD_BY_ID.success, getMyWaitroseSuccess],
    [GET_MYWAITROSE_CARD_BY_ID.failure, getMyWaitroseFailure],
  ] as const;
