import React, { forwardRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { getArrayFromCSVText } from 'utils/get-array-from-csv-text';

import styles from './with-max-length-list.scss';

const cleanText = textToClean => textToClean.replace(/\n/g, ',');

const getExcessValue = (text, maxLength) => {
  const existingText = cleanText(get(this, 'state.value', ''));
  const newText = cleanText(text);
  const list = getArrayFromCSVText(`${existingText},${newText}`);

  return list.slice(0, maxLength).join('\n');
};

const getStandardValue = (text, remainingItems) => {
  if (remainingItems > 0) return text;

  let trimmedText = text;

  while (trimmedText.charAt(trimmedText.length - 1) === '\n') {
    trimmedText = trimmedText.slice(0, trimmedText.length - 1);
  }

  return trimmedText;
};

const numberOfRemainingListItems = (list, maxItems) => maxItems - (list || []).length;

const buildState = (text, maxLength) => {
  const value = text.replace(/,/g, '\n').replace(/;/g, '\n');
  const remainingListItems = numberOfRemainingListItems(getArrayFromCSVText(text), maxLength);

  return {
    maxLengthExceeded: remainingListItems <= 0,
    value:
      remainingListItems < 0
        ? getExcessValue(text, maxLength)
        : getStandardValue(value, remainingListItems),
  };
};

export default function (WrappedComponent) {
  class WithMaxLengthListWrapper extends Component {
    static propTypes = {
      maxLength: PropTypes.number.isRequired,
      message: PropTypes.string,
      onTextChange: PropTypes.func.isRequired,
      text: PropTypes.string,
    };

    static defaultProps = {
      message: PropTypes.string,
      text: '',
    };

    constructor(props) {
      super(props);

      this.state = buildState(this.props.text, this.props.maxLength);
    }

    componentDidUpdate({ text: prevText }) {
      const { text, maxLength } = this.props;
      if (prevText !== text) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(buildState(text, maxLength));
      }
    }

    onTextChange = event => {
      const { maxLength, onTextChange } = this.props;
      const newState = buildState(event.target.value, maxLength);

      this.setState(newState);

      onTextChange({
        target: {
          value: newState.value,
        },
      });
    };

    render = () => {
      const { forwardedRef, message } = this.props;
      const { maxLengthExceeded } = this.state;

      return (
        <Fragment>
          <WrappedComponent
            ref={forwardedRef}
            {...this.props}
            {...this.state}
            onTextChange={this.onTextChange}
          />
          <span
            aria-live="assertive"
            className={classNames(styles.message, {
              [styles.visible]: maxLengthExceeded,
            })}
            data-testid="max-length-list-message"
          >
            {message}
          </span>
        </Fragment>
      );
    };
  }

  const WithMaxLengthList = forwardRef((props, ref) => (
    <WithMaxLengthListWrapper {...props} forwardedRef={ref} />
  ));

  WithMaxLengthList.displayName = 'WithMaxLengthList';

  return WithMaxLengthList;
}
