import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import {
  formatMeasurement,
  formatQuantity,
  unitIsNoSpace,
  formatMultiplier,
} from 'utils/format-recipe';

import styles from './index.scss';

const Ingredients = ({ ingredientGroups }) => (
  <div data-testid="ingredients" className={styles.container}>
    {ingredientGroups.map(({ title, ingredients }) => (
      <div className={styles.group} key={Object.values(ingredients[0] || []).join()}>
        {title && (
          <Typography element="h3" styleAs="paragraphHeading">
            {title}
          </Typography>
        )}
        <ul>
          {ingredients.map(item => {
            const { name, preparationNotes, quantityMultiplier, quantity, measurement } = item;
            const unit = formatMeasurement(measurement);
            const multiplier =
              quantityMultiplier && quantityMultiplier !== '1'
                ? `${formatMultiplier(quantityMultiplier)} x `
                : '';

            return (
              <li key={uuidv4()} className={unitIsNoSpace(unit) ? styles['no-space'] : ''}>
                <Typography element="span" styleAs="paragraph">
                  <Typography element="span" styleAs="paragraphHeading">
                    {`${multiplier}${formatQuantity(quantity)}`}
                  </Typography>

                  <Typography element="span" styleAs="paragraphHeading">
                    {unit}
                  </Typography>

                  {` ${preparationNotes || name || ''}`}
                </Typography>
              </li>
            );
          })}
        </ul>
      </div>
    ))}
  </div>
);

export default Ingredients;

Ingredients.propTypes = {
  ingredientGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          quantity: PropTypes.number,
          measurement: PropTypes.string,
        }),
      ),
    }),
  ),
};

Ingredients.defaultProps = {
  ingredientGroups: [],
};
