import {
  SET_INCENTIVE_UNLOADING_STATUS,
  UNLOAD_INCENTIVE,
} from 'redux/modules/incentives/actions/types';
import { IncentivesState } from 'redux/modules/incentives/state';

import { ApiError } from 'constants/errors';

const unloadIncentiveRequest = (state: IncentivesState): IncentivesState => ({
  ...state,
  serverError: null,
});

const unloadIncentiveSuccess = (state: IncentivesState): IncentivesState => ({
  ...state,
  serverError: null,
});

const unloadIncentiveFailure = (
  state: IncentivesState,
  action: { payload: ApiError },
): IncentivesState => ({
  ...state,
  serverError: action.payload,
  isProcessingIncentiveDeselection: false,
});

const setIncentiveUnloadingStatus = (
  state: IncentivesState,
  action: { payload: { isProcessingIncentiveDeselection: boolean } },
): IncentivesState => ({
  ...state,
  isProcessingIncentiveDeselection: action.payload.isProcessingIncentiveDeselection,
});

export default () =>
  [
    [UNLOAD_INCENTIVE.request, unloadIncentiveRequest],
    [UNLOAD_INCENTIVE.success, unloadIncentiveSuccess],
    [UNLOAD_INCENTIVE.failure, unloadIncentiveFailure],
    [SET_INCENTIVE_UNLOADING_STATUS, setIncentiveUnloadingStatus],
  ] as const;
