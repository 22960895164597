export const APPLYFILTER = 'waitrose/search/APPLYFILTER';
export const APPLY_CATEGORY_FILTER = 'waitrose/search/APPLY_CATEGORY_FILTER';
export const CLEAR = 'waitrose/search/CLEAR';
export const CLEARFILTERS = 'waitrose/search/CLEARFILTERS';
export const REMOVEFILTER = 'waitrose/search/REMOVEFILTER';
export const SETFILTERGROUP = 'waitrose/search/SETFILTERGROUP';
export const SORTBY = 'waitrose/search/SORTBY';
export const UPDATESEARCH = 'waitrose/search/UPDATESEARCH';
export const UPDATESEARCHTAGS = 'waitrose/search/UPDATESEARCHTAGS';
export const UPDATESEARCHTERM = 'waitrose/search/UPDATESEARCHTERM';
export const REMOVE_SEARCHTERM_ORIGIN = 'waitrose/search/REMOVE_SEARCHTERM_ORIGIN';
