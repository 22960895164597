import isEmpty from 'lodash/isEmpty';
import { flattenAddress } from 'components/Address/util';
import { addressSelector } from './index';

export const foundAddress = state => {
  const address = addressSelector(state);
  if (!isEmpty(address)) {
    const { isContactAddress, line1, line2, line3, region, town, country, postalCode } =
      flattenAddress(address) || {};
    return { isContactAddress, line1, line2, line3, region, town, country, postalCode };
  }
  return {};
};

export const hasFoundAddress = state => {
  const address = addressSelector(state);
  return !isEmpty(address);
};
