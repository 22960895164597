import { isInternal } from 'utils/is-internal';
import queryString from 'query-string';

export const bookSlotQueryString = (editSlotUrl, slotDates) => {
  const queryObj = slotDates.reduce((accu, { date, type }) => {
    const result = { ...accu };
    if (type === 'ENFORCED_EARLIEST') {
      result.fromDate = date;
    }
    if (type === 'SUGGESTED_EARLIEST') {
      result.startDate = date;
    }
    return result;
  }, {});
  const query = `?${queryString.stringify(queryObj)}`;

  return isInternal(editSlotUrl) ? query : '';
};
