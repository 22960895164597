import { createSelector } from 'reselect';
import root from 'window-or-global';

import { getProducts } from 'redux/modules/entities/selectors/products';
import { LISTS_PER_LIST_FETCH_SIZE, SHOPPING_LIST_VIEW_SIZE } from 'constants/shopping-list';

export const getShoppingLists = ({ entities: { shoppingLists } = {} } = {}) => shoppingLists;

export const getShoppingListById = createSelector(
  [getShoppingLists, (state, listId) => listId],
  (shoppingLists = {}, listId) => shoppingLists[listId],
);

/**
 * Prepares the data for the Shopping List card in the list of shopping lists.
 */
export const getShoppingListCardById = createSelector(
  [getShoppingListById, getProducts],
  ({ id, items, name, numItems } = { items: [] }, products = {}) => ({
    name,
    id,
    url: `/ecom/shopping-lists/${id}`,
    thumbnails: items.slice(0, 4).map(({ lineNumber } = {}) => ({
      productId: lineNumber,
      thumbnail: (products[lineNumber] || {}).thumbnail,
    })),
    numItems,
  }),
);

export const getShoppingListForUpdateById = createSelector(
  [getShoppingListById],
  ({ items = [], name } = {}) => ({
    name,
    items: items.map(({ lineNumber, quantity }) => ({
      lineNumber,
      quantity,
    })),
  }),
);

export const getShoppingListsCardItems = createSelector(getShoppingLists, (lists = {}) =>
  Array.from(
    new Set(
      Object.values(lists)
        .flatMap(({ items = [] } = {}) =>
          items.slice(0, LISTS_PER_LIST_FETCH_SIZE).map(({ lineNumber } = {}) => lineNumber),
        )
        .filter(item => item)
        .slice(0, SHOPPING_LIST_VIEW_SIZE),
    ),
  ),
);

export const getShoppingListCount = createSelector(
  getShoppingListById,
  ({ numItems } = {}) => numItems,
);

export const getShoppingListItems = createSelector(
  getShoppingListById,
  ({ items = [] } = {}) => items,
);

export const getShoppingListItemsLineNumbers = createSelector(getShoppingListItems, (items = []) =>
  items.map(({ lineNumber } = {}) => lineNumber).filter(lineNumber => lineNumber),
);

export const getShoppingListName = createSelector(getShoppingListById, ({ name } = {}) => name);

export const getSortedShoppingListIds = createSelector(getShoppingLists, (shoppingLists = {}) =>
  Object.values(shoppingLists)
    .sort(
      ({ created: createdA } = {}, { created: createdB } = {}) =>
        new Date(createdB) - new Date(createdA),
    )
    .map(({ id } = {}) => id),
);

export const shoppingListHasItems = createSelector(
  getShoppingListItems,
  (items = []) => items.length > 0,
);

export const getShoppingListHasLineNumber = createSelector(
  [getShoppingListItems, (state, listId, lineNumber) => lineNumber],
  (items = [], lineNumber) => items.some(item => item.lineNumber === lineNumber),
);
