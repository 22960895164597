import env from 'env/env';
import { weightOptions } from 'constants/weightOptions';

import { pluralize, removeDiacriticsFromLetters } from 'utils/format';

const roundQuantity = quantity => +`${Math.round(`${parseFloat(quantity)}e+2`)}e-2`;

export const cookingDefinitionType = type => {
  switch (type) {
    case 'GENERAL':
      return 'General';
    case 'FROMAMBIENTCHILLED':
      return 'From Chilled/Ambient';
    case 'FROMFROZEN':
      return 'From Frozen';
    default:
      return '';
  }
};

export const formatProductSize = ({ currentUnit, defaultUnit, quantity, size, typicalUnit }) => {
  if ((currentUnit !== 'C62' && defaultUnit === 'C62') || currentUnit === 'C62') {
    if (typicalUnit && quantity) {
      const { [typicalUnit]: { label = '' } = {} } = weightOptions;

      return `Typical weight ${roundQuantity(quantity)}${label}`;
    }

    if (size) return size;
  }

  return '';
};

export const formatServings = ({ max = 1, min = 1 } = {}) =>
  min !== max && max > min && min !== 1 ? `Serves ${min} - ${max}` : `Serves ${Math.max(min, max)}`;

export const getEntertainingAttributes = (leadTime, servings) =>
  leadTime || servings
    ? {
        servings: servings ? formatServings(servings) : null,
        leadTime: leadTime && leadTime > 0 ? pluralize(leadTime, 'day') : null,
      }
    : null;

export const generatePDPSlug = (name = '') =>
  removeDiacriticsFromLetters(name)
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');

export const getPDPUrl = (id, productName) =>
  id ? `${env.root}/products/${generatePDPSlug(productName)}/${id}` : null;

export const isPersonalisableProduct = ({ maxPersonalisedMessageLength } = {}) =>
  typeof maxPersonalisedMessageLength === 'number' && maxPersonalisedMessageLength > 0;
