import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { conflictType } from 'components/Conflict/propTypes';
import ConflictMessage from 'components/Conflict/ConflictMessage';
import OutOfStock from 'components/Conflict/OutOfStock';
import Resolution from 'components/Conflict/Resolution';
import { TEMPORARILY_UNAVAILABLE } from 'constants/conflicts';
import ViewAlternativesOrRemove from './ViewAlternativesOrRemove';

import styles from './Conflict.scss';

export const MESSAGE_TYPE = {
  short: 'shortItem',
  long: 'longItem',
};

const isTemporarilyUnavailable = message => message === TEMPORARILY_UNAVAILABLE;

const Conflict = ({
  className,
  conflict,
  disableResolution,
  messageType,
  onPDP,
  textOnly,
  isInTrolley,
  removeFromTrolley,
  position,
}) => {
  if (!conflict) return null;

  const { messages = {}, resolutionActions = [], outOfStock = false, productId } = conflict;
  const message = messages[messageType];

  if (outOfStock) {
    return (
      <OutOfStock message="Sold out online" onPDP={onPDP} className={className}>
        {!onPDP && (
          <ViewAlternativesOrRemove
            {...{
              isInTrolley,
              removeFromTrolley,
              productId,
              conflictMessage: messages.shortItem,
              position,
            }}
          />
        )}
      </OutOfStock>
    );
  }
  if (!message) return null;

  if (!disableResolution && resolutionActions.length > 0) {
    const resolutionProps = {
      className,
      conflict,
      conflictMessage: message,
      onPDP,
      textOnly,
    };

    return <Resolution {...resolutionProps} />;
  }

  if (isTemporarilyUnavailable(message)) {
    return (
      <OutOfStock message={message} onPDP={onPDP} className={className}>
        {!onPDP && (
          <ViewAlternativesOrRemove
            isInTrolley={isInTrolley}
            productId={productId}
            removeFromTrolley={removeFromTrolley}
            position={position}
            conflictMessage={messages.shortItem}
          />
        )}
      </OutOfStock>
    );
  }

  return (
    <ConflictMessage
      className={classNames(className, { [styles.onPDP]: onPDP })}
      text={message}
      onPDP={onPDP}
      textOnly={textOnly}
    />
  );
};

Conflict.propTypes = {
  className: PropTypes.string,
  conflict: conflictType,
  disableResolution: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  entityType: PropTypes.oneOf(['products', 'trolley']).isRequired,
  messageType: PropTypes.oneOf(Object.values(MESSAGE_TYPE)).isRequired,
  onPDP: PropTypes.bool,
  textOnly: PropTypes.bool,
  isInTrolley: PropTypes.bool,
  removeFromTrolley: PropTypes.func,
  position: PropTypes.number,
};

Conflict.defaultProps = {
  className: '',
  conflict: undefined,
  disableResolution: false,
  onPDP: false,
  textOnly: false,
  isInTrolley: undefined,
  removeFromTrolley: undefined,
  position: undefined,
};

export default Conflict;
