import { POST } from 'redux/modules/address/actions/types';
import { sortAddresses } from 'redux/modules/address/util';

const addressRequest = state => ({
  ...state,
  addAddressLoading: true,
  editAddressLoading: true,
  editedFields: [],
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const addressSuccess = (state, action) => {
  const address = action.result;
  const { addresses = [] } = state;

  const sortedAddresses = sortAddresses(
    [...addresses, { ...address, selected: true }],
    action.result.id,
  );

  return {
    ...state,
    addressInError: null,
    addresses: sortedAddresses,
    addAddressLoading: false,
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const addressFailure = (state, action) => ({
  ...state,
  addAddressLoading: false,
  editAddressLoading: false,
  request: {
    loading: false,
    loaded: false,
    error: action.error,
  },
});

export default map => {
  map.set(POST.request, addressRequest);
  map.set(POST.success, addressSuccess);
  map.set(POST.failure, addressFailure);
  return map;
};
