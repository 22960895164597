import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getShoppingLists } from 'redux/modules/entities/selectors/shopping-lists';

const getShoppingListItems = state => state.shoppingList.items;
export const getActiveShoppingListId = state => state.shoppingList.id;
export const activeShoppingListProductsAreLoading = state => state.shoppingList.productsLoading;
export const getShoppingListLoading = state => state.shoppingList.loading;
export const shoppingListNameIsBeingEdited = state => state.shoppingList.editingName;

export const getActiveShoppingListItems = createSelector([getShoppingListItems], items =>
  items.filter(item => item),
);

export const getActiveShoppingList = createSelector(
  [getShoppingLists, getActiveShoppingListId],
  (shoppingLists, activeListId) => get(shoppingLists, `${activeListId}`),
);

export const getActiveShoppingListName = createSelector([getActiveShoppingList], activeList =>
  get(activeList, 'name', null),
);

export const getActiveShoppingListItemCount = createSelector([getActiveShoppingList], activeList =>
  get(activeList, 'numItems', 0),
);

export const activeShoppingListHasMoreResults = createSelector(
  [getActiveShoppingListItemCount, getShoppingListItems],
  (listTotal, fetchedItems) => listTotal - fetchedItems.length > 0,
);
