import React from 'react';
import { getFeatureFlags } from 'utils/feature-flags';
import classNames from 'classnames';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from './index.scss';

const Disclaimer = () =>
  getFeatureFlags().shoppableRecipesMVP ? (
    <Typography
      data-testid="disclaimer"
      noMargins
      styleAs="paragraphSmallLight"
      className={classNames(styles.disclaimer, 'no-print')}
    >
      Please note, we take every care to ensure the product, allergen and recipe information
      displayed is correct. However, should a product be unavailable, alternatives may be displayed
      and/or a substitution provided. If you have an allergy or intolerance, please always check the
      product label before use.
    </Typography>
  ) : null;

export default Disclaimer;
