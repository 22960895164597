import { CheckoutState } from '../state/initial-state';

interface Payload {
  placeOrderOriginMobileAvailable: CheckoutState['placeOrderOriginMobileAvailable'];
}

export const placeOrderOriginMobileAvailable: WtrReducer<CheckoutState, Payload> = (
  state,
  { payload },
) => ({
  ...state,
  placeOrderOriginMobileAvailable: payload.placeOrderOriginMobileAvailable,
});
