export const pending = state => {
  return {
    ...state,
    loading: true,
  };
};

export const fulfilled = (state, action) => {
  return {
    ...state,
    loading: false,
    schemes: {
      ...state.schemes,
      [action.marketingScheme]: action.result,
    },
  };
};

export const rejected = (state, action) => {
  return {
    ...state,
    loading: false,
    schemes: {
      ...state.schemes,
      [action.marketingScheme]: { error: action.error },
    },
  };
};
