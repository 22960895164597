import update from 'immutability-helper';

const buildViewAllMenuLink = ({ id, name }, url) => ({
  bold: true,
  generated: true,
  id: `${id}_ALL`,
  name: `View all ${name}`,
  url,
});

const buildOffersMenuLink = ({ id, name }, url) => ({
  generated: true,
  id: `${id}_OFFERS`,
  offer: true,
  name: `${name} Offers`,
  url,
});

export default (subcategory, path, offersPath) => {
  const { categoryIds } = subcategory;
  const cat = {
    ...subcategory,
    categoryIds: update(categoryIds, {
      $unshift: [buildViewAllMenuLink(subcategory, path)],
    }),
  };
  if (offersPath) cat.categoryIds?.splice(1, 0, buildOffersMenuLink(subcategory, offersPath));
  return cat;
};
