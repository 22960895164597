import { retry } from 'utils/retry';
import type {
  Fetcher,
  FetcherArgs,
  PreloadOnlyFetcher,
  PreloadOnlyFetcherArgs,
} from './fetcher-types';

/**
 * Makes a fetcher that lazily imports the module with a `default` export
 * @param importFn The function to dynamically import the fetcher
 *
 * @example
 * const cmsPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-page'));
 */
export function lazyFetcher<
  PathParamKey extends string,
  TImportFn extends { default: Fetcher<PathParamKey> },
>(importFn: () => Promise<TImportFn>): Fetcher<PathParamKey> | PreloadOnlyFetcher;

/**
 * Makes a fetcher that lazily imports the module with a named export of the fetcher
 * @param importFn The function to dynamically import the fetcher
 * @param exportName The name of the exported fetcher
 *
 * @example
 * const favouritesFetcher = lazyFetcher(
 *   () => import('redux/fetchers/favourites'),
 *   'favouritesFetcher',
 * );
 */
export function lazyFetcher<
  PathParamKey extends string,
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
>(
  importFn: () => Promise<TImportFn>,
  exportName: TExportName,
): Fetcher<PathParamKey> | PreloadOnlyFetcher;

/**
 * Makes a fetcher that lazily imports the module with a named export of a function that creates a fetcher and the function to actually make the fetcher
 * @param importFn The function to dynamically import the fetcher
 * @param exportName The name of the exported fetcher
 * @param fetcherFn The function to construct the fetcher
 *
 * @example
 * const cmsExperienceFragment = (...args) =>
 *   lazyFetcher(
 *     () => import('redux/fetchers/experience-fragment'),
 *     'default',
 *     fetcher => fetcher(...args),
 *   );
 */
export function lazyFetcher<
  PathParamKey extends string,
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
>(
  importFn: () => Promise<TImportFn>,
  exportName: TExportName,
  fetcherFn: (fetcher: TImportFn[TExportName]) => Fetcher<PathParamKey>,
): Fetcher<PathParamKey> | PreloadOnlyFetcher;

// Implementation
export function lazyFetcher<
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
  PathParamKey extends string = string,
>(
  importFn: () => Promise<TImportFn>,
  exportName: TExportName | 'default' = 'default',
  fetcherFn?: (fetcher: TImportFn[TExportName]) => Fetcher<PathParamKey>,
) {
  return (args: FetcherArgs<PathParamKey> | PreloadOnlyFetcherArgs) => {
    // This double layered if statement is necessary to narrow the type of the args function parameter
    if ('preloadOnly' in args) {
      if (args.preloadOnly) {
        importFn();
      }
      // This function shouldn't ever be executed but it makes the TypeScript types simpler by returning it as there is commonality in return types in this function
      return async () => {};
    }
    return async (dispatch: WtrDispatch) => {
      // try 3 times, original and two more, with no delay time as the CDN should not have same behaviour as an API
      const module = await retry(importFn, [0, 0]);
      if (module) {
        const fetcher = module[exportName];
        if (fetcherFn) {
          return dispatch(fetcherFn(fetcher as never)(args));
        }
        return dispatch((fetcher as Fetcher<PathParamKey>)(args));
      }

      return Promise.reject(new Error('Module could not be loaded'));
    };
  };
}
