import setGiftVouchersSuccess from 'redux/modules/order/reducer/set-gift-vouchers-success';

const reducer = (state, action) => {
  const order = action?.result?.data?.setGiftVouchers?.order;

  if (!order) {
    return { ...state, giftVoucherToRemove: null, waiting: false };
  }

  const giftVouchersSuccess = setGiftVouchersSuccess(state, order);

  return {
    ...state,
    ...giftVouchersSuccess,
    waiting: false,
  };
};

export default reducer;
