import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { Label } from 'components/Forms/utilities/Label';
import SubText from 'components/Forms/utilities/SubText';

import styles from 'components/Forms/utilities/Selection/Selection.scss';

export default class Selection extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleChange = e => {
    const { type, onChange } = this.props;
    const val = type === 'radio' ? e.target.value : e.target.checked;

    if (onChange) {
      onChange(e, val);
    }
  };

  render() {
    const {
      children,
      type,
      label,
      id,
      name,
      value,
      checked,
      autofocus,
      disabled,
      className,
      helpText,
      invalidText,
      required,
      optional,
      renderReduxForm,
      input,
    } = this.props;

    const displayType = type === 'radio' ? 'radio' : 'checkbox';

    const containerClasses = classnames(styles.container, className, {
      [styles.radioContainer]: displayType === 'radio',
      [styles.invalidText]: invalidText,
    });

    const errorText = invalidText && typeof invalidText === 'boolean' ? null : invalidText;

    return (
      <div className={containerClasses} data-testid="selection-wrapper">
        {!renderReduxForm ? (
          <input
            aria-describedby={`${id}-subtext`}
            aria-labelledby={`${id}-label`}
            autoFocus={autofocus} //eslint-disable-line
            checked={checked}
            className={classnames('sr-only', styles.input)}
            defaultValue={value}
            disabled={disabled}
            id={id}
            name={name}
            onChange={this.handleChange}
            type={displayType}
          />
        ) : (
          <Field
            component="input"
            aria-describedby={`${id}-subtext`}
            aria-labelledby={`${id}-label`} //eslint-disable-line
            checked={checked}
            className={classnames('sr-only', styles.input)}
            value={value}
            disabled={disabled}
            id={id}
            type={displayType}
            {...input}
          />
        )}

        <Label
          label={label}
          id={`${id}-label`}
          labelForId={id}
          className={`${styles.label} ${styles[type]}`}
          disabled={disabled}
          required={required}
          optional={optional}
        >
          {children}
        </Label>
        <SubText helpText={helpText} id={`${id}-subtext`} invalidText={errorText} />
      </div>
    );
  }
}

Selection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  helpText: PropTypes.string,
  invalidText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  optional: PropTypes.bool,
  onChange: PropTypes.func,
  renderReduxForm: PropTypes.bool,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
  }),
};

Selection.defaultProps = {
  children: null,
  name: null,
  value: null,
  checked: false,
  autofocus: false,
  disabled: false,
  className: null,
  helpText: null,
  invalidText: null,
  required: false,
  optional: true,
  onChange: null,
  renderReduxForm: false,
  input: null,
};
