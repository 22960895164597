import { LOADED } from 'redux/modules/search-and-browse/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADED: {
      return {
        ...state,
        search: action.result.header,
      };
    }
    default:
      return state;
  }
}
