import { apiCallback } from 'api';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';

const definition = {
  defaultVersion: 1,
  endpoint: 'products/${lineNumbers}',
  query: {
    view: 'SUMMARY',
    trolleyId: '${trolleyId}',
  },
  service: 'products',
  verbs: ['get'],
  timeout: TIMEOUT_CONFIG,
};

export default {
  get: apiCallback(definition, 'get'),
};
