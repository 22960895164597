/*
Event dispatched at point of products LOADED.
Should return empty result for non-Search page.

example:
```
{
  event: 'search_results',
  search: {
    keywordReturned: 'Bananas%2CFruit%2CLoose',
    numberOfResults: 48,
    searchTerm: 'Bananas',
    searchRulesets: 'Geoff|Woods',
    type: 'regular',
  },
}
```
*/
import { getUrlParam } from 'utils/general';

import { getAllSearchTags } from 'redux/modules/search/selectors/get-search-tags';
import {
  getSearchRulesets,
  getSearchWeightings,
  getSearchOrigin,
} from 'redux/modules/search/selectors';
import getSearchTerm from 'redux/modules/search/selectors/get-search-term';
import { getLocationSearchParams } from 'redux/modules/routing/selectors';
import { getTotalMatches } from 'redux/modules/search-and-browse/selectors/get-total-matches';
import { getExperiment } from 'redux/modules/search-and-browse/selectors';
import { getSearchHeaders } from 'redux/modules/header/selectors';
import { METADATA_HEADER_KEY, parseMetadataHeader } from 'api/utils/metadata-header';

const SEARCH_RESULTS = 'search_results';
const EMPTY_SEARCH_RESULTS = 'empty_search_results';

export default function transform(state) {
  const searchParams = getLocationSearchParams(state);
  const searchTerm =
    getSearchTerm(state) ||
    getUrlParam('ct', searchParams) ||
    getUrlParam('searchTerm', searchParams);
  const searchOrigin = getSearchOrigin(state);

  if (!searchTerm || searchTerm.length < 1) return {};

  const numberOfResults = getTotalMatches(state);
  const searchTags = getAllSearchTags(state);
  const sortByTag = getUrlParam('sortBy', searchParams);
  const searchRulesets = getSearchRulesets(state) || [];
  const searchWeightings = getSearchWeightings(state) || [];
  const searchHeaders = getSearchHeaders(state);
  const experiment = getExperiment(state);
  const metadataHeader = searchHeaders?.[METADATA_HEADER_KEY] ?? '';
  const { 'search-request-id': searchRequestId } = parseMetadataHeader(metadataHeader);

  return {
    event: numberOfResults > 0 ? SEARCH_RESULTS : EMPTY_SEARCH_RESULTS,
    search: {
      keywordReturned: encodeURIComponent(searchTags.toString()),
      numberOfResults,
      searchRulesets: encodeURIComponent(searchRulesets.join('|')),
      searchWeightings: searchWeightings.join(','),
      searchTerm: encodeURIComponent(searchTerm),
      sortBy: encodeURIComponent(sortByTag),
      type: searchOrigin,
      experiment,
    },
    'search-request-id': searchRequestId,
  };
}
