import {
  updateAddress,
  updateAddressee,
  updateChosenAddress,
  updateContactAddressAsync,
  contactAddressApiError,
} from 'redux/modules/address/actions';
import { validateUpdateAddress as validate } from 'components/Address/util';
import isPlainObject from 'lodash/isPlainObject';
import { getContactAddressId } from '../selectors/get-address-selectors';

const buildAddressModel = flatAddress => {
  const {
    title = null,
    firstName = null,
    lastName = null,
    contactNumber = null,
    id = null,
    isContactAddress = null,
    line1 = null,
    line2 = null,
    line3 = null,
    region = null,
    town = null,
    country = null,
    postalCode = null,
  } = flatAddress;
  return {
    id,
    isContactAddress,
    line1,
    line2,
    line3,
    region,
    town,
    country,
    postalCode,
    addressee:
      firstName && lastName
        ? {
            title,
            firstName,
            lastName,
            contactNumber,
          }
        : null,
  };
};

const cleanAddress = address => {
  if (!address || !isPlainObject(address)) {
    return null;
  }
  const {
    id = null,
    isContactAddress = null,
    line1 = null,
    line2 = null,
    line3 = null,
    region = null,
    town = null,
    country = null,
    postalCode = null,
    addressee = {},
  } = address;

  const { title = null, firstName = null, lastName = null, contactNumber = null } = addressee;
  return {
    ...{
      id,
      isContactAddress,
      line1,
      line2,
      line3,
      town,
      region,
      country,
      postalCode,
    },
    addressee:
      firstName && lastName
        ? {
            title,
            firstName,
            lastName,
            contactNumber,
          }
        : null,
  };
};

const isContactAddress = (state, { id = '' }) => {
  const contactAddressId = getContactAddressId(state);
  return contactAddressId === id;
};

const updateAddresseeAction = (dispatch, address) => dispatch(updateAddressee(address));

const updateAddressAction = (dispatch, address) => {
  const { id = '' } = address;
  return dispatch(updateAddress(id, address));
};

const updatedContactAddress = async ({ dispatch, address = {} }) => {
  try {
    const { id = '' } = address;
    await dispatch(updateContactAddressAsync({ contactAddressId: id }));
    return true;
  } catch (e) {
    await dispatch(contactAddressApiError(address));
    return false;
  }
};

export { updateAddressAction, cleanAddress, buildAddressModel, updatedContactAddress };

export default address => {
  const { control = null } = address || {};
  if (!isPlainObject(control) || !isPlainObject(address)) {
    return () => null;
  }

  return async (dispatch, getState) => {
    if (validate.addressRequiredFields(address) && validate.addressChanged(control, address)) {
      const state = await getState();

      const addressIsContactAddress = isContactAddress(state, control);

      if (addressIsContactAddress) {
        const updatedAddress = await updateAddressAction(dispatch, address);

        const clean = cleanAddress(updatedAddress);
        await updateChosenAddress(dispatch, state, { ...clean, isContactAddress: true });

        return clean;
      }

      const updatedAddress = await updateAddressAction(dispatch, address);
      await updateChosenAddress(dispatch, state, updatedAddress);

      return cleanAddress(updatedAddress);
    }

    if (validate.addresseeRequiredFields(address) && validate.addresseeChanged(control, address)) {
      const updatedAddressee = await updateAddresseeAction(dispatch, address);

      const updatedAddress = { ...address, addressee: { ...updatedAddressee } };
      const state = await getState();
      await updateChosenAddress(dispatch, state, updatedAddress);

      return cleanAddress(updatedAddress);
    }
    if (
      !validate.addressChanged(control, address) &&
      !validate.addresseeChanged(control, address)
    ) {
      return buildAddressModel(address);
    }
    return null;
  };
};
