import { apiCallback } from 'api';

const definition = {
  service: 'term-suggest',
  endpoint: 'term-suggest/terms',
  query: {
    term: '${term}',
  },
  verbs: ['get'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(definition, 'get'),
};
