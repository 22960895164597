import { compareUrls } from 'utils/compare-urls';
import { dataLayer } from 'analytics/data-layer';
import { monetateQ } from 'utils/monetate-q';

import {
  PAGE_CLEAR_COOKIES,
  PAGE_CLEAR_PRELOADED,
  PAGE_CLEAR_SERVER_GTM_EVENTS,
  PAGE_CLEAR_SERVER_MONETATE_EVENTS,
  PAGE_SET_PRELOADED,
  PAGE_SET_REQUEST_COOKIES,
  PAGE_SET_RESPONSE_COOKIES,
  PAGE_SET_SERVER_GTM_EVENTS,
  PAGE_SET_SERVER_MONETATE_EVENTS,
  SETCLEARVALUE,
  SETPAGEDEPTH,
  SETMOBILEOVERLAY,
  TOGGLE_PIN_BAR_OPEN,
} from 'redux/modules/page/actions/types';

import {
  getPreloadedPath,
  getServerGtmEvents,
  getServerMonetateEvents,
} from 'redux/modules/page/selectors';

export const clearCookies = () => ({
  type: PAGE_CLEAR_COOKIES,
});

export const clearPreloaded = () => ({
  type: PAGE_CLEAR_PRELOADED,
});

export const clearPreloadedIfLocationChanged =
  ({ pathname, search }) =>
  (dispatch, getState) => {
    const currentPath = pathname + search;
    const preloadedPath = getPreloadedPath(getState());
    if (preloadedPath && !compareUrls(currentPath, preloadedPath)) dispatch(clearPreloaded());
  };

export function setCurrentPageDepth(pageDepth, pageType) {
  return {
    type: SETPAGEDEPTH,
    pageDepth,
    pageType,
  };
}

export const setPreloaded = path => ({
  type: PAGE_SET_PRELOADED,
  payload: path,
});

export const setRequestCookies = cookies => ({
  type: PAGE_SET_REQUEST_COOKIES,
  payload: cookies,
});

export const setResponseCookies = cookies => ({
  type: PAGE_SET_RESPONSE_COOKIES,
  payload: cookies,
});

export const setServerGtmEvents = events => ({
  type: PAGE_SET_SERVER_GTM_EVENTS,
  payload: events,
});

export const setServerMonetateEvents = events => ({
  type: PAGE_SET_SERVER_MONETATE_EVENTS,
  payload: events,
});

export const rehydrateServerGtmEvents = () => (dispatch, getState) => {
  if (!__SERVER__) {
    const events = getServerGtmEvents(getState());

    if (events?.length) {
      events.forEach(event => dataLayer.push(event));
    }

    dispatch({
      type: PAGE_CLEAR_SERVER_GTM_EVENTS,
    });
  }
};

export const rehydrateServerMonetateEvents = () => (dispatch, getState) => {
  if (!__SERVER__) {
    const events = getServerMonetateEvents(getState());

    if (events?.length) {
      events.forEach(event => monetateQ.push(event));
    }

    dispatch({
      type: PAGE_CLEAR_SERVER_MONETATE_EVENTS,
    });
  }
};

export function setMobileOverlay(isMobileOverlayOpen) {
  return { type: SETMOBILEOVERLAY, isMobileOverlayOpen };
}

export function setClearValue(isValueClear) {
  return { type: SETCLEARVALUE, isValueClear };
}

export function togglePinBar() {
  return {
    type: TOGGLE_PIN_BAR_OPEN,
  };
}
