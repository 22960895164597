import { GET } from 'redux/modules/accounts/actions/types';

const getCustomerAccountsRequest = state => ({
  ...state,
  request: {
    ...state.request,
    loading: true,
    loaded: false,
    error: null,
  },
});

const getCustomerAccountsSuccess = (state, action) => ({
  ...state,
  account: action.result,
  request: {
    ...state.request,
    initialLoadComplete: true,
    loading: false,
    loaded: true,
    error: null,
  },
});

const getCustomerAccountsFailure = state => ({
  ...state,
  totalOrderCount: -1,
  request: {
    ...state.request,
    loading: false,
    loaded: false,
    error: true,
  },
});

export default map => {
  map.set(GET.request, getCustomerAccountsRequest);
  map.set(GET.success, getCustomerAccountsSuccess);
  map.set(GET.failure, getCustomerAccountsFailure);
  return map;
};
