import buildPromotions from 'redux/transforms/build-promotions';
import transformBundleProductsIntoLegacyFormat from 'redux/transforms/transform-bundle-products-into-legacy-format';
import mapProductsIntoLegacyFormat from 'redux/transforms/transform-product-into-legacy-format';
import mapProductsAndRecommendationsIntoLegacyFormat from 'redux/transforms/transform-products-and-recommendations-into-legacy-format';
import transformGridItems from 'redux/transforms/transform-grid-items';
import transformInterstitialsContent from 'redux/transforms/transform-interstitials-content';

export const BUILD_PROMOTIONS = 'buildPromotions';
export const TRANSFORM_BUNDLE_PRODUCTS_INTO_LEGACY_FORMAT =
  'transformBundleProductsIntoLegacyFormat';
export const TRANSFORM_GRID_ITEMS = 'transformGridItems';
export const TRANSFORM_INTERSTITIALS_CONTENT = 'transformInterstitialsContent';
export const TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT = 'mapProductsIntoLegacyFormat';
export const TRANSFORM_PRODUCTS_AND_RECOMMENDATIONS_INTO_LEGACY_FORMAT =
  'mapProductsAndRecommendationsIntoLegacyFormat';

export const transforms = {
  buildPromotions,
  transformBundleProductsIntoLegacyFormat,
  mapProductsIntoLegacyFormat,
  mapProductsAndRecommendationsIntoLegacyFormat,
  transformGridItems,
  transformInterstitialsContent,
};
