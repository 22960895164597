import { weightOptions, C62 } from 'constants/weightOptions';

export function formatQuantity(quantity = { amount: 0, uom: C62 }) {
  const { allowFractional, maximum, minimum } = weightOptions[quantity.uom];

  let amount = quantity.amount.toString().replace(/[^\d\.-]+/g, ''); // eslint-disable-line no-useless-escape
  amount = parseFloat(amount || 0, 10);
  amount = allowFractional ? Math.round(amount * 10) / 10 : parseInt(amount, 10);

  if (amount < 0) amount = 0;
  if (amount > 0 && amount < minimum) amount = minimum;
  if (amount > maximum) amount = maximum;

  return {
    amount,
    uom: quantity.uom,
  };
}
