import { weightOptions } from 'constants/weightOptions';
import optimisticallyUpdateTrolleyItem from 'redux/modules/trolley/actions/optimistically-update-trolley-item';
import addNewItemToTrolley from 'redux/modules/trolley/actions/add-new-item-to-trolley';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';
import { getItemByLineNumber } from 'redux/modules/trolley/selectors/get-item';
import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { isPersonalisableProduct } from 'utils/product';
import {
  getItemToAddToTrolleyPostAuthentication,
  removeItemToAddToTrolleyPostAuthentication,
} from 'utils/trolley';

export const addToTrolleyPostAuthentication = () => (dispatch, getState) => {
  const state = getState();

  if (!isUserLoggedIn(state)) {
    return;
  }

  const productInfo = getItemToAddToTrolleyPostAuthentication();

  if (!productInfo) {
    return;
  }

  removeItemToAddToTrolleyPostAuthentication();

  // This validation is necessary because we can have multiple of the same personalised item in our trolley
  // and although they are different items (different trolleyItemId), they have the same lineNumber
  // So the getItemByLineNumber would get us the information of the pre existing personalised item
  const product = getProductByLineNumber(state, productInfo.lineNumber) || {};

  if (isPersonalisableProduct(product)) {
    dispatch(addNewItemToTrolley(productInfo));
    return;
  }

  const trolleyItem = getItemByLineNumber(state, productInfo.lineNumber);

  if (!trolleyItem) {
    dispatch(optimisticallyUpdateTrolleyItem(productInfo));

    return;
  }

  const {
    quantity: { amount: currentAmount, uom: currentUom },
  } = trolleyItem;

  if (currentUom === productInfo.quantity.uom) {
    const quantity = formatQuantity({
      amount: currentAmount + productInfo.quantity.amount,
      uom: currentUom,
    });

    dispatch(
      optimisticallyUpdateTrolleyItem({
        ...productInfo,
        quantity,
      }),
    );

    return;
  }

  const decrement = productInfo.quantity.amount < 0;
  const quantity = formatQuantity({
    amount: currentAmount + weightOptions[currentUom].increment * (decrement ? -1 : 1),
    uom: currentUom,
  });

  dispatch(
    optimisticallyUpdateTrolleyItem({
      ...productInfo,
      quantity,
    }),
  );
};
