import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Close, ChevronDown } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { setBodyNoScroll } from 'utils/scroll';
import { keyValues } from 'constants/keys';
import MenuExperienceFragmentClickHandler from 'components/ExperienceFragment/MenuExperienceFragmentClickHandler';
import { pushMenuClick } from 'utils/gtm';
import ExperienceFragment from 'components/ExperienceFragment';
import { SEASONAL_MENU } from 'constants/experience-fragments';

import styles from './SeasonalMenuPanels.scss';

export type SeasonalMenuPanelsProps = {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
};

export const SeasonalMenuPanels = ({
  isOpen = false,
  onToggle,
  title,
}: SeasonalMenuPanelsProps) => {
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setBodyNoScroll(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    onToggle();

    menuButtonRef?.current?.focus?.();
  };

  const handleEscapeToClose = (event: React.KeyboardEvent) => {
    if (isOpen && event.key === keyValues.KEY_ESCAPE) {
      pushMenuClick(`Seasonal | closed menu using escape`);
      handleClose();
    }
  };

  const handleButtonClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (isOpen) {
      handleClose();
      pushMenuClick(`Seasonal | closed menu using ${event.currentTarget.id}`);
    } else {
      onToggle();
      pushMenuClick('Seasonal');
    }
  };

  useOnClickOutside(menuRef, () => {
    if (isOpen) onToggle();
  });

  return (
    <div ref={menuRef} role="none" data-testid="SeasonalMenu" onKeyDown={handleEscapeToClose}>
      <button
        aria-controls="SeasonalMenu"
        aria-expanded={isOpen}
        ref={menuButtonRef}
        aria-label={`Toggle ${title} menu`}
        className={classNames(styles.btnMenu, {
          [styles.menuActive]: isOpen,
        })}
        data-testid="seasonal-menu-desktop-button"
        id="seasonal-menu-button"
        onClick={handleButtonClick}
        type="button"
      >
        {title}
        <ChevronDown className="icon" size="xsmall" />
      </button>
      <div>
        <div
          aria-label={`${title} with Waitrose`}
          className={classNames(styles.nav, {
            [styles.open]: isOpen,
          })}
          data-testid="seasonal-open"
          id="SeasonalMenu"
          role="region"
        >
          <div className={styles.panelWrapper}>
            <MenuExperienceFragmentClickHandler trackingPrefix="Seasonal" onClose={handleClose}>
              <ExperienceFragment
                experienceFragmentKey={SEASONAL_MENU.key}
                locationName={SEASONAL_MENU.location}
              />
            </MenuExperienceFragmentClickHandler>
          </div>
          <button
            className={styles.close}
            data-testid="seasonal-menu-close"
            id="menu-close"
            onClick={handleButtonClick}
            type="button"
          >
            <span className="sr-only">Close</span>
            <Close className={styles.icon} size="small" />
          </button>
        </div>
      </div>
    </div>
  );
};
