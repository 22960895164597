export default (state, action) => ({
  ...state,
  promoCodes: {
    ...state.promoCodes,
    isAdding: false,
    isRemoving: true,
    requestedPromoCode: action?.code,
    serverError: null,
  },
  waiting: true,
});
