import orderGiftCards from 'api/definitions/orderGiftCards';
import get from 'lodash/get';
import handleErrors from 'redux/errors/handle-errors';
import { REMOVE_GIFT_CARD } from 'redux/modules/order/actions/types';
import { getCurrentGiftCards } from 'redux/modules/order/selectors/get-current-gift-cards';
import { getGiftCardSerialNumberToRemove } from 'redux/modules/order/selectors/get-gift-card-serial-number-to-remove';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { handleGiftCardError, handleTimeoutError } from './errors';

const handleResponse = dispatch => result => {
  const failures = get(result, 'data.setGiftCards.failures', null);

  if (failures) {
    const errorResponse = {
      status: failures.statusCode,
      response: {
        body: {
          type: 'REMOVE_GIFT_CARD_ERROR',
          code: failures.conflicts?.code,
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftCardError]));
  }

  if (result?.errors) {
    const errorResponse = {
      response: {
        body: {
          type: 'REMOVE_GIFT_CARD_ERROR',
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftCardError]));
  }

  return result;
};

const handleRequestErrors = dispatch => error => {
  dispatch(handleErrors(error, [handleTimeoutError]));

  return true;
};

const confirmRemoveGiftCard = () => (dispatch, getState) => {
  const state = getState();
  const serialNumberToRemove = getGiftCardSerialNumberToRemove(state);
  const customerOrderId = getCustomerOrderId(state);
  const currentGiftCards = getCurrentGiftCards(state);
  const giftCards = currentGiftCards.filter(card => card.serialNumber !== serialNumberToRemove);

  return dispatch({
    apiCall: orderGiftCards.post({ customerOrderId, giftCards }),
    types: REMOVE_GIFT_CARD.triplet,
  }).then(handleResponse(dispatch), handleRequestErrors(dispatch));
};

export default confirmRemoveGiftCard;
