import { DependencyList, EffectCallback, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Triggers the callback for every change of the hash */
export function useOnHashChange(
  callback: (hash: string) => void | (() => void),
  deps: DependencyList = [],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedCallback = useCallback(callback, deps);

  const { hash } = useLocation();
  useEffect(() => {
    return cachedCallback(hash);
  }, [cachedCallback, hash]);
}

/** Triggers the callback only if the desired hash was entered */
export function useOnHashEnter(hash: string, callback: EffectCallback, deps: DependencyList = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedCallback = useCallback(callback, deps);

  const desiredHash = hash.startsWith('#') ? hash : `#${hash}`;

  useOnHashChange(
    enteredHash => {
      if (enteredHash === desiredHash) {
        return callback();
      }

      return undefined;
    },
    [desiredHash, cachedCallback],
  );
}
