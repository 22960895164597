// transforms array of [{ name, value }, ... ] into object of { [name]: value, ... }
const normaliseErrorDetails = details =>
  details.reduce(
    (errors, { name, value }) => ({
      ...errors,
      [name]: value,
    }),
    {},
  );

const parseGiftVoucherInlineErrors = body =>
  body?.code === 'ORDER_ORCHESTRATION_037' && Array.isArray(body.details)
    ? normaliseErrorDetails(body.details)
    : null;

const reducer = (state, action) => {
  const { status = 0, response: { body } = {} } = action.error ?? {};

  return {
    ...state,
    errorStatusCode: status,
    errorResponse: { ...body },
    errorSource: state.currentApiRequest,
    giftVoucherInlineErrors: parseGiftVoucherInlineErrors(body),
  };
};

export default reducer;
