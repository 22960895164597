export default state => {
  const removedPromoCode = state.promoCodes.requestedPromoCode;
  const updatedPromoCodesList = state.promoCodes.promoCodesList.filter(
    ({ code }) => code !== removedPromoCode,
  );

  return {
    ...state,
    promoCodes: {
      ...state.promoCodes,
      promoCodesList: updatedPromoCodesList,
      isRemoving: false,
    },
    waiting: false,
  };
};
