import root from 'window-or-global';

import { yieldToMain } from 'utils/yield-to-main';
import { log } from 'utils/error-logging';

const createMiddleware =
  (actionHandlers = {}) =>
  store =>
  next =>
  async action => {
    next(action);

    await yieldToMain();

    if (__SERVER__ || !root.ire) {
      return;
    }

    const { type } = action;
    const handler = actionHandlers[type];

    if (!handler) {
      return;
    }

    const { dispatch, getState } = store;

    try {
      actionHandlers[type](dispatch, getState);
    } catch (err) {
      log(err, { logger: 'impact-create-middleware' });
    }
  };

export default createMiddleware;
