import {
  PRODUCT_ISSUES_FORM_SUBMIT_SUCCESS,
  PRODUCT_ISSUES_FORM_SUBMIT_FAIL,
  PRODUCT_ISSUES_FORM_RESET,
} from 'redux/modules/customer-service/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  submissionSucceeded: false,
  submissionFailed: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_ISSUES_FORM_RESET: {
      return initialState;
    }
    case PRODUCT_ISSUES_FORM_SUBMIT_FAIL: {
      return { ...state, submissionSucceeded: false, submissionFailed: true };
    }
    case PRODUCT_ISSUES_FORM_SUBMIT_SUCCESS: {
      const {
        result: {
          id,
          request: { parentCaseId },
        },
      } = action;

      if (parentCaseId) {
        return {
          ...state,
          submissionSucceeded: true,
          submissionFailed: false,
          parentCaseId,
        };
      }

      return {
        ...state,
        submissionSucceeded: true,
        submissionFailed: false,
        parentCaseId: id,
      };
    }

    default:
      return state;
  }
};

export default reducer;
