import {
  REVIEWS_TOKEN_LOADING,
  REVIEWS_TOKEN_LOADED,
  REVIEWS_TOKEN_FAILED,
} from 'redux/modules/reviews/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  token: null,
  tokenLoading: false,
});

export default function reviews(state = initialState, action = {}) {
  switch (action.type) {
    case REVIEWS_TOKEN_LOADING:
      return {
        ...state,
        token: null,
        tokenLoading: true,
      };
    case REVIEWS_TOKEN_LOADED:
      return {
        ...state,
        token: action.result.token,
        tokenLoading: false,
      };
    case REVIEWS_TOKEN_FAILED:
      return {
        ...state,
        tokenLoading: false,
      };
    default:
      return state;
  }
}
