import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import styles from 'components/Warrant/Warrant.scss';

const Warrant = pure(({ armsUrl, byAppointmentTo }) => (
  <figure className={styles.warrant}>
    <img className={styles.arms} src={armsUrl} />
    <figcaption className={styles.caption}>
      {'By Appointment to'}
      <br />
      {byAppointmentTo}
      <br />
      {'Grocer and Wine & Spirit Merchants'}
      <br />
      {'Waitrose Limited, Bracknell'}
    </figcaption>
  </figure>
));

Warrant.displayName = 'Warrant';

Warrant.propTypes = {
  armsUrl: PropTypes.string.isRequired,
  byAppointmentTo: PropTypes.string.isRequired,
};

export default Warrant;
