import createReducer from 'redux/createReducer';
import { BUNDLE_NOT_FOUND, GET_BUNDLE } from 'redux/modules/bundle/actions/types';
import {
  BundleErrorState,
  BundleSuccessState,
  BundleState,
  initialState,
  BundleNotFoundState,
} from 'redux/modules/bundle/state';
import { TransformedBundleResponse } from 'redux/transforms/transform-bundle-products-into-legacy-format';

interface GetBundleSuccessAction {
  result: TransformedBundleResponse;
}

const getBundleRequest = (): Record<string, never> => ({});

const getBundleSuccess = (
  _state: BundleState,
  { result: { productCollections, title } }: GetBundleSuccessAction,
): BundleSuccessState => ({
  groups: productCollections.map(({ thresholds, title: productCollectionTitle, products }) => ({
    thresholds,
    title: productCollectionTitle,
    products,
  })),
  title,
});

const getBundleFailure = (): BundleErrorState => ({ serverError: true });

const getBundleNotFound = (): BundleNotFoundState => ({ notFound: true });

const actionTypeReducerMap = [
  [GET_BUNDLE.request, getBundleRequest],
  [GET_BUNDLE.success, getBundleSuccess],
  [GET_BUNDLE.failure, getBundleFailure],
  [BUNDLE_NOT_FOUND, getBundleNotFound],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
