import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import {
  LOCK_TROLLEY,
  UNLOCK_TROLLEY,
  UPDATE_TROLLEY_ITEM,
} from 'redux/modules/trolley/actions/types';
import {
  handleTrolleyConflicts,
  handleUpdateTrolleyErrors,
} from 'redux/modules/trolley/error-handling';
import { yieldToMain } from 'utils/yield-to-main';

const trolleyActionQueue = [];

let getTrolleyDebounceTimer;
const GET_TROLLEY_DEBOUNCE_MS = 1000;

async function processQueue(dispatch, getState) {
  if (typeof getTrolleyDebounceTimer !== 'undefined') {
    clearTimeout(getTrolleyDebounceTimer);
    getTrolleyDebounceTimer = undefined;
  }

  if (!trolleyActionQueue.length) {
    dispatch({ type: UNLOCK_TROLLEY });

    getTrolleyDebounceTimer = setTimeout(() => {
      getTrolleyDebounceTimer = undefined;

      if (!trolleyActionQueue.length) {
        dispatch(getTrolley({ suppressErrorModal: true }));
      }
    }, GET_TROLLEY_DEBOUNCE_MS);

    return;
  }

  dispatch({ type: LOCK_TROLLEY });

  const nextTrolleyAction = trolleyActionQueue.shift();

  if (nextTrolleyAction.queueFunction) {
    nextTrolleyAction.queueFunction();
  } else {
    try {
      const result = await dispatch(nextTrolleyAction);
      handleTrolleyConflicts(dispatch)(result);
    } catch (error) {
      handleUpdateTrolleyErrors(dispatch, getState)(error);
    }
  }

  await yieldToMain();

  processQueue(dispatch, getState);
}

const trolleyQueueMiddleware =
  ({ dispatch, getState }) =>
  next =>
  ({ queue, ...action } = {}) => {
    if (!queue) {
      return next(action);
    }

    const latestItemIndex = trolleyActionQueue
      .map(({ lineNumber }) => lineNumber)
      .lastIndexOf(action.lineNumber);

    if (latestItemIndex >= 0 && trolleyActionQueue[latestItemIndex].type === UPDATE_TROLLEY_ITEM) {
      trolleyActionQueue.splice(latestItemIndex, 1);
    }

    trolleyActionQueue.push(action);

    const state = getState();
    const isTrolleyLocked = state.trolley.locked;

    if (!isTrolleyLocked) {
      processQueue(dispatch, getState);
    }

    return undefined;
  };

export default (server = __SERVER__) =>
  server ? () => next => action => next(action) : trolleyQueueMiddleware;
