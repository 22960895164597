import orderIncentive from 'api/definitions/orderIncentive';

import { initialiseOrder } from 'redux/modules/order/actions';
import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import { showSnackbar } from 'redux/modules/common-snackbar/actions';

import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import {
  getRequestedPromoCode,
  getPromoCodeByCode,
} from 'redux/modules/order/selectors/promo-codes';

import swallowStatusCodes from 'utils/swallow-status-codes';
import { promoCodeAppliedAtCheckout, promoCodeNotAppliedAtCheckout } from 'constants/snackbars';

import { ADD_PROMO_CODE } from './types';
import { handleTimeoutError } from './errors';

export const iHandleStatusCodes = [400, 403, 404, 409, 410, 422, 500, 503];

export const getSnackbarByStatus = status => {
  switch (status) {
    case 'APPLIED':
      return promoCodeAppliedAtCheckout;
    case 'UNAVAILABLE':
    case 'ALREADY_REDEEMED':
      return { ...promoCodeNotAppliedAtCheckout, type: 'error' };
    case 'THRESHOLD_NOT_MET':
    case 'PRODUCT_NOT_IN_TROLLEY':
    case 'EXPIRED':
    case 'INACTIVE':
    case 'UNAVAILABLE_FOR_BRANCH':
      return { ...promoCodeNotAppliedAtCheckout, type: 'warning' };
    default:
      return undefined;
  }
};

export default ({ code, displaySnackbar = false, handleErrors = true }) =>
  (dispatch, getState) => {
    dispatch({
      type: ADD_PROMO_CODE.request,
      code,
    });
    const resultPromise = orderIncentive
      .addIncentive({ code })({
        dispatch,
        getState,
      })
      .then(async response => {
        const { data, errors } = response;
        const failures = data?.addIncentive?.failures || [];
        if (errors) {
          const regex = /^50[0-9]/;
          const error = errors.some(({ message = '' } = {}) => regex.test(message))
            ? {
                status: 500,
                message:
                  'Sorry, we could not apply this code/voucher to your order. If the problem continues, try again later.',
              }
            : { status: 400, message: 'Sorry, there is something wrong with this request.' };
          dispatch({
            error,
            type: ADD_PROMO_CODE.failure,
          });

          throw error;
        } else if (failures.length > 0) {
          const error = {
            status: parseInt(failures[0].type, 10),
            message: failures[0].message,
          };
          dispatch({
            error,
            type: ADD_PROMO_CODE.failure,
          });

          throw error;
        } else {
          dispatch({
            type: ADD_PROMO_CODE.success,
            result: data.addIncentive,
          });

          await Promise.all([
            dispatch(initialiseOrder(getCustomerOrderId(getState()))),
            dispatch(getTrolley()),
          ]);

          if (displaySnackbar) {
            // sometimes, right after adding the incentive, it can temporarily be in 'PENDING_QUALIFICATION_CHECK' status, for which we are not supposed to show any snackbar
            // but then, when we get the order data again with updated adjustments (added promo codes will be part of order adjustments data), the status may have changed to 'APPLIED' or some other status, and only then we are able to determine if we should display a snackbar or not
            // that's why we can only show the snackbar after getting the the updated order data
            const requestedPromoCode = getRequestedPromoCode(getState());
            const promoCode = getPromoCodeByCode(getState(), requestedPromoCode);
            const snackbar = getSnackbarByStatus(promoCode?.status);
            if (snackbar) {
              dispatch(showSnackbar(snackbar));
            }
          }
        }
      })
      .catch(error => {
        if (!handleTimeoutError(error)(dispatch, getState)) {
          throw error;
        }
      });

    return handleErrors
      ? resultPromise.catch(swallowStatusCodes({ statusCodes: iHandleStatusCodes }))
      : resultPromise;
  };
