/*

PRODUCTS level custom dimensions currently not configured in Google Analytics
------------------------------------------------------------------------

* search_term

*/
import { UPDATESEARCHTERM } from 'redux/modules/search/actions/types';

import addProductSearchTerm from './search-term';

export default {
  [UPDATESEARCHTERM]: addProductSearchTerm,
};
