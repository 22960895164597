import jwtDecode from 'jwt-decode';
import { initialState } from 'redux/modules/sessions/reducer';

const reducer = (state, action) => {
  const { accessToken, customerId, origin } = action.data;

  if (!accessToken) {
    return {
      ...state,
      ...initialState,
    };
  }

  try {
    const decodedToken = jwtDecode(accessToken);
    return {
      ...state,
      accessToken: `Bearer ${accessToken}`,
      permissions: decodedToken['http://waitrose.com/permissions'] || [],
      customerId: customerId || decodedToken['http://waitrose.com/customer_id'] || '-1',
      principalId: decodedToken['http://waitrose.com/principal_id'] || '-1',
      origin,
    };
  } catch (e) {
    return {
      ...state,
    };
  }
};

export default reducer;
