import historyUtils from 'utils/history';

import { MONETATE_ACTIONS } from 'redux/middleware/monetate/actions-to-track';
import { createPayload } from 'redux/middleware/monetate/actions/create-payload';

import { CLIENT_PAGE_LOADED } from 'redux/modules/page/actions/types';
import { yieldToMain } from 'utils/yield-to-main';

const isActionTrackable = types => types.some(type => MONETATE_ACTIONS.indexOf(type) > -1);

/* the event should be fired if:
  1) page is completely loaded
  2) page suffers any updates after being loaded
*/
const shouldReport = (isClientPageLoaded, actionTypes) =>
  actionTypes.includes(CLIENT_PAGE_LOADED) ? true : isClientPageLoaded;

let refCount = 0;
const generatePayload = (getState, actionTypes) => {
  refCount -= 1;

  const { isClientPageLoaded } = getState().page;

  if (!shouldReport(isClientPageLoaded, actionTypes)) {
    return;
  }

  if (refCount === 0) {
    createPayload(getState(), historyUtils.getLocation());
  }
};

const monetateMiddleware =
  ({ getState }) =>
  next =>
  async action => {
    const types = action.types || [action.type];
    const trackAction = isActionTrackable(types);

    if (!trackAction) {
      return next(action);
    }

    refCount += 1;
    const nextInChain = next(action);

    if (nextInChain) {
      Promise.resolve(nextInChain)
        .catch(() => {})
        .then(() => yieldToMain())
        .finally(() => {
          generatePayload(getState, types);
        });
    } else {
      yieldToMain().finally(() => {
        generatePayload(getState, types);
      });
    }

    return nextInChain;
  };

export default monetateMiddleware;
