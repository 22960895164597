import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  activeFilterGroup: '',
  queryParams: {
    category: null,
    filterTags: [],
    searchTags: [],
    searchTerm: '',
    size: 48,
    sortBy: '',
  },
  origin: undefined,
  searchRulesets: undefined,
  relevancyWeightings: undefined,
});

export default initialState;
