import React from 'react';
import { bool, number } from 'prop-types';
import { formatAsPositivePoundsOrPence } from 'utils/currency';
import GoToTrolley from 'components/GoToTrolley';
import TrolleyButtonContents from './TrolleyButtonContents';

import styles from './ShoppingSummary.scss';

const ShoppingSummary = ({ loadingStatus, totalEstimatedCost, totalNumberOfItems }) => {
  const estimatedCost = formatAsPositivePoundsOrPence(totalEstimatedCost);
  const description = `trolley, ${totalNumberOfItems} item${
    totalNumberOfItems !== 1 ? 's' : ''
  } ${estimatedCost} total, view trolley.`;

  return (
    <div className={styles.shoppingSummary}>
      <section
        className={styles.trolleyActions}
        data-actiontype="redirect"
        data-customdata="category:Header"
        data-origincomponent="ShoppingSummary"
        data-shortdescription={description}
      >
        <GoToTrolley
          className={styles.trolleyLink}
          data-testid="header-trolley-link"
          label={estimatedCost}
          linkStyle="text"
          originComponent="site-header accessible trolley link"
          title={description}
        >
          <TrolleyButtonContents
            estimatedCost={estimatedCost}
            loadingStatus={loadingStatus}
            totalNumberOfItems={totalNumberOfItems}
          />
        </GoToTrolley>
      </section>
    </div>
  );
};

ShoppingSummary.propTypes = {
  loadingStatus: bool,
  totalEstimatedCost: number,
  totalNumberOfItems: number,
};

ShoppingSummary.defaultProps = {
  loadingStatus: false,
  totalEstimatedCost: 0,
  totalNumberOfItems: 0,
};

export default ShoppingSummary;
