import { createSelector } from 'reselect';
import getAmendOrderOrSavedPaymentCard from './get-amend-order-or-saved-payment-card';

/**
 * Gets the saved card number
 *
 * WARNING: The card number will be masked
 */
export default createSelector(getAmendOrderOrSavedPaymentCard, amendOrderOrSavedPaymentCard => {
  const maskedCardNumber = amendOrderOrSavedPaymentCard?.maskedCardNumber;
  return {
    cardNumber: maskedCardNumber,
    isMasked: !!maskedCardNumber,
  };
});
