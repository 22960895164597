import React from 'react';

import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';

const SlotButtonAlert = () => (
  <Alert
    data-testid="slot-details-alert"
    message="Your order contains items currently being prepared. We are unable to change the delivery/collection time."
    small
    type="warning"
  />
);

export default SlotButtonAlert;
