import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const nameSpace = 'waitrose/payment/';

export const ACCOUNT_VERIFICATION = apiPromiseTypes('orderPaymentCardsV2', 'put');
export const ADD_ORDER_PAYMENT_CARD = apiPromiseTypes('orderPaymentCardsV1', 'put');
export const CREATE_PAYMENT_SESSION = apiPromiseTypes('paymentSession', 'post');
export const DELETE_PAYMENT_CARD = apiPromiseTypes('paymentCards', 'delete');
export const GET_PAYMENT_CARDS = apiPromiseTypes('paymentCards', 'get');
export const GET_SECURITY_TOKEN = apiPromiseTypes('paymentsSecurityTokens', 'get');
export const GLOBAL_ACTIVE = `${nameSpace}GLOBAL_ACTIVE` as const;
export const GLOBAL_BIN_READY = `${nameSpace}GLOBAL_BIN_READY` as const;
export const GLOBAL_CARD_TOKEN = `${nameSpace}GLOBAL_CARD_TOKEN` as const;
export const GLOBAL_CARDINAL_READY = `${nameSpace}GLOBAL_CARDINAL_READY` as const;
export const GLOBAL_CARDINAL_SETUP_TIMER_FINISHED =
  `${nameSpace}GLOBAL_CARDINAL_SETUP_TIMER_FINISHED` as const;
export const GLOBAL_CARDINAL_SETUP_START_TIME =
  `${nameSpace}GLOBAL_CARDINAL_SETUP_START_TIME` as const;
export const GLOBAL_JWT = `${nameSpace}GLOBAL_JWT` as const;
export const POST_CARD_TO_VERIFONE_REQUEST = `${nameSpace}POST_CARD_TO_VERIFONE_REQUEST` as const;
export const POST_CARD_TO_VERIFONE_SUCCESS = `${nameSpace}POST_CARD_TO_VERIFONE_SUCCESS` as const;
export const PATCH_AUTHENTICATED_PAYMENT_CARD = apiPromiseTypes(
  'authenticatedPaymentCardsV4',
  'patch',
);
export const START_PAYMENT_SESSION_TIMEOUT = `${nameSpace}SESSION_TIMEOUT` as const;
export const GET_FAILED_ORDER_PAYMENT = apiPromiseTypes('failed-order-payments', 'get');
export const POST_FAILED_ORDER_PAYMENT_CARD = apiPromiseTypes('failedOrderPayment', 'post');
