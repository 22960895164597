import isEqual from 'lodash/isEqual';
import { createSelector, lruMemoize } from 'reselect';

import { getOrderedItems } from 'redux/modules/trolley/selectors/get-items';
import { getProductByLineNumber, getProducts } from 'redux/modules/entities/selectors/products';

const EMPTY_ARRAY = [];

export const getPromotions = ({ promotions = {} } = {}) => promotions;
const getPromotionId = (_state, promotionId) => promotionId;

const getTriggeredPromotions = createSelector([getPromotions], ({ triggered } = {}) => triggered);

const getUntriggeredPromotions = createSelector(
  [getPromotions],
  ({ untriggered } = {}) => untriggered,
);

const getTriggeredPromotionById = createSelector(
  [getTriggeredPromotions, getPromotionId],
  (triggeredPromotions = {}, promotionId) => triggeredPromotions[promotionId],
);

const getUntriggeredPromotionById = createSelector(
  [getUntriggeredPromotions, getPromotionId],
  (untriggeredPromotions = {}, promotionId) => untriggeredPromotions[promotionId],
);

const concatenateProducts = (
  { products: untriggeredProducts = [] } = {},
  { products: triggeredProducts = [] } = {},
) => [...new Set(untriggeredProducts.concat(triggeredProducts))];

export const getProductsByPromotionId = createSelector(
  [getUntriggeredPromotions, getTriggeredPromotions, getPromotionId],
  (untriggeredPromotions = [], triggeredPromotions = [], promotionId) =>
    promotionId
      ? concatenateProducts(untriggeredPromotions[promotionId], triggeredPromotions[promotionId])
      : EMPTY_ARRAY,
);

export const getOrderedProductsByPromotionId = createSelector(
  [getOrderedItems, getProductsByPromotionId],
  (orderedItems = [], products) =>
    orderedItems
      .filter(({ lineNumber }) => products.includes(lineNumber))
      .map(({ lineNumber }) => lineNumber),
  {
    memoize: lruMemoize,
    memoizeOptions: {
      maxSize: 2,
      resultEqualityCheck: isEqual,
    },
  },
);

export const getOrderedEntitiesByPromotionId = createSelector(
  [getOrderedProductsByPromotionId, getProducts],
  (lineNumbers, products) => {
    const state = { entities: { products } };

    return lineNumbers.map(lineNumber => getProductByLineNumber(state, lineNumber));
  },
);

export const getPromotionIsComplete = createSelector(
  [getTriggeredPromotionById, getUntriggeredPromotionById],
  (triggeredPromotion, untriggeredPromotion) => !!(triggeredPromotion && !untriggeredPromotion),
);

export const getUntriggeredPromotionIds = createSelector(
  [getUntriggeredPromotions],
  (untriggered = {}) => Object.keys(untriggered),
);
