import React, { memo, useRef, useCallback, useEffect } from 'react';
import { bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { KEY_ENTER, KEY_ESCAPE } from 'constants/keys';
import { useOnClickOutside } from 'hooks';
import { setBodyNoScroll } from 'utils/scroll';

import { dataLayer } from 'analytics/data-layer';
import { ChevronDown } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import MegaMenu from 'components/MegaMenu/Menus';
import SiteHeaderOverlay from 'components/SiteHeader/SiteHeaderOverlay/SiteHeaderOverlay';

import { setMegaMenuActiveLevel } from 'redux/modules/page/actions/set-mega-menu-active-level';
import { setMegaMenuStart } from 'redux/modules/page/actions/set-mega-menu-start';
import useMenus from 'hooks/use-menus';
import styles from './DropDownNav.scss';

const DropDownNav = memo(({ scrolled }) => {
  const menuButtonRef = useRef();
  const navigationRef = useRef();
  const menuRef = useRef();
  const { isMegaMenuOpen, toggleMegaMenu } = useMenus();

  const dispatch = useDispatch();

  const onOpen = menuRoot => {
    dispatch(setMegaMenuStart(menuRoot));
    toggleMegaMenu();
  };

  const onClose = useCallback(() => {
    toggleMegaMenu();
    dispatch(setMegaMenuActiveLevel(-1));
  }, [dispatch, toggleMegaMenu]);

  useEffect(() => {
    setBodyNoScroll(isMegaMenuOpen);
  }, [isMegaMenuOpen]);

  const handleClickOpener = (event, taxonomyRoot) => {
    event.preventDefault();

    if (isMegaMenuOpen) {
      onClose();
    } else {
      onOpen(taxonomyRoot);
      dataLayer.push({
        event: 'click_megamenu',
        mega_menu: {
          button_clicked: 'Groceries',
        },
      });
    }
  };

  const handleKeyDownOpener = (event, taxonomyRoot) => {
    if (event.keyCode === KEY_ENTER) {
      handleClickOpener(event, taxonomyRoot);
      setTimeout(() => {
        if (navigationRef.current) navigationRef.current.focusFirst();
      }, 200);
    }
  };

  const handleClickToClose = useCallback(
    event => {
      if (isMegaMenuOpen) {
        event.stopPropagation();
        onClose();

        if (menuButtonRef?.current) {
          menuButtonRef.current.focus();
        }
      }
    },
    [isMegaMenuOpen, onClose],
  );

  const handleClickOutside = useCallback(
    event => {
      if (isMegaMenuOpen) {
        event.stopPropagation();
        onClose();
      }
    },
    [isMegaMenuOpen, onClose],
  );

  useOnClickOutside(menuRef, handleClickOutside);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="DropDownNav"
      onKeyDown={event => {
        if (event.keyCode === KEY_ESCAPE) {
          handleClickToClose(event);
        }
      }}
    >
      <button
        aria-controls="megamenu"
        aria-expanded={isMegaMenuOpen}
        ref={menuButtonRef}
        aria-label="Toggle groceries menu"
        className={classNames(styles.btnMenu, {
          [styles.menuActive]: isMegaMenuOpen,
          [styles.scrolled]: scrolled,
        })}
        data-testid="site-header-browse-groceries"
        id="drop-down-nav"
        onClick={event => handleClickOpener(event, 'Groceries')}
        onKeyDown={event => handleKeyDownOpener(event, 'Groceries')}
        type="button"
      >
        Groceries
        <ChevronDown className="icon" size="xsmall" />
      </button>
      <div ref={menuRef}>
        {isMegaMenuOpen && (
          <MegaMenu
            isOpen={isMegaMenuOpen}
            navigationRef={navigationRef}
            scrolled={scrolled}
            onToggle={toggleMegaMenu}
            handleClickToClose={handleClickToClose}
          />
        )}
      </div>
      <SiteHeaderOverlay isActive={isMegaMenuOpen} />
    </div>
  );
});

DropDownNav.displayName = 'DropDownNav';

DropDownNav.propTypes = {
  scrolled: bool,
};

DropDownNav.defaultProps = {
  scrolled: false,
};

export default DropDownNav;
