import { apiCallback } from 'api';

const definition = {
  service: 'marketing-preferences',
  endpoint: 'marketingpreferences/${marketingScheme}',
  query: {
    clientType: 'WEB_APP',
  },
  verbs: ['get', 'put', 'post'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(definition, 'get'),
  put: apiCallback(definition, 'put'),
  post: apiCallback(definition, 'post'),
};
