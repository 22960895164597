import trolleyItems from 'api/definitions/trolleyItems';

import {
  CREATE_NEW_TROLLEY_ITEM,
  TROLLEY_QUEUE_ITEM_UPDATING,
  TROLLEY_QUEUE_ITEM_UPDATED,
  TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
} from 'redux/modules/trolley/actions/types';

import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';
import { redirectToLogin } from 'utils/login';
import { trackPreLoginInteraction } from 'analytics/preLoginInteractionsTracking';
import { saveItemToAddToTrolleyPostAuthentication } from 'utils/trolley';

export default ({
    lineNumber,
    personalisedMessage,
    productId,
    quantity: rawQuantity,
    clickContext,
    productPosition,
    searchType,
  }) =>
  async (dispatch, getState) => {
    if (!isUserLoggedIn(getState())) {
      trackPreLoginInteraction('Add to Cart');

      saveItemToAddToTrolleyPostAuthentication({
        lineNumber,
        personalisedMessage,
        productId,
        quantity: rawQuantity,
        clickContext,
        productPosition,
        searchType,
      });

      redirectToLogin();
      return;
    }

    const quantity = formatQuantity(rawQuantity);
    const newItem = {
      lineNumber,
      personalisedMessage,
      productId,
      quantity,
      trolleyItemId: -Number.parseInt(lineNumber, 10),
    };

    const analytics = {
      decrement: false,
      lineNumber,
      uom: quantity.uom,
      clickContext,
      productPosition,
    };

    dispatch({
      trolleyItem: {
        analytics: {
          quantity,
        },
        ...newItem,
      },
      type: CREATE_NEW_TROLLEY_ITEM,
    });

    dispatch({
      analytics,
      queue: true,
      lineNumber,
      types: [
        TROLLEY_QUEUE_ITEM_UPDATING,
        TROLLEY_QUEUE_ITEM_UPDATED,
        TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
      ],
      apiCall: trolleyItems.addItem({
        body: newItem,
      }),
      callback: {
        success: response => {
          const { data, errors } = response;
          const failures = data?.addItemToTrolley?.failures || [];
          if (errors) {
            throw new Error('Graph returned a add item to trolley error');
          }
          if (failures.length > 0) {
            const error = new Error(failures[0].message);
            error.type = failures[0].type;
            throw error;
          }
          return {
            ...data.addItemToTrolley,
          };
        },
      },
    });
  };
