import { CampaignAPI, CampaignName } from 'api/definitions/slotdates/types';
import { Campaign } from 'redux/modules/slot-dates/state/initial-state';

export function mapSeasonalRanges(
  campaigns: CampaignAPI[],
): Partial<Record<CampaignName, Campaign>> {
  return campaigns?.reduce((rangesObject, campaign) => {
    const { name, marketingStartDate, marketingEndDate, startDate, endDate } = campaign;
    return Object.assign({}, rangesObject, {
      [name]: {
        seasonDescription: name,
        seasonalStartDate: marketingStartDate,
        seasonalEndDate: marketingEndDate,
        siteStartDate: startDate,
        siteEndDate: endDate,
      },
    });
  }, {});
}
