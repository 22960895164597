import { normalize as normalizr } from 'normalizr';

import { mergeEntities } from 'redux/modules/entities/actions/merge-entities';
import { transforms } from 'redux/transforms';
import { schemas } from 'redux/schemas';

export const processResult = ({ dispatch, normalize = normalizr, result, schema, transform }) => {
  let data = result;

  if (transform) {
    data = transforms[transform](data);
  }

  if (schema) {
    data = normalize(data, schemas[schema]) || {};
    const isNormalised = 'entities' in data && 'result' in data;

    if (isNormalised) {
      dispatch(mergeEntities(data.entities));
      data = data.result;
    }
  }

  return data;
};
