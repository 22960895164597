const dummyMap = new Map();

const AsyncLocalStorage = __SERVER__
  ? // eslint-disable-next-line @typescript-eslint/no-require-imports
    require('async_hooks').AsyncLocalStorage
  : function AsyncLocalStorage() {
      return {
        getStore: () => dummyMap,
      };
    };

export const asyncLocalStorage = new AsyncLocalStorage();
