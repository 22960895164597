import React from 'react';
import PropTypes from 'prop-types';

import styles from './SiteHeaderOverlay.scss';

const SiteHeaderOverlay = ({ isActive }) => {
  return <div className={styles[isActive ? 'overlayActive' : 'overlay']} />;
};

SiteHeaderOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default SiteHeaderOverlay;
