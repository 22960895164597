import root from 'window-or-global';

export type ValueGetter<T, TArg> = (args: TArg) => T | null | undefined;

export interface WaitForValueOptions {
  /** AbortSignal to cancel the watch. When aborted, the promise will be rejected with the abort reason */
  abortSignal?: AbortSignal;
  /** The interval of when the value should be checked */
  checkInterval?: number;
}

export const waitForValue = <T, Arg>(
  valueGetter: ValueGetter<T, Arg>,
  getterArg: Arg,
  { abortSignal, checkInterval = 500 }: WaitForValueOptions = {},
) => {
  let intervalId: number | NodeJS.Timeout;

  return new Promise<T>((resolve, reject) => {
    const check = () => {
      if (abortSignal?.aborted) {
        reject(abortSignal.reason ?? new DOMException('AbortError'));
      } else {
        const value = valueGetter(getterArg);

        if (value) {
          resolve(value);
          return true;
        }
      }

      return false;
    };

    if (!check()) {
      intervalId = root.setInterval(check, checkInterval);
    }
  }).finally(() => {
    if (intervalId) {
      root.clearInterval(intervalId as number);
    }
  });
};
