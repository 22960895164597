import { migrateJotter } from 'redux/modules/multi-search/actions/migrate-jotter';

import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

import { createStoreSubscriber } from './create';
import { isNowTrue } from './matchers';

export const loginListener = store =>
  createStoreSubscriber({
    match: isNowTrue,
    once: true,
    onChange: migrateJotter,
    select: isUserLoggedIn,
    store,
  });
