import React from 'react';
import classNames from 'classnames';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import {
  AddBold as AddIcon,
  MinusBold as MinusIcon,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './index.scss';

export interface ItemButtonProps {
  productName: string;
  isDisabled: boolean;
  handleDecrement: () => void;
  handleIncrement: () => void;
  isRecentlyUpdated: boolean;
  isEditingAmount: boolean;
  handleUpdateClick: () => void;
  inputValue: number;
}

const ItemButtons = ({
  productName,
  isDisabled,
  handleDecrement,
  handleIncrement,
  isRecentlyUpdated,
  isEditingAmount,
  handleUpdateClick,
  inputValue,
}: ItemButtonProps) => {
  return (
    <div data-testid="item-buttons">
      <div className={classNames(styles.updateBtn, { [styles.hide]: !isEditingAmount })}>
        {isRecentlyUpdated ? (
          <div className={styles.updated}>Updated</div>
        ) : (
          <Button
            aria-label={`Update ${productName}`}
            data-testid="updateButton"
            disabled={isDisabled}
            onClick={handleUpdateClick}
            label="Update"
            type="button"
            theme="secondary"
            width="fit"
          />
        )}
      </div>

      <div className={classNames(styles.quantityButtons, { [styles.hide]: isEditingAmount })}>
        <Button
          aria-label={`Decrement ${productName}`}
          data-testid="minusButton"
          disabled={isDisabled || inputValue <= 0}
          onClick={handleDecrement}
          startIcon={<MinusIcon />}
          type="button"
          theme="primary"
          width="fit"
        />
        <Button
          aria-label={`Increment ${productName}`}
          data-testid="plusButton"
          disabled={isDisabled}
          onClick={handleIncrement}
          startIcon={<AddIcon />}
          type="button"
          theme="primary"
          width="fit"
        />
      </div>
    </div>
  );
};

export default ItemButtons;
