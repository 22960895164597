import { createSelector } from 'reselect';

import { getProductById, getProductByLineNumber } from 'redux/modules/entities/selectors/products';

const getCategoryName = ({ name } = {}) => name;
const getProductCategories = ({ categories = [] } = {}) => categories;

const getProductFinalCategory = createSelector([getProductCategories], categories =>
  categories.length > 0 ? getCategoryName(categories[categories.length - 1]) : undefined,
);

export const getProductCategoriesById = createSelector(
  getProductById,
  product => product?.categories || [],
);

export const getProductCategoriesByLineNumber = createSelector(
  getProductByLineNumber,
  product => product?.categories || [],
);

export const getProductCategoryNames = createSelector(getProductCategories, categories =>
  categories.map(category => getCategoryName(category)),
);

export const getProductFinalCategoryById = createSelector(getProductById, product =>
  getProductFinalCategory(product),
);

export const getProductG1IdByLineNumber = createSelector(
  getProductCategoriesByLineNumber,
  ([, { id } = {}] = []) => id,
);

export const getProductG1NameByLineNumber = createSelector(
  getProductCategoriesByLineNumber,
  ([, { name } = {}] = []) => name,
);
