import memberships from 'api/definitions/memberships';

import { getMembershipActionTypes, REMOVE_MARKETING_PREFERENCES } from './types';

export const getMembershipStatus = membershipPreferences => ({
  types: getMembershipActionTypes.triplet,
  apiCall: memberships.get({ membershipPreferences }),
});

export const removeMarketingPreferences = () => ({
  type: REMOVE_MARKETING_PREFERENCES,
});
