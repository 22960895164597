import PropTypes from 'prop-types';

export const ProductPromotion = () => ({
  myWaitrosePromotion: PropTypes.bool,
  promotionDescription: PropTypes.string,
  promotionExpiryDate: PropTypes.string,
  promotionId: PropTypes.number,
  promotionTypeCode: PropTypes.string,
  wasDisplayPrice: PropTypes.string,
});

const ProductQuantity = () => ({
  amount: PropTypes.number,
  uom: PropTypes.string,
});

export const ProductCurrentSaleUnitPrice = PropTypes.shape({
  price: PropTypes.shape({
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
  quantity: PropTypes.shape(ProductQuantity()),
});

export const SearchProduct = () => ({
  currentSaleUnitPrice: ProductCurrentSaleUnitPrice,
  customerProductDetails: PropTypes.shape({
    customerFavourite: PropTypes.bool,
  }),
  defaultQuantity: PropTypes.shape(ProductQuantity()),
  displayPrice: PropTypes.string,
  displayPriceEstimated: PropTypes.bool,
  displayPriceQualifier: PropTypes.string,
  formattedPriceRange: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  promotions: PropTypes.arrayOf(PropTypes.number),
  pricePerUnit: PropTypes.string,
  productType: PropTypes.string,
  reviews: PropTypes.shape({
    averageRating: PropTypes.number,
    reviewCount: PropTypes.number,
  }),
  thumbnail: PropTypes.string,
  wasDisplayPrice: PropTypes.string,
  weights: PropTypes.shape({
    uoms: PropTypes.arrayOf(PropTypes.string),
  }),
});
