import { session } from 'utils/storage';
import { ADD_TO_FAVOURITES_POST_AUTHENTICATION_KEY } from 'constants/shared-session-storage';

export const saveItemToFavouritePostAuthentication = payload =>
  session.setJson(ADD_TO_FAVOURITES_POST_AUTHENTICATION_KEY, payload);

export const getItemToFavouritePostAuthentication = () =>
  session.getJson(ADD_TO_FAVOURITES_POST_AUTHENTICATION_KEY);

export const removeItemToFavouritePostAuthentication = () =>
  session.remove(ADD_TO_FAVOURITES_POST_AUTHENTICATION_KEY);
