import root from 'window-or-global';

export const clearTimeout = (id: ReturnType<typeof root.setTimeout>) => root.clearTimeout(id);

export const setTimeout = (func: (...args: unknown[]) => void, delay: number) =>
  root.setTimeout(func, delay);

export const waitFor = (delay: number) =>
  new Promise(resolve => setTimeout(resolve.bind(null, delay), delay));

export const requestIdleCallback = (
  callback: IdleRequestCallback,
  options?: IdleRequestOptions,
) => {
  if (root.requestIdleCallback) {
    return root.requestIdleCallback(callback, options);
  }

  return root.setTimeout(callback, 0);
};
