import root from 'window-or-global';

import { BREAKPOINTS } from 'constants/grid';

// We need to eliminate this whole module and stop doing JS responsiveness for SSR's sake
let currentBreakpointEqualOrGreaterThan = breakpoint =>
  root.matchMedia(`(min-width: ${breakpoint[1]}px)`).matches;

const SSR_BREAKPOINT = 'lg';
if (__SERVER__) {
  currentBreakpointEqualOrGreaterThan = breakpoint =>
    BREAKPOINTS[breakpoint] >= BREAKPOINTS[SSR_BREAKPOINT];
}

const getBodyPropertyValue = () =>
  root
    .getComputedStyle(document.querySelector('body'), ':before')
    .getPropertyValue('content')
    .replace(/"/g, '');

export const getCurrentBreakpoint = () =>
  Object.entries(BREAKPOINTS)
    .filter(currentBreakpointEqualOrGreaterThan)
    .map(([breakpoint]) => breakpoint)
    .pop();

export function mediaBreakpointCurrent() {
  if (__SERVER__) return SSR_BREAKPOINT;
  return getBodyPropertyValue() || 'lg';
}

function mediaBreakpointPosition(breakpoint) {
  return Object.keys(BREAKPOINTS).indexOf(breakpoint || mediaBreakpointCurrent());
}

export function mediaBreakpointUp(breakpoint) {
  return mediaBreakpointPosition() >= mediaBreakpointPosition(breakpoint);
}

export function mediaBreakpointDown(breakpoint) {
  return mediaBreakpointPosition() <= mediaBreakpointPosition(breakpoint);
}

export function mediaBreakpointOnly(breakpoint) {
  return mediaBreakpointUp(breakpoint) && mediaBreakpointDown(breakpoint);
}

export function mediaBreakpointBetween(minBreakpoint, maxBreakpoint) {
  return mediaBreakpointUp(minBreakpoint) && mediaBreakpointDown(maxBreakpoint);
}

export const getBreakpointName = (breakpoint = {}) =>
  breakpoint.name === 'default' ? 'xl' : breakpoint.name;

export const isValidAtBreakpoint = (
  componentBreakpoints,
  currentBreakpoint = getCurrentBreakpoint(),
) =>
  componentBreakpoints.filter(
    breakpoint => getBreakpointName(breakpoint) === currentBreakpoint && breakpoint.visible,
  ).length > 0;
