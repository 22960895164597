import { getCustomerId } from 'redux/modules/sessions/selectors';

const buildAnalytics = method => ({
  event: 'mywaitrose_api_error',
  method,
});

const putCardFailure = state => ({ ...buildAnalytics('PUT', state) });

const getCardByIdFailure = state => ({ ...buildAnalytics('GETBYID', state) });

const deleteCardFailure = state => ({ ...buildAnalytics('DELETE', state) });

const postCardFailure = state => ({ ...buildAnalytics('POST', state) });

const postCardSuccess = state => ({
  event: 'join_mywaitrose_success',
  customerId: getCustomerId(state),
});

const putCardSuccess = state => ({
  event: 'link_mywaitrose_success',
  customerId: getCustomerId(state),
});

const deleteCardSuccess = state => ({
  event: 'leave_mywaitrose_success',
  customerId: getCustomerId(state),
});

export {
  putCardFailure,
  getCardByIdFailure,
  deleteCardFailure,
  postCardFailure,
  postCardSuccess,
  putCardSuccess,
  deleteCardSuccess,
};
