export const all =
  (...fetchers) =>
  args => {
    if (args.preloadOnly) {
      fetchers.filter(filter => filter).forEach(fetcher => fetcher(args));
      // This function shouldn't ever be executed but it makes the TypeScript types simpler by returning it as there is commonality in return types in this function
      return () => {};
    }
    return (dispatch, getState) =>
      Promise.all(
        fetchers.filter(filter => filter).map(fetcher => fetcher(args)(dispatch, getState)),
      );
  };
