import {
  POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH,
  POD_SLIDEDOWN_ADVERT_RECIPES,
  POD_SLIDEDOWN_ADVERT_RELATED,
} from 'constants/monetateExperiments';

const podSlidedownAdvertExperimentKeys = [
  POD_SLIDEDOWN_ADVERT_RECIPES.experiment,
  POD_SLIDEDOWN_ADVERT_RELATED.experiment,
  POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH.experiment,
];

const formatPodSlidedownAdvert = payloads => {
  const productIdMap = {};

  podSlidedownAdvertExperimentKeys.forEach(experimentKey => {
    const payload = payloads?.[experimentKey] ?? {};

    Object.values(payload).forEach(({ productIds, ...variationMetadata }) => {
      productIds.forEach(productId => {
        productIdMap[productId] = { experimentKey, ...variationMetadata };
      });
    });
  });

  return productIdMap;
};

export const saveExperiments = (
  state,
  { decisions, payloads, impressions, bypassMonetate, preview, ipAddress },
) => {
  const combinedPayloads = {
    ...state.payloads,
    ...payloads,
  };

  return {
    ...state,
    hasDecisions: true,
    decisions: {
      ...state.decisions,
      ...decisions,
    },
    payloads: {
      ...combinedPayloads,
      podSlidedownAdvert: formatPodSlidedownAdvert(combinedPayloads),
    },
    impressions: {
      ...state.impressions,
      ...impressions,
    },
    bypassMonetate: bypassMonetate ?? state.bypassMonetate,
    preview: preview ?? state.preview,
    ipAddress: ipAddress ?? state.ipAddress,
  };
};
