import React from 'react';
import { pure } from 'recompose';

import Warrant from 'components/Warrant';

const QueenWarrant = pure(() => (
  <Warrant
    armsUrl="https://waitrose-prod.scene7.com/is/image/waitroseprod/queen-2?$Waitrose-Image-Preset-95$"
    byAppointmentTo="Her Majesty The Queen"
  />
));

QueenWarrant.displayName = 'QueenWarrant';

export default QueenWarrant;
