import React, { memo } from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import urls from 'constants/urls';

import wcsConfig from 'utils/wcs-config';

import styles from './LinksBar.scss';

const AnchorLink = ({ children, className, id, href }) => (
  <a className={className} id={id} href={href}>
    {children}
  </a>
);
AnchorLink.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  id: string,
  href: string.isRequired,
};

AnchorLink.defaultProps = {
  className: undefined,
  id: undefined,
};

const LinksBar = memo(() => (
  <div className={styles.siteHeaderLinks}>
    <nav className={styles.primaryLinks} aria-label="Main navigation">
      <AnchorLink
        className={styles.links}
        id="site-header-entertaining"
        href={urls.entertainingHome}
      >
        Entertaining
      </AnchorLink>
      <AnchorLink className={styles.offers} id="site-header-offers" href={urls.offers}>
        Offers
      </AnchorLink>
      <AnchorLink id="site-header-new" className={styles.links} href={urls.new}>
        New
      </AnchorLink>
      <AnchorLink id="site-header-recipes" className={styles.links} href="/ecom/recipes">
        Recipes
      </AnchorLink>
      <AnchorLink
        id="site-header-inspiration"
        className={styles.links}
        href={wcsConfig.inspirationUrl()}
      >
        Inspiration
      </AnchorLink>
    </nav>
  </div>
));

LinksBar.displayName = 'Links';

export default LinksBar;
