import env from 'env/env';

export default {
  searchPath: `${env.root}/shop/search`,
  productImage: '//ecom-su-static-prod.wtrecom.com/images/products',
};

const SIX_SECONDS = 6 * 1000;
const FIFTEEN_SECONDS = 15 * 1000;
const THIRTY_SECONDS = 30 * 1000;
const TWENTY_FIVE_MINUTES = 25 * 60 * 1000;

export const MAX_503_RETRY_DELAY = THIRTY_SECONDS;

export const REQUEST_TIMEOUT_RESPONSE = FIFTEEN_SECONDS; // The default time after which an API request will be aborted (milliseconds)
export const REQUEST_TIMEOUT_DEADLINE = THIRTY_SECONDS; // The default time after which an API request will be aborted even if it has received some data (milliseconds)

export const WAITING_SLOW_TIMEOUT = SIX_SECONDS;
export const WAITING_TIMEOUT = THIRTY_SECONDS;

export const PAYMENT_CARD_SESSION_TIMEOUT = TWENTY_FIVE_MINUTES; // Note: the verifone session expires after 30 minutes
