import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import ViewAlternatives from '../Resolution/ViewAlternatives';

import styles from './styles.scss';

const ViewAlternativesOrRemove = memo(
  ({ isInTrolley, removeFromTrolley, productId, conflictMessage, position }) =>
    isInTrolley && removeFromTrolley ? (
      <div className={styles.removeButton}>
        <Button width="full" onClick={removeFromTrolley} label="Remove from trolley" />
      </div>
    ) : (
      <ViewAlternatives
        conflictMessage={conflictMessage}
        productId={productId}
        position={position}
      />
    ),
);

ViewAlternativesOrRemove.propTypes = {
  isInTrolley: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  removeFromTrolley: PropTypes.func,
  conflictMessage: PropTypes.string,
  position: PropTypes.number,
};

ViewAlternativesOrRemove.defaultProps = {
  removeFromTrolley: undefined,
  isInTrolley: false,
  conflictMessage: undefined,
  position: undefined,
};

export default ViewAlternativesOrRemove;
