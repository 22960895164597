import { LOCATION_CHANGE } from 'redux-first-history';
import { getPathname } from 'redux/modules/routing/selectors';

/**
 * This middleware fixes a very specific issue with `redux-first-history`.
 * The behaviour of `redux-first-history` is to trigger a `POP` event when the page loads, and that populates the initial router state.
 *
 * The problem is when receiving a preloaded state from the server,
 * it would contain data about the router, but, a new `POP` event would still be dispatched,
 * causing reducers to trigger actions when they shouldn't, sometimes clearing needed data.
 *
 * This middleware will ignore the first `LOCATION_CHANGE` event received if a `pathname` already exists in the store.
 * The `pathname` won't exist in the server at first and won't be present if the page was entirely rendered by the client,
 * allowing the route to be initially populated.
 */
export default ({ getState }) =>
  next => {
    let hasReceivedOneLocationChangeEvent = false;
    return action => {
      if (!hasReceivedOneLocationChangeEvent && action.type === LOCATION_CHANGE) {
        hasReceivedOneLocationChangeEvent = true;

        if (getPathname(getState())) {
          return;
        }
      }

      next(action);
    };
  };
