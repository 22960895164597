import React, { useEffect, useRef, useState } from 'react';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import Textarea from 'components/Form/Textarea';
import withMaxLengthList from 'components/Form/with-max-length-list';
import styles from './ListModal.scss';

const TextareaWithMaxLengthList = withMaxLengthList(Textarea);
const diffView = 208; // 208 = modal header + footer height + textarea padding

const ListModal = ({
  bodyText,
  customButtons,
  flexButtons,
  isOpen,
  label,
  maxListLength,
  mobile,
  placeholder,
  text,
  onTextChange,
  onClose,
  titleText,
}) => {
  const textareaRef = useRef();
  const [resetHeight, setResetHeight] = useState(false);
  const [minHeight, setMinHeight] = useState();

  const buttons = !Array.isArray(customButtons)
    ? customButtons({
        enableSaveButton: text.length > 0,
      })
    : customButtons;

  const modalProps = {
    buttonAutoFocus: false,
    buttons,
    handleModalClose: ({ value: type }) => {
      if (type === ModalCloseOptions.CLEAR_LIST) {
        setResetHeight(true);
        textareaRef.current.focus();
      }
      onClose(type);
    },
    dataTest: 'multi-search-modal',
    flexButtons,
    isOpen,
    fullScreen: mobile,
    scrollable: mobile,
    titleText,
  };

  const textAreaProps = {
    autoExpand: true,
    label,
    list: true,
    maxLength: maxListLength,
    minHeight: minHeight && `${minHeight}px`,
    message: 'Item limit reached',
    onTextChange,
    placeholder,
    ref: textareaRef,
    resetHeight,
    rows: 5,
    text,
  };

  useEffect(() => {
    // the textarea should have be full height of content
    // the viewport (vh unit in css) is responsive on android and fixed in iOS
    // so, javascript was the solution to keep the textarea with a constant full height
    const newHeight = window?.innerHeight - diffView;
    if (mobile && minHeight !== newHeight) {
      setMinHeight(newHeight);
    }
  }, [mobile, minHeight]);

  useEffect(() => {
    if (resetHeight) setResetHeight(false);
  }, [resetHeight]);

  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      // scrollTop after keyboard is open
      timeoutId = setTimeout(() => {
        document.querySelector("[data-test='multi-search-modal']").scrollTop = 0;
      }, 300);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <Modal {...modalProps}>
      <div className={styles.content}>
        <TextareaWithMaxLengthList {...textAreaProps} />
        {bodyText && <p>{bodyText}</p>}
      </div>
    </Modal>
  );
};

ListModal.propTypes = {
  bodyText: string,
  customButtons: oneOfType([array, func]).isRequired,
  flexButtons: bool,
  isOpen: bool,
  label: string,
  maxListLength: number.isRequired,
  mobile: bool,
  onTextChange: func.isRequired,
  onClose: func.isRequired,
  placeholder: string,
  text: string,
  titleText: string,
};

ListModal.defaultProps = {
  bodyText: null,
  flexButtons: false,
  isOpen: false,
  label: null,
  mobile: false,
  placeholder: null,
  text: '',
  titleText: null,
};

export default ListModal;
