export const links = [
  {
    name: 'Cellar',
    tracking: 'topnav_control_cellar',
    url: 'http://www.waitrosecellar.com/',
  },
  {
    name: 'Garden',
    tracking: 'topnav_control_garden',
    url: 'http://www.waitrosegarden.com/',
  },
];
