import {
  ADD_TO_TROLLEY_DESCRIPTION,
  REMOVE_FROM_TROLLEY_DESCRIPTION,
} from 'redux/middleware/analytics/actions-to-track/trolley/constants';
import getCompleteMealDealEvent from './utils/complete-meal-deal';
import changeCartTransform from './change-cart';

const updateCartTransform = (state, { analytics }) => {
  if (analytics.decrement) {
    return changeCartTransform(state, [{ analytics }], 'remove', REMOVE_FROM_TROLLEY_DESCRIPTION);
  }

  const completeMealDealEvent = getCompleteMealDealEvent(state, analytics.lineNumber);

  const changeCartEvents = changeCartTransform(
    state,
    [{ analytics }],
    'add',
    ADD_TO_TROLLEY_DESCRIPTION,
  );

  return completeMealDealEvent ? [...changeCartEvents, completeMealDealEvent] : changeCartEvents;
};

export default updateCartTransform;
