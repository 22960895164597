import React, { useMemo, Suspense } from 'react';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';

export default () => {
  const C = useMemo(() => {
    if (!__SERVER__) {
      const Component = React.lazy(() => import('./ExperimentsReporter'));
      return () => (
        <SimpleErrorBoundary logger="experiments-reported" section="App">
          <Suspense fallback={<></>}>
            <Component />
          </Suspense>
        </SimpleErrorBoundary>
      );
    }
    return () => null;
  }, []);

  return <C />;
};
