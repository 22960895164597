import { apiCallback } from 'api';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';
import gql from 'fake-tag';

const query = gql`
  mutation ($customerOrderId: ID!, $GiftVoucherInput: [GiftVoucherInput!]!) {
    setGiftVouchers(customerOrderId: $customerOrderId, giftVouchers: $GiftVoucherInput) {
      failures {
        type
      }
      order {
        estimatedTotals {
          deliveryCharge {
            amount
            currencyCode
          }
          giftCards {
            amount
            currencyCode
          }
          giftVouchers {
            amount
            currencyCode
          }
          incentiveSavings {
            amount
            currencyCode
          }
          membershipSavings {
            amount
            currencyCode
          }
          offerSavings {
            amount
            currencyCode
          }
          orderLevelSavings {
            amount
            currencyCode
          }
          orderLines {
            amount
            currencyCode
          }
          paymentCard {
            amount
            currencyCode
          }
          totalSavings {
            amount
            currencyCode
          }
          toPay {
            amount
            currencyCode
          }
          totalPrice {
            amount
            currencyCode
          }
        }
        giftVouchers {
          serialNumber
          status
          value {
            amount
          }
        }
      }
    }
  }
`;

const definition = {
  graph: true,
  query: () => query,
  variables: ({ args }) => args,
  queryString: { tag: 'set-giftVouchers' },
  timeout: TIMEOUT_CONFIG,
};

export default {
  post: apiCallback(definition, 'post'),
};
