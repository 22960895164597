import { createSelector } from 'reselect';
import get from 'lodash/get';

const getSearchAndBrowse = ({ searchAndBrowse } = {}) => searchAndBrowse;

const getSearchAndBrowseSearchTypeHistoric = createSelector(
  [getSearchAndBrowse],
  ({ searchTypeHistoric } = {}) => searchTypeHistoric,
);

const getSearchQueryParams = createSelector([getSearchAndBrowse], searchAndBrowse =>
  get(searchAndBrowse, 'request.body.customerSearchRequest.queryParams', {}),
);

export const getCustomerSearchTerm = createSelector(
  [getSearchQueryParams],
  ({ searchTerm = '' }) => searchTerm,
);

export const getSearchAndBrowseSearchType = createSelector(
  [getSearchAndBrowse],
  ({ searchType } = {}) => searchType,
);

export const isPreviousSearch = createSelector(
  [getSearchAndBrowseSearchTypeHistoric],
  (searchTypeHistoric = []) => searchTypeHistoric[searchTypeHistoric.length - 2] === 'search',
);
