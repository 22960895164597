import { DELETE_BY_ID, REPORT_DELETE_SUCCESS } from 'redux/modules/address/actions/types';

const deleteAddressByIdRequest = state => ({
  ...state,
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const deleteAddressByIdSuccess = (state, action) => {
  const { addresses = [] } = state;
  const { id } = action;
  const updatedAddresses = addresses.filter(address => address.id !== id);
  return {
    ...state,
    addressInError: null,
    addresses: updatedAddresses.slice(),
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const deleteAddressByIdFailure = (state, action) => ({
  ...state,
  editAddressLoading: false,
  request: {
    loading: false,
    loaded: false,
    error: action.error,
  },
});

const reportDeleteSuccess = state => ({ ...state });

export default map => {
  map.set(DELETE_BY_ID.request, deleteAddressByIdRequest);
  map.set(DELETE_BY_ID.success, deleteAddressByIdSuccess);
  map.set(DELETE_BY_ID.failure, deleteAddressByIdFailure);
  map.set(REPORT_DELETE_SUCCESS, reportDeleteSuccess);
  return map;
};
