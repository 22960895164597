import { apiCallback } from 'api';

import query from 'api/definitions/shoppingContext/shoppingContext.graphql';

const definition = {
  graph: true,
  query: () => query,
};

export default {
  get: apiCallback(definition, 'post'),
};
