import { networkErrorModal } from 'constants/modals';
import { openModal } from 'redux/modules/common-modal/actions';
import locator from 'utils/locator';

export const showGenericNetworkErrorModal = (dispatch: WtrDispatch) => {
  dispatch(
    openModal({
      ...networkErrorModal,
      cannotClose: true,
      onCloseCallback: () => {
        locator.reload();
      },
    }),
  );
};
