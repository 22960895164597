import { GET_BY_ID } from 'redux/modules/address-suggestion/actions/types';

const findByIdRequest = state => ({
  ...state,
  getAddressLoading: true,
});

const findByIdSuccess = (state, action) => {
  const address = action.result || {};
  return {
    ...state,
    address,
    searchValues: [],
    modifiedFields: [],
    getAddressLoading: false,
  };
};

const findByIdFailure = (state, action) => ({
  ...state,
  getAddressLoading: false,
  error: action.error,
});

export default map => {
  map.set(GET_BY_ID.request, findByIdRequest);
  map.set(GET_BY_ID.success, findByIdSuccess);
  map.set(GET_BY_ID.failure, findByIdFailure);
  return map;
};
