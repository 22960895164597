import { connect } from 'react-redux';

import {
  getFooterData,
  getIsFooterLoading,
  getTaxonomyLevel,
} from 'redux/modules/taxonomy/selectors';

import SiteFooterMinimal from './SiteFooterMinimal';

export default connect(state => ({
  data: getFooterData(state),
  loading: getIsFooterLoading(state),
  taxonomyLevel: getTaxonomyLevel(state),
}))(SiteFooterMinimal);
