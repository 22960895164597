import { getOrder } from 'redux/modules/order/selectors';

const changeSlotApiSuccess = state => {
  const orderState = getOrder(state);
  const customerOrderId = orderState?.customerOrderId;

  return {
    event: 'change_slot_started',
    ecommerce: {
      checkout: {
        actionField: {
          id: customerOrderId,
        },
      },
    },
  };
};

const changeSlotApiError = error => ({
  event: 'change_slot_error',
  error,
});

export const checkChangeSlotByResponse = (state, payload) => {
  const {
    result: {
      data: { amendOrder },
    },
  } = payload;

  if (amendOrder?.failures) return changeSlotApiError(amendOrder.failures);
  return changeSlotApiSuccess(state);
};
