import mutableMergeWith from 'lodash/mergeWith';

const customizer = (_existingValue, newValue) => newValue;

const mergeEntity = (state, entityGroup) => {
  const entityGroupKeys = Object.keys(entityGroup);

  if (!entityGroupKeys.length) {
    return state;
  }

  return {
    ...state,
    ...entityGroupKeys.reduce((entityGroupState, entityGroupKey) => {
      const stateEntity = state?.[entityGroupKey];
      const newEntity = entityGroup[entityGroupKey];

      // eslint-disable-next-line no-param-reassign
      entityGroupState[entityGroupKey] = mutableMergeWith({}, stateEntity, newEntity, customizer);

      return entityGroupState;
    }, {}),
  };
};

export const merge = (state, newEntities) => {
  const newEntitiesKeys = Object.keys(newEntities);

  if (!newEntitiesKeys.length) {
    return state;
  }

  return {
    ...state,
    ...newEntitiesKeys.reduce((entitiesState, entityName) => {
      const stateEntityGroup = state?.[entityName];
      const newEntityGroup = newEntities[entityName];

      // eslint-disable-next-line no-param-reassign
      entitiesState[entityName] = mergeEntity(stateEntityGroup, newEntityGroup);

      return entitiesState;
    }, {}),
  };
};
