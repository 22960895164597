import { apiCallback } from 'api';
import orderSlotType from './orderSlotType.graphql';

const graphDef = graphQuery => {
  const query = () => graphQuery;
  const variables = ({ customerOrderId }) => ({
    customerOrderId,
  });

  return {
    graph: true,
    query,
    variables,
  };
};

export default {
  post: apiCallback(graphDef(orderSlotType), 'post'),
};
