import type { CategoryLevelFilter } from 'constants/products';

export const uniqueCategoryLevelFilters = (categoryLevelFilters: CategoryLevelFilter[] = []) => {
  const seen: Record<string, boolean> = {};

  return categoryLevelFilters.filter(({ categoryId }) => {
    if (seen[categoryId]) {
      return false;
    }

    seen[categoryId] = true;

    return true;
  });
};
