import { OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS } from 'redux/modules/trolley/actions/types';

import { getItemByLineNumber } from 'redux/modules/trolley/selectors/get-item';
import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';

export default trolleyItems => (dispatch, getState) => {
  const state = getState();
  const newTrolleyItems = trolleyItems.map(
    ({
      batchId,
      lineNumber,
      productId,
      quantity: rawQuantity,
      clickContext,
      processing = false,
      canSubstitute = true,
      noteToShopper,
      productPosition,
      searchType,
    }) => {
      const oldTrolleyItem = getItemByLineNumber(state, lineNumber);
      const newQuantity = rawQuantity ? formatQuantity(rawQuantity) : oldTrolleyItem?.quantity;
      return {
        ...oldTrolleyItem,
        batchId,
        canSubstitute: canSubstitute !== undefined ? canSubstitute : oldTrolleyItem?.canSubstitute,
        lineNumber,
        productId: productId || oldTrolleyItem?.productId,
        quantity: newQuantity,
        trolleyItemId: oldTrolleyItem
          ? oldTrolleyItem.trolleyItemId
          : -Number.parseInt(lineNumber, 10),
        analytics: {
          quantity: newQuantity,
          clickContext,
          productPosition,
          searchType,
        },
        processing,
        noteToShopper: noteToShopper !== undefined ? noteToShopper : oldTrolleyItem?.noteToShopper,
      };
    },
  );

  dispatch({
    trolleyItems: newTrolleyItems,
    type: OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS,
  });
};
