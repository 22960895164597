import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronRightBold } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from 'components/Product/Promotion/Description/PromotionDescription.scss';

const SimplePromotionDescription = ({
  description,
  underlineText,
  noMargin,
  backgroundColor,
  isLink,
  withChevron,
}) => {
  return (
    <p
      className={classNames(styles.offerDescription, {
        [styles.noMargin]: noMargin,
        [styles.withBackground]: !!backgroundColor,
        [styles.green]: backgroundColor === 'green',
      })}
    >
      <span className={classNames({ [styles.underline]: underlineText })}>{description}</span>
      {backgroundColor && isLink && withChevron && (
        <ChevronRightBold size="xsmall" className={styles.chevronIcon} />
      )}
    </p>
  );
};

SimplePromotionDescription.displayName = 'SimplePromotionDescription';

SimplePromotionDescription.propTypes = {
  description: PropTypes.string.isRequired,
  underlineText: PropTypes.bool,
  noMargin: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isLink: PropTypes.bool,
  withChevron: PropTypes.bool,
};
SimplePromotionDescription.defaultProps = {
  underlineText: false,
  noMargin: false,
  isLink: false,
  backgroundColor: undefined,
  withChevron: false,
};

export default memo(SimplePromotionDescription);
