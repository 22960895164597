import { createSelector } from 'reselect';

const getAccounts = state => state?.accounts;

export const getIsPendingContactAddressVerification = createSelector(
  getAccounts,
  accounts => accounts?.prompts?.isPendingContactAddressVerification ?? false,
);

export const hasCustomerAccountsInitialLoadCompleted = state =>
  state?.accounts?.request.initialLoadComplete;
