import set from 'lodash/set';

import {
  queryStringToObject,
  typedValueFromString,
  wrapParamInArray,
} from 'utils/query-string-params';

import { getGraphFlags } from './get-graph-flags';

export const graphFlags = () => {
  const { graphflag } = queryStringToObject();
  const flags = getGraphFlags();

  if (graphflag) {
    wrapParamInArray(graphflag).forEach(param => {
      const [key, value] = param.split(':');
      set(flags, key, typedValueFromString(value));
    });
  }

  return flags;
};
