import { apiCallback } from 'api';

const definition = {
  service: 'mywaitrose',
  endpoint: 'mywaitrose/${cardNumber}',
  defaultVersion: 2,
  verbs: ['get', 'put', 'delete'],
};

export default {
  get: apiCallback(definition, 'get'),
  put: apiCallback(definition, 'put'),
  delete: apiCallback(definition, 'delete'),
};
