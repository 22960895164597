import { CLEAR } from 'redux/modules/search/actions/types';
import { clearProducts } from 'redux/modules/search-and-browse/actions/clear-products';

export const clearSearch =
  (maintainProducts = false) =>
  dispatch => {
    dispatch({
      type: CLEAR,
    });

    if (!maintainProducts) dispatch(clearProducts());
  };
