import { createSelector } from 'reselect';

import { getCustomerOrderById } from 'redux/modules/entities/selectors/customer-orders';
import { getOrderData, getOrder } from 'redux/modules/order/selectors';

const EMPTY_OBJECT = {};

const getCurrentOrderEstimatedTotals = createSelector(
  getOrderData,
  ({ totals = {} } = {}) => totals.estimated || EMPTY_OBJECT,
);

const getEstimatedTotalsByCustomerOrderId = createSelector(
  getCustomerOrderById,
  ({ totals = {} } = {}) => totals?.estimated || EMPTY_OBJECT,
);

const getCustomerOrderIdArg = (_state, customerOrderId) => customerOrderId;

export const getEstimatedTotals = createSelector(
  getEstimatedTotalsByCustomerOrderId,
  getCurrentOrderEstimatedTotals,
  getCustomerOrderIdArg,
  (estimatedTotalsByCustomerOrderId, currentOrderEstimatedTotals, customerOrderId) => {
    // use of "totalPrice" field for the estimated total is a FE hack to workaround
    // a BE data migration issue and should be used only when "toPay.amount" is nullish
    const estimated = customerOrderId
      ? estimatedTotalsByCustomerOrderId
      : currentOrderEstimatedTotals;

    const { toPay, totalPrice } = estimated;
    const toPayIsMissing = (toPay?.amount ?? null) === null;

    return {
      ...estimated,
      toPay: toPayIsMissing ? totalPrice : toPay,
    };
  },
);

export const getNumberOfItems = createSelector(
  getOrder,
  ({ numberOfItems = 0 } = EMPTY_OBJECT) => numberOfItems,
);

export const getEstimatedBalanceToPay = createSelector(
  getEstimatedTotals,
  totals => totals?.toPay?.amount ?? 0,
);

export const getEstimatedPaymentCardAmount = createSelector(
  getEstimatedTotals,
  ({ paymentCard = {} } = {}) => paymentCard?.amount || 0,
);

export const getActualTotals = createSelector(
  getCustomerOrderById,
  ({ totals = {} } = {}) => totals?.actual || EMPTY_OBJECT,
);

export const hasActualTotals = createSelector(
  getCustomerOrderById,
  ({ totals = {} } = {}) => !!totals?.actual,
);

export const getHasDeliveryCharge = state =>
  !!state?.order?.order?.totals?.estimated?.deliveryCharge?.amount || false;
