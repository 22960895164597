import update from 'immutability-helper';
import { deepFreeze } from 'utils/deepFreeze';

import {
  GET_ORDER_PRODUCTS_LOADED,
  GET_ORDER_PRODUCTS_LOADING,
  GET_ORDER_LOADING,
} from 'redux/modules/orders/actions/types';

import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';
import {
  CLEAR,
  FAILED,
  NEXTLOADED,
  RESETERROR,
} from 'redux/modules/search-and-browse/actions/types';
import uniqBy from 'lodash/uniqBy';

const initialState = deepFreeze({
  displayedProducts: 0,
  error: false,
  hasMore: false,
  hasResults: false,
  items: [],
  loading: false,
  loadingMore: false,
  totalMatches: 0,
});

export default function productGrid(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR:
      return {
        ...state,
        displayedProducts: 0,
        hasMore: false,
        hasResults: false,
        items: [],
        totalMatches: 0,
      };
    case FAILED: {
      const { start } = action;

      return {
        ...state,
        error: true,
        hasMore: false,
        hasResults: false,
        [start ? 'loadingMore' : 'loading']: false,
      };
    }
    case GET_ORDER_LOADING: {
      const { withProducts } = action;
      if (!withProducts) return state;
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ORDER_PRODUCTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ORDER_PRODUCTS_LOADED: {
      const { result: { products: items = [], totalMatches } = {} } = action;
      const newItems = uniqBy([...state.items, ...items], 'searchProduct');
      const newItemsLength = newItems.length;
      return {
        ...state,
        displayedProducts: newItemsLength,
        hasMore: false, // [BM] needs to be dynamic
        hasResults: newItems.length > 0,
        items: newItems,
        loading: false,
        totalMatches, // [BM] needs to be a comparison against order: ;
      };
    }
    case INTERSTITIALS_FULFILLED:
    case INTERSTITIALS_UPDATED: {
      const {
        result: { componentsAndProducts: items = [], productsInResultset, totalMatches } = {},
        start,
      } = action;

      return {
        ...state,
        displayedProducts: productsInResultset,
        hasMore: start + productsInResultset < totalMatches,
        hasResults: items.length > 0,
        items,
        [start ? 'loadingMore' : 'loading']: false,
        totalMatches,
      };
    }
    case NEXTLOADED: {
      const {
        result: { componentsAndProducts: items = [], productsInResultset, totalMatches } = {},
      } = action;

      const displayedProducts = productsInResultset + state.displayedProducts;

      return {
        ...state,
        displayedProducts,
        hasMore: displayedProducts < totalMatches,
        hasResults: items.length > 0,
        items: update(state.items, { $push: items }),
        loadingMore: false,
        totalMatches,
      };
    }
    case RESETERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
