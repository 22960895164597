import React from 'react';

import { Search } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { getClientPageIsLoaded } from 'redux/modules/page/selectors/get-client-page-is-loaded';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'utils/feature-flags';
import { getSearchAndBrowseLoading } from 'redux/modules/search-and-browse/selectors';

import styles from 'components/SiteHeader/SiteHeader.scss';

type MobileSearchButtonProps = {
  toggleOverlay: () => void;
};

const MobileSearchButton = ({ toggleOverlay }: MobileSearchButtonProps) => {
  const { showSearchLoading } = getFeatureFlags();

  const clientPageIsLoaded = useSelector(getClientPageIsLoaded);
  const productsLoading = useSelector(getSearchAndBrowseLoading);

  const loading = showSearchLoading && (!clientPageIsLoaded || productsLoading);

  return (
    <Button
      aria-label="Search"
      data-testid="searchBtn"
      className={styles.searchBtn}
      onClick={toggleOverlay}
      theme="secondary"
      waiting={loading}
      width="fit"
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="mobileSearchButton">
        <Search />
      </label>
    </Button>
  );
};

export default MobileSearchButton;
