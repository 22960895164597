import { arrayOf, bool, shape, string } from 'prop-types';

const cmsMenuLinkType = shape({
  card: bool,
  href: string,
  id: string,
  name: string,
  newWindow: bool,
  style: string,
  subtext: string,
});

export const cmsMenuLinksType = arrayOf(cmsMenuLinkType);
