import retailSessions from 'api/definitions/retailSessions';

import { getCustomerId } from 'redux/modules/sessions/selectors';
import { createSessionsActionTypes } from './types';

const generateSessionAsync = ({ username, password, customerId }) => ({
  types: createSessionsActionTypes.triplet,
  apiCall: retailSessions.generateSession({
    username,
    password,
    customerId,
    iHandleStatusCodes: [401, 423],
  }),
});

let sessionPromise;

export const generateSession =
  ({ username, password } = {}) =>
  (dispatch, getState) => {
    if (__SERVER__) {
      return dispatch(
        generateSessionAsync({
          username,
          password,
          customerId: getCustomerId(getState()),
        }),
      );
    }

    if (!sessionPromise) {
      sessionPromise = dispatch(
        generateSessionAsync({
          username,
          password,
          customerId: getCustomerId(getState()),
        }),
      );

      sessionPromise
        .catch(() => {})
        .finally(() => {
          sessionPromise = null;
        });
    }

    return sessionPromise;
  };
