const reducer = (state, action) => {
  const { status } = action.error;

  return {
    ...state,
    errorStatusCode: status,
    errorCount: state.errorCount + 1,
    errorSource: state.currentApiRequest,
  };
};

export default reducer;
