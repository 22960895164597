import React, { useCallback, MouseEvent } from 'react';

import AnchorLink from 'components/AnchorLink';
import { OpenLink } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './CMSMenuLink.scss';

type CMSMenuLinkProps = {
  card?: boolean;
  id: string;
  level: number;
  name: string;
  navigateFromMegaMenu: (level: number, name: string, id: string) => void;
  newWindow?: boolean;
  styleAs?: string;
  subtext?: string;
  url?: string;
  handleClickToClose: (event: MouseEvent<HTMLAnchorElement>) => void;
};

type LinkImagesTexts = {
  [key: string]: {
    image: {
      url: string;
    };
    text: string;
    ariaLabel: string;
    className: string;
  };
};

export const linkImagesTexts: LinkImagesTexts = {
  CELLAR: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/wine-2?$Waitrose-Image-Preset-95$&wid=200&fit=constrain,0',
    },
    text: 'Explore more than 1,200 beers, wines and spirits',
    ariaLabel:
      'Waitrose Cellar. Explore more than 1,200 beers, wines and spirits. Opens in new window',
    className: 'imgBox cellar',
  },
  GARDEN: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/garden-1?$Waitrose-Image-Preset-95$&wid=160&fit=constrain,0',
    },
    text: 'All your gardening needs - to plants, seeds, tools & furniture',
    ariaLabel:
      'Waitrose Garden. All your gardening needs - to plants, seeds, tools & furniture. Opens in new window',
    className: 'imgBox garden',
  },
  DISHPATCH: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/dishpatch?$Waitrose-Image-Preset-95$&wid=160&fit=constrain,0',
    },
    text: 'Chef-created set menus, by restaurant-quality meal delivery experts Dishpatch.',
    ariaLabel:
      'Chef-created set menus, by restaurant-quality meal delivery experts Dishpatch. Opens in new window',
    className: 'imgBox garden',
  },
};

const CMSMenuLink = ({
  card,
  id,
  level,
  name,
  navigateFromMegaMenu,
  newWindow,
  styleAs,
  subtext,
  url,
  handleClickToClose,
}: CMSMenuLinkProps) => {
  const closeMegaMenuAction = useCallback(
    event => {
      navigateFromMegaMenu(level, name, id);
      handleClickToClose(event);
    },
    [id, level, name, navigateFromMegaMenu, handleClickToClose],
  );

  const linkProps = {
    className: styles.link,
    'data-actiontype': 'redirect',
    'data-origincomponent': 'megamenu link',
    'data-shortdescription': name,
    'data-test': `mm-${name}`,
    inNewTab: newWindow,
    onClick: closeMegaMenuAction,
    title: name,
    to: url,
  };

  if (card) {
    return (
      <AnchorLink
        {...linkProps}
        aria-label={linkImagesTexts[name].ariaLabel}
        className={styles.card}
      >
        <img
          className={styles.linkImg}
          alt={name}
          src={linkImagesTexts[name].image.url}
          loading="lazy"
          height="80"
          width="80"
        />
        <span className={styles.textWrapper}>
          <span className={styles.name}>{name}</span>
          {subtext && <span className={styles.subtext}>{linkImagesTexts[name].text}</span>}
        </span>
      </AnchorLink>
    );
  }

  if (url) return <AnchorLink {...linkProps}>{name}</AnchorLink>;

  if (styleAs === 'small') {
    return (
      <span className={styles.smallWrapper}>
        {' '}
        <OpenLink size="small" />
        <span className={styles.small}>Each website opens in a new tab.</span>
      </span>
    );
  }

  return name === 'More from <span style="white-space:nowrap">Waitrose &amp; Partners</span>' ? (
    <span className={styles.newHeading}>MORE FROM WAITROSE</span>
  ) : (
    <span className={styles.heading} dangerouslySetInnerHTML={{ __html: name }} />
  );
};

export default CMSMenuLink;
