import locator from 'utils/locator';
import { filterSearchQuery, queryStringToObject } from 'utils/query-string-params';

export const isAdminEditAddress = () => {
  try {
    if (!locator.originValid()) {
      return false;
    }
    const { editAddressId } = queryStringToObject(filterSearchQuery());
    return typeof editAddressId === 'string' && editAddressId.trim() !== 'null';
  } catch (e) {
    return false;
  }
};
