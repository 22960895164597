import { pathJoin } from 'utils/general';

const ROOT_CATEGORIES = ['entertaining', 'groceries', 'offers'];

const INTERNAL_BROWSE_ROOT = '/ecom/shop/browse';

const generateBreadcrumbPath = ({ baseUrl, breadcrumbUrlNames, index }) => {
  const path = breadcrumbUrlNames
    .slice(0, index + 1)
    .join('/')
    .toLowerCase();

  const isRoot = ROOT_CATEGORIES.includes(path);

  return pathJoin(isRoot ? INTERNAL_BROWSE_ROOT : baseUrl, path);
};

export const enrichBreadcrumbs = ({ baseUrl = INTERNAL_BROWSE_ROOT, breadcrumbs }) => {
  if (!breadcrumbs?.length) {
    return [];
  }

  const breadcrumbUrlNames = breadcrumbs.map(crumb => crumb.urlName);

  return breadcrumbs.map((breadcrumb, index) => ({
    ...breadcrumb,
    path: breadcrumb.path || generateBreadcrumbPath({ baseUrl, breadcrumbUrlNames, index }),
  }));
};
