import { UPDATESEARCHTERM } from 'redux/modules/search/actions/types';

import { getSearchQueryFromApiRequest } from 'utils/searchQuery';

export const updateSearchTerm =
  ({ searchTerm, origin }) =>
  (dispatch, getState) => {
    dispatch({
      type: UPDATESEARCHTERM,
      searchTerm,
      origin,
    });

    return getSearchQueryFromApiRequest(getState().search.queryParams);
  };
