import { PATCH_CONTACT_ADDRESS } from '../actions/types';

const updateContactAddressRequest = state => ({
  ...state,
  updateContactAddressError: false,
  request: {
    isLoading: true,
    error: null,
  },
});

const updateContactAddressSuccess = (state, action) => {
  const { contactAddressId, created, email, id, lastLogin, totalOrderCount } = action.result;

  return {
    ...state,
    contactAddressId,
    created,
    email,
    id,
    lastLogin,
    totalOrderCount,
    request: {
      isLoading: false,
      error: null,
    },
  };
};

const updateContactAddressFailure = (state, action) => ({
  ...state,
  updateContactAddressError: true,
  request: {
    isLoading: false,
    error: action.error,
  },
});

export default map => {
  map.set(PATCH_CONTACT_ADDRESS.request, updateContactAddressRequest);
  map.set(PATCH_CONTACT_ADDRESS.success, updateContactAddressSuccess);
  map.set(PATCH_CONTACT_ADDRESS.failure, updateContactAddressFailure);
};
