const RE_MATCH_TEMPLATE_STRING = /\$\{(\w+)\}/g;

const interpolate = (templateString: string, vars: Record<string, unknown>) => {
  const matchGroups = Array.from(templateString.matchAll(RE_MATCH_TEMPLATE_STRING));

  if (!matchGroups.length) {
    return templateString;
  }

  const [[, firstMatchKey]] = matchGroups;

  if (!firstMatchKey) {
    return templateString;
  }

  const firstMatchValue = vars[firstMatchKey];

  if (Array.isArray(firstMatchValue)) {
    return firstMatchValue;
  }

  let outputString = templateString;

  // eslint-disable-next-line no-restricted-syntax
  for (const [templateMatch, matchKey] of matchGroups) {
    outputString = outputString.replaceAll(
      templateMatch,
      vars[matchKey] ? (vars[matchKey] as string) : '',
    );
  }

  return outputString;
};

export default interpolate;
