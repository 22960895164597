import { MarketingSubscriptionOption } from 'constants/marketing-preferences';

export const ALL_CHANNELS_GRANTED = {
  EMAIL: true,
  SMS: true,
};

export const ALL_CHANNELS_DENIED = {
  EMAIL: false,
  SMS: false,
};

export const PRIVACY_POLICY_TITLE = 'Privacy policy';
export const PRIVACY_POLICY_VERSION = 'NA';

export const SHOW_MYWAITROSE_CHECKBOX = false;
export const SHOW_DIVISIONS_CHECKBOX = true;

export const marketingFieldsets = {
  [MarketingSubscriptionOption.WAITROSE]: {
    label: 'Waitrose & Partners',
    helpText:
      'Receive offers, news on latest products, local branch information and in-store events.',
  },
  [MarketingSubscriptionOption.MY_WAITROSE]: {
    label: 'myWaitrose',
    helpText: 'Receive your exclusive offers, vouchers and news on myWaitrose benefits.',
  },
  [MarketingSubscriptionOption.JOHN_LEWIS]: {
    label: 'John Lewis & Partners',
    helpText:
      'Receive emails and texts containing tips, guidance, offers and news on new products and services.',
  },
  [MarketingSubscriptionOption.JOHN_LEWIS_FINANCIAL_SERVICES]: {
    label: 'John Lewis Money',
    helpText:
      'Receive offers on Partnership Card, Foreign Currency and information on other services.',
  },
};

export const sources = {
  checkout: 'existing customer - checkout - set marketing preferences - web',
  myAccountJoinMyWaitrose: 'existing customer - myAccount - join myWaitrose - web',
  updateMarketingPreferences: 'existing customer - myAccount - update marketing preferences - web',
  registration: 'new customer - registration - set marketing preferences - web',
};
