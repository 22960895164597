import {
  addressApiError,
  hideEditDeleteSpinner,
  setSelectedAddress,
  updateContactAddress,
} from 'redux/modules/address/actions';

import { openModal } from 'redux/modules/common-modal/actions';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { contactAddressChangedSnackbar } from 'constants/snackbars';
import { showSnackbar, clearViewedIds } from 'redux/modules/common-snackbar/actions';
import { saveAsContactAddressModal } from 'constants/modals';
import { dataLayer } from 'analytics/data-layer';
import { resetUpdateContactAddressError } from './reset-update-contact-address-error';
import { showEditDeleteSpinner } from './affect-address-modal';

export const openSaveAsContactAddressModal = address => dispatch =>
  dispatch(
    openModal({
      ...saveAsContactAddressModal,
      onCloseCallback: option => {
        if (option === ModalCloseOptions.CONFIRM) {
          const addressValues = {
            ...address,
            isContactAddress: true,
          };

          dispatch(updateContactAddress(addressValues))
            .catch(error => {
              if (error && error.status) {
                return dispatch(addressApiError(addressValues));
              }
              throw error;
            })
            .finally(() => {
              dataLayer.push({
                event: 'save_contact_address',
                temporary: true,
              });
              dispatch(showSnackbar(contactAddressChangedSnackbar));
              dispatch(clearViewedIds(contactAddressChangedSnackbar.id));
            });
        } else {
          dispatch(hideEditDeleteSpinner());
        }
      },
    }),
  );

export const showSaveAsContactAddressModal = address => async dispatch => {
  const { id } = address;
  await dispatch(showEditDeleteSpinner(id));

  await dispatch(resetUpdateContactAddressError());
  await dispatch(setSelectedAddress(address));
  await openSaveAsContactAddressModal(address)(dispatch);
  await dispatch(hideEditDeleteSpinner());
};
