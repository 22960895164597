import urls from 'constants/urls';

import { generateSession } from 'redux/modules/sessions/actions/generate-session';
import { initiateSession } from 'redux/modules/sessions/actions/initiate-session';
import { getCustomerDataIfLoggedIn } from 'redux/modules/sessions/get-customer-data-if-logged-in';
import { getFeatureFlags } from 'utils/feature-flags';

import history from 'utils/history';

const refreshSessionWithOAuth2 = (dispatch, getState, dataLoad) => {
  const sessionGenerated = dispatch(initiateSession()).catch(err => {
    const { error, status } = err;

    // WIP: Error handling to be confirmed and completed in follow up ticket
    if (status === 403 && error === 'soft_locked') {
      history.replace(urls.forgotPasswordLocked);

      return;
    }
    if (err.status !== 401) throw err;
  });

  const customerDataLoaded = sessionGenerated.then(() =>
    dataLoad ? getCustomerDataIfLoggedIn(dispatch, getState) : null,
  );

  return {
    customerDataLoaded,
    sessionGenerated,
  };
};

const refreshSessionWithoutOAuth2 = (dispatch, getState, dataLoad) => {
  const sessionGenerated = dispatch(generateSession())
    .catch(err => {
      if (err.status !== 401) throw err;
    })
    .then(response => {
      if (response?.data?.generateSession?.failures?.[0]?.type === 'SOFT_LOCKED') {
        history.replace(urls.forgotPasswordLocked);
      }
    });

  const customerDataLoaded = sessionGenerated.then(() =>
    dataLoad ? getCustomerDataIfLoggedIn(dispatch, getState) : null,
  );

  return {
    customerDataLoaded,
    sessionGenerated,
  };
};

export const refreshSession = (dispatch, getState, { dataLoad } = { dataLoad: true }) => {
  if (getFeatureFlags().identity_enableOAuth2Web) {
    return refreshSessionWithOAuth2(dispatch, getState, dataLoad);
  }

  return refreshSessionWithoutOAuth2(dispatch, getState, dataLoad);
};
