import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from './Nutritional.scss';

const Nutritional = ({
  energy,
  fat,
  carbohydrates,
  fibre,
  protein,
  salt,
  makes,
  notLegallyRequired,
  measurement,
}) => {
  const formatValue = (value, measurementType) =>
    `${value?.toLocaleString('en-GB')}${measurementType}`;

  const fields = [
    [
      'Energy',
      energy,
      energyProp => formatValue(energyProp?.kj, 'kJ/ ') + formatValue(energyProp?.kcals, 'kcals'),
    ],
    ['Fat', fat, fatProp => formatValue(fatProp?.total, 'g')],
    ['Saturated Fat', fat, fatProp => formatValue(fatProp?.saturated, 'g')],
    [
      'Carbohydrates',
      carbohydrates,
      carbohydratesProp => formatValue(carbohydratesProp?.total, 'g'),
    ],
    ['Sugars', carbohydrates, carbohydratesProp => formatValue(carbohydratesProp?.sugars, 'g')],
    ['Fibre', fibre, fibreProp => formatValue(fibreProp, 'g')],
    ['Protein', protein, proteinProp => formatValue(proteinProp, 'g')],
    ['Salt', salt, saltProp => formatValue(saltProp, 'g')],
  ];

  const measurementValue = measurement || (makes ? 'item' : 'serving');

  return (
    <div data-testid="nutritional" className={styles.nutritional}>
      <Typography styleAs="paragraphSmallLight">
        {`Typical values per ${measurementValue} when made using specific products in recipe`}
      </Typography>
      <table>
        <tbody>
          {fields.map(([legend, value, formatFunction]) => {
            if (
              value !== undefined &&
              value !== null &&
              parseFloat(formatFunction(value).replace(/\D/g, ''), 10) === 0 &&
              notLegallyRequired
            ) {
              return <React.Fragment key={legend} />;
            }

            if (value !== undefined && value !== null) {
              return (
                <tr key={legend} className={styles.nutritional__row}>
                  <th className={styles.nutritional__cell}>
                    <Typography styleAs="paragraph">{legend}</Typography>
                  </th>
                  <td className={styles.cell_right}>
                    <Typography styleAs="paragraphHeading">{formatFunction(value)}</Typography>
                  </td>
                </tr>
              );
            }

            return <React.Fragment key={legend} />;
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Nutritional;

Nutritional.propTypes = {
  energy: PropTypes.shape({
    kj: PropTypes.number,
    kcals: PropTypes.number,
  }),
  fat: PropTypes.shape({
    total: PropTypes.number,
    saturated: PropTypes.number,
  }),
  carbohydrates: PropTypes.shape({
    total: PropTypes.number,
    sugars: PropTypes.number,
  }),
  protein: PropTypes.number,
  salt: PropTypes.number,
  fibre: PropTypes.number,
  makes: PropTypes.number,
  notLegallyRequired: PropTypes.bool,
  measurement: PropTypes.string,
};

Nutritional.defaultProps = {
  energy: null,
  fat: null,
  carbohydrates: null,
  protein: null,
  salt: null,
  fibre: null,
  makes: null,
  notLegallyRequired: false,
  measurement: null,
};
