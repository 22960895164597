import { deepFreeze } from 'utils/deepFreeze';

export interface PaymentCard {
  addressId: string;
  cardholderName: string;
  expiryDate: string;
  id: string;
  primary: boolean;
  maskedCardNumber: string;
  schemeName: string;
}

interface GlobalFlow {
  active: boolean;
  binReady: boolean;
  cardinalReady: boolean;
  jwt: string | null;
  jwtId: string | null;
  setupTimerFinished: boolean;
  setupStartTime: number | null;
}

export interface PaymentState {
  acsUrl: string | null;
  cardAuthenticated: boolean;
  currentApiRequest: unknown | null;
  deletingCards: string[];
  errorCount: number;
  errorSource: unknown | null;
  errorStatusCode: unknown | null;
  globalFlow: GlobalFlow;
  payerAuthAmount: number | null;
  payerAuthRequest: unknown | null;
  payerAuthRequestId: string | null;
  paymentCardId: string | null;
  paymentCards: PaymentCard[];
  paymentCardsLoaded: boolean;
  processingDB: string | null;
  requireCardAuth: boolean;
  sessionGUID: string | null;
  sessionPasscode: string | null;
}

const initialState: Readonly<PaymentState> = deepFreeze({
  acsUrl: null,
  cardAuthenticated: false,
  currentApiRequest: null,
  deletingCards: [],
  errorCount: 0,
  errorSource: null,
  errorStatusCode: null,
  globalFlow: {
    active: false,
    binReady: false,
    cardinalReady: false,
    jwt: null,
    jwtId: null,
    setupTimerFinished: false,
    setupStartTime: null,
  },
  payerAuthAmount: null,
  payerAuthRequest: null,
  payerAuthRequestId: null,
  paymentCardId: null,
  paymentCards: [],
  paymentCardsLoaded: false,
  processingDB: null,
  requireCardAuth: false,
  sessionGUID: null,
  sessionPasscode: null,
});

export default initialState;
