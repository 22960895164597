const reducer = (state, action = {}) => {
  const { result: results, searchTerm } = action;

  return {
    ...state,
    loading: false,
    results,
    searchTerm,
  };
};

export default reducer;
