import { createSelector } from 'reselect';

import { getCustomerAddresses } from 'redux/modules/customer-address/selectors';

import { getCustomerSlotAddressId } from './index';

export const getCustomerAddressById = createSelector(
  [getCustomerAddresses, getCustomerSlotAddressId],
  (addresses: Array<Record<string, string>>, addressId) =>
    addresses.find(address => address.id === addressId),
);
