export const globalActive = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    active: !!payload,
  },
});

export const globalBinReady = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    binReady: !!payload,
  },
});

export const globalCardinalReady = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    cardinalReady: !!payload,
  },
});

export const globalJwt = (state, { payload: { jwt, jwtId } }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    jwt,
    jwtId,
  },
});

export const globalCardToken = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    cardToken: payload,
  },
});

export const globalCardinalSetupTimerFinished = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    setupTimerFinished: !!payload,
  },
});

export const globalCardinalSetupStartTime = (state, { payload }) => ({
  ...state,
  globalFlow: {
    ...state.globalFlow,
    setupStartTime: payload,
  },
});
