export const COOKIE_CONSENT = 'wtr_cookie_consent' as const;
export const COOKIE_ADVERTISING = 'wtr_cookies_advertising' as const;
export const COOKIE_ANALYTICS = 'wtr_cookies_analytics' as const;
export const COOKIE_FUNCTIONAL = 'wtr_cookies_functional' as const;
export const COOKIE_DATALAYER = 'gdpr_consent' as const;

export const COOKIE_EXPERIMENTAL_UI = 'mt.experimentalUI' as const;
export const COOKIE_MONETATE_ID = 'mt.v' as const;

export const COOKIE_APP_BANNER_DISMISS = 'wtr_app_banner_dismiss' as const;
export const COOKIE_PRODUCT_RECOMMENDATIONS_DECISION_MEMOIZE_ID =
  'wtr_product_recommendations_decision_memoize_id' as const;
export const COOKIE_CUSTOMER_ID = 'customerId' as const;
export const COOKIE_BRANCH_ID = 'branchId' as const;

export const COOKIE_FAVOURITES_SORT_BY = 'wtr_favourites_sort_by_preference' as const;
