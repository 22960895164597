import uniqWith from 'lodash/uniqWith';

import { deepFreeze } from 'utils/deepFreeze';

import compareLowerCase from 'utils/compare-lower-case';

import {
  CLEAR_JOTTER,
  JOTTER_FULFILLED,
  JOTTER_PENDING,
  JOTTER_REJECTED,
  SET_JOTTER,
  USE_JOTTER,
} from 'redux/modules/multi-search/actions/types';
import { removeDiacriticsFromLetters } from 'utils/format';

const initialState = deepFreeze({
  jotterValues: [],
  loading: false,
  values: null,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_JOTTER:
      return {
        ...state,
        jotterValues: initialState.jotterValues,
      };
    case JOTTER_FULFILLED:
    case SET_JOTTER: {
      const { payload } = action;

      return {
        ...state,
        jotterValues: payload.map(v => removeDiacriticsFromLetters(v)),
        loading: false,
      };
    }
    case JOTTER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case JOTTER_REJECTED:
      return {
        ...state,
        loading: false,
      };
    case USE_JOTTER:
      return {
        ...state,
        values: uniqWith(
          state.jotterValues.map(v => removeDiacriticsFromLetters(v)),
          compareLowerCase,
        ),
      };
    default:
      return state;
  }
};
