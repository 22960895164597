import React from 'react';
import { svgIcon } from 'utils/svg-icon';

export default svgIcon(
  <svg
    aria-hidden="true"
    x="0px"
    y="0px"
    viewBox="10 10 30 30"
    width="20"
    height="20"
    xmlSpace="preserve"
  >
    <path
      d="M25.1,13.2c6.5,0,11.8,5.3,11.8,11.8s-5.3,11.8-11.8,11.8S13.4,31.5,13.4,25S18.7,13.2,25.1,13.2 M25.1,11.2
	c-7.6,0-13.8,6.2-13.8,13.8c0,7.6,6.2,13.8,13.8,13.8c7.6,0,13.8-6.2,13.8-13.8C38.9,17.4,32.7,11.2,25.1,11.2L25.1,11.2z"
    />
    <g>
      <path
        d="M22.6,31.3v-0.6c0.3,0,0.6-0.1,0.8-0.1c0.4-0.1,0.5-0.4,0.5-0.8v-5.4c0-0.4-0.2-0.8-0.5-1c-0.2-0.2-0.8-0.3-1.2-0.3v-0.6
		l4.3-0.2l0.1,0.1v7.2c0,0.4,0.2,0.7,0.5,0.9c0.3,0.1,0.5,0.2,0.8,0.2v0.6H22.6z M25.1,20.5c-0.9,0-1.7-0.7-1.7-1.7
		c0-0.9,0.8-1.7,1.7-1.7s1.7,0.7,1.7,1.7C26.8,19.7,26.1,20.5,25.1,20.5z"
      />
    </g>
  </svg>,
  'warning',
);
