const reducer = (state, action) => {
  const { status, message } = action.error ?? {};

  return {
    ...state,
    promoCodes: {
      ...state.promoCodes,
      requestedPromoCode: null,
      serverError: {
        ...state.promoCodes.serverError,
        status,
        message,
      },
    },
    waiting: false,
  };
};

export default reducer;
