import { ADD_DELAYED_ACTION, CANCEL_DELAYED_ACTION } from 'redux/middleware/delayed-action/types';
import { openModal } from 'redux/modules/common-modal/actions';

export const showSecurityTokenTimeoutModal = () => dispatch => {
  dispatch(
    openModal({
      titleText: 'Sorry, your session has timed out',
      messageText: [
        "To secure your data, you've been timed out. Please refresh the page to complete your order",
      ],
      buttons: [
        {
          buttonText: 'Refresh now',
        },
      ],
      // eslint-disable-next-line no-restricted-globals
      onCloseCallback: () => location.reload(),
      useIngredientsModal: true,
    }),
  );
};

export const setSecurityTokenTimeout = () => dispatch => {
  dispatch({
    type: ADD_DELAYED_ACTION,
    payload: {
      action: showSecurityTokenTimeoutModal(),
      timerName: 'SECURITY_TOKEN_EXPIRATION',
      timeout: 28 * 60 * 1000, // 28 minutes to trigger the error modal
    },
  });
};

export const cancelSecurityTokenTimeout = () => dispatch => {
  dispatch({
    type: CANCEL_DELAYED_ACTION,
    payload: {
      timerName: 'SECURITY_TOKEN_EXPIRATION',
    },
  });
};
