export const promotionTypes = {
  BOOK_OF_THE_MONTH: 'BKM',
  BOOK_OF_THE_WEEK: 'BOW',
  BETTER_THAN_HALF_PRICE: 'BHP',
  BUYERS_CHOICE: 'BYC',
  CATALOGUE: 'CAT',
  EXCLUSIVE_TO_WAITROSE: 'EXC',
  EXTRA_VALUE: 'EXV',
  FEATURE_REDUCTION: 'FER',
  FEATURED: 'FEA',
  FRESH_PRODUCE_VALUE: 'FPV',
  FOOD_HEROES: 'FDH',
  GADGET_OF_THE_MONTH: 'GOM',
  HALF_PRICE: 'HP',
  INFORMATION: 'IMF',
  INTRODUCTORY_OFFER: 'INT',
  KITCHEN_MAGAZINE: 'KIT',
  LINKED_ITEM: 'LI',
  LOCALLY_PRODUCED: 'LPD',
  MARKETING_LEAFLET: 'MLL',
  MULTI_VALUE: 'MV',
  MYWAITROSE_PERCENTAGE_OFF: 'MPO',
  NATIONAL_PROMO: 'NAT',
  NEW_LINE: 'NEW',
  NEW_LOWER_PRICE: 'NLP',
  NEW_SEASON: 'NSE',
  PERCENTAGE_OFF: 'POF',
  PERMANENT_MULTI_VALUE: 'PMV',
  PRESS_QUOTE: 'PRQ',
  PRICE_MATCH_ASDA: 'PMA',
  PRICE_MATCH_RANGE: 'PMR',
  PRICE_MATCH_SAINSBURYS: 'PMS',
  PRICE_MATCH_TESCO: 'PMT',
  REDUCED_TO_CLEAR: 'RTC',
  SAVE_A_THIRD: 'S13',
  SPECIAL_BUY: 'SBU',
  SPECIAL_PURCHASE: 'SPU',
  WAITROSE_WEEKEND_NEWSPAPER: 'WWN',
  WAITROSE_WEEKEND_NEWSPAPER_PAGE: 'BPL',
  WAITROSE_WEEKEND_OFFERS: 'WWO',
  WAS_NOW: 'WN',
  WHOLE_RANGE_REDUCTION: 'WRR',
  WINE_MEDAL: 'WME',
  WINE_NEW_VINTAGE: 'WNV',
};

export const featuredPromotions = new Set([
  promotionTypes.FEATURED,
  promotionTypes.LINKED_ITEM,
  promotionTypes.MULTI_VALUE,
  promotionTypes.PERMANENT_MULTI_VALUE,
  promotionTypes.BUYERS_CHOICE,
  promotionTypes.EXTRA_VALUE,
  promotionTypes.FRESH_PRODUCE_VALUE,
  promotionTypes.NEW_LINE,
  promotionTypes.PRESS_QUOTE,
  promotionTypes.MARKETING_LEAFLET,
  promotionTypes.SPECIAL_BUY,
  promotionTypes.SPECIAL_PURCHASE,
]);

export const introductoryOfferPromotions = new Set([promotionTypes.INTRODUCTORY_OFFER]);

export const percentOffPromotions = new Set([promotionTypes.MYWAITROSE_PERCENTAGE_OFF]);

export const priceMatchPromotions = new Set([
  promotionTypes.PRICE_MATCH_ASDA,
  promotionTypes.PRICE_MATCH_RANGE,
  promotionTypes.PRICE_MATCH_SAINSBURYS,
  promotionTypes.PRICE_MATCH_TESCO,
]);
