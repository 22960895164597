import { dataLayer } from 'analytics/data-layer';
import { EventType, postAdTechEvent } from './gtmServerFunctions';

type PromoImpression = {
  id?: string;
  name?: string;
  position?: string;
  products?: {
    id: string;
  }[];
};

export default function promoImpression({ id, name, position, products }: PromoImpression) {
  postAdTechEvent({ eventType: EventType.IMPRESSION, sponsorshipId: id });
  dataLayer.push({
    event: 'promo_impression',
    ecommerce: {
      promoView: {
        products,
        promotions: [
          {
            id,
            name,
            position,
          },
        ],
      },
    },
  });
}
