import { session } from 'utils/storage';
import { ADD_TO_TROLLEY_POST_AUTHENTICATION_KEY } from 'constants/shared-session-storage';

export const saveItemToAddToTrolleyPostAuthentication = payload =>
  session.setJson(ADD_TO_TROLLEY_POST_AUTHENTICATION_KEY, payload);

export const getItemToAddToTrolleyPostAuthentication = () =>
  session.getJson(ADD_TO_TROLLEY_POST_AUTHENTICATION_KEY);

export const removeItemToAddToTrolleyPostAuthentication = () =>
  session.remove(ADD_TO_TROLLEY_POST_AUTHENTICATION_KEY);
