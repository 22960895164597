import linkTypes from 'constants/link-types';

export const ALL_CATEGORIES_VALUE = 'allcategories';
export const CATEGORY_GROUP = 'CATEGORY_GROUP';

export const FACETS_ORDER = [
  {
    group: linkTypes.OFFER,
    label: 'Offers',
  },
  {
    group: linkTypes.DIETARY,
    label: 'Dietary',
  },
  {
    group: linkTypes.BRAND,
    label: 'Waitrose Brands',
  },
  {
    group: linkTypes.LEAD_TIME,
    label: 'Available in',
    allowMultiple: false,
    deselectAllOptionsLabel: 'Show all availability',
  },
];
