import favouritesExperience from 'api/definitions/favourites-experience';
import { SORT_URL_CATEGORY_RANKING, SORT_URL_PURCHASE_FREQUENCY } from './constants';

export const FAVOURITES_SORT_URL_TO_API_MAP = {
  [SORT_URL_CATEGORY_RANKING]: favouritesExperience.SORT_CATEGORY,
  [SORT_URL_PURCHASE_FREQUENCY]: favouritesExperience.SORT_PURCHASE_FREQUENCY,
};

export const FAVOURITES_SORT_API_TO_URL_MAP = {
  [favouritesExperience.SORT_CATEGORY]: SORT_URL_CATEGORY_RANKING,
  [favouritesExperience.SORT_PURCHASE_FREQUENCY]: SORT_URL_PURCHASE_FREQUENCY,
};
