import React from 'react';
import { getFeatureFlags } from 'utils/feature-flags';
import { svgIcon } from 'utils/svg-icon';
import { defaultLogo, defaultLogoSmall, prideLogo, prideLogoSmall } from './svgLogos';

export interface WaitroseLogoProps {
  small?: boolean;
  className?: string;
}

function getLogo(small: boolean) {
  const { showPrideLogo } = getFeatureFlags();

  if (small) {
    return showPrideLogo ? prideLogoSmall : defaultLogoSmall;
  }

  return showPrideLogo ? prideLogo : defaultLogo;
}

const WaitroseLogo = ({ small = false, className }: WaitroseLogoProps) => {
  const Logo = svgIcon(getLogo(small), 'WaitroseLogo');

  return <Logo className={className} />;
};

export default WaitroseLogo;
