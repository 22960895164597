import { REMOVE_FROM_TROLLEY_DESCRIPTION } from 'redux/middleware/analytics/actions-to-track/trolley/constants';

import changeCart from './change-cart';

export default function transform(state, { trolleyItems }) {
  const removedItems = trolleyItems.map(trolleyItem => ({
    analytics: {
      // unlike update cart action, here it is safe to derive these from the trolleyItem
      lineNumber: trolleyItem.lineNumber,
      uom: trolleyItem.quantity.uom,
    },
  }));

  return changeCart(state, removedItems, 'remove', REMOVE_FROM_TROLLEY_DESCRIPTION);
}
