import { SET_DISPLAYED_ADDRESS_ID } from 'redux/modules/address/actions/types';

const setDisplayedAddressId = (state, { payload }) => ({
  ...state,
  displayedAddressId: payload ? payload.id : '',
});

export default map => {
  map.set(SET_DISPLAYED_ADDRESS_ID, setDisplayedAddressId);
  return map;
};
