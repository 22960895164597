import queryString from 'query-string';
import urlParse from 'url-parse';
import history from 'utils/history';
import { useDispatch } from 'react-redux';
import { SAVE_LAST_NON_AUTHENTICATION_PATH } from './types';

export const useSaveNewPathForPostAuthNavigation = () => {
  const dispatch = useDispatch();

  const saveNewPathForPostAuthNavigation = ({
    customPathname,
    customSearch,
  }: {
    customPathname?: string;
    customSearch?: string;
  }) => {
    const { pathname, search } = history.getLocation();

    dispatch({
      type: SAVE_LAST_NON_AUTHENTICATION_PATH,
      payload: {
        location: {
          pathname: customPathname || pathname,
          search: customSearch || search,
        },
      },
    });
  };

  return saveNewPathForPostAuthNavigation;
};

export const saveLastNonAuthenticationPath = (): WtrThunkAction<void> => dispatch => {
  const { search } = history.getLocation();
  const { redirect } = queryString.parse(search);

  if (redirect) {
    const { pathname, query } = urlParse(redirect, true);
    const redirectSearch = queryString.stringify(query);

    dispatch({
      type: SAVE_LAST_NON_AUTHENTICATION_PATH,
      payload: {
        location: { pathname, search: redirectSearch ? `?${redirectSearch}` : '' },
      },
    });
  }
};
