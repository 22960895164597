const reducer = (state, action) => {
  const { estimatedTotals = {}, giftCards = [], giftVouchers = [] } = action;

  return {
    ...state,
    giftCards,
    giftCardToRemove: null,
    giftVouchers: giftVouchers.map(({ status, serialNumber, value }) => ({
      status,
      serialNumber,
      amount: value.amount,
    })),
    order: {
      ...state.order,
      totals: {
        ...state.order.totals,
        estimated: {
          ...estimatedTotals,
        },
      },
    },
    waiting: false,
  };
};

export default reducer;
