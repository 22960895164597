import locator from 'utils/locator';
import { getReturnToAdminUrl } from 'redux/modules/sessions/selectors';
import { destroySession } from 'redux/modules/sessions/actions/destroy-session';

export const returnToAdmin = () => (dispatch, getState) => {
  const returnToAdminUrl = getReturnToAdminUrl(getState());

  dispatch(destroySession(true)).finally(() => {
    locator.href = returnToAdminUrl;
  });
};
