import React from 'react';
import { oneOfType, string, element, arrayOf } from 'prop-types';
import { log } from 'utils/error-logging';

export class SimpleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, extra) {
    const { logger, section } = this.props;
    log(error, { logger, section, extra });
  }

  render() {
    const { hasError } = this.state;
    const { children, errorComponent } = this.props;
    if (hasError) {
      return errorComponent || null;
    }

    return children;
  }
}
SimpleErrorBoundary.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  errorComponent: oneOfType([element, arrayOf(element)]),
  logger: string,
  section: string,
};
SimpleErrorBoundary.defaultProps = {
  errorComponent: null,
  logger: 'LazyErrorBoundary',
  section: 'Components',
};
