import React, { Suspense, useMemo } from 'react';
import { bool, string } from 'prop-types';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import { lazy as loadableLazy } from '@loadable/component';

export const LazyIcon = ({ name, className, legacy, size }) => {
  const Icon = useMemo(() => {
    if (!__SERVER__ && name) {
      const Component = legacy
        ? React.lazy(
            () =>
              import(
                `@johnlewispartnership/wtr-ingredients/dist/foundations/icons/legacy/icons/${name}.js`
              ),
          )
        : loadableLazy(() =>
            import(
              `@johnlewispartnership/wtr-ingredients/dist/foundations/icons/icons/${name}.js`
            ).then(({ [name]: AsyncIcon }) => AsyncIcon),
          );

      return () => (
        <SimpleErrorBoundary logger="slideout-menu-icon" section="MenuLink">
          <Suspense fallback={<></>}>
            <Component {...{ className, size }} />
          </Suspense>
        </SimpleErrorBoundary>
      );
    }
    return () => null;
  }, [name, className, legacy, size]);

  return <Icon />;
};
LazyIcon.propTypes = {
  name: string.isRequired,
  className: string,
  legacy: bool,
  size: string,
};

LazyIcon.defaultProps = {
  className: undefined,
  legacy: true,
  size: undefined,
};
