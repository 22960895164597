import { Address } from 'redux/modules/address/state/initial-state';

import { deepFreeze } from 'utils/deepFreeze';

export interface CustomerAddressState {
  loading: boolean;
  addresses: Address[];
  error: unknown;
}

const initialState: Readonly<CustomerAddressState> = deepFreeze({
  loading: false,
  addresses: [],
  error: null,
});

export default initialState;
