export default state => {
  const { pathname } = state.router.location;
  const filterEvent = pathname.includes('browse') ? 'browse_sort' : 'search_sort';
  const { stickyFilter } = state.search.filters || {};

  return {
    event: filterEvent,
    sort_method: state.search.queryParams.sortBy,
    sort_location: stickyFilter ? 'sticky' : 'normal',
    sort_version: 'new',
  };
};
