import queryString from 'query-string';

import { formatUrl, formatJohnLewisUrl, formatLoyaltyExperiencesUrl } from './format-url';

export const buildUrl = (api = {}, path = '', query) =>
  api.query && query ? `${path}?${queryString.stringify(query)}` : path;

export const buildApiUrlOfType = (api, url = '') => {
  const { johnLewis = false, loyaltyExperiences = false } = api || {};
  if (loyaltyExperiences) {
    return formatLoyaltyExperiencesUrl(url);
  }
  if (johnLewis) {
    return formatJohnLewisUrl(url);
  }
  return formatUrl(url);
};
