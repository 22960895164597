import creditCardType, { CardInfo, CardType } from 'credit-card-type';

declare global {
  namespace CreditCardType {
    interface CustomCardTypes {
      'business-account-card': 'business-account-card';
      'new-day-partnership': 'new-day-partnership';
    }
  }
}

// A list of cards supported by Waitrose for payment
export const SUPPORTED_CARDS = {
  AMERICAN_EXPRESS: creditCardType.types.AMERICAN_EXPRESS,
  BUSINESS_ACCOUNT_CARD: 'business-account-card',
  MAESTRO: creditCardType.types.MAESTRO,
  MASTERCARD: creditCardType.types.MASTERCARD,
  VISA: creditCardType.types.VISA,
  NEW_DAY_PARTNERSHIP: 'new-day-partnership',
} as const;

// Adds card lengths of 11-19 digits to comply with acceptance criteria in WPIP-5190 (as Barclaycard are recommending so)
creditCardType.updateCard(creditCardType.types.VISA, {
  lengths: [11, 12, 13, 14, 15, 16, 17, 18, 19],
});

// Modifies Maestro to exclude JLP Waitrose Business account cards
creditCardType.updateCard(creditCardType.types.MAESTRO, {
  exactPattern: /^(5[06-9]|63(?!562[89])|67)\d*$/,
  prefixPattern: /^(5[06-9]|63(?!562[89])|67)$/,
});

// Adds the JLP Waitrose Business account cards
creditCardType.addCard({
  code: {
    name: 'CVC',
    size: 0,
  },
  exactPattern: /^(63562[89])$/,
  gaps: [4, 8, 12],
  lengths: [19],
  niceType: 'JLP/Waitrose Business Account',
  prefixPattern: /^(63562[89])\d*$/,
  type: SUPPORTED_CARDS.BUSINESS_ACCOUNT_CARD,
});

// Adds the New Day Partnership Mastercard
creditCardType.addCard({
  code: {
    name: 'CVC',
    size: 3,
  },
  exactPattern: /^(537370)\d*$/,
  gaps: [4, 8, 12],
  lengths: [16],
  niceType: 'New Day Partnership',
  prefixPattern: /^(537370)\d*$/,
  type: SUPPORTED_CARDS.NEW_DAY_PARTNERSHIP,
});

const cardSupported = (type: creditCardType.CardType) => {
  if (type) {
    const transformedType = type.replace(/-/g, '_').toUpperCase();
    return transformedType in SUPPORTED_CARDS;
  }
  return undefined;
};

const generateIconName = (type: CardType, niceType: string) => {
  if (type && niceType) {
    switch (type) {
      case SUPPORTED_CARDS.BUSINESS_ACCOUNT_CARD:
        return 'Maestro';
      case SUPPORTED_CARDS.NEW_DAY_PARTNERSHIP:
        return null;
      default:
        return niceType.replace(/[\W_]+/g, '');
    }
  }
  return undefined;
};

export const getCardTypes = creditCardType;

export interface GetCardTypeResult extends CardInfo {
  supported: boolean | null | undefined;
  iconName: string | null | undefined;
}

export const getCardType = (pan: string): GetCardTypeResult | undefined => {
  if (!pan) {
    return undefined;
  }
  const bin = pan.substring(0, 6) ?? '';

  // using slice(-1) because partnership card number is also matched by maestroCard, because of the priority for testing https://www.npmjs.com/package/credit-card-type. we could also change the order priority but that could break something.
  const [cardInfo] = creditCardType(bin).slice(-1) || {};
  if (cardInfo) {
    const { type, niceType } = cardInfo;
    return {
      ...cardInfo,
      supported: cardSupported(type),
      iconName: generateIconName(type, niceType),
    };
  }
  return undefined;
};

export const paymentCardTypeIconName = (cardType = '') => {
  if (/Visa/.test(cardType) || /Electron/.test(cardType)) return 'Visa';

  if (
    /Mastercard/.test(cardType) ||
    /MasterCard/.test(cardType) ||
    /Vanilla/.test(cardType) ||
    /Compliments/.test(cardType)
  )
    return 'Mastercard';

  if (/American Express/.test(cardType) || /Amex/.test(cardType)) return 'AmericanExpress';

  if (/Maestro/.test(cardType)) return 'Maestro';

  if (cardType === 'JLP Business Card') return 'BusinessAccountCard';

  return 'PaymentCard';
};
