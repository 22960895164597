import { RECORD_EXPERIMENT_ID_VIEWED } from 'redux/modules/experiments/actions/types';

const monetateViewed = 'monetate_fe_experiment_viewed';

export const trackExperiment =
  event =>
  (state, { id, variant } = {}) => ({
    event,
    monetate_fe_experiment: {
      id: `fe_${id}`,
      variant: variant === 'default' ? 'Control' : variant,
    },
  });

export default {
  [RECORD_EXPERIMENT_ID_VIEWED]: trackExperiment(monetateViewed),
};
