import { createSelector } from 'reselect';

import { getBranchByCustomerSlotBranchId } from './get-branch-by-customer-slot-branch-id';

export const getCustomerSlotBranchAddress = createSelector(
  getBranchByCustomerSlotBranchId,
  branch => {
    const { contactDetails, name } = branch || {};
    const { address1, city, postcode } = contactDetails || {};
    return {
      line1: address1,
      name,
      postalCode: postcode,
      town: city,
    };
  },
);
