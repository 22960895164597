import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import { SHOW_CHANGE_EMAIL_MODAL, HIDE_CHANGE_EMAIL_MODAL } from '../../types';

const initialState = deepFreeze({
  isOpen: false,
});

const updateChangeEmail = (state, { payload }) => ({
  ...state,
  ...payload,
});

const changeEmailReducerMap = new Map([
  [SHOW_CHANGE_EMAIL_MODAL, updateChangeEmail],
  [HIDE_CHANGE_EMAIL_MODAL, updateChangeEmail],
]);

export default createReducer(initialState, changeEmailReducerMap);
