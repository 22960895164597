import { ADDRESS_API_ERROR, CONTACT_ADDRESS_API_ERROR } from 'redux/modules/address/actions/types';

export const addressApiError = address => ({
  type: ADDRESS_API_ERROR,
  address,
});

export const contactAddressApiError = address => ({
  type: CONTACT_ADDRESS_API_ERROR,
  address,
});
