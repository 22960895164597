import root from 'window-or-global';
import env from 'env/env';
import { asyncLocalStorage } from 'server/utils/async-local-storage';

type FeatureFlags = Record<string, boolean>;

export const ROOT_FEATURE_FLAGS_KEY = 'features';
const ASYNC_LOCAL_STORAGE_FEATURE_FLAGS_KEY = 'featureFlags';

export const getFeatureFlags = (): FeatureFlags => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();

    return (
      (requestContext?.get(ASYNC_LOCAL_STORAGE_FEATURE_FLAGS_KEY) as FeatureFlags) ||
      root.defaultFeatures ||
      ({} as FeatureFlags)
    );
  }

  return root[ROOT_FEATURE_FLAGS_KEY] || root.defaultFeatures || {};
};

export const setFeatureFlags = (featureFlags: FeatureFlags): void => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    requestContext?.set(ASYNC_LOCAL_STORAGE_FEATURE_FLAGS_KEY, featureFlags);

    return;
  }

  root[ROOT_FEATURE_FLAGS_KEY] = featureFlags;
};

export const getFeatureFlagsToPassToAPI = () => {
  const features = getFeatureFlags();

  return Object.fromEntries(
    Object.entries(features).filter(([flagName]) => env.featureFlagsToPassToAPI.includes(flagName)),
  );
};

export const featureFlagsToQueryString = (featureFlags: FeatureFlags) =>
  Object.keys(featureFlags)
    .reduce<string[]>(
      (featureFlagsArray, flagName) =>
        featureFlags[flagName] ? featureFlagsArray.concat(flagName) : featureFlagsArray,
      [],
    )
    .join(',');
