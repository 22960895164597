import { MOST_RELEVANT } from '../constants';

export const permanentSortByParams = {
  Featured: 'FEATURED_LAST_PUBLISHED',
  'Recently added': 'LAST_PUBLISHED',
  Alphabetical: 'A_2_Z',
  'Alphabetical reverse': 'Z_2_A',
};

export const sortByParams: { [key: string]: string } = {
  [MOST_RELEVANT]: '',
  ...permanentSortByParams,
};

export const getKeyByValue = (value: string) =>
  Object.keys(sortByParams).find(key => sortByParams[key] === value);
