import root from 'window-or-global';
import { asyncLocalStorage } from 'server/utils/async-local-storage';
import { requestIdleCallback } from 'utils/settimeout-wrapper';

const serverDataLayer = {};

if (__SERVER__) {
  // allowSendingOfEvents doesn't mean anything in the server world, but has been added as a no-op
  // here so the interface to client and server versions are the same.
  serverDataLayer.allowSendingOfEvents = () => {};
  serverDataLayer.push = (...args) => {
    const requestContext = asyncLocalStorage.getStore();
    const gtmEvents = requestContext.get('gtmEvents') || [];
    gtmEvents.push(...args);
    requestContext.set('gtmEvents', gtmEvents);
  };
  serverDataLayer.whenAll = () => Promise.resolve();
}

/**
 * Schedules dataLayer.push calls until browser idle time or if requestIdleCallback is not supported,
 * a more immediate but separate main thread task.
 */
const idleUntilUrgentClientDataLayer = {
  buffer: [],
  sendScheduled: false,
  hasBufferFlushOnVisibilityHiddenBeenSetup: false,
  eventSendingOn: false,
  async performSend() {
    if (this.buffer.length === 0) {
      this.sendScheduled = false;

      // Nothing to do
      return;
    }

    const bufferItem = this.buffer.shift();
    const data = bufferItem[0] instanceof Promise ? [await bufferItem[0]] : bufferItem;

    // This file is the to be used abstraction around the dataLayer, so it is ok to use a direct reference here.
    // eslint-disable-next-line no-restricted-properties
    root.dataLayer.push(...data);

    // Reset the boolean so future sends can be scheduled.
    this.sendScheduled = false;

    // Check if there are more events still to send.
    if (this.buffer.length > 0) {
      this.schedulePendingEvents();
    }
  },
  flushBuffer() {
    this.buffer.forEach(data => {
      // This file is the to be used abstraction around the dataLayer, so it is ok to use a direct reference here.
      // eslint-disable-next-line no-restricted-properties
      root.dataLayer.push(...data);
    });
    this.buffer = [];
  },
  schedulePendingEvents() {
    this.sendScheduled = true;

    // requestIdleCallback does provide a deadline object which can be introspected for how much
    // time is available and if more work can be squeezed in. However the budget at time of writing
    // is always blown by a single dataLayer.push call and the time remaining does not decrease
    // correctly sometimes prompting for two to be scheduled when there wasn't technically time for
    // one.
    requestIdleCallback(() => {
      this.performSend();
    });
  },
  setupBufferFlushOnVisibilityHidden() {
    this.hasBufferFlushOnVisibilityHiddenBeenSetup = true;
    root.addEventListener(
      'visibilitychange',
      () => {
        if (root.document.visibilityState === 'hidden') {
          this.flushBuffer();
        }
      },
      true,
    );
  },
  push(...args) {
    this.buffer.push(args);

    if (!this.hasBufferFlushOnVisibilityHiddenBeenSetup) {
      this.setupBufferFlushOnVisibilityHidden();
    }

    if (!this.sendScheduled && this.eventSendingOn) {
      this.schedulePendingEvents();
    }
  },
};

const clientDataLayer = {
  allowSendingOfEvents: () => {
    if (!idleUntilUrgentClientDataLayer.eventSendingOn) {
      idleUntilUrgentClientDataLayer.eventSendingOn = true;
      idleUntilUrgentClientDataLayer.schedulePendingEvents();
    }
  },
  push: (...args) => {
    idleUntilUrgentClientDataLayer.push(...args);
  },
  /** adds a promise to the end of the dataLayer queue. This means that when the promise is resolved, all the events will have at least started being processed.
   * @argument {number} timeout - Time max time to wait for the dataLayer queue to finish processing. This is important in case the promise never gets triggered.
   */
  whenAll(timeout = 3000) {
    // if google_tag_manager isn't available, then the dataLayer.push callback fn would never be called
    // It also means that there are no events to be sent, so we don't need to wait
    if (!root.google_tag_manager) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this.push(resolve);

      // dataLayer_test_timeout is used during functional tests to make tests take less time, or to cause an error
      setTimeout(resolve, root.dataLayer_test_timeout ?? timeout);
    });
  },
};

export const dataLayer = __SERVER__ ? serverDataLayer : clientDataLayer;
