import orderStatus from 'constants/orderStatus';
import { getCustomerAccount } from 'redux/modules/accounts/selectors/get-customer-account';
import { getOrder } from 'redux/modules/order/selectors';
import { getSlotType } from 'redux/modules/order/selectors/get-order-slot-selectors';
import getFirstSavedPaymentCard from 'redux/modules/payment/selectors/get-first-saved-payment-card';
import { canInstantCheckout } from 'redux/modules/trolley/selectors/can-instant-checkout';

export default (state: WtrState) => {
  const orderState = getOrder(state);

  const isAmend = orderState?.order?.status === orderStatus.AMENDING;
  const customerOrderId = orderState?.customerOrderId;
  const instantCheckoutEligibility = canInstantCheckout(state);
  const { id: customerId } = getCustomerAccount(state);
  const accountHasSavedCard = !!getFirstSavedPaymentCard(state);

  return {
    event: 'checkout_review_order',
    instant_checkout_eligibility: instantCheckoutEligibility,
    user: {
      customerId,
    },
    ecommerce: {
      checkout: {
        actionField: {
          option: isAmend ? 'amend' : 'purchase',
          id: customerOrderId,
          service_type: getSlotType(state),
          account_saved_card: accountHasSavedCard,
        },
      },
    },
  };
};
