import React from 'react';
// This is one of the few files where it is expected to import the
// `<Navigate />` component.
//
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate, NavigateProps } from 'react-router-dom';
import { useResponse } from 'components/ResponseProvider';
import locator from 'utils/locator';

export interface RedirectProps extends NavigateProps {
  /**
   * @default 302 Found
   */
  status?: number;
}

const getRedirectUrl = (to: NavigateProps['to']) => {
  if (typeof to === 'string') {
    return to;
  }

  const { pathname = locator.pathname, search = '' } = to;

  return `${pathname}${search}`;
};

const Redirect = ({ status = 302, ...navigateProps }: RedirectProps) => {
  const { setStatus } = useResponse();

  if (__SERVER__) {
    setStatus(status);
    locator.href = getRedirectUrl(navigateProps.to);
  }

  return <Navigate {...navigateProps} />;
};

Redirect.displayName = 'Redirect';

export default Redirect;
