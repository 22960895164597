import React, { createContext, useContext } from 'react';
import { shape, node, number } from 'prop-types';

const ResponseContext = createContext({});

const { Provider } = ResponseContext;

export const useResponse = () => {
  const response = useContext(ResponseContext);

  return {
    setStatus: status => {
      response.status = status;
    },
  };
};

const ResponseProvider = ({ children, response }) => (
  <Provider value={response}>{children}</Provider>
);

ResponseProvider.propTypes = {
  children: node.isRequired,
  response: shape({
    status: number.isRequired,
  }).isRequired,
};

export default ResponseProvider;
