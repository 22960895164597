import { FACETS_ORDER } from 'constants/facets';

export function sortFacets(facets) {
  if (!Array.isArray(facets) || facets.length < 0) return [];

  return FACETS_ORDER.map(orderedFacet => ({
    ...facets.filter(facet => orderedFacet.group === facet.group)[0],
    label: orderedFacet.label,
    allowMultiple: orderedFacet.allowMultiple === undefined ? true : orderedFacet.allowMultiple,
    deselectAllOptionsLabel: orderedFacet.deselectAllOptionsLabel
      ? orderedFacet.deselectAllOptionsLabel
      : undefined,
  })).filter(orderedFacet => !!orderedFacet.group);
}
