import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import PropTypes from 'prop-types';

import { ProductPromotion } from 'constants/data-shapes/search-product';

import {
  brandPriceMatchPromotion,
  featuredPromotion,
  introductoryOfferPromotion,
  percentageOffPromotion,
} from 'utils/promotion-types';
import { formatAsPositivePoundsOrPence } from 'utils/currency';

import IntroductoryOffer from 'components/Product/Promotion/Description/IntroductoryOffer';
import PricePromotion from 'components/Product/Promotion/Description/PricePromotion';
import SimpleDescription from 'components/Product/Promotion/Description/Simple';
import PriceOnlyDescription from 'components/Product/Promotion/Description/PriceOnlyDescription';

const PromotionDescription = ({
  alternatePrice,
  miniPromo,
  noMargin,
  productId,
  promotion,
  underlineText,
  backgroundColor,
  withChevron,
  isLink,
  isTrolley,
}) => {
  const currentSaleUnitPrice = useSelector(
    state => getProductById(state, productId)?.currentSaleUnitPrice,
    isEqual,
  );
  const { promotionDescription: description, promotionTypeCode } = promotion;
  const { price: { amount } = {} } = currentSaleUnitPrice;
  const price = alternatePrice || (amount && formatAsPositivePoundsOrPence(amount)) || null;

  if (!description) return null;

  if (introductoryOfferPromotion(promotionTypeCode)) {
    return <IntroductoryOffer {...{ price, underlineText }} />;
  }

  if (brandPriceMatchPromotion(promotionTypeCode)) {
    return (
      <SimpleDescription
        {...{
          description: miniPromo ? 'Brand Price Match' : description,
          underlineText,
          noMargin,
        }}
      />
    );
  }

  if (featuredPromotion(promotionTypeCode) || percentageOffPromotion(promotionTypeCode)) {
    return (
      <SimpleDescription
        {...{ description, underlineText, noMargin, backgroundColor, isLink, withChevron }}
      />
    );
  }

  if (promotionTypeCode === 'NLP' && !isTrolley) {
    return (
      <PriceOnlyDescription
        {...{ underlineText, price, noMargin, backgroundColor, isLink, withChevron }}
      />
    );
  }

  return (
    <PricePromotion
      {...{ description, price, underlineText, noMargin, backgroundColor, isLink, withChevron }}
    />
  );
};

PromotionDescription.displayName = 'PromotionDescription';

PromotionDescription.propTypes = {
  alternatePrice: PropTypes.string,
  miniPromo: PropTypes.bool,
  noMargin: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  promotion: PropTypes.shape(ProductPromotion()).isRequired,
  underlineText: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isLink: PropTypes.bool,
  isTrolley: PropTypes.bool,
  withChevron: PropTypes.bool,
};

PromotionDescription.defaultProps = {
  alternatePrice: undefined,
  noMargin: false,
  miniPromo: false,
  underlineText: true,
  isLink: false,
  isTrolley: false,
  backgroundColor: undefined,
  withChevron: false,
};

export default memo(PromotionDescription);
