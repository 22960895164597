import React from 'react';
import { Badge } from '@johnlewispartnership/wtr-ingredients/ingredients/Badge';
import { Chip } from '@johnlewispartnership/wtr-ingredients/ingredients/Chips';
import classNames from 'classnames';
import styles from './index.scss';

export interface ProductBadgesProps {
  isSponsored: boolean;
  isNewRecipe: boolean;
  isAllergenUpdate: boolean;
}

const ProductBadges = ({ isSponsored, isAllergenUpdate, isNewRecipe }: ProductBadgesProps) => {
  if (!isSponsored && !isAllergenUpdate && !isNewRecipe) return null;
  return (
    <div
      className={classNames({
        [styles.badges]: isSponsored || isAllergenUpdate || isNewRecipe,
      })}
      data-testid="product-badges"
    >
      {isSponsored && (
        <Badge text="Sponsored" data-testid="sponsored-badge" className={styles.sponsoredBadge} />
      )}

      {isNewRecipe && (
        <Chip small data-testid="new-recipe-badge">
          New Recipe
        </Chip>
      )}
      {isAllergenUpdate && (
        <Chip small data-testid="allergen-update-badge" colour="red">
          Allergen Update
        </Chip>
      )}
    </div>
  );
};

export default ProductBadges;
