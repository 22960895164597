import React from 'react';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { SitewideInPage } from '@johnlewispartnership/wtr-ingredients/ingredients/Sitewide/InPage';

const UnsupportedBrowserBanner = (
  <SitewideInPage variant="offer">
    <Typography noMargins element="p">
      This site will not work correctly unless you use a modern browser. Try downloading one of the
      following:
    </Typography>
    <Typography noMargins element="p">
      <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> |{' '}
      <a href="https://www.google.co.uk/chrome/">Google Chrome</a> |{' '}
      <a href="https://www.mozilla.org/en-GB/">Mozilla Firefox</a>
    </Typography>
  </SitewideInPage>
);

export default UnsupportedBrowserBanner;
