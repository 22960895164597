const reducer = (state, action) => ({
  ...state,
  partnerDiscountCardNumber: action?.result?.partnerDiscountCardNumber,
  promoCodes: {
    ...state.promoCodes,
    isAdding: false,
  },
  waiting: false,
});

export default reducer;
