import recipe from 'api/definitions/recipes/recipe';
import { DEFAULT_SORT_BY } from 'redux/modules/recipes/constants';

import { MEAL_PLANNER_FETCH_ACTIONS } from './types';

interface FetchRecipesFilter {
  title: string;
  values: string[];
}

export const fetchRecipes = (
  contentUrl: string,
  tags = [],
  filters: FetchRecipesFilter[] = [],
  currentPage: number,
  start = 0,
  size = 48,
  sortBy = DEFAULT_SORT_BY,
  search = '',
  onlyShoppable = false,
  excludeLegacy = false,
) => {
  return {
    apiCall: recipe.post({
      body: {
        filters,
        search: {
          term: search,
          tags,
        },
        preferences: {
          size,
          sortBy,
          start,
          onlyShoppable,
          excludeLegacy,
        },
      },
      endpoint: contentUrl,
      iHandleStatusCodes: [400, 404],
    }),
    contentUrl,
    start,
    currentPage,
    sortBy,
    types: MEAL_PLANNER_FETCH_ACTIONS,
  };
};
