import { createSelector } from 'reselect';

import locator from 'utils/locator';
import urls from 'constants/urls';

import {
  getActiveAddresses,
  getSelectedAddress,
  getAddressInError,
} from 'redux/modules/address/selectors/get-address-selectors';

const addressFormModalModalSelector = state => state.addresses.modal;
const chooseAddressModalSelector = state => state.addresses.chooseAddressModal;
const cannotAlterAddressSelector = state => state.addresses.cannotAlterAddress;

const getRoutedAddressModalFormProps = (state, hash) => {
  const { isOpen, isEdit } = addressFormModalModalSelector(state);
  const force = hash === '';
  const hasError = getAddressInError(state) !== null;

  return {
    navigateClosed: isOpen && force,
    clearNavigation: !isOpen && !force,
    hasError,
    isOpen,
    isEdit,
  };
};

const getDeleteAddressModalProps = state => {
  const address = getSelectedAddress(state);
  return { address };
};

const getCanEditOrDeleteAddressOption = state => {
  const { options = [] } = cannotAlterAddressSelector(state);
  return options.some(option => option === 'DELETE' || option === 'delete');
};

const optionsLoadingAddressId = state => {
  const { addressId = '' } = cannotAlterAddressSelector(state);
  return addressId;
};

const editOrDeleteAddressModalOpening = state => optionsLoadingAddressId(state) !== '';

const modalCloseUrl = (hash, state) => {
  const addresses = getActiveAddresses(state) || [];
  const isChooseAddress = hash === urls.addressModals.choose;
  const addAddress = addresses.length === 0 && hash === urls.addressModals.add;

  return `${locator.pathname}${locator.search}${
    isChooseAddress || addAddress ? '#' : urls.addressModals.choose
  }`;
};

const getAddressSelectedPosition = state => {
  const { choosenPosition } = chooseAddressModalSelector(state);
  return choosenPosition;
};

const getAddressChosenFromModal = createSelector(
  chooseAddressModalSelector,
  ({ address }) => address,
);

const getIsChosenAddressModalOpen = createSelector(
  chooseAddressModalSelector,
  ({ isOpen }) => isOpen ?? false,
);

export {
  getRoutedAddressModalFormProps,
  getDeleteAddressModalProps,
  chooseAddressModalSelector as getChooseAddressModal,
  getCanEditOrDeleteAddressOption,
  optionsLoadingAddressId,
  modalCloseUrl,
  getAddressSelectedPosition,
  editOrDeleteAddressModalOpening,
  getAddressChosenFromModal,
  getIsChosenAddressModalOpen,
};
