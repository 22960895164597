import createReducer from 'redux/createReducer';

import {
  AUTOSUGGEST_CLEAR,
  AUTOSUGGEST_FULFILLED,
  AUTOSUGGEST_PENDING,
  AUTOSUGGEST_REJECTED,
} from 'redux/modules/auto-suggest/actions/types';

import { deepFreeze } from 'utils/deepFreeze';
import autoSuggestClear from './auto-suggest-clear';
import autoSuggestFulfilled from './auto-suggest-fulfilled';
import autoSuggestPending from './auto-suggest-pending';
import autoSuggestRejected from './auto-suggest-rejected';

const initialState = deepFreeze({
  active: false,
  error: false,
  loading: false,
  results: {
    terms: [],
  },
  searchTerm: '',
});

const actionTypeReducerMap = new Map([
  [AUTOSUGGEST_CLEAR, autoSuggestClear],
  [AUTOSUGGEST_FULFILLED, autoSuggestFulfilled],
  [AUTOSUGGEST_PENDING, autoSuggestPending],
  [AUTOSUGGEST_REJECTED, autoSuggestRejected],
]);

export default createReducer(initialState, actionTypeReducerMap);
