import createReducer from 'redux/createReducer';

import clearCustomerSlot from 'redux/modules/customer-slot/reducers/clear-customer-slot';
import customerAddressesReceived from 'redux/modules/customer-slot/reducers/customer-slot-request-success';
import customerAddressesRequestFailed from 'redux/modules/customer-slot/reducers/customer-slot-request-failed';
import requestCustomerAddresseses from 'redux/modules/customer-slot/reducers/customer-slot-request';
import updateAddressName from 'redux/modules/customer-slot/reducers/update-address-name';
import cancelSlotRequest from 'redux/modules/customer-slot/reducers/cancel-slot';

import {
  CLEAR_CUSTOMER_SLOT,
  CUSTOMER_SLOT_REQUEST,
  CUSTOMER_SLOT_REQUEST_SUCCESS,
  CUSTOMER_SLOT_REQUEST_FAIL,
  UPDATE_ADDRESS_NAME,
  CANCEL_SLOT_REQUEST_ACTIONS,
} from 'redux/modules/customer-slot/actions/types';

import initialState from 'redux/modules/customer-slot/state/initial-state';

const actionTypeReducerMap = new Map([
  [CLEAR_CUSTOMER_SLOT, clearCustomerSlot],
  [CUSTOMER_SLOT_REQUEST, requestCustomerAddresseses],
  [CUSTOMER_SLOT_REQUEST_SUCCESS, customerAddressesReceived],
  [CUSTOMER_SLOT_REQUEST_FAIL, customerAddressesRequestFailed],
  [UPDATE_ADDRESS_NAME, updateAddressName],
  [CANCEL_SLOT_REQUEST_ACTIONS.CANCEL_SLOT_REQUEST, cancelSlotRequest],
  [CANCEL_SLOT_REQUEST_ACTIONS.CANCEL_SLOT_REQUEST_SUCCESS, cancelSlotRequest],
  [CANCEL_SLOT_REQUEST_ACTIONS.CANCEL_SLOT_REQUEST_FAILED, cancelSlotRequest],
]);

export default createReducer(initialState, actionTypeReducerMap);
