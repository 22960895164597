import root from 'window-or-global';
import { storageAvailable } from 'utils/storage-available';
import { saveCookie, getCookie } from 'utils/cookie';

type StorageType = 'localStorage' | 'sessionStorage';

/**
 * Creates a storage instance.
 *
 * If the storage type is natively available then it is used.
 *
 * If the storage type is not available, then a polyfilled store is created
 * backed by a cookie. Client-side the cookie is stored using browser cookie
 * storage. Server-side the cookie is store using async local storage if
 * available. See `src/utils/cookie.ts` for further implementation details.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * persisted.
 *
 * **Note:** Server-side storage set using this method is not currently sent to
 * the client. This **cannot** be relied upon as a server -> client state
 * transfer mechanism.
 *
 * @param {string} type The type of storage, one of: 'localStorage', 'sessionStorage'.
 * @returns {object} Native or polyfilled storage instance.
 */
export function createStorage(type: StorageType) {
  if (storageAvailable(type)) {
    return root[type];
  }

  let cookieName = `${type}PolyFill`;
  let expires: Date | null = new Date('Tue, 19 Jan 2038 03:14:07 GMT');

  if (type === 'sessionStorage') {
    cookieName += new Date().getTime();
    expires = null;
  }

  const rawData: string = getCookie(cookieName);
  let data: Record<string, string> = rawData ? JSON.parse(decodeURIComponent(rawData)) : {};
  let storage;

  const setData = () => {
    saveCookie(cookieName, encodeURIComponent(JSON.stringify(data)), expires);
    storage.length = Object.keys(data).length;
  };

  storage = {
    length: Object.keys(data).length,
    clear: () => {
      data = {};
      setData();
    },
    getItem: (key: string) => data[key] || null,
    key: (index: number) => {
      const key = Object.keys(data)[index];
      return key === undefined ? null : key;
    },
    removeItem: (key: string) => {
      delete data[key];
      setData();
    },
    setItem: (key: string, value: unknown) => {
      data[key] = `${value}`;
      setData();
    },
  };

  return storage;
}
