import orderGiftVouchers from 'api/definitions/orderGiftVouchers';
import get from 'lodash/get';
import handleErrors from 'redux/errors/handle-errors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import handleGiftVoucherError from './errors/handle-gift-voucher-error';
import { REMOVE_GIFT_VOUCHER } from './types';
import { handleTimeoutError } from './errors';

const handleResponse = dispatch => result => {
  const failures = get(result, 'data.setGiftVouchers.failures', null);

  if (failures) {
    const errorResponse = {
      status: failures.statusCode,
      response: {
        body: {
          type: 'REMOVE_GIFT_VOUCHER_ERROR',
          message: failures[0]?.type,
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftVoucherError]));
  }

  dispatch(handleErrors(result, [handleTimeoutError]));

  return result;
};

const confirmRemoveGiftVoucher = serialNumberToRemove => (dispatch, getState) => {
  const currentGiftVouchers = getState().order.giftVouchers.map(({ serialNumber }) => ({
    serialNumber,
  }));

  const giftVouchersExceptRemoved = currentGiftVouchers.filter(
    item => item.serialNumber !== serialNumberToRemove,
  );

  const customerOrderId = getCustomerOrderId(getState());

  const responseHandler = handleResponse(dispatch);

  return dispatch({
    apiCall: orderGiftVouchers.post({
      args: {
        customerOrderId,
        GiftVoucherInput: giftVouchersExceptRemoved,
      },
    }),
    types: REMOVE_GIFT_VOUCHER.triplet,
  }).then(responseHandler, responseHandler);
};

export default confirmRemoveGiftVoucher;
