import get from 'lodash/get';

import transformGridItems from 'redux/transforms/transform-grid-items';

const transformInterstitialsContent = ({ data } = {}) => {
  const interstitials = get(data, 'interstitials', {}) || {};
  const { content = {}, currentStep = null, hash, steps = [] } = interstitials;

  if (content === null) {
    return {};
  }

  return {
    ...transformGridItems(content),
    interstitials: {
      currentStep,
      hash,
      steps,
    },
  };
};

export default transformInterstitialsContent;
