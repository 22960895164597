import initialState from 'redux/modules/payment/state/initial-state';

const reducer = (state = initialState, { result }) => {
  const {
    processingDatabase: processingDB,
    id: sessionGUID,
    passcode: sessionPasscode,
  } = result || {
    processingDatabase: initialState.processingDB,
    id: initialState.sessionGUID,
    passcode: initialState.sessionPasscode,
  };
  const { currentApiRequest, errorStatusCode, errorCount, errorSource } = initialState;

  return {
    ...state,
    currentApiRequest,
    errorStatusCode,
    errorCount,
    errorSource,
    processingDB,
    sessionGUID,
    sessionPasscode,
  };
};

export default reducer;
