import writeMessage from 'utils/logging/write-message';
import queryString from 'query-string';

import parse from 'url-parse';

// consoleInfo, consoleWarn and consoleError are retained for standard logging in dev. mode
// e.g. stubs messages. They are NOT for production, any logs for production should be wrapped correctly
// with a dedicated function in this file.

// the name consoleInfo is used to distinguish between normal console.log (forbidden in linting) and console.info

export const consoleInfo = !__SERVER__ ? (...args) => console.log(...args) : () => {};
export const consoleWarn = !__SERVER__ ? (...args) => console.warn(...args) : () => {};
export const consoleError = !__SERVER__ ? (...args) => console.error(...args) : () => {};
export const consoleGroup = !__SERVER__ ? (...args) => console.group(...args) : () => {};
export const consoleGroupCollapsed = !__SERVER__
  ? (...args) => console.groupCollapsed(...args)
  : () => {};
export const consoleGroupEnd = !__SERVER__ ? (...args) => console.groupEnd(...args) : () => {};

export const sanitiseUrl = url => {
  const { pathname, query } = parse(url, true);
  const {
    access_token: accessToken,
    email,
    newPassword,
    oldPassword,
    password,
    token,
    ...queryParams
  } = query;

  const cleanParams = {
    ...queryParams,
    // deepcode ignore HardcodedNonCryptoSecret - placeholder
    ...(accessToken && { access_token: 'wmwmwmwmwm' }),
    ...(email && { email: 'wmwmwmwmwm' }),
    // deepcode ignore NoHardcodedPasswords - placeholder
    ...(newPassword && { newPassword: 'wmwmwmwmwm' }),
    // deepcode ignore NoHardcodedPasswords - placeholder
    ...(oldPassword && { oldPassword: 'wmwmwmwmwm' }),
    // deepcode ignore NoHardcodedPasswords - placeholder
    ...(password && { password: 'wmwmwmwmwm' }),
    // deepcode ignore HardcodedNonCryptoSecret - placeholder
    ...(token && { token: 'wmwmwmwmwm' }),
  };

  const cleanQueryString = queryString.stringify(cleanParams);

  const cleanUrl = !cleanQueryString ? pathname : `${pathname}?${cleanQueryString}`;
  return { pathname, query: cleanQueryString, url: cleanUrl };
};

export const ssrStartUpMessage = (message, environment) =>
  writeMessage({
    payload: {
      ...(environment && { environment }),
      logType: 'SSRSTARTUP',
      message,
    },
    priority: 'INFO',
  });

export const ssrGracefulShutdown = message =>
  writeMessage({
    payload: {
      logType: 'SSR_GRACEFUL_SHUTDOWN',
      message,
    },
    priority: 'INFO',
  });

export const ssrCacheHit = memoryCacheKey =>
  writeMessage({
    priority: 'INFO',
    payload: {
      logType: 'SSR_CACHE_HIT',
      message: `Mem cache hit for ${memoryCacheKey}`,
    },
  });

export const ssrStaleCacheHit = memoryCacheKey =>
  writeMessage({
    priority: 'INFO',
    payload: {
      logType: 'SSR_STALE_CACHE_HIT',
      message: `Mem cache stale hit for ${memoryCacheKey}`,
    },
  });

export const ssrPendingCacheHit = memoryCacheKey =>
  writeMessage({
    priority: 'INFO',
    payload: {
      logType: 'SSR_PENDING_CACHE_HIT',
      message: `Mem cache pending hit for ${memoryCacheKey}`,
    },
  });

export const ssrCacheSet = memoryCacheKey =>
  writeMessage({
    priority: 'INFO',
    payload: {
      logType: 'SSR_CACHE_SET',
      message: `Mem cache set for ${memoryCacheKey}`,
    },
  });

export const docEntryMessage = (
  requestId,
  customerId,
  url,
  clientUserAgent,
  ipAddress,
  isSupportedBrowser,
) =>
  writeMessage({
    payload: {
      ...sanitiseUrl(url),
      clientUserAgent,
      clientIpAddress: ipAddress,
      isSupportedBrowser,
      logType: 'DOCENTRY',
    },
    priority: 'INFO',
    requestId,
    customerId,
  });

export const docExitMessage = (
  requestId,
  customerId,
  url,
  clientUserAgent,
  httpStatus,
  redirectUrl,
  ipAddress,
  requestDuration,
) =>
  writeMessage({
    payload: {
      ...sanitiseUrl(url),
      ...(redirectUrl && { redirectUrl }),
      clientUserAgent,
      clientIpAddress: ipAddress,
      httpStatus,
      logType: 'DOCEXIT',
      requestDuration,
    },
    priority: httpStatus < 500 ? 'INFO' : 'ERROR',
    requestId,
    customerId,
  });

export const restEntryMessage = (url, method, requestId, data) => {
  const { pathname, query } = sanitiseUrl(url);
  writeMessage({
    ...(!!requestId && { requestId }),
    payload: {
      logType: 'RESTENTRY',
      method,
      pathname,
      query,
      url,
      ...(!!data && {
        details: {
          data: JSON.stringify(data),
        },
      }),
    },
    priority: 'INFO',
  });
};

export const restExitSuccessMessage = (url, method, httpStatus, correlationId, requestId, data) => {
  const { pathname, query } = sanitiseUrl(url);
  return writeMessage({
    ...(!!correlationId && { correlationId }),
    ...(!!requestId && { requestId }),
    payload: {
      ...(!httpStatus && { mock: true }),
      httpStatus: httpStatus || 200,
      logType: 'RESTEXIT',
      method,
      pathname,
      query,
      url,
      ...(!!data && {
        details: {
          data: JSON.stringify(data),
        },
      }),
    },
    priority: 'INFO',
  });
};

export const restExitErrorMessage = (
  url,
  method,
  httpStatus,
  response,
  correlationId,
  requestId,
  data,
) => {
  const { pathname, query } = sanitiseUrl(url);
  return writeMessage({
    ...(!!correlationId && { correlationId }),
    ...(!!requestId && { requestId }),
    payload: {
      httpStatus,
      logType: 'RESTEXIT',
      method,
      pathname,
      query,
      response,
      url,
      ...(!!data && {
        details: {
          data: JSON.stringify(data),
        },
      }),
    },
    priority: httpStatus === 401 ? 'INFO' : 'ERROR',
  });
};

export const fatalRenderError = (title, message) =>
  writeMessage({
    payload: {
      logType: 'FATAL_RENDER_ERROR',
      message: `Unable to recover to client render - error message returned to client. ${message}`,
      title,
    },
    priority: 'ERROR',
  });

export const unhandledExceptionError = (error, origin) => {
  return writeMessage({
    payload: {
      logType: 'UNHANDLED_EXCEPTION_ERROR',
      title: origin,
      message: error.message,
    },
    priority: 'ERROR',
  });
};

export const unhandledRejectionError = error => {
  return writeMessage({
    payload: {
      logType: 'UNHANDLED_REJECTION_ERROR',
      message: `A promise rejected without being handled`,
      httpErrorMessage: error.message,
      httpStatus: error.response?.status,
    },
    priority: 'WARN',
  });
};

export const renderError = title =>
  writeMessage({
    payload: {
      logType: 'RENDER_ERROR',
      message: 'Fall back to client render',
      title,
    },
    priority: 'WARN',
  });

export const featureFlagsError = ({ message, error = {} }) =>
  writeMessage({
    payload: {
      message,
      logType: 'FEATUREFLAGS',
      httpErrorMessage: error.message,
    },
    priority: 'ERROR',
  });
