import { ModalButton } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal/Buttons';
import { trackModalClosed, trackModalOpened } from 'analytics/modal-tracking';
import { IOpenModalArgs, IWebviewOptions } from 'redux/modules/common-modal/actions';
import interpolate from 'utils/interpolate';
import { consoleInfo } from 'utils/logging';
import root from 'window-or-global';
import type { NativeDialogAction, NativeDialogData } from './app-js-integrations';
import { appLinks, deepLinkForWebUrl } from './navigation';

const isDialogApiAvailable = () =>
  typeof root.Android?.showDialog === 'function' ||
  typeof root.webkit?.messageHandlers?.dialog?.postMessage === 'function';

function getActions(
  { buttons = [{ buttonText: 'OK', theme: 'primary' }] }: IOpenModalArgs,
  webview: NonNullable<IWebviewOptions>,
): NativeDialogAction[] {
  const result = buttons.map((button: ModalButton & { primary?: boolean }, index) => ({
    action: button.buttonText ?? 'OK',
    url: deepLinkForWebUrl(webview.ctas?.[index]),
    value: (button.buttonValue instanceof Array
      ? button.buttonValue[0]
      : button.buttonValue
    )?.toString(),
    isPrimary: button.primary || button.theme?.startsWith('primary'),
  }));

  if (webview.hasCustomerCareButton) {
    result.push({
      action: 'Call Customer Care',
      url: appLinks.customerCare,
      value: undefined,
      isPrimary: undefined,
    });
  }

  return result;
}

function getMessageText(
  { messageText, messageIsHtml, messageTextParams }: IOpenModalArgs,
  { nativeMessage }: IWebviewOptions,
) {
  const message = nativeMessage ?? messageText ?? [];
  const text = message
    .map(msg => (messageTextParams ? interpolate(msg, messageTextParams) : msg))
    .join('');

  if (messageIsHtml) {
    const parser = new DOMParser();
    const html = parser.parseFromString(text, 'text/html');

    // istanbul ignore next
    return html.body.textContent?.trim() ?? '';
  }

  return text;
}

let currentModalAndCustomer: [modal: IOpenModalArgs, user: string] | null = null;

function trackNativeModalOpened(
  { id, severity, titleText: title }: IOpenModalArgs,
  customerId: string,
) {
  trackModalOpened({
    id,
    severity,
    title,
    customerId,
  });
}

/**
 * Attempts to properly serialize the modal and sends it to the native APIs of the platform.
 * @param modal The website's modal implementation
 * @returns true if the modal's API was successfully called
 */
export function openNativeDialog(modal: IOpenModalArgs, customerId: string) {
  if (!modal.webview) {
    return false;
  }

  const decoded: NativeDialogData = {
    title: modal.titleText ?? null,
    message: getMessageText(modal, modal.webview),
    actions: getActions(modal, modal.webview),
  };

  currentModalAndCustomer = [modal, customerId];

  if (root?.Android?.showDialog) {
    trackNativeModalOpened(modal, customerId);
    root.Android.showDialog(JSON.stringify(decoded));
    return true;
  }

  if (root?.webkit?.messageHandlers?.dialog?.postMessage) {
    trackNativeModalOpened(modal, customerId);
    root.webkit.messageHandlers.dialog.postMessage(decoded);
    return true;
  }

  consoleInfo('[webview] Webview dialog bridge does not exist.', decoded);
  currentModalAndCustomer = null;
  return false;
}

interface DismissDialogEvent extends Event {
  dialogValue?: string;
}

function trackNativeModalClosed(
  { id, severity, titleText: title }: IOpenModalArgs,
  { buttonText, buttonValue }: ModalButton | undefined = {},
  customerId: string,
) {
  trackModalClosed({
    id,
    severity,
    title,
    response: (buttonText || buttonValue) as string,
    customerId,
  });
}

function dismissModalDialog(event: DismissDialogEvent) {
  if (!currentModalAndCustomer) return;

  const [currentModal, customerId] = currentModalAndCustomer;
  const { dialogValue } = event;

  if (dialogValue) {
    const button = currentModal.buttons?.find(
      btn =>
        (Array.isArray(btn.buttonValue) ? btn.buttonValue[0] : btn.buttonValue) === dialogValue,
    );

    if (button) {
      button.onClick?.(event as never);
      currentModal.onCloseCallback?.(button.buttonValue);
      currentModalAndCustomer = null;

      trackNativeModalClosed(currentModal, button, customerId);

      return;
    }
  }

  currentModal.onCloseCallback?.(dialogValue);
  currentModalAndCustomer = null;

  trackNativeModalClosed(currentModal, undefined, customerId);
}

export function addWebViewDialogEventListeners() {
  if (isDialogApiAvailable()) {
    root.addEventListener('dismissDialog', dismissModalDialog);
  }
}
