const reducer = state => ({
  ...state,
  errorStatusCode: null,
  errorResponse: undefined,
  errorSource: null,
  requestTimedOut: false,
  sessionTimedOut: false,
});

export default reducer;
