import React, { useMemo, type ComponentClass, type FunctionComponent } from 'react';
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface RouteProps<TParams extends string | Record<string, string | undefined> = string> {
  match: {
    url: string;
    params: ReturnType<typeof useParams<TParams>>;
  };
  location: ReturnType<typeof useLocation>;
  history: {
    push(to: To, state?: unknown): void;
    replace(to: To, state?: unknown): void;
    go(n: number): void;
    goBack(): void;
    goForward(): void;
    location: ReturnType<typeof useLocation>;
  };
}

/** Injects the match, location and history props into the component */
export function withRouteProps<TProps extends object = object>(
  Component: FunctionComponent<TProps> | ComponentClass<TProps>,
) {
  function WithRouteProps(props: Omit<TProps, 'match' | 'location' | 'history'>) {
    const urlParams = useParams();
    const location = useLocation();

    const match = useMemo(() => {
      return { url: location.pathname, params: urlParams };
    }, [location.pathname, urlParams]);

    const navigate = useNavigate();
    const history = useMemo(() => {
      return {
        push: (to: To, state?: unknown) => navigate(to, { state }),
        replace: (to: To, state?: unknown) => navigate(to, { state, replace: true }),
        go: (n: number) => navigate(n),
        goBack: () => navigate(-1),
        goForward: () => navigate(1),
        location,
      };
      /*
      missed props are:
      length
      action
      entries
      canGo
      block
      listen - This seems to be the only one still used
      */
    }, [location, navigate]);

    const newProps = { ...props, navigate, match, location, history } as TProps;

    return <Component {...newProps} />;
  }

  WithRouteProps.displayName = `withRouteProps(${Component.displayName ?? Component.name})`;

  return WithRouteProps;
}
