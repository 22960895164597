import { arrayOf, node, oneOfType } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RedirectToLoginPage } from 'utils/login/RedirectToLoginPage';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';
import { makeActions } from './action-factories';
import actions from './actions';
import { isAuthorised } from './authorisation';

export const RouteChange = ({ children }) => {
  const store = useStore();
  const { pathname, search } = useLocation();
  const location = useMemo(() => ({ pathname, search }), [pathname, search]); // get stable location
  const runtimeActions = useMemo(() => makeActions({ location, store }), [location, store]);
  const blockRender = runtimeActions.length > 0;
  const customerSlotLoaded = useSelector(hasCustomerSlotInitialLoadCompleted);
  const trolleyLoaded = useSelector(hasTrolleyLoadCompleted);

  useEffect(() => {
    if (customerSlotLoaded && trolleyLoaded) {
      runtimeActions.concat(actions).forEach(action => action({ location, store }));
    }
  }, [runtimeActions, location, store, customerSlotLoaded, trolleyLoaded]);

  if (!isAuthorised({ location, store })) return <RedirectToLoginPage />;
  if (blockRender || !children) return null;
  return <div>{children}</div>;
};

export default RouteChange;

RouteChange.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

RouteChange.defaultProps = {
  children: null,
};
