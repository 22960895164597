import root from 'window-or-global';
import * as SentryReact from '@sentry/react';
import type { ErrorEvent } from '@sentry/types';
import env from 'env/env';
import { getFeatureFlags } from './feature-flags';

const config: SentryReact.BrowserOptions = {
  attachStacktrace: true,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  dsn: 'https://c2532dd7a60242628def80ac35a40dae@sentry.io/269866',
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    `TypeError: Cannot read property '_avast_submit' of undefined`,
    // Facebook borked
    'fb_xd_fragment',
    // animation frame errors - supported by all Waitrose supported browsers
    'window.cancelAnimationFrame',
    'window.requestAnimationFrame',
    /google_tag_manager\[['"]GTM-[0-z]{6}['"]\]\.macro\(/i,
    // Ignore http errors
    /Error: Unsuccessful HTTP Response.*/i,
    // Ignores generateSession expected 401 errors
    /Error: Unauthorized.*/i,
    // Ignores generateSession expected 401 errors
    /Error: {"errors":\[{"message":"Unauthorised"*/i,
    /Error: Not Found.*/i,
    /Error: Internal Server Error.*/i,
    /Error: HTTP\/2\.0.*/i,
    /Error: Conflict.*/i,
    /Error: Response timeout of 30000ms exceeded.*/i,
    // Ignore vague error that's not affecting customers (most likely 3rd parties or extensions) - https://github.com/getsentry/sentry-javascript/issues/2546
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  ],
  maxBreadcrumbs: 150,
  release: root.SENTRY_RELEASE,
};

/**
 * Exceptions to be ignored if the stacktrace includes the matching filename.
 *
 * This extends upon the Sentry config for `denyUrls` (which only considers the
 * last script URL in the stacktrace) and `ignoreErrors` (which is a blanket
 * ignore across all script URLs), neither of which can provide the required
 * level of targeting to ignore some third party errors.
 */
const ignoredExceptionsByStacktraceFilename = [
  /**
   * Pinterest errors due to missing error handling for `fetch()` timeouts.
   *
   * REF: https://waitrose.sentry.io/issues/4557658590/events/f6e8930e3dab4b64808bad092d8a6af4/
   */
  { type: 'TypeError', value: 'Load failed', filename: /s\.pinimg\.com/ },
];

const isIgnoredEvent = (event: ErrorEvent): boolean => {
  const exceptionValues = event.exception?.values;

  if (!exceptionValues) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const exception of exceptionValues) {
    // eslint-disable-next-line no-restricted-syntax
    for (const ignoredExceptionByStacktraceFilename of ignoredExceptionsByStacktraceFilename) {
      if (
        ignoredExceptionByStacktraceFilename.type === exception.type &&
        ignoredExceptionByStacktraceFilename.value === exception.value &&
        exception.stacktrace?.frames?.some(
          frame =>
            frame?.filename && ignoredExceptionByStacktraceFilename.filename.test(frame.filename),
        )
      ) {
        return true;
      }
    }
  }

  return false;
};

// eslint-disable-next-line import/no-mutable-exports
let sentry: typeof SentryReact | null = null;

const { sentry: sentryEnabled = false, sentryContentsquare = false } = getFeatureFlags() || {};

if (sentryEnabled) {
  if (__SERVER__) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports, global-require
    const SentryNode = require('@sentry/node');

    SentryNode.init({
      ...config,
      environment: process.env.ENVIRONMENT,
      release: __GIT_HASH_LONG__,
      serverName: 'SSR',
      initialScope: {
        tags: { origin: 'back-end' },
      },
    });

    sentry = SentryNode;
  } else {
    const environment = env.name;

    const beforeSend = (event: ErrorEvent) => {
      if (sentryContentsquare) {
        // REF: https://support.contentsquare.com/hc/en-gb/articles/4411095385106-Sentry
        // Name: Sentry / Contentsquare Integration
        // Version: 1.0.0
        (function csBeforeSend() {
          const eventPayload = { ...event };

          /**
           * Fix error in the Contentsquare Sentry Integration:
           * - https://waitrose.sentry.io/issues/6089489112/events/32fca63811ec45218a47559fbcf19024/
           * - https://waitrose.sentry.io/issues/6080338400/events/be3d5bb9968f495fb12413dedf1384da/
           * - https://waitrose.sentry.io/issues/6080465969/events/b5375b1cbc3d4d3a81e9c111a7ab8eb5/
           */
          eventPayload.breadcrumbs = eventPayload.breadcrumbs || [];

          let disableCallback = false;

          // eslint-disable-next-line no-underscore-dangle
          root._uxa = root._uxa || [];

          function csCallback() {
            if (!disableCallback) {
              disableCallback = true;

              root.CS_CONF?.integrations_handler?.sentry?.(eventPayload);
            }
          }

          // eslint-disable-next-line no-underscore-dangle
          root._uxa.push(['afterPageView', csCallback]);
        })();
      }

      if (isIgnoredEvent(event)) {
        return null;
      }

      return event;
    };

    SentryReact.init({
      ...config,
      beforeSend,
      environment,
      initialScope: {
        tags: { origin: 'front-end' },
      },
    });

    // TODO: What is this for? It feels wrong to call init() twice. It seems to be used only in formbuilder: src/formbuilder/index.js
    root.sentry = function init() {
      SentryReact.init({
        ...config,
        beforeSend,
        environment,
        initialScope: {
          tags: { origin: 'front-end' },
        },
      });
      return sentry;
    };

    sentry = SentryReact;
  }
}

export { sentry };
