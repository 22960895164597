import createReducer from 'redux/createReducer';
import * as slotExperienceTypes from 'redux/modules/slot-experience/actions/types';
import initialState from 'redux/modules/slot-experience/state/initial-state';

import slotExperienceSuccess from './slot-experience-success';

const actionTypeReducerMap = [
  [slotExperienceTypes.SLOT_EXPERIENCE.success, slotExperienceSuccess],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
