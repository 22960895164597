/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Tabs, { Tab, TabPanel } from '@johnlewispartnership/wtr-ingredients/ingredients/Tabs';
import { Print } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { SmallButton } from '@johnlewispartnership/wtr-ingredients/ingredients/SmallButton';
import { StarRating } from '@johnlewispartnership/wtr-ingredients/ingredients/StarRating';
import env from 'env/env';
import { Recipe } from 'api/definitions/recipes/index.d';
import { trackRecipeTabNavigation, trackRecipe } from 'analytics/recipes-tracking';
import {
  selectImage,
  checkZeroNutritional,
  prepareRecipeTags,
  selectLogo,
} from 'utils/format-recipe';
import Picture from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/Image/Picture';
import RecipesTitle from './Title';
import RecipesDescription from './Description';
import RecipesNutritional from './Nutritional';
import RecipesIngredients from './Ingredients';
import RecipesMethod from './Method';
import Share from './Share';
import RecipesCourseDetails from './CourseDetails';
import RecipesAllergens from './Allergens';
import Disclaimer from './Shoppable/Disclaimer';
import styles from './index.scss';

type RecipeDetailProps = {
  recipe: Recipe;
  loading: boolean;
  showPrint: boolean;
  isPrinting?: boolean;
  children?: JSX.Element;
  rating?: {
    average?: number;
    totalRatings: number;
    loaded?: boolean;
    error?: boolean;
  };
  ratings: React.RefObject<HTMLDetailsElement>;
  headingLevel?: number;
};

export const RecipeDetail = ({
  recipe,
  loading,
  showPrint,
  isPrinting = false,
  children,
  rating = { totalRatings: 0 },
  ratings,
  headingLevel = 1,
}: RecipeDetailProps) => {
  const {
    id,
    title,
    description = RecipesDescription.defaultProps.value as Recipe['description'],
    images = [],
    makes,
    cookingTime,
    nutritional = { allergens: [] },
    ingredientGroups,
    course = { id: '', title: '' },
    method,
    plusTimingNotes,
    preparationTime,
    servingSize,
    servingNote,
    totalTime,
    cooksTip,
    addToDrink,
    dietaryTags = [],
    nutritionalTags = [],
    isShoppable,
    isMealMath = false,
    logos = [],
  } = recipe;
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const { pathname } = useLocation();
  const canonicalHref = `https://${env.prodHost}${pathname}`;

  const { title: courseTitle } = course;

  const image = images[0];
  const imageAlt = image?.alternativeText || title;
  const logo = selectLogo(logos);

  const changeTab = (index: number) => {
    setActiveTabIndex(index);
    trackRecipeTabNavigation(index);
  };

  const showLogoForMealMaths = isMealMath && logo;

  /* istanbul ignore next */
  const recipeTags = prepareRecipeTags(dietaryTags, nutritional, nutritionalTags);

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.imagewrapper}>
          {image && (
            <Picture
              altText={imageAlt}
              fileReference={image.fileReference}
              scene7Url={image.dynamicImages?.defaultQuality}
              scene7UrlMobile={image.dynamicImages?.highQuality85}
              scene7UrlTablet={image.dynamicImages?.highQuality90}
              height="300"
              width="300"
            />
          )}
        </div>
        <div className={styles.heroContent}>
          {loading && <div className={styles.placeholder} />}
          {!loading && (
            <>
              <div className={styles.mealMathsWrapper}>
                <div className={styles.title}>
                  <RecipesTitle value={title} headingLevel={headingLevel} />
                </div>
                <div>
                  {showLogoForMealMaths && (
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      className={styles.recipeLogo}
                      data-testid="recipe-logo"
                      height="48"
                      width="48"
                    />
                  )}
                </div>
              </div>
              <div className={styles.description}>
                <RecipesDescription value={description} disableMaxParagraphs={isPrinting} />
              </div>
              <Link
                className={styles.ratingSummary}
                to="#rating"
                onClick={e => {
                  setTimeout(() => {
                    (
                      (ratings.current?.querySelector('form > span:first-child input') ||
                        ratings.current) as HTMLInputElement
                    )?.focus();
                    trackRecipe(id, { event: 'click_rate_recipe' });
                  }, 0);
                  e.preventDefault();
                }}
              >
                <StarRating
                  className={styles.rating}
                  rating={rating.average!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  starSize="medium"
                  itemProp="aggregateRating"
                >
                  {rating.loaded && (
                    <>
                      (<span itemProp="reviewCount">{rating.totalRatings}</span>)
                    </>
                  )}{' '}
                  {rating.error && <span>Failed to fetch ratings</span>}
                  <span className={styles.rate}>Rate this recipe</span>
                </StarRating>
              </Link>
              <div className={styles.allergens}>
                <RecipesAllergens allergens={recipeTags} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classNames(styles.content)}>
        {!loading &&
          ((
            <>
              <RecipesCourseDetails
                {...{
                  cookingTime,
                  courseTitle,
                  makes,
                  plusTimingNotes,
                  preparationTime,
                  servingSize,
                  servingNote,
                  totalTime,
                }}
              />
              <div>
                <Share
                  recipeName={title}
                  recipeDescription={description?.plainText}
                  recipeImage={selectImage({ url: canonicalHref }, image)}
                />
                {showPrint && (
                  <SmallButton
                    theme="secondary"
                    label="Print"
                    onClick={() => window.print()}
                    startIcon={<Print />}
                    className={styles.printButton}
                  />
                )}
              </div>
              {isShoppable && <Disclaimer />}
              <div className={styles.tabs}>
                <Tabs activeIndex={activeTabIndex} onChange={changeTab}>
                  <Tab label="Ingredients" />
                  <Tab label="Method" />
                  {!checkZeroNutritional(nutritional) ? <Tab label="Nutrition" /> : <></>}
                </Tabs>
                <TabPanel controlledByTabIndex={0} activeTabIndex={activeTabIndex}>
                  <div className={styles.ingredients}>
                    <Typography
                      element="h2"
                      styleAs="sectionHeading"
                      className={classNames(styles.printHeading, 'sr-only')}
                    >
                      Ingredients
                    </Typography>
                    <RecipesIngredients ingredientGroups={ingredientGroups} />
                  </div>
                </TabPanel>
                <TabPanel controlledByTabIndex={1} activeTabIndex={activeTabIndex}>
                  <div className={styles.method}>
                    <Typography
                      element="h2"
                      styleAs="sectionHeading"
                      className={classNames(styles.printHeading, 'sr-only')}
                    >
                      Method
                    </Typography>
                    <RecipesMethod method={method} cooksTip={cooksTip} andToDrink={addToDrink} />
                  </div>
                </TabPanel>
                {!checkZeroNutritional(nutritional) && (
                  <TabPanel controlledByTabIndex={2} activeTabIndex={activeTabIndex}>
                    <div className={styles.nutritional}>
                      <Typography
                        element="h2"
                        styleAs="sectionHeading"
                        className={classNames(styles.printHeading, 'sr-only')}
                      >
                        Nutritional
                      </Typography>
                      <RecipesNutritional makes={makes} {...nutritional} />
                    </div>
                  </TabPanel>
                )}
              </div>
              {children}
            </>
          ) as ReactElement)}
      </div>
    </>
  );
};
