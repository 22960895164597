import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const nameSpace = 'waitrose/checkout/';

export const CHECKOUT_LOADED = `${nameSpace}CHECKOUT_LOADED` as const;
export const CLEAR_CHECKOUT_ERRORS = `${nameSpace}CLEAR_CHECKOUT_ERRORS` as const;
export const PLACE_AND_UPDATE_ORDER = apiPromiseTypes('placeAndUpdateOrder', 'patch');
export const PLACE_ORDER_ORIGIN = `${nameSpace}PLACE_ORDER_ORIGIN` as const;
export const PLACE_ORDER_ORIGIN_MOBILE_AVAILABLE =
  `${nameSpace}PLACE_ORDER_ORIGIN_MOBILE_AVAILABLE` as const;
export const SAVE_NEW_CARD = `${nameSpace}SAVE_NEW_CARD` as const;
export const START_PLACE_ORDER = `${nameSpace}START_PLACE_ORDER` as const;
export const STOP_WAITING = `${nameSpace}STOP_WAITING` as const;
export const TRA_SESSION_ID = `${nameSpace}TRA_SESSION_ID` as const;
export const WAITING = `${nameSpace}WAITING` as const;
