import { getOrder } from 'redux/modules/order/selectors';
import orderStatus from 'constants/orderStatus';
import { canInstantCheckout } from 'redux/modules/trolley/selectors/can-instant-checkout';
import { getPaymentTypes } from '../utilities/get-payment-types';

export const startPlaceOrder = state => {
  const orderState = getOrder(state);
  const isAmend = orderState?.order?.status === orderStatus.AMENDING;
  const instantCheckoutEligibility = canInstantCheckout(state);

  return {
    event: 'checkout_click_confirm',
    instant_checkout_eligibility: instantCheckoutEligibility,
    ecommerce: {
      checkout: {
        actionField: {
          option: isAmend ? 'amend' : 'purchase',
          payment_type: getPaymentTypes(state),
        },
      },
    },
  };
};
