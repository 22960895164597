import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import { KEY_ENTER } from 'constants/keys';

import { slowlyScrollElementHorizontallyIntoView } from 'utils/scroll';

import styles from 'components/MultiSearch/Button/MultiSearchButton.scss';

const SCROLL_DEBOUNCE_MS = 200;
const BUTTON_SCROLL_PADDING = 150;

const MultiSearchButton = ({ loading, onClick, selected, value }) => {
  const ref = useRef();

  const scrollIntoView = debounce(() => {
    setTimeout(() => {
      const { current: element } = ref;

      if (selected && element) {
        slowlyScrollElementHorizontallyIntoView(element, BUTTON_SCROLL_PADDING);
      }
    }, 0);
  }, SCROLL_DEBOUNCE_MS);

  useEffect(() => {
    scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handler = event => {
    // Prevent Chrome's crude scrolling into view before firing handler
    event.preventDefault();

    return onClick(event);
  };

  const onKeyDown = event => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      return handler(event);
    }

    return false;
  };

  return (
    <div className={styles.multiSearchButton} ref={ref}>
      <Button
        data-testid={`msv-${selected ? 'active' : 'label'}`}
        label={value}
        onClick={handler}
        theme={selected ? 'primary' : 'secondary'}
        onKeyDown={onKeyDown}
        width="fit"
        disabled={loading}
        selected={selected}
      />
    </div>
  );
};

MultiSearchButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

MultiSearchButton.defaultProps = {
  loading: false,
  selected: false,
};

export default MultiSearchButton;
