export const GET_CMS_PAGE_LOADING = 'waitrose/cms-page/loading';
export const GET_CMS_PAGE_SUCCESS = 'waitrose/cms-page/success';
export const GET_CMS_PAGE_FAILURE = 'waitrose/cms-page/failure';
export const GET_CMS_PAGE_NOT_FOUND = 'waitrose/cms-page/not-found';

export interface CmsPageLoadingStateActions {
  type:
    | typeof GET_CMS_PAGE_LOADING
    | typeof GET_CMS_PAGE_SUCCESS
    | typeof GET_CMS_PAGE_FAILURE
    | typeof GET_CMS_PAGE_NOT_FOUND;
}
