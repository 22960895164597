import { UPDATE_SHOPPABLE_INGREDIENT_BY_ID } from 'redux/modules/recipes/actions/types';

export const updateShoppableIngredientById = ({
  recipeId,
  ingredientId,
  groupId: shoppableProductIndex,
  updatedIngredient,
}) => ({
  type: UPDATE_SHOPPABLE_INGREDIENT_BY_ID,
  recipeId,
  ingredientId,
  groupId: shoppableProductIndex,
  updatedIngredient,
});
