import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

const OutOfStock = ({ message, onPDP, className, children }) => {
  const classes = classNames(styles.outOfStock, className, {
    [styles.onPDP]: onPDP,
  });

  return (
    <div className={classes}>
      <span>{message}</span>
      {children}
    </div>
  );
};

OutOfStock.propTypes = {
  className: PropTypes.string,
  onPDP: PropTypes.bool,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
};

OutOfStock.defaultProps = {
  className: undefined,
  onPDP: false,
  children: undefined,
};

export default OutOfStock;
