import setGiftVouchersSuccess from 'redux/modules/order/reducer/set-gift-vouchers-success';

const reducer = (state, action) => {
  const order = action?.result?.data?.setGiftVouchers?.order ?? {};
  const giftVouchersSuccess = setGiftVouchersSuccess(state, order);

  return {
    ...state,
    ...giftVouchersSuccess,
  };
};

export default reducer;
