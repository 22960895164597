const reducer = (state, action) => {
  const { status, response: { body } = {} } = action.error;

  if (status === 409 && body && body.code === 'SECURITY_NEEDED') {
    const details = body.details.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    return {
      ...state,
      ...details,
      errorStatusCode: null,
      errorCount: 0,
      requireCardAuth: true,
      cardAuthenticated: false,
    };
  }

  if (status === 409 && body?.code === 'ORDER_ORCHESTRATION_004') {
    return { ...state };
  }

  return {
    ...state,
    errorStatusCode: status,
    errorResponse: { ...body },
    errorCount: state.errorCount + 1,
    errorSource: state.currentApiRequest,
  };
};

export default reducer;
