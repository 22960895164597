import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import {
  pending as loadPreferencesPending,
  fulfilled as loadPreferencesFulfilled,
  rejected as loadPreferencesRejected,
} from './load-preferences';
import {
  pending as setConsentPending,
  fulfilled as setConsentFulfilled,
  rejected as setConsentRejected,
} from './set-consent';
import {
  pending as getMembershipPending,
  fulfilled as getMembershipFulfilled,
  rejected as getMembershipRejected,
  removeMarketingPreferences,
} from './get-membership-status';
import { reportMarketingConsents } from './report-marketing-consents';

import {
  LOAD_PREFERENCES_PENDING,
  LOAD_PREFERENCES_FULFILLED,
  LOAD_PREFERENCES_REJECTED,
  getMembershipActionTypes,
  SET_CONSENT_PENDING,
  SET_CONSENT_FULFILLED,
  SET_CONSENT_REJECTED,
  REMOVE_MARKETING_PREFERENCES,
  REPORT_MARKETING_CONSENTS,
} from '../actions/types';

const initialState = deepFreeze({
  getMembershipStatusInitialLoadComplete: false,
  loading: false,
  schemes: {
    waitrose: undefined,
    mywaitrose: undefined,
    johnlewis: undefined,
    johnlewisfinancialservices: undefined,
  },
  membership: null,
  error: undefined,
  marketingConsents: {},
});

const actionTypeReducerMap = new Map([
  [LOAD_PREFERENCES_PENDING, loadPreferencesPending],
  [LOAD_PREFERENCES_FULFILLED, loadPreferencesFulfilled],
  [LOAD_PREFERENCES_REJECTED, loadPreferencesRejected],
  [getMembershipActionTypes.request, getMembershipPending],
  [getMembershipActionTypes.success, getMembershipFulfilled],
  [getMembershipActionTypes.failure, getMembershipRejected],
  [SET_CONSENT_PENDING, setConsentPending],
  [SET_CONSENT_FULFILLED, setConsentFulfilled],
  [SET_CONSENT_REJECTED, setConsentRejected],
  [REMOVE_MARKETING_PREFERENCES, removeMarketingPreferences],
  [REPORT_MARKETING_CONSENTS, reportMarketingConsents],
]);

export default createReducer(initialState, actionTypeReducerMap);
