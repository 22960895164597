export const GET_CMS_HYBRID_RECIPES_PAGE_LOADING = 'waitrose/cms-hybrid-page/loading';
export const GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS = 'waitrose/cms-hybrid-page/success';
export const GET_CMS_HYBRID_RECIPES_PAGE_FAILURE = 'waitrose/cms-hybrid-page/failure';

export const GET_CMS_HYBRID_RECIPES_PAGE: readonly [string, string, string] = [
  GET_CMS_HYBRID_RECIPES_PAGE_LOADING,
  GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS,
  GET_CMS_HYBRID_RECIPES_PAGE_FAILURE,
];

export const GET_CMS_TAGGED_RECIPES_LOADING = 'waitrose/tagged-recipes/loading';
export const GET_CMS_TAGGED_RECIPES_SUCCESS = 'waitrose/tagged-recipes/success';
export const GET_CMS_TAGGED_RECIPES_FAILURE = 'waitrose/tagged-recipes/failure';
export const GET_CMS_FILTERED_SORTED_RECIPES_SUCCESS = 'waitrose/filtered-sorted-recipes/success';
export const INCREMENT_CURRENT_RECIPES_PAGE =
  'waitrose/filtered-sorted-recipes/currentPageincrement';
export const RESET_CURRENT_RECIPES_PAGE = 'waitrose/filtered-sorted-recipes/currentPagereset';
export const RESET_FILTERED_SORTED_RECIPES = 'waitrose/filtered-sorted-recipes/reset';
export const RECIPES_REMEMBER_FOCUS = 'waitrose/filtered-sorted-recipes/rememberfocus';
