import { apiCallback } from 'api';

const statsDefinition = {
  service: 'customerorder-orchestration',
  endpoint: 'customerorders/stats',
  verbs: ['get'],
  defaultVersion: 1,
};

export default {
  getStats: apiCallback(statsDefinition, 'get'),
};
