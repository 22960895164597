import createReducer from 'redux/createReducer';
import {
  BRANCH_SEARCH_POSTCODE_INVALID,
  GET_BRANCH_BY_ID,
  GET_BRANCHES_BY_POSTCODE,
  GET_BRANCHES_LIST,
} from 'redux/modules/branches/actions/types';
import { LOCATION_CHANGE } from 'redux-first-history';

import initialState from '../state/initial-state';

import requestBranches from './request-branches';
import branchesReceived from './branches-received';
import branchReceived from './branch-received';
import branchesRequestFailed from './branches-request-failed';
import postcodeInvalid from './search-postcode-invalid';
import clearOnRouteChange from './clear-on-route-change';

const actionTypeReducerMap = [
  [GET_BRANCH_BY_ID.request, requestBranches],
  [GET_BRANCH_BY_ID.success, branchReceived],
  [GET_BRANCH_BY_ID.failure, branchesRequestFailed],
  [GET_BRANCHES_BY_POSTCODE.request, requestBranches],
  [GET_BRANCHES_BY_POSTCODE.success, branchesReceived],
  [GET_BRANCHES_BY_POSTCODE.failure, branchesRequestFailed],
  [GET_BRANCHES_LIST.request, requestBranches],
  [GET_BRANCHES_LIST.success, branchesReceived],
  [GET_BRANCHES_LIST.failure, branchesRequestFailed],
  [BRANCH_SEARCH_POSTCODE_INVALID, postcodeInvalid],
  [LOCATION_CHANGE, clearOnRouteChange],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
