import { connect } from 'react-redux';

import { getItemTotalEstimatedCost } from 'redux/modules/trolley/selectors/get-totals';
import { getTotalNumberOfItems } from 'redux/modules/trolley/selectors/number-of-items';
import getIsTrolleyInitialLoading from 'redux/modules/trolley/selectors/get-loading-customer-info';

import ShoppingSummary from './ShoppingSummary';

export default connect(state => ({
  loadingStatus: getIsTrolleyInitialLoading(state) || state.trolley.loadingAdd,
  totalEstimatedCost: getItemTotalEstimatedCost(state),
  totalNumberOfItems: getTotalNumberOfItems(state),
}))(ShoppingSummary);
