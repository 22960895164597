import trolleyItems from 'api/definitions/trolleyItems';
import {
  TROLLEY_ITEM_UPDATE_FAILED,
  TROLLEY_ITEM_UPDATED,
  TROLLEY_ITEM_UPDATING,
  UPDATE_CAN_SUBSTITUTE,
} from 'redux/modules/trolley/actions/types';

import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';

import { getItemById } from 'redux/modules/trolley/selectors/get-item';
import { getProductIdByLineNumber } from 'redux/modules/entities/selectors/products';

export function allowSubstitutions(trolleyItemId, canSubstitute) {
  return (dispatch, getState) => {
    let item = getItemById(getState(), trolleyItemId);
    const { productId, lineNumber } = item;

    if (!productId) {
      const missingProductId = getProductIdByLineNumber(getState(), lineNumber);
      item = { ...item, productId: missingProductId };
    }

    dispatch({
      type: UPDATE_CAN_SUBSTITUTE,
      productId: item.productId,
      canSubstitute,
    });
    dispatch({ type: TROLLEY_ITEM_UPDATING });
    return trolleyItems
      .updateItem({
        trolleyItemId,
        body: { ...item, canSubstitute },
      })({ dispatch, getState })
      .then(response => {
        const { data: { updateTrolleyItem } = {}, errors } = response;

        if (errors || !updateTrolleyItem || updateTrolleyItem.failures) {
          let error = new Error('Graph call succeeded but returned no data or an unknown error');
          dispatch({
            error: 'Graph call succeeded but returned no data or an unknown error',
            type: TROLLEY_ITEM_UPDATE_FAILED,
            trolleyItem: item,
          });

          if (errors?.length) {
            [error] = errors;
          }

          if (updateTrolleyItem?.failures?.length) {
            const { failures } = updateTrolleyItem;
            [error] = failures;
          }
          return handleUpdateTrolleyErrors(dispatch, getState)(error);
        }

        return dispatch({
          type: TROLLEY_ITEM_UPDATED,
          result: updateTrolleyItem,
        });
      })
      .catch(err => {
        dispatch({
          error: 'Update trolley item call to graph failed',
          type: TROLLEY_ITEM_UPDATE_FAILED,
          trolleyItem: item,
        });
        return handleUpdateTrolleyErrors(dispatch, getState)(err);
      });
  };
}
