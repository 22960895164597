import orderGiftCards from 'api/definitions/orderGiftCards';
import get from 'lodash/get';
import handleErrors from 'redux/errors/handle-errors';
import { ADD_GIFT_CARD } from 'redux/modules/order/actions/types';
import { getCurrentGiftCards } from 'redux/modules/order/selectors/get-current-gift-cards';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { handleGiftCardError, handleTimeoutError } from './errors';

const handleResponse = dispatch => result => {
  const failures = get(result, 'data.setGiftCards.failures', null);
  if (failures) {
    const orderErrorResponseCode = failures.conflicts?.code;

    const errorResponse = {
      status: failures.statusCode,
      response: {
        body: {
          type: 'ADD_GIFT_CARD_ERROR',
          code: orderErrorResponseCode,
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftCardError]));

    return (
      failures.statusCode === 400 ||
      [
        // error codes that will NOT reset the form
        'ORDER_PAYMENT_004',
        'ORDER_PAYMENT_029',
        'ORDER_PAYMENT_058',
        'ORDER_PAYMENT_076',
        'ORDER_PAYMENT_077',
        'ORDER_PAYMENT_093',
      ].indexOf(orderErrorResponseCode) === -1
    );
  }
  if (result?.errors) {
    const errorResponse = {
      response: {
        body: {
          type: 'ADD_GIFT_CARD_ERROR',
        },
      },
    };

    dispatch(handleErrors(errorResponse, [handleGiftCardError]));
    return true;
  }

  return true;
};

const handleRequestErrors = dispatch => error => {
  dispatch(handleErrors(error, [handleTimeoutError]));

  return true;
};

const addGiftCard =
  ({ serialNumber, giftCardPin }) =>
  (dispatch, getState) => {
    const state = getState();
    const customerOrderId = getCustomerOrderId(state);
    const currentGiftCards = getCurrentGiftCards(state);
    const giftCards = [...currentGiftCards, { serialNumber, giftCardPin }];

    return dispatch({
      apiCall: orderGiftCards.post({ customerOrderId, giftCards }),
      types: ADD_GIFT_CARD.triplet,
    }).then(handleResponse(dispatch), handleRequestErrors(dispatch));
  };

export default addGiftCard;
