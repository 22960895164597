import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';
import locator from 'utils/locator';

import { openModal } from 'redux/modules/common-modal/actions';

import styles from 'constants/checkout/errorMessages.scss';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  if (status === 409 && code === 'ORDER_ORCHESTRATION_111') {
    dispatch(
      openModal({
        id: 'checkout-delivery-date-unavailable',
        severity: 'conflict',
        buttons: [
          {
            buttonText: 'OK',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
        ],
        cannotClose: true,
        messageIsHtml: true,
        messageText: [
          `An issue with your order is preventing you from checking out.  Please contact Customer Care on <a class="${styles.phoneNumber}" href="tel:0800188884">0800 188 884</a>`,
        ],
        titleText: 'Sorry, there was a problem',
        webview: {
          id: 'checkout-error-care',
          ctas: [urls.trolleyPage],
          hasCustomerCareButton: true,
        },
        useIngredientsModal: false,
        onCloseCallback: () => {
          locator.href = urls.trolleyPage;
        },
      }),
    );

    return true;
  }

  return false;
};
