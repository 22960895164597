import uuid from 'uuid/v4';
import { AlertTypesAPI, SlotExperienceAlertAPI } from 'api/definitions/slot-experience/types';
import {
  AlertTypes,
  SlotExperienceAlert,
  SlotExperienceState,
} from 'redux/modules/slot-experience/state/initial-state';

const AlertTypesMap = {
  [AlertTypesAPI.INFORMATIVE]: AlertTypes.info,
  [AlertTypesAPI.SUCCESS]: AlertTypes.success,
  [AlertTypesAPI.WARNING]: AlertTypes.warning,
  [AlertTypesAPI.ERROR]: AlertTypes.error,
};

const mapAlertTypes = (alerts: SlotExperienceAlertAPI[]): SlotExperienceAlert[] =>
  alerts.map(alert => ({
    ...alert,
    alertType: AlertTypesMap[alert.alertType],
    id: uuid(),
  }));

const reducer = (
  state: SlotExperienceState,
  action: { result: { slotAlertResponses: SlotExperienceAlertAPI[] } },
) => ({
  ...state,
  alerts: mapAlertTypes(action.result.slotAlertResponses),
});

export default reducer;
