import { useRef, useEffect } from 'react';
import { podObserver } from 'utils/custom-intersection-observer';

// TODO remove feture flag when impression service is implemented
export function usePodObserver(onVisible) {
  const observeElemRef = useRef(null);

  useEffect(() => {
    podObserver.observe(observeElemRef.current, onVisible);
  }, [onVisible]);

  useEffect(() => {
    const el = observeElemRef.current;
    return () => {
      podObserver.unobserve(el);
    };
  }, []);

  return observeElemRef;
}
