import { PATCH_CONTACT_ADDRESS } from 'redux/modules/address/actions/types';

import accountsProfile from 'api/definitions/accountsProfile';
import {
  setChoosenAddress,
  setContactAddress,
  contactAddressApiError,
} from 'redux/modules/address/actions';
import { getContactAddressId } from '../selectors/get-address-selectors';

const updateContactAddressAsync = body => ({
  types: PATCH_CONTACT_ADDRESS.triplet,
  apiCall: accountsProfile.patch({
    body,
    iHandleStatusCodes: [400, 401, 403, 404, 410, 422, 500, 502, 503, 504],
  }),
});

const updateContactAddress = address => async (dispatch, getState) => {
  const { id = '' } = address;
  const state = getState();
  const contactAddressId = getContactAddressId(state) || '';
  try {
    if (id !== contactAddressId && id.length > 0) {
      await dispatch(updateContactAddressAsync({ contactAddressId: id }));
      await dispatch(setContactAddress(address));
      await dispatch(setChoosenAddress({ ...address, isContactAddress: true }));
    }

    if (id === contactAddressId) {
      await dispatch(setChoosenAddress({ ...address, isContactAddress: id === contactAddressId }));
    }
  } catch (e) {
    await dispatch(contactAddressApiError(address));
  }
};

export { updateContactAddress, updateContactAddressAsync };
