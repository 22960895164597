import baseAxios, { AxiosInstance } from 'axios';
import { Agent as HttpAgentInstance } from 'http';
import { Agent as HttpsAgentInstance } from 'https';

// eslint-disable-next-line import/no-mutable-exports
let axios: AxiosInstance;
// eslint-disable-next-line import/no-mutable-exports
let httpAgent: HttpAgentInstance;
// eslint-disable-next-line import/no-mutable-exports
let httpsAgent: HttpsAgentInstance;

if (__SERVER__) {
  // eslint-disable-next-line global-require, @typescript-eslint/no-require-imports
  const HttpAgent = require('agentkeepalive');
  const { HttpsAgent } = HttpAgent;

  httpAgent = new HttpAgent();
  httpsAgent = new HttpsAgent();

  axios = baseAxios.create({
    httpAgent,
    httpsAgent,
  });
} else {
  axios = baseAxios;
}

export { axios, httpAgent, httpsAgent };
