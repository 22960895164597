import { apiCallback } from 'api';

const definition = {
  service: 'favourites2',
  endpoint: 'favourites/${lineNumber}',
  verbs: ['put', 'delete'],
  defaultVersion: 2,
};

export default {
  put: apiCallback(definition, 'put'),
  delete: apiCallback(definition, 'delete'),
};
