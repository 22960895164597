import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const GET = apiPromiseTypes('account-orchestration', 'get');
const POST = apiPromiseTypes('account-orchestration', 'post');

const CHANGE_CUSTOMER_EMAIL_PENDING = 'waitrose/api/account-orchestration/put/request';
const CHANGE_CUSTOMER_EMAIL_FULFILLED = GET.success;
const CHANGE_CUSTOMER_EMAIL_REJECTED = 'waitrose/api/account-orchestration/put/failure';

const PUT = {
  triplet: [
    CHANGE_CUSTOMER_EMAIL_PENDING,
    CHANGE_CUSTOMER_EMAIL_FULFILLED,
    CHANGE_CUSTOMER_EMAIL_REJECTED,
  ],
};

export const UPDATE_CUSTOMER_ACCOUNT_DETAILS = 'waitrose/UPDATE_CUSTOMER_ACCOUNT_DETAILS';

export { GET, POST, PUT };

export const GET_PROMPTS_FOR_CONTACT_ADDRESS = apiPromiseTypes(
  'accountsPromptsForContactAddress',
  'get',
);

export const REMOVE_PROMPTS_FOR_CONTACT_ADDRESS = apiPromiseTypes(
  'accountsPromptsForContactAddress',
  'delete',
);
