import { createSelector } from 'reselect';

export const getAddAllToBasket = (state: WtrState) => !!state?.content?.addAllToBasket ?? false;

const getLocations = ({ content }: WtrState) => content?.locations || {};

export const getLocationsLoading = ({ content }: WtrState) => !content?.locationsLoaded;

const getLocationName = (_state: WtrState, name: string) => name;

export const getLocationByName = createSelector(
  [getLocations, getLocationName],
  (locations = {}, name) => locations[name] || [],
);

export const getLocationsBody = (state: WtrState) => getLocationByName(state, 'body');
export const getLocationsHeader = (state: WtrState) => getLocationByName(state, 'header');
export const getLocationsMasthead = (state: WtrState) => getLocationByName(state, 'masthead');
export const getLocationsSEOContent = (state: WtrState) => getLocationByName(state, 'seoContent');
export const getLocationsRoot = (state: WtrState) => getLocationByName(state, 'root');

export const hasContentByName = createSelector([getLocationByName], content =>
  content ? content.length > 0 : false,
);

export const hasHeaderContent = (state: WtrState) => hasContentByName(state, 'header');
export const hasLocationsMasthead = (state: WtrState) => hasContentByName(state, 'masthead');
export const hasLocationsSEOContent = (state: WtrState) => hasContentByName(state, 'seoContent');
