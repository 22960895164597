const reducer = state => ({
  ...state,
  promoCodes: {
    ...state.promoCodes,
    isAdding: false,
    isRemoving: true,
    requestedPromoCode: null,
    serverError: null,
  },
  waiting: true,
});

export default reducer;
