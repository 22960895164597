import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isPaymentPage } from 'constants/payment-pages';
import { isEmbeddedInWebView } from 'utils/webview';
import { onLoginPage } from 'utils/login';
import { getCookies } from 'redux/modules/cookies/selectors';
import { withLegacyBlockingApis } from 'components/withLegacyBlockingApis';
import AnalyticsScripts from './AnalyticsScripts';
import MarketingScripts from './MarketingScripts';

/* When Google Tag Manager (GTM) loads should be treated very delicately. After the script is injected
 * it gives rises to a sequential series of tasks occupying the main thread of which one is very long.
 * A recent change by consequence allowed GTM to load sooner which had the effect of delaying the initiation of
 * key page api requests on most pages.
 *
 * Previous to the aforementioned change, GTM always loaded just after a group of "logged in" api requests had
 * completed. The addition of the withLegacyBlockingApis higher order component (also currently used in the main
 * routing component file "App.js") always delays the script injection until this happens so the delicate timing
 * remains as similar as possible to before.
 *
 * In an ideal performance centric world, GTM wouldn't be as expensive to load and it would always be delayed after
 * critical page content is displayed. The delay at least needs more careful consideration as it could have an effect
 * on analytics reported and would need be worked through with the relevant teams.
 */
const DelayedAnalyticsScripts = withLegacyBlockingApis(AnalyticsScripts, null);

export const CookiesScripts = memo(() => {
  const { pathname } = useLocation();
  const { marketing, analytics } = useSelector(getCookies);

  const isInPaymentPage = isPaymentPage(pathname);
  const isInLoginPageInAppWebView = onLoginPage() && isEmbeddedInWebView();

  if (isInPaymentPage || isInLoginPageInAppWebView) {
    return null;
  }

  return (
    <>
      {marketing ? <MarketingScripts /> : null}
      {analytics ? <DelayedAnalyticsScripts /> : null}
    </>
  );
});
