import createReducer from 'redux/createReducer';
import { LegacyProductReference } from 'constants/products';

import { OffersExperienceResponse } from 'api/definitions/offers-experience';
import { GET_MEAL_DEAL } from '../actions/types';

import { initialState } from '../state';
import {
  MealDealRequestState,
  MealDealSuccessState,
  MealDealErrorState,
  MealDealState,
  MealDealErrorCode,
  MEAL_DEAL_UNKNOWN_ERROR_CODE,
} from '../types';

interface MealDealFetcherResult
  extends Omit<OffersExperienceResponse, 'recommendations' | 'content' | 'products'> {
  recommendations: LegacyProductReference[];
}

export type GetMealDealSuccessAction = {
  result: MealDealFetcherResult;
};

type GetMealDealFailurePayload = {
  payload: {
    response: {
      body: {
        errorCode: MealDealErrorCode;
      };
    };
  };
};

const getMealDealRequest = (): MealDealRequestState => ({ loading: true });

const getMealDealSuccess = (
  _state: MealDealState,
  {
    result: { description, discount, endDate, groups, id, name, recommendations, startDate, type },
  }: GetMealDealSuccessAction,
): MealDealSuccessState => ({
  loading: false,
  description,
  discount,
  endDate,
  groups,
  id,
  name,
  recommendations,
  startDate,
  type,
});

const getMealDealFailure = (
  _state: MealDealState,
  { payload }: GetMealDealFailurePayload,
): MealDealErrorState => ({
  errorCode: payload?.response?.body?.errorCode ?? MEAL_DEAL_UNKNOWN_ERROR_CODE,
  loading: false,
});

const actionTypeReducerMap = [
  [GET_MEAL_DEAL.request, getMealDealRequest],
  [GET_MEAL_DEAL.success, getMealDealSuccess],
  [GET_MEAL_DEAL.failure, getMealDealFailure],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
