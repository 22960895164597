import { connect } from 'react-redux';
import { logoutUser } from 'redux/modules/login/actions/logout-user';
import { isSignedOnAsCustomer, isUserLoggedIn } from 'redux/modules/sessions/selectors';

export default component =>
  connect(
    state => ({
      isSignedOnAsCustomer: isSignedOnAsCustomer(state),
      loggedIn: isUserLoggedIn(state),
    }),
    dispatch => ({
      onLogoutUser: () => {
        dispatch(logoutUser());
      },
    }),
  )(component);
