import { CacheEntryForCallback, Callback } from './types';

const cleanCacheForCallback = (cacheEntryForCallback: CacheEntryForCallback, now: number) =>
  Object.keys(cacheEntryForCallback).reduce<CacheEntryForCallback | null>((newCache, key) => {
    const expires = cacheEntryForCallback[key]?.expires;

    if (typeof expires === 'number' && expires <= now) {
      return newCache;
    }

    return {
      ...(newCache ?? {}),
      [key]: cacheEntryForCallback[key],
    };
  }, null);

export const cleanCache = (cache: Map<Callback, CacheEntryForCallback>) => {
  const now = new Date().getTime();
  const cacheCallbackKeysToDelete: Callback[] = [];

  cache.forEach((cacheEntryForCallback, callback) => {
    const newCacheEntryForCallback = cleanCacheForCallback(cacheEntryForCallback, now);

    if (newCacheEntryForCallback) {
      cache.set(callback, newCacheEntryForCallback);
    } else {
      cacheCallbackKeysToDelete.push(callback);
    }
  });

  cacheCallbackKeysToDelete.forEach(callback => cache.delete(callback));
};
