import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

export const getDefaultBranch = (state: WtrState) =>
  (getStateAtNamespaceKey(state, 'branches').branches || []).find(el => el.defaultBranch) || {
    id: null,
  };

export const getBranches = (state: WtrState) => getStateAtNamespaceKey(state, 'branches').branches;

export const getBranchesSelector = (state: WtrState) => state.branches;

export const hasBranches = (state: WtrState) =>
  !!getStateAtNamespaceKey(state, 'branches').branches.length;

export const isBranchesLoading = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'branches').loading;

export const isPostcodeInvalid = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'branches').isPostcodeInvalid;

export const getBranchById = (state: WtrState, branchId: string) =>
  getStateAtNamespaceKey(state, 'branches').branches.find(el => el.id === branchId);

export const getListOfBranches = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'branches').branches.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));
