import { getPromotionById } from 'redux/modules/entities/selectors/promotions';

const SEPARATOR = ' | ';

export default function concatenatePromotionValues(state, promotions) {
  if (!promotions || !Array.isArray(promotions)) return false;

  return promotions
    .map(promotionId => getPromotionById(state, promotionId) || {})
    .map(({ promotionDescription } = {}) => promotionDescription)
    .filter(description => description)
    .join(SEPARATOR);
}
