import { dataLayer } from 'analytics/data-layer';

export const trackPersonalisation = ({
  personalisation = {},
  otherTrackingValues = {},
  fallback = false,
} = {}) => {
  if (Object.keys(personalisation).length > 0) {
    dataLayer.push({
      event: 'personalisation_applied',
      personalisation: { ...personalisation, fallback },
      ...otherTrackingValues,
    });
  }
};
