import interpolate from 'utils/interpolate';

const buildHeaders = ({ headers = {} }, args = {}) => {
  const { headers: interpolatedHeaders = {} } = args;

  Object.keys(headers).forEach(header => {
    interpolatedHeaders[header] = interpolate(headers[header], args);
  });

  return interpolatedHeaders;
};

export default buildHeaders;
