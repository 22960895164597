import { apiCallback } from 'api';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';
import addIncentiveQuery from './addIncentive.graphql';
import removeIncentiveQuery from './removeIncentive.graphql';

const graphDefinition = (query, tag) => ({
  graph: true,
  query: () => query,
  variables: ({ orderId, code }) => ({
    orderId,
    incentiveCode: code,
  }),
  queryString: { tag },
  timeout: TIMEOUT_CONFIG,
});

export default {
  addIncentive: apiCallback(graphDefinition(addIncentiveQuery, 'add-incentive'), 'post'),
  removeIncentive: apiCallback(graphDefinition(removeIncentiveQuery, 'remove-incentive'), 'post'),
};
