import { REPORT_ADDRESS_SUGGESTION_MODIFIED } from 'redux/modules/address-suggestion/actions/types';
import { propertiesChanged } from 'components/Address/util';

const reportModifiedFields = (state, action) => {
  const { address = {}, control = null } = action;
  const modifiedFields = propertiesChanged(control, address);
  return {
    ...state,
    modifiedFields,
  };
};

export default map => {
  map.set(REPORT_ADDRESS_SUGGESTION_MODIFIED, reportModifiedFields);
  return map;
};
