import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames/bind';
import styles from 'components/Forms/utilities/FormFields.scss';

const InputRadioGroup = props => {
  const {
    children,
    heading,
    meta: { invalid },
    className,
    invalidText,
    name,
    onChange,
    value,
    inlineAtBreakpoint,
  } = props;

  const handleChange = event => {
    const { target } = event;
    if (onChange && target.checked) onChange(target.value);
  };

  const cx = classnames.bind(styles);

  const headerClasses = cx('radioGroupHeading', {
    inlineRadios: inlineAtBreakpoint,
  });

  const radioButtonClasses = cx(className, 'radioButtons', {
    [`${inlineAtBreakpoint}InlineRadios`]: inlineAtBreakpoint,
  });

  return (
    <div
      role="radiogroup"
      aria-labelledby={`radioGroupHeading-${name}`}
      aria-invalid={invalid ? invalid : null}
      className={styles.radioGroupContainer}
    >
      {heading && (
        <header id={`radioGroupHeading-${name}`} className={headerClasses}>
          {heading}
        </header>
      )}
      <div data-testid="radio-buttons" className={radioButtonClasses}>
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;

          return React.cloneElement(child, {
            key: index,
            name,
            checked: value === child.props.value,
            onChange: handleChange,
            invalidText: invalidText && invalidText.length > 0,
            className: styles.input,
          });
        })}
      </div>
    </div>
  );
};

InputRadioGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
  }),
  helpText: PropTypes.string,
  className: PropTypes.string,
  invalidText: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inlineAtBreakpoint: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};

InputRadioGroup.defaultProps = {
  meta: {
    invalid: false,
  },
};

export default InputRadioGroup;
