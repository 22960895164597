import { createSelector } from 'reselect';

import urls, { shopFromPreviousURL } from 'constants/urls';

import { getPageType } from 'utils/get-page-type';
import type { Location } from 'history';

declare module 'history' {
  export interface Location {
    previousPath?: string;
    previousPaths?: string[];
  }
}

const EMPTY_OBJECT = {} as const;

const getRouting = (state?: WtrState) => state?.router;

export const getLocation = createSelector(
  getRouting,
  (router): Location | Record<string, never> => router?.location ?? EMPTY_OBJECT,
);

export const getLocationSearchParams = createSelector(
  getLocation,
  location => location.search || '',
);

export const getSearchObject = createSelector(getLocationSearchParams, search =>
  Object.fromEntries(new URLSearchParams(search).entries()),
);

export const getPathname = createSelector(getLocation, location => location.pathname);

export const getPreviousPath = createSelector(getLocation, location => location.previousPath);

export const getPreviousPaths = createSelector(getLocation, location => location.previousPaths);

export const getPreviousLocation = createSelector(
  getRouting,
  router => router?.previousLocations?.[1],
);

export const getPreviousLocationData = createSelector(
  getPreviousLocation,
  previous => previous?.location,
);

export const getIsSsrPage = createSelector(
  getPreviousPath,
  previousPath => typeof previousPath === 'undefined',
);

export const getRoutingPageType = createSelector(getPathname, path =>
  path ? getPageType(path) : path,
);

export const getOrderIdFromPath = createSelector(getPathname, (path = '') =>
  path.includes(shopFromPreviousURL('')) ? path.replace(shopFromPreviousURL(''), '') : undefined,
);

// Too specific name for a too specific query
export const userIsTryingToBookSlotComingFromMealDealsPage = createSelector(
  getPreviousPath,
  getPreviousPaths,
  (previousPath, previousPaths) => {
    /** return negative if previousPaths is empty */
    if (!previousPaths?.length) {
      return false;
    }

    /** return positive if previousPath was mealDealPromoPath */
    if (previousPath?.includes(urls.mealDealPromoPath)) {
      return true;
    }

    /**
     * Check if user went in mealDealPromoPath at any moment
     * and return positive only if the user went right before trying to book (or change) a slot
     */
    const mealDealIndexes = previousPaths
      .map((path, index) => [path, index] as const)
      .filter(([path]) => path.includes(urls.mealDealPromoPath))
      .map(([, index]) => index);

    if (!mealDealIndexes.length) {
      return false;
    }

    const mealDealIndex = Math.max(...mealDealIndexes);
    const mealDealFound = mealDealIndex >= 0;

    if (mealDealFound) {
      const pathsAfterMealDeal = previousPaths.filter((_, index) => index > mealDealIndex);
      return (
        pathsAfterMealDeal.filter(
          path => !path.includes('bookslot') && !path.includes('serviceselection'),
        ).length === 0
      );
    }

    return false;
  },
);

export const isSuppressAlternatives = createSelector(
  getLocation,
  location =>
    location.pathname.includes(urls.offers) ||
    location.pathname.includes(urls.offerDetails) ||
    location.pathname.includes(urls.featuredOffers) ||
    location.pathname.includes(urls.mealDealPromoPath) ||
    location.pathname.includes(urls.bundles),
);
