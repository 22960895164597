import PropTypes from 'prop-types';

export const RecipeProductTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          isSponsored: PropTypes.boolean,
          isEnforced: PropTypes.boolean,
          products: PropTypes.arrayOf(
            PropTypes.shape({
              lineNumber: PropTypes.string,
              matchStrategy: PropTypes.string,
              quantity: PropTypes.number,
            }),
          ),
        }),
      ),
    }),
  ),
};

export const AllergenTypes = {
  allergens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};
