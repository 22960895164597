const viewCart = (_state, { amendingOrder, estimatedTotalCost, products }) => ({
  event: 'view_cart',
  ecommerce: {
    cart: {
      actionField: {
        option: amendingOrder ? 'amend' : 'purchase',
        value: estimatedTotalCost,
      },
      products: products.map(
        // eslint-disable-next-line camelcase
        ({ id, price, quantity, offer, favourite, amendment_quantity_delta }) => ({
          id,
          price,
          quantity,
          offer,
          favourite,
          // eslint-disable-next-line camelcase
          amendment_quantity_delta,
        }),
      ),
    },
  },
});

export default viewCart;
