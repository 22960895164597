import { ORDER_LOADED } from 'redux/modules/trolley/actions/types';
import { ADD_ALL_ITEMS_FULFILLED } from 'redux/modules/add-all-items/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  triggered: {},
  untriggered: {},
});

export default function promotions(state = initialState, action = {}) {
  const { result = {}, type } = action;

  switch (type) {
    case ADD_ALL_ITEMS_FULFILLED:
    case ORDER_LOADED: {
      const { triggeredPromotions: triggered = {}, untriggeredPromotions: untriggered = {} } =
        result;

      return {
        ...state,
        triggered,
        untriggered,
      };
    }
    default:
      return state;
  }
}
