export enum AlertTypesAPI {
  INFORMATIVE = 'INFORMATIVE',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum AlertPage {
  SERVICE_SELECTION = 'SERVICE_SELECTION',
  COLLECTION_SERVICE_SELECTION = 'COLLECTION_SERVICE_SELECTION',
  DELIVERY_SLOT_GRID = 'DELIVERY_SLOT_GRID',
  GROCERY_COLLECTION_SLOT_GRID = 'GROCERY_COLLECTION_SLOT_GRID',
  ENTERTAINING_COLLECTION_SLOT_GRID = 'ENTERTAINING_COLLECTION_SLOT_GRID',
}

export enum AlertPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type SlotPage = 'SERVICE_SELECTION' | 'SELECT_COLLECTION_SERVICE' | 'SLOT_GRID';
export type SlotType =
  | 'UNDEFINED'
  | 'DELIVERY'
  | 'GROCERY_COLLECTION'
  | 'ENTERTAINING_COLLECTION'
  | 'DELIVERY_SERVICE';

export type SlotExperienceAlertAPI = {
  alertPage: AlertPage;
  alertPosition: AlertPosition;
  alertType: AlertTypesAPI;
  richTextMessage: Record<string, unknown>;
  title: Record<string, unknown>;
};
