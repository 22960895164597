import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSearchAndBrowseLoading } from 'redux/modules/search-and-browse/selectors';
import { navigateToMultiSearch } from 'redux/modules/multi-search/actions/navigate-to-multi-search';
import {
  getMultiSearchCurrentValue,
  getMultiSearchValues,
} from 'redux/modules/multi-search/selectors';

import HorizontalSlider from 'components/MultiSearch/HorizontalSlider';
import MultiSearchButton from 'components/MultiSearch/Button';
import SkipLink from 'components/SkipLink';

import styles from './MultiSearchStrip.scss';

const MultiSearchStrip = () => {
  const loading = useSelector(getSearchAndBrowseLoading);
  const values = useSelector(getMultiSearchValues);
  const current = useSelector(getMultiSearchCurrentValue);
  const dispatch = useDispatch();

  return values && values.length ? (
    <div className={styles.outer}>
      <div className="container-fluid">
        <SkipLink destination="#main">Skip to main content</SkipLink>
        <HorizontalSlider resetKey={JSON.stringify(values)}>
          <ol className={styles.strip} data-testid="multi-search-strip">
            {values.map(value => (
              <li className={styles.value} key={value}>
                <MultiSearchButton
                  onClick={() => dispatch(navigateToMultiSearch(value, { preserveParams: true }))}
                  selected={value === current}
                  value={value}
                  loading={loading}
                />
              </li>
            ))}
          </ol>
        </HorizontalSlider>
      </div>
    </div>
  ) : null;
};

export default MultiSearchStrip;
