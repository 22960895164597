import React from 'react';
import { Helmet } from 'react-helmet-async';
import type { Config } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/config/types';

import { dataLayer } from 'analytics/data-layer';
import { log } from 'utils/error-logging';
import env from 'env/env';
import componentMap from '../component-map';

export const config: Config = {
  cfDomain: env.cfDomain,
  componentMap,
  headFunction: meta => <Helmet>{meta}</Helmet>,
  onAnalyticsEvent: (...events) => dataLayer.push(...events),
  onError: (err, additionalData) => log(err, additionalData),
};
