import {
  SHOW_ADD_MODAL,
  HIDE_ADD_MODAL,
  CANCEL_ADD_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  CANCEL_EDIT_MODAL,
  SHOW_CHOOSE_ADDRESS_MODAL,
  HIDE_CHOOSE_ADDRESS_MODAL,
  CANCEL_CHOOSE_ADDRESS_MODAL,
  EXPAND_ADDRESS_BOOK,
  SHOW_EDIT_DELETE_SPINNER,
  HIDE_EDIT_DELETE_SPINNER,
} from 'redux/modules/address/actions/types';

const showAddAddressModal = state => ({
  ...state,
  addressInError: null,
  modal: {
    isOpen: true,
    isEdit: false,
  },
});

const hideAddAddressModal = state => ({
  ...state,
  modal: {
    isOpen: false,
    isEdit: false,
  },
});
const showEditAddressModal = state => ({
  ...state,
  addressInError: null,
  editAddressLoading: false,
  modal: {
    isOpen: true,
    isEdit: true,
  },
});

const hideEditAddressFormModal = state => ({
  ...state,
  modal: {
    isOpen: false,
    isEdit: true,
  },
});
const showChooseAddressModal = (state, action) => {
  const { chooseAddressModal } = state;
  const { addresses } = action;
  return {
    ...state,
    addresses,
    addressInError: null,
    chooseAddressModal: {
      ...chooseAddressModal,
      isOpen: true,
      updated: false,
    },
  };
};

const hideChooseAddressModal = state => {
  const { chooseAddressModal } = state;
  return {
    ...state,
    cannotAlterAddress: {
      addressId: '',
    },
    chooseAddressModal: {
      ...chooseAddressModal,
      isOpen: false,
    },
    modal: { isOpen: false },
  };
};

const expandAddressBook = state => ({
  ...state,
  addressBook: { collapsed: false },
});

const showEditDeleteSpinner = (state, action) => {
  const { id } = action;
  const { cannotAlterAddress } = state;
  return {
    ...state,
    cannotAlterAddress: {
      ...cannotAlterAddress,
      addressId: id,
    },
  };
};

const hideEditDeleteSpinner = state => {
  const { cannotAlterAddress } = state;
  return {
    ...state,
    cannotAlterAddress: {
      ...cannotAlterAddress,
      addressId: '',
    },
  };
};

export default map => {
  map.set(SHOW_ADD_MODAL, showAddAddressModal);
  map.set(HIDE_ADD_MODAL, hideAddAddressModal);
  map.set(CANCEL_ADD_MODAL, hideAddAddressModal);
  map.set(SHOW_EDIT_MODAL, showEditAddressModal);
  map.set(HIDE_EDIT_MODAL, hideEditAddressFormModal);
  map.set(CANCEL_EDIT_MODAL, hideEditAddressFormModal);
  map.set(SHOW_CHOOSE_ADDRESS_MODAL, showChooseAddressModal);
  map.set(HIDE_CHOOSE_ADDRESS_MODAL, hideChooseAddressModal);
  map.set(CANCEL_CHOOSE_ADDRESS_MODAL, hideChooseAddressModal);
  map.set(EXPAND_ADDRESS_BOOK, expandAddressBook);
  map.set(SHOW_EDIT_DELETE_SPINNER, showEditDeleteSpinner);
  map.set(HIDE_EDIT_DELETE_SPINNER, hideEditDeleteSpinner);
  return map;
};
