import { apiCallback } from 'api';

const definition = {
  service: 'mywaitrose',
  endpoint: 'mywaitrose',
  defaultVersion: 2,
  verbs: ['post'],
};

export default {
  post: apiCallback(definition, 'post'),
};
