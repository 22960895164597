import { createSelector } from 'reselect';

const getOrders = ({ orders = {} } = {}) => orders;

const getCancellationsInProgress = createSelector(
  getOrders,
  ({ cancellationsInProgress = [] } = {}) => cancellationsInProgress,
);

export const isOrderCancellationInProgress = createSelector(
  [getCancellationsInProgress, (state, orderId) => orderId],
  (orders, orderId) => orders.includes(orderId),
);

export const getCustomerHasACompletedOrder = createSelector(
  getOrders,
  orders => orders?.userStats?.customerHasACompletedOrder,
);

export const getDaysSinceLastCompletedOrder = createSelector(
  getOrders,
  orders => orders?.userStats?.daysSinceLastCompletedOrder,
);

export const getCustomerHasAnOrderRequiringPaymentAttention = createSelector(
  getOrders,
  orders => orders?.userStats?.customerHasAnOrderRequiringPaymentAttention,
);

export const hasCustomerOrderStatsInitialLoadCompleted = createSelector(
  getOrders,
  orders => orders?.userStats?.initialLoadComplete,
);
