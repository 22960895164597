import { createSelector } from 'reselect';

import { getSelectedAddress } from 'redux/modules/address/selectors/get-address-selectors';

import { getCustomerAddressById } from './get-customer-address-by-id';

export const getCustomerDeliveryAddress = createSelector(
  [getCustomerAddressById, getSelectedAddress],
  (addressById, selectedAddress: Record<string, string>) => addressById || selectedAddress,
);
