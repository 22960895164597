import React, { useState } from 'react';
import { addYears } from 'utils/date';
import { Close } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { COOKIE_APP_BANNER_DISMISS } from 'constants/cookies';
import AppLogo from 'components/AppBanner/AppLogo';

import { getCookie, saveCookie } from 'utils/cookie';
import { userAgent } from 'utils/user-agent';

import styles from 'components/AppBanner/AppBanner.scss';

const shouldShowBanner = () => {
  const {
    os: { family },
    name,
    ua = '',
  } = userAgent();

  const isAndroid = family === 'Android';

  const showBanner =
    !getCookie(COOKIE_APP_BANNER_DISMISS) &&
    !ua?.includes('MobileApp/') && // [BM]: App is being consumed via a web-view
    (isAndroid || (family === 'iOS' && name !== 'Safari'));

  return { showBanner, isAndroid };
};

const AppBanner = () => {
  const { showBanner, isAndroid } = shouldShowBanner();
  const [show, setShow] = useState(showBanner);

  const handleClose = () => {
    saveCookie(COOKIE_APP_BANNER_DISMISS, 1, addYears(new Date(), 1));
    setShow(false);
  };

  if (show) {
    return (
      <div id="app-banner" className={styles.appBanner}>
        <div className={styles.close}>
          <button
            data-test="close"
            onClick={handleClose}
            type="button"
            aria-label="Close option to install Waitrose app"
          >
            <Close size="xsmall" />
          </button>
        </div>

        <div className={styles.icon}>
          <AppLogo />
        </div>

        <dl className={styles.info}>
          <div className={styles.appName}>
            <dt>App name</dt>
            <dd>Waitrose &amp; Partners</dd>
          </div>
          <div>
            <dt>App developer</dt>
            <dd>Waitrose {isAndroid ? 'Ltd' : 'Limited'}</dd>
          </div>
          <div>
            <dt>How to download</dt>
            <dd>{isAndroid ? 'FREE - On Google Play' : 'GET - On the App Store'}</dd>
          </div>
        </dl>
        <div className={styles.openLink}>
          <a
            href={
              isAndroid
                ? 'https://play.google.com/store/apps/details?id=com.waitrose.groceries'
                : 'https://apps.apple.com/gb/app/waitrose-partners/id1346064128'
            }
          >
            View
          </a>
        </div>
      </div>
    );
  }
  return null;
};

export default AppBanner;
