import { CampaignAPI } from 'api/definitions/slotdates/types';
import { mapSeasonalRanges } from 'redux/modules/slot-dates/utils/mapSeasonalRanges';

const reducer = (state: WtrState, action: { result: { data: { campaigns: CampaignAPI[] } } }) => {
  const { campaigns = [] } = action?.result?.data ?? {};

  return {
    ...state,
    campaigns: mapSeasonalRanges(campaigns),
  };
};
export default reducer;
