import footer from 'api/definitions/footer';
import taxonomy from 'api/definitions/taxonomy';
import { log } from 'utils/error-logging';

import {
  FAILED,
  FOOTER_FAILED,
  FOOTER_LOADED,
  FOOTER_LOADING,
  LOADED,
  LOADING,
} from 'redux/modules/taxonomy/actions/types';
import { TAXONOMY_FULL_DEPTH } from 'constants/taxonomy';

export function getTaxonomy() {
  return dispatch =>
    dispatch({
      types: [LOADING, LOADED, FAILED],
      depth: TAXONOMY_FULL_DEPTH,
      apiCall: taxonomy.get({
        retry: {
          numberOfRetries: 1,
        },
      }),
    });
}

export function fetchFooter() {
  return {
    types: [FOOTER_LOADING, FOOTER_LOADED, FOOTER_FAILED],
    callback: {
      failure: err => {
        if (err.status || err.timeout) {
          log(`Footer fetch failed! ${err}`, { logger: 'footer-fetcher' });
        }
      },
    },
    apiCall: footer.get({
      iHandleStatusCodes: true,
    }),
  };
}
