import { normalize } from 'normalizr';

import { trolleyConflictListSchema } from 'redux/schemas';

import { getConflicts } from './get-conflicts';
import { transformTrolleyConflict } from './transform';

const getNormalisedTrolleyConflicts = data => {
  const trolleyItemsOnly = conflict => conflict.itemId;
  const trolleyConflicts = getConflicts(data)
    .filter(trolleyItemsOnly)
    .map(conflict => transformTrolleyConflict(conflict));
  const normalized = normalize(trolleyConflicts, trolleyConflictListSchema);

  return normalized.entities.trolleyConflicts || {};
};

export default getNormalisedTrolleyConflicts;
