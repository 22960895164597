import { Recipe } from 'api/definitions/recipes/index.d';
import recipeRating from 'api/definitions/recipes/rating';
import { RECIPE_RATING } from './types';

export const getRating = (recipeId: Recipe['id']) => (dispatch: WtrDispatch) =>
  dispatch({
    types: RECIPE_RATING.triplet,
    apiCall: recipeRating.get({ recipeId, iHandleStatusCodes: true }),
    recipeId,
  }).catch(() => {});

export const postRating = (recipeId: Recipe['id'], rating: any) => (dispatch: WtrDispatch) =>
  dispatch({
    types: RECIPE_RATING.triplet,
    apiCall: recipeRating.post({ body: { recipeId, rating }, iHandleStatusCodes: true }),
  });
