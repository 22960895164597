import createReducer from 'redux/createReducer';

import initialState from '../state';

import getAutoLinkedMembershipRequest from './get-membership';

const actionTypeReducerMap = new Map();
getAutoLinkedMembershipRequest(actionTypeReducerMap);

export default createReducer(initialState, actionTypeReducerMap);
