import createReducer from 'redux/createReducer';
import { SLOT_DATES_REQUEST_ACTIONS } from 'redux/modules/slot-dates/actions/types';
import slotsRequest from 'redux/modules/slots/reducers/slots-request';
import slotDatesRequest from 'redux/modules/slots/reducers/slot-dates-request';
import slotsRequestSuccess from 'redux/modules/slots/reducers/slots-request-success';
import slotsRequestFailed from 'redux/modules/slots/reducers/slots-request-failed';
import requestBookSlot from 'redux/modules/slots/reducers/request-book-slot';
import bookSlotRequestFailed from 'redux/modules/slots/reducers/book-slot-request-failed';
import setSlotsDisabled from 'redux/modules/slots/reducers/set-slots-disabled';
import initialState from 'redux/modules/slots/state/initial-state';
import {
  BOOK_SLOT,
  SLOT_DAYS,
  SET_SLOTS_DISABLED,
  SET_BOOKING_SLOT_ID,
} from 'redux/modules/slots/actions/types';

const actionTypeReducerMap = [
  [SLOT_DAYS.request, slotsRequest],
  [SLOT_DAYS.success, slotsRequestSuccess],
  [SLOT_DAYS.failure, slotsRequestFailed],
  [BOOK_SLOT.failure, bookSlotRequestFailed],
  [SLOT_DATES_REQUEST_ACTIONS.request, slotDatesRequest],
  [SET_BOOKING_SLOT_ID, requestBookSlot],
  [SET_SLOTS_DISABLED, setSlotsDisabled],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
