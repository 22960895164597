import root from 'window-or-global';

import env from 'env/env';
import { CLICK_ID_KEY, CONVERSION_KEY, CONVERSION_OPTIONS } from 'constants/impact';
import { getPageType } from 'utils/get-page-type';
import locator from 'utils/locator';
import { unstashObject } from 'utils/stash';

export const trackConversion = () => {
  const pageType = getPageType(locator.pathname);
  const conversion = pageType === 'order-confirmation' && unstashObject(CONVERSION_KEY);

  if (conversion) {
    const clickId = unstashObject(CLICK_ID_KEY, { preserve: true }) ?? '';
    const conversionWithClickId = { ...conversion, clickId };

    // Send event using the Imapact Radius Event API
    root.ire('trackConversion', env.impactTrackerId, conversionWithClickId, CONVERSION_OPTIONS);
  }
};
