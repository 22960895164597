import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import classNames from 'classnames';

import { ModalCloseOptions } from 'constants/modalCloseOptions';

import styles from './ModalCloseButton.scss';

const ModalCloseButton = pure(({ close, disabled, fullScreen }) => (
  <button
    aria-label="Close"
    className={classNames(styles.closeButton, {
      [styles.fullScreen]: fullScreen,
      [styles.disabled]: disabled,
    })}
    disabled={disabled}
    id="modalCloseButton" // iOS/Android apps rely on this id!
    onClick={() => close(ModalCloseOptions.CANCEL)}
    type="button"
  >
    <span className="sr-only">Close</span>
  </button>
));

ModalCloseButton.propTypes = {
  close: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

ModalCloseButton.defaultProps = {
  disabled: false,
  fullScreen: false,
};

ModalCloseButton.displayName = 'ModalCloseButton';

export default ModalCloseButton;
