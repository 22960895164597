import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  searchParam: {
    searchTerm: '',
    resultSet: '',
  },
  searchValues: [],
  address: {},
  searchLoading: false,
  getAddressLoading: false,
  error: null,
  modifiedFields: [],
  navigation: [],
});

export default initialState;
