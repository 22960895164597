import address from 'api/definitions/address';
import apiAddressee from 'api/definitions/addressee';
import addressbyid from 'api/definitions/addressbyid';
import { buildAddressModel } from 'redux/modules/address/util';
import { POST, PUT_ADDRESSEE, PUT_BY_ID } from 'redux/modules/address/actions/types';
import { addressSelector as getSuggestedAddress } from 'redux/modules/address-suggestion/selectors';
import { filterSearchQuery, queryStringToObject } from 'utils/query-string-params';

const putAddressAsync = (addressId, body) => ({
  types: PUT_BY_ID.triplet,
  apiCall: addressbyid.put({
    addressId,
    body,
    iHandleStatusCodes: [400, 401, 403, 422, 500, 503],
  }),
  address: addressId,
});

const putAddresseeAsync = (addressId, body) => ({
  types: PUT_ADDRESSEE.triplet,
  apiCall: apiAddressee.put({
    addressId,
    body,
    iHandleStatusCodes: [400, 401, 403, 422, 500, 503],
  }),
  addressee: addressId,
});

const putAdminAddresseeAsync = (addressId, body) => ({
  types: PUT_ADDRESSEE.triplet,
  apiCall: apiAddressee.put({
    addressId,
    body,
    iHandleStatusCodes: [],
    headers: { 'subject-id': queryStringToObject(filterSearchQuery()).userIds },
  }),
  addressee: addressId,
});

const onAddAddress =
  (newAddress, addAddressOptions, saveWCSAddress = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { id = null } = getSuggestedAddress(state) || {};
    const body = buildAddressModel({
      ...newAddress,
      suggestionId: id,
    });

    dispatch({
      type: POST.request,
      body,
      addAddressOptions,
    });

    return address
      .post({
        ...body,
        ...addAddressOptions,
        ...(saveWCSAddress && {
          headers: { 'subject-id': queryStringToObject(filterSearchQuery()).userIds },
        }),
      })({
        dispatch,
        getState,
      })
      .then(async response => {
        const { data } = response;
        const failures = data?.addAddress?.failures || [];

        if (failures.length > 0) {
          const error = new Error(failures[0].message);
          error.type = failures[0].type;

          dispatch({
            error,
            type: POST.failure,
          });
        } else {
          dispatch({
            type: POST.success,
            result: data.addAddress,
          });
        }

        return {
          ...data.addAddress,
        };
      });
  };

export const addAddress = (newAddress, addAddressOptions) =>
  onAddAddress(newAddress, addAddressOptions);

export const saveAdminAddress = newAddress => onAddAddress(newAddress, {}, true);

export const updateAddress = (id, body) => dispatch => {
  const addressBody = buildAddressModel({
    ...body,
  });
  return dispatch(putAddressAsync(id, addressBody));
};

export const updateAddressee =
  ({ id, ...body }) =>
  dispatch =>
    dispatch(putAddresseeAsync(id, body));

export const updateAdminAddressee =
  ({ id, ...body }) =>
  dispatch =>
    dispatch(putAdminAddresseeAsync(id, body));
