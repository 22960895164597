/*
  actions to be captured by the Analytics Middleware should be defined
  in their own modules e.g.

  `redux/middleware/analytics/actions-to-track/sessions/sessions-received.js`

  and added here to the complete list of trackable actions paired with their transforms

  `transforms` generate objects as per analytics requirements to be pushed to
  the GTM data layer by extracting information from:

  - the action's payload
  - the store using different selectors as necessary

  `transforms` are called from the Analytics Middleware and get the _updated_ state
  along with the action's payload _after_ the action has been processed by a reducer

  each transform is associated with a specific action (1:1 relationship)

  ```
  import { ACTION_TYPE } from 'redux/modules/foo/actions/types';
  import actionTransform from 'redux/middleware/analytics/actions-to-track/foo/action';

  export default {
    [ACTION_TYPE]: actionTransform
  };
  ```

  NOTE: we _must_ comply with the GTM requirements which they dictate and
  provide neither less nor more but the just required information through
  pushing the objects generated by our `transforms` to the data layer

  NOTE: in order to keep our code and the GTM setup independent of each other and
  allow us to maintain / refactor our code as necessary, also to
  enable the GTM setup to be maintained, we _must_ keep them separate
  maintaining an _abstraction layer_ via our `transforms`

  therefore _do not_ just expose the action's payload to the data layer as is,
  because that will have the implication of tightly coupling our implementation
  (the redux store structure) with the GTM setup as that must rely on the objects
  and their structure which we expose through the data layer

  Please use a transformer function to extract only the necessary information
  from the action's payload and the store and push the resulting object
  to the GTM data layer!
 */
import addresses from './addresses';
import addressSuggestions from './address-suggestions';
import contactUs from './contact-us';
import customerFeedback from './customer-feedback';
import datePicker from './date-picker';
import experiments from './experiments';
import marketingPreferences from './marketing-preferences';
import modals from './modals';
import myWaitrose from './myWaitrose';
import order from './order';
import page from './page';
import product from './product';
import productsAndComponents from './products-and-components';
import reduxForms from './redux-forms';
import registration from './registration';
import slots from './slots';
import trolley from './trolley';

export default {
  ...addresses,
  ...addressSuggestions,
  ...contactUs,
  ...customerFeedback,
  ...datePicker,
  ...experiments,
  ...marketingPreferences,
  ...modals,
  ...myWaitrose,
  ...order,
  ...page,
  ...product,
  ...productsAndComponents,
  ...reduxForms,
  ...registration,
  ...trolley,
  ...slots,
};
