import { GET } from 'redux/modules/address-suggestion/actions/types';

const searchRequest = (state, action) => {
  const { searchTerm = '', resultSet = '' } = action;
  const { searchParam = {}, navigation } = state;
  if (!resultSet) {
    return {
      ...state,
      searchParam: {
        searchTerm: searchTerm !== '' ? searchTerm : searchParam.searchTerm,
        resultSet: resultSet !== '' ? resultSet : searchParam.resultSet,
      },
      navigation: [searchTerm],
      searchLoading: true,
    };
  }
  return {
    ...state,
    searchParam: {
      searchTerm: searchTerm !== '' ? searchTerm : searchParam.searchTerm,
      resultSet: resultSet !== '' ? resultSet : searchParam.resultSet,
    },
    searchLoading: true,
    navigation: [...navigation, resultSet],
  };
};

const searchSuccess = (state, action) => {
  const searchValues =
    action.result && Array.isArray(action.result) ? action.result.slice() : state.searchValues;
  return {
    ...state,
    searchValues,
    searchLoading: false,
  };
};

const searchFailure = (state, action) => ({
  ...state,
  searchLoading: false,
  error: action.error,
});

export default map => {
  map.set(GET.request, searchRequest);
  map.set(GET.success, searchSuccess);
  map.set(GET.failure, searchFailure);
  return map;
};
