import get from 'lodash/get';
import update from 'immutability-helper';
import { deepFreeze } from 'utils/deepFreeze';

import {
  FAVOURITES_SUMMARY_FULFILLED,
  FORGOTTEN_FAVOURITES_PRODUCTS_PENDING,
  FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED,
  FORGOTTEN_FAVOURITES_PRODUCTS_REJECTED,
} from 'redux/modules/favourites/actions/types';

export const initialState = deepFreeze({
  error: null,
  forgotten: [],
  loading: false,
  loadingMore: false,
  summaryProducts: undefined,
  totalForgotten: null,
  nRequestedProducts: 0,
});

export default function favourites(state = initialState, action = {}) {
  switch (action.type) {
    case FAVOURITES_SUMMARY_FULFILLED: {
      return { ...state, summaryProducts: get(action, 'result.products', []) };
    }
    case FORGOTTEN_FAVOURITES_PRODUCTS_PENDING:
      return {
        ...state,
        error: null,
        loading: !action.start,
        loadingMore: !!action.start,
      };
    case FORGOTTEN_FAVOURITES_PRODUCTS_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loadingMore: false,
      };
    case FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        forgotten: action.start
          ? update(state.forgotten, { $push: action.result.products })
          : action.result.products,
        totalForgotten: action.result.totalResults,
        nRequestedProducts: action.start ? state.nRequestedProducts + action.size : action.size,
      };
    default:
      return state;
  }
}
