/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import classNames from 'classnames';

import debounce from 'lodash/debounce';

import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'constants/directions';

import { setTimeout as setTimeoutWrapper } from 'utils/settimeout-wrapper';
import { slowlyScrollElementHorizontallyTo } from 'utils/scroll';

import { getNewSliderScrollPosition } from './calculate-scroll';
import HorizontalSliderControl from './Control';

import styles from './HorizontalSlider.scss';

const HorizontalSlider = ({ resetKey, threshold, children }) => {
  const [isAtTheEnd, setIsAtTheEnd] = useState(true);
  const [isAtTheStart, setIsAtTheStart] = useState(true);
  const [mouseActive, setMouseActive] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const slider = createRef();

  const updateControls = current => {
    if (!current) return;
    const sliderLength = current.scrollWidth - current.clientWidth;
    const sliderPos = current.scrollLeft;

    setIsAtTheEnd(sliderPos >= sliderLength - threshold);
    setIsAtTheStart(sliderPos <= 0 + threshold);
    setShowControls(sliderLength > 0);
  };

  const handleControls = direction => {
    const { current } = slider;
    const newScrollPosition = getNewSliderScrollPosition(current, direction);
    slowlyScrollElementHorizontallyTo(current, newScrollPosition);

    updateControls(current);
  };

  const debouncedResetControls = current => debounce(() => updateControls(current), 200);

  const addListeners = () => {
    const { current } = slider;

    root.addEventListener('resize', debouncedResetControls(current));
    if (current) current.addEventListener('scroll', debouncedResetControls(current), false);
  };

  const resetSlider = () => {
    setTimeoutWrapper(() => {
      const { current } = slider;

      if (!current) return;

      current.scrollLeft = 0;

      setIsAtTheEnd(!(current.scrollWidth - current.clientWidth));
      setIsAtTheStart(true);
      setShowControls(current.scrollWidth - current.clientWidth > 0);
    }, 0);
  };

  useEffect(() => {
    addListeners();
    resetSlider();
  }, []);

  useEffect(() => {
    updateControls();
  }, [slider.scrollWidth, slider.scrollLeft]);

  useEffect(() => {
    resetSlider();
  }, [resetKey]);

  const buttonProps = { hover: mouseActive, onClick: handleControls };

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.slider, {
          [styles.slide]: showControls,
        })}
        onMouseEnter={() => setMouseActive(true)}
        onMouseLeave={() => setMouseActive(false)}
        ref={slider}
        tabIndex="0"
      >
        {children}
      </div>
      <HorizontalSliderControl
        {...buttonProps}
        direction={DIRECTION_LEFT}
        display={!isAtTheStart}
      />
      <HorizontalSliderControl {...buttonProps} direction={DIRECTION_RIGHT} display={!isAtTheEnd} />
    </div>
  );
};

HorizontalSlider.displayName = 'HorizontalSlider';

HorizontalSlider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resetKey: PropTypes.string,
  threshold: PropTypes.number,
};

HorizontalSlider.defaultProps = {
  resetKey: null,
  threshold: 20,
};

export default HorizontalSlider;
