import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { openModal } from 'redux/modules/common-modal/actions';
import { clientOfflineModal } from 'constants/modals';
import locator from 'utils/locator';

export const showClientOfflineModal = (dispatch: WtrDispatch) => {
  dispatch(
    openModal({
      ...clientOfflineModal,
      onCloseCallback: modalCloseOption => {
        if (modalCloseOption === ModalCloseOptions.OK) locator.reload();
      },
    }),
  );
};
