import trolleyItemToAnalyticsProduct from 'redux/middleware/analytics/actions-to-track/utilities/trolley-item-to-analytics-product';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import { canInstantCheckout } from 'redux/modules/trolley/selectors/can-instant-checkout';
import { getPaymentTypes } from '../utilities/get-payment-types';

export const startedInstantCheckout = state => {
  const option = 'instant checkout amend';

  const trolleyItems = getTrolleyItems(state);
  const products = trolleyItems && trolleyItems.map(trolleyItemToAnalyticsProduct(state));

  const paymentType = getPaymentTypes(state);
  const instantCheckoutEligibility = canInstantCheckout(state);

  return [
    {
      event: 'checkout_start',
      instant_checkout_eligibility: instantCheckoutEligibility,
      ecommerce: {
        checkout: {
          actionField: {
            option,
          },
          products,
        },
      },
    },
    {
      event: 'checkout_review_order',
      instant_checkout_eligibility: instantCheckoutEligibility,
      ecommerce: {
        checkout: {
          actionField: {
            option,
          },
        },
      },
    },
    {
      event: 'checkout_click_confirm',
      instant_checkout_eligibility: instantCheckoutEligibility,
      ecommerce: {
        checkout: {
          actionField: {
            option,
            payment_type: paymentType,
          },
        },
      },
    },
  ];
};
