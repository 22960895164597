import { getCustomerId } from 'redux/modules/sessions/selectors';

const appstoreEvent = ({ appStore, customerId }) => ({
  event: 'navigate_to_app_store',
  appStore,
  customerId,
});

const androidAppStoreButtonClicked = state =>
  appstoreEvent({ appStore: 'google-play', customerId: getCustomerId(state) });

const appleAppStoreButtonClicked = state =>
  appstoreEvent({ appStore: 'apple', customerId: getCustomerId(state) });

const appleWalletButtonClicked = state => ({
  event: 'myWaitrose_apple_wallet_downloaded',
  customerId: getCustomerId(state),
});

const appleWalletButtonErrored = state => ({
  event: 'myWaitrose_apple_wallet_error',
  customerId: getCustomerId(state),
});

export {
  androidAppStoreButtonClicked,
  appleWalletButtonErrored,
  appleAppStoreButtonClicked,
  appleWalletButtonClicked,
};
