import { getCookie, saveCookie } from 'utils/cookie';
import { COOKIE_MONETATE_ID, COOKIE_CUSTOMER_ID } from 'constants/cookies';
import { addYears } from 'utils/date';

export const getPersonalisationIdsAndSaveToCookie = (
  personalisationMetadata: Record<string, string>,
) => {
  const customerId = getCookie(COOKIE_CUSTOMER_ID);
  const monetateIdFromMetadata = personalisationMetadata.monetate_id;
  const monetateIdFromCookie = getCookie(COOKIE_MONETATE_ID);

  if (!monetateIdFromCookie && monetateIdFromMetadata) {
    const cookieExpiryDate = addYears(new Date(), 1);

    saveCookie(COOKIE_MONETATE_ID, monetateIdFromMetadata, cookieExpiryDate, '.waitrose.com');
  }

  return {
    monetateId: monetateIdFromMetadata ?? monetateIdFromCookie,
    customerId: customerId ?? '-1',
  };
};
