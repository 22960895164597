import {
  GET_INCENTIVES_PRODUCTS_REQUEST,
  GET_INCENTIVES_PRODUCTS_SUCCESS,
  GET_INCENTIVES_PRODUCTS_FAILURE,
} from 'redux/modules/incentives/actions/types';
import { IncentivesState } from 'redux/modules/incentives/state';

import { Product } from 'constants/products';
import { ApiError } from 'constants/errors';

const getIncentivesProductsRequest = (state: IncentivesState): IncentivesState => ({
  ...state,
  isLoadingProducts: true,
  serverError: null,
});

const getIncentivesProductsSuccess = (
  state: IncentivesState,
  action: { payload: { products: Product[] } },
): IncentivesState => ({
  ...state,
  products: action.payload.products ?? [],
  isLoadingProducts: false,
});

const getIncentivesProductsFailure = (
  state: IncentivesState,
  action: { payload: ApiError },
): IncentivesState => ({
  ...state,
  isLoadingProducts: false,
  serverError: action.payload,
});

export default () =>
  [
    [GET_INCENTIVES_PRODUCTS_REQUEST, getIncentivesProductsRequest],
    [GET_INCENTIVES_PRODUCTS_SUCCESS, getIncentivesProductsSuccess],
    [GET_INCENTIVES_PRODUCTS_FAILURE, getIncentivesProductsFailure],
  ] as const;
