import { Branch } from 'api/definitions/branches/types';

const reducer = (state: WtrState, action: { result: { branches: Branch[] } }) => ({
  ...state,
  loading: false,
  branches: action.result.branches,
  error: null,
});

export default reducer;
