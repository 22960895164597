import myWaitroseByCard from 'api/definitions/mywaitrosebycard';
import { GET_MYWAITROSE_CARD_BY_ID } from 'redux/modules/mywaitrose/actions/types';

export const getMyWaitroseByCard =
  (cardNumber: string): WtrThunkAction =>
  dispatch => {
    return dispatch({
      apiCall: myWaitroseByCard.get({
        cardNumber,
        iHandleStatusCodes: [404],
      }),
      types: GET_MYWAITROSE_CARD_BY_ID.triplet,
    });
  };
