const cancelOrderApiSuccess = customerOrderId => ({
  event: 'transaction_cancelled',
  ecommerce: {
    refund: {
      actionField: {
        id: customerOrderId,
      },
    },
  },
});

const cancelOrderApiError = error => ({
  event: 'transaction_cancel_error',
  error,
});

export const checkCancelOrderByResponse = (state, payload) => {
  const {
    customerOrderId,
    result: {
      data: { cancelOrder },
    },
  } = payload;

  if (cancelOrder?.failures) return cancelOrderApiError(cancelOrder.failures);
  return cancelOrderApiSuccess(customerOrderId);
};
