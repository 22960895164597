import { SlotTypes } from 'constants/fulfilmentTypes';

export type SlotDatesInput = {
  addressId: string;
  branchId: string;
  slotType: SlotTypes;
  fromDate: string;
  customerOrderId: number;
  size: number;
};

export enum CampaignName {
  THANKSGIVING = 'THANKSGIVING',
  CHRISTMAS = 'CHRISTMAS',
  NEWYEAR = 'NEWYEAR',
  EASTER = 'EASTER',
  MOTHERSDAY = 'MOTHERSDAY',
}

export enum SlotGridType {
  DEFAULT_GRID = 'DEFAULT_GRID',
  SAVER_GRID = 'SAVER_GRID',
}

export type CampaignAPI = {
  name: CampaignName;
  marketingStartDate: string;
  marketingEndDate: string;
  startDate: string;
  endDate: string;
  inPriorityPeriod: boolean;
};

export type SlotDate = {
  date: string;
  dayOfWeek?: string;
};

export type SlotTime = {
  id: string;
  start: string;
  end: string;
};

export type SlotDatesResponse = {
  campaigns: CampaignAPI[];
  filedSlotTimes: {
    times: SlotTime[];
  };
  filedSlotTimesV2: {
    timesByGrids: {
      slotGridType: SlotGridType;
      slotTimes: SlotTime[];
    }[];
  };
  slotDatesOnly: {
    content: SlotDate[];
    failures: unknown;
  };
  slotPriority: {
    prioritySlotBookingAllowed: boolean;
  };
};
