import { createSelector } from 'reselect';

const getFavouritesProductsState = state => state.favouritesProducts;

export const getLineNumbersFetched = createSelector(
  [getFavouritesProductsState],
  ({ fetched } = {}) => fetched,
);

export const getLineNumbers = createSelector(
  [getFavouritesProductsState],
  ({ lineNumbers = {} } = {}) => lineNumbers,
);

export const getAllFavouritesFetched = createSelector(
  [getFavouritesProductsState],
  ({ fetched } = {}) => !!fetched,
);

export const makeGetIsFavourite = () =>
  createSelector(
    [getLineNumbers, (state, lineNumber) => lineNumber],
    (lineNumbers, lineNumber) => !!lineNumbers[lineNumber],
  );

export const getIsFavourite = makeGetIsFavourite();

export const getFavouritesTotal = createSelector(
  [getLineNumbers],
  lineNumbers => Object.keys(lineNumbers).filter(lineNumber => lineNumbers[lineNumber]).length,
);
