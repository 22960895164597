import orderPartnerDiscount from 'api/definitions/orderPartnerDiscount';
import { partnerDiscountAppliedAtCheckout } from 'constants/snackbars';
import { showSnackbar } from 'redux/modules/common-snackbar/actions';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { handleTimeoutError } from './errors';
import { ADD_PARTNER_DISCOUNT } from './types';

const addPartnerDiscount =
  ({ code, displaySnackbar = false }) =>
  async (dispatch, getState) => {
    try {
      const customerOrderId = getCustomerOrderId(getState());

      await dispatch({
        apiCall: orderPartnerDiscount.put({
          body: {
            partnerDiscountCardNumber: code,
          },
          customerOrderId,
          iHandleStatusCodes: [400],
        }),
        types: ADD_PARTNER_DISCOUNT.triplet,
        code,
      });

      if (displaySnackbar) {
        dispatch(showSnackbar(partnerDiscountAppliedAtCheckout));
      }
    } catch (error) {
      if (!dispatch(handleTimeoutError(error))) {
        throw error;
      }
    }
  };

export default addPartnerDiscount;
