import favourite from 'api/definitions/favourite';

import {
  FAVOURITE_TOGGLING,
  FAVOURITE_TOGGLED,
  FAVOURITE_FAILED,
} from 'redux/modules/search-and-browse/actions/types';

import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import { redirectToLogin } from 'utils/login';
import { trackPreLoginInteraction } from 'analytics/preLoginInteractionsTracking';
import { saveItemToFavouritePostAuthentication } from 'utils/favourites';

export const addRemoveFavourite =
  (isFavourited: boolean, lineNumber: string): WtrThunkAction =>
  (dispatch, getState) => {
    if (!isUserLoggedIn(getState())) {
      trackPreLoginInteraction('Add to Favourites');
      saveItemToFavouritePostAuthentication({
        isFavourited,
        lineNumber,
      });
      return redirectToLogin();
    }
    return dispatch({
      apiCall: favourite[isFavourited ? 'delete' : 'put']({ lineNumber }),
      lineNumber,
      types: [FAVOURITE_TOGGLING, FAVOURITE_TOGGLED, FAVOURITE_FAILED],
    });
  };
