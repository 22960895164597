import initialState from 'redux/modules/payment/state/initial-state';

const reducer = (state, { result }) => {
  const { paymentCardId } = result;
  const {
    acsUrl,
    currentApiRequest,
    errorStatusCode,
    errorCount,
    errorSource,
    payerAuthAmount,
    payerAuthRequest,
    payerAuthRequestId,
  } = initialState;

  return {
    ...state,
    acsUrl,
    currentApiRequest,
    errorStatusCode,
    errorCount,
    errorSource,
    payerAuthAmount,
    payerAuthRequest,
    payerAuthRequestId,
    paymentCardId,
  };
};

export default reducer;
