import { combineReducers } from 'redux';

import changeNameModal from '../change-name-modal/reducer/change-name-modal-reducer';
import changeEmailModal from '../change-email-modal/reducer/change-email-modal-reducer';
import changePasswordModal from '../change-password-modal/reducer/change-password-modal-reducer';

export default combineReducers({
  changeNameModal,
  changeEmailModal,
  changePasswordModal,
});
