export default ({ actionDefinedReturn = {}, statusCodes = [] } = {}) =>
  (error = {}) => {
    const { status = 0, message = '' } = error;

    if (!statusCodes.includes(status)) {
      throw message;
    }

    return actionDefinedReturn;
  };
