import orderStatus from 'constants/orderStatus';
import { getCustomerAccount } from 'redux/modules/accounts/selectors/get-customer-account';
import { getPlaceOrderOrigin } from 'redux/modules/checkout/selectors/get-place-order-origin';
import { getPlaceOrderOriginMobileAvailable } from 'redux/modules/checkout/selectors/get-place-order-origin-mobile-available';
import { getSaveNewCard } from 'redux/modules/checkout/selectors/get-save-new-card';
import { getIsInstantCheckoutIsInProgress } from 'redux/modules/instant-checkout/selectors';
import { getOrder } from 'redux/modules/order/selectors';
import {
  getSlotBranchId,
  getSlotType,
  isBookedSlotAnEntertainingCollectionSlot,
} from 'redux/modules/order/selectors/get-order-slot-selectors';
import {
  getEstimatedBalanceToPay,
  getEstimatedTotals,
} from 'redux/modules/order/selectors/get-order-totals';
import {
  getGiftCards,
  getGiftVouchers,
} from 'redux/modules/order/selectors/gift-cards-and-vouchers';
import { getPartnerDiscountCardNumber } from 'redux/modules/order/selectors/partner-discount';
import {
  getPromoCodesList,
  getQualifiedPromoCodesInline,
} from 'redux/modules/order/selectors/promo-codes';
import getFirstSavedPaymentCard from 'redux/modules/payment/selectors/get-first-saved-payment-card';
import { canInstantCheckout } from 'redux/modules/trolley/selectors/can-instant-checkout';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import { getAllFavouritesFetched } from 'redux/modules/favourites-products/selectors';
import { getPaymentTypes } from '../utilities/get-payment-types';
import trolleyItemToAnalyticsProduct from '../utilities/trolley-item-to-analytics-product';

type EventType = (
  | {
      event: 'redeem_gift_voucher';
      gift_voucher_number: string;
    }
  | { event: 'redeem_promo_code'; promo_code: string }
  | { event: 'redeem_gift_card'; gift_card_number: string }
)[];

function getOptionFieldValue(isAmend: boolean, isInstantCheckout: boolean) {
  if (isInstantCheckout) {
    return 'instant checkout amend';
  }

  return isAmend ? 'amend' : 'purchase';
}

function getSaveNewCardValue(
  saveNewCard: boolean | undefined,
  isEntertainingCollectionSlot: boolean,
) {
  if (isEntertainingCollectionSlot) {
    return 'no_card';
  }

  if (saveNewCard === undefined) {
    return 'using_saved_card';
  }

  return saveNewCard ? 'save_card' : 'dont_save_card';
}

export default (state: WtrState) => {
  const orderState = getOrder(state);
  const trolleyItems = getTrolleyItems(state);
  const trolleyItemMapper = trolleyItemToAnalyticsProduct(state);
  const isFavouritesFetched = getAllFavouritesFetched(state);
  const isInstantCheckout = getIsInstantCheckoutIsInProgress(state);
  const giftCards = getGiftCards(state);
  const giftVouchers = getGiftVouchers(state);
  const promoCodes = getPromoCodesList(state);
  const partnershipDiscount = getPartnerDiscountCardNumber(state);
  const { email, id: customerId } = getCustomerAccount(state);

  const customerOrderId = orderState?.customerOrderId;
  const isAmend = orderState?.order?.status === orderStatus.AMENDING;
  const products = trolleyItems && trolleyItems.map(trolleyItemMapper);

  const { deliveryCharge } = getEstimatedTotals(state);
  const instantCheckoutEligibility = canInstantCheckout(state);
  const placeOrderOrigin = getPlaceOrderOrigin(state) || '';
  const placeOrderOriginMobile = getPlaceOrderOriginMobileAvailable(state)
    ? 'available'
    : 'unavailable';

  const accountHasSavedCard = !!getFirstSavedPaymentCard(state);
  const saveNewCard = getSaveNewCardValue(
    getSaveNewCard(state),
    isBookedSlotAnEntertainingCollectionSlot(state),
  );

  const giftCardEvents: EventType = giftCards.map(({ serialNumber }) => ({
    event: 'redeem_gift_card',
    gift_card_number: serialNumber,
  }));

  const giftVouchersEvents: EventType = giftVouchers
    .filter(({ status }) => status !== 'REDEEMED')
    .map(({ serialNumber }) => ({
      event: 'redeem_gift_voucher',
      gift_voucher_number: serialNumber,
    }));

  const promoCodesEvents: EventType = promoCodes
    .filter(({ status }) => status === 'APPLIED')
    .map(({ code }) => ({
      event: 'redeem_promo_code',
      promo_code: code,
    }));

  const partnershipDiscountEvent: EventType = partnershipDiscount
    ? [{ event: 'redeem_promo_code', promo_code: partnershipDiscount }]
    : [];

  const currentDate = new Date();

  const allEvents: any[] = [
    ...giftCardEvents,
    ...giftVouchersEvents,
    ...promoCodesEvents,
    ...partnershipDiscountEvent,
  ];

  if (isFavouritesFetched) {
    allEvents.push({
      event: 'transaction_completed',
      instant_checkout_eligibility: instantCheckoutEligibility,
      orderDate: currentDate.toISOString(),
      user: {
        email_address: email,
        customerId,
      },
      ecommerce: {
        purchase: {
          actionField: {
            id: customerOrderId,
            affiliation: getSlotBranchId(state),
            revenue: getEstimatedBalanceToPay(state),
            shipping: deliveryCharge?.amount,
            coupon: getQualifiedPromoCodesInline(state), // TODO: We need to check with analytics engineers if they want change this structure now that we have to support multiple promo codes
            option: getOptionFieldValue(isAmend, isInstantCheckout),
            service_type: getSlotType(state),
            payment_type: getPaymentTypes(state),
            account_saved_card: accountHasSavedCard,
            save_new_card: saveNewCard,
            place_order_origin: placeOrderOrigin,
            place_order_origin_mobile: placeOrderOriginMobile,
          },
          products,
        } as unknown as any,
      },
    });
  }

  return allEvents;
};
