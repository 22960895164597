import Modal from 'components/Modal';
import React, { FC } from 'react';
import { Modal as IngredientsModal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { ModalButton } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal/Buttons';

export type ModalSelectorProps = {
  useIngredientsModal?: boolean;
  isOpen: boolean;
  closeModal: (
    modalCloseValue: ModalButton['buttonValue'],
    modalCloseText: ModalButton['buttonText'],
  ) => WtrThunkAction<void>;
  [x: string]: any;
};

const ModalSelector: FC<ModalSelectorProps> = ({
  useIngredientsModal = true,
  isOpen,
  closeModal,
  ...rest
}) => {
  if (!isOpen) return null;

  return useIngredientsModal ? (
    <IngredientsModal
      handleModalClose={({ value, label }) => closeModal(value, label)}
      isOpen
      {...rest}
    />
  ) : (
    <Modal isOpen closeModal={closeModal} {...rest} />
  );
};

export default ModalSelector;
