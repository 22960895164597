import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import {
  pending as forgotPasswordPending,
  fulfilled as forgotPasswordFulfilled,
  rejected as forgotPasswordRejected,
} from './reset-password';

import { resetPasswordActions } from '../actions/types';

const initialState = deepFreeze({
  loading: false,
  error: '',
});

const actionTypeReducerMap = new Map([
  [resetPasswordActions.request, forgotPasswordPending],
  [resetPasswordActions.success, forgotPasswordFulfilled],
  [resetPasswordActions.failure, forgotPasswordRejected],
]);

export default createReducer(initialState, actionTypeReducerMap);
