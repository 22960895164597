import { REPORT_ADDRESS_PROPERTIES_EDITED } from 'redux/modules/address/actions/types';
import { propertiesChanged } from 'components/Address/util';

const reportEditedFields = (state, action) => {
  const { address = {} } = action;
  const { control = null } = address;
  const editedFields = propertiesChanged(control, address);
  return {
    ...state,
    editedFields,
  };
};

export default map => {
  map.set(REPORT_ADDRESS_PROPERTIES_EDITED, reportEditedFields);
  return map;
};
