import locator from 'utils/locator';
import { local as storage } from 'utils/storage';
import urls from 'constants/urls';

import { callCustomerDataLayerEvent } from 'redux/modules/login/customer-datalayer-event';
import { dataLayer } from 'analytics/data-layer';
import { WtrGetState } from 'redux/create-store';

export const notifyLoginError = (message: string) => {
  dataLayer.push({
    event: 'login_error',
    error_message: message,
  });
};

export const isLoggingStorageKey = 'isLogging';

export const notifyLoginSuccess = () => (_dispatch: WtrDispatch, getState: WtrGetState) => {
  try {
    const justLoggedIn = storage.get(isLoggingStorageKey);
    if (justLoggedIn === 'true' && locator.pathname !== urls.login) {
      storage.remove(isLoggingStorageKey);
      callCustomerDataLayerEvent(getState(), 'login');
    }
  } catch {
    // Error checking localStorage - do nothing
  }
};
