import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getProductById } from 'redux/modules/entities/selectors/products';

export const getAlcoholDetails = createSelector(
  [getProductById],
  ({ alcoholDetails } = {}) => alcoholDetails,
);

export const getProductBrand = createSelector([getProductById], product =>
  get(product, 'brand', null),
);

export const getProductClosureType = createSelector([getProductById], product =>
  get(product, 'closureType', null),
);

export const makeGetProductName = () =>
  createSelector([getProductById], product => get(product, 'name', null));

export const getProductName = makeGetProductName();

export const getProductPackageContents = createSelector([getProductById], product =>
  get(product, 'packageContents', null),
);

export const getProductPackaging = createSelector([getProductById], product =>
  get(product, 'packaging', null),
);

export const getProductPercentByVolume = createSelector(
  [getAlcoholDetails],
  alcoholdetails => alcoholdetails?.percentByVolume,
);

export const getProductUnitsInBottle = createSelector(
  [getAlcoholDetails],
  alcoholDetails => alcoholDetails?.unitsInBottle,
);

export const getProductReviews = createSelector([getProductById], product => product?.reviews);

export const productHasReviews = createSelector(
  getProductReviews,
  reviews => reviews?.reviewCount > 0,
);

export const getProductThumbnail = createSelector([getProductById], product =>
  get(product, 'thumbnail', null),
);

export const getProductType = createSelector([getProductById], product =>
  get(product, 'productType', null),
);

export const makeGetMarkedForDelete = () =>
  createSelector([getProductById], product => get(product, 'markedForDelete', false));

export const getMarkedForDelete = makeGetMarkedForDelete();
