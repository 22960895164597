import update from 'immutability-helper';

import { SET_BRANCH_NAME } from 'redux/modules/entities/actions/types';

const orders = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_BRANCH_NAME: {
      const {
        payload: { branchName, orderId },
      } = action;

      return update(state, {
        [orderId]: {
          slotBranchName: {
            $set: branchName,
          },
        },
      });
    }
    default:
      return state;
  }
};

export default orders;
