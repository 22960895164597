import {
  SET_SELECTED_ADDRESS,
  CLEAR_SELECTED_ADDRESS,
} from 'redux/modules/slot-booking/actions/types';

export const setSelectedAddress = (
  address: { addressId?: string; postalCode: string },
  position?: unknown,
) => ({
  type: SET_SELECTED_ADDRESS,
  address,
  position,
});

export const clearSelectedAddress = {
  type: CLEAR_SELECTED_ADDRESS,
};
