import { createSelector, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

import { getTrolleyConflicts } from 'redux/modules/conflicts/selectors';
import { getProducts } from 'redux/modules/entities/selectors/products';
import {
  isItemEditable,
  isItemRemovable,
  hasHardConflict,
  hasSoftConflict,
} from 'redux/modules/conflicts/utils';
import { isPersonalisableProduct } from 'utils/product';
import { findItemByLineNumber } from '../utils/findItemByLineNumber';
import {
  getOptimisticTrolleyItems,
  getBlendedOptimisticTrolleyItems,
} from './get-optimistic-items';

const EMPTY_OBJECT = {};

const getLineNumber = (_state, lineNumber) => lineNumber;

export const makeGetOptimisticItemByLineNumber = () =>
  createSelector([getOptimisticTrolleyItems, getLineNumber], findItemByLineNumber, {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  });

export const getOptimisticItemByLineNumber = makeGetOptimisticItemByLineNumber();

export const makeGetShopperNotesAndCanSubstituteByLineNumber = () =>
  createSelector(
    [getOptimisticItemByLineNumber],
    ({ canSubstitute, noteToShopper } = {}) => ({
      optimisticCanSubstitute: canSubstitute,
      noteToShopper,
    }),
    {
      memoize: lruMemoize,
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    },
  );

/**
 * @type (state: WtrState, trolleyItemId: string)
 */
export const getFromBlendedItemsById = createSelector(
  [getBlendedOptimisticTrolleyItems, (state, trolleyItemId) => trolleyItemId],
  (blendedTrolleyItems, trolleyItemId) =>
    blendedTrolleyItems.find(item => item.trolleyItemId === trolleyItemId),
);

export const getFromBlendedItemsByLineNumber = createSelector(
  [getBlendedOptimisticTrolleyItems, (state, lineNumber) => lineNumber],
  findItemByLineNumber,
);

export const makeGetItemIdByLineNumber = () =>
  createSelector([getFromBlendedItemsByLineNumber], ({ trolleyItemId } = {}) => trolleyItemId);

export const getItemIdByLineNumber = makeGetItemIdByLineNumber();

export const makeGetBlendedTrolleyItemByLineNumber = () =>
  createSelector([getFromBlendedItemsByLineNumber], item => item || EMPTY_OBJECT);

export const getBlendedTrolleyItemByLineNumber = makeGetBlendedTrolleyItemByLineNumber();

export const getOptimisticTrolleyProductByItemId = createSelector(
  [getFromBlendedItemsById, getProducts, getTrolleyConflicts],
  (trolleyItem, products, conflicts) => {
    if (!trolleyItem) return EMPTY_OBJECT;
    const { lineNumber, trolleyItemId, discounts } = trolleyItem;
    const product = products[lineNumber] || {};
    return {
      ...trolleyItem,
      ...product,
      hasHardConflict: hasHardConflict(conflicts, trolleyItemId),
      hasSoftConflict: hasSoftConflict(conflicts, trolleyItemId),
      hasTriggeredOffer:
        discounts?.some(({ status, category }) => status === 'TRIGGERED' && category === 'OFFER') ??
        false,
      hasUntriggeredOffer:
        discounts?.some(
          ({ status, category }) => status === 'UNTRIGGERED' && category === 'OFFER',
        ) ?? false,
      isItemEditable: isItemEditable(conflicts, trolleyItemId),
      isItemRemovable: isItemRemovable(conflicts, trolleyItemId),
      isMissingProductDetails: Object.keys(product).length === 0,
      isPersonalisableProduct: isPersonalisableProduct(product),
      lineNumber,
      productId: product.id || trolleyItem.productId,
    };
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const makeGetOptimisticCanSubstituteByItemId = () =>
  createSelector(
    [getOptimisticTrolleyProductByItemId],
    ({ canSubstitute: blendedCanSubstitute } = {}) => blendedCanSubstitute,
    {
      memoize: lruMemoize,
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    },
  );
