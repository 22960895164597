export const CUSTOMER_SERVICE_FORM_SUBMIT = 'waitrose/customerService/CUSTOMER_SERVICE_FORM_SUBMIT';
export const CUSTOMER_SERVICE_FORM_SUBMIT_FAIL =
  'waitrose/customerService/CUSTOMER_SERVICE_FORM_SUBMIT_FAILED';
export const CUSTOMER_SERVICE_FORM_SUBMIT_SUCCESS =
  'waitrose/customerService/CUSTOMER_SERVICE_FORM_SUBMIT_SUCCESS';
export const PRODUCT_ISSUES_FORM_SUBMIT = 'waitrose/customerService/PRODUCT_ISSUES_FORM_SUBMIT';
export const PRODUCT_ISSUES_FORM_SUBMIT_FAIL =
  'waitrose/customerService/PRODUCT_ISSUES_SUBMIT_FAILED';
export const PRODUCT_ISSUES_FORM_SUBMIT_SUCCESS =
  'waitrose/customerService/PRODUCT_ISSUES_FORM_SUBMIT_SUCCESS';
export const PRODUCT_ISSUES_FORM_RESET = 'waitrose/customerService/PRODUCT_ISSUES_FORM_RESET';

export const CUSTOMER_SERVICE_FORM_ACTIONS = [
  CUSTOMER_SERVICE_FORM_SUBMIT,
  CUSTOMER_SERVICE_FORM_SUBMIT_SUCCESS,
  CUSTOMER_SERVICE_FORM_SUBMIT_FAIL,
] as const;

export const PRODUCT_ISSUES_FORM_ACTIONS = [
  PRODUCT_ISSUES_FORM_SUBMIT,
  PRODUCT_ISSUES_FORM_SUBMIT_SUCCESS,
  PRODUCT_ISSUES_FORM_SUBMIT_FAIL,
] as const;
