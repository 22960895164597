import branchbyid from 'api/definitions/branchbyid';
import { BranchById } from 'api/definitions/branchbyid/types';

import { GET_BRANCH_BY_ID } from 'redux/modules/branches/actions/types';

const branchTransform = (res: BranchById) => ({
  ...res,
  id: res.id.toString(),
});

export const getBranchById = (branchId: string) => ({
  types: GET_BRANCH_BY_ID.triplet,
  apiCall: branchbyid.get({ branchId }),
  callback: {
    success: branchTransform,
  },
});

export { getBranchById as default };
