export const CLEAR_CUSTOMER_SLOT = 'waitrose/slotDays/CLEAR_CUSTOMER_SLOT';
export const CUSTOMER_SLOT_REQUEST = 'waitrose/slotDays/CUSTOMER_SLOT_REQUEST';
export const CUSTOMER_SLOT_REQUEST_SUCCESS = 'waitrose/slotDays/CUSTOMER_SLOT_REQUEST_SUCCESS';
export const CUSTOMER_SLOT_REQUEST_FAIL = 'waitrose/slotDays/CUSTOMER_SLOT_REQUEST_FAIL';
export const UPDATE_ADDRESS_NAME = 'waitrose/slotDays/UPDATE_ADDRESS_NAME';

export const CUSTOMER_SLOT_REQUEST_ACTIONS = [
  CUSTOMER_SLOT_REQUEST,
  CUSTOMER_SLOT_REQUEST_SUCCESS,
  CUSTOMER_SLOT_REQUEST_FAIL,
  UPDATE_ADDRESS_NAME,
];

export const CANCEL_SLOT_REQUEST = 'waitrose/cancelSlot/CANCEL_SLOT_REQUEST';
export const CANCEL_SLOT_REQUEST_SUCCESS = 'waitrose/cancelSlot/CANCEL_SLOT_REQUEST_SUCCESS';
export const CANCEL_SLOT_REQUEST_FAIL = 'waitrose/cancelSlot/CANCEL_SLOT_REQUEST_FAIL';
export const CANCEL_SLOT_REQUEST_ACTIONS = [
  CANCEL_SLOT_REQUEST,
  CANCEL_SLOT_REQUEST_SUCCESS,
  CANCEL_SLOT_REQUEST_FAIL,
];
