import { OPTIONS_BY_ID } from 'redux/modules/address/actions/types';

const optionAddressByIdRequest = state => {
  const { cannotAlterAddress } = state;
  return {
    ...state,
    addressInError: null,
    cannotAlterAddress: {
      ...cannotAlterAddress,
      options: [],
    },
    request: { loading: true, loaded: false, error: null },
  };
};

const optionAddressByIdSuccess = (state, action) => {
  const { options = [] } = action.result;
  const { cannotAlterAddress } = state;
  return {
    ...state,
    cannotAlterAddress: {
      ...cannotAlterAddress,
      options,
    },
    request: { loading: false, loaded: true, error: null },
  };
};

const optionAddressByIdFailure = (state, action) => {
  const { cannotAlterAddress } = state;
  return {
    ...state,
    cannotAlterAddress: {
      ...cannotAlterAddress,
      options: [],
    },
    request: { loading: false, loaded: false, error: action.error },
  };
};

export default map => {
  map.set(OPTIONS_BY_ID.request, optionAddressByIdRequest);
  map.set(OPTIONS_BY_ID.success, optionAddressByIdSuccess);
  map.set(OPTIONS_BY_ID.failure, optionAddressByIdFailure);
  return map;
};
