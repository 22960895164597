import { ADDRESS_API_ERROR, CONTACT_ADDRESS_API_ERROR } from 'redux/modules/address/actions/types';

const addressApiError = (state, action) => {
  const { address } = action;
  const { request } = state;
  return {
    ...state,
    addressInError: address,
    addAddressLoading: false,
    editAddressLoading: false,
    request: {
      ...request,
      loaded: true,
    },
  };
};

const contactAddressApiError = state => {
  const { request = {}, addresses = [], chooseAddressModal = {} } = state;
  const accountsProfile = addresses.find(a => a.isContactAddress) || {};
  const { id = null } = accountsProfile;

  if (id !== null) {
    return {
      ...state,
      addresses: [
        { ...accountsProfile, isContactAddress: true },
        ...addresses.filter(a => a.id !== id).map(a => ({ ...a, isContactAddress: false })),
      ],
      addressInError: accountsProfile,
      chooseAddressModal: {
        ...chooseAddressModal,
        address: accountsProfile,
      },
      request: {
        ...request,
        loaded: true,
      },
    };
  }

  return {
    ...state,
    addresses: [
      ...addresses.filter(a => a.id !== id).map(a => ({ ...a, isContactAddress: false })),
    ],
    request: {
      ...request,
      loaded: true,
    },
  };
};

export default map => {
  map.set(ADDRESS_API_ERROR, addressApiError);
  map.set(CONTACT_ADDRESS_API_ERROR, contactAddressApiError);
  return map;
};
