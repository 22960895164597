import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const SHOW_CHANGE_NAME_MODAL = 'waitrose/SHOW_CHANGE_NAME_MODAL';
export const HIDE_CHANGE_NAME_MODAL = 'waitrose/HIDE_CHANGE_NAME_MODAL';
export const SHOW_CHANGE_EMAIL_MODAL = 'waitrose/SHOW_CHANGE_EMAIL_MODAL';
export const HIDE_CHANGE_EMAIL_MODAL = 'waitrose/HIDE_CHANGE_EMAIL_MODAL';
export const SHOW_CHANGE_PASSWORD_MODAL = 'waitrose/SHOW_CHANGE_PASSWORD_MODAL';
export const HIDE_CHANGE_PASSWORD_MODAL = 'waitrose/HIDE_CHANGE_PASSWORD_MODAL';
export const SHOW_INVALID_PASSWORD_ERROR = 'waitrose/SHOW_INVALID_PASSWORD_ERROR';
export const HIDE_INVALID_PASSWORD_ERROR = 'waitrose/HIDE_INVALID_PASSWORD_ERROR';

export const UPDATE_NAME = apiPromiseTypes('myDetailsName', 'patch');
export const UPDATE_EMAIL = apiPromiseTypes('myDetailsEmail', 'patch');
export const changePasswordActions = apiPromiseTypes('change-password', 'patch');
