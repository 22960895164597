import root from 'window-or-global';
import throttle from 'lodash/throttle';
import type { History } from 'history';

import { scrollTo } from 'utils/scroll-to-wrapper';
import { canRestoreScrollStateFromSessionStorage } from 'utils/scroll-session-storage';

const scrollWhenReady = (position = 0) => {
  setTimeout(() => {
    if (position > document.body.scrollHeight) {
      scrollWhenReady(position);
    } else {
      scrollTo(0, position);
    }
  }, 200);
};

const initScrollRestorer = (history: History) => {
  const positions = new Map<string, number>();
  let prevKey: string;
  let prevPathname = root.location.pathname;

  root.document.addEventListener(
    'scroll',
    throttle(() => {
      positions.set(prevKey, root.pageYOffset);
    }, 200),
  );

  history.listen(({ location: { key, pathname, state }, action }) => {
    prevKey = key;

    // If the pathname has not changed, return early to allow links to document fragments to work.
    if (pathname === prevPathname) {
      return;
    }

    prevPathname = pathname;

    if (state && typeof state === 'object' && 'noScroll' in state && state.noScroll) return;

    if (canRestoreScrollStateFromSessionStorage()) return;

    if (action === 'PUSH') {
      // New page in history
      scrollWhenReady(0);
    } else {
      // Forward OR backward
      scrollWhenReady(positions.get(key) ?? 0);
    }
  });
};

export default initScrollRestorer;
