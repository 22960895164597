export default (state, action) => {
  const promoCode = action.result;

  return {
    ...state,
    promoCodes: {
      ...state.promoCodes,
      promoCodesList: promoCode
        ? state.promoCodes.promoCodesList.concat(promoCode)
        : [...state.promoCodes.promoCodesList],
      isAdding: false,
    },
    waiting: false,
  };
};
