import { bool, shape, string } from 'prop-types';

import formatSlotButtonText from './format-slot-button-text';

const SlotButtonText = ({
  isSlotBooked,
  showEndTime,
  slotDetails: { slotDate, slotEndTime, slotStartTime } = {},
  shortTime,
} = {}) =>
  formatSlotButtonText({
    isSlotBooked,
    showEndTime,
    slotDate,
    slotEndTime,
    slotStartTime,
    shortTime,
  });

SlotButtonText.displayName = 'SlotButtonText';

SlotButtonText.propTypes = {
  isSlotBooked: bool,
  showEndTime: bool,
  slotDetails: shape({
    slotDate: string,
    slotEndTime: string,
    slotStartTime: string,
  }),
};

SlotButtonText.defaultProps = {
  isSlotBooked: false,
  showEndTime: false,
  slotDetails: {},
};

export default SlotButtonText;
