import { apiCallback } from 'api';

const definition = {
  service: 'search',
  endpoint: 'taxonomy/footer',
  verbs: ['get'],
  defaultVersion: 2,
  memoryCacheKey: 'footer',
  memoryCacheMaxAge: 300000,
};

export default {
  get: apiCallback(definition, 'get'),
};
