import { Response } from 'superagent';
import { withPersonalisationHeader } from 'api/utils/personalisation-header';
import { canShowRecommended } from 'components/RecommendedCarousel/canShowRecommended';
import { trackPersonalisation } from 'analytics/personalisation-tracking';

export const trackPersonalisationHeaderAndRecommendations = (
  res: Pick<Response, 'header' | 'body'>,
) => {
  withPersonalisationHeader(res, ({ personalisation }) => {
    const { recommendations = [], metadata: { fallbackRecommendations: fallback = false } = {} } =
      res.body ?? {};

    trackPersonalisation({
      personalisation,
      otherTrackingValues: {
        is_carousel_visible: canShowRecommended(recommendations) ? 'YES' : 'NO',
      },
      fallback,
    });
  });
};
