import queryString from 'query-string';

import { CLICK_ID_KEY } from 'constants/impact';
import locator from 'utils/locator';
import { stashObject } from 'utils/stash';

export const stashClickId = () => {
  const { irclickid } = queryString.parse(locator.search);

  if (irclickid) stashObject(CLICK_ID_KEY, irclickid);
};
