import { schema } from 'normalizr';
import omit from 'lodash/omit';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

const productConflictEntity = new schema.Entity(
  'productConflicts',
  {},
  { idAttribute: ({ productId }) => extractLineNumberFromSku(productId) },
);
const promotionEntity = new schema.Entity(
  'promotions',
  {},
  {
    idAttribute: 'promotionId',
    processStrategy: (value, parent) => {
      const { promotionId, wasDisplayPrice = null } = value;

      if (wasDisplayPrice) {
        const { previousPricing } = parent;

        // eslint-disable-next-line no-param-reassign
        parent.previousPricing = {
          ...previousPricing,
          [promotionId]: wasDisplayPrice,
        };
      }

      return omit(value, 'wasDisplayPrice');
    },
  },
);
const trolleyConflictEntity = new schema.Entity('trolleyConflicts', {}, { idAttribute: 'itemId' });
const productEntity = new schema.Entity(
  'products',
  {
    conflicts: [productConflictEntity],
    promotions: [promotionEntity],
  },
  { idAttribute: ({ id, lineNumber }) => lineNumber || extractLineNumberFromSku(id) },
);
const orderEntity = new schema.Entity('orders', {}, { idAttribute: 'orderId' });
const shoppingListEntity = new schema.Entity('shoppingLists', {
  shoppingListId: {
    items: [
      {
        item: productEntity,
      },
    ],
  },
});

const aemChildComponentSchema = new schema.Object({
  items: [
    {
      searchProduct: productEntity,
    },
  ],
});
aemChildComponentSchema.define({
  childComponents: [aemChildComponentSchema],
  paragraphSystem: {
    childComponents: [aemChildComponentSchema],
  },
});

export const cmsMultiGridSchema = {
  nodes: [aemChildComponentSchema],
};

const locationsSchema = new schema.Values([aemChildComponentSchema]);

export const experienceFragmentSchema = {
  conflicts: [productConflictEntity],
  locations: locationsSchema,
};

export const componentsAndProductsSchema = {
  content: locationsSchema,
  locations: locationsSchema,
  componentsAndProducts: [
    {
      searchProduct: productEntity,
      aemComponent: aemChildComponentSchema,
    },
  ],
};

export const componentsAndProductsAndRecommendationsSchema = {
  ...componentsAndProductsSchema,
  recommendations: [
    {
      searchProduct: productEntity,
    },
  ],
};

const customerOrderEntity = new schema.Entity(
  'customerOrders',
  {},
  { idAttribute: 'customerOrderId' },
);

export const customerOrdersSchema = {
  content: [customerOrderEntity],
};

export const legacyProductsSchema = {
  products: [
    {
      searchProduct: productEntity,
    },
  ],
};

export const legacyProductsAndRecommendationsSchema = {
  ...legacyProductsSchema,
  recommendations: [
    {
      searchProduct: productEntity,
    },
  ],
};

export const productsSchema = {
  products: [productEntity],
};

export const bundleSchema = {
  productCollections: [legacyProductsSchema],
};

export const promotionsSchema = [promotionEntity];

export const trolleySchema = {
  products: [productEntity],
};

export const ordersSchema = {
  legacyOrderSummaryResponse: {
    orderSummaries: [orderEntity],
  },
};

export const shoppingListSchema = shoppingListEntity;
export const shoppingListsSchema = [shoppingListEntity];

export const productConflictListSchema = [productConflictEntity];
export const trolleyConflictListSchema = [trolleyConflictEntity];

export const BUNDLE_SCHEMA = 'bundleSchema';
export const CMS_MULTI_GRID_SCHEMA = 'cmsMultiGridSchema';
export const COMPONENTS_AND_PRODUCTS_SCHEMA = 'componentsAndProductsSchema';
export const COMPONENTS_AND_PRODUCTS_AND_RECOMMENDATIONS_SCHEMA =
  'componentsAndProductsAndRecommendationsSchema';
export const CUSTOMER_ORDERS_SCHEMA = 'customerOrdersSchema';
export const EXPERIENCE_FRAGMENT_SCHEMA = 'experienceFragmentSchema';
export const PRODUCT_CONFLICT_LIST_SCHEMA = 'productConflictListSchema';
export const PRODUCTS_SCHEMA = 'productsSchema';
export const LEGACY_PRODUCTS_SCHEMA = 'legacyProductsSchema';
export const LEGACY_PRODUCTS_AND_RECOMMENDATIONS_SCHEMA = 'legacyProductsAndRecommendationsSchema';
export const ORDERS_SCHEMA = 'ordersSchema';
export const PROMOTIONS_SCHEMA = 'promotionsSchema';
export const SHOPPING_LIST_SCHEMA = 'shoppingListSchema';
export const SHOPPING_LISTS_SCHEMA = 'shoppingListsSchema';
export const TROLLEY_CONFLICT_LIST_SCHEMA = 'trolleyConflictListSchema';
export const TROLLEY_SCHEMA = 'trolleySchema';

export const schemas = {
  bundleSchema,
  cmsMultiGridSchema,
  componentsAndProductsSchema,
  componentsAndProductsAndRecommendationsSchema,
  customerOrdersSchema,
  experienceFragmentSchema,
  ordersSchema,
  legacyProductsSchema,
  legacyProductsAndRecommendationsSchema,
  productConflictListSchema,
  productsSchema,
  promotionsSchema,
  shoppingListSchema,
  shoppingListsSchema,
  trolleyConflictListSchema,
  trolleySchema,
};
