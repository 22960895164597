import { openModal } from 'redux/modules/common-modal/actions';
import locator from 'utils/locator';

export const handleTimeoutError =
  (error: unknown): WtrThunkAction<boolean> =>
  dispatch => {
    if (
      error &&
      typeof error === 'object' &&
      'timeout' in error &&
      typeof error.timeout === 'number'
    ) {
      dispatch(
        openModal({
          id: 'checkout-timeout-error',
          severity: 'error',
          titleText: "Sorry, there's been a problem",
          messageText: ['Please try again'],
          buttons: [
            {
              buttonText: 'Try again',
              theme: 'primary',
            },
          ],
          webview: {
            id: 'checkout-error-timeout',
            ctas: [null],
          },
          onCloseCallback: () => locator.reload(),
        }),
      );
      return true;
    }

    return false;
  };
