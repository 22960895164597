const REQUEST = 0;
const SUCCESS = 1;
const FAILURE = 2;

const defaultSuccess = resp => resp;
const defaultFailure = err => {
  throw err;
};

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const { apiCall, types, callback = {}, timeout, ...rest } = action;
    const { success = defaultSuccess, failure = defaultFailure } = callback;

    if (!apiCall) return next(action);

    next({ ...rest, type: types[REQUEST] });

    return apiCall({ dispatch, getState, timeout })
      .then(success)
      .then(result => {
        // we keep passing "result" alongside "payload" for retrocompatibility reasons because old reducers rely on the existence of "result"
        // "payload" should be used for any new/refactored reducer
        next({ ...rest, result, payload: result, type: action.types[SUCCESS] });
        return result;
      })
      .catch(failure)
      .catch(error => {
        // we keep passing "error" alongside "payload" for retrocompatibility reasons because old reducers rely on the existence of "error"
        // "payload" should be used for any new/refactored reducer
        next({ ...rest, error, payload: error, type: action.types[FAILURE] });

        throw error;
      });
  };
