import { FulfilmentType, fulfilmentTypes } from 'constants/fulfilmentTypes';
import { getPathName } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';

export const getFulfilmentTypeByURL = (url: string): FulfilmentType | undefined => {
  let result;
  const routePath = url || getPathName();
  (Object.keys(fulfilmentTypes) as [keyof typeof fulfilmentTypes]).forEach(item => {
    const fulfilmentType = fulfilmentTypes[item];
    if (routePath.includes(fulfilmentType.path)) {
      result = fulfilmentType;
    }
  });

  return result;
};
