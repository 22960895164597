import { Branch } from 'api/definitions/branches/types';
import { SlotGridType } from 'api/definitions/slotdates/types';
import { FulfilmentType } from 'constants/fulfilmentTypes';
import { Address } from 'redux/modules/address/state/initial-state';
import { deepFreeze } from 'utils/deepFreeze';

export type RestrictedProductGroups = {
  intendedSelectedDate?: string;
  productGroups: Array<{
    message?: string;
    products: Array<{
      name?: string;
      price?: string;
      productId: string;
      quantity: string;
      onProductSelection: () => void;
    }>;
  }>;
  saverGridTabSelected?: boolean;
};

export interface SlotBookingState {
  fulfilmentType: FulfilmentType | null;
  hasSelectedNewSlotThisPageVisit: boolean;
  lastNonSlotBookingPath: string | null;
  restrictedProductGroups: RestrictedProductGroups;
  selectedAddress: Address | null;
  selectedBranch: Branch | null;
  selectedSlotGridType: SlotGridType;
  selectedDate: string;
  showExpiredOffersModal: boolean;
  slotChargesLoading: boolean;
  gridLength: 3 | 5;
}

const initialState: Readonly<SlotBookingState> = deepFreeze({
  fulfilmentType: null,
  hasSelectedNewSlotThisPageVisit: false,
  lastNonSlotBookingPath: null,
  restrictedProductGroups: { productGroups: [] },
  selectedAddress: null,
  selectedBranch: null,
  selectedSlotGridType: SlotGridType.DEFAULT_GRID,
  selectedDate: '',
  showExpiredOffersModal: false,
  slotChargesLoading: false,
  gridLength: 5,
});

export default initialState;
