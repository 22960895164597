import { all } from 'route-data/all';
import { chain } from 'route-data/chain';
import { SITE_WIDE_BANNER } from 'constants/experience-fragments';
import cmsExperienceFragment from 'redux/fetchers/experience-fragment';
import { minimumOrderValuesFetcher } from 'redux/fetchers/minimum-order-values';
import { addressesFetcher } from 'redux/fetchers/address';
import loggedInCustomerDataFetcher from 'redux/fetchers/logged-in-customer-data';

export const createFetcherChain = ({
  fetchersAfterLoggedInCustomerData,
  fetchersInParallel,
}: {
  fetchersAfterLoggedInCustomerData?: unknown;
  fetchersInParallel?: unknown;
}) =>
  all(
    ...[
      cmsExperienceFragment(SITE_WIDE_BANNER.key),
      chain(
        loggedInCustomerDataFetcher,
        // minimumOrderValuesFetcher after loggedInCustomerDataFetcher as it does nothing if the get trolley request returns i.e. when logged in
        fetchersAfterLoggedInCustomerData
          ? all(fetchersAfterLoggedInCustomerData, minimumOrderValuesFetcher)
          : minimumOrderValuesFetcher,
      ),
      addressesFetcher,
      // spreading an array of one item like this is a method to only append to the parent array if it's truthy
      ...[fetchersInParallel],
    ],
  );
