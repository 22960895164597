import { dayjs } from 'utils/date';

export const seasonalCutOffDateTime = 'midnight 15 December';

export const isSeasonalSlotDate = (date: dayjs.ConfigType) => {
  const currentYear = new Date().getUTCFullYear();
  return dayjs(date)
    .tz()
    .isBetween(
      dayjs(`${currentYear}-12-20T00:00:00Z`).tz(),
      dayjs(`${currentYear}-12-24T23:59:59.999Z`).tz(),
      undefined,
      '[]',
    );
};
