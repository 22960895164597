import {
  DATE_PICKER_OPENED,
  DATE_PICKER_SELECTED_DATE,
  DATE_PICKER_NEXT_MONTH,
  DATE_PICKER_PREV_MONTH,
} from 'redux/modules/slot-booking/actions/types';

import {
  datePickerOpened,
  datePickerSelectedDate,
  datePickerNextMonth,
  datePickerPrevMonth,
} from './datePickerActions';

export default {
  [DATE_PICKER_OPENED]: datePickerOpened,
  [DATE_PICKER_SELECTED_DATE]: datePickerSelectedDate,
  [DATE_PICKER_NEXT_MONTH]: datePickerNextMonth,
  [DATE_PICKER_PREV_MONTH]: datePickerPrevMonth,
};
