import { SlotTypes } from 'constants/fulfilmentTypes';

const reducer = (state, action) => ({
  ...state,
  loading: false,
  addressId: null,
  addressName: '',
  branchId: null,
  slotDate: '',
  slotEndTime: '',
  slotExpiryTime: '',
  slotStartTime: '',
  slotType: SlotTypes.UNDEFINED,
  greenSlot: false,
  orderCutOff: '',
  amendOrderCutOff: '',
  error: action.error,
});

export default reducer;
