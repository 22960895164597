export const INVALID_SLOT_CAUSE = Symbol('INVALID_SLOT_CAUSE');
export const ENDING_SOON_CAUSE = Symbol('ENDING_SOON_CAUSE');
export const ENDING_TODAY_CAUSE = Symbol('ENDING_TODAY_CAUSE');
export const INVALID_BRANCH_CAUSE = Symbol('INVALID_BRANCH_CAUSE');

export const INVALID_SLOT_TITLE = 'This offer is not available with your slot';
export const ENDING_SOON_TITLE = 'This offer must end soon';
export const ENDING_TODAY_TITLE = 'This offer ends today';

export const INVALID_SLOT_TITLE_TACTICAL = (justDeal: boolean) =>
  justDeal
    ? 'Deal unavailable with your chosen slot'
    : 'Meal deal unavailable with your chosen slot';
export const ENDING_SOON_TITLE_TACTICAL = (justDeal: boolean) =>
  justDeal ? 'This deal ends soon' : 'This meal deal ends soon';
