import { getTaxonomy } from 'redux/modules/taxonomy/actions';
import { getIsTaxonomyLoaded } from 'redux/modules/taxonomy/selectors';
import cmsExperienceFragment from 'redux/fetchers/experience-fragment';
import { MIDDLE_MEGA_MENU, SEASONAL_MENU } from 'constants/experience-fragments';
import { Fetcher } from 'route-data/fetcher-types';

export const menuFetcher: Fetcher = args => (dispatch, getState) => {
  const fetchers = [
    dispatch(
      cmsExperienceFragment(MIDDLE_MEGA_MENU.key, { numberOfRetries: 2, cache: false })(args),
    ),
    dispatch(cmsExperienceFragment(SEASONAL_MENU.key, { numberOfRetries: 2, cache: false })(args)),
  ];

  const isTaxonomyLoaded = getIsTaxonomyLoaded(getState());

  if (!isTaxonomyLoaded) {
    fetchers.push(dispatch(getTaxonomy()));
  }

  return Promise.all(fetchers);
};
