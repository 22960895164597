import createReducer from 'redux/createReducer';

import { initialState } from 'redux/modules/mywaitrose/state';
import linkMyWaitroseCard from './link-mywaitrose-card';
import getMyWaitroseByCard from './get-mywaitrose-by-card-id';
import joinMyWaitrose from './join-mywaitrose';
import leaveMyWaitrose from './leave-mywaitrose';
import resetMyWaitroseValidation from './reset-mywaitrose-request-error';

const actionTypeReducerMap = [
  ...linkMyWaitroseCard(),
  ...getMyWaitroseByCard(),
  ...joinMyWaitrose(),
  ...leaveMyWaitrose(),
  ...resetMyWaitroseValidation(),
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
