import { LockClosed, Phone } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Logo } from '@johnlewispartnership/wtr-ingredients/ingredients/Logo';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import urls from 'constants/urls';
import React, { memo } from 'react';
import { isEmbeddedInWebView } from 'utils/webview';
import styles from './styles.scss';

export default memo(function CheckoutHeader() {
  if (isEmbeddedInWebView()) {
    return null;
  }

  const linkId = __SERVER__ ? 'ssr-checkout-header-logo-link' : 'checkout-header-logo-link';

  return (
    <>
      <header className={styles.header} data-testid="checkout-header">
        <div className={styles.logoWrapper}>
          <TextLink id={linkId} href={urls.groceriesHome} data-testid="link-groceries">
            <Logo className={styles.logo} width={392} />
          </TextLink>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.secureWrapper}>
            <LockClosed />
            <Typography styleAs="paragraphSmall" className={styles.secure}>
              Secure Checkout
            </Typography>
          </div>
          <div className={styles.contactWrapper}>
            <Phone />
            <a href="tel:0800 188 884" type="tel" className={styles.contact} data-testid="tel">
              <span className="sr-only">Need help? Telephone 0 800 1 8 8 8 8 4</span>
              <Typography element="span" aria-hidden="true" styleAs="paragraphHeading">
                0800 188 884
              </Typography>
            </a>
          </div>
        </div>
      </header>
    </>
  );
});
