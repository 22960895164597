import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const PUT_MYWAITROSE_CARD = apiPromiseTypes('mywaitrosebycard', 'put');
export const SET_MY_WAITROSE_ADDRESS = 'waitrose/mywaitrose/SET_MY_WAITROSE_ADDRESS';
export const GET_MYWAITROSE_CARD_BY_ID = apiPromiseTypes('mywaitrosebycard', 'get');
export const DELETE_MYWAITROSE_CARD = apiPromiseTypes('mywaitrosebycard', 'delete');
export const POST_MYWAITROSE_CARD = apiPromiseTypes('mywaitrose', 'post');
export const RESET_MY_WAITROSE_REQUEST_ERROR =
  'waitrose/mywaitrose/RESET_MY_WAITROSE_REQUEST_ERROR';
export const APPLE_WALLET_BUTTON_CLICKED = 'waitrose/mywaitrose/APPLE_WALLET_BUTTON_CLICKED';
export const APPLE_APP_STORE_CLICKED = 'waitrose/mywaitrose/APPLE_APP_STORE_CLICKED';
export const ANDROID_APP_STORE_CLICKED = 'waitrose/mywaitrose/ANDROID_APP_STORE_CLICKED';
