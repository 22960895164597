import { SlotDate } from 'api/definitions/slotdates/types';

const reducer = (state: WtrState, action: { payload: { campaignDates: SlotDate[] } }) => ({
  ...state,
  loading: false,
  dates: action.payload.campaignDates,
  hasCampaignDatesOnly: true,
});

export default reducer;
