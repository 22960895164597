import { createSelector } from 'reselect';
import { slotStatuses, SlotTypes } from 'constants/fulfilmentTypes';
import { isSeasonalSlotDate } from 'utils/checkout/is-seasonal-slot-date';
import { WtrRootState } from 'redux/create-store';

const getOrder = ({ order }: Partial<WtrRootState> = {}) => order;

const getOrderSlot = createSelector(getOrder, order => {
  if (!order || !order.slots || order.slots.length === 0) {
    return undefined;
  }

  // When an order is in amending state and a new slot is booked, that would return two slots:
  // One with status 'UNCONFIRMED' that would be the just chosen slot
  // Another one 'CONFIRMED' is the previously booked slot, the last time that order was placed or amended
  if (order.slots.length > 1) {
    return order.slots.find(({ status }) => status === slotStatuses.UNCONFIRMED);
  }

  return order.slots[0];
});

export const getAmendOrderCutOffDateTime = createSelector(
  getOrderSlot,
  slot => slot?.amendOrderCutoffDateTime,
);

export const getSlotBranchAddress = createSelector(getOrderSlot, slot => slot?.branchAddress);

export const getSlotBranchId = createSelector(getOrderSlot, slot => slot?.branchId);

export const getSlotDeliveryAddress = createSelector(getOrderSlot, slot => slot?.deliveryAddress);

export const getSlotEndDateTime = createSelector(getOrderSlot, slot => slot?.endDateTime);

export const getSlotStartDateTime = createSelector(getOrderSlot, slot => slot?.startDateTime);

export const getSlotType = createSelector(getOrderSlot, slot => slot?.type);

export const isBookedSlotACollectionSlot = createSelector(
  getSlotType,
  slotType =>
    slotType === SlotTypes.ENTERTAINING_COLLECTION || slotType === SlotTypes.GROCERY_COLLECTION,
);

export const isBookedSlotAnEntertainingCollectionSlot = createSelector(
  getSlotType,
  slotType => slotType === SlotTypes.ENTERTAINING_COLLECTION,
);

export const isBookedSlotGroceryCollectionSlot = createSelector(
  getSlotType,
  slotType => slotType === SlotTypes.GROCERY_COLLECTION,
);

export const getIsSeasonalSlot = createSelector(getSlotStartDateTime, startDateTime => {
  const result = isSeasonalSlotDate(startDateTime);

  return result;
});
