import { getCookie } from 'utils/cookie';

import {
  getAccessToken,
  getCustomerId,
  getCustomerOrderId,
} from 'redux/modules/sessions/selectors';
import { getRequestCookies } from 'redux/modules/page/selectors';

export const connectApiToState = (args = {}, state = {}) => {
  const {
    authCookie = getCookie('AuthCookie'),
    customerId = getCustomerId(state),
    orderId = getCustomerOrderId(state),
  } = args;

  return {
    ...args,
    authCookie,
    cookies: getRequestCookies(state),
    customerId,
    customerOrderId: orderId,
    orderId,
    jwt: getAccessToken(state),
  };
};
