import minimumOrderValues from 'api/definitions/minimumOrderValues';

import {
  GET_MINIMUM_ORDER_VALUES,
  GET_MINIMUM_ORDER_VALUES_SUCCESS,
  GET_MINIMUM_ORDER_VALUES_FAILED,
} from 'redux/modules/trolley/actions/types';

export const getMinimumOrderValues = () => (dispatch, getState) => {
  dispatch({ type: GET_MINIMUM_ORDER_VALUES });
  return minimumOrderValues
    .get()({ dispatch, getState })
    .then(response => {
      if (!response) {
        dispatch({
          error: 'Graph returned an error getting minimum order values',
          type: GET_MINIMUM_ORDER_VALUES_FAILED,
        });
      } else {
        const { data, errors } = response;
        const failures = data?.getMinimumOrderValues?.failures || [];
        if (errors || !data?.getMinimumOrderValues || failures.length > 0) {
          dispatch({
            error: 'Graph returned an error getting minimum order values',
            type: GET_MINIMUM_ORDER_VALUES_FAILED,
          });
        } else {
          dispatch({
            type: GET_MINIMUM_ORDER_VALUES_SUCCESS,
            result: data.getMinimumOrderValues,
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        error: 'Get Minimum Order values call to graph failed',
        type: GET_MINIMUM_ORDER_VALUES_FAILED,
      });
    });
};
