/*

SESSION level custom dimensions currently not currently available from APIs
---------------------------------------------------------------------------
* customer_shop_number
* customer_type
* days_since_last_shop
* lifetime_customer_value
* mywaitrose_customer
* shops_since_90_days

SESSION level custom dimensions currently not configured in Google Analytics
----------------------------------------------------------------------------
* last_branch
* mywaitrose_customer_number
* number_of_coupons_redeemed
* order_id
* postcode
* trolleyTotal

*/
import {
  CLEAR_SELECTED_BRANCH,
  SET_SELECTED_BRANCH,
} from 'redux/modules/slot-booking/actions/types';
import { CUSTOMER_SLOT_REQUEST_SUCCESS } from 'redux/modules/customer-slot/actions/types';

import addBranchId from './branch-id';

export default {
  [CUSTOMER_SLOT_REQUEST_SUCCESS]: addBranchId,
  [CLEAR_SELECTED_BRANCH]: addBranchId,
  [SET_SELECTED_BRANCH]: addBranchId,
};
