export const PRODUCT_NAME_CURRENTLY_UNAVAILABLE = 'Product name currently unavailable';
export const SUBS_NOT_ALLOWED_TOOLTIP =
  'We are unable to provide substitutions for selected items, including those from our Entertaining, pharmaceutical and fresh counter categories.';
export const SUBS_POLICY_TOOLTIP =
  "If an item you order goes out of stock, we'll replace it with the best alternative*";
export const SWAP_UNAVAILABLE_TOOLTIP = "Select 'Swap' to see alternatives";

export const TROLLEY_SORT_BY_DATE = 'Recently added';
export const TROLLEY_SORT_BY_CATEGORY = 'Categories';
export const TROLLEY_SORT_ALPHABETICALLY = 'Alphabetical';
