import urls from 'constants/urls';

const continueShoppingExcludedPaths = [
  urls.bookslot,
  urls.login,
  urls.orderConfirmation,
  urls.registration,
  urls.serviceSelection,
  urls.entertainingChristmas,
];

const isValidContinueShoppingPath = path =>
  !continueShoppingExcludedPaths.some(url => path.includes(url));

const reducer = (state, action) => {
  if (
    action?.payload?.location?.pathname &&
    isValidContinueShoppingPath(action.payload.location.pathname)
  ) {
    return {
      ...state,
      lastNonSlotBookingPath: `${action.payload.location.pathname}${
        action.payload.location.search || ''
      }`,
    };
  }

  return state;
};

export default reducer;
