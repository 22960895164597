import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Modal as IngredientsModal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { Provider as ReduxProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import root from 'window-or-global';
import { loadableReady } from '@loadable/component';

const reactRender = (element, history, store, container, callback) => {
  const isSsr = root.isSSR;

  const renderOrHydrate = isSsr ? ReactDOM.hydrate : ReactDOM.render;

  const renderFunction = () =>
    renderOrHydrate(
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>{element}</HistoryRouter>
      </ReduxProvider>,
      container,
      callback,
    );

  Modal.setAppElement(container);
  IngredientsModal.setAppElement(container);

  if (isSsr) {
    loadableReady(() => renderFunction());
  } else {
    renderFunction();
  }
};

export default reactRender;
