export const SAVE_EXPERIMENTS = 'waitrose/experiments/SAVE_EXPERIMENTS' as const;

export const RECORD_EXPERIMENT_ID_VIEWED =
  'waitrose/experiments/RECORD_EXPERIMENT_ID_VIEWED' as const;

interface MonetateCustomVariable {
  variable: string;
  value: string;
}

interface MonetateDecisionRequestEvent {
  eventType: 'monetate:decision:DecisionRequest';
  requestId: string;
  includeReporting: boolean;
}

interface MonetateUserAgentEvent {
  eventType: 'monetate:context:UserAgent';
  userAgent: string;
}

interface MonetateIpAddressEvent {
  eventType: 'monetate:context:IpAddress';
  ipAddress: string;
}

interface MonetatePageViewEvent {
  eventType: 'monetate:context:PageView';
  url: string;
  pageType: string;
}

interface MonetateCustomVariablesEvent {
  eventType: 'monetate:context:CustomVariables';
  customVariables: MonetateCustomVariable[];
}

type MonetateEvent =
  | MonetateDecisionRequestEvent
  | MonetateUserAgentEvent
  | MonetateIpAddressEvent
  | MonetatePageViewEvent
  | MonetateCustomVariablesEvent;

export interface MonetateApiRequestBody {
  preview?: string;
  monetateId?: string;
  customerId?: string;
  channel?: string;
  events: MonetateEvent[];
}

export interface MonetateImpressionReport extends Record<string, unknown> {
  experience_label: string;
  variant_label: string;
}

type MonetatePayload = Record<string, unknown>;

export interface MonetateApiResponseAction {
  actionType: string;
  isControl: boolean;
  impressionReporting: [MonetateImpressionReport];
  json?: {
    id: string;
    type: string;
    variant: string;
    payload: MonetatePayload;
  };
}

export interface MonetateApiResponse {
  meta?: { code: number; monetateId?: string };
  data?: { responses?: [{ requestId: string; actions: MonetateApiResponseAction[] }] };
}

export interface ParsedMonetateApiResponseActions {
  decisions?: Record<string, string>;
  impressions?: Record<string, MonetateImpressionReport>;
  payloads?: Record<string, MonetatePayload>;
}

export interface MonetateActions extends ParsedMonetateApiResponseActions {
  monetateId?: string;
}

export interface Experiments extends MonetateActions {
  bypassMonetate?: boolean;
  preview?: string;
  ipAddress?: string;
}

export interface SaveExperimentsAction extends Omit<Experiments, 'monetateId'> {
  type: typeof SAVE_EXPERIMENTS;
}
