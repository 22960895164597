import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// for more info see: docs/use-selector.md

export function useMakeSelector(factory, ...args) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(factory, []);
  return useSelector(state => selector(state, ...args));
}
