/* eslint-disable @typescript-eslint/no-restricted-imports */
import React, { useRef, MutableRefObject } from 'react';
import { AccordionItem } from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import { useSelector } from 'react-redux';
import { RecipesRoot } from 'redux/modules/recipes/reducers';
import AccordionRecipeTitle from './AccordionRecipeTitle';
import styles from './index.scss';
import RecipesProductGrid from '../../Content/Shoppable/ProductGrid';
import type { RecipeSummaryItem } from '../../../../api/definitions/recipes';
import { getNumberOfShoppableProducts } from '../../../../redux/modules/recipes/selectors';
import { RecipeIdList } from '.';

export type ToggleIngredientsRefType = MutableRefObject<Record<string, (checked: boolean) => void>>;

export interface RecipeAccordionItemProps {
  recipe: RecipeSummaryItem;
  selectedRecipesIds: RecipeIdList;
  setSelectedRecipesIds: (
    selectedRecipesIds: RecipeIdList | ((prevSelectedRecipesIds: RecipeIdList) => RecipeIdList),
  ) => void;
}

const RecipeAccordionItem: React.FC<RecipeAccordionItemProps> = ({
  recipe,
  selectedRecipesIds,
  setSelectedRecipesIds,
}) => {
  const numberOfShoppableProducts = useSelector((state: RecipesRoot) =>
    getNumberOfShoppableProducts(state, recipe.id),
  );
  const toggleIngredientsRef = useRef<Record<string, (checked: boolean) => void>>({});

  const toggleAllIngredients = (checked: boolean) => {
    Object.values(toggleIngredientsRef.current).forEach(toggle => {
      if (toggle) {
        toggle(checked);
      }
    });
  };

  if (numberOfShoppableProducts === 0) return null;
  return (
    <AccordionItem
      title={
        <AccordionRecipeTitle
          recipeId={recipe.id}
          title={recipe.title}
          checked={selectedRecipesIds.includes(recipe.id)}
          setSelectedRecipesIds={setSelectedRecipesIds}
          toggleIngredients={toggleAllIngredients}
        />
      }
      id={recipe.id}
      defaultExpanded={false}
      contentClassName={styles.recipesAccordionItem}
      data-testid={`recipe-accordion-item-${recipe.id}`}
      headingElement="div"
    >
      <RecipesProductGrid
        recipeId={recipe.id}
        showSwapProduct={() => {}}
        setCurrentIngredientIndex={() => {}}
        currentSwappedProduct={null}
        toggleIngredientsRef={toggleIngredientsRef}
        forcedHideSwapLink
      />
    </AccordionItem>
  );
};

export default RecipeAccordionItem;
