import { dataLayer } from 'analytics/data-layer';
import { isUserLoggedIn, getCustomerId } from 'redux/modules/sessions/selectors';
import { isMyWaitroseMember } from 'redux/modules/marketing-preferences/selectors/get-membership-status';
import { generateCustomerEventForGTMDataLayer } from 'utils/gtm';
import { getCustomerHasACompletedOrder, getDaysSinceLastCompletedOrder } from '../orders/selectors';

export const generateCustomerDataLayerEvent = (state, eventName) => {
  const membershipStatus = isMyWaitroseMember(state) ? 'Y' : 'N';
  const loggedInStatus = isUserLoggedIn(state);
  const customerId = getCustomerId(state);
  const customerHasACompletedOrder = getCustomerHasACompletedOrder(state);
  const daysSinceLastCompletedOrder = getDaysSinceLastCompletedOrder(state);

  return generateCustomerEventForGTMDataLayer(
    customerId,
    loggedInStatus,
    membershipStatus,
    customerHasACompletedOrder,
    daysSinceLastCompletedOrder,
    eventName,
  );
};

export const callCustomerDataLayerEvent = (...args) => {
  const customerDataLayerEvent = generateCustomerDataLayerEvent(...args);
  dataLayer.push(customerDataLayerEvent);
};
