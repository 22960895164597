import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { CircleAdd } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { Alert } from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { Recipe, RecipeSummaryItem } from 'api/definitions/recipes/index.d';
import { Filter } from 'services/recipes-landing-service';
import { RecipeCard } from '../Content/RecipeCard';
import PreferencesModal from './Preferences';
import styles from './RecipeSelection.scss';
import { getFiltersFromSearch } from './Filters';

type MealPlanRecipesProps = {
  recipes: RecipeSummaryItem[];
  filters: Filter[];
  loading: boolean;
  moreRecipesAvailable: boolean;
  onFilterChange: (search: URLSearchParams) => void;
  onSelect: (recipe: Recipe['id']) => void;
  onView: (recipe: Recipe['id']) => void;
  loadMore: () => void;
};

export const RecipeSelection = ({
  recipes,
  filters,
  loading,
  moreRecipesAvailable,
  onFilterChange,
  onSelect,
  onView,
  loadMore,
}: MealPlanRecipesProps) => {
  const location = useLocation();

  const [showPrefs, setShowPrefs] = useState<boolean>(false);

  const activeFilters = getFiltersFromSearch(new URLSearchParams(location.search));
  const count = activeFilters.reduce<number>((acc, f) => acc + f.values.length, 0);

  return (
    <>
      <div className={styles.header}>
        <button type="button" className={styles['prefs-button']} onClick={() => setShowPrefs(true)}>
          Edit preferences{count ? <span className={styles.badge}>{count}</span> : ''}
        </button>
      </div>
      <section className={styles.section} aria-label="Available recipes">
        <h3 className="sr-only">Recipes</h3>
        <div className={styles.scrollable}>
          {!loading && !recipes.length && (
            <Alert
              type="warning"
              title="No matches found"
              message="Your search provided no results. Try editing your preferences for more results."
            />
          )}
          <ul className={styles.list}>
            {recipes.map(r => (
              <RecipeCard
                key={r.id}
                recipe={r}
                headingLevel="h4"
                onClick={e => {
                  onView(r.id);
                  e.preventDefault();
                }}
                analytics={{}}
                className={styles.card}
              >
                <Button
                  theme="primary"
                  width="full"
                  className={styles['add-recipe']}
                  onClick={() => onSelect(r.id)}
                  startIcon={<CircleAdd />}
                >
                  Add recipe
                </Button>
              </RecipeCard>
            ))}
          </ul>
          {moreRecipesAvailable && (
            <Button theme="secondary" className={styles['load-more']} onClick={loadMore}>
              Load more
            </Button>
          )}
        </div>
      </section>
      <PreferencesModal
        isOpen={showPrefs}
        onClose={() => {
          setShowPrefs(false);
        }}
        filters={filters}
        onFilterChange={updatedSearch => {
          onFilterChange(updatedSearch);
        }}
      />
    </>
  );
};
