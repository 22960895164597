import { createSelector } from 'reselect';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { hasCustomerOrders } from 'redux/modules/entities/selectors/customer-orders';
import { isAmendable } from 'redux/modules/orders/selectors/get-order';

const getAmendOrder = ({ amendOrder } = {}) => amendOrder;

export const getError = createSelector(getAmendOrder, ({ error } = {}) => error);

const getPending = createSelector(getAmendOrder, ({ pending } = {}) => pending);

const getInProgress = createSelector(getAmendOrder, ({ inProgress } = {}) => inProgress);

export const currentlyAmendingOrder = createSelector(
  [getPending, getInProgress],
  (pending, inProgress) => pending || inProgress || false,
);

export const unableToAmendOrder = createSelector(
  [getError, amendingOrder, hasCustomerOrders, isAmendable],
  (
    error,
    amending,
    customerOrdersExist, // a stop-gap until the PendingOrder component is converted to using the order-orchestration API
    amendable,
  ) => error || amending || (customerOrdersExist && !amendable) || false,
);
