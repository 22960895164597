import { createSelector } from 'reselect';

export const getCommonModal = ({ modal } = {}) => modal;

export const getCommonModalConflictState = createSelector(
  getCommonModal,
  ({ conflict } = {}) => !!conflict,
);

export const getCommonModalMessageText = createSelector(
  getCommonModal,
  ({ messageText = [] } = {}) => messageText,
);

export const getIsAnyCommonModalOpen = createSelector(
  getCommonModal,
  ({ isAnyModalOpen = false } = {}) => isAnyModalOpen,
);
