import { PUT_BY_ID } from 'redux/modules/address/actions/types';
import { sortAddresses } from 'redux/modules/address/util';

const editAddressByIdRequest = state => ({
  ...state,
  addAddressLoading: true,
  editAddressLoading: true,
  request: {
    loading: true,
    loaded: false,
    error: null,
  },
});

const editAddressByIdSuccess = (state, action) => {
  // the edited address returns with a new id, thus we need to compare the id of the previous address and replace that one with the new address
  const prevAddress = state.selectedAddress;
  const address = {
    ...action.result,
    ...(prevAddress.isContactAddress && { isContactAddress: prevAddress.isContactAddress }),
    ...(prevAddress.myWaitrose && { myWaitrose: prevAddress.myWaitrose }),
  };

  const { addresses = [] } = state;
  const prevAddressIndex = addresses.findIndex(add => add.id === prevAddress.id);
  if (prevAddressIndex > -1) addresses[prevAddressIndex] = address;
  const sortedAddresses = sortAddresses(addresses, action.result.id);

  return {
    ...state,
    addressInError: null,
    addresses: sortedAddresses,
    addAddressLoading: false,
    editAddressLoading: false,
    request: {
      loading: false,
      loaded: true,
      error: null,
    },
  };
};

const editAddressByIdFailure = (state, action) => ({
  ...state,
  addAddressLoading: false,
  editAddressLoading: false,
  request: {
    loading: false,
    loaded: false,
    error: action.error,
  },
});

export default map => {
  map.set(PUT_BY_ID.request, editAddressByIdRequest);
  map.set(PUT_BY_ID.success, editAddressByIdSuccess);
  map.set(PUT_BY_ID.failure, editAddressByIdFailure);
  return map;
};
