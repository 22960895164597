export const KGM = 'KGM';
export const GRM = 'GRM';
export const LBR = 'LBR';
export const C62 = 'C62';

export type WeightUnits = typeof KGM | typeof GRM | typeof LBR | typeof C62;

export const weightOptions = {
  [KGM]: {
    unit: KGM,
    label: 'kg',
    ariaLabel: 'kilograms',
    default: 0.5,
    increment: 0.5,
    minimum: 0.1,
    maximum: 99.9,
    allowFractional: true,
  },
  [GRM]: {
    unit: GRM,
    label: 'g',
    ariaLabel: 'grams',
    default: 100,
    increment: 100,
    minimum: 100,
    maximum: 999,
    allowFractional: false,
  },
  [LBR]: {
    unit: LBR,
    label: 'lb',
    ariaLabel: 'pounds',
    default: 1,
    increment: 1,
    minimum: 1,
    maximum: 99.99,
    allowFractional: true,
  },
  [C62]: {
    unit: C62,
    label: '',
    ariaLabel: 'number of items',
    default: 1,
    increment: 1,
    minimum: 1,
    maximum: 99,
    allowFractional: false,
  },
};
