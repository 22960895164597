import { apiCallback } from 'api';

const definition = {
  service: 'address',
  endpoint: 'addresses/${addressId}/addressee',
  verbs: ['put'],
  defaultVersion: 2,
};

export default {
  put: apiCallback(definition, 'put'),
};
