import {
  SHOW_SNACKBAR,
  VIEWED_SNACKBAR,
  RESET_SNACKBAR,
  REMOVE_VIEWED_IDS,
} from 'redux/modules/common-snackbar/actions/types';

import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  queuedSnackbars: [],
  viewedSnackbars: [],
});

export default function SnackbarReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const newSnackbar = action.snackbar;

      const updatedQueue = newSnackbar.relationId
        ? state.queuedSnackbars.filter(snackbar => snackbar?.relationId !== newSnackbar.relationId)
        : state.queuedSnackbars;

      return {
        ...state,
        queuedSnackbars: [...updatedQueue, action.snackbar],
      };
    }
    case VIEWED_SNACKBAR:
      return {
        ...state,
        queuedSnackbars: state.queuedSnackbars.slice(1),
        viewedSnackbars: state.viewedSnackbars.includes(action.id)
          ? state.viewedSnackbars
          : [...state.viewedSnackbars, action.id],
      };
    case REMOVE_VIEWED_IDS:
      return {
        ...state,
        viewedSnackbars: state.viewedSnackbars.filter(id => !action.removeIds.includes(id)),
      };
    case RESET_SNACKBAR:
      return initialState;
    default:
      return state;
  }
}
