import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';
import { ValueGetter, waitForValue } from 'utils/wait-for-value';
import root from 'window-or-global';

type RootType = typeof root;

interface UseGlobalVariableEffectOptions {
  /** The interval of when the value should be checked */
  checkInterval?: number;
}

/**
 * Waits for a (global) value to be defined before invoking the specified function. The function will then behave as if it was called on useEffect
 * @param variableGetter The function to get the variable, or the name of the value in the `window-or-global` object
 * @param onAvailable The function to execute when the value is available
 * @param deps The list of dependencies that is then forwarded to `useEffect`
 * @param options Additional options
 */
export function useGlobalVariableEffect<T, TEffectResult extends () => void | undefined>(
  variableGetter: ValueGetter<T, RootType>,
  onAvailable: (value: T) => TEffectResult,
  deps: DependencyList,
  { checkInterval = 500 }: UseGlobalVariableEffectOptions = {},
) {
  const [valueCache, setValueCache] = useState<T>();

  const variableGetterRef = useRef(variableGetter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAvailableCache = useCallback(onAvailable, deps);

  useEffect(() => {
    if (valueCache) {
      return onAvailableCache(valueCache);
    }

    const controller = new AbortController();

    waitForValue(variableGetterRef.current, root, {
      abortSignal: controller.signal,
      checkInterval,
    })
      .then(value => {
        setValueCache(value);
      })
      .catch(err => {
        // if the action was not aborted, we rethrow the error
        if (!controller.signal.aborted) {
          throw err;
        }
      });

    return () => controller.abort();
  }, [checkInterval, valueCache, onAvailableCache]);
}
