import { removeDiacriticsFromLetters } from 'utils/format';

const clean = (text = '') =>
  text
    .replace(/\n|_/g, ',')
    .split(',')
    .map(item => removeDiacriticsFromLetters(item.trim()))
    .filter(item => item && item.length > 0);

export default clean;
