import { showSnackbar, clearViewedIds } from 'redux/modules/common-snackbar/actions';

import { checkoutDisabled, checkoutDestination } from 'redux/modules/checkout/selectors/checkout';

import {
  SLOT_EXPIRY_FINAL,
  SLOT_EXPIRY_INITIAL,
} from 'redux/modules/customer-slot/actions/slot-expiry-snackbar-constants';
import { getFormattedTimeRemaining } from 'redux/modules/customer-slot/selectors/get-slot-expiry-time';

export const showSlotSnackbar = type => async (dispatch, getState) => {
  const state = getState();
  const snackbar = {
    open: true,
    id: type,
    message: `Checkout within ${getFormattedTimeRemaining(state)} to keep this slot`,
    type: type === SLOT_EXPIRY_FINAL ? 'warning' : 'info',
    icon: type === SLOT_EXPIRY_FINAL ? undefined : 'Clock',
    action: checkoutDisabled(state)
      ? null
      : {
          label: 'Checkout',
          href: checkoutDestination(state),
        },
  };

  dispatch(showSnackbar(snackbar));
};

export const resetSlotSnackbar = () => dispatch => {
  dispatch(clearViewedIds([SLOT_EXPIRY_FINAL, SLOT_EXPIRY_INITIAL]));
};
