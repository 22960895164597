import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import root from 'window-or-global';

import Modal from 'components/Modal';
import { ModalCloseOptions } from 'constants/modalCloseOptions';

import styles from './SubText.scss';

class SubText extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { showExplanation: false };
  }

  onShowErrorExplanation = event => {
    event.target.blur();
    this.x = root.pageXOffset;
    this.y = root.pageYOffset;
    this.setState({ showExplanation: true });
  };

  onCloseErrorExplanation = () => {
    this.setState({ showExplanation: false });

    // The modal scrolls to the top of the page
    // and only returns to a focused element, but
    // we want to loose the focus, so we scroll back
    // to the position the user was at when they
    // wants to show the modal. Uses a timer
    // to run after any renders and animations.
    root.setTimeout(() => {
      root.scrollTo(this.x, this.y);
    }, 0);
  };

  renderExplanationModal = () => {
    const { invalidText } = this.props;
    const { showExplanation } = this.state;
    const { explanation: invalidExplanation } = invalidText || {};
    if (!invalidExplanation || !showExplanation) {
      return null;
    }

    const { body, title } = invalidExplanation;

    return (
      <Modal
        buttons={[
          {
            buttonText: 'OK',
            buttonValue: ModalCloseOptions.OK,
            secondary: true,
          },
        ]}
        isOpen
        titleText={title}
        closeModal={this.onCloseErrorExplanation}
        shouldReturnFocusAfterClose={false}
      >
        {body}
      </Modal>
    );
  };

  render() {
    const { hasFocus, helpText, id, maxlength, showCharacterCount, value, invalidText } =
      this.props;
    const { message, explanation: invalidExplanation } = invalidText || {};
    const errorText = message || invalidText;

    const cx = classnames.bind(styles);

    const valueLength = value ? value.toString().length : 0;
    const characterCountClasses = cx('characterCount', {
      nearMax: valueLength >= maxlength * 0.75 && hasFocus,
      reachedMax: valueLength === maxlength,
    });

    const explanationModal = this.renderExplanationModal();

    return (
      <Fragment>
        <div
          className={styles.subText}
          id={id}
          aria-live="assertive"
          data-cs-mask
          data-testid="subtext-wrapper"
        >
          {errorText && <span className={styles.errorMsg}>{errorText}</span>}
          {!invalidText && helpText && <span className={styles.helpText}>{helpText}</span>}
          {invalidText && helpText && <span className={styles.helpText}>{helpText}</span>}
          {invalidExplanation && (
            <button
              className={styles.whyButton}
              onClick={this.onShowErrorExplanation}
              type="button"
            >
              Why?
            </button>
          )}
          {!invalidExplanation && maxlength && showCharacterCount && (
            <div className={characterCountClasses}>
              {valueLength}/{maxlength}
            </div>
          )}
        </div>

        {explanationModal}
      </Fragment>
    );
  }
}

export default SubText;

SubText.propTypes = {
  hasFocus: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string.isRequired,
  invalidText: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
      explanation: PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
      }),
    }),
    PropTypes.string,
  ]),
  maxlength: PropTypes.number,
  showCharacterCount: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SubText.defaultProps = {
  hasFocus: false,
  helpText: null,
  invalidText: null,
  maxlength: null,
  showCharacterCount: false,
  value: '',
};
