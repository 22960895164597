import { createSelector } from 'reselect';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { FavouritesExperienceSuccessState, FavouritesExperienceState } from '../types';

export const isFavouritesSuccessfullyLoaded = (
  state: FavouritesExperienceState,
): state is FavouritesExperienceSuccessState => 'categories' in state;

export const getFavourites = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'favouritesExperience');

export const getIsFavouritesSuccessfullyLoaded = createSelector(
  [getFavourites],
  isFavouritesSuccessfullyLoaded,
);
