import get from 'lodash/get';
import omit from 'lodash/omit';
import { initialState } from 'redux/modules/sessions/reducer';

export const pending = state => ({
  ...state,
  loading: true,
  error: null,
});

export const fulfilled = (state, action) => {
  const response = get(action, 'result.data.generateSession') || {};
  let error = get(response, 'failures[0].type');

  // Map INVALID_DETAILS error to INVALID_CREDENTIALS
  // Can be removed once we've switched to the OAuth2 flow
  if (error === 'INVALID_DETAILS') {
    error = 'INVALID_CREDENTIALS';
  }

  if (action?.result?.errors) {
    error = 'UNKNOWN';
  }

  if (error) {
    return {
      ...state,
      loading: false,
      error,
    };
  }

  return {
    ...state,
    ...omit(response, 'failures'),
    accessToken: `Bearer ${response.accessToken || 'unauthenticated'}`,
    loading: false,
    error: null,
  };
};

export const rejected = (state, action) => {
  const statusCode = get(action, 'error.response.statusCode');
  const error = statusCode === 401 ? 'INVALID_CREDENTIALS' : null;

  return {
    ...state,
    ...initialState,
    error,
    loading: false,
  };
};

export const tokenPending = state => ({
  ...state,
  loading: true,
  error: null,
});

export const tokenFulfilled = (state, action) => {
  const { result } = action;

  return {
    ...state,
    ...result,
    accessToken: `Bearer ${result.accessToken}`,
    expiresIn: result.expires_in,
    loading: false,
    error: null,
  };
};

// WIP: Ticket in backlog to add error handling
export const tokenRejected = (state, action) => {
  const { error } = action;

  return {
    ...state,
    error,
    loading: false,
  };
};

export const clearError = state => ({
  ...state,
  error: null,
});

export const throttleLogins = state => ({
  ...state,
  error: 'THROTTLE_LOGIN_ERROR',
});

export const resetSessionState = state => ({
  ...state,
  ...initialState,
});

export const saveShoppingContext = (state, action) => ({
  ...state,
  ...action?.result?.data?.shoppingContext,
  loading: false,
  error: null,
});
