import { amendOrderErrorModal } from 'constants/modals';
import { log } from 'utils/error-logging';

import { openModal } from 'redux/modules/common-modal/actions';
import { getFeatureFlags } from 'utils/feature-flags';

export const showAmendOrderErrorModal = error => dispatch => {
  const { modalErrorLogging } = getFeatureFlags();

  if (error && modalErrorLogging) {
    log(error, { logger: 'amend-order-error' });
  }

  dispatch(openModal(amendOrderErrorModal));
};
