import { hasBrokenTrolleyToRestore } from 'redux/modules/trolley/selectors/has-broken-trolley';
import { restoreBrokenTrolley } from 'redux/modules/trolley/actions/restore-broken-trolley';

const signOnAsCustomerActions = (dispatch, state) => {
  if (hasBrokenTrolleyToRestore(state)) {
    dispatch(restoreBrokenTrolley());
  }

  return true;
};

export default signOnAsCustomerActions;
