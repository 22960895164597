import { apiCallback } from 'api';

const definition = {
  service: 'pricing2',
  endpoint: 'priced-products',
  verbs: ['post'],
  defaultVersion: 2,
};

export default {
  post: apiCallback(definition, 'post'),
};
