import urls from 'constants/urls';
import { setModalRoute } from 'components/Address/util';
import { onShowEditModal } from 'redux/modules/address/actions/affect-address-modal';
import {
  addressApiError,
  canActionOnAddress,
  hideEditDeleteSpinner,
  onDeleteAddress,
  setSelectedAddress,
} from 'redux/modules/address/actions';
import { getCanEditOrDeleteAddressOption } from 'redux/modules/address/selectors';

import { openModal } from 'redux/modules/common-modal/actions';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import {
  cannotEditAddressModal,
  cannotDeleteAddressModal,
  deleteAddressModal,
  deleteContactAddressModalError,
  deleteLastAddressModalError,
} from 'constants/modals';
import { resetUpdateContactAddressError } from './reset-update-contact-address-error';

const showEditModal = address => async (dispatch, getState) => {
  await dispatch(canActionOnAddress(address));
  const state = getState();
  const canEdit = getCanEditOrDeleteAddressOption(state);

  if (canEdit) {
    await dispatch(resetUpdateContactAddressError());
    await dispatch(setSelectedAddress(address));
    await dispatch(onShowEditModal(address));
    await dispatch(hideEditDeleteSpinner());
  } else {
    await dispatch(openModal(cannotEditAddressModal));
    await dispatch(hideEditDeleteSpinner());
  }

  return canEdit && setModalRoute(urls.addressModals.edit);
};

export const openDeleteModal = address => dispatch =>
  dispatch(
    openModal({
      ...deleteAddressModal,
      onCloseCallback: option => {
        if (option === ModalCloseOptions.DELETE) {
          dispatch(onDeleteAddress(address)).catch(error => {
            if (error && error.status) {
              if (error.status === 423) {
                const reasonCode = error.response?.body?.reason_code;
                if (reasonCode === 'CONTACT_ADDRESS') {
                  return dispatch(openModal(deleteContactAddressModalError));
                }
                if (reasonCode === 'FINAL_ADDRESS') {
                  return dispatch(openModal(deleteLastAddressModalError));
                }
                if (reasonCode === 'ACTIVE_TROLLEY_ORDER') {
                  return dispatch(openModal(cannotDeleteAddressModal));
                }
              }
              return dispatch(addressApiError(address));
            }
            throw error;
          });
        } else {
          dispatch(hideEditDeleteSpinner());
        }
      },
    }),
  );

const showDeleteModal = address => async (dispatch, getState) => {
  await dispatch(canActionOnAddress(address));
  const state = getState();
  const canDelete = getCanEditOrDeleteAddressOption(state);

  if (canDelete) {
    await dispatch(resetUpdateContactAddressError());
    await dispatch(setSelectedAddress(address));
    await openDeleteModal(address)(dispatch);
    await dispatch(hideEditDeleteSpinner());
  } else {
    await dispatch(openModal(cannotDeleteAddressModal));
    await dispatch(hideEditDeleteSpinner());
  }
};

export { showEditModal, showDeleteModal };
