import { waitFor } from './settimeout-wrapper';

/**
 * Calls the function and retries it in case of rejections.
 *
 * The following example will call fn, at most, 3 times. One immediately, one after 50ms and the last after 100ms.
 * @example
 * const result = await retry(fn, [50, 100]);
 *
 * @param fn The function to retry in case of error
 * @param delays The delays between attempts
 * @returns The result of the successful call or the latest error
 */
export function retry<T>(fn: () => Promise<T>, delays: readonly number[] = [0]): Promise<T> {
  return fn().catch(error => {
    if (delays.length === 0) {
      return Promise.reject(error);
    }

    const [delay, ...newDelays] = delays;
    return waitFor(delay).then(() => retry(fn, newDelays));
  });
}
