import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';
import {
  NO_DELIVERY_AND_COLLECTION,
  SAVE_USER_DETAILS,
  SAVE_USER_ACCOUNT_DETAILS,
  COLLECTION,
  DELIVERY,
} from '../actions/types';

export const initialState = deepFreeze({
  loading: false,
  error: null,
  id: null,
  created: null,
  isActive: false,
});

const saveUserDetails = (state, { payload }) => ({
  ...state,
  ...payload,
});

const actionTypeReducerMap = new Map([
  [SAVE_USER_DETAILS, saveUserDetails],
  [SAVE_USER_ACCOUNT_DETAILS, saveUserDetails],
  [NO_DELIVERY_AND_COLLECTION, saveUserDetails],
  [COLLECTION, saveUserDetails],
  [DELIVERY, saveUserDetails],
]);

export default createReducer(initialState, actionTypeReducerMap);
