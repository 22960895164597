import { CheckoutState } from '../state/initial-state';

interface Payload {
  TRASessionId: string;
}

const reducer: WtrReducer<CheckoutState, Payload> = (state, { payload }) => ({
  ...state,
  TRASessionId: payload.TRASessionId,
});

export default reducer;
