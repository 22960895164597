import myWaitroseByCard from 'api/definitions/mywaitrosebycard';

import { DELETE_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { removeMarketingPreferences } from 'redux/modules/marketing-preferences/actions/get-membership-status';

export const leaveMyWaitrose =
  (cardNumber: string): WtrThunkAction =>
  async dispatch => {
    await dispatch({
      types: DELETE_MYWAITROSE_CARD.triplet,
      apiCall: myWaitroseByCard.delete({ cardNumber }),
    });

    return dispatch(removeMarketingPreferences());
  };
