export const CUSTOMER_ADDRESS_REQUEST = 'waitrose/slotDays/CUSTOMER_ADDRESS_REQUEST' as const;
export const CUSTOMER_ADDRESS_REQUEST_SUCCESS =
  'waitrose/slotDays/CUSTOMER_ADDRESS_REQUEST_SUCCESS' as const;
export const CUSTOMER_ADDRESS_REQUEST_FAIL =
  'waitrose/slotDays/CUSTOMER_ADDRESS_REQUEST_FAIL' as const;

export const CUSTOMER_ADDRESS_REQUEST_ACTIONS = [
  CUSTOMER_ADDRESS_REQUEST,
  CUSTOMER_ADDRESS_REQUEST_SUCCESS,
  CUSTOMER_ADDRESS_REQUEST_FAIL,
] as const;
