import buildApiData from './build-data';
import buildApiHeaders from './build-headers';
import buildApiUrl from './build-url';

export const apiPropsBuilder = (api = {}, args = {}) => ({
  data: buildApiData(api, args),
  headers: buildApiHeaders(api, args),
  url: buildApiUrl(api, args),
  debug: !!api.debug,
  memoryCacheKey: api.memoryCacheKey,
  memoryCacheMaxAge: api.memoryCacheMaxAge,
});
