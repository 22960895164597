import { createSelector } from 'reselect';

import rootCategories, { ENTERTAINING, ENTERTAINING_NEW, GROCERIES } from 'constants/categoryIds';
import urls from 'constants/urls';

import {
  getAccountMenu,
  getCategoryById,
  getCategoryUrlSegmentById,
  getData,
  getSiteLinks,
} from 'redux/modules/taxonomy/selectors';
import { getCustomerId } from 'redux/modules/sessions/selectors';

import decorateSubcategoryMenu from './decorate-subcategory-menu';

const getMegaMenu = ({ megaMenu } = {}) => megaMenu;

export const getMegaMenuActiveItemId = createSelector(
  getMegaMenu,
  ({ activeItem } = {}) => activeItem,
);

export const getMegaMenuHistory = createSelector(getMegaMenu, ({ history } = {}) => history);

export const getMegaMenuRoot = createSelector(getMegaMenu, ({ menuRoot } = {}) => menuRoot);

const getUrlForActiveMegaMenuLevel = createSelector(
  [getData, getMegaMenu],
  (data, { activeItem, menuRoot } = {}) => {
    const taxonomy = { data };
    if (menuRoot === activeItem) {
      return getCategoryUrlSegmentById({ taxonomy }, activeItem);
    }

    return [
      getCategoryUrlSegmentById({ taxonomy }, menuRoot),
      getCategoryUrlSegmentById({ taxonomy }, activeItem),
    ].join('/');
  },
);

const getPathForMegaMenu = createSelector(
  [getData, getMegaMenu, getMegaMenuHistory],
  (data, megaMenu, history = []) => {
    const taxonomy = { data };
    return history.length === 0
      ? getUrlForActiveMegaMenuLevel({ taxonomy, megaMenu })
      : [
          getCategoryUrlSegmentById({ taxonomy }, history[0].menuRoot),
          ...history.map(({ activeItem } = {}) =>
            getCategoryUrlSegmentById({ taxonomy }, activeItem),
          ),
        ].join('/');
  },
);

const buildOffersPathFromHistory = createSelector(
  [getData, getMegaMenuHistory],
  (data, history = []) =>
    [urls.offers]
      .concat(
        history
          .filter(({ activeItem }) => !Object.values(rootCategories).includes(activeItem))
          .map(
            ({ activeItem }) =>
              `${getCategoryUrlSegmentById({ taxonomy: { data } }, activeItem)}_offers`,
          ),
      )
      .join('/'),
);

const getOffersPath = createSelector(
  [getData, getMegaMenuHistory, getMegaMenuRoot],
  (data, history, menuRoot) => ({
    pathname: buildOffersPathFromHistory({ taxonomy: { data }, megaMenu: { history } }),
    search: menuRoot === GROCERIES ? null : `?categoryId=${menuRoot}`,
  }),
);

const getMegaMenuActiveMenu = createSelector(
  [getData, getMegaMenuRoot, getMegaMenuHistory, getPathForMegaMenu, getOffersPath],
  (data, menuRoot, history = [], path, offersPath) => {
    const [{ activeItem: subCategoryRoot } = {}] = history;
    const hasOffersLink = history.length < 3;
    let activeMenu = getCategoryById({ taxonomy: { data } }, menuRoot);

    if (subCategoryRoot === GROCERIES) {
      activeMenu = decorateSubcategoryMenu(activeMenu, path, hasOffersLink && offersPath);
    }

    return activeMenu;
  },
);

export const getPreviousActiveTitle = createSelector(
  [getData, getMegaMenuHistory],
  (data, history = []) => {
    const { menuRoot: previousActiveItem } = history[history.length - 1] || {};
    const { name } = getCategoryById({ taxonomy: { data } }, previousActiveItem) || {};

    return name;
  },
);
const getTopLevelNavigationMenus = createSelector(
  [getMegaMenuActiveMenu, getAccountMenu, getSiteLinks],
  (taxonomyMenu, accountMenu, siteLinks) =>
    [taxonomyMenu, accountMenu, siteLinks].filter(menuItem => menuItem),
);

const getNavigationMenusForLevel = createSelector([getMegaMenuActiveMenu], taxonomyMenu => {
  const menus = [taxonomyMenu];

  return menus.filter(menuItem => menuItem);
});

export const getNavigationMenus = createSelector(
  [getCustomerId, getData, getMegaMenu, getMegaMenuHistory],
  (customerId, data, megaMenu, history = []) => {
    const level = history.length;
    const taxonomy = { data };

    if (level === 0) {
      return getTopLevelNavigationMenus({ taxonomy, megaMenu, sessions: { customerId } });
    }

    return getNavigationMenusForLevel({ taxonomy, megaMenu }, level);
  },
);

export const getPathForMegaMenuItemById = createSelector(
  [getData, getMegaMenu, (_, id) => id, getMegaMenuHistory, getPathForMegaMenu],
  (data, megaMenu, id, history = [], path) => {
    if ([ENTERTAINING, ENTERTAINING_NEW].includes(id)) return urls.entertainingHome;
    const taxonomy = { data };
    return history.length === 0
      ? getUrlForActiveMegaMenuLevel({ taxonomy, megaMenu })
      : [path, getCategoryUrlSegmentById({ taxonomy }, id)].join('/');
  },
);

export const getCurrentMenuList = createSelector(
  [getData, getNavigationMenus],
  (data, navigationMenus = []) =>
    []
      .concat(...navigationMenus.map(({ categoryIds = [] }) => categoryIds))
      .map(category => (typeof category === 'object' ? category.id : category))
      .filter(categoryId => categoryId)
      .filter(categoryId => {
        const { emptyOfProducts = false } = getCategoryById({ taxonomy: { data } }, categoryId);

        return !emptyOfProducts;
      }),
);

export const getActiveItemIndex = createSelector(
  [getMegaMenuActiveItemId, getCurrentMenuList],
  (activeItem, currentMenuList = []) =>
    activeItem && currentMenuList.length > 0 ? currentMenuList.indexOf(activeItem) : 0,
);

export const getExperienceFragmentKeyForActiveMenuItem = createSelector(
  [getMegaMenuActiveMenu],
  activeItem => activeItem?.banner,
);
