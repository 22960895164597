import update from 'immutability-helper';

const reducer = (state, { result = {} } = {}) => {
  const { id } = result;

  return {
    ...state,
    addresses: update(state.addresses, {
      $apply: (addresses = []) => {
        let newAddresses;

        if (addresses.some(({ id: addressId }) => addressId === id)) {
          newAddresses = addresses.map(address =>
            address.id === id ? { ...address, ...result } : address,
          );
        } else {
          newAddresses = [...addresses];
          newAddresses.push(result);
        }

        return newAddresses;
      },
    }),
  };
};

export default reducer;
