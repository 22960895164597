import { splitPath } from 'utils/get-page-type';
import history from 'utils/history';

const getBackgroundColour = ({ isNLP, page }) => {
  const isPDP = page === 'products';

  if (isPDP) {
    return undefined;
  }

  if (isNLP) {
    return 'green';
  }

  return undefined;
};

export const usePromotion = ({ currentPromotionPage = null, promotionTypeCode }) => {
  const segments = splitPath(history.getLocation().pathname);

  const isNLP = promotionTypeCode === 'NLP';

  const backgroundColor = getBackgroundColour({
    isNLP,
    page: segments[0],
  });

  const isLink = !currentPromotionPage;

  return {
    backgroundColor,
    isLink,
    withChevron: !isNLP,
  };
};
