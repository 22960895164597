import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import env from 'env/env';

import WaitroseLogo from 'components/WaitroseLogo';
import EventMonitor from 'components/EventMonitor/EventMonitor';

import styles from './FooterBadges.scss';

const mhraLinkIsValid = data => data.mhra && data.mhra.href && data.mhra.src;

const FooterBadges = pure(({ data = {}, data: { copyright, mhra = {} } } = {}) => (
  <div className={styles.badges}>
    <div className={styles.logoWrapper}>
      <span className={`${styles.logo} no-print`}>
        <WaitroseLogo small />
      </span>
      <small className={styles.copyright}>{copyright}</small>
    </div>
    {mhraLinkIsValid(data) && (
      <div className={`${styles.mhra} no-print`}>
        <span id="mhra-label" className={styles.mhraLabel}>
          {mhra.alt}
        </span>
        <EventMonitor
          actionType="redirect"
          originComponent="footer link"
          shortDescription={`footer link: ${mhra.alt}`}
        >
          <a href={mhra.href} aria-labelledby="mhra-label" className={styles.verifyLink}>
            <img loading="lazy" src={`${env.cfDomain}${mhra.src}`} alt={mhra.alt} />
          </a>
        </EventMonitor>
      </div>
    )}
  </div>
));

FooterBadges.propTypes = {
  data: PropTypes.shape({
    copyright: PropTypes.string,
    mhra: PropTypes.shape({
      alt: PropTypes.string,
      href: PropTypes.string,
      src: PropTypes.string,
    }),
  }),
};

FooterBadges.displayName = 'FooterBadges';

export default FooterBadges;
