import { rootPath } from 'utils/root-path';

export const urlParser = (url: string | { pathname: string; search: string }) => {
  switch (typeof url) {
    case 'string':
      return !url.startsWith('http') ? rootPath(url.toLowerCase()) : url;
    case 'object': {
      const { pathname, search } = url;
      return search ? pathname + search : pathname;
    }
    default:
      return url;
  }
};
