export default state => ({
  ...state,
  promoCodes: {
    ...state.promoCodes,
    isAdding: false,
    isRemoving: false,
    serverError: null,
  },
  waiting: false,
});
