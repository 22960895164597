import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronRightBold } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from 'components/Product/Promotion/Description/PromotionDescription.scss';

const PriceOnlyDescription = ({
  price,
  underlineText,
  noMargin,
  backgroundColor,
  isLink,
  withChevron,
}) => {
  return (
    <p
      className={classNames({
        [styles.noMargin]: noMargin,
      })}
    >
      <span
        className={classNames(styles.offerDescription, {
          [styles.withBackground]: !!backgroundColor,
          [styles.green]: backgroundColor === 'green',
          [styles.underline]: underlineText,
        })}
      >
        {`Was ${price}`}
        {backgroundColor && isLink && withChevron && (
          <ChevronRightBold size="xsmall" className={styles.chevronIcon} />
        )}
      </span>
    </p>
  );
};

PriceOnlyDescription.displayName = 'PriceOnlyDescription';

PriceOnlyDescription.propTypes = {
  price: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  underlineText: PropTypes.bool,
  isLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withChevron: PropTypes.bool,
};

PriceOnlyDescription.defaultProps = {
  noMargin: false,
  underlineText: false,
  isLink: false,
  backgroundColor: undefined,
  withChevron: false,
};

export default memo(PriceOnlyDescription);
