import { POST } from 'redux/modules/apple-wallet/actions/types';

const appleWalletRequest = state => ({
  ...state,
  appleWalletApiError: false,
  loading: true,
  pkPass: null,
});

const appleWalletSuccess = (state, action) => {
  const { result } = action;

  return {
    ...state,
    appleWalletApiError: false,
    pkPass: result,
    loading: false,
  };
};

const appleWalletFailure = state => ({
  ...state,
  appleWalletApiError: true,
  loading: false,
  pkPass: null,
});

export default map => {
  map.set(POST.request, appleWalletRequest);
  map.set(POST.success, appleWalletSuccess);
  map.set(POST.failure, appleWalletFailure);
  return map;
};
