export const BREAKPOINTS = {
  xs: 0,
  sm: 544,
  md: 768,
  lg: 992,
  xl: 1200,
} as const;

export type Breakpoints = typeof BREAKPOINTS;
export type BreakpointWidth = Breakpoints[keyof Breakpoints];

export const BREAKPOINT_NAMES = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

export const COLUMNS_VISIBLE_AT_BREAKPOINT = {
  xs: 1,
  sm: 3,
  md: 3,
  lg: 4,
  xl: 6,
} as const;

export const GRID_BASE = 12;
