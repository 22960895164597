import root from 'window-or-global';

export const yieldToMain = async (): Promise<void> => {
  if (__SERVER__) {
    return Promise.resolve();
  }

  return new Promise<void>(resolve => {
    root.requestAnimationFrame(() => {
      root.setTimeout(() => {
        resolve();
      }, 0);
    });
  });
};
