import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppleAppStore from '@johnlewispartnership/wtr-ingredients/dist/Icon/AppStore';
import GooglePlay from '@johnlewispartnership/wtr-ingredients/dist/Icon/GooglePlay';

import styles from './AppLinks.scss';

const getAppIcon = type => {
  switch (type) {
    case 'App Store':
      return <AppleAppStore className={styles.icon} />;
    case 'Google Play':
      return <GooglePlay className={styles.icon} />;
    default:
      return null;
  }
};

const AppLinks = ({ className, data }) => {
  const { links, title } = data;

  return (
    <div className={classNames(className, styles.appLinks)}>
      <h3 className={styles.appsSectionTitle}>{title}</h3>
      {links.map((link, index) => {
        const { href, popup, title: linkTitle, type } = link;

        const linkProps = {
          'data-actiontype': 'redirect',
          'data-origincomponent': 'footer link',
          'data-shortdescription': `footer link: ${title}: ${linkTitle}`,
          'aria-label': `Find our app on the ${linkTitle}`,
          className: styles.appLink,
          href,
          key: `footer-${linkTitle.toLowerCase()}-soc-${index}`,
          target: popup ? '_blank' : '_self',
          title: linkTitle,
        };

        return (
          <a data-testid={type} className={styles.icon} {...linkProps}>
            {getAppIcon(type)}
          </a>
        );
      })}
    </div>
  );
};

AppLinks.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        popup: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
};

AppLinks.defaultProps = {
  className: '',
};

AppLinks.displayName = 'AppLinks';

export default AppLinks;
