import { getCategoryNameById } from 'redux/modules/taxonomy/selectors';

export default state => {
  const { pathname } = state.router.location;
  const filterEvent = pathname.includes('browse') ? 'browse_filter' : 'search_filter';

  const categoryId = state.search.queryParams.category;
  const { stickyFilter } = state.search.filters || {};

  return {
    event: filterEvent,
    filter_label: 'Category',
    filter_selection: getCategoryNameById(state, categoryId),
    filter_location: stickyFilter ? 'sticky' : 'normal',
    filter_version: 'new',
  };
};
