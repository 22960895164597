import { SET_JOTTER } from 'redux/modules/multi-search/actions/types';

import { getJotterStorage } from 'redux/modules/multi-search/selectors/get-jotter-storage';

export const setJotter = values => (dispatch, getState) => {
  dispatch({
    type: SET_JOTTER,
    payload: values,
  });

  const jotterStorage = getJotterStorage(getState());
  jotterStorage.set({ values });
};
