import createReducer from 'redux/createReducer';
import { CLEAR_CHECKOUT_ERRORS } from 'redux/modules/checkout/actions/types';
import {
  ADD_ORDER_PAYMENT_CARD,
  CREATE_PAYMENT_SESSION,
  DELETE_PAYMENT_CARD,
  GET_PAYMENT_CARDS,
  GLOBAL_ACTIVE,
  GLOBAL_BIN_READY,
  GLOBAL_CARDINAL_READY,
  GLOBAL_CARDINAL_SETUP_START_TIME,
  GLOBAL_CARDINAL_SETUP_TIMER_FINISHED,
  GLOBAL_CARD_TOKEN,
  GLOBAL_JWT,
  POST_CARD_TO_VERIFONE_REQUEST,
  POST_CARD_TO_VERIFONE_SUCCESS,
} from 'redux/modules/payment/actions/types';
import addOrderPaymentCardFailure from 'redux/modules/payment/reducer/add-order-payment-card-failure';
import addOrderPaymentCardRequest from 'redux/modules/payment/reducer/add-order-payment-card-request';
import addOrderPaymentCardSuccess from 'redux/modules/payment/reducer/add-order-payment-card-success';
import clearPaymentErrors from 'redux/modules/payment/reducer/clear-payment-errors';
import createPaymentSessionFailure from 'redux/modules/payment/reducer/create-payment-session-failure';
import createPaymentSessionRequest from 'redux/modules/payment/reducer/create-payment-session-request';
import createPaymentSessionSuccess from 'redux/modules/payment/reducer/create-payment-session-success';
import deletePaymentCardRequest from 'redux/modules/payment/reducer/delete-payment-card-request';
import deletePaymentCardSuccess from 'redux/modules/payment/reducer/delete-payment-card-success';
import getPaymentCardsFailure from 'redux/modules/payment/reducer/get-payment-cards-failure';
import getPaymentCardsRequest from 'redux/modules/payment/reducer/get-payment-cards-request';
import getPaymentCardsSuccess from 'redux/modules/payment/reducer/get-payment-cards-success';
import {
  globalActive,
  globalBinReady,
  globalCardinalReady,
  globalCardinalSetupStartTime,
  globalCardinalSetupTimerFinished,
  globalCardToken,
  globalJwt,
} from 'redux/modules/payment/reducer/global-flow';
import postCardToVerifoneRequest from 'redux/modules/payment/reducer/post-card-to-verifone-request';
import postCardToVerifoneSuccess from 'redux/modules/payment/reducer/post-card-to-verifone-success';
import initialState from 'redux/modules/payment/state/initial-state';

const actionTypeReducerMap = [
  [ADD_ORDER_PAYMENT_CARD.request, addOrderPaymentCardRequest],
  [ADD_ORDER_PAYMENT_CARD.failure, addOrderPaymentCardFailure],
  [ADD_ORDER_PAYMENT_CARD.success, addOrderPaymentCardSuccess],
  [DELETE_PAYMENT_CARD.request, deletePaymentCardRequest],
  [DELETE_PAYMENT_CARD.success, deletePaymentCardSuccess],
  [CLEAR_CHECKOUT_ERRORS, clearPaymentErrors],
  [GET_PAYMENT_CARDS.failure, getPaymentCardsFailure],
  [GET_PAYMENT_CARDS.request, getPaymentCardsRequest],
  [GET_PAYMENT_CARDS.success, getPaymentCardsSuccess],
  [GLOBAL_ACTIVE, globalActive],
  [GLOBAL_BIN_READY, globalBinReady],
  [GLOBAL_CARD_TOKEN, globalCardToken],
  [GLOBAL_CARDINAL_READY, globalCardinalReady],
  [GLOBAL_CARDINAL_SETUP_TIMER_FINISHED, globalCardinalSetupTimerFinished],
  [GLOBAL_CARDINAL_SETUP_START_TIME, globalCardinalSetupStartTime],
  [GLOBAL_JWT, globalJwt],
  [CREATE_PAYMENT_SESSION.failure, createPaymentSessionFailure],
  [CREATE_PAYMENT_SESSION.request, createPaymentSessionRequest],
  [CREATE_PAYMENT_SESSION.success, createPaymentSessionSuccess],
  [POST_CARD_TO_VERIFONE_REQUEST, postCardToVerifoneRequest],
  [POST_CARD_TO_VERIFONE_SUCCESS, postCardToVerifoneSuccess],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
