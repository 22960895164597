import marketingpreferences from 'api/definitions/marketingpreferences';
import { reportMarketingConsents } from 'redux/modules/marketing-preferences/actions/report-marketing-consents';
import locator from 'utils/locator';

import { SET_CONSENT_ACTIONS } from './types';
import {
  ALL_CHANNELS_GRANTED,
  ALL_CHANNELS_DENIED,
  PRIVACY_POLICY_TITLE,
  PRIVACY_POLICY_VERSION,
  sources,
} from '../constants';

export const getSource = () => {
  if (locator.search.includes('CheckOutInitiateCmd')) return sources.checkout;
  if (locator.search.includes('dv=0')) return sources.myAccountJoinMyWaitrose;
  if (locator.pathname.includes('myaccount')) return sources.updateMarketingPreferences;
  return sources.registration;
};

const setMarketingConsent = (marketingScheme, consent) => ({
  types: SET_CONSENT_ACTIONS,
  apiCall: marketingpreferences.put({
    body: {
      consent: { ...(consent ? ALL_CHANNELS_GRANTED : ALL_CHANNELS_DENIED) },
      notices: [
        {
          title: PRIVACY_POLICY_TITLE,
          version: PRIVACY_POLICY_VERSION,
        },
      ],
      source: getSource(),
    },
    marketingScheme: marketingScheme,
  }),
  marketingScheme: marketingScheme,
});

export const setAllMarketingConsents = formInputValues => dispatch =>
  Promise.all(
    Object.keys(formInputValues).map(marketingScheme => {
      const consent = formInputValues[marketingScheme];
      if (consent !== undefined) {
        return dispatch(setMarketingConsent(marketingScheme, consent));
      }
    }),
    dispatch(reportMarketingConsents(formInputValues)),
  );

export const setOnlyPositiveMarketingConsents = formInputValues => dispatch =>
  Promise.all(
    Object.keys(formInputValues).map(marketingScheme => {
      const consent = formInputValues[marketingScheme];
      if (consent !== undefined && consent !== false) {
        return dispatch(setMarketingConsent(marketingScheme, consent));
      }
    }),
    dispatch(reportMarketingConsents(formInputValues)),
  );
