import { fetchRecipes } from 'redux/modules/meal-planner/actions/fetch';
import { getFeatureFlags } from 'utils/feature-flags';
import { getProductsByRecipeId } from 'redux/modules/recipes/actions/get-products';
import type { RecipeSummaryItem } from 'api/definitions/recipes';
import { getURLParamValue } from 'redux/modules/recipes/utils/get-url-param-value';
import { RecipeCardsGridInfo } from 'redux/modules/cms-hybrid-recipes-page/reducers';
import { RECIPE_CARDS_GRID } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/cmsResourceTypes';
import { WaitroseModelProps } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/cms-components/types';
import { fifoBufferLookup } from 'utils/fifo-buffer';
import getCmsHybridRecipesPage from 'redux/modules/cms-hybrid-recipes-page/actions/fetch-cms-hybrid-recipes-page';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { fetchRecipeById } from 'redux/modules/recipes/actions';
import { FilterTitles } from 'components/Recipes/MealPlanner/Filters';

export const fetchMealPlannerServingSizes = () => async (dispatch: WtrDispatch) =>
  dispatch(
    fetchRecipes('recipes/summary', undefined, undefined, 0, 0, 1, undefined, '', true, true),
  );

export const fetchMealPlannerDietaryRequirements = () => async (dispatch: WtrDispatch) =>
  dispatch(
    fetchRecipes(
      'recipes/summary',
      undefined,
      [
        {
          title: 'Course',
          values: ['Main meal'],
        },
      ],
      0,
      0,
      1,
      undefined,
      '',
      true,
      true,
    ),
  );

export const getProductsForRecipes =
  () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
    if (!getFeatureFlags().shoppableRecipesMVP) {
      return Promise.resolve();
    }

    const { mealPlanner = { recipes: [] } } = getState();
    if (!mealPlanner) return Promise.resolve();
    const recipeIds = mealPlanner.recipes.map((recipe: RecipeSummaryItem | null) => recipe?.id);

    const promises = recipeIds
      .filter(Boolean)
      .map((recipeId: string) => dispatch(getProductsByRecipeId(recipeId)));

    return Promise.all(promises);
  };

export const getRecipesForMealPlanner =
  () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
    if (!getFeatureFlags().shoppableRecipesMVP) {
      return Promise.resolve();
    }

    const { mealPlanner = { recipes: [] } } = getState();
    if (!mealPlanner) return Promise.resolve();
    const recipeIds = mealPlanner.recipes.map((recipe: RecipeSummaryItem | null) => recipe?.id);

    const promises = recipeIds
      .filter(Boolean)
      .map((recipeId: string) => dispatch(fetchRecipeById(recipeId)));

    return Promise.all(promises);
  };

export default ({ match: { pathname = '' } = {} } = {}) =>
  async (dispatch: WtrDispatch, getState: () => WtrState) => {
    const contentUrl = pathname.replace('/ecom/', '');
    const content = fifoBufferLookup(getState().cmsHybridRecipesPage!.locations, contentUrl); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const result =
      content || (await dispatch(getCmsHybridRecipesPage(contentUrl, getCustomerId(getState()))));
    const components = result?.locations?.root;

    const recipeCardsGrid = components?.find(
      (component: WaitroseModelProps) => component.resourceType === RECIPE_CARDS_GRID,
    ) as RecipeCardsGridInfo;

    const filters = FilterTitles.reduce(
      (acc, val) => {
        const param = getURLParamValue(val);
        if (param) {
          acc.push({
            title: val,
            values: param.split(','),
          });
        } else if (val === 'Dietary') {
          if (recipeCardsGrid && recipeCardsGrid.gridType === 'meal-plan') {
            const { dietaryFilters } = recipeCardsGrid;
            if (dietaryFilters) {
              acc.push({
                title: 'Dietary',
                values: dietaryFilters,
              });
            }
          }
        }
        return acc;
      },
      [
        {
          title: 'Course',
          values: ['Main meal'],
        },
      ],
    );

    if (getState().mealPlanner!.recipes.filter(Boolean).length) return Promise.resolve(); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return dispatch(
      fetchRecipes('recipes/summary', undefined, filters, 0, 0, 7, undefined, '', true, true),
    );
  };
