import get from 'lodash/get';

import {
  CUSTOMER_SERVICE_FORM_SUBMIT,
  CUSTOMER_SERVICE_FORM_SUBMIT_FAIL,
  CUSTOMER_SERVICE_FORM_SUBMIT_SUCCESS,
} from 'redux/modules/customer-service/actions/types';

const reducer = (state, action) => {
  switch (action.type) {
    case CUSTOMER_SERVICE_FORM_SUBMIT: {
      return {
        ...state,
        formSubmittingError: false,
      };
    }

    case CUSTOMER_SERVICE_FORM_SUBMIT_FAIL: {
      const statusCode = get(action, 'result.status');
      const formSubmittingError = !statusCode || statusCode !== 201;
      return {
        ...state,
        formSubmittingError,
        statusCode,
      };
    }

    case CUSTOMER_SERVICE_FORM_SUBMIT_SUCCESS: {
      const statusCode = get(action, 'result.status');
      const formSubmittingError = !statusCode || statusCode !== 201;

      if (formSubmittingError) {
        return {
          ...state,
          formSubmittingError,
          statusCode,
        };
      }

      // redux-form docs suggest blasting the full state away here:
      // https://redux-form.com/7.4.2/docs/faq/howtoclear.md/
      // But for safety, retain state by default and clear known properties.
      return {
        formSubmittingError: false,
        formSubmitted: true,
      };
    }

    default:
      return state;
  }
};

export default reducer;
