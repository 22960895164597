import { compose } from 'redux';
import { connect } from 'react-redux';

import { clearPreloadedIfLocationChanged } from 'redux/modules/page/actions';
import { clientPageLoaded } from 'redux/modules/page/actions/client-page-loaded';
import { clientPageLoading } from 'redux/modules/page/actions/client-page-loading';
import { getIsPageDataPreloadInProgress, getIsPreloaded } from 'redux/modules/page/selectors';
import { routes } from 'route-data/routes';
import { withRouteProps } from 'utils/with-route-props';

import DataProvider from './DataProvider';

const createFetcherMap = (key, dispatch) =>
  routes.reduce((map, route, index) => {
    map[index] = (...args) => dispatch(route[key](...args));
    return map;
  }, {});

const withConnect = connect(
  state => ({
    isPageDataPreloadInProgress: getIsPageDataPreloadInProgress(state),
    preloaded: getIsPreloaded(state),
  }),
  dispatch => ({
    clearPreloadedIfLocationChanged: location =>
      dispatch(clearPreloadedIfLocationChanged(location)),
    fetch: createFetcherMap('fetch', dispatch),
    clientFetch: createFetcherMap('clientFetch', dispatch),
    clientPageLoaded: () => dispatch(clientPageLoaded()),
    clientPageLoading: () => dispatch(clientPageLoading()),
  }),
);

export default compose(withRouteProps, withConnect)(DataProvider);
