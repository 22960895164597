export const limitWords = (phrase = '', maxWords = 10, separatorChars = ' ,.-') => {
  let wordCount = 0;
  return phrase.split('').reduce((limited, ch, index, chars) => {
    const isSeparator = separatorChars.includes(ch);
    const isPrevSeparator = separatorChars.includes(chars[index - 1]);
    if (isSeparator && !isPrevSeparator) {
      wordCount += 1;
    }
    return wordCount < maxWords ? limited + ch : limited;
  }, '');
};
