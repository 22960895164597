import { getCustomerId } from 'redux/modules/sessions/selectors';
import { modifiedFieldsSelector } from 'redux/modules/address-suggestion/selectors';

const addressSuggestionSearch = state => ({
  event: 'address_suggestion_search',
  customerId: getCustomerId(state),
});

const addressSuggestionSearchResultSet = state => ({
  event: 'address_suggestion_search_resultset',
  customerId: getCustomerId(state),
});

const addressSuggestionRetrieve = state => ({
  event: 'address_suggestion_retrieve',
  customerId: getCustomerId(state),
});

const addressSuggestionModified = state => ({
  event: 'address_suggestion_modified',
  customerId: getCustomerId(state),
  modifiedFields: modifiedFieldsSelector(state),
});

export {
  addressSuggestionSearch,
  addressSuggestionSearchResultSet,
  addressSuggestionRetrieve,
  addressSuggestionModified,
};
