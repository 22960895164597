import { getAllDecisions } from 'redux/modules/experiments/selectors';
import { getIsInitialPageLoaded } from 'redux/modules/page/selectors';
import { getCustomerDataIfLoggedIn } from 'redux/modules/sessions/get-customer-data-if-logged-in';

export default () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
  if (getIsInitialPageLoaded(getState())) {
    return;
  }
  // Extraction of key logic from useExperiments hook so it's function can be used outside of a React component
  const experimentId = 'renderBlockingApiRequests';
  // Odd casting to 'never' type as arg to getAllDecisions to avoid TS error. Best fix would be
  // to convert the file the function is to TypeScript.
  const decisions = getAllDecisions(getState() as never);
  const variant = decisions?.[experimentId];
  if (variant !== 'unBlock') {
    return;
  }
  await getCustomerDataIfLoggedIn(dispatch, getState);
};
