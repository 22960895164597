import { addRemoveFavourite } from 'redux/modules/search-and-browse/actions/add-remove-favourite';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import {
  getItemToFavouritePostAuthentication,
  removeItemToFavouritePostAuthentication,
} from 'utils/favourites';

export const favouritePostAuthentication = () => (dispatch, getState) => {
  if (isUserLoggedIn(getState())) {
    const productInfo = getItemToFavouritePostAuthentication();

    if (productInfo) {
      removeItemToFavouritePostAuthentication();

      const { isFavourited, lineNumber } = productInfo;
      dispatch(addRemoveFavourite(isFavourited, lineNumber));
    }
  }
};
