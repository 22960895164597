import { CLEAR_JOTTER } from 'redux/modules/multi-search/actions/types';

import { getJotterStorage } from 'redux/modules/multi-search/selectors/get-jotter-storage';

export const clearJotter = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_JOTTER,
  });

  const jotterStorage = getJotterStorage(getState());
  jotterStorage.clear();
};
