import flatMap from 'lodash/flatMap';
import get from 'lodash/get';

//
// findem accepts a dot-separated path to values you wish to retrieve from
// a given object or array of objects, returning an array of matching results.
//
// It allows deeply nested arrays to be searched using '[].' syntax.
//
// const data = [
//   { products: [{ id: '100' }, { id: '101' }] },
//   { products: [{ id: '200' }] },
//   { things: [{ more: { things: [{ value: 'val1' }] } }] },
//   { things: [{ more: { things: [{ value: 'val2' }, { value: 'val3' }] } }] },
// ];
//
// Example:
//
// findem(d, 'products[].id'));                 // --> ['100', '101', '200']
// findem(d, 'things[].more.things[].value'));  // --> ['val1', 'val2', 'val3']
//
export const findem = (object, deepPath) => {
  if (!deepPath) throw new Error('findem expected path to be specified!');
  const paths = deepPath.split(/\[\][.]/);
  const collection = Array.isArray(object) ? object : [object];
  return paths
    .reduce(
      (acc, path, index) =>
        index === paths.length - 1
          ? acc.map(obj => get(obj, path))
          : flatMap(acc, obj => get(obj, path)),
      collection,
    )
    .filter(value => value);
};
