import fromPairs from 'lodash/fromPairs';
import { CHANGE_SLOT_AMEND, START_AMEND } from 'redux/modules/amend-order/actions/types';
import { PLACE_AND_UPDATE_ORDER, START_PLACE_ORDER } from 'redux/modules/checkout/actions/types';
import { STARTED_INSTANT_CHECKOUT } from 'redux/modules/instant-checkout/actions/types';
import {
  ADD_ORDER_TO_CALENDAR,
  ADD_PROMO_CODE,
  NOTIFY_REVIEW_ORDER,
  REMOVE_PROMO_CODE,
} from 'redux/modules/order/actions/types';
import { CANCEL_ORDER_LOADED } from 'redux/modules/orders/actions/types';
import { ORDER_LOADED } from 'redux/modules/trolley/actions/types';
import addOrderToCalendar from './add-order-to-calendar';
import {
  checkAmendOrderWhenOrderLoaded,
  checkAmendOrderWhenStartAmendFulfilled,
} from './amend-order';
import { checkCancelOrderByResponse } from './cancel-order';
import { checkChangeSlotByResponse } from './change-slot';
import placeOrder from './place-order';
import {
  addPromoCode,
  addPromoCodeApiError,
  removePromoCode,
  removePromoCodeApiError,
} from './promo-codes';
import reviewOrder from './review-order';
import { startPlaceOrder } from './start-place-order';
import { startedInstantCheckout } from './started-instant-checkout';

const actionTypeReducerMap = [
  [ADD_ORDER_TO_CALENDAR, addOrderToCalendar],
  [ADD_PROMO_CODE.success, addPromoCode],
  [ADD_PROMO_CODE.failure, addPromoCodeApiError],
  [CANCEL_ORDER_LOADED, checkCancelOrderByResponse],
  [CHANGE_SLOT_AMEND.success, checkChangeSlotByResponse],
  [NOTIFY_REVIEW_ORDER, reviewOrder],
  [ORDER_LOADED, checkAmendOrderWhenOrderLoaded],
  [PLACE_AND_UPDATE_ORDER.success, placeOrder],
  [REMOVE_PROMO_CODE.success, removePromoCode],
  [REMOVE_PROMO_CODE.failure, removePromoCodeApiError],
  [START_AMEND.success, checkAmendOrderWhenStartAmendFulfilled],
  [START_PLACE_ORDER, startPlaceOrder],
  [STARTED_INSTANT_CHECKOUT, startedInstantCheckout],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default fromPairs(actionTypeReducerMap as any);
