import { createHistory } from 'utils/create-history';
import { findRouteMatch } from 'route-data/find-route-match';

export const oneTimeFetch = async (req, { dispatch }, client = false) => {
  const routeMatch = findRouteMatch(req.path);
  if (routeMatch && routeMatch.fetch) {
    const history = createHistory(req.url);
    const { match } = routeMatch;
    const { location } = history;
    await dispatch(routeMatch.fetch({ location, match }));
    if (client && routeMatch.clientFetch) {
      await dispatch(routeMatch.clientFetch({ location, match }));
    }
  }
  return Promise.resolve();
};
