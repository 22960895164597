import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { trackModalClosed, trackModalOpened } from 'analytics/modal-tracking';
import { navigateToMultiSearch } from 'redux/modules/multi-search/actions/navigate-to-multi-search';
import { useJotter } from 'redux/modules/multi-search/actions/use-jotter';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import isEqual from 'lodash/isEqual';

import { getJotterValues } from 'redux/modules/multi-search/selectors';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { clearJotter } from 'redux/modules/multi-search/actions/clear-jotter';

import { setJotter } from 'redux/modules/multi-search/actions/set-jotter';
import { modalSeverityValues } from 'constants/modals';

import cleanValues from '../Modal/clean';

export const useModal = ({ onToogleModal, onSearch }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const customerId = useSelector(getCustomerId, isEqual);
  const values = useSelector(getJotterValues, isEqual);
  const [text, setText] = useState(undefined);

  useEffect(() => {
    if (!text) setText(values.join('\n'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const dispatch = useDispatch();

  const toogleModal = useCallback(
    event => {
      const multiSearch = {
        id: 'multiSearchModal',
        severity: modalSeverityValues.INFO,
        title: 'Multi-search',
        customerId,
      };

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (onToogleModal) {
        onToogleModal(!modalOpen);
      }

      const trackModal = modalOpen ? trackModalOpened : trackModalClosed;
      trackModal(multiSearch);

      setModalOpen(!modalOpen);
    },
    [customerId, modalOpen, onToogleModal],
  );

  const onTextChange = useCallback(event => setText(event.target.value), []);

  const goToMultiSearchPage = useCallback(() => {
    const cleanText = cleanValues(text);
    dispatch(setJotter(cleanText));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    dispatch(useJotter());
    if (onSearch) onSearch();
    dispatch(navigateToMultiSearch(cleanText[0]));
  }, [dispatch, onSearch, text]);

  const onClose = useCallback(
    type => {
      switch (type) {
        case ModalCloseOptions.CLEAR_LIST:
          setText('');
          dispatch(clearJotter());
          return;

        case ModalCloseOptions.CLEAR:
          dispatch(clearJotter());
          return;
        case ModalCloseOptions.SAVE:
          goToMultiSearchPage();
          break;
        default:
          dispatch(setJotter(cleanValues(text)));
      }

      toogleModal();
    },
    [text, dispatch, goToMultiSearchPage, toogleModal],
  );

  return {
    toogleModal,
    text,
    onTextChange,
    onClose,
    modalOpen,
  };
};
