import { apiCallback } from 'api';

export default {
  get: apiCallback(
    {
      service: 'recipes',
      endpoint: 'recipes/rating?recipeId=${recipeId}',
      verbs: ['get'],
      defaultVersion: 1,
    },
    'get',
  ),
  post: apiCallback(
    {
      service: 'recipes',
      endpoint: 'recipes/rating',
      verbs: ['post'],
      defaultVersion: 1,
    },
    'post',
  ),
};
