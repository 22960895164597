import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useScrolled } from 'hooks/use-scrolled';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';
import { slowlyScrollPageVerticallyToTop } from 'utils/scroll';

import { ChevronUp } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from 'components/BackToTop/BackToTop.scss';

const dataAttributes = dataAttributesGenerator({
  actiontype: 'redirect',
  origincomponent: 'BackToTop',
  shortdescription: 'Goes to the top of the page',
});

const onClick = event => {
  event.stopPropagation();
  slowlyScrollPageVerticallyToTop();
};

const BackToTop = ({ visiblePosition }) => {
  const { scrolled } = useScrolled(visiblePosition);

  return (
    <div
      className={classNames(styles.backToTop, `no-print`, {
        [styles.active]: scrolled,
      })}
    >
      <button
        className={styles.button}
        onClick={onClick}
        type="button"
        aria-label="Back to top"
        {...dataAttributes}
      >
        <ChevronUp className={styles.icon} size="small" />
      </button>
    </div>
  );
};

BackToTop.propTypes = {
  visiblePosition: PropTypes.number,
};

BackToTop.defaultProps = {
  visiblePosition: 600,
};

export default BackToTop;
