import {
  addAddress,
  deleteAddress,
  reportDeleteSuccess,
  hideAddModal,
  showEditModal,
  hideEditModal,
  showChooseAddress,
  hideChooseAddress,
  cancelChooseAddress,
  chooseAddress,
  cancelAddModal,
  cancelEditModal,
} from 'redux/modules/address/actions';
import { resetUpdateContactAddressError } from 'redux/modules/address/actions/reset-update-contact-address-error';
import { getChooseAddressModal, getActiveAddresses } from 'redux/modules/address/selectors';

const updateChosenAddress = (dispatch, state, address) => {
  const { isOpen } = getChooseAddressModal(state);
  if (isOpen) {
    return dispatch(chooseAddress(address));
  }
  return null;
};

const onSubmitAddAddress = address => async (dispatch, getState) => {
  const updatedAddress = await dispatch(addAddress(address));
  const state = await getState();
  await updateChosenAddress(dispatch, state, updatedAddress);
  return updatedAddress;
};
const onDeleteAddress = address => async (dispatch, getState) => {
  await dispatch(deleteAddress(address));
  await dispatch(reportDeleteSuccess());
  const state = await getState();
  const choosenModal = getChooseAddressModal(state);
  const { id = '' } = address;
  if (choosenModal.address && choosenModal.address.id === id) {
    const addresses = getActiveAddresses(state);
    await updateChosenAddress(dispatch, state, addresses.filter(a => a.id !== id)[0]);
  }
};
const onShowEditModal = address => dispatch => dispatch(showEditModal(address));

const onShowChooseAddress = addresses => dispatch => {
  dispatch(resetUpdateContactAddressError());
  dispatch(showChooseAddress(addresses));
};
const onHideChooseAddress = cancelled => dispatch =>
  cancelled ? dispatch(cancelChooseAddress()) : dispatch(hideChooseAddress());

const onCloseAddEditModal = (isEdit, cancelled) => dispatch => {
  if (cancelled) {
    const action = isEdit ? cancelEditModal : cancelAddModal;
    return dispatch(action());
  }
  const action = isEdit ? hideEditModal : hideAddModal;
  return dispatch(action());
};

export {
  onSubmitAddAddress,
  onDeleteAddress,
  onShowEditModal,
  onShowChooseAddress,
  onHideChooseAddress,
  onCloseAddEditModal,
  updateChosenAddress,
};
