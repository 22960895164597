import { connect } from 'react-redux';

import { getCurrentPageDepth } from 'redux/modules/page/selectors';
import { getLocationByName } from 'redux/modules/content/selectors';

import ContentLocation from 'components/ContentLocation/ContentLocation';

export default connect((state, { name }) => ({
  childComponents: getLocationByName(state, name),
  taxonomyLevel: getCurrentPageDepth(state),
  position: name,
}))(ContentLocation);
