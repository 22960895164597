import { showClientOfflineModal } from 'api/actions/show-client-offline-modal';
import { showGenericNetworkErrorModal } from 'api/actions/show-generic-network-error-modal';
import { log } from 'utils/error-logging';
import { ApiError } from 'constants/errors';
import { getFeatureFlags } from 'utils/feature-flags';
import { isOnline } from 'utils/is-online';

const isAbortedRequestError = (error?: ApiError | string) =>
  typeof error !== 'string' && error?.crossDomain && error?.status === undefined;

export const handleGenericNetworkError = ({
  dispatch,
  error,
  shouldLog = true,
}: {
  dispatch: WtrDispatch;
  error?: ApiError | string;
  shouldLog?: boolean;
}) => {
  if (!isOnline()) {
    showClientOfflineModal(dispatch);

    return;
  }

  if (isAbortedRequestError(error)) {
    return;
  }

  const { modalErrorLogging } = getFeatureFlags();

  if (modalErrorLogging && shouldLog && error) {
    log(error, { logger: 'generic-network-error' });
  }

  showGenericNetworkErrorModal(dispatch);
};
