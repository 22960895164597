import over from 'lodash/over';

import { SESSION_DETAILS_LOADED } from 'redux/modules/sessions/actions/types';

import { identifyCustomer } from './identify-customer';
import { stashClickId } from './stash-click-id';
import { trackConversion } from './track-conversion';

export const actionHandlers = {
  [SESSION_DETAILS_LOADED]: over([stashClickId, identifyCustomer, trackConversion]),
};
