import { favouritesSortOptions } from 'components/Favourites/FavouritesSorting/constants';

const favouritesSortImpressions = (
  _state: WtrState,
  { payload: { sortBy } }: { payload: { sortBy: string } },
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sortOption = favouritesSortOptions.find(({ value }) => sortBy === value)!;

  return {
    event: 'favourites_sort',
    sort_method: sortOption.children,
  };
};

export default favouritesSortImpressions;
