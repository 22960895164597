import { connect } from 'react-redux';

import { navigateFromMegaMenu } from 'redux/modules/page/actions/navigate-from-mega-menu';
import { getMegaMenuActiveLevel } from 'redux/modules/page/selectors/mega-menu';

import CMSMenuLink from './CMSMenuLink';

export default connect(
  state => ({
    level: getMegaMenuActiveLevel(state),
  }),
  {
    navigateFromMegaMenu,
  },
)(CMSMenuLink);
