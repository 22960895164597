import initialState from 'redux/modules/order/state/initial-state';

const EE_VOUCHER_TYPE_REGEX = /^\d*:\d+$/;
const TRACKED_OFFER_TYPE_REGEX = /^[PQ2RTW6789XBCDYFGHZJKLMN]{15}$/;
const BASE_64_VALUE_CHECK_REGEX = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;

export function determineIncentiveCodeType(incentiveCode) {
  if (
    BASE_64_VALUE_CHECK_REGEX.test(incentiveCode) &&
    EE_VOUCHER_TYPE_REGEX.test(atob(incentiveCode))
  ) {
    return 'VOUCHER';
  }

  if (TRACKED_OFFER_TYPE_REGEX.test(incentiveCode)) {
    return 'COMBO';
  }

  return 'COUPON';
}

const reducer = (state, { result = {} }) => {
  const {
    adjustments = [],
    containsEntertainingLines,
    contactNumber,
    customerOrderId,
    deliveryNote,
    numberOfItems,
    partnerDiscountCardNumber,
    paymentInfo: { cardPayment, giftCards, giftVouchers } = {},
    paperStatement,
    slots,
    status,
    totals,
    orderWarnings,
  } = result;

  const promoCodesList = adjustments
    .filter(a => a.adjustmentType === 'INCENTIVE')
    .map(adjustment => ({
      code: adjustment.id,
      status: adjustment.status,
      description: adjustment.displayText,
      value: adjustment.amount,
      type: determineIncentiveCodeType(adjustment.id),
      applicationMethod: adjustment.applicationMethod,
    }));

  return {
    ...state,
    contactNumber,
    amendOrderPaymentCard: {
      addressId: cardPayment && cardPayment.billingAddress && cardPayment.billingAddress.id,
      cardholderName: cardPayment && cardPayment.cardholderName,
      expiryDate: cardPayment && cardPayment.expiryDate,
      id: cardPayment && cardPayment.id,
      maskedCardNumber: cardPayment && cardPayment.maskedCardNumber,
    },
    containsEntertainingLines:
      containsEntertainingLines !== undefined
        ? containsEntertainingLines
        : initialState.containsEntertainingLines,
    customerOrderId,
    giftCards: giftCards || [],
    giftVouchers: giftVouchers
      ? giftVouchers.map(item => ({
          amount: item.value.amount,
          serialNumber: item.serialNumber,
          status: item.status,
        }))
      : [],
    loaded: true,
    numberOfItems,
    order: {
      deliveryNote: deliveryNote !== undefined ? deliveryNote : initialState.order.deliveryNote,
      paperStatement:
        paperStatement !== undefined ? paperStatement : initialState.order.paperStatement,
      status,
      totals,
    },
    partnerDiscountCardNumber,
    promoCodes: {
      ...state.promoCodes,
      promoCodesList,
    },
    slots,
    orderWarnings,
    waiting: false,
  };
};

export default reducer;
