/**
  Tracking quantity and value added/removed to/from the trolley for analytics
  is complicated due to reasons such as the following:

  1. We optimistically increase quantity when items are added to the trolley,
     before API responses have been received, meaning that the state is
     not always a reliable indicator especially when multiple updates are pending
  2. The trolley-queue mechanism isn't easy to reason about in code and drops
     update actions that have been dispatched in quick succession. It does however
     ensure reliable sequential processing of update trolley API calls.
  3. The frontend has no knowledge of the prices of UOMs that are not the default UOM
     because this information is not returned by product APIs

  This code therefore maintains a separate area of the store that tracks *changes* in
  quantities and prices of items in the trolley following responses from the trolley API,
  by storing reliable current and previous price and quantity seen.

  Analytics code can refer to this to determine the change in value of an item updated
  in the trolley and when taking into account the quantity change, the unit price can
  also be inferred.
*/
export const updateAnalytics = (state, responseTrolleyItems) => {
  const updatedItems = {};
  const localItems = state.analytics.trolleyItems;

  if (!responseTrolleyItems) return state.analytics;

  // added and updated items
  responseTrolleyItems.forEach(item => {
    const { lineNumber } = item;
    const localItem = localItems[lineNumber];
    const updatedItem = updatedItems[lineNumber];

    updatedItems[lineNumber] = updatedItem
      ? {
          // multiple trolley items received for this line number,
          // e.g. personalised entertaining products - add values
          ...updatedItem,
          price: updatedItem.price + item.price?.amount ?? 0,
          quantity: updatedItem.quantity + item.quantity?.amount ?? 0,
        }
      : {
          // the first trolley item in response for this lineNumber,
          // stash previous and set current
          prevPrice: localItem?.price ?? 0,
          prevQuantity: localItem?.quantity ?? 0,
          price: item.price?.amount ?? 0,
          quantity: item.quantity?.amount ?? 0,
        };
  });

  // removed items (missing from response)
  Object.keys(localItems).forEach(localLineNumber => {
    if (!updatedItems[localLineNumber]) {
      const localItem = localItems[localLineNumber];
      updatedItems[localLineNumber] = {
        prevPrice: localItem.price,
        prevQuantity: localItem.quantity,
        price: 0,
        quantity: 0,
      };
    }
  });

  return {
    analytics: {
      ...state.analytics,
      trolleyItems: updatedItems,
    },
  };
};
