import { createSelector, weakMapMemoize } from 'reselect';
import { PaymentCard } from '../state/initial-state';

const EMPTY_LIST: PaymentCard[] = [];

const getSavedPaymentCards = (state: WtrState) => state?.payment?.paymentCards ?? EMPTY_LIST;

// NOTE: When a saved card(s) exists on amend order journey, the payment card previously
// used to place that order should be having a priority over any saved card
// For now we are using just the first saved card as there could be only one as initial MVP
export default createSelector<[typeof getSavedPaymentCards], PaymentCard | undefined>(
  getSavedPaymentCards,
  paymentCards => paymentCards[0],
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);
