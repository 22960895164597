import { CheckoutState } from '../state/initial-state';

interface Payload {
  saveNewCard: boolean | undefined;
}

export const setSaveNewCart: WtrReducer<CheckoutState, Payload> = (state, { payload }) => ({
  ...state,
  saveNewCard: payload.saveNewCard,
});
