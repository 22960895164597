import createReducer from 'redux/createReducer';

import { initialState } from 'redux/modules/incentives/state';
import getIncentives from './get-incentives';
import getIncentivesProducts from './get-incentives-products';
import loadIncentive from './load-incentive';
import unloadIncentive from './unload-incentive';

export const actionTypeReducerMap = [
  ...getIncentives(),
  ...getIncentivesProducts(),
  ...loadIncentive(),
  ...unloadIncentive(),
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
