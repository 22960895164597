import urls from 'constants/urls';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import { getFulfilmentTypeForBookedSlot } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';

export const getEditSlotUrl = state => {
  let editSlotUrl = urls.serviceSelection;
  if (hasBookedSlot(state)) {
    const { slotType } = getFulfilmentTypeForBookedSlot(state);
    switch (slotType) {
      case SlotTypes.DELIVERY:
        editSlotUrl = urls.bookDeliverySlot;
        break;
      case SlotTypes.ENTERTAINING_COLLECTION:
        editSlotUrl = urls.bookEntertainingCollectionSlot;
        break;
      case SlotTypes.GROCERY_COLLECTION:
        editSlotUrl = urls.bookGroceriesAndEntertainingCollectionSlot;
        break;
      default:
    }
  }
  return editSlotUrl;
};
