import { ADD_DELAYED_ACTION } from 'redux/middleware/delayed-action/types';
import { showSecurityTokenTimeoutModal } from 'redux/modules/payment/actions/global-flow/security-token-timeout';
import { START_PAYMENT_SESSION_TIMEOUT } from 'redux/modules/payment/actions/types';
import root from 'window-or-global';

export default store => {
  if (root) {
    if (root.usestubs) {
      root.tisljqwejljasdoijqwjkpn = () =>
        store.dispatch({
          timeout: 0,
          timerName: 'CHECKOUT_SESSION',
          type: START_PAYMENT_SESSION_TIMEOUT,
        });

      root.yofehfhldshfsheoh3892heos = () => {
        store.dispatch({
          type: ADD_DELAYED_ACTION,
          payload: {
            action: showSecurityTokenTimeoutModal(),
            timerName: 'SECURITY_TOKEN_EXPIRATION',
            timeout: 100,
          },
        });
      };
    }
  }
};
