import { AccordionItem } from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import { OpenLink } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import EventMonitor from 'components/EventMonitor/EventMonitor';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import locator from 'utils/locator';
import styles from './index.scss';

const UTMLinkTitles = ['Cellar', 'Gifts', 'Garden'];

const getHrefWithUTMParameter = link => {
  if (!UTMLinkTitles.includes(link.title)) {
    return link.href;
  }

  const [linkBase, params] = link.href.split('?');

  const searchParams = new URLSearchParams(params);

  const utmCampaign = searchParams.get('utm_campaign');

  if (!utmCampaign) {
    searchParams.set('utm_campaign', `footer_${link.title.toLowerCase()}`);
    searchParams.set('utm_source', locator.href);
    searchParams.set('utm_medium', link.title.toLowerCase());
  }

  return `${linkBase}?${searchParams.toString()}`;
};

const FooterLinkList = memo(({ accordion, classesToAdd, section: { links, title } }) => {
  const accordionProps = {
    title,
    id: title.toLowerCase(),
  };

  const linksComponent = links
    ? links.map((link, index) => {
        const eventProps = {
          actionType: 'redirect',
          originComponent: 'footer link',
          shortDescription: `footer link: ${title}: ${link.title}`,
          key: `footer-${title.replace(' ', '-').toLowerCase()}-${index}`,
        };
        const linkProps = {
          className: styles.footerLink,
          href: getHrefWithUTMParameter(link),
          target: link.popup ? '_blank' : '_self',
        };

        return (
          <EventMonitor {...eventProps}>
            <a {...linkProps}>
              <span>{link.title}</span>
              {link.popup && (
                <OpenLink className={styles.openlink} size="xsmall" title="Opens in new window" />
              )}
            </a>
          </EventMonitor>
        );
      })
    : null;

  return accordion ? (
    <AccordionItem {...accordionProps}>{linksComponent}</AccordionItem>
  ) : (
    <div className={classesToAdd ? `${styles.linkSection} ${classesToAdd}` : styles.linkSection}>
      <h3 className={styles.linkSectionTitle}>{title}</h3>
      {linksComponent}
    </div>
  );
});

FooterLinkList.propTypes = {
  accordion: PropTypes.bool,
  classesToAdd: PropTypes.string,
  section: PropTypes.object.isRequired,
};

FooterLinkList.defaultTypes = {
  accordion: false,
  classesToAdd: '',
};

FooterLinkList.displayName = 'FooterLinkList';

export default FooterLinkList;
