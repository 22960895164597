import { GET, GET_BY_ID } from 'redux/modules/address/actions/types';
import { queryStringToObject } from 'utils/query-string-params';

import address from 'api/definitions/address';
import addressbyid from 'api/definitions/addressbyid';

export const getAddresses =
  ({ triplet = GET.triplet, sortBy = '-created' } = {}) =>
  (dispatch: WtrDispatch) =>
    dispatch({
      types: triplet,
      apiCall: address.get({ sortBy }),
    });

const getAddressByIdAsync = (addressId: string) => ({
  types: GET_BY_ID.triplet,
  apiCall: addressbyid.get({ addressId }),
});

export const getAddressById = (addressId: string) => async (dispatch: WtrDispatch) =>
  dispatch(getAddressByIdAsync(addressId));

const getWCSAddressByIdAsync = (addressId: string, headers: Record<string, string>) => ({
  types: GET_BY_ID.triplet,
  apiCall: addressbyid.get({ addressId, headers }),
});

export const getAdminAddressById = (addressId: string) => async (dispatch: WtrDispatch) => {
  const headers = { 'subject-id': queryStringToObject().userIds };
  return dispatch(getWCSAddressByIdAsync(addressId, headers));
};
