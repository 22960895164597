import { createSelector } from 'reselect';
import { getTrolleyItems, getTrolley } from 'redux/modules/trolley/selectors/get-items';

const getItemId = (state, trolleyItemId) => trolleyItemId;
const getProductId = (state, productId) => productId;
const getLineNumber = (state, lineNumber) => lineNumber;

export const getItemById = createSelector([getTrolleyItems, getItemId], (items, itemId) =>
  items.find((item = {}) => item.trolleyItemId === itemId),
);

export const makeGetItemByLineNumber = () =>
  createSelector([getTrolleyItems, getLineNumber], (items, lineNumber) =>
    items.find((item = {}) => item.lineNumber === lineNumber),
  );

export const getItemByLineNumber = makeGetItemByLineNumber();

export const getLastItemIdByProductId = createSelector(
  [getTrolleyItems, getProductId],
  (items, productId) => {
    const trolleyItem = items.reverse().find(item => item.productId === productId);
    return trolleyItem ? trolleyItem.trolleyItemId : undefined;
  },
);

export const getItemIdByProductId = createSelector(
  [getTrolleyItems, getProductId],
  (items, productId) => {
    const trolleyItem = items.find(item => item.productId === productId);
    return trolleyItem ? trolleyItem.trolleyItemId : undefined;
  },
);

export const getItemLockStatusById = createSelector(
  [getItemById],
  ({ locked = false } = {}) => locked,
);

export const getLineNumberByProductId = createSelector(
  [getTrolleyItems, getProductId],
  (items, productId) => {
    const trolleyItem = items.find(item => item.productId === productId);
    return trolleyItem && trolleyItem.lineNumber;
  },
);

export const makeGetItemIdByLineNumber = () =>
  createSelector([getItemByLineNumber], ({ trolleyItemId } = {}) => trolleyItemId);

export const getItemIdByLineNumber = makeGetItemIdByLineNumber();

export const getLastRemovedTrolleyItem = createSelector(
  [getTrolley],
  ({ lastRemovedTrolleyItem } = {}) => lastRemovedTrolleyItem,
);

export const getLastReaddedTrolleyItem = createSelector(
  [getTrolley],
  ({ lastReaddedTrolleyItem } = {}) => lastReaddedTrolleyItem,
);
