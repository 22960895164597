import React, { Component } from 'react';
import { bool, object, oneOfType, node, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import { refType } from 'constants/types/ref';
import { isInternal } from 'utils/is-internal';
import button from 'components/Button/styles.scss';

class AnchorLink extends Component {
  constructor(props) {
    super(props);
    this.link = React.createRef();
  }

  componentDidMount() {
    const { isFocused } = this.props;
    if (isFocused && this.link && this.link.focus) this.link.focus();
  }

  render() {
    const { children, className, displayAsButton } = this.props;
    const cx = classNames.bind(button);
    const styleNames = cx({
      [className]: !!className,
      primary: displayAsButton && !displayAsButton.secondary,
      secondary: displayAsButton && displayAsButton.secondary,
      blockButton: displayAsButton && displayAsButton.blockButton,
    });

    if (!children) return null;

    // Filter out all props that equal null, as we don't want to pass down such props
    const filteredProps = Object.entries(this.props).reduce((accu, [key, value]) => {
      if (value !== null) accu[key] = value; // eslint-disable-line no-param-reassign
      return accu;
    }, {});

    const {
      children: content,
      className: styles,
      displayAsButton: display,
      href,
      innerRef,
      isFocused,
      replace,
      to,
      inNewTab,
      ...anchorProps
    } = filteredProps;
    const linkProps = {
      ...anchorProps,
      replace: filteredProps.replace,
      to: filteredProps.to,
    };
    if (inNewTab) anchorProps.target = '_blank';
    const url =
      typeof to === 'string'
        ? to
        : `${(to && to.pathname) || ''}${(to && to.search) || ''}${(to && to.hash) || ''}`;

    return isInternal(url) && !inNewTab ? (
      // since React Router 5.1 innerRef is deprecated and we can use normal ref
      // https://v5.reactrouter.com/web/api/Link/innerref-func
      <Link {...linkProps} className={styleNames} ref={innerRef ?? this.link}>
        {children}
      </Link>
    ) : (
      <a {...anchorProps} className={styleNames} href={`${url}`} ref={innerRef}>
        {children}
      </a>
    );
  }
}

AnchorLink.displayName = 'AnchorLink';

AnchorLink.propTypes = {
  children: node.isRequired,
  className: string,
  displayAsButton: oneOfType([bool, shape({ secondary: bool, blockButton: bool })]),
  innerRef: refType,
  isFocused: bool,
  replace: bool,
  to: oneOfType([shape({ pathname: string, search: string, hash: string, state: object }), string]),
  inNewTab: bool,
};

AnchorLink.defaultProps = {
  className: null,
  displayAsButton: false,
  innerRef: null,
  isFocused: false,
  replace: null,
  to: '',
  inNewTab: false,
};

export default AnchorLink;
