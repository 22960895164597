import { REMOVE_PROMPTS_FOR_CONTACT_ADDRESS } from '../actions/types';

const removePromptsForContactAddressRequest = state => ({
  ...state,
  prompts: {
    ...state.prompts,
  },
});

const removePromptsForContactAddressSuccess = state => ({
  ...state,
  prompts: {
    ...state.prompts,
    isPendingContactAddressVerification: false,
  },
});

const removePromptsForContactAddressFailure = state => ({
  ...state,
  prompts: {
    ...state.prompts,
  },
});

export default map => {
  map.set(REMOVE_PROMPTS_FOR_CONTACT_ADDRESS.request, removePromptsForContactAddressRequest);
  map.set(REMOVE_PROMPTS_FOR_CONTACT_ADDRESS.success, removePromptsForContactAddressSuccess);
  map.set(REMOVE_PROMPTS_FOR_CONTACT_ADDRESS.failure, removePromptsForContactAddressFailure);
};
