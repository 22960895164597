import {
  COOKIES_ACCEPT_ALL,
  COOKIES_REJECT_ALL,
  COOKIES_FORCE_SHOW_CONTROLS,
  COOKIES_MONETATE,
  COOKIES_INIT,
  COOKIES_UPDATE_PREFERENCES,
  COOKIES_VIEW_PREFERENCES,
} from 'redux/modules/cookies/actions/types';

import initialState from 'redux/modules/cookies/reducer/initial-state';

export default (state = initialState, action = {}) => {
  const {
    data: {
      analytics = state.analytics,
      consented,
      experimental = state.experimental,
      functional = state.functional,
      marketing = state.marketing,
      returnUrl,
    } = {},
    type,
  } = action;

  switch (type) {
    case COOKIES_ACCEPT_ALL:
      return {
        ...state,
        analytics: true,
        functional: true,
        marketing: true,
        showInitialModal: false,
        showPrivacyControls: false,
      };
    case COOKIES_REJECT_ALL:
      return {
        ...state,
        analytics: false,
        functional: false,
        marketing: false,
        showInitialModal: false,
        showPrivacyControls: false,
      };
    case COOKIES_FORCE_SHOW_CONTROLS:
      return {
        ...state,
        showPrivacyControls: true,
        showInitialModal: false,

        analytics,
        functional,
        marketing,
        returnUrl,
      };
    case COOKIES_INIT:
      return {
        ...state,

        showPrivacyControls: false,
        showInitialModal: !consented,

        analytics,
        functional,
        marketing,
      };
    case COOKIES_MONETATE:
      return {
        ...state,
        experimental,
      };
    case COOKIES_UPDATE_PREFERENCES:
      return {
        ...state,
        showInitialModal: false,
        showPrivacyControls: false,

        analytics,
        functional,
        marketing,
      };
    case COOKIES_VIEW_PREFERENCES:
      return {
        ...state,
        showInitialModal: false,
        showPrivacyControls: true,
      };
    default:
      return state;
  }
};
