import get from 'lodash/get';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { createSelector } from 'reselect';

const getTrolleyInstantCheckout = state => get(state, 'trolley.instantCheckout');

export const getInstantCheckoutLocation = state =>
  get(state, 'trolley.instantCheckoutLocation') || '';

export const canInstantCheckout = createSelector(
  [getTrolleyInstantCheckout],
  instantCheckoutValue => instantCheckoutValue === 'ALLOWED',
);

export const getInstantCheckoutReason = createSelector(
  [amendingOrder, getTrolleyInstantCheckout],
  (amending, instantCheckoutValue) =>
    (amending ? instantCheckoutValue : 'not_amending')?.toLowerCase(),
);
