import { isGroceriesOrSimilar } from 'constants/productTypes';
import { getProductById } from 'redux/modules/entities/selectors/products';

export const groceriesItemInTrolley = state => {
  const myTrolleyItems = state.trolley.trolleyItems.map(trolleyItems =>
    isGroceriesOrSimilar((getProductById(state, trolleyItems.productId) || {}).productType),
  );

  return myTrolleyItems.some(groceryItem => groceryItem === true);
};
