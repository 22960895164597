import { apiCallback } from 'api';

const definition = {
  service: 'address',
  endpoint: 'addresses/${addressId}',
  verbs: ['get', 'delete', 'options', 'put'],
  defaultVersion: 2,
};

export default {
  get: apiCallback(definition, 'get'),
  delete: apiCallback(definition, 'delete'),
  options: apiCallback(definition, 'options'),
  put: apiCallback(definition, 'put'),
};
