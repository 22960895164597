import { searchValueSelector } from './index';

const RESULTSET = 'RESULT_SET';

export const getAddressSearchResults = state => {
  const searchValues = searchValueSelector(state);
  if (!searchValues || searchValues.length === 0) {
    return searchValues;
  }

  const mappedSeachValues = searchValues.map(({ id, label, description, type } = {}) => ({
    id,
    type,
    name: `${type === RESULTSET ? `${label}` : `${label}, ${description}`}`,
    extraData: type === RESULTSET ? description : '',
  }));

  const resultSets = mappedSeachValues.filter(item => item.extraData);
  const results = mappedSeachValues.filter(item => !item.extraData);
  return resultSets.concat(results);
};
