import React, { memo } from 'react';
import loadable from '@loadable/component';
import {
  Container as ContentLibraryContainer,
  ContainerProps,
} from '@johnlewispartnership/wtr-content-component-library';

const Poll = loadable(() => import('components/Poll'));

const POLL_ELEMENT_ID_PREFIX = 'peak-poll-component';

const isPollComponent = (elementId?: string) =>
  elementId?.startsWith(POLL_ELEMENT_ID_PREFIX) ?? false;

const withContainerReplacements = (ContainerComponent: typeof ContentLibraryContainer) => {
  const ContainerWrapper = memo((props: ContainerProps) => {
    if (isPollComponent(props.elementId)) {
      return <Poll {...props} />;
    }

    return <ContainerComponent {...props} />;
  });

  return ContainerWrapper;
};

export default withContainerReplacements;
