import {
  CLEAR_CHOSEN_ADDRESS,
  SET_CONTACT_ADDRESS,
  SET_SELECTED_ADDRESS,
  SET_CHOOSEN_ADDRESS,
} from 'redux/modules/address/actions/types';
import { buildAddressModel } from '../util';

const setSelectedAddress = (state, action) => {
  const { selectedAddress } = action;
  return {
    ...state,
    selectedAddress,
  };
};

const updateAddresses = (addresses, address) => {
  const { id = null, isContactAddress = null } = address;

  if (id && isContactAddress) {
    return [
      { ...address, selected: true },
      ...addresses
        .filter(a => a.id !== id && a.isContactAddress !== true)
        .map(a => ({ ...a, selected: false })),
    ];
  }

  return id
    ? [
        ...(addresses.some(add => add.isContactAddress === true)
          ? [
              ...addresses
                .filter(a => a.isContactAddress === true)
                .map(a => ({ ...a, selected: false })),
            ]
          : []),
        { ...address, selected: true },
        ...addresses
          .filter(a => a.isContactAddress !== true && a.id !== address.id)
          .map(a => ({ ...a, selected: false })),
      ]
    : addresses.map(a => ({ ...a, selected: false }));
};

const updateContactAddress = (addresses, address) => {
  const { id = null } = address || {};

  return id
    ? [
        { ...address, isContactAddress: true },
        ...addresses.filter(a => a.id !== id).map(a => ({ ...a, isContactAddress: false })),
      ]
    : addresses.map(a => ({ ...a, isContactAddress: a.isContactAddress ?? false }));
};

const setChoosenAddress = (state, action) => {
  const { address } = action;
  const { chooseAddressModal, addresses = [] } = state;

  if (address) {
    const { choosenPosition = 0 } = address;
    return {
      ...state,
      addresses: updateAddresses(addresses, address),
      chooseAddressModal: {
        ...chooseAddressModal,
        choosenPosition,
        address: buildAddressModel(address),
        updated: true,
      },
      displayedAddressId: address ? address.id : '',
    };
  }
  return {
    ...state,
    addresses: updateAddresses(addresses, {}),
    chooseAddressModal: {
      ...chooseAddressModal,
      address: null,
      updated: true,
    },
  };
};

const clearChosenAddress = state => ({
  ...state,
  addresses: updateAddresses(state.addresses, {}),
  chooseAddressModal: {
    address: null,
    updated: false,
  },
});

const setContactAddress = (state, action) => {
  const { address } = action;
  const { addresses = [] } = state;
  return {
    ...state,
    addresses: updateContactAddress(addresses, address),
  };
};

export { updateAddresses, updateContactAddress };

export default map => {
  map.set(SET_SELECTED_ADDRESS, setSelectedAddress);
  map.set(SET_CHOOSEN_ADDRESS, setChoosenAddress);
  map.set(CLEAR_CHOSEN_ADDRESS, clearChosenAddress);
  map.set(SET_CONTACT_ADDRESS, setContactAddress);
  return map;
};
