export function makeObserver(observerOptions) {
  let observer;
  const elements = new Map();

  function observe(el, onIntersecting, options = { once: true }) {
    if (!observer && window && typeof window.IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver((entries, o) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const { cb, once } = elements.get(entry.target) || {};
            if (once) o.unobserve(entry.target);
            if (cb) cb(entry.target, o);
          }
        });
      }, observerOptions);
    }
    if (observer && el && onIntersecting) {
      if (!elements.has(el)) observer.observe(el);
      elements.set(el, { cb: onIntersecting, ...options });
    }
  }

  function unobserve(el) {
    if (observer && el) {
      observer.unobserve(el);
      elements.delete(el);
    }
  }

  function getElements() {
    return elements;
  }

  return { observe, unobserve, getElements };
}

export const podObserver = makeObserver({ threshold: [0.5] });
