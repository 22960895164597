import { createSelector } from 'reselect';

import { CHANGE_QUANTITY, HARD_TYPE, SOFT_TYPE } from 'constants/restrictions';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';
import { getTrolleyItemFromGroups } from 'utils/get-trolley-items-from-groups';
import { getProductById, getProducts } from 'redux/modules/entities/selectors/products';
import { isItemSwappable } from '../utils';

const EMPTY_OBJECT = {};

export const getConflicts = ({ conflicts } = {}) => conflicts;

const getId = (_state, _entityType, id) => id;
const getProductId = (_state, productId) => productId;
const getTrolleyItemId = (_state, trolleyItemId) => trolleyItemId;

const getProductConflicts = createSelector(getConflicts, ({ products } = {}) => products);
export const getTrolleyConflicts = createSelector(getConflicts, ({ trolley } = {}) => trolley);

const getConflictsOfType = conflictType =>
  createSelector(getTrolleyConflicts, (trolleyConflicts = {}) =>
    Object.keys(trolleyConflicts).reduce((conflicts, key) => {
      const conflict = trolleyConflicts[key] || {};
      const { type } = conflict;
      const updatedConflicts = conflicts;

      if (type === conflictType) {
        updatedConflicts[key] = conflict;
      }

      return updatedConflicts;
    }, {}),
  );

export const getTrolleyHardConflicts = getConflictsOfType(HARD_TYPE);

export const getTrolleySoftConflicts = getConflictsOfType(SOFT_TYPE);

export const getTrolleyConflictById = createSelector(
  [getTrolleyConflicts, getTrolleyItemId],
  (conflicts = {}, trolleyItemId) => conflicts[trolleyItemId],
);

export const getTrolleyHardConflictById = createSelector(
  [getTrolleyHardConflicts, getTrolleyItemId],
  (conflicts = {}, trolleyItemId) => conflicts[trolleyItemId],
);

export const getTrolleyConflictsPriorityOrder = createSelector(getTrolleyHardConflicts, items =>
  Object.keys(items).sort((itemA, itemB) => items[itemA].priority - items[itemB].priority),
);

export const groupTrolleyConflictsByPriorityReason = createSelector(
  [getTrolleyHardConflicts, getTrolleyConflictsPriorityOrder],
  (conflicts = {}, order = []) =>
    order.reduce((reasons, key) => {
      const { itemId, messages: { longGroup, longItem } = {}, productId } = conflicts[key] || {};
      const message = longGroup || longItem || 'Unavailable Item(s)';
      const updatedReasons = reasons;

      if (!updatedReasons[message]) {
        updatedReasons[message] = [];
      }

      updatedReasons[message].push({
        trolleyItemId: itemId,
        lineNumber: extractLineNumberFromSku(productId),
      });

      return updatedReasons;
    }, {}),
);

export const getTrolleyHardConflictsLength = createSelector(
  getTrolleyHardConflicts,
  (hardTrolleyConflicts = {}) => Object.keys(hardTrolleyConflicts).length,
);

export const getSwappableItemsLength = createSelector(
  [getTrolleyHardConflicts, getProducts],
  (hardTrolleyConflicts = {}, products) => {
    const filteredConflict = Object.keys(hardTrolleyConflicts).filter(key => {
      const { productId } = hardTrolleyConflicts[key];
      const lineNumber = extractLineNumberFromSku(productId);
      const product = products[lineNumber] || {};

      return !product?.hfss?.status && isItemSwappable(hardTrolleyConflicts, key);
    });
    return filteredConflict.length;
  },
);

export const hasAnyHardTrolleyConflicts = createSelector(
  getTrolleyHardConflictsLength,
  (hardTrolleyConflictsLength = {}) => hardTrolleyConflictsLength > 0,
);

const getAllConflicts = (state, entityType) => {
  switch (entityType) {
    case 'products': {
      return getProductConflicts(state);
    }
    case 'trolley': {
      return getTrolleyConflicts(state);
    }
    default: {
      return EMPTY_OBJECT;
    }
  }
};

export const hasSoftTrolleyConflict = createSelector(
  [getTrolleySoftConflicts, getTrolleyItemId],
  (softConflicts, trolleyItemId) => !!softConflicts[trolleyItemId],
);

export const isItemEditable = createSelector([getAllConflicts, getId], (conflicts = {}, id) => {
  const conflict = conflicts[id];

  if (!conflict) return true;

  const { prohibitedActions } = conflict;

  if (prohibitedActions && prohibitedActions.includes(CHANGE_QUANTITY)) return false;

  return true;
});

export const getNextConflictTrolleyItem = createSelector(
  [
    groupTrolleyConflictsByPriorityReason,
    getTrolleyHardConflicts,
    getProducts,
    (state, lineNumber) => lineNumber,
  ],
  (sortedConflicts = {}, conflicts, products, lineNumber) => {
    const trolleyItems = getTrolleyItemFromGroups(sortedConflicts);
    const nonHfssTrolleyItems = trolleyItems.filter(
      ({ lineNumber: conflcitItemLineNumber, trolleyItemId }) => {
        const product = products[conflcitItemLineNumber] || {};
        return !product?.hfss?.status && isItemSwappable(conflicts, trolleyItemId);
      },
    );
    const index = nonHfssTrolleyItems.findIndex(item => item.lineNumber === lineNumber);
    if (index < 0) return undefined;
    if (index === nonHfssTrolleyItems.length - 1)
      return {
        lineNumber: nonHfssTrolleyItems[0].lineNumber,
        trolleyItemId: nonHfssTrolleyItems[0].trolleyItemId,
      };
    return {
      lineNumber: nonHfssTrolleyItems[index + 1].lineNumber,
      trolleyItemId: nonHfssTrolleyItems[index + 1].trolleyItemId,
    };
  },
);

export const makeGetProductConflictById = () =>
  createSelector(
    [getProductConflicts, getProductId],
    (conflicts = {}, productId) => conflicts[extractLineNumberFromSku(productId)],
  );

export const getProductConflictById = makeGetProductConflictById();

export const isOutOfStock = createSelector(
  getProductConflictById,
  ({ outOfStock = false } = {}) => outOfStock,
);

export const makeTrolleyControlsDisabled = () =>
  createSelector(
    [getConflicts, getProductId, getProductById],
    (conflicts, productId, { currentSaleUnitPrice } = {}) =>
      !isItemEditable({ conflicts }, 'products', productId) || !currentSaleUnitPrice,
  );

export const trolleyControlsDisabled = makeTrolleyControlsDisabled();
