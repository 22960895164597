import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const nameSpace = 'waitrose/order/';

export const ACKNOWLEDGE_SERVER_ERRORS = `${nameSpace}ACKNOWLEDGE_SERVER_ERRORS` as const;
export const ADD_GIFT_CARD = apiPromiseTypes('add-gift-card', 'post');
export const ADD_GIFT_VOUCHER = apiPromiseTypes('add-gift-voucher', 'post');
export const ADD_ORDER_TO_CALENDAR = `${nameSpace}ADD_ORDER_TO_CALENDAR` as const;
export const ADD_PARTNER_DISCOUNT = apiPromiseTypes('partner-discount-card', 'put');
export const ADD_PROMO_CODE = apiPromiseTypes('incentives', 'post');
export const CANCEL_REMOVE_GIFT_CARD = `${nameSpace}CANCEL_REMOVE_GIFT_CARD` as const;
export const CANCEL_REMOVE_GIFT_VOUCHER = `${nameSpace}CANCEL_REMOVE_GIFT_VOUCHER` as const;
export const CLEAR_PROMO_CODE_ERROR = `${nameSpace}CLEAR_PROMO_CODE_ERROR` as const;
export const GET_ORDER = apiPromiseTypes('get-order', 'get');
export const INITIALISE_ORDER = apiPromiseTypes('initialise-order', 'patch');
export const REMOVE_GIFT_CARD = apiPromiseTypes('remove-gift-card', 'post');
export const REMOVE_GIFT_VOUCHER = apiPromiseTypes('remove-gift-voucher', 'post');
export const REMOVE_PARTNER_DISCOUNT = apiPromiseTypes('partner-discount-card', 'delete');
export const REMOVE_PROMO_CODE = apiPromiseTypes('incentives', 'delete');
export const SET_GIFT_CARD_TO_REMOVE = `${nameSpace}SET_GIFT_CARD_TO_REMOVE` as const;
export const SET_GIFT_VOUCHER_TO_REMOVE = `${nameSpace}SET_GIFT_VOUCHER_TO_REMOVE` as const;
export const NOTIFY_REVIEW_ORDER = `${nameSpace}NOTIFY_REVIEW_ORDER` as const;
