import { log } from 'utils/error-logging';
import { handleGenericNetworkError } from 'api/actions/handle-generic-network-error';
import { getHandlers } from 'api/status';
import { ApiCallArgs, ApiCallOverrides, ApiDefinition, ApiToCallFn, Verb } from 'api/types';
import { ApiError } from 'constants/errors';
import { WtrGetState } from 'redux/create-store';

export const handleStatusCodes =
  ({
    api,
    verb,
    args,
    apiOverrides,
    apiToCall,
    dispatch,
    getState,
  }: {
    api: ApiDefinition;
    verb: Verb;
    args: ApiCallArgs;
    apiOverrides: ApiCallOverrides;
    apiToCall: ApiToCallFn;
    dispatch: WtrDispatch;
    getState: WtrGetState;
  }) =>
  (err: ApiError) => {
    if (
      args?.iHandleStatusCodes &&
      (args.iHandleStatusCodes === true ||
        (typeof err.status === 'number' && args.iHandleStatusCodes.includes(err.status)))
    ) {
      throw err;
    }

    const { handleTimeout } = apiOverrides?.timeout ?? api?.timeout ?? {};

    if (err?.timeout && handleTimeout) {
      throw err;
    }

    const handlers = getHandlers();

    if (typeof err.status === 'number' && handlers[err.status]) {
      return handlers[err.status]({
        api,
        verb,
        args,
        apiToCall,
        dispatch,
        getState,
        err,
      });
    }

    handleGenericNetworkError({ dispatch, error: err, shouldLog: false });

    if (err.status && ![401, 409].includes(err.status)) {
      err.message ||= `HTTP error`; // eslint-disable-line no-param-reassign
      log(err, { logger: 'handle-status-codes', section: 'unhandled-status-code' });
    } else if (err.timeout) {
      log(err, { logger: 'handle-status-codes', section: 'unhandled-timeout' });
    } else if (typeof err.status === 'undefined') {
      log(err, { logger: 'handle-status-codes' });
    }

    throw err;
  };
