import {
  RESET_ADDRESS_SUGGESTION,
  RESET_ADDRESS_SUGGESTION_NOT_ADDRESS,
} from 'redux/modules/address-suggestion/actions/types';
import initialState from 'redux/modules/address-suggestion/reducers/initial-state';

const resetAddressSuggestionNotAddress = state => {
  const {
    searchParam,
    searchValues,
    searchLoading,
    getAddressLoading,
    error,
    modifiedFields,
    navigation,
  } = initialState;

  return {
    ...state,
    searchParam,
    searchValues,
    searchLoading,
    getAddressLoading,
    error,
    modifiedFields,
    navigation,
  };
};

const resetAddressSuggestion = () => ({
  ...initialState,
});

export default map => {
  map.set(RESET_ADDRESS_SUGGESTION, resetAddressSuggestion);
  map.set(RESET_ADDRESS_SUGGESTION_NOT_ADDRESS, resetAddressSuggestionNotAddress);
  return map;
};
