import { compose } from 'redux';

const convertToNumber = price => (price ? Number(price) : 0);

const removeGBPFromPrice = price => (price ? price.replace('£', '') : price);

const removePenniesFromPrice = (price = '') =>
  price.indexOf('p') > -1 ? String(parseInt(price.replace('p', ''), 10) / 100) : price;

export const removeCurrencyCharacters = price => removeGBPFromPrice(removePenniesFromPrice(price));

export const convertDisplayPriceToNumber = compose(
  convertToNumber,
  removeGBPFromPrice,
  removePenniesFromPrice,
);
