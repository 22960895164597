import root from 'window-or-global';
import { getFeatureFlags } from 'utils/feature-flags';
import { storageAvailable } from 'utils/storage-available';

export const SEARCH_HISTORY_NAME = 'wtr_search_history';
const LIMIT = 60;

const decorate = fn => (arg, searchType) =>
  fn(arg, `wtr_${searchType ? `${searchType}_` : ''}search_history`);

export const getSavedSearchTerms = decorate((quantity, searchName) => {
  if (!storageAvailable('localStorage') || !getFeatureFlags().searchHistory) return [];

  try {
    const parsedValue = JSON.parse(root.localStorage.getItem(searchName));
    if (!parsedValue || !Array.isArray(parsedValue)) {
      throw new Error('Not a valid search history value');
    }
    return parsedValue.slice(0, quantity).map(({ t }) => t);
  } catch (e) {
    root.localStorage.removeItem(searchName);
  }

  return [];
});

export const saveSearchTerm = decorate((term, searchName) => {
  if (!storageAvailable('localStorage') || !getFeatureFlags().searchHistory || !term.length) return;

  let searchHistory = [];
  try {
    const parsedValue = JSON.parse(root.localStorage.getItem(searchName));
    if (parsedValue) {
      if (!parsedValue || !Array.isArray(parsedValue)) {
        throw new Error('Not a valid search history value');
      }

      searchHistory = [...parsedValue];

      searchHistory = searchHistory.filter(({ t }) => `${t}`.toLowerCase() !== term.toLowerCase());

      if (searchHistory.length >= LIMIT) {
        searchHistory = searchHistory.slice(0, LIMIT - 1);
      }
    }
  } catch (e) {
    root.localStorage.removeItem(searchName);
  }

  searchHistory.unshift({ t: term });

  root.localStorage.setItem(searchName, JSON.stringify(searchHistory));
});

export const removeSavedSearchTerm = decorate((term, searchName) => {
  if (!storageAvailable('localStorage')) return;

  try {
    const parsedValue = JSON.parse(root.localStorage.getItem(searchName));
    if (!parsedValue || !Array.isArray(parsedValue)) {
      throw new Error('Not a valid search history value');
    }
    root.localStorage.setItem(
      searchName,
      JSON.stringify(parsedValue.filter(({ t }) => t !== term)),
    );
  } catch (e) {
    root.localStorage.removeItem(searchName);
  }
});

export const deleteSearchHistory = searchType => {
  decorate((_, searchName) => {
    root.localStorage.removeItem(searchName);
  })(null, searchType);
};
