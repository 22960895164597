import platform from 'platform';
import { asyncLocalStorage } from 'server/utils/async-local-storage';

let userAgentCache: typeof platform;

/**
 * @return platform object
 */
export const userAgent = (): typeof platform => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    const rawUserAgent = requestContext?.get('userAgent') ?? '';

    return platform.parse(rawUserAgent);
  }

  if (typeof userAgentCache !== 'undefined') {
    return userAgentCache;
  }

  const rawUserAgent = navigator.userAgent;
  userAgentCache = platform.parse(rawUserAgent);

  return userAgentCache;
};
