import PropTypes from 'prop-types';

export default () =>
  PropTypes.shape({
    accessibleText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    theme: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string,
  });
