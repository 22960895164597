import addressbyid from 'api/definitions/addressbyid';
import { OPTIONS_BY_ID } from 'redux/modules/address/actions/types';
import { showEditDeleteSpinner } from 'redux/modules/address/actions';

const canActionOnAddressIdAsync = id => ({
  types: OPTIONS_BY_ID.triplet,
  apiCall: addressbyid.options({
    addressId: id,
    iHandleStatusCodes: [400, 401, 403, 423, 500, 503],
  }),
  id,
});

export const canActionOnAddress = address => async dispatch => {
  const { id } = address;
  await dispatch(showEditDeleteSpinner(id));
  return dispatch(canActionOnAddressIdAsync(id));
};
