import setBrowsePageDepth from 'route-actions/browse-page-depth';
import determineTrolleyContents from 'route-actions/determine-trolley-contents-change-route';

// The contract for an "action" method is as follows:
//
// - it receives the react-router location object and store and calls side effect based on the passed params
// - the return value is not importantl; it is called just for its side effects

// IMPORTANT: do not call "action" during render
// If you need to do it use "action factory" (see action-factories.js)

export default [setBrowsePageDepth, determineTrolleyContents];
