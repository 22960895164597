import myWaitroseByCard from 'api/definitions/mywaitrosebycard';

import { PUT_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { setAllMarketingConsents } from 'redux/modules/marketing-preferences/actions/set-marketing-consents';
import { updateContactAddressAsync } from 'redux/modules/address/actions';
import { getContactAddressId, getAddressChosenFromModal } from 'redux/modules/address/selectors';

import historyUtils from 'utils/history';
import locator from 'utils/locator';
import urls from 'constants/urls';

export const linkMyWaitroseCard =
  ({
    myWaitroseCardNumber,
    mywaitrose,
  }: {
    myWaitroseCardNumber?: string;
    mywaitrose: boolean;
  }): WtrThunkAction<Promise<unknown>> =>
  async (dispatch, getState) => {
    const contactAddressId = getContactAddressId(getState());
    const addressChosenFromModal = getAddressChosenFromModal(getState());

    if (!myWaitroseCardNumber || !contactAddressId) {
      return Promise.resolve();
    }

    if (addressChosenFromModal) {
      await dispatch(updateContactAddressAsync({ contactAddressId }));
    }

    await dispatch(setAllMarketingConsents({ mywaitrose }));

    await dispatch({
      types: PUT_MYWAITROSE_CARD.triplet,
      apiCall: myWaitroseByCard.put({
        cardNumber: myWaitroseCardNumber,
        iHandleStatusCodes: [400, 409, 410],
      }),
    });

    historyUtils.replace(`${urls.linkMyWaitroseSuccess}${locator.search}`);

    return Promise.resolve();
  };
