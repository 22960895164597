import { getCustomerId } from 'redux/modules/sessions/selectors';

const signnedOnAsAnonymousCustomer = () => ({
  event: 'sign_on_as_anonymous_customer',
});

const signnedOnAsAnonymousCustomerCreated = state => ({
  event: 'sign_on_as_anonymous_customer_customer_created',
  customerId: getCustomerId(state),
});

export { signnedOnAsAnonymousCustomer, signnedOnAsAnonymousCustomerCreated };
