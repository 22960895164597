import { ADD_ALL_ITEMS_FULFILLED } from 'redux/modules/add-all-items/actions/types';
import {
  GET_SUBSTITUTION_PRODUCTS_SUCCESS,
  FILTER_TROLLEY_ANALYTICS,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS,
  SUB_ALL_UPDATED,
  TRACK_VIEW_TROLLEY,
  TROLLEY_EMPTIED,
  TROLLEY_QUEUE_ITEM_UPDATED,
  UNDO_REMOVE_TROLLEY_ITEM_SUCCESS,
} from 'redux/modules/trolley/actions/types';

import addAllToCart from './add-all-to-cart';
import filterTrolley from './filter-trolley';
import removeMultipleFromCart from './remove-multiple-from-cart';
import substitutionsLoaded from './substitutions-loaded';
import toggleSubAll from './toggle-sub-all';
import undoRemove from './undo-remove-item-from-trolley';
import updateCart from './update-cart';
import viewCart from './view-cart';

export default {
  [ADD_ALL_ITEMS_FULFILLED]: addAllToCart,
  [GET_SUBSTITUTION_PRODUCTS_SUCCESS]: substitutionsLoaded,
  [FILTER_TROLLEY_ANALYTICS]: filterTrolley,
  [REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS]: removeMultipleFromCart,
  [SUB_ALL_UPDATED]: toggleSubAll,
  [TRACK_VIEW_TROLLEY]: viewCart,
  [TROLLEY_EMPTIED]: removeMultipleFromCart,
  [TROLLEY_QUEUE_ITEM_UPDATED]: updateCart,
  [UNDO_REMOVE_TROLLEY_ITEM_SUCCESS]: undoRemove,
};
