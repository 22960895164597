import root from 'window-or-global';

import all from './all.env';

const envSpecificVariables = __SERVER__
  ? require(`./environments/${process.env.ENVIRONMENT}`) // eslint-disable-line
  : root.environment || {};

const defaultFeatures = Object.freeze(
  Object.assign({}, all.features, envSpecificVariables.features || {}),
);

const env = Object.assign({}, all, envSpecificVariables);

// removing `features` prop from the exported object
// as it should be only available through the `getFeatures` redux selector
delete env.features;

root.defaultFeatures = defaultFeatures;

// Temporary Clip-to-Card customer IDs for pilot testing
if (__SERVER__) {
  root.clipToCardPilotCustomerIds = env.clipToCardPilotCustomerIds || [];
}
delete env.clipToCardPilotCustomerIds;

export default env;
