import { DELETE_BY_ID, REPORT_DELETE_SUCCESS } from 'redux/modules/address/actions/types';
import addressbyid from 'api/definitions/addressbyid';
import { filterSearchQuery, queryStringToObject } from 'utils/query-string-params';

const deleteAddressByIdAsync = id => ({
  types: DELETE_BY_ID.triplet,
  apiCall: addressbyid.delete({
    addressId: id,
    iHandleStatusCodes: [400, 401, 403, 404, 423, 500, 503],
  }),
  id,
});

const deleteWCSAddress = (id, headers) => ({
  types: DELETE_BY_ID.triplet,
  apiCall: addressbyid.delete({ addressId: id, headers }),
  id,
});

export const deleteAddress = address => async dispatch => {
  const { id } = address;
  return dispatch(deleteAddressByIdAsync(id));
};

export const deleteAdminAddress = address => async dispatch => {
  const { id } = address;
  const headers = { 'subject-id': queryStringToObject(filterSearchQuery()).userIds };
  return dispatch(deleteWCSAddress(id, headers));
};

export const reportDeleteSuccess = () => ({
  type: REPORT_DELETE_SUCCESS,
});
