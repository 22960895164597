import { PUT_MYWAITROSE_CARD } from 'redux/modules/mywaitrose/actions/types';
import { MyWaitroseState } from 'redux/modules/mywaitrose/state';

import { ApiError } from 'constants/errors';

const linkMyWaitroseCardRequest = (state: MyWaitroseState) => ({
  ...state,
  request: {
    isLoading: true,
    error: null,
  },
});

const linkMyWaitroseCardSuccess = (state: MyWaitroseState) => ({
  ...state,
  request: {
    isLoading: false,
    error: null,
  },
});

const linkMyWaitroseCardFailure = (state: MyWaitroseState, action: { payload: ApiError }) => ({
  ...state,
  request: {
    isLoading: false,
    error: action.payload,
  },
});

export default () =>
  [
    [PUT_MYWAITROSE_CARD.request, linkMyWaitroseCardRequest],
    [PUT_MYWAITROSE_CARD.success, linkMyWaitroseCardSuccess],
    [PUT_MYWAITROSE_CARD.failure, linkMyWaitroseCardFailure],
  ] as const;
