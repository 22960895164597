import { apiCallback } from 'api';

const definition = {
  service: 'recipes',
  endpoint: 'recipes/${recipeId}',
  verbs: ['get'],
  defaultVersion: 1,
};

const definitionSummary = {
  service: 'recipes',
  endpoint: 'recipes/summary',
  verbs: ['post'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(definition, 'get'),
  post: apiCallback(definitionSummary, 'post'),
};
