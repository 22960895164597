const reducer = (state: WtrState) => ({
  ...state,
  loading: true,
  campaigns: {},
  error: null,
  showPriorityOnlyMessage: false,
  hasCampaignDatesOnly: false,
});

export default reducer;
