import { deepFreeze } from 'utils/deepFreeze';
import { Action } from 'redux';
import { SET_SCROLLED_LINE_NUMBER } from '../actions/types';

export const initialState = deepFreeze({
  lineNumber: null,
});

type SetScrolledLineNumberAction = Action & {
  result?: string;
};

export default function scrollSessionStorage(
  state = initialState,
  action: SetScrolledLineNumberAction = { type: '' },
) {
  switch (action.type) {
    case SET_SCROLLED_LINE_NUMBER:
      return {
        ...state,
        lineNumber: action.result,
      };
    default:
      return state;
  }
}
