import { createSelector } from 'reselect';

import { SlotTypes } from 'constants/fulfilmentTypes';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { NEW_ADDRESS_ID } from 'redux/modules/customer-slot/constants';
import { SlotGridType } from 'api/definitions/slotdates/types';

export const customerSlotSelector = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerSlot');

export const getSlotType = createSelector(
  customerSlotSelector,
  customerSlot => customerSlot.slotType,
);

export const hasBookedSlot = (state: WtrState) =>
  !!state.customerSlot &&
  !!state.customerSlot.slotDate &&
  state.customerSlot.slotType !== SlotTypes.UNDEFINED;

export const getCustomerSlotAddressId = (state: WtrState) =>
  state && state.customerSlot && state.customerSlot.addressId;

export const isBookedSlotADeliverySlot = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerSlot').slotType === SlotTypes.DELIVERY;

export const isBookedSlotACollectionSlot = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerSlot').slotType.includes('COLLECTION');

export const isBookedSlotAnEntertainingCollectionSlot = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerSlot').slotType === SlotTypes.ENTERTAINING_COLLECTION;

export const isBookedSlotASaverSlotSelector = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerSlot').slotGridType === SlotGridType.SAVER_GRID;

export const isBookedSlotForNewAddress = (state: WtrState) =>
  getCustomerSlotAddressId(state) === NEW_ADDRESS_ID;

export const getCustomerSlotBranchId = createSelector(
  [customerSlotSelector, hasBookedSlot],
  (customerSlot, customerHasSlot) => (customerHasSlot ? customerSlot.branchId : undefined),
);

export const getCustomerSlotDate = createSelector(
  [customerSlotSelector, hasBookedSlot],
  (customerSlot, customerHasSlot) => (customerHasSlot ? customerSlot.slotDate : undefined),
);

export const getCustomerSlotSubsitutionPref = createSelector(
  [customerSlotSelector],
  ({ substitutionPref = false }) => substitutionPref,
);

export const getCustomerSlotAmendOrderCutOff = createSelector(
  customerSlotSelector,
  ({ amendOrderCutOff }) => amendOrderCutOff,
);

export const getSlotReservationId = createSelector(
  customerSlotSelector,
  ({ slotReservationId }) => slotReservationId,
);

export const isCustomerSlotLoading = createSelector(
  customerSlotSelector,
  customerSlot => customerSlot.loading,
);

export const hasCustomerSlotInitialLoadCompleted = createSelector(
  customerSlotSelector,
  customerSlot => customerSlot.initialLoadComplete,
);
