import {
  ANDROID_APP_STORE_CLICKED,
  APPLE_APP_STORE_CLICKED,
  APPLE_WALLET_BUTTON_CLICKED,
} from 'redux/modules/mywaitrose/actions/types';

export const reportAndroidAppStoreClicked = () => ({
  type: ANDROID_APP_STORE_CLICKED,
});

export const reportAppleAppStoreClicked = () => ({
  type: APPLE_APP_STORE_CLICKED,
});

export const reportAppleWalletButtonClicked = () => ({
  type: APPLE_WALLET_BUTTON_CLICKED,
});
