import { apiCallback } from 'api';
import query from 'api/definitions/minimumOrderValues/minimumOrderValues.graphql';
import env from 'env/env';

const definition = {
  graph: true,
  query: () => query,
  timeout: { ...(env?.timeouts?.minimumOrderValues ?? {}), handleTimeout: true },
  queryString: { tag: 'get-minimum-order' },
};

export default {
  get: apiCallback(definition, 'post'),
};
