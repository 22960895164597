import { getCustomerId } from 'redux/modules/sessions/selectors';
import { getAddressSelectedPosition, edittedAddressFields } from 'redux/modules/address/selectors';

const addAddressShown = state => ({
  event: 'add_address_loaded',
  customerId: getCustomerId(state),
});

const addAddressComplete = state => ({
  event: 'add_address_complete',
  customerId: getCustomerId(state),
});

const addAddressCancelled = state => ({
  event: 'add_address_cancelled',
  customerId: getCustomerId(state),
});

const editAddressShown = state => ({
  event: 'edit_address_loaded',
  customerId: getCustomerId(state),
});

const editAddressComplete = state => ({
  event: 'edit_address_complete',
  customerId: getCustomerId(state),
  editedFields: edittedAddressFields(state),
});

const editAddressCancelled = state => ({
  event: 'edit_address_cancelled',
  customerId: getCustomerId(state),
});

const chooseAddressShown = state => ({
  event: 'choose_address_loaded',
  customerId: getCustomerId(state),
});

const chooseAddressCompleted = state => ({
  event: 'choose_address_success',
  customerId: getCustomerId(state),
  addressSelectedPositionInList: getAddressSelectedPosition(state),
});

const chooseAddressCancelled = state => ({
  event: 'choose_address_cancelled',
  customerId: getCustomerId(state),
});

export {
  addAddressShown,
  addAddressComplete,
  addAddressCancelled,
  editAddressShown,
  editAddressComplete,
  editAddressCancelled,
  chooseAddressShown,
  chooseAddressCompleted,
  chooseAddressCancelled,
};
