import { createSelector } from 'reselect';

import { FORMAT, dayjs } from 'utils/date';

export const getSlotExpiryTime = (state: WtrState) => state.customerSlot?.slotExpiryTime;

export const getFormattedExpiryTime = createSelector(
  [getSlotExpiryTime],
  expiryTime => expiryTime && dayjs(expiryTime).format(FORMAT.HOUR_MINUTE_AMPM),
);

export const getTimeTillSlotExpiry = (state: WtrState) => {
  const slotExpiryDateTime = getSlotExpiryTime(state);

  if (!slotExpiryDateTime) {
    return null;
  }

  const expiry = dayjs(slotExpiryDateTime).tz();
  const ukNow = dayjs.tz();

  const difference = expiry.diff(ukNow);

  return difference;
};

export const getFormattedTimeRemaining = createSelector([getTimeTillSlotExpiry], timeTillExpiry => {
  if (!timeTillExpiry) {
    return `0m`;
  }

  const timeRemaining = dayjs.duration(timeTillExpiry);
  const hoursRemaining = timeRemaining.hours();
  return hoursRemaining > 0
    ? `${hoursRemaining}h ${timeRemaining.minutes()}m`
    : `${timeRemaining.minutes()}m`;
});
