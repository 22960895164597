import experienceFragment from 'api/definitions/experience-fragment';
import { log } from 'utils/error-logging';
import { RetryHandler } from 'api/client/types';
import { EXPERIENCE_FRAGMENT_SCHEMA } from 'redux/schemas';
import {
  EXPERIENCE_FRAGMENT_SUBMIT,
  EXPERIENCE_FRAGMENT_SUBMIT_FAIL,
  EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS,
} from './types';

const getExperienceFragment =
  (
    experienceFragmentKey: string,
    customerId: string,
    applyColumnTransformation = false,
    numberOfRetries = 0,
    cache = false,
  ) =>
  async (dispatch: WtrDispatch) => {
    const retryHandler: RetryHandler = error => {
      if (!error || error.status === 404) {
        return false;
      }
      return true;
    };

    const experienceFragmentRequestType = cache
      ? experienceFragment.cached(experienceFragmentKey)
      : experienceFragment.put;

    const apiCall = {
      apiCall: experienceFragmentRequestType({
        experienceFragmentKey,
        body: {
          contentUrl: `/content/experience-fragments/waitrosegroceriescms/${experienceFragmentKey}`,
          customerId,
          customerSearchRequest: {
            queryParams: {},
          },
        },
        retry: {
          numberOfRetries,
          retryHandler,
        },
        iHandleStatusCodes: true,
      }),
      applyColumnTransformation,
      experienceFragmentKey,
      types: [
        EXPERIENCE_FRAGMENT_SUBMIT,
        EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS,
        EXPERIENCE_FRAGMENT_SUBMIT_FAIL,
      ] as const,
      schema: EXPERIENCE_FRAGMENT_SCHEMA,
    };

    return dispatch(apiCall).catch(error => {
      // We don't want to log 404 as unpublished but correct EF URLs will 404
      if (error.status !== 404) {
        log(error, {
          logger: 'aem',
          section: 'experience-fragments',
          extra: {
            experienceFragmentKey,
            applyColumnTransformation,
            numberOfRetries,
            cache,
          },
        });
      }
    });
  };

export default getExperienceFragment;
