import recipe from 'api/definitions/recipes/recipe';
import { GET_CMS_PAGE_NOT_FOUND } from 'redux/modules/cms-page/actions/types';

import { RECIPE_BY_ID } from './types';
import { getRating } from './rating';

export const fetchRecipeById = recipeId => (dispatch, getState) => {
  const notFoundStatuses = [400, 404];

  if (getState) {
    const { recipes } = getState();
    if (recipes[recipeId]?.loading || recipes[recipeId]?.id) {
      return null;
    }

    if (!recipes[recipeId]?.rating) {
      dispatch(getRating(recipeId));
    }
  }

  return dispatch({
    types: RECIPE_BY_ID.triplet,
    apiCall: recipe.get({ recipeId, iHandleStatusCodes: notFoundStatuses }),
    recipeId,
  }).catch(error => {
    if (notFoundStatuses.includes(error.status)) {
      dispatch({ type: GET_CMS_PAGE_NOT_FOUND });
    }
  });
};
