const orderStatus = {
  AMENDING: 'AMENDING',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FULFIL: 'FULFIL',
  PAID: 'PAID',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PENDING: 'PENDING',
  PICKED: 'PICKED',
  PLACED: 'PLACED',
  REFUND_PENDING: 'REFUND_PENDING',
} as const;

export default orderStatus;

export type OrderStatus = (typeof orderStatus)[keyof typeof orderStatus];
