import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';

import { openModal } from 'redux/modules/common-modal/actions';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';

import locator from 'utils/locator';

const CHANGE_SLOT_BUTTON_VALUE = 'CHANGE_SLOT';

export default errorResponse => (dispatch, getState) => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  const isAmendingOrder = amendingOrder(getState());

  if (status === 409 && code === 'ORDER_ORCHESTRATION_043') {
    if (!isAmendingOrder) {
      const onCloseCallback = value => {
        locator.href =
          value === CHANGE_SLOT_BUTTON_VALUE ? urls.reselectCollectionService : urls.groceriesHome;
      };

      dispatch(
        openModal({
          id: 'checkout-error-slot-mismatch',
          severity: 'conflict',
          buttons: [
            {
              buttonText: 'Change slot',
              buttonValue: CHANGE_SLOT_BUTTON_VALUE,
              primary: true,
            },
            {
              buttonText: 'Return to Groceries',
              secondary: true,
            },
          ],
          cannotClose: true,
          messageText: [
            'You currently have a Groceries slot with no grocery items. Please change to an Entertaining slot or add one or more items from our Groceries range to keep this slot.',
          ],
          titleText: 'Change slot or add grocery items',
          webview: {
            id: 'checkout-error-1',
            ctas: [urls.reselectCollectionService, urls.groceriesHome],
          },
          useIngredientsModal: false,
          onCloseCallback,
        }),
      );
    } else {
      const onCloseCallback = () => {
        locator.href = urls.trolleyPage;
      };

      dispatch(
        openModal({
          id: 'checkout-error-slot-mismatch-amend',
          severity: 'conflict',
          buttons: [
            {
              buttonText: 'OK',
              buttonValue: DEFAULT_BUTTON_VALUE,
              primary: true,
            },
          ],
          cannotClose: true,
          messageText: [
            'You have booked a mixed grocery and Entertaining slot. Please add a grocery item to checkout with this order. Alternatively, cancel and create an Entertaining-only slot.',
          ],
          titleText: 'Add a grocery item or cancel the order',
          webview: {
            id: 'checkout-error-12',
            ctas: [urls.trolleyPage],
          },
          useIngredientsModal: false,
          onCloseCallback,
        }),
      );
    }

    return true;
  }

  return false;
};
