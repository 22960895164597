import { rootPath } from 'utils/root-path';

import categoryIds from 'constants/categoryIds';

const buildAndFilterUrlSegments = menus =>
  menus
    .filter(menu => menu.id && menu.urlName)
    .filter(menu => !Object.values(categoryIds).includes(menu.id))
    .map(menu => `${menu.urlName.toLowerCase()}_offers`);

const concatenateOffersPathFromMenus = (menus = []) =>
  ['shop/browse/offers'].concat(buildAndFilterUrlSegments(menus)).join('/');

export const buildOffersPathFromMenus = menus => rootPath(concatenateOffersPathFromMenus(menus));
