import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import interpolate from 'utils/interpolate';

const MessageText = pure(({ isHtml, params, text }) => (
  <div>
    {text &&
      text.map(message => {
        const interpolatedMessage = params ? interpolate(message, params) : message;

        return isHtml ? (
          // eslint-disable-next-line react/no-danger
          <p key={String(message)} dangerouslySetInnerHTML={{ __html: interpolatedMessage }} />
        ) : (
          <p key={String(message)}>{interpolatedMessage}</p>
        );
      })}
  </div>
));

MessageText.displayName = 'MessageText';

MessageText.propTypes = {
  isHtml: PropTypes.bool,
  params: PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  }),
  text: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

MessageText.defaultProps = {
  isHtml: false,
  params: null,
  text: [],
};

export default MessageText;
