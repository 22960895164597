import trolley from 'api/definitions/trolley';
import orderStatuses from 'constants/orderStatus';
import { getCustomerOrderState } from 'redux/modules/sessions/selectors';
import {
  ORDER_LOAD_FAILED,
  ORDER_LOADED,
  ORDER_LOADING,
} from 'redux/modules/trolley/actions/types';
import {
  handleGenericTrolleyNetworkError,
  handleOrderCutOff,
} from 'redux/modules/trolley/error-handling';
import { TROLLEY_SCHEMA } from 'redux/schemas';
import { BUILD_PROMOTIONS } from 'redux/transforms';

const { AMENDING, PENDING } = orderStatuses;

export const getTrolley =
  ({ suppressErrorModal, initialLoad = false } = {}) =>
  (dispatch, getState) => {
    const orderState = getCustomerOrderState(getState());
    if (![AMENDING, PENDING].includes(orderState)) {
      return Promise.resolve();
    }

    dispatch({ type: ORDER_LOADING, initialLoad });

    return trolley
      .post()({ dispatch, getState })
      .then(response => {
        if (!response) {
          dispatch({
            error: 'Graph returned a get trolley error',
            type: ORDER_LOAD_FAILED,
          });

          return;
        }

        const { data, errors = [] } = response;
        const failures = data?.getTrolley?.failures || [];

        if (errors.length > 0 || !data?.getTrolley || failures.length > 0) {
          dispatch({
            error: 'Graph returned a get trolley error',
            type: ORDER_LOAD_FAILED,
          });

          if (failures.some(({ type }) => type === '400' || type === '410')) {
            handleOrderCutOff(failures[0], dispatch, getState);

            return;
          }

          const error =
            failures.length || errors.length
              ? JSON.stringify([...failures, ...errors])
              : 'Unknown getTrolley error';

          if (!suppressErrorModal) handleGenericTrolleyNetworkError({ dispatch, error });

          return;
        }

        dispatch({
          type: ORDER_LOADED,
          result: data.getTrolley,
          schema: TROLLEY_SCHEMA,
          transform: BUILD_PROMOTIONS,
        });
      })
      .catch(error => {
        if (!suppressErrorModal) handleGenericTrolleyNetworkError({ dispatch, error });

        dispatch({
          error: 'Get Trolley call to graph failed',
          type: ORDER_LOAD_FAILED,
        });
      });
  };
