import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const SAVE_USER_DETAILS = 'waitrose/registration/SAVE_USER_DETAILS';
export const SAVE_USER_ACCOUNT_DETAILS = 'waitrose/registration/SAVE_USER_ACCOUNT_DETAILS';
export const NO_DELIVERY_AND_COLLECTION =
  'waitrose/registration/NO_AVAILABLE_DELIVERY_AND_COLLECTION';
export const COLLECTION = 'waitrose/registration/AVAILABLE_COLLECTION';
export const DELIVERY = 'waitrose/registration/AVAILABLE_DELIVERY';
export const addAddressAndMarketingPreferencesListTypes = apiPromiseTypes(
  'addAddressAndMarketingPreferencesList',
  'post',
);
