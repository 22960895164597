import get from 'lodash/get';

export const getSearchTags = state => get(state, 'search.queryParams.searchTags', []);

export const getAllSearchTags = state =>
  getSearchTags(state)
    .map(tag => tag.text)
    .filter(tag => tag);

export const getSearchTag = (state, index) => get(getSearchTags(state), `[${index}].text`);
