import token from 'api/definitions/tokenClient';
import { getSessionCookie } from 'redux/modules/page/selectors';
import { asyncLocalStorage } from 'server/utils/async-local-storage';
import { getShoppingContext } from './get-shopping-context';
import { setAccessToken } from './set-access-token';
import { csrfTokenActionTypes, tokenActionTypes } from './types';

const getCsrfToken = () => ({
  types: csrfTokenActionTypes.triplet,
  apiCall: token.csrf(),
});

const getToken = (csrfToken: string, headerName: string, state: WtrState) => {
  const Cookie = getSessionCookie(state);

  return {
    types: tokenActionTypes.triplet,
    apiCall: token.token({
      headers: {
        [headerName]: csrfToken,
        Cookie,
      },
      iHandleStatusCodes: [401, 403],
    }),
  };
};

const postSoacToken = (adminToken: string, customerId: string) => {
  const body = new URLSearchParams({
    adminToken,
    customerId,
  }).toString();

  return {
    types: tokenActionTypes.triplet,
    apiCall: token.soac({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
      iHandleStatusCodes: [401, 403],
    }),
  };
};

let tokenPromise: Promise<void> | null;

const initiateSessionCall = (dispatch: WtrDispatch, getState: () => WtrState) =>
  dispatch(getCsrfToken()).then(({ headerName, token: csrfToken }) =>
    dispatch(getToken(csrfToken, headerName, getState())).then(response => {
      dispatch(setAccessToken(response.accessToken));
      return dispatch(getShoppingContext());
    }),
  );

export const initiateSession = () => (dispatch: WtrDispatch, getState: () => WtrState) => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    const hasTokenSession = requestContext.get('hasTokenSession');

    return hasTokenSession
      ? initiateSessionCall(dispatch, getState).catch(() => {})
      : Promise.resolve();
  }

  if (!tokenPromise) {
    // eslint-disable-next-line no-underscore-dangle
    const hasTokenSession = window.__WTR_WEBSITE__?.hasTokenSession;

    tokenPromise = hasTokenSession ? initiateSessionCall(dispatch, getState) : Promise.reject();

    tokenPromise
      .catch(() => {})
      .finally(() => {
        tokenPromise = null;
      });
  }

  return tokenPromise;
};

export const initiateSoacSession =
  (adminToken: string, customerId: string) => async (dispatch: WtrDispatch) => {
    try {
      await dispatch(postSoacToken(adminToken, customerId));
    } catch (error) {
      console.error('SOAC initialisation failed', error);
    }
  };
