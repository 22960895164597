import React from 'react';
import { Helmet } from 'react-helmet-async';

export const GTM_SCRIPT = `
  if (includeExternal) {
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-53TGWS');
  }
`;

// it would seem entirely natural to have each as a separate component, react-helmet doesn't
// support this. So here we are.
const AnalyticsScripts = () => (
  <Helmet>
    <noscript data-test="gtm-iframe-tag">
      {`
        <iframe
          src="//www.googletagmanager.com/ns.html?id=GTM-53TGWS"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
          title=""
        ></iframe>
      `}
    </noscript>
    <script data-test="gtm-script-tag">{GTM_SCRIPT}</script>
  </Helmet>
);

export default AnalyticsScripts;
