import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { bool, func, number, string } from 'prop-types';
import classNames from 'classnames';

import styles from './Textarea.scss';

const Textarea = forwardRef(
  (
    {
      autoExpand,
      label,
      list,
      maxHeight,
      minHeight,
      onTextChange,
      placeholder,
      resetHeight,
      rows,
      value,
    },
    forwardedRef,
  ) => {
    const ref = useRef();

    // nice trick to share the same ref, internally and forwarded externally
    useImperativeHandle(forwardedRef, () => ref.current);

    const initialStyle = useMemo(() => {
      const canResize = rows > 1 && !autoExpand;
      return {
        maxHeight,
        minHeight,
        resize: canResize ? 'inherit' : 'none',
      };
    }, [autoExpand, maxHeight, minHeight, rows]);

    const [style, setStyle] = useState(initialStyle);

    const setHeight = useCallback(
      height => {
        setStyle({
          ...initialStyle,
          boxSizing: 'border-box',
          height,
        });
      },
      [initialStyle],
    );

    useEffect(() => {
      if (autoExpand) {
        const borderHeight = 2; // 1px top + bottom
        const { scrollHeight, clientHeight } = ref.current;
        if (scrollHeight > clientHeight) setHeight(scrollHeight + borderHeight);
      }
    }, [autoExpand, setHeight, value]);

    useEffect(() => {
      if (resetHeight) setStyle(initialStyle);
    }, [initialStyle, resetHeight]);

    return (
      <textarea
        aria-label={label}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className={classNames(styles.textarea, {
          [styles.list]: list,
        })}
        data-testid="textarea-component"
        onChange={onTextChange}
        placeholder={placeholder}
        ref={ref}
        rows={rows}
        style={style}
        value={value}
      />
    );
  },
);

Textarea.propTypes = {
  autoExpand: bool,
  label: string,
  list: bool,
  minHeight: string,
  maxHeight: string,
  onTextChange: func.isRequired,
  placeholder: string,
  resetHeight: bool,
  rows: number,
  value: string,
};

Textarea.defaultProps = {
  autoExpand: false,
  label: '',
  list: false,
  minHeight: undefined,
  maxHeight: undefined,
  placeholder: 'Please enter your message...',
  resetHeight: false,
  rows: 3,
  value: '',
};

export default Textarea;
