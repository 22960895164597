import { GET_INCENTIVES } from 'redux/modules/incentives/actions/types';
import { IncentivesState } from 'redux/modules/incentives/state';

import { Incentive } from 'constants/incentives';
import { ApiError } from 'constants/errors';

const getIncentivesRequest = (state: IncentivesState): IncentivesState => ({
  ...state,
  isLoading: true,
  serverError: null,
});

const getIncentivesSuccess = (
  state: IncentivesState,
  action: {
    payload: Incentive[];
    result: {
      header: HeadersInit;
    };
  },
): IncentivesState => {
  const myHeaders = new Headers(action.result.header);
  return {
    ...state,
    voucherC2CPilot: myHeaders.get('wtr-voucher-c2c-pilot-enabled') === 'true',
    incentivesList: action.payload,
    isLoading: false,
    isLoaded: true,
  };
};

const getIncentivesFailure = (
  state: IncentivesState,
  action: { payload: ApiError },
): IncentivesState => ({
  ...state,
  incentivesList: [],
  isLoading: false,
  isLoaded: true,
  serverError: action.payload,
});

export default () =>
  [
    [GET_INCENTIVES.request, getIncentivesRequest],
    [GET_INCENTIVES.success, getIncentivesSuccess],
    [GET_INCENTIVES.failure, getIncentivesFailure],
  ] as const;
