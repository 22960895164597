import { createSelector } from 'reselect';

/** @returns {{ status: string; serialNumber: string }[]} */
export const getGiftCards = state => state.order.giftCards;

/** @returns {{ amount: number; status: import('../state/initial-state').GiftVoucherStatus; serialNumber: string }[]} */
export const getGiftVouchers = state => state.order.giftVouchers;

/** @returns {null | { [serialNumber: string]: import('../state/initial-state').GiftVoucherStatus; }} */
export const getGiftVoucherInlineErrors = state => state.checkout.giftVoucherInlineErrors ?? null;

export const giftCardsLimitReached = createSelector(
  getGiftCards,
  giftCards => giftCards.length > 9,
);

export const giftVouchersLimitReached = createSelector(
  getGiftVouchers,
  giftVouchers => giftVouchers.length > 9,
);

/** @returns {number} */
export const getNumberOfGiftVouchers = createSelector(
  getGiftVouchers,
  giftVouchers => giftVouchers.length,
);
