import initialState from '../state/initial-state';

const reducer = state => {
  const { currentApiRequest, errorStatusCode, errorSource } = initialState;

  return {
    ...state,
    currentApiRequest,
    errorStatusCode,
    errorSource,
  };
};

export default reducer;
