import {
  SHOW_ADD_MODAL,
  HIDE_ADD_MODAL,
  CANCEL_ADD_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  CANCEL_EDIT_MODAL,
  SHOW_CHOOSE_ADDRESS_MODAL,
  HIDE_CHOOSE_ADDRESS_MODAL,
  CANCEL_CHOOSE_ADDRESS_MODAL,
  EXPAND_ADDRESS_BOOK,
  SHOW_EDIT_DELETE_SPINNER,
  HIDE_EDIT_DELETE_SPINNER,
} from 'redux/modules/address/actions/types';
import { setChoosenAddress } from 'redux/modules/address/actions';
import { resetUpdateContactAddressError } from './reset-update-contact-address-error';

const hideAddModal = () => ({
  type: HIDE_ADD_MODAL,
});

const onShowEditModal = address => ({
  type: SHOW_EDIT_MODAL,
  address,
});

const hideEditModal = () => ({
  type: HIDE_EDIT_MODAL,
});

const showChooseAddress = addresses => ({
  type: SHOW_CHOOSE_ADDRESS_MODAL,
  addresses,
});

const hideChooseAddress = () => ({
  type: HIDE_CHOOSE_ADDRESS_MODAL,
});

const cancelChooseAddress = () => ({
  type: CANCEL_CHOOSE_ADDRESS_MODAL,
});

const chooseAddress = address => async dispatch => {
  dispatch(setChoosenAddress(address));
  dispatch(hideChooseAddress());
};

const expandAddressBook = () => ({
  type: EXPAND_ADDRESS_BOOK,
});

const showAddModal = () => async dispatch => {
  await dispatch(resetUpdateContactAddressError());
  return dispatch({
    type: SHOW_ADD_MODAL,
  });
};

const showAddModalOnAddressBook = () => async dispatch => {
  await dispatch(showAddModal());
  return dispatch(expandAddressBook());
};

const cancelAddModal = () => ({
  type: CANCEL_ADD_MODAL,
});

const cancelEditModal = () => ({
  type: CANCEL_EDIT_MODAL,
});

const showEditDeleteSpinner = id => ({
  type: SHOW_EDIT_DELETE_SPINNER,
  id,
});

const hideEditDeleteSpinner = () => ({
  type: HIDE_EDIT_DELETE_SPINNER,
});

export {
  showAddModal,
  hideAddModal,
  onShowEditModal,
  hideEditModal,
  showChooseAddress,
  hideChooseAddress,
  cancelChooseAddress,
  chooseAddress,
  expandAddressBook,
  showAddModalOnAddressBook,
  cancelAddModal,
  cancelEditModal,
  showEditDeleteSpinner,
  hideEditDeleteSpinner,
};
