/*
  AnalyticsMiddleware pushes data to Google Tag Manager (GTM) data layer
  when it encounters certain actions.

  It generates and pushes an object to the GTM data layer derived from
  the actions' payload and the store's updated state using specific transform
  functions.

  To create this middleware, the following must be provided to the factory:

  - `dataLayer`: reference to window.dataLayer object
  - `actionsToTrack`: key/value pairs, where
      keys: action types to track (triggering GTM tags)
      values: specific transform functions

  Example:

  ```
  import { ACTION_ONE, ACTION_TWO } from `redux/modules/foo/actions/types`;

  const actionOneTransform = (state, { foo }) => ({ foo: `${foo}-bar`});
  const actionTwoTransform = (state, { bar }) => ({ baz: `${baz}-${state.blah}`});
  const actionsToTrack = {
    [ACTION_ONE]: actionOneTransform,
    [ACTION_TWO]: actionTwoTransform
  };
  const analyticsMiddleware = createMiddleware(window.dataLayer, actionsToTrack);
  ```

  where the transform functions method signature is:

  - `state`: updated store state
  - `payload`: action's payload

 */

import { CLIENT_PAGE_LOADED } from 'redux/modules/page/actions/types';

const createMiddleware =
  (dataLayer, actionsToTrack = {}) =>
  store =>
  next =>
  action => {
    next(action);

    if (action.type === CLIENT_PAGE_LOADED) {
      dataLayer.allowSendingOfEvents();
    }

    if (!Object.keys(actionsToTrack).includes(action.type)) {
      return;
    }

    const nextState = store.getState();
    const { type: event, ...payload } = action;
    const transform = actionsToTrack[action.type];
    const data = transform(nextState, payload);

    if (!data) {
      return;
    }

    if (Array.isArray(data)) {
      data.forEach(dataset => dataLayer.push({ event, ...dataset }));
    } else if (typeof data === 'object') {
      dataLayer.push({ event, ...data });
    }
  };

export default createMiddleware;
