import React from 'react';
import uuidv4 from 'uuid/v4';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import RichText from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/RichText';
import { RichTextNode } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/RichText/types';

import styles from './Method.scss';

type MethodProps = {
  method: {
    step: string;
  }[];
  cooksTip?: RichTextNode;
  andToDrink?: RichTextNode;
};

const Method = ({ method = [], cooksTip, andToDrink }: MethodProps) => (
  <div data-test="method" className={styles.method}>
    <ol className={styles.steps}>
      {method.map(({ step }) => (
        <li key={uuidv4()}>
          <Typography element="p" styleAs="paragraph">
            {step}
          </Typography>
        </li>
      ))}
    </ol>
    <div className={styles.add}>
      {cooksTip && (
        <>
          <Typography element="h3" styleAs="paragraphHeading">
            Cook’s tip
          </Typography>
          <RichText {...cooksTip} position="cooks-tip" />
        </>
      )}
      {andToDrink && (
        <>
          <Typography element="h3" styleAs="paragraphHeading">
            And to drink...
          </Typography>
          <RichText {...andToDrink} position="and-to-drink" />
        </>
      )}
    </div>
  </div>
);

export default Method;
