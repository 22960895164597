import { createSelector } from 'reselect';

export const getCustomerOrders = ({ entities: { customerOrders } = {} } = {}) => customerOrders;

/**
 * @param {*} state
 * @param {string} [customerOrderId]
 * @returns {string}
 */
const getCustomerOrderId = (_state, customerOrderId) => customerOrderId;

export const getCustomerOrderById = createSelector(
  [getCustomerOrders, getCustomerOrderId],
  (orders = {}, customerOrderId) => orders[customerOrderId],
);

export const getOrderRecommendationsByCustomerOrderId = createSelector(
  [getCustomerOrderById],
  ({ recommendedProducts } = {}) => recommendedProducts?.products,
);

export const makeGetOrderLinesByCustomerOrderId = () =>
  createSelector([getCustomerOrderById], ({ orderLines = [] } = {}) => orderLines);

export const getOrderLinesByCustomerOrderId = makeGetOrderLinesByCustomerOrderId();

export const getOrderLinesLengthByCustomerOrderId = createSelector(
  getOrderLinesByCustomerOrderId,
  (orderLines = []) => orderLines.length,
);

export const getOrderProductLineNumbersByCustomerOrderId = createSelector(
  getOrderLinesByCustomerOrderId,
  (orderLines = []) => orderLines.map(({ lineNumber }) => lineNumber),
);

export const hasCustomerOrders = createSelector(
  getCustomerOrders,
  (customerOrders = {}) => Object.keys(customerOrders).length > 0,
);
