import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { IOpenModalArgs } from 'redux/modules/common-modal/actions/index';
import {
  ANOTHER_MODAL_OPEN,
  ANOTHER_MODAL_CLOSED,
  CLOSE_MODAL,
  OPEN_MODAL,
} from 'redux/modules/common-modal/actions/types';

import { deepFreeze } from 'utils/deepFreeze';

const DEFAULT_BUTTON = [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }];

export const initialState = deepFreeze({
  asideText: '',
  buttons: [],
  buttonOrientation: null,
  cannotClose: false,
  conflict: null,
  dataTest: null,
  id: null,
  isOpen: false,
  messageIsHtml: false,
  messageText: [],
  messageTextParams: null,
  responseStatus: null,
  severity: null,
  titleText: '',
  isAnyModalOpen: false,
  webViewId: null,
  useIngredientsModal: false,
});

type OpenModalType = {
  conflict: unknown;
} & Omit<IOpenModalArgs, 'onCloseCallback'>;

type ActionTuple = readonly [
  [typeof OPEN_MODAL, WtrReducer<typeof initialState, OpenModalType>],
  [typeof CLOSE_MODAL, WtrReducer<typeof initialState, undefined>],
  [typeof ANOTHER_MODAL_OPEN, WtrReducer<typeof initialState, undefined>],
  [typeof ANOTHER_MODAL_CLOSED, WtrReducer<typeof initialState, undefined>],
];

type ActionTypes =
  | ({
      type: typeof OPEN_MODAL;
    } & OpenModalType)
  | { type: typeof CLOSE_MODAL | typeof ANOTHER_MODAL_OPEN | typeof ANOTHER_MODAL_CLOSED | '' };

export type PayloadMap = ReducerMapTupleToRecord<ActionTuple>;

export default function reducer(state = initialState, action: ActionTypes = { type: '' }) {
  switch (action.type) {
    case OPEN_MODAL: {
      const {
        asideText = '',
        buttons = DEFAULT_BUTTON,
        buttonOrientation,
        cannotClose = false,
        conflict,
        dataTest,
        id,
        messageIsHtml,
        messageText = [],
        messageTextParams,
        severity,
        responseStatus = null,
        titleText = '',
        webview,
        useIngredientsModal,
      } = action;

      return {
        ...state,
        asideText,
        buttons,
        buttonOrientation,
        cannotClose,
        conflict,
        dataTest,
        id,
        isOpen: true,
        messageIsHtml,
        messageText,
        messageTextParams,
        responseStatus,
        severity,
        titleText,
        isAnyModalOpen: true,
        webViewId: webview?.id ?? null,
        useIngredientsModal,
      };
    }

    case CLOSE_MODAL:
      return initialState;

    case ANOTHER_MODAL_OPEN:
      return {
        ...state,
        isAnyModalOpen: true,
      };

    case ANOTHER_MODAL_CLOSED:
      return {
        ...state,
        isAnyModalOpen: false,
      };

    default:
      return state;
  }
}
