import { push } from 'redux-first-history';

import urls from 'constants/urls';
import legacyGetOrderWithRecommendations from './legacy-get-order-with-recommendations';

/**
 * @deprecated Graph usage is deprecated and should be avoided.
 */
const legacyGetOrder =
  ({ customerOrderId, iHandleStatusCodes }) =>
  dispatch => {
    const failure = error => {
      const statusCode = parseInt(error.message?.slice(0, 3), 10);

      if (iHandleStatusCodes.includes(statusCode)) {
        return dispatch(push(urls.myOrdersPage));
      }

      throw error;
    };

    return dispatch(
      legacyGetOrderWithRecommendations({
        customerOrderId,
        handleError: iHandleStatusCodes && failure,
      }),
    );
  };

export default legacyGetOrder;
