import urls from 'constants/urls';

import { getPathDepth, getPathTaxonomyType } from 'utils/analytics';

import { setCurrentPageDepth } from 'redux/modules/page/actions';

export default (pathname = '') =>
  setCurrentPageDepth(
    getPathDepth(pathname.replace(urls.browse, '')),
    getPathTaxonomyType(pathname),
  );
