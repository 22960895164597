import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const RECIPE_BY_ID = apiPromiseTypes('recipes', 'get');
export const PRODUCTS_BY_RECIPE_ID = apiPromiseTypes('pims', 'get');
export const PRODUCT_ALTERNATIVES_BY_INGREDIENT = apiPromiseTypes(
  'pims/alternatives_by_ingredient',
  'get',
);
export const RECIPE_RATING = apiPromiseTypes('recipe-rating', 'get');
export const NAV_TO_RECIPE = 'waitrose/recipes/nav-to-recipe';
export const UPDATE_SHOPPABLE_INGREDIENT_BY_ID =
  'waitrose/recipes/UPDATE_SHOPPABLE_INGREDIENT_BY_ID';

export const SHOPPABLE_PRICING_REQUEST = 'waitrose/recipes/PRICING_REQUEST';
export const SHOPPABLE_PRICING_REQUEST_SUCCESS = 'waitrose/recipes/PRICING_REQUEST_SUCCESS';
export const SHOPPABLE_PRICING_REQUEST_FAIL = 'waitrose/recipes/PRICING_REQUEST_FAIL';

export const SHOPPABLE_PRICING_REQUEST_ACTIONS: readonly [string, string, string] = [
  SHOPPABLE_PRICING_REQUEST,
  SHOPPABLE_PRICING_REQUEST_SUCCESS,
  SHOPPABLE_PRICING_REQUEST_FAIL,
];

export const SWAP_SHOPPABLE_PRICING_REQUEST = 'waitrose/recipes/SWAP_PRICING_REQUEST';
export const SWAP_SHOPPABLE_PRICING_REQUEST_SUCCESS =
  'waitrose/recipes/SWAP_PRICING_REQUEST_SUCCESS_SWAP';
export const SWAP_SHOPPABLE_PRICING_REQUEST_FAIL = 'waitrose/recipes/SWAP_PRICING_REQUEST_FAIL';

export const SWAP_SHOPPABLE_PRICING_ACTIONS = [
  SWAP_SHOPPABLE_PRICING_REQUEST,
  SWAP_SHOPPABLE_PRICING_REQUEST_SUCCESS,
  SWAP_SHOPPABLE_PRICING_REQUEST_FAIL,
];
