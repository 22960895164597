import { apiCallback } from 'api';

const accounts = {
  service: 'account-orchestration',
  endpoint: 'accounts/self',
  verbs: ['get'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(accounts, 'get'),
};
