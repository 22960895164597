import { openModal } from 'redux/modules/common-modal/actions';

const toggleErrorModalNumberOfGiftCards = () =>
  openModal({
    id: 'checkout-gift-card-limit',
    severity: 'info',
    messageText: ['Sorry, you can have a maximum of 10 gift cards on this order.'],
    titleText: 'Gift card limit reached',
    webview: {
      id: 'checkout-error-gift-card-limit',
    },
  });

export default toggleErrorModalNumberOfGiftCards;
