import React from 'react';
import { pure } from 'recompose';

import Warrant from 'components/Warrant';

const PrinceOfWalesWarrant = pure(() => (
  <Warrant
    armsUrl="https://waitrose-prod.scene7.com/is/image/waitroseprod/prince-of-wales-1?$Waitrose-Image-Preset-95$"
    byAppointmentTo="His Royal Highness The Prince of Wales"
  />
));

PrinceOfWalesWarrant.displayName = 'PrinceOfWalesWarrant';

export default PrinceOfWalesWarrant;
