import env from 'env/env';
import locator from 'utils/locator';
import queryString from 'query-string';

// Note: Adding a new URL to this const will cause a new function to appear on the exported object from this module.
// The function will be a camel-cased version of your const with 'Url' appended, i.e. MY_FAVOURITES becomes myFavouritesUrl()
// This function can take an object as a parameter, and the keys and values of this parameter will form the query string for the Url
// i.e. calling myFavouritesUrl({foo: 'bar'}) will return 'https://xxx.waitrose.com/shop/myFavourites/?foo=bar'
export const pageUrls = {
  ADD_NEW_ADDRESS: { path: 'shop/MyAddresses', query: { addNewAddress: 'true' } },
  BOOK_COLLECTION_SLOT: { path: 'shop/BookCollectionLocation' },
  BRANCH_FINDER: { path: '/find-a-store' },
  BROWSE_FEATURED: { path: 'shop/Featured' },
  BROWSE_ROOT: { path: 'shop/Browse' },
  CHECKOUT: { path: 'shop/TrolleyDisplay', query: { isFromCheckout: true } },
  EXPEDITED_CHECKOUT: {
    path: '/webapp/wcs/stores/servlet/CheckOutInitiateCmd',
    query: { invokedFromInterstitials: true },
  },
  ENTERTAINING: { path: 'shop/Browse/Entertaining' },
  GROCERIES: { path: 'shop/Browse/Groceries' },
  INSPIRATION: { path: 'home/inspiration.html' },
  MULTI_SEARCH: { path: '/shop/JotterSummaryView', query: { 'jotter-items': undefined } },
  MY_ADDRESSES: { path: '/shop/MyAddresses' },
  MY_FAVOURITES: { path: 'shop/MyFavourites' },
  MY_LIST: { path: '/shop/MyList' },
  MY_LOGIN_DETAILS: { path: '/shop/MyLoginDetails' },
  MY_PAYMENT_DETAILS: { path: '/shop/MyPaymentDetails' },
  MY_PERSONAL_DETAILS: { path: '/shop/MyPersonalDetails' },
  MY_WAITROSE_CARD_LINK: { path: '/shop/MyWaitroseCard?URL=/ecom/myaccount' },
  MY_WAITROSE_JOIN: { path: '/shop/ActivateMyWaitrose?URL=/ecom/myaccount' },
  OFFER_DETAILS: { path: 'shop/OfferDetails' },
  OFFERS: { path: 'shop/Browse/Offers' },
  ORDER_REVIEW: { path: 'shop/CheckOutInitiateCmd' },
  PREVIOUS_ORDERS: { path: 'shop/GetCustomerOrders' },
  PRODUCT_DETAILS: { path: 'shop/DisplayProductFlyout' },
  RECIPES: { path: 'home/recipes.html' },
  RESERVATION: { path: 'webapp/wcs/stores/servlet/CheckOutInitiateCmd' },
  ROOT: { path: '/' },
  SEARCH: { path: 'shop/HeaderSearchCmd' },
  SERVICE_SELECTOR: { path: 'shop/PickAService' },
  TERMS_AND_CONDITIONS_PRICING: {
    path: 'content/waitrose/en/corporate_information_home/corporate_information/legal_notices/terms_and_conditions.html#PricingPolicy',
  },
  TERMS_AND_CONDITIONS: {
    path: 'content/waitrose/en/corporate_information_home/corporate_information/legal_notices/terms_and_conditions.html',
  },
  THANKSGIVING_TURKEYS: {
    path: 'shop/Browse/Entertaining/Christmas_and_Seasonal/Turkey_Goose_and_Duck/Thanksgiving_Turkeys',
  },
  TROLLEY_DETAILS: { path: 'shop/TrolleyDisplay' },
  ADMIN_HOME: { path: 'shop/ReturnToAdmin' },
  ADMIN_CUSTOMER_DETAILS: { path: 'Admin/servlet/ViewCustomerDetails' },
  ADMIN_LOCK: { path: 'Admin/servlet/WRMemberLock' },
};

export function getCamelCaseFunctionName(str) {
  return `${str
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('')}Url`;
}

function buildUrl(path, query) {
  let normalisedPath = `/${(Array.isArray(path)
    ? path.map(item => item.replace(/^\/+|\/+$/g, '')).join('/')
    : path || ''
  ).replace(/^\/+|\/+$/g, '')}`;

  if (query && Object.keys(query).length) {
    normalisedPath += `?${Object.keys(query)
      .map(key => `${key}=${query[key]}`)
      .join('&')}`;
  }

  return normalisedPath;
}

function buildWcsUrl(path, query) {
  return env.wcsDomain + buildUrl(path, query);
}

function buildCanonicalWcsUrl(path, query) {
  return `http:/${buildUrl([env.prodHost, ...path], query)}`;
}

const buildEncodedProductionUrl = () => {
  const { categoryId } = queryString.parse(locator.search);

  // Some pages, e.g. offers pages, require the categoryId to return to the correct place. This preserves the required parameters,
  // but drops the rest. May well need to be extended
  const query = categoryId ? `?${queryString.stringify({ categoryId })}` : '';

  return encodeURIComponent(`https://${env.prodHost}${locator.pathname}${query}`);
};

const urlBuilders = Object.keys(pageUrls).reduce((acc, curr) => {
  acc[getCamelCaseFunctionName(curr)] = query =>
    buildWcsUrl(pageUrls[curr].path, { ...pageUrls[curr].query, ...query });
  return acc;
}, {});

export default {
  ...urlBuilders,
  browseBreadcrumbWcsUrl: (breadcrumbPath, query) =>
    buildWcsUrl([pageUrls.BROWSE_ROOT.path, breadcrumbPath], {
      ...pageUrls.BROWSE_ROOT.query,
      ...query,
    }),
  buildUrl: buildWcsUrl,
  canonicalBrowseUrl: productPath => buildCanonicalWcsUrl([pageUrls.BROWSE_ROOT.path, productPath]),
  canonicalOfferDetailsUrl: params => buildCanonicalWcsUrl([pageUrls.OFFER_DETAILS.path], params),
  canonicalProductDetailsUrl: params =>
    buildCanonicalWcsUrl([pageUrls.PRODUCT_DETAILS.path], params),
  multiSearchUrl: params =>
    buildWcsUrl(pageUrls.MULTI_SEARCH.path, { 'jotter-items': encodeURIComponent(params) }),
  previousOrders: () => buildWcsUrl(pageUrls.PREVIOUS_ORDERS.path),
  shopFromOrder: orderId =>
    buildWcsUrl(pageUrls.TROLLEY_DETAILS.path, {
      isShopFromPreviousOrder: true,
      orderId,
    }),
};
