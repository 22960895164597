import React from 'react';
import root from 'window-or-global';

import env from 'env/env';

if (env.development || env.apiEnv === 'qa') {
  root.React = React;
  root.waitrose = {};
  root.waitrose.env = env;
}
