import { TROLLEY_SORT_BY_DATE } from 'constants/trolley';
import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  amendingOrder: false,
  analytics: {
    trolleyItems: {},
    trolleyFiltered: false,
  },
  emptyTrolleyModal: {
    isOpen: false,
  },
  loading: false,
  loadingAdd: false,
  loadingOrder: false,
  loadingSubs: false,
  // This loading variable is necessary to differentiate a normal get trolley
  // from the one done initially when we get customer information.
  // This distinction is necessary to keep the spinning in the ShoppingSummary component
  // behave as is, but with the adding loading state for the initial request
  // to get customer trolley information.
  loadingCustomerInfo: false,
  nextAvailableTrolleyItemId: -1,
  pending: 0,
  personalisedMessageModal: {
    currentProductMessage: '',
    currentTrolleyItemId: null,
    isEdit: false,
    isOpen: false,
    maxPersonalisedMessageLength: 0,
  },
  shopperNotesModal: {
    currentProductId: null,
    currentProductNotes: '',
    currentTrolleyItemId: null,
    isEdit: false,
    isOpen: false,
  },
  swapUnavailableProductModal: {
    isOpen: false,
    lineNumber: null,
    trolleyItemId: null,
  },
  subAllModal: {
    isOpen: false,
  },
  trolleyScreenAnnoucement: {
    type: null,
    lineNumber: null,
  },
  slotChangeable: true,
  trolleyItems: [],
  recommendations: [],
  substitutionProducts: {},
  lastRemovedTrolleyItem: undefined,
  lastReaddedTrolleyItem: undefined,
  trolleyItemsSetForRemoval: [],
  optimisticTrolleyItems: [],
  trolleyTotals: {
    collectionMinimumOrderValue: {
      amount: 40,
      currencyCode: 'GBP',
    },
    deliveryCharge: {
      amount: 0,
      currencyCode: 'GBP',
    },
    deliveryMinimumOrderValue: {
      amount: null,
      currencyCode: 'GBP',
    },
    itemTotalEstimatedCost: {
      amount: 0,
      currencyCode: 'GBP',
    },
    minimumSpendThresholdMet: false,
    savingsFromMyWaitrose: {
      amount: 0,
      currencyCode: 'GBP',
    },
    savingsFromOffers: {
      amount: 0,
      currencyCode: 'GBP',
    },
    savingsFromOffersAndIncentives: {
      amount: 0,
      currencyCode: 'GBP',
    },
    totalEstimatedCost: {
      amount: 0,
      currencyCode: 'GBP',
    },
    trolleyItemCounts: {
      hardConflicts: 0,
      noConflicts: 0,
      softConflicts: 0,
    },
  },
  trolleySortValue: TROLLEY_SORT_BY_DATE,
});

export default initialState;
