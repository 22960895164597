import { createSelector } from 'reselect';
import env from 'env/env';
import { errors } from 'components/Registration/AccountDetails/errors';

export const getAccessToken = state => state.sessions.accessToken;
export const getCustomerId = state => state.sessions.customerId;

export const getOrigin = state => state.sessions.origin;
export const getCustomerOrderId = state => state?.sessions?.customerOrderId;
export const getError = state => state.sessions.error;
export const getPrincipalId = state => state.sessions.principalId;

const isIdAnonymous = id => id.toString() === '-1';
const isIdSet = id => !!id;
const idsAreEqual = (id1, id2) => id1 === id2;

export const getSessions = state => state?.sessions;
export const getCustomerOrderState = createSelector(
  getSessions,
  sessions => sessions?.customerOrderState,
);

export const isSignedOnAsCustomer = createSelector(
  getCustomerId,
  getPrincipalId,
  (customerId, principalId) =>
    isIdSet(customerId) &&
    isIdSet(principalId) &&
    !idsAreEqual(customerId, principalId) &&
    !isIdAnonymous(customerId) &&
    !isIdAnonymous(principalId),
);

export const isUserLoggedIn = createSelector(
  getCustomerId,
  customerId => !!customerId && !isIdAnonymous(customerId),
);

export const getReturnToAdminUrl = createSelector(getCustomerId, customerId =>
  customerId ? `${env.adminDomain}/customer/${customerId}/details` : undefined,
);

export const getRegistrationErrorType = state =>
  Object.prototype.hasOwnProperty.call(errors(), state.sessions.error)
    ? state.sessions.error
    : null;
