import orderIncentive from 'api/definitions/orderIncentive';

import { initialiseOrder } from 'redux/modules/order/actions';
import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import { showSnackbar } from 'redux/modules/common-snackbar/actions';

import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

import { promoCodeRemovedAtCheckout } from 'constants/snackbars';
import swallowStatusCodes from 'utils/swallow-status-codes';

import { REMOVE_PROMO_CODE } from './types';
import { handleTimeoutError } from './errors';

export const iHandleStatusCodes = [400, 403, 423, 500, 503];

export default ({ code, displaySnackbar = false }) =>
  (dispatch, getState) => {
    dispatch({
      type: REMOVE_PROMO_CODE.request,
      code,
    });
    return orderIncentive
      .removeIncentive({ code })({
        dispatch,
        getState,
      })
      .then(async response => {
        const { data, errors } = response;
        const failures = data?.removeIncentive?.failures || [];
        if (errors) {
          const regex = /^50[0-9]/;
          const error = errors.some(({ message = '' } = {}) => regex.test(message))
            ? {
                status: 500,
                message:
                  'Sorry, we could not remove this code/voucher from your order. If the problem continues, try again later.',
              }
            : { status: 400, message: 'Sorry, there is something wrong with this request.' };
          dispatch({
            error,
            type: REMOVE_PROMO_CODE.failure,
          });
          swallowStatusCodes({ statusCodes: iHandleStatusCodes })(error);
        } else if (failures.length > 0) {
          dispatch({
            error: {
              status: failures[0].type,
              message: failures[0].message,
            },
            type: REMOVE_PROMO_CODE.failure,
          });
        } else {
          dispatch({
            type: REMOVE_PROMO_CODE.success,
            result: {
              ...data.removeIncentive,
            },
          });

          await Promise.all([
            dispatch(initialiseOrder(getCustomerOrderId(getState()))),
            dispatch(getTrolley()),
          ]);

          if (displaySnackbar) {
            dispatch(showSnackbar(promoCodeRemovedAtCheckout));
          }
        }
      })
      .catch(error => {
        if (!handleTimeoutError(error)(dispatch, getState)) {
          throw error;
        }
      })
      .catch(swallowStatusCodes({ statusCodes: iHandleStatusCodes }));
  };
