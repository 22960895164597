import castArray from 'lodash/castArray';
import isString from 'lodash/isString';
import queryString from 'query-string';

import { MAX_SEARCH_WORDS } from 'constants/search';

import { limitWords } from 'utils/limit-words';

export const getSearchTermFromSearchQuery = search => {
  const query = queryString.parse(search);
  const searchTermParam = castArray(query.searchTerm)[0] || query.value;
  return searchTermParam ? searchTermParam.replace(/[^\w\s&'.,+-]/gi, '').trim() : '';
};

export const getSearchValueFromSearchQuery = search => {
  const query = queryString.parse(search);
  const searchTermParam = castArray(query.searchTerm)[0];
  return searchTermParam ? searchTermParam.replace(/[^\w\s&'.,+-]/gi, '').trim() : '';
};

export const getApiRequestFromSearchQuery = search => {
  const query = queryString.parse(search);
  const searchPhrase = getSearchTermFromSearchQuery(search);
  const sortBy = query.sortBy ? query.sortBy.replace(/[^\w\s&'.,+-]/gi, '').trim() : '';
  const customTags = query.ct ? query.ct.split(',') : [];
  const popularTags = query.pt ? query.pt.split(',') : [];
  const searchTerm = query.ct ? '' : limitWords(searchPhrase, MAX_SEARCH_WORDS);
  const filters = query.flt ? query.flt.split(';') : [];
  const category = query.cat || null;

  if (
    sortBy === '' &&
    searchTerm === '' &&
    !customTags.length &&
    !popularTags.length &&
    !filters.length &&
    !category
  )
    return null;

  const searchTags = [];
  const filterTags = [];

  // eslint-disable-next-line
  for (const text of customTags) {
    searchTags.push({ group: 'CUSTOM', text });
  }

  // eslint-disable-next-line
  for (const text of popularTags) {
    searchTags.push({ group: 'POPULAR', text });
  }

  // eslint-disable-next-line
  for (const filter of filters) {
    const fil = filter.split(':');
    filterTags.push({ id: fil[0], value: fil[1] });
  }

  return { category, searchTerm, sortBy, searchTags, filterTags };
};

export const getSearchQueryFromApiRequest = apiRequest => {
  const apiRequestFilterTags = apiRequest.filterTags || [];
  const customTagsArray = [];
  const popularTagsArray = [];
  const filtersArray = [];
  let category = '';
  let searchTerm = '';
  let customTags = '';
  let popularTags = '';
  let filterTags = '';
  let sortBy = '';

  // eslint-disable-next-line
  for (const tag of apiRequest.searchTags || []) {
    if (tag.group === 'CUSTOM') {
      customTagsArray.push(encodeURIComponent(tag.text));
    } else {
      popularTagsArray.push(encodeURIComponent(tag.text));
    }
  }

  // eslint-disable-next-line
  for (const filter of apiRequestFilterTags) {
    filtersArray.push(`${encodeURIComponent(filter.id)}:${encodeURIComponent(filter.value)}`);
  }
  searchTerm = apiRequest.searchTerm
    ? `&searchTerm=${encodeURIComponent(apiRequest.searchTerm)}`
    : '';
  sortBy = apiRequest.sortBy ? `&sortBy=${encodeURIComponent(apiRequest.sortBy)}` : '';
  customTags = customTagsArray.length > 0 ? `&ct=${customTagsArray.join()}` : '';
  popularTags = popularTagsArray.length > 0 ? `&pt=${popularTagsArray.join()}` : '';
  filterTags = apiRequestFilterTags.length > 0 ? `&flt=${filtersArray.join(';')}` : '';
  category = apiRequest.category ? `&cat=${apiRequest.category}` : '';

  return searchTerm + sortBy + customTags + popularTags + filterTags + category;
};

const makeQueryObject = queryStringOrObject =>
  isString(queryStringOrObject) ? queryString.parse(queryStringOrObject) : queryStringOrObject;

export const mergeQueryParams = (existingParams = {}, newParams = {}) => {
  const merged = `?${queryString.stringify({
    ...makeQueryObject(existingParams),
    ...makeQueryObject(newParams),
  })}`;
  return merged === '?' ? '' : merged;
};

export const mergeFilterParams = (existingParams, filterParams = {}) =>
  mergeQueryParams(existingParams, {
    cat: undefined,
    ct: undefined,
    pt: undefined,
    flt: undefined,
    ...makeQueryObject(filterParams),
  });
