import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const GET = apiPromiseTypes('addressSuggestion', 'get');
const GET_BY_ID = apiPromiseTypes('addressSuggestionById', 'get');
const RESET_ADDRESS_SUGGESTION = 'waitrose/address-suggestion/RESET_ADDRESS_SUGGESTION';
const RESET_ADDRESS_SUGGESTION_NOT_ADDRESS =
  'waitrose/address-suggestion/RESET_ADDRESS_SUGGESTION_NOT_ADDRESS';
const AMEND_FOUND_ADDRESSEE = 'waitrose/address-suggestion/AMEND_FOUND_ADDRESSEE';
const REPORT_ADDRESS_SUGGESTION_NO_RESULTSET =
  'waitrose/address-suggestion/REPORT_ADDRESS_SUGGESTION_NO_RESULTSET';
const REPORT_ADDRESS_SUGGESTION_RESULTSET =
  'waitrose/address-suggestion/REPORT_ADDRESS_SUGGESTION_RESULTSET';
const REPORT_ADDRESS_SUGGESTION_MODIFIED =
  'waitrose/address-suggestion/REPORT_ADDRESS_SUGGESTION_MODIFIED';
const UPDATE_NAVIGATION = 'waitrose/address-suggestion/UPDATE_NAVIGATION';

export {
  GET,
  GET_BY_ID,
  RESET_ADDRESS_SUGGESTION,
  RESET_ADDRESS_SUGGESTION_NOT_ADDRESS,
  AMEND_FOUND_ADDRESSEE,
  REPORT_ADDRESS_SUGGESTION_NO_RESULTSET,
  REPORT_ADDRESS_SUGGESTION_RESULTSET,
  REPORT_ADDRESS_SUGGESTION_MODIFIED,
  UPDATE_NAVIGATION,
};
