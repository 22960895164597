import createReducer from 'redux/createReducer';
import * as paymentTypes from 'redux/modules/payment/actions/types';
import * as checkoutTypes from '../actions/types';
import initialState from '../state/initial-state';
import { checkoutLoaded } from './checkout-loaded';
import checkoutSessionTimeout from './checkout-session-timeout';
import clearCheckoutErrors from './clear-checkout-errors';
import placeAndUpdateOrderFailure from './place-and-update-order-failure';
import placeAndUpdateOrderRequest from './place-and-update-order-request';
import placeAndUpdateOrderSuccess from './place-and-update-order-success';
import { placeOrderOrigin } from './place-order-origin';
import { placeOrderOriginMobileAvailable } from './place-order-origin-mobile-available';
import { setSaveNewCart } from './set-save-new-card';
import stopWaiting from './stop-waiting';
import updateTRASessionId from './update-tra-session-id';
import waiting from './waiting';
import {
  getFailedOrderPaymentFailure,
  getFailedOrderPaymentRequest,
  getFailedOrderPaymentSuccess,
} from './get-failed-order-payment';

const actionTypeReducerMap = [
  [checkoutTypes.CHECKOUT_LOADED, checkoutLoaded],
  [checkoutTypes.CLEAR_CHECKOUT_ERRORS, clearCheckoutErrors],
  [checkoutTypes.PLACE_AND_UPDATE_ORDER.failure, placeAndUpdateOrderFailure],
  [checkoutTypes.PLACE_AND_UPDATE_ORDER.request, placeAndUpdateOrderRequest],
  [checkoutTypes.PLACE_AND_UPDATE_ORDER.success, placeAndUpdateOrderSuccess],
  [checkoutTypes.PLACE_ORDER_ORIGIN, placeOrderOrigin],
  [checkoutTypes.PLACE_ORDER_ORIGIN_MOBILE_AVAILABLE, placeOrderOriginMobileAvailable],
  [checkoutTypes.SAVE_NEW_CARD, setSaveNewCart],
  [checkoutTypes.STOP_WAITING, stopWaiting],
  [checkoutTypes.TRA_SESSION_ID, updateTRASessionId],
  [checkoutTypes.WAITING, waiting],
  [paymentTypes.GET_FAILED_ORDER_PAYMENT.failure, getFailedOrderPaymentFailure],
  [paymentTypes.GET_FAILED_ORDER_PAYMENT.request, getFailedOrderPaymentRequest],
  [paymentTypes.GET_FAILED_ORDER_PAYMENT.success, getFailedOrderPaymentSuccess],
  [paymentTypes.GET_PAYMENT_CARDS.failure, stopWaiting],
  [paymentTypes.GET_PAYMENT_CARDS.request, waiting],
  [paymentTypes.GET_PAYMENT_CARDS.success, stopWaiting],
  [paymentTypes.START_PAYMENT_SESSION_TIMEOUT, checkoutSessionTimeout],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
