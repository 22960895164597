import locator from 'utils/locator';
import queryString from 'query-string';

export function redirectFromSearchResults(redirectUrl) {
  // Add http to the URL if it doesn't exist to ensure it's not relative.
  // this is unlikely to be required as AEM will validate for a full valid URL.
  const url =
    redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')
      ? redirectUrl
      : `${locator.protocol}//${redirectUrl}`;

  locator.replace(url);
}

export function getUrlParam(param, pURL) {
  const url = pURL || locator.href;
  const name = param.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function pathJoin(...parts) {
  return parts
    .filter(empty => empty)
    .map((part, index, filteredParts) => {
      let trimmed = part;
      const isBase = index === 0;
      const isTerminal = index === filteredParts.length - 1;
      trimmed = !isBase && trimmed.startsWith('/') ? trimmed.substr(1) : trimmed;
      trimmed = !isTerminal && trimmed.endsWith('/') ? trimmed.slice(0, -1) : trimmed;
      return trimmed;
    })
    .join('/');
}

export const preserveStubsUrl = url => {
  const [path = '', hash = ''] = url.split('#');
  const [locatorPath = ''] = locator.href.split('#');

  const { query: { usestubs, stubflag, noexternal, mobileApp, lighthouse, experimentflag } = {} } =
    queryString.parseUrl(locatorPath);
  const next = queryString.parseUrl(path);
  const finalSearch = queryString.stringify(
    {
      ...next.query,
      noexternal,
      ...(experimentflag && { experimentflag }),
      usestubs,
      ...(stubflag && { stubflag }),
      mobileApp,
      lighthouse,
    },
    { sort: false },
  );

  return `${next.url}${finalSearch ? '?' : ''}${finalSearch}${hash ? '#' : ''}${hash}`;
};
