export const pending = state => ({
  ...state,
  loading: true,
});

export const fulfilled = state => ({
  ...state,
  loading: false,
  error: null,
});

export const rejected = (state, action) => ({
  ...state,
  loading: false,
  error: action.error.status,
});
