const selectedAddress = (state, action) => {
  if (action.position) {
    return {
      event: 'selected_delivery_address_card',
      delivery_address_position: action.position,
    };
  }
  return null;
};

export { selectedAddress };
