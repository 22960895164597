const buildAddressModel = flatAddress => {
  const {
    line1 = null,
    line2 = null,
    line3 = null,
    region = null,
    town = null,
    country = null,
    postalCode = null,
    title = null,
    firstName = null,
    lastName = null,
    contactNumber = null,
    addressee = null,
    suggestionId = null,
    isContactAddress = null,
  } = flatAddress;
  if (addressee) {
    return flatAddress;
  }
  return {
    ...{
      line1,
      line2,
      line3,
      town,
      region,
      country,
      postalCode,
      suggestionId,
      isContactAddress,
    },
    addressee:
      firstName && lastName
        ? {
            title,
            firstName,
            lastName,
            contactNumber,
          }
        : null,
  };
};

const sortAddresses = (addresses = [], selectedAddressId) =>
  addresses.reduce((sortedAddresses, add) => {
    if (add.isContactAddress) return [add, ...sortedAddresses];

    if (add.id === selectedAddressId) {
      const [firstAddress, ...rest] = sortedAddresses;

      return firstAddress && firstAddress.isContactAddress
        ? [firstAddress, add, ...rest]
        : [add, ...sortedAddresses];
    }

    return [...sortedAddresses, add];
  }, []);

export { buildAddressModel, sortAddresses };
