import initialState from '../state/initial-state';

const reducer = state => ({
  ...state,
  formData: {
    ...initialState.formData,
  },
  sessionTimedOut: true,
});

export default reducer;
