import { apiCallback } from 'api';

const definition = {
  service: 'branch',
  endpoint: 'branches/${branchId}',
  verbs: ['get'],
  defaultVersion: 3,
};

export default {
  get: apiCallback(definition, 'get'),
};
