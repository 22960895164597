import { getCurrentPageDepth } from 'redux/modules/page/selectors';
import { getGridItemProducts } from 'redux/modules/search-and-browse/selectors';
import { getSearchAndBrowseSearchType } from 'redux/modules/search-and-browse/selectors/search';

import productGridImpressions from 'redux/middleware/analytics/actions-to-track/products-and-components/product-grid-impressions';

export default productGridImpressions({
  fetch: getGridItemProducts, // TODO [BM]: update underlying implementations to use productGrid redux module
  list: getSearchAndBrowseSearchType,
  taxonomy: getCurrentPageDepth,
});
