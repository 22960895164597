import trolleyItems from 'api/definitions/trolleyItems';
import {
  TROLLEY_ITEM_UPDATING,
  TROLLEY_ITEM_UPDATED,
  TROLLEY_ITEM_UPDATE_FAILED,
  UPDATE_SHOPPER_NOTES,
} from 'redux/modules/trolley/actions/types';

import { getItemById } from 'redux/modules/trolley/selectors/get-item';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';

export function saveShopperNotes(trolleyItemId, notes) {
  return (dispatch, getState) => {
    const item = getItemById(getState(), trolleyItemId);

    dispatch({
      type: UPDATE_SHOPPER_NOTES,
      notes,
      trolleyItemId,
    });

    dispatch({ type: TROLLEY_ITEM_UPDATING });
    return trolleyItems
      .updateItem({
        trolleyItemId,
        body: { ...item, noteToShopper: notes },
      })({ dispatch, getState })
      .then(response => {
        const { data: { updateTrolleyItem } = {}, errors } = response;

        if (errors || !updateTrolleyItem || updateTrolleyItem.failures) {
          let error = new Error('Graph call succeeded but returned no data or an unknown error');

          if (errors?.length) {
            [error] = errors;
          }

          if (updateTrolleyItem?.failures?.length) {
            const { failures } = updateTrolleyItem;
            [error] = failures;
          }

          dispatch({
            error:
              error?.message ?? 'Graph call succeeded but returned no data or an unknown error',
            type: TROLLEY_ITEM_UPDATE_FAILED,
            trolleyItem: item,
          });

          return handleUpdateTrolleyErrors(dispatch, getState)(error);
        }

        return dispatch({
          type: TROLLEY_ITEM_UPDATED,
          result: updateTrolleyItem,
        });
      })
      .catch(err => {
        dispatch({
          error: err?.message ?? 'Update trolley item call to graph failed',
          type: TROLLEY_ITEM_UPDATE_FAILED,
          trolleyItem: item,
        });
        return handleUpdateTrolleyErrors(dispatch, getState)(err);
      });
  };
}
