import createReducer from 'redux/createReducer';
import initialState from './initial-state';

import * as instantCheckoutTypes from '../actions/types';
import {
  confirmInstantCheckout,
  startInstantCheckout,
  finishedInstantCheckout,
} from './instant-checkout-status';

const actionTypeReducerMap = [
  [instantCheckoutTypes.CONFIRM_INSTANT_CHECKOUT, confirmInstantCheckout],
  [instantCheckoutTypes.STARTED_INSTANT_CHECKOUT, startInstantCheckout],
  [instantCheckoutTypes.FINISHED_INSTANT_CHECKOUT, finishedInstantCheckout],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;

export default createReducer(initialState, actionTypeReducerMap);
