import { FORMAT, dayjs } from 'utils/date';

import { confirmAmendOrderCutOffModal } from 'constants/modals';

import { openModal } from 'redux/modules/common-modal/actions';

export const showAmendOrderCutoffModal = amendOrderCutOff => dispatch => {
  const amendOrderCutOffFormatted = dayjs(amendOrderCutOff)
    .tz()
    .format(FORMAT.SHORT_DAY_MONTH_TIME);

  dispatch(
    openModal({
      ...confirmAmendOrderCutOffModal,
      messageTextParams: {
        amendOrderCutOff: amendOrderCutOffFormatted,
      },
    }),
  );
};
