import { deepFreeze } from 'utils/deepFreeze';

export interface CookieState {
  analytics: boolean | null;
  experimental: boolean;
  functional: boolean | null;
  marketing: boolean | null;
  showInitialModal: boolean;
  showPrivacyControls: boolean;
  returnUrl: string;
}

const initialState: Readonly<CookieState> = deepFreeze({
  analytics: null,
  experimental: false,
  functional: null,
  marketing: null,
  showInitialModal: false,
  showPrivacyControls: false,
  returnUrl: '',
});

export default initialState;
