import React, { Component } from 'react';
import { arrayOf, element, oneOfType, string } from 'prop-types';

import { log } from 'utils/error-logging';

import CheckoutHeader from 'components/NewCheckout/CheckoutHeader/CheckoutHeader';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, extra) {
    const { logger, section } = this.props;
    log(error, { logger, section, extra });
  }

  render() {
    const { hasError } = this.state;
    const { children, section } = this.props;
    const isCheckout = section === 'checkout';

    if (hasError)
      return (
        <>
          {isCheckout && <CheckoutHeader />}
          <div className="container-fluid">
            <div className="row col-xs-12of12">
              <Typography styleAs="screenHeading" element="h1">
                Sorry, something has gone wrong...
              </Typography>
              {isCheckout && (
                <Typography styleAs="paragraph">
                  There may be a problem with your order that is preventing you from checking out.
                </Typography>
              )}
              <Typography styleAs="paragraph">
                Please contact Customer Care on <a href="tel:0800188884">0800 188 884</a> for
                assistance.
              </Typography>
            </div>
          </div>
        </>
      );

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  logger: string,
  section: string,
};

ErrorBoundary.defaultProps = {
  logger: 'ErrorBoundary',
  section: 'Components',
};

export default ErrorBoundary;
