import { createSelector } from 'reselect';
import get from 'lodash/get';

import { C62 } from 'constants/weightOptions';

import { getFavouritesProducts } from 'redux/modules/favourites/selectors/get-favourites-products';
import { getFavouritesRecommendedProducts } from 'redux/modules/favourites/selectors/get-favourites-recommendations';
import { getGridItems, getRecommendations } from 'redux/modules/search-and-browse/selectors';
import { getBundleGroups } from 'redux/modules/bundle/selectors';
import { getMealDealRecommendedProducts } from 'redux/modules/meal-deals/selectors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { getLocationBreadcrumbs } from 'redux/modules/taxonomy/selectors';
import { getOrderedItems } from 'redux/modules/trolley/selectors/get-items';
import {
  getCustomerOrders,
  getOrderRecommendationsByCustomerOrderId,
} from 'redux/modules/entities/selectors/customer-orders';
import { getOrderIdFromPath } from 'redux/modules/routing/selectors';

const EMPTY_ARRAY = [];

const getProductGridItems = state => state.productGrid?.items;

const getItemQuantity = (item, weighted) =>
  weighted ? '1' : String(item?.quantity?.amount ?? null);

const getItemUnitPrice = (item, weighted) =>
  String(
    (weighted ? get(item, 'totalPrice.amount') : get(item, 'retailPrice.price.amount')) || null,
  );

const isWeightedItem = item => item?.quantity?.uom !== C62;

const trolleyQuantityAndPrice = (trolleyItem = {}) => {
  const weighted = isWeightedItem(trolleyItem);

  return {
    quantity: getItemQuantity(trolleyItem, weighted),
    unitPrice: getItemUnitPrice(trolleyItem, weighted),
  };
};

const propertyMapper =
  prop =>
  (map = EMPTY_ARRAY) =>
    map.map(item => item[prop]).filter(item => item);

const getLinenumbers = propertyMapper('searchProduct');
const getUrlNames = propertyMapper('urlName');

export const getMonetateBreadcrumbs = createSelector([getLocationBreadcrumbs], breadcrumbs =>
  breadcrumbs ? getUrlNames(breadcrumbs) : EMPTY_ARRAY,
);

export const getMonetateBundleProductLineNumbers = createSelector([getBundleGroups], groups =>
  groups.flatMap(({ products }) => getLinenumbers(products)),
);

const getMonetateProductLineNumbers = createSelector([getGridItems], products =>
  products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY,
);

const getMonetateProductRecommendations = createSelector([getRecommendations], products =>
  products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY,
);

export const getMonetateCombinedProducts = createSelector(
  [getMonetateProductLineNumbers, getMonetateProductRecommendations],
  (products, recommendations) => {
    const combinedProducts = [...products];

    recommendations.forEach(rec => {
      if (!combinedProducts.includes(rec)) {
        combinedProducts.push(rec);
      }
    });

    return combinedProducts;
  },
);

export const getMonetateCartItems = createSelector([getOrderedItems], (trolleyItems = []) =>
  trolleyItems.map(trolleyItem => ({
    ...trolleyQuantityAndPrice(trolleyItem),
    currency: get(trolleyItem, 'retailPrice.price.currencyCode', null),
    productId: trolleyItem.lineNumber,
  })),
);

const getMonetateFavouriteProductLineNumbers = createSelector([getFavouritesProducts], products =>
  products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY,
);

const getMonetateFavouriteRecommendations = createSelector(
  [getFavouritesRecommendedProducts],
  products => (products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY),
);

export const getMonetateCombinedFavourites = createSelector(
  [getMonetateFavouriteProductLineNumbers, getMonetateFavouriteRecommendations],
  (products, recommendations) => {
    const combinedProducts = [...products];

    recommendations.forEach(rec => {
      if (!combinedProducts.includes(rec)) {
        combinedProducts.push(rec);
      }
    });

    return combinedProducts;
  },
);

const getOrderId = state => getOrderIdFromPath(state);

const getOrderRecProducts = createSelector(
  [getCustomerOrders, getOrderId],
  (customerOrders, orderId) =>
    getOrderRecommendationsByCustomerOrderId({ entities: { customerOrders } }, orderId),
);

const getMonetateProductGridLineNumbers = createSelector([getProductGridItems], products =>
  products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY,
);

const getMonetateOrderRecommendations = createSelector([getOrderRecProducts], products =>
  products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY,
);

export const getMonetateCombinedOrderProducts = createSelector(
  [getMonetateProductGridLineNumbers, getMonetateOrderRecommendations],
  (products, recommendations) => {
    const combinedProducts = [...products];

    recommendations.forEach(rec => {
      if (!combinedProducts.includes(rec)) {
        combinedProducts.push(rec);
      }
    });

    return combinedProducts;
  },
);

const getMonetateMealDealRecommendations = createSelector(
  [getMealDealRecommendedProducts],
  products => (products?.length > 0 ? getLinenumbers(products) : EMPTY_ARRAY),
);

export const getMonetateCombinedMealDealLineNumbers = createSelector(
  [getMonetateProductLineNumbers, getMonetateMealDealRecommendations],
  (lineNumbers, recommendations) => {
    const combinedLineNumbers = [...lineNumbers];

    recommendations.forEach(rec => {
      if (!combinedLineNumbers.includes(rec)) {
        combinedLineNumbers.push(rec);
      }
    });

    return combinedLineNumbers;
  },
);

export const getMonetatePurchaseRows = createSelector(
  [getCustomerOrderId, getOrderedItems],
  (customerOrderId, trolleyItems = []) =>
    trolleyItems.map(trolleyItem => ({
      ...trolleyQuantityAndPrice(trolleyItem),
      currency: trolleyItem?.retailPrice?.price?.currencyCode ?? 'null',
      productId: trolleyItem?.lineNumber ?? 'null',
      purchaseId: customerOrderId,
    })),
);
