const reducer = (state, action) => {
  const addresses =
    action.result &&
    action.result.data &&
    action.result.data.addresses &&
    Array.isArray(action.result.data.addresses)
      ? action.result.data.addresses.slice()
      : [];

  return {
    ...state,
    loading: false,
    addresses,
    error: null,
  };
};

export default reducer;
