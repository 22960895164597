import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import {
  SHOW_CHANGE_PASSWORD_MODAL,
  SHOW_INVALID_PASSWORD_ERROR,
  HIDE_INVALID_PASSWORD_ERROR,
  HIDE_CHANGE_PASSWORD_MODAL,
} from '../../types';

const initialState = deepFreeze({
  isOpen: false,
  showAlertBox: false,
});

const changePasswordReducer = (state, { payload }) => ({
  ...state,
  ...payload,
});

const changePasswordModalReducerMap = new Map([
  [SHOW_CHANGE_PASSWORD_MODAL, changePasswordReducer],
  [HIDE_CHANGE_PASSWORD_MODAL, changePasswordReducer],
  [SHOW_INVALID_PASSWORD_ERROR, changePasswordReducer],
  [HIDE_INVALID_PASSWORD_ERROR, changePasswordReducer],
]);

export default createReducer(initialState, changePasswordModalReducerMap);
