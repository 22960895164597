import { RECORD_EXPERIMENT_ID_VIEWED } from 'redux/modules/experiments/actions/types';
import { getViewedExperiments, isExperimentLive } from 'redux/modules/experiments/selectors';

export const trackExperimentView =
  ({ id, variant, trackAll }) =>
  (dispatch, getState) => {
    /*
    ensure we only track LIVE experiments and once per page view
  */
    const state = getState();
    if (!isExperimentLive(state, id)) return;

    const hasAlreadyViewedExperiment = getViewedExperiments(state).includes(id);
    if (trackAll || !hasAlreadyViewedExperiment) {
      dispatch({ type: RECORD_EXPERIMENT_ID_VIEWED, id, variant });
    }
  };
