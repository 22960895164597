/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { KEY_DOWN, KEY_ENTER, KEY_UP, KEY_TAB } from 'constants/keys';
import { refType } from 'constants/types/ref';

import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import Highlight from './Highlight';

import styles from './AutoComplete.scss';

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: null,
    };
  }

  componentDidMount() {
    const { inputRef } = this.props;

    if (inputRef && inputRef.current && inputRef.current.addEventListener) {
      inputRef.current.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    const { inputRef } = this.props;
    if (inputRef && inputRef.current && inputRef.current.removeEventListener) {
      inputRef.current.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = event => {
    const { active, suggestions, onClear } = this.props;
    const { selectedIndex } = this.state;

    if (!active) return;

    switch (event.keyCode) {
      case KEY_DOWN: {
        if (selectedIndex === null) {
          this.setState({ selectedIndex: 0 });
          break;
        }

        const newSelectedIndex = selectedIndex + 1;
        if (newSelectedIndex < suggestions.length) {
          this.setState({ selectedIndex: newSelectedIndex });
        }
        break;
      }
      case KEY_UP: {
        event.preventDefault();
        const newSelectedIndex = selectedIndex - 1;
        if (newSelectedIndex >= 0) {
          this.setState({ selectedIndex: newSelectedIndex });
        } else {
          this.setState({ selectedIndex: null });
        }
        break;
      }
      case KEY_ENTER: {
        if (selectedIndex !== null) {
          this.handleItemClick(suggestions[selectedIndex]);
        }
        break;
      }
      case KEY_TAB: {
        onClear();
        this.setState({ selectedIndex: null });
        break;
      }

      default: {
        this.setState({ selectedIndex: null });
      }
    }
  };

  handleItemClick = suggestion => {
    const { onSelectItem } = this.props;
    onSelectItem(suggestion);
  };

  render() {
    const { active, homepage, inline, searchTerm, suggestions } = this.props;
    const { selectedIndex } = this.state;

    if (!active || suggestions.length < 1) return null;

    return (
      <section
        className={classNames(styles.autoComplete, {
          [styles.homepage]: homepage,
          [styles.inline]: inline,
        })}
        data-testid="autocomplete"
      >
        <ul className={styles.suggestions} id="autocomplete-listbox" role="listbox">
          {suggestions.map((suggestion, index) => (
            <li
              aria-selected={index === selectedIndex}
              className={classNames(styles.suggestion, {
                [styles.selected]: index === selectedIndex,
              })}
              data-testid="suggestion"
              key={suggestion}
              id="autocomplete-suggestion"
              onClick={() => this.handleItemClick(suggestion)}
              role="option"
            >
              <Highlight match={searchTerm} string={suggestion} />
            </li>
          ))}
        </ul>
        <ScreenReaderAnnouncement message={`${suggestions.length} suggestions available.`} />
      </section>
    );
  }
}

AutoComplete.displayName = 'AutoComplete';

AutoComplete.propTypes = {
  active: PropTypes.bool,
  homepage: PropTypes.bool,
  inline: PropTypes.bool,
  inputRef: refType,
  onClear: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
};

AutoComplete.defaultProps = {
  active: false,
  homepage: false,
  inline: false,
  inputRef: null,
  searchTerm: '',
  suggestions: [],
};
