import { RESET_MY_WAITROSE_REQUEST_ERROR } from 'redux/modules/mywaitrose/actions/types';
import { MyWaitroseState } from 'redux/modules/mywaitrose/state';

const resetMyWaitroseRequestError = (state: MyWaitroseState) => ({
  ...state,
  request: {
    ...state.request,
    error: null,
  },
});

export default () => [[RESET_MY_WAITROSE_REQUEST_ERROR, resetMyWaitroseRequestError]] as const;
