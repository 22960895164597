import { createSelector, weakMapMemoize } from 'reselect';

import { getOrder } from './index';

const EMPTY_OBJECT = {};
const EMPTY_LIST = [];

const getCode = (state, code) => code;

const getPromoCodes = createSelector(getOrder, order => order?.promoCodes ?? EMPTY_OBJECT);

export const getPromoCodesList = createSelector(
  getPromoCodes,
  promoCodes => promoCodes.promoCodesList ?? EMPTY_LIST,
);

export const getQualifiedPromoCodes = createSelector(getPromoCodesList, promoCodes =>
  promoCodes.filter(({ status }) => status === 'APPLIED'),
);

export const getNonQualifiedPromoCodes = createSelector(getPromoCodesList, promoCodes =>
  promoCodes.filter(({ status }) => status !== 'APPLIED'),
);

export const getIsAddingPromoCode = createSelector(
  getPromoCodes,
  promoCodes => promoCodes.isAdding ?? false,
);

export const getRequestedPromoCode = createSelector(
  [getPromoCodes],
  promoCodes => promoCodes.requestedPromoCode,
);

export const getIsRemovingPromoCode = createSelector(
  getPromoCodes,
  promoCodes => promoCodes.isRemoving ?? false,
);

export const getPromoCodeServerErrorMessage = createSelector(
  [getPromoCodes, getIsAddingPromoCode],
  ({ serverError }, isAddingPromoCode) => {
    if (!serverError) {
      return undefined;
    }

    const { status, message } = serverError;
    if (!status || status >= 500 || !message) {
      return isAddingPromoCode
        ? 'Sorry, we could not apply this code/voucher to your order. If the problem continues, try again later.'
        : 'Sorry, we could not remove this code/voucher from your order. If the problem continues, try again later.';
    }

    return message;
  },
);

export const getPromoCodeByCode = createSelector(
  [getPromoCodesList, getCode],
  (promoCodesList, code) => promoCodesList.find(promoCode => promoCode.code === code),
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);

export const getQualifiedPromoCodesInline = createSelector(getQualifiedPromoCodes, promoCodes =>
  promoCodes.length ? promoCodes.map(({ code }) => code).join('|') : '',
);

export const getQualifiedPromoCodesDescriptionsInline = createSelector(
  getQualifiedPromoCodes,
  promoCodes =>
    promoCodes.length ? promoCodes.map(({ description }) => description).join('|') : '',
);
