import urls from 'constants/urls';
import { pathJoin } from 'utils/general';
import locator from 'utils/locator';
import { getAppType } from '.';
import { Actions, postNativeAction } from './action';

// https://johnlewispartnership.atlassian.net/wiki/spaces/WD/pages/197788914/Deep+Universal+Linking+supported+by+Mobile+Apps
export const appLinks = {
  /** Book slot grid screen */
  bookslot: 'waitrose://app/home/book-slot',
  /** Browse page */
  browse: 'waitrose://app/browse',
  customerCare: 'tel:0800188884',
  /** Orders list screen */
  myOrders: 'waitrose://app/home/orders',
  /** Trolley page */
  trolley: 'waitrose://app/trolley',
  /** Account page */
  myDetails: 'waitrose://app/account',
};

export function navigateToAppPage(appPage: keyof typeof appLinks) {
  return postNativeAction({ type: Actions.Navigate, payload: appLinks[appPage] });
}

export function deepLinkForWebUrl(url: string | null | undefined) {
  // Note that if `null` is returned the app will send the "dismissDialog" event when clicked
  if (!url) return null;
  if (url.startsWith(urls.checkout)) return null;
  if (url.startsWith(urls.browse)) return appLinks.browse;
  if (url.startsWith(urls.myOrdersPage)) return appLinks.myOrders;
  if (url.startsWith(urls.reselectCollectionService)) return appLinks.bookslot;
  if (url.startsWith(urls.serviceSelection)) return appLinks.bookslot;
  if (url.startsWith(urls.myDetailsPage)) return appLinks.myDetails;

  return appLinks.trolley;
}

export function getWebviewOrderConfirmationSuffix() {
  const query = new URLSearchParams(locator.search);

  if (query.get('lighthouse') === '1') return null;

  switch (getAppType()) {
    case 'android':
      return 'OrderConfirmation';
    case 'ios':
      return 'orderConfirmationAWS';
    default:
      return null;
  }
}

export function goToOrderConfirmationWithSuffix() {
  const suffix = getWebviewOrderConfirmationSuffix();

  if (!suffix || locator.pathname.includes(suffix)) return;

  const url = new URL(locator.href);
  url.pathname = pathJoin(url.pathname, suffix);
  locator.href = url.toString();
}
