/*
  generates an object to provide all common attributes to analytics
  ```
  {
    page: {
      browse_category: undefined,
      platform: undefined,
      path: '/ecom/shop/search', // extract from url
      previous_path: '/ecom/shop/groceries', // extract from redux/midleware/router/index.js
      site_section: undefined,
      type: 'page-type', // extract from url
    },
  }
  ```
 */
import deepmerge from 'deepmerge';

import urls from 'constants/urls';

import { getPageTitle } from 'redux/middleware/analytics/actions-to-track/page/get-page-title';
import {
  getPathname,
  getPreviousPath,
  getRoutingPageType,
  getLocation,
} from 'redux/modules/routing/selectors';
import { splitPath } from 'utils/get-page-type';
import pageUnloadEvent from 'constants/page-unload-event';
import { impressionsService } from 'analytics/product-impressions-tracking';

const notAvailableAttributes = {
  page: {
    browse_category: undefined,
    platform: undefined,
    site_section: undefined,
  },
};

export const categoryLevels = [1, 2, 3, 4, 5, 6].map(pos => `category_level_${pos}`);

export default function transform(state) {
  impressionsService.flushProductImpressions();
  const { search } = getLocation(state);
  const searchFiltered = search && search.match(/sortBy/) !== null;
  const completePreviousPath = getPreviousPath(state);
  let previousPath = completePreviousPath;

  if (completePreviousPath?.indexOf(urls.search) >= 0) {
    previousPath = completePreviousPath?.split('?')?.[0];
  }

  const type = getRoutingPageType(state);

  const categories =
    type === 'browse'
      ? splitPath(getPathname(state)).filter(segment => !['browse', 'shop'].includes(segment))
      : [];

  const availableAttributes = {
    event: 'virtual_page_view',
    page: {
      path: getPathname(state),
      previous_path: previousPath,
      title: getPageTitle(state),
      type,
      ...categoryLevels.reduce((acc, cat, key) => {
        acc[cat] = categories[key];
        return acc;
      }, {}),
    },
  };

  if (searchFiltered) {
    return null;
  }
  const virtualPageViewEvent = deepmerge(notAvailableAttributes, availableAttributes);

  return completePreviousPath ? [pageUnloadEvent, virtualPageViewEvent] : virtualPageViewEvent;
}
