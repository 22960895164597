import { apiCallback } from 'api';

const definition = {
  service: 'memberships',
  endpoint: 'memberships',
  defaultVersion: 2,
  verbs: ['get'],
};

export default {
  get: apiCallback(definition, 'get'),
};
