// TODO: [SSR][WARN] Is this triggered on SSR?
// eslint-disable-next-line no-restricted-imports
import { getCurrentBreakpoint } from 'utils/mediaQueries';

import { getGridItems } from 'redux/modules/search-and-browse/selectors';
import { createSelector } from 'reselect';
import { calculateProductsGridPositions } from 'utils/analytics';
import { getMissedOffersItems } from 'redux/modules/missed-offers/selectors';
import { getOrderedProductsByPromotionId } from 'redux/modules/promotions/selectors';

const getProductGridPositions = createSelector(getGridItems, calculateProductsGridPositions);

export const getVisibleGridPosition = (state, lineNumber) => {
  const positions = getProductGridPositions(state);
  const currentBreakpoint = getCurrentBreakpoint();
  return positions[lineNumber]?.[currentBreakpoint];
};

export const getMissedOfferPosition = (state, lineNumber, promotionId) => {
  if (!promotionId) return undefined;
  const missedOffersProducts = getMissedOffersItems(state).flatMap(promoId =>
    getOrderedProductsByPromotionId(state, promoId),
  );
  const position = missedOffersProducts.indexOf(lineNumber) + 1;
  return position > 0 ? position : undefined;
};
