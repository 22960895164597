import accounts from 'api/definitions/accounts';

import { GET } from 'redux/modules/accounts/actions/types';

const getCustomerAccount = () => dispatch =>
  dispatch({
    types: GET.triplet,
    apiCall: accounts.get(),
  });

export { getCustomerAccount };
