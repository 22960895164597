import addressDeliveryNote from 'api/definitions/addressDeliveryNote';
import { UPDATE_DELIVERY_NOTE } from 'redux/modules/address/actions/types';

export const updateDeliveryNote =
  ({ addressId, deliveryNote, iHandleStatusCodes = [] }) =>
  dispatch =>
    dispatch({
      apiCall: addressDeliveryNote.patch({
        addressId,
        body: { customerNote: { value: deliveryNote } },
        iHandleStatusCodes,
      }),
      types: UPDATE_DELIVERY_NOTE.triplet,
    });
