import {
  isBookedSlotADeliverySlot,
  isBookedSlotACollectionSlot,
  customerSlotSelector,
} from 'redux/modules/customer-slot/selectors';
import { getAddressById } from 'redux/modules/address/actions';
import { getBranchById } from 'redux/modules/branches/actions/get-branch-by-id';
import { getBranchById as branchByIdSelector } from 'redux/modules/branches/selectors';
import { getSelectedAddress } from 'redux/modules/slot-booking/selectors/get-selected-address';
import { getSelectedAddress as getCustomerAddressById } from 'redux/modules/address/selectors';
import { getSelectedBranchSelector } from 'redux/modules/slot-booking/selectors/get-selected-branch';
import { setSelectedAddress } from 'redux/modules/slot-booking/actions/set-selected-address';
import { setSelectedBranch } from 'redux/modules/slot-booking/actions/set-selected-branch';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { UPDATE_ADDRESS_NAME } from 'redux/modules/customer-slot/actions/types';

export const getCustomerSlotAddressAndBranch = () => async (dispatch, getState) => {
  const currentSlot = customerSlotSelector(getState());

  if (currentSlot && currentSlot.slotType !== SlotTypes.UNDEFINED) {
    const { addressId, branchId, addressPostcode } = currentSlot;
    let addressName = addressPostcode || '';
    let selectedBranch = getSelectedBranchSelector(getState());
    let selectedAddress = getSelectedAddress(getState());

    if (isBookedSlotADeliverySlot(getState())) {
      if (addressId && (!selectedAddress || selectedAddress?.id !== addressId)) {
        await dispatch(getAddressById(addressId));
        selectedAddress = getCustomerAddressById(getState(), addressId);
        dispatch(setSelectedAddress(selectedAddress));
      }
      if (selectedAddress?.line1 && selectedAddress?.postalCode) {
        addressName = `${selectedAddress.line1}, ${selectedAddress.postalCode}`;
      }
    }

    if (isBookedSlotACollectionSlot(getState())) {
      if (branchId && selectedBranch?.id !== branchId) {
        await dispatch(getBranchById(branchId));
        selectedBranch = branchByIdSelector(getState(), branchId);
        dispatch(setSelectedBranch(selectedBranch));
      }
      if (selectedBranch?.name) {
        addressName = `Waitrose & Partners ${selectedBranch.name}`;
      }
    }

    dispatch({
      type: UPDATE_ADDRESS_NAME,
      result: addressName,
    });
  }
};
