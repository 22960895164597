import get from 'lodash/get';
import setGiftCardsSuccess from 'redux/modules/order/reducer/set-gift-cards-success';
import setGiftCardsSuccessErrors from 'redux/modules/order/reducer/set-gift-cards-success-errors';

const reducer = (state, action) => {
  if (get(action, 'result.errors.length')) {
    return { ...state, waiting: false };
  }

  const failures = {
    result: get(action, 'result.data.setGiftCards.failures', null),
    type: 'REMOVE_GIFT_CARD_ERROR',
  };

  if (failures.result) {
    const giftCardsSuccessErrors = setGiftCardsSuccessErrors(state, failures);

    return {
      ...state,
      ...giftCardsSuccessErrors,
      waiting: false,
    };
  }

  const order = get(action, 'result.data.setGiftCards.order', {});
  const giftCardSuccess = setGiftCardsSuccess(state, order);

  return {
    ...state,
    ...giftCardSuccess,
  };
};

export default reducer;
