import { createSelector } from 'reselect';

import { getPaymentCardsLoaded } from 'redux/modules/payment/selectors/get-payment-cards-loaded';
import { activeOrderIsAmending } from 'redux/modules/order/selectors/order-status';
import getFirstSavedPaymentCard from 'redux/modules/payment/selectors/get-first-saved-payment-card';
import { PaymentCard } from 'redux/modules/payment/state/initial-state';
import { AmendOrderPaymentCard } from 'redux/modules/order/state/initial-state';

export const getAmendOrderPaymentCard = (state: WtrState) =>
  state && state.order && state.order.amendOrderPaymentCard;

export default createSelector<
  [
    typeof getPaymentCardsLoaded,
    typeof activeOrderIsAmending,
    typeof getAmendOrderPaymentCard,
    typeof getFirstSavedPaymentCard,
  ],
  AmendOrderPaymentCard | PaymentCard | undefined
>(
  getPaymentCardsLoaded,
  activeOrderIsAmending,
  getAmendOrderPaymentCard,
  getFirstSavedPaymentCard,
  (cardsLoaded, orderInAmendingState, amendOrderPaymentCard, firstSavedPaymentCard) => {
    if (
      (orderInAmendingState && !amendOrderPaymentCard?.maskedCardNumber) ||
      (!orderInAmendingState && !cardsLoaded)
    ) {
      return undefined;
    }

    return orderInAmendingState ? amendOrderPaymentCard : firstSavedPaymentCard;
  },
);
