import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOptimisticTrolleyItems } from 'redux/modules/trolley/selectors/get-optimistic-items';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import addNewProductToTrolleyAction from 'redux/modules/trolley/actions/add-new-product-to-trolley';
import updateTrolleyItemAction from 'redux/modules/trolley/actions/update-trolley-item';
import removeAllOptimisticTrolleyItemsAction from 'redux/modules/trolley/actions/remove-all-optimistic-trolley-items';
import removeOptimisticTrolleyItemAction from 'redux/modules/trolley/actions/remove-optimistic-trolley-item';
import removeMultipleOptimisticTrolleyItemsAction from 'redux/modules/trolley/actions/remove-multiple-optimistic-trolley-items';
import optimisticallyUpdateTrolleyItemAction from 'redux/modules/trolley/actions/optimistically-update-trolley-item';
import optimisticallyUpdateMultipleTrolleyItemsAction from 'redux/modules/trolley/actions/optimistically-update-multiple-trolley-items';
import { allowSubstitutions } from 'redux/modules/trolley/actions/allow-substitutions';
import { saveShopperNotes } from 'redux/modules/trolley/actions/save-shopper-notes';
import { toggleSubAll } from 'redux/modules/trolley/actions/allow-sub-all';
import { useOptimisticTrolleyHandler } from './useOptimisticTrolleyHandler';

const OptimisticTrolleyHandler = ({
  trolleyLoading,
  trolleyAddLoading,
  trolleyGetLoading,
  hasTrolleyError,
  optimisticTrolleyItems,
  trolleyItems,
  updateTrolleyItem,
  addNewProductToTrolley,
  removeAllOptimisticTrolleyItems,
  removeOptimisticTrolleyItem,
  removeMultipleOptimisticTrolleyItems,
  optimisticallyUpdateTrolleyItem,
  optimisticallyUpdateMultipleTrolleyItems,
  onAllowSubstitutions,
  onToggleSubAll,
  onSaveShopperNotes,
}) => {
  useOptimisticTrolleyHandler(
    hasTrolleyError,
    removeAllOptimisticTrolleyItems,
    trolleyLoading,
    trolleyAddLoading,
    trolleyGetLoading,
    optimisticTrolleyItems,
    removeOptimisticTrolleyItem,
    removeMultipleOptimisticTrolleyItems,
    optimisticallyUpdateTrolleyItem,
    optimisticallyUpdateMultipleTrolleyItems,
    trolleyItems,
    addNewProductToTrolley,
    updateTrolleyItem,
    onAllowSubstitutions,
    onToggleSubAll,
    onSaveShopperNotes,
  );
  return null;
};

OptimisticTrolleyHandler.displayName = 'OptimisticTrolleyHandler';

OptimisticTrolleyHandler.propTypes = {
  trolleyAddLoading: PropTypes.bool.isRequired,
  trolleyGetLoading: PropTypes.bool.isRequired,
  trolleyLoading: PropTypes.bool.isRequired,
  hasTrolleyError: PropTypes.bool.isRequired,
  optimisticTrolleyItems: PropTypes.arrayOf(
    PropTypes.shape({
      batchId: PropTypes.string,
      processing: PropTypes.bool.isRequired,
      lineNumber: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      trolleyItemId: PropTypes.number.isRequired,
      canSubstitute: PropTypes.bool.isRequired,
      quantity: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        uom: PropTypes.string.isRequired,
      }).isRequired,
      analytics: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        clickContext: PropTypes.any,
      }).isRequired,
    }),
  ).isRequired,
  trolleyItems: PropTypes.arrayOf(
    PropTypes.shape({
      lineNumber: PropTypes.string.isRequired,
      canSubstitute: PropTypes.bool.isRequired,
      quantity: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        uom: PropTypes.string.isRequired,
      }).isRequired,
      trolleyItemId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  updateTrolleyItem: PropTypes.func.isRequired,
  addNewProductToTrolley: PropTypes.func.isRequired,
  removeAllOptimisticTrolleyItems: PropTypes.func.isRequired,
  removeOptimisticTrolleyItem: PropTypes.func.isRequired,
  removeMultipleOptimisticTrolleyItems: PropTypes.func.isRequired,
  optimisticallyUpdateTrolleyItem: PropTypes.func.isRequired,
  optimisticallyUpdateMultipleTrolleyItems: PropTypes.func.isRequired,
  onAllowSubstitutions: PropTypes.func.isRequired,
  onToggleSubAll: PropTypes.func.isRequired,
  onSaveShopperNotes: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    trolleyAddLoading: state.trolley.loadingAdd,
    trolleyGetLoading: state.trolley.loadingOrder,
    trolleyLoading: state.trolley.loading,
    hasTrolleyError: !!state.trolley.errorResponse,
    optimisticTrolleyItems: getOptimisticTrolleyItems(state),
    trolleyItems: getTrolleyItems(state),
  }),
  {
    addNewProductToTrolley: addNewProductToTrolleyAction,
    updateTrolleyItem: updateTrolleyItemAction,
    removeAllOptimisticTrolleyItems: removeAllOptimisticTrolleyItemsAction,
    removeOptimisticTrolleyItem: removeOptimisticTrolleyItemAction,
    removeMultipleOptimisticTrolleyItems: removeMultipleOptimisticTrolleyItemsAction,
    optimisticallyUpdateTrolleyItem: optimisticallyUpdateTrolleyItemAction,
    optimisticallyUpdateMultipleTrolleyItems: optimisticallyUpdateMultipleTrolleyItemsAction,
    onAllowSubstitutions: allowSubstitutions,
    onSaveShopperNotes: saveShopperNotes,
    onToggleSubAll: toggleSubAll,
  },
)(OptimisticTrolleyHandler);
