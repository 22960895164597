import { JOTTER_PENDING, JOTTER_REJECTED } from 'redux/modules/multi-search/actions/types';

import { setJotter } from 'redux/modules/multi-search/actions/set-jotter';
import { getJotterStorage } from 'redux/modules/multi-search/selectors/get-jotter-storage';

export const migrateJotter = (dispatch, getState) => {
  dispatch({ type: JOTTER_PENDING });

  const jotter = getJotterStorage(getState());
  const jotterOptions = { anonymous: true };
  const anonymousValues = jotter.get(jotterOptions);

  if (anonymousValues && anonymousValues.length) {
    jotter.clear(jotterOptions);

    return dispatch(setJotter(anonymousValues));
  }

  return dispatch({ type: JOTTER_REJECTED });
};
