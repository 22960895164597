import { areNotEqual } from 'redux/listeners/matchers';

export const createStoreSubscriber = ({
  match = areNotEqual,
  once = false,
  onChange,
  select,
  store,
}) => {
  let currentState;
  let unsubscribe;

  function handleChange() {
    const { dispatch, getState } = store;
    const nextState = select(getState());

    if (match(currentState, nextState)) {
      currentState = nextState;
      onChange(dispatch, getState);

      if (once) unsubscribe();
    }
  }

  unsubscribe = store.subscribe(handleChange);

  return once ? null : unsubscribe;
};
