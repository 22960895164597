import { apiCallback } from 'api';

const accountsProfile = {
  service: 'account-orchestration',
  endpoint: 'accounts/profile',
  verbs: ['get', 'patch'],
  defaultVersion: 2,
};

export default {
  get: apiCallback(accountsProfile, 'get'),
  patch: apiCallback(accountsProfile, 'patch'),
};
