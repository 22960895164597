import history from 'utils/history';

export const decodeHttpEncodedQueryString = () => {
  const query = history.getLocation().search;
  const regex = /&(amp;)+/g;

  if (query && query.match(regex)) {
    history.replace({
      search: query.replace(regex, '&'),
    });
  }
};
