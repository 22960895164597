import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './Label.scss';

export const Label = ({
  children,
  className,
  disabled,
  id,
  label,
  labelForId,
  onClick,
  required,
  type,
}) => {
  const labelClasses = classnames(styles.label, className, {
    [styles.disabled]: disabled,
  });

  return (
    <label className={labelClasses} htmlFor={labelForId} id={id} onClick={onClick}>
      {label || children}
      {!required && type !== 'date' && <span> (optional)</span>}
    </label>
  );
};

Label.propTypes = {
  label: (props, propName, componentName) => {
    if (!props.label && !props.children) {
      return new Error(
        `One of props 'label' or 'children' was not specified in '${componentName}'.`,
      );
    }
  },
  children: PropTypes.node,
  labelForId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
};
