import { refreshShoppingContext } from 'redux/modules/sessions/refresh-shopping-context';
import { initialiseOrder } from 'redux/modules/order/actions';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

const refreshAndInitialiseOrder = () => (dispatch, getState) =>
  Promise.all([
    dispatch(initialiseOrder(getCustomerOrderId(getState()))),
    refreshShoppingContext(dispatch, getState),
  ]);

export default refreshAndInitialiseOrder;
