import productsByLinenumber from 'api/definitions/productsByLinenumber';

import { LEGACY_PRODUCTS_SCHEMA } from 'redux/schemas';
import { TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT } from 'redux/transforms';

const notFoundStatuses = [400, 404];

export const fetchMultipleProducts =
  types =>
  (lineNumbers = [], { listId, customerOrderId, start, size } = {}) => ({
    types,
    apiCall: productsByLinenumber.get({
      lineNumbers: [...new Set(lineNumbers)].join('+'),
      trolleyId: customerOrderId,
    }),
    lineNumbers,
    start,
    size,
    listId,
    schema: LEGACY_PRODUCTS_SCHEMA,
    transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
    doNotHandleHttpStatuses: notFoundStatuses,
  });
