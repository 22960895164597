import { dataLayer } from 'analytics/data-layer';

/**
 * @param {{id?: string, severity?: string, customerId?: string, title?: string}} params
 */
export const trackModalOpened = ({
  id,
  severity,
  customerId = 'not-set',
  title = 'not-set',
} = {}) => {
  if (id && severity) {
    dataLayer.push({
      event: 'modal_opened',
      customerId,
      modal: {
        id,
        severity,
        title,
      },
    });
  }
};

/**
 * @param {{id?: string, severity?: string, customerId?: string, title?: string, response?: string}} params
 */
export const trackModalClosed = ({
  id,
  severity,
  customerId = 'not-set',
  title = 'not-set',
  response = 'close',
} = {}) => {
  if (id && severity) {
    dataLayer.push({
      event: 'modal_closed',
      customerId,
      modal: {
        id,
        severity,
        title,
        response: response?.target ? 'close' : response,
      },
    });
  }
};

export const trackNotAdded = (amount, lineNumber) => {
  dataLayer.push({
    event: 'failed_to_add',
    unavailable: {
      product_id: lineNumber,
      quantity_attempted_to_add: amount,
    },
  });
};
