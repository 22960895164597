import { WtrRootState } from './create-store';

export function getStateAtNamespaceKey<T extends keyof WtrRootState>(
  state: Partial<WtrRootState>,
  key: T,
): WtrRootState[T] {
  const namespaceState = state[key];
  if (!namespaceState) {
    throw new Error(`Attempted to access state for an unregistered namespace at key ${key}`);
  }

  return namespaceState;
}
