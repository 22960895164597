import { ModalCloseOptions } from 'constants/modalCloseOptions';
import locator from 'utils/locator';
import { openModal } from 'redux/modules/common-modal/actions';
import { slotExpiredModal } from 'constants/modals';
import urls from 'constants/urls';

const openSlotExpiredModal = () =>
  openModal({
    ...slotExpiredModal,
    onCloseCallback: option => {
      if (option === ModalCloseOptions.OK) {
        locator.href = urls.serviceSelection;
      }
    },
  });

export default openSlotExpiredModal;
