import { apiCallback } from 'api';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';

const definition = {
  defaultVersion: 1,
  endpoint: 'orders/${customerOrderId}/partner-discount-card',
  service: 'order-orchestration',
  verbs: ['put', 'delete'],
  timeout: TIMEOUT_CONFIG,
};

export default {
  put: apiCallback(definition, 'put'),
  delete: apiCallback(definition, 'delete'),
};
