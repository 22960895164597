import { useState } from 'react';
import { MediaQueryMatchers, MediaQueryAllQueryable, useMediaQuery } from 'react-responsive';
import { useClientOnlyLayoutEffect } from './use-client-only-layout-effect';

/**
 * This hook provides the ability to query the viewport in JS for conditionally
 * rendering content.
 *
 * ---
 *
 * 🚨 Before using this component you should first consider:
 *
 * - Can you use CSS media queries in your component directly to handle
 * viewport specific requirements? If so, use them over this hook.
 *
 * Only if the layout of the page is sufficiently different between
 * viewports (such that CSS grid etc. would cause a11y issues) should we then
 * consider:
 *
 * - If the component requires user interaction to load, is lazy loaded, or
 * "deferred" in some other way, then using this hook is fine.
 * - If the component is part of the critical render path then it should use
 * the SSRMediaQuery component instead to ensure no CLS.
 *
 * ---
 *
 * **Description**
 *
 * This hook assumes that there is no match when in a server-side or
 * pre-hydration context and will always return `false`. This differs to the
 * SSRMediaQuery component which will always render in this context. This is
 * because this hook is intended for only client-side viewport related changes
 * that requires user interaction to load, is lazy loaded, or
 * "deferred" in some other way.
 *
 * Once hydrated this hook will accurately report whether there is a media
 * query match for the viewport.
 */
export const useClientSideMediaQuery = (
  settings: Partial<MediaQueryAllQueryable & { query?: string | undefined }>,
  device?: MediaQueryMatchers,
  callback?: (matches: boolean) => void,
): boolean => {
  const [isClientSideMatch, setIsClientSideMatch] = useState(false);
  const isMatch = useMediaQuery(settings, device, callback);

  useClientOnlyLayoutEffect(() => {
    setIsClientSideMatch(isMatch);
  }, [isMatch]);

  return isClientSideMatch;
};
