import queryString from 'query-string';
import { compose } from 'redux';

const normalisedQuery = compose(queryString.stringify, queryString.parse);

/*
 Compares the given URLs, returning true if they are logically the same
   -  ignores any '#' part
   -  allows the order of query parameters to change
   -  path part is compared case insensitively
*/
export const compareUrls = (urlA = '', urlB = '') => {
  if (urlA === urlB) return true;

  const [pathA, queryA] = urlA.split('#')[0].split('?');
  const [pathB, queryB] = urlB.split('#')[0].split('?');

  if (pathA.toLowerCase() !== pathB.toLowerCase()) return false;
  if (normalisedQuery(queryA) !== normalisedQuery(queryB)) return false;

  return true;
};
