import { rootPath } from 'utils/root-path';
import urls from 'constants/urls';
import { getPromotionDescription } from 'redux/modules/entities/selectors/promotions';
import { capitaliseWords, delimitWordsWithSpaces } from 'utils/format';
import { formatRecipeMetaTitle } from 'utils/format-recipe';
import { getSearchObject } from 'redux/modules/routing/selectors';

export const homepageTitle = 'Waitrose & Partners | Food | Drink | Recipes';

const capitaliseTitle = title => capitaliseWords(title, { ignore: ['myWaitrose'] });

const getOfferPageTitle = (pathname, state) => {
  const [promoId] = pathname.split('/').slice(-1);
  const promotionDescription = getPromotionDescription(state, promoId);
  const pageTitle = capitaliseTitle(promotionDescription || delimitWordsWithSpaces(promoId));
  return pageTitle ? `${pageTitle} - Offers` : 'Offers';
};

const getLastPartOfPath = pathname => {
  const pathNameArray = pathname.split('/');

  return capitaliseTitle(delimitWordsWithSpaces(pathNameArray[pathNameArray.length - 1]));
};

export const exactMatches = [
  ['/', homepageTitle],
  [urls.bookslot, 'Slot Booking'],
  [urls.bookDeliverySlot, 'Delivery Slot Booking'],
  [`${urls.bookDeliverySlot}/christmas`, 'Christmas Delivery Slot Booking'],
  [`${urls.bookDeliverySlot}/newyear`, 'New Year Delivery Slot Booking'],
  [`${urls.bookDeliverySlot}/thanksgiving`, 'Thanksgiving Delivery Slot Booking'],
  [urls.favourites, 'Favourites'],
  [urls.customerServiceHomePage, 'Customer Service'],
  [rootPath('content/inspiration'), 'Inspiration'],
  [urls.lists, 'Lists'],
  [urls.login, 'Log in'],
  [urls.signIn, 'Log in'],
  [urls.myDetailsPage, 'My Details'],
  [urls.myWaitrosePage, 'myWaitrose'],
  [urls.becomeMyWaitroseMember, 'My Waitrose Membership'],
  [`${urls.myWaitrosePage}/join`, 'Join myWaitrose'],
  [urls.joinMyWaitroseSuccess, 'Welcome to myWaitrose'],
  [`${urls.myWaitrosePage}/link-card`, 'Link myWaitrose'],
  [`${urls.myWaitrosePage}/link-card/welcome`, 'Welcome to myWaitrose'],
  [urls.linkMyWaitroseSuccess, 'myWaitrose card linked'],
  [urls.myPartnershipCardAndDetails, 'Membership account'],
  [urls.myWaitroseCardAndDetails, 'myWaitrose account'],
  [urls.myWaitroseBenefits, 'myWaitrose benefits'],
  [urls.myWaitroseBenefitsDryCleaning, 'Save on dry cleaning & laundry'],
  [urls.myWaitroseBenefitsFreeHotDrink, 'Free hot drink'],
  [urls.myWaitroseBenefitsVitality, 'Vitality cashback'],
  [urls.myWaitroseVouchers, 'Exclusive vouchers'],
  [urls.orderReplacementCard, 'Order a Plastic Card'],
  [urls.orderReplacementCardAddress, 'Order Replacement myWaitrose Card'],
  [urls.orderReplacementCardSuccess, 'Plastic card ordered'],
  [urls.myAccount, 'My Account'],
  [urls.marketingPreferences, 'Marketing Preferences - My Account'],
  [urls.myOrdersPage, 'My Orders'],
  [urls.viewOrder, 'View Order'],
  [urls.shopFromPrevious, 'Shop From Order'],
  [`${urls.myAccount}/mywaitrose`, 'myWaitrose'],
  [urls.offers, 'Offers'],
  [rootPath('/shop/browse/offers/highlights/:offerIdentifier'), getOfferPageTitle],
  [rootPath('/shop/offers/:offerIdentifier'), getOfferPageTitle],
  [rootPath('/shop/browse/offers/:offerIdentifier'), getOfferPageTitle],
  [urls.paymentCards, 'Payment Cards - My Account'],
  [urls.resetPassword, 'Reset Password'],
  [urls.serviceSelection, 'Choose Your Shopping Method'],
  [rootPath('shop'), 'Shop'],
  [urls.browse, 'Browse'],
  [`${urls.browse}/:type/*`, getLastPartOfPath],
  [urls.entertainingHome, 'Entertaining'],
  [urls.groceriesHome, 'Online Grocery Shopping & Delivery'],
  [urls.trolleyPage, 'My Trolley'],
  ['/*/addresses/add', 'Add address'],
  [urls.checkout, 'Checkout'],
  [urls.leaveMyWaitrose, 'Leave myWaitrose'],
  [urls.leaveMyWaitroseConfirmation, 'Leave myWaitrose'],
  [
    urls.multiSearch,
    (_, state) => {
      const { value } = getSearchObject(state);
      return `${capitaliseTitle(value)} - Multi-search`;
    },
  ],
  [
    rootPath('/products/:productName/:productId'),
    pathname => {
      const urlProductFragment = pathname.replace(rootPath('products/'), '');
      const [productNameFromUrl] = urlProductFragment.split('/');
      return capitaliseTitle(delimitWordsWithSpaces(productNameFromUrl));
    },
  ],
  [urls.shopFromPrevious, 'Shop From Previous Order'],
  [
    rootPath(`/shopping-lists/:shoppingListName/:shoppingListId`),
    pathname => capitaliseTitle(pathname.split('/')[3]),
  ],
  [
    urls.recipe,
    pathname => `${formatRecipeMetaTitle(delimitWordsWithSpaces(pathname.split('/')[3]))}`,
  ],
  [urls.recipes, getLastPartOfPath],
];
export const startsWithMatches = [
  [urls.interstitials, 'Interstitials'],
  [urls.bookslotCollection, 'Click & Collect Slot Booking'],
  [urls.registration, 'Register'],
  [urls.forgotPassword, 'Forgot Password'],
  [urls.customerServiceHomePage, getLastPartOfPath],
  [urls.orderConfirmation, 'Order Confirmation'],
  [urls.paymentConfirmationPage, 'Payment successful'],
  [urls.resolveOrderPayment, 'Payment'],
  [rootPath('content/inspiration'), getLastPartOfPath],
  [rootPath('/shop/featured'), getLastPartOfPath],
  [
    urls.search,
    (_, state) => {
      const { searchTerm } = getSearchObject(state);
      return searchTerm ? `${capitaliseTitle(searchTerm)} - Search` : 'Search';
    },
  ],
];
