export const SHOPPING_LIST_FULFILLED = 'waitrose/shopping-list/SHOPPING_LIST_FULFILLED';
export const SHOPPING_LIST_PENDING = 'waitrose/shopping-list/SHOPPING_LIST_PENDING';
export const SHOPPING_LIST_REJECTED = 'waitrose/shopping-list/SHOPPING_LIST_REJECTED';
export const SHOPPING_LIST_ACTIONS = [
  SHOPPING_LIST_PENDING,
  SHOPPING_LIST_FULFILLED,
  SHOPPING_LIST_REJECTED,
];

export const SHOPPING_LIST_DELETED = 'waitrose/shopping-list/SHOPPING_LIST_DELETED';

export const SHOPPING_LIST_PRODUCTS_FULFILLED =
  'waitrose/shopping-list/SHOPPING_LIST_PRODUCTS_FULFILLED';
export const SHOPPING_LIST_PRODUCTS_PENDING =
  'waitrose/shopping-list/SHOPPING_LIST_PRODUCTS_PENDING';
export const SHOPPING_LIST_PRODUCTS_REJECTED =
  'waitrose/shopping-list/SHOPPING_LIST_PRODUCTS_REJECTED';
export const SHOPPING_LIST_PRODUCTS_ACTIONS = [
  SHOPPING_LIST_PRODUCTS_PENDING,
  SHOPPING_LIST_PRODUCTS_FULFILLED,
  SHOPPING_LIST_PRODUCTS_REJECTED,
];

export const SHOPPING_LIST_TOGGLE_MEMBERSHIP =
  'waitrose/shopping-list/SHOPPING_LIST_TOGGLE_MEMBERSHIP';

export const SHOPPING_LIST_UPDATE_FULFILLED =
  'waitrose/shopping-list/SHOPPING_LIST_UPDATE_FULFILLED';
export const SHOPPING_LIST_UPDATE_PENDING = 'waitrose/shopping-list/SHOPPING_LIST_UPDATE_PENDING';
export const SHOPPING_LIST_UPDATE_REJECTED = 'waitrose/shopping-list/SHOPPING_LIST_UPDATE_REJECTED';
export const SHOPPING_LIST_UPDATE_ACTIONS = [
  SHOPPING_LIST_UPDATE_PENDING,
  SHOPPING_LIST_UPDATE_FULFILLED,
  SHOPPING_LIST_UPDATE_REJECTED,
];

export const SHOPPING_LIST_EDIT_NAME = 'waitrose/shopping-list/SHOPPING_LIST_EDIT_NAME';
export const SHOPPING_LIST_EDIT_NAME_CANCELLED =
  'waitrose/shopping-list/SHOPPING_LIST_EDIT_NAME_CANCELLED';
export const SHOPPING_LIST_UPDATE_NAME = 'waitrose/shopping-list/SHOPPING_LIST_UPDATE_NAME';

export const SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE =
  'waitrose/shopping-list/SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE';
