import urls from 'constants/urls';
import { WINE_WEEK_REDIRECT, matchDecisionByExperiment } from 'constants/monetateExperiments';
import { getAllDecisions } from 'redux/modules/experiments/selectors';
import { Fetcher, FetcherArgs, PreloadOnlyFetcher, PreloadOnlyFetcherArgs } from './fetcher-types';

const getIsWineWeekRedirectMatch = (args: FetcherArgs, getState: () => WtrState) => {
  if (args.location.pathname !== urls.wineBrowse) {
    return false;
  }

  const state = getState();
  // @ts-expect-error experiments state is not typed
  const decisions = getAllDecisions(state);
  const variant = matchDecisionByExperiment(
    WINE_WEEK_REDIRECT.experiment,
    decisions?.[WINE_WEEK_REDIRECT.experiment],
  );

  return variant === WINE_WEEK_REDIRECT.variant.showBuilderPage;
};

export const exceptWhenWineWeekRedirect =
  (fetcher: Fetcher | PreloadOnlyFetcher) =>
  (args: FetcherArgs | PreloadOnlyFetcherArgs): WtrThunkAction => {
    if ('preloadOnly' in args) {
      if (args.preloadOnly) {
        (fetcher as PreloadOnlyFetcher)(args);
      }
      // This function shouldn't ever be executed but it makes the TypeScript types simpler by returning it as there is commonality in return types in this function
      return () => {};
    }
    return (dispatch, getState) =>
      getIsWineWeekRedirectMatch(args, getState)
        ? Promise.resolve()
        : (fetcher as Fetcher)(args)(dispatch, getState);
  };
