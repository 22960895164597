import { ModalCloseLabels, ModalCloseOptions } from 'constants/modalCloseOptions';
import { modalButtonOrientation } from 'constants/modal-button-orientation';
import urls from 'constants/urls';

export const modalSeverityValues = {
  INFO: 'info', // general information modal
  ERROR: 'error', // a modal shown due to an error
  CONFLICT: 'conflict', // usually a BE triggered error e.g. trolley conflict
} as const;

export const slotTimeoutModal = {
  id: 'slotTimeoutModal',
  titleText: 'Slot availability update',
  messageText: [
    'The slot grid will refresh every two minutes so you will always see the latest availability.',
  ],
  buttons: [
    {
      buttonText: 'Refresh page',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  severity: modalSeverityValues.INFO,
};

export const newAddressModal = {
  titleText: "We're not quite there yet",
  messageText: [
    `Our BETA website will soon support this functionality,
                  in the meantime please go to My Account to enter a new address.`,
  ],
  buttons: [{ buttonText: 'My Account', buttonValue: ModalCloseOptions.OK }],
  useIngredientsModal: false,
};

export const slotBookingErrorModal = {
  id: 'slotBookingErrorModal',
  severity: modalSeverityValues.INFO,
  titleText: "Sorry, that slot's just been taken",
  messageText: [`Refresh now to see all our currently available slots`],
  buttons: [{ buttonText: 'Refresh page', buttonValue: ModalCloseOptions.OK }],
};

export const slotExpectedChargeErrorModal = {
  id: 'slotExpectedChargeErrorModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Delivery charge update',
  messageText: [
    `The delivery charge for this slot has changed. Please refresh the page to see the updated cost`,
  ],
  buttons: [{ buttonText: 'Refresh page', buttonValue: ModalCloseOptions.OK }],
};

export const noEarlierSlotDatesModal = {
  id: 'noEarlierSlotDatesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Sorry, no earlier slots available for this product',
  messageText: [
    'Your viewed product requires more time to prepare or is only available on specific dates.',
  ],
  buttons: [{ buttonText: 'OK' }],
  messageIsHtml: true,
};

export const noEarlierSlotDatesModalWithProductsInTrolley = {
  id: 'noEarlierSlotDatesModalWithProductsInTrolley',
  severity: modalSeverityValues.INFO,
  titleText: 'Sorry, no earlier slots available',
  messageText: [
    'Certain products in your trolley require more time to prepare or are only available on specific dates.',
  ],
  buttons: [
    { buttonText: 'Select a later slot', buttonValue: ModalCloseOptions.CANCEL },
    { buttonText: 'Go to My Trolley', buttonValue: ModalCloseOptions.OK },
  ],
  messageIsHtml: true,
  buttonOrientation: modalButtonOrientation.VERTICAL,
};

export const noSlotDatesModal = {
  id: 'noSlotDatesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Sorry, there are no slots available for this order',
  messageText: [
    'Your trolley contains a combination of products that will not be ready or available with this slot.',
    'Some may require more time to prepare or are only available on specific dates.',
    'Please amend your trolley and try again',
  ],
  buttons: [{ buttonText: 'Amend trolley', buttonValue: ModalCloseOptions.OK }],
  messageIsHtml: true,
};

export const deliveryAddressNotSupportedModal = {
  id: 'deliveryAddressNotSupportedModal',
  severity: modalSeverityValues.INFO,
  titleText: "Unfortunately, we don't deliver to this address",
  messageText: [`Please choose a different address or add a new address to your account.`],
  buttons: [{ buttonText: 'Ok', buttonValue: ModalCloseOptions.OK }],
  useIngredientsModal: false,
};

export const networkErrorModal = {
  id: 'networkErrorModal',
  severity: modalSeverityValues.ERROR,
  buttons: [{ buttonText: 'Try again', buttonValue: ModalCloseOptions.OK }],
  messageText: [
    'Please try again to refresh the page, check your internet connection or try again later',
  ],
  responseStatus: 503, // Prevent Google indexing the error
  titleText: 'Unable to load page',
  webview: {
    id: 'checkout-error-network',
    ctas: [urls.trolleyPage],
    message: ['These problems are normally temporary. Please try again later.'],
  },
  useIngredientsModal: true,
};

export const clientOfflineModal = {
  buttons: [{ buttonText: 'Try again', buttonValue: ModalCloseOptions.OK }],
  id: 'clientOfflineModal',
  messageText: ['Please check your connection and try again'],
  severity: modalSeverityValues.ERROR,
  titleText: 'Internet connection lost',
  useIngredientsModal: true,
};

export const amendOrderErrorModal = {
  ...networkErrorModal,
  messageText: [
    'These problems are normally temporary. Please refresh the page, restart your browser or try again a little later.',
  ],
  titleText: "Sorry, we're experiencing an unexpected error.",
  id: 'amendOrderErrorModal',
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const cancelCustomerOrderErrorModal = {
  ...networkErrorModal,
  messageText: [
    'These problems are normally temporary. Please refresh the page, restart your browser or try again a little later.',
  ],
  titleText: "Sorry, we're experiencing an unexpected error.",
  id: 'cancelCustomerOrderErrorModal',
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const trolleyNetworkErrorModal = {
  ...networkErrorModal,
  messageText: [
    'These problems are normally temporary. Please refresh the page, restart your browser or try again a little later.',
  ],
  titleText: "Sorry, we're experiencing an unexpected error.",
  id: 'trolleyNetworkErrorModal',
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const updateTrolleyBadRequestModal = {
  id: 'updateTrolleyBadRequestModal',
  severity: modalSeverityValues.ERROR,
  titleText: "Sorry, this item hasn't been added",
  messageText: [
    "We couldn't add that last item to your order due to a technical issue.  Please try again.",
  ],
  buttons: [{ buttonText: 'Ok', buttonValue: ModalCloseOptions.OK }],
  useIngredientsModal: false,
};

export const confirmCancelAmendOrderModal = {
  buttons: [
    {
      buttonText: 'Yes, cancel changes',
      buttonValue: ModalCloseOptions.OK,
    },
    {
      buttonText: 'Continue shopping',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  buttonOrientation: modalButtonOrientation.VERTICAL,
  messageText: [
    'Are you sure you want to cancel your changes to this order?',
    'Your order will revert back to its previous state.',
  ],
  titleText: 'Cancel changes?',
  useIngredientsModal: false,
};

/* eslint-disable no-template-curly-in-string */
export const confirmAmendOrderCutOffModal = {
  titleText: 'Changes cannot be made to your order',
  messageText: [
    "Sorry, changes to your order can only be made up until ${amendOrderCutOff}. You'll still receive your previously confirmed order.",
  ],
  useIngredientsModal: false,
};

export const confirmSaveBrokenTrolleyModal = {
  id: 'confirmSaveBrokenTrolleyModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Fix broken trolley',
  messageText: [
    'Only do this if the customers trolley is broken.',
    'After the trolley has been fixed you will automatically "Return to Admin" and will need to sign in again as the customer.',
    'Are you sure you want to do this?',
  ],
  buttons: [
    {
      buttonText: 'Ok',
      buttonValue: ModalCloseOptions.OK,
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  buttonOrientation: modalButtonOrientation.VERTICAL,
  useIngredientsModal: false,
};

export const saveBrokenTrolleyEmptyTrolleyFailedModal = {
  id: 'saveBrokenTrolleyEmptyTrolleyFailedModal',
  severity: modalSeverityValues.ERROR,
  titleText: 'Saving trolley failed',
  messageText: ['Saving the broken trolley failed.', 'Please try again.'],
  useIngredientsModal: false,
};

export const restoreBrokenTrolleyPatchOrderItemsFailedModal = {
  id: 'restoreBrokenTrolleyPatchOrderItemsFailedModal',
  severity: modalSeverityValues.ERROR,
  titleText: 'Restoring trolley failed',
  messageText: ['Restoring the broken trolley failed.', 'Please try again.'],
  useIngredientsModal: false,
};

export const confirmRestoreBrokenTrolleyModal = {
  id: 'confirmRestoreBrokenTrolleyModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Restore broken trolley',
  messageText: [
    'There is a Fixed trolley in memory for this customer.',
    'Do you want to restore this?',
  ],
  buttons: [
    {
      buttonText: 'Ok',
      buttonValue: ModalCloseOptions.OK,
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  buttonOrientation: modalButtonOrientation.VERTICAL,
  useIngredientsModal: false,
};

export const logoutFailedModal = {
  id: 'logoutFailedModal',
  severity: modalSeverityValues.ERROR,
  titleText: 'Unable to log out',
  messageText: [
    `We were unable to log you out. Please try again (clearing any waitrose.com cookies beforehand may also help)`,
  ],
  buttons: [{ buttonText: 'Ok', buttonValue: ModalCloseOptions.OK }],
  useIngredientsModal: false,
};

export const loyaltyCardModal = {
  id: 'loyaltyCardModal',
  severity: modalSeverityValues.INFO,
};

export const personalisedMessageErrorModal = {
  id: 'personalisedMessageErrorModal',
  severity: modalSeverityValues.INFO,
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
  messageText: [
    `Sorry, you can't amend your greetings message after you have placed your order. If you need help contact Customer Service on 0800 188 884`,
  ],
  titleText: 'Unable to edit',
  useIngredientsModal: false,
};

export const myWaitroseAppleWalletErrorModal = {
  buttons: [
    {
      buttonText: 'Ok',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  buttonOrientation: modalButtonOrientation.VERTICAL,
  messageText: [
    `We can't currently generate your myWaitrose card from Apple Wallet. Please try again
    later.`,
  ],
  titleText: `Sorry, we're experiencing a problem`,
  useIngredientsModal: false,
};

export const inStoreIncentivesModal = {
  id: 'inStoreIncentivesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Your voucher',
};

export const entertainingSlotConflict = {
  id: 'entertainingSlotConflict',
  severity: modalSeverityValues.INFO,
  buttons: [
    {
      buttonText: 'Edit your trolley',
      buttonValue: ModalCloseOptions.REMOVE,
    },
    {
      buttonText: 'View grocery collection slots',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  cannotClose: true,
  titleText: 'These slots are for collecting Entertaining items only',
  messageText: [
    'To view collection slots for Entertaining items, simply remove your grocery products from your trolley. Alternatively, keep all the items in your trolley and view grocery collection slots.',
  ],
};

export const restrictedProductsModal = {
  id: 'restrictionModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Choose another date or remove item',
  useIngredientsModal: false,
};

export const slotsExpiredOffersModal = {
  id: 'slotsExpiredOffersModal',
  severity: modalSeverityValues.INFO,
  titleText:
    'The following offers are not valid with your booked slot. By continuing, you agree to receive these products at their non-offer price.',
  buttons: [
    {
      buttonText: 'Continue',
      buttonValue: 'OK',
    },
    {
      buttonText: 'Cancel',
      buttonValue: 'CANCEL',
    },
  ],
  useIngredientsModal: false,
};

export const slotExpiredModal = {
  id: 'slotExpiredModal',
  severity: modalSeverityValues.INFO,
  buttons: [{ buttonText: 'Book a slot', buttonValue: ModalCloseOptions.OK }],
  titleText: 'Sorry, your slot has now expired.',
  messageText: [
    "You'll need to re-book your slot before you can check out. Your shopping is still in your trolley.",
  ],
  useIngredientsModal: false,
};

export const cancelSlotModal = {
  id: 'cancelSlotModal',
  severity: modalSeverityValues.INFO,
  buttons: [
    { buttonText: 'Keep slot' },
    { buttonText: 'Cancel slot', buttonValue: ModalCloseOptions.DELETE },
  ],
  titleText: 'Cancel reserved slot?',
  messageText: [
    'Please confirm you wish to cancel your reserved ${slotType} slot on <strong>${slotDate}</strong>',
  ],
  messageIsHtml: true,
};

export const rewardRedemptionConfirmationModal = {
  id: 'rewardRedemptionConfirmationModal',
  severity: modalSeverityValues.INFO,
  messageIsHtml: true,
  titleText: 'Confirm your selection',
  buttons: [
    {
      buttonText: 'Choose a different voucher',
      buttonValue: ModalCloseOptions.CANCEL,
    },
    {
      buttonText: 'Continue with this voucher',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
};

export const slotsChristmasLimitModal = {
  id: 'slotsChristmasLimitModal',
  severity: modalSeverityValues.INFO,
  titleText: "Sorry, you've already booked two Christmas slots",
  messageIsHtml: true,
  messageText: [
    "Because of demand, we have limited Christmas slots to two per customer. You can change or cancel orders within 'My Orders'",
  ],
  buttons: [
    {
      buttonText: 'View My Orders',
      buttonValue: ModalCloseOptions.REMOVE,
    },
  ],
};

export const personaliseMessageModal = {
  id: 'personaliseMessageModal',
  severity: modalSeverityValues.INFO,
  useIngredientsModal: false,
};

export const emptyTrolleyModal = {
  id: 'emptyTrolleyModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Empty trolley',
  useIngredientsModal: false,
};

export const shopperNotesModal = {
  id: 'shopperNotesModal',
  severity: modalSeverityValues.INFO,
  useIngredientsModal: false,
};

export const subAllModal = {
  id: 'subAllModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Disallow substitutions',
  useIngredientsModal: false,
};

export const swapUnavailableModal = {
  id: 'swapUnavailableModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Swap',
};

export const viewAlternativesModal = {
  id: 'viewAlternativesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'View alternatives',
};

export const cannotEditAddressModal = {
  id: 'cannotEditAddressModal',
  severity: modalSeverityValues.CONFLICT,
  titleText: 'Unable to edit',
  messageText: [
    `You either have an order in progress or a slot booked to this address.
    To edit this address, please change slot or wait until your order has been delivered.`,
  ],
  dataTest: 'cannotEditAddressModal',
};

export const cannotDeleteAddressModal = {
  id: 'cannotDeleteAddressModal',
  severity: modalSeverityValues.CONFLICT,
  titleText: 'Unable to delete',
  messageText: [
    `You either have an order in progress or a slot booked to this address.
    To delete this address, please change slot or wait until your order has been delivered.`,
  ],
  dataTest: 'cannotDeleteAddressModal',
};

export const deleteAddressModal = {
  id: 'deleteAddressModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Delete address',
  messageText: ['Are you sure you want to delete this address?'],
  buttons: [
    {
      buttonText: 'Delete',
      buttonValue: ModalCloseOptions.DELETE,
      theme: 'primary',
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
      theme: 'secondary',
    },
  ],
  dataTest: 'deleteAddressModal',
};

export const saveAsContactAddressModal = {
  id: 'saveAsContactAddressmodal',
  severity: modalSeverityValues.INFO,
  titleText: 'Confirm new contact address',
  messageText: [
    'Are you sure you want to make this your contact address? This will be used for all Waitrose communications you choose to receive, including myWaitrose',
  ],
  buttons: [
    {
      buttonText: 'Confirm',
      buttonValue: ModalCloseOptions.CONFIRM,
      theme: 'primary',
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
      theme: 'secondary',
    },
  ],
  dataTest: 'saveAsContactAddressModal',
};

export const nonQualifiedPromoCodesExplanationModal = {
  id: 'nonQualifiedPromoCodesExplanationModal',
  severity: modalSeverityValues.INFO,
  titleText: "Why can't I use my promo code / voucher?",
  messageText: [
    'You may not have met the qualifying criteria for an offer (for example, minimum qualifying spend), or the voucher/code may not be useable alongside another.',
    'Your qualifying spend could also be impacted by alcohol in your basket to comply with Minimum Unit Pricing laws in Scotland, Wales and Jersey.',
    'Certain goods or services are excluded from being used or paid for with vouchers/codes. These include: Waitrose & Partners Food, Health and Drinks magazines, first stage infant formula/milk, tobacco, stamps, mobile top-ups, lottery tickets, gift vouchers and delivery charges.',
  ],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const howToUseIncentiveOnlineModal = {
  id: 'howToUseIncentiveOnlineModal',
  severity: modalSeverityValues.INFO,
  messageIsHtml: true,
  titleText: 'How to use a voucher online',
  messageText: [
    `<ol style="text-align: left; list-style-type: none; padding-left:0px;">
      <li>
        <p style="font-weight: 400; margin-bottom: 4px;">1. Finish your shop</p>
        <p style="font-weight: 200; margin-bottom: 24px;">Once you've added vouchers to your wallet, finish adding items to your trolley and checkout</p>
      </li>
      <li>
        <p style="font-weight: 400; margin-bottom: 4px;">2. At checkout</p>
        <p style="font-weight: 200; margin-bottom: 24px;">When you reach 'Order review & payment', scroll down to 'Promotional codes & vouchers'. There you will find 'Your voucher wallet'</p>
      </li>
      <li>
        <p style="font-weight: 400; margin-bottom: 4px;">3. Apply vouchers</p>
        <p style="font-weight: 200; margin-bottom: 24px;">In your voucher wallet, click or tap 'Apply to this order' on the voucher you want to use. Close the wallet and your voucher will show applied to your order</p>
      </li>
    </ol>`,
  ],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
  dataTest: 'how-to-use-incentive-online-modal',
};

export const howToUseIncentiveOnlineModalClipToCard = {
  id: 'howToUseIncentiveOnlineModal',
  severity: modalSeverityValues.INFO,
  messageIsHtml: true,
  titleText: 'How to use a voucher online',
  messageText: [
    `<ol style="text-align: left; list-style-type: none; padding-left:0px;">
      <li>
        <p style="font-weight: 400; margin-bottom: 4px;">1. Select your vouchers</p>
        <p style="font-weight: 200; margin-bottom: 24px;">Select the vouchers you intend to use and add all the items you wish to buy to your trolley – then go to checkout</p>
      </li>
      <li>
        <p style="font-weight: 400; margin-bottom: 4px;">2. At checkout</p>
        <p style="font-weight: 200; margin-bottom: 24px;">When you reach the checkout page, scroll down and select 'Vouchers & promos' – there you will see which vouchers are applied to your order</p>
      </li>
    </ol>`,
  ],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
  dataTest: 'how-to-use-incentive-online-modal-clip-to-card',
};

export const howToUseIncentiveInStoreModal = {
  id: 'howToUseIncentiveInStoreModal',
  severity: modalSeverityValues.INFO,
  titleText: 'How to use a voucher in store',
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
  dataTest: 'how-to-use-incentive-in-store-modal',
};

export const contactAddressInformativeModal = {
  id: 'contactAddressInformativeModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Why do I need this?',
  messageText: [
    'To provide you with a more streamlined experience, your contact address will be used for all Waitrose communications you choose to receive. It will also allow our Customer Service team to identify you quickly and easily should you ever need to contact us',
  ],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const deleteContactAddressModalError = {
  id: 'deleteContactAddressModalError',
  severity: modalSeverityValues.INFO,
  titleText: 'Unable to delete this address',
  messageText: ['Your account must contain a contact address'],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const deleteLastAddressModalError = {
  id: 'deleteLastAddressModalError',
  severity: modalSeverityValues.INFO,
  titleText: 'Unable to delete this address',
  messageText: ['Your account must contain at least one address'],
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const recipeShareModal = {
  id: 'recipeShare',
  severity: modalSeverityValues.INFO,
  title: 'Share this recipe',
};

export const recipeFilterModal = {
  id: 'recipeFilter',
  severity: modalSeverityValues.INFO,
  title: 'Filter by',
};

export const youHaveAlreadyRatedRecipeModal = {
  id: 'youHaveAlreadyRatedRecipe',
  severity: modalSeverityValues.INFO,
  titleText: 'Rating already submitted',
  messageText: ['You are unable to give two ratings for this recipe'],
  useIngredientsModal: true,
  buttons: [{ buttonText: 'OK', buttonValue: ModalCloseOptions.OK }],
};

export const keepPreviousOrderShopperNotesModal = {
  id: 'keepPreviousOrderShopperNotesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Reuse shopper notes?',
  messageText: [
    'You have a shopper note attached to this product. Would you like to reuse the note on your new order? You can always change it in your Trolley.',
  ],
  buttons: [
    {
      buttonText: 'Don’t reuse shopper notes',
      buttonValue: ModalCloseOptions.CLEAR,
    },
    {
      buttonText: 'Reuse shopper notes',
      buttonValue: ModalCloseOptions.CONFIRM,
    },
  ],
  useIngredientsModal: true,
};

export const keepBulkPreviousOrderShopperNotesModal = {
  id: 'keepBulkPreviousOrderShopperNotesModal',
  severity: modalSeverityValues.INFO,
  titleText: 'Reuse shopper notes?',
  messageText: [
    'You have shopper notes attached to some of your products - would you like to reuse them on your new order? You can always change them in your Trolley.',
  ],
  buttons: [
    {
      buttonText: 'Don’t reuse shopper notes',
      buttonValue: ModalCloseOptions.CLEAR,
    },
    {
      buttonText: 'Reuse shopper notes',
      buttonValue: ModalCloseOptions.CONFIRM,
    },
  ],
  useIngredientsModal: true,
};

export const removeUnavailableItemsModal = {
  id: 'removeUnavailableItemsModal',
  severity: modalSeverityValues.INFO,
  buttons: [
    {
      buttonText: 'Remove All',
      buttonValue: ModalCloseOptions.REMOVE,
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  titleText: 'Remove all unavailable items?',
  messageText: [
    'Sure you want to remove all unavailable items? You might prefer to choose alternatives using the ‘Swap’ buttons on the My Trolley page instead?',
  ],
};

export const onlineClipToCardOnboardingGuideModal = {
  id: 'onlineClipToCardOnboardingGuideModal',
  severity: modalSeverityValues.INFO,
  buttons: [
    {
      buttonText: 'Close',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  titleText: 'Simply scan your card',
};

export const saverSlotsUnavailableOnChristmas = {
  id: 'saverSlotsUnavailableOnChristmas',
  severity: modalSeverityValues.INFO,
  buttons: [
    {
      buttonText: 'Ok',
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  titleText: 'Saver slots unavailable',
  messageText: [
    "There are no Saver slots for these dates so we are showing you 1-hour Christmas slots. To view available Saver slots, select '£ Saver' and choose alternative dates",
  ],
};

export const removedLinesModal = {
  id: 'removedLinesModal',
  severity: modalSeverityValues.ERROR,
  cannotClose: true,
  buttons: [
    {
      buttonText: 'View my trolley',
      buttonValue: ModalCloseOptions.CONFIRM,
    },
  ],
  titleText: 'Unavailable items in trolley',
  messageText: [
    "We can't reserve this slot as items in your trolley are now unavailable. Please remove all unavailable items and try again",
  ],
};

export const changeToAGroceriesCollectionModal = {
  id: 'changeToAGroceriesCollectionModal',
  severity: modalSeverityValues.CONFLICT,
  buttons: [
    {
      buttonText: 'Keep current slot',
      buttonValue: ModalCloseOptions.CANCEL,
    },
    {
      buttonText: 'Change slot',
      buttonValue: ModalCloseOptions.CONFIRM,
    },
  ],
  titleText: 'To add groceries to your order, select Change slot',
  messageText: [
    "You can't add grocery products because you have an Entertaining-only slot booked. To add groceries to your collection order select Change slot.",
  ],
};

export const incentivesDetailsModal = {
  id: 'incentivesDetailsModal',
  severity: modalSeverityValues.CONFLICT,
  buttons: [
    {
      buttonText: ModalCloseLabels.CLOSE,
      buttonValue: ModalCloseOptions.OK,
    },
  ],
  titleText: 'Your voucher',
};
