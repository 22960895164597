import { trolleyNetworkErrorModal, updateTrolleyBadRequestModal } from 'constants/modals';

import { cancelAmendOrder } from 'redux/modules/amend-order/actions/cancel-amend-order';
import { showAmendOrderCutoffModal } from 'redux/modules/amend-order/actions/show-amend-order-cutoff-modal';
import { showClientOfflineModal } from 'api/actions/show-client-offline-modal';
import { getCustomerSlot } from 'redux/modules/customer-slot/actions/get-customer-slot';

import { openModal, openConflictModal } from 'redux/modules/common-modal/actions';
import { getIsAnyCommonModalOpen } from 'redux/modules/common-modal/selectors';
import { transformProductConflict } from 'redux/modules/conflicts/reducer/transform';
import { log } from 'utils/error-logging';
import { getFeatureFlags } from 'utils/feature-flags';
import { isOnline } from 'utils/is-online';

const isCutoffError = error => {
  // This is how the error presents itself post-samosa when in amend state and order cutoff has passed.
  // See https://www.jlpit.com/jira/browse/WPIP-18388 comments for discussion around FE hack
  // See https://www.jlpit.com/jira/browse/WPIP-19047 for BE tech debt ticket that should address this properly
  const type = error?.type ?? '';
  const message = error?.message ?? '';
  if (
    (type === '400' &&
      message.match(/^The date must be after today and before .* days in the future$/i)) ||
    type === '410'
  )
    return true;

  return false;
};

export const handleGenericTrolleyNetworkError = ({ dispatch, error }) => {
  const { modalErrorLogging } = getFeatureFlags();

  if (error && modalErrorLogging) {
    log(error, { logger: 'generic-trolley-network-error' });
  }

  dispatch(openModal(trolleyNetworkErrorModal));
};

export async function handleOrderCutOff(error, dispatch, getState) {
  if (isCutoffError(error) && !getIsAnyCommonModalOpen(getState())) {
    // This is necessary because the getTrolley request is resolving before the customer slot is loaded
    // In those scenario the modal would display 'undefined' in the slot date
    try {
      const [, slotResponse] = await Promise.all([
        dispatch(cancelAmendOrder()),
        dispatch(getCustomerSlot()),
      ]);
      dispatch(showAmendOrderCutoffModal(slotResponse?.amendOrderCutOff));
    } catch (amendOrderError) {
      handleGenericTrolleyNetworkError({ dispatch, error: amendOrderError });
    }
  } else {
    throw error;
  }
}

export const handleUpdateTrolleyErrors = (dispatch, getState) => async error => {
  if (!isOnline()) {
    showClientOfflineModal(dispatch);

    return;
  }

  if (error?.type === '400' || error?.type === '410') {
    await handleOrderCutOff(error, dispatch, getState).catch(() => {
      dispatch(openModal(updateTrolleyBadRequestModal));
    });
  } else {
    dispatch(openModal(updateTrolleyBadRequestModal));
  }
};

export const handleTrolleyConflicts = dispatch => result => {
  const { trolley: { conflicts = [] } = {} } = result.trolley
    ? result
    : Object.values(result.data)[0];
  if (conflicts.length) {
    const normalisedConflict = transformProductConflict(conflicts[0]);
    dispatch(openConflictModal(normalisedConflict));
  }
};
