import { FORMAT, format } from 'utils/date';
import { formatSlotTime } from 'utils/format-slot-time';

export default ({
  isSlotBooked,
  showEndTime,
  slotDate,
  slotEndTime,
  slotStartTime,
  shortTime,
} = {}) => {
  if (isSlotBooked && slotDate && slotStartTime) {
    let formattedData = `${format(slotDate, FORMAT.SHORT_2DAY_MONTH)}, ${formatSlotTime(
      slotStartTime,
    ).toLowerCase()}${
      showEndTime && slotEndTime ? ` - ${formatSlotTime(slotEndTime).toLowerCase()}` : ''
    }`;

    if (shortTime) {
      formattedData = `${format(slotDate, FORMAT.SHORT_DAY)}, ${formatSlotTime(
        slotStartTime,
      ).toLowerCase()}`;
    }

    return formattedData;
  }
  return 'Book a slot';
};
