import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnchorLink from 'components/AnchorLink';
import styles from './ConflictMessage.scss';
import WarningIcon from './WarningIcon';

const ConflictMessage = ({ className, onPDP, pathname, resolution, search, text, textOnly }) => {
  const shouldDisplayLink = !!search && !!pathname;
  const classes = classNames(
    styles.conflict,
    {
      [styles.hasResolution]: !!resolution,
      [styles.textOnly]: textOnly,
      [styles.onPDP]: onPDP,
    },
    className,
  );
  const icon = (
    <div className={styles.icon}>
      <WarningIcon />
    </div>
  );

  return (
    <div className={classes} data-test="conflict-message">
      {!textOnly && icon}
      <div className={styles.content}>
        <div className={styles.message}>{text}</div>
        {resolution &&
          (shouldDisplayLink ? (
            <AnchorLink className={styles.resolution} to={{ pathname, search }}>
              {resolution}
            </AnchorLink>
          ) : (
            <div className={styles.resolution}>{resolution}</div>
          ))}
      </div>
    </div>
  );
};

ConflictMessage.defaultProps = {
  className: '',
  onPDP: false,
  pathname: null,
  resolution: undefined,
  text: undefined,
  search: null,
  textOnly: false,
};

ConflictMessage.propTypes = {
  className: PropTypes.string,
  onPDP: PropTypes.bool,
  pathname: PropTypes.string,
  resolution: PropTypes.node,
  search: PropTypes.string,
  text: PropTypes.string,
  textOnly: PropTypes.bool,
};

export default ConflictMessage;
