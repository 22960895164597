import root from 'window-or-global';

import { getImpactIdentify } from 'redux/modules/impact/selectors';

export const identifyCustomer = (dispatch, getState) => {
  const identify = getImpactIdentify(getState());

  // Send event using the Imapact Radius Event API
  root.ire('identify', identify);
};
