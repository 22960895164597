export const datePickerOpened = (state, { date } = {}) => ({
  event: 'date_picker_opened',
  datePickerDate: date,
});

export const datePickerSelectedDate = (state, { date } = {}) => ({
  event: 'date_picker_selected_date',
  datePickerDate: date,
});

export const datePickerNextMonth = () => ({
  event: 'date_picker_next_month',
});

export const datePickerPrevMonth = () => ({
  event: 'date_picker_prev_month',
});
