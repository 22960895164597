import { consoleInfo } from 'utils/logging';
import root from 'window-or-global';

export const enum Actions {
  Navigate = 'Navigate',
  PaymentLoaded = 'PaymentLoaded',
  PaymentSuccess = 'PaymentSuccess',
  CheckoutLoaded = 'CheckoutLoaded',
  CheckoutSuccess = 'CheckoutSuccess',
}

export type AllActions =
  | { type: Actions.Navigate | Actions.PaymentLoaded | Actions.PaymentSuccess; payload: string }
  | { type: Actions.CheckoutLoaded | Actions.CheckoutSuccess };

/**
 * Sends the action through the appropriate bridge, aka `window.Android.postAction` in Android or `webkit.messageHandlers.actions.postMessage` in webkit.
 * @param action The action to send
 * @returns A boolean indicating if we were able to send the action to the bridge
 */
export function postNativeAction(action: AllActions) {
  if (root.Android?.postAction) {
    root.Android.postAction(JSON.stringify(action));
    return true;
  }

  if (root.webkit?.messageHandlers.actions?.postMessage) {
    root.webkit.messageHandlers.actions.postMessage(action);
    return true;
  }

  consoleInfo(
    '[webview] Neither root.Android.postAction or root.webkit?.messageHandlers.actions.postMessage are defined',
    action,
  );
  return false;
}

export function supportsNativeAction() {
  return !!root.Android?.postAction || !!root.webkit?.messageHandlers.actions?.postMessage;
}
