import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import styles from './index.scss';
import { ProductDataType } from '..';
import ProductPodPromotions from '../../../../../ProductPod/Promotions';

export type ProductOffersProps = Pick<ProductDataType, 'productType' | 'id'>;

const ProductOffers = ({ productType, id }: ProductOffersProps) => {
  return (
    <Typography
      styleAs="paragraphSmall"
      noMargins
      className={styles.offers}
      component="div"
      data-testid="product-offers"
    >
      <ProductPodPromotions
        productType={productType}
        productId={id ?? ''}
        searchType="recipe"
        reportAnalyticsClick={() => {}}
      />
    </Typography>
  );
};

export default ProductOffers;
