import pricing from 'api/definitions/pricing';
import { FORMAT, dayjs } from 'utils/date';

import { SHOPPABLE_PRICING_REQUEST_ACTIONS } from 'redux/modules/recipes/actions/types';

export const updateShoppableIngredientPriceById = ({
  branchId,
  recipeId,
  ingredientId,
  groupId: shoppableProductIndex,
  updatedIngredient,
}) => {
  const { lineNumber, amountSelected, uom } = updatedIngredient;
  return {
    types: SHOPPABLE_PRICING_REQUEST_ACTIONS,
    recipeId,
    ingredientId,
    groupId: shoppableProductIndex,
    updatedIngredient,
    apiCall: pricing.post({
      body: {
        products: [
          {
            lineNumber,
            quantity: {
              amount: amountSelected,
              uom,
            },
          },
        ],
        branchId,
        dates: [dayjs().format(FORMAT.ISO8601_DATE)],
        // Discounts should not be applied to Shoppable modal prices
        myWaitrose: false,
      },
    }),
  };
};
