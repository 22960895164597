import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import { SHOW_CHANGE_NAME_MODAL, HIDE_CHANGE_NAME_MODAL } from '../../types';

const initialState = deepFreeze({
  isOpen: false,
});

const showChangeNameModal = (state, { payload }) => ({
  ...state,
  ...payload,
});

const hideChangeNameModal = (state, { payload }) => ({
  ...state,
  ...payload,
});

const changeNameModalReducerMap = new Map([
  [SHOW_CHANGE_NAME_MODAL, showChangeNameModal],
  [HIDE_CHANGE_NAME_MODAL, hideChangeNameModal],
]);

export default createReducer(initialState, changeNameModalReducerMap);
