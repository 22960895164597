import update from 'immutability-helper';

import {
  SHOPPING_LIST_DELETED,
  SHOPPING_LIST_PRODUCTS_FULFILLED,
  SHOPPING_LIST_TOGGLE_MEMBERSHIP,
  SHOPPING_LIST_UPDATE_NAME,
  SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE,
} from 'redux/modules/shopping-list/actions/types';

import {
  SHOPPING_LISTS_CREATE_FULFILLED,
  SHOPPING_LISTS_FULFILLED,
} from 'redux/modules/shopping-lists/actions/types';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

const getMatchingProduct = (lineNumber, products) =>
  products
    .filter(product => product)
    .filter(product => extractLineNumberFromSku(product.searchProduct) === lineNumber)[0];

const getMatchingProductId = (lineNumber, products = []) =>
  (getMatchingProduct(lineNumber, products) || {}).searchProduct;

const itemIsAwaitingLoadMore = ({ start, size, index }) =>
  typeof start !== 'undefined' && typeof size !== 'undefined' && index >= start + size;

const addProductIdToItems = ({ items, products }) =>
  items.map(item => ({
    ...item,
    id: item.id ?? getMatchingProductId(item.lineNumber, products),
  }));

const itemNotRequested = (item, requestedLineNumbers) =>
  !requestedLineNumbers.includes(item.lineNumber);

const hasId = ({ id }) => !!id;

const shoppingLists = (state = {}, action = {}) => {
  const { payload = {}, result = {}, type } = action;

  switch (type) {
    case SHOPPING_LISTS_CREATE_FULFILLED: {
      const { id } = result;
      return {
        ...state,
        [id]: result,
      };
    }
    case SHOPPING_LIST_DELETED: {
      const { listId } = action;

      return update(state, { $unset: [listId] });
    }
    case SHOPPING_LISTS_FULFILLED: {
      const { result: lists = [] } = action;

      return lists.reduce(
        (prev, list) => ({
          ...prev,
          [list.id]: list,
        }),
        {},
      );
    }
    case SHOPPING_LIST_PRODUCTS_FULFILLED: {
      const { listId, start, size, lineNumbers: requestedLineNumbers } = action;
      const { products = [] } = result;

      if (listId) {
        const list = state[listId];
        const items = addProductIdToItems({ items: list.items, products });
        const itemsWithoutDeleteLines = items.filter(
          (item, index) => hasId(item) || itemIsAwaitingLoadMore({ start, size, index }),
        );

        const numItems = itemsWithoutDeleteLines.length;
        return {
          ...state,
          [listId]: {
            ...list,
            items: itemsWithoutDeleteLines,
            numItems,
          },
        };
      }

      const newState = {};

      Object.entries(state).forEach(([listIdKey, list]) => {
        const items = addProductIdToItems({ items: list.items, products });
        const itemsWithoutDeleteLines = items.filter(
          item => hasId(item) || itemNotRequested(item, requestedLineNumbers),
        );
        const numItems = itemsWithoutDeleteLines.length;

        newState[listIdKey] = {
          ...list,
          items: itemsWithoutDeleteLines,
          numItems,
        };
      });

      return newState;
    }

    case SHOPPING_LIST_TOGGLE_MEMBERSHIP: {
      const { quantity, shoppingListId: listId, lineNumber } = payload;
      const list = state[listId];
      if (!list) return state;

      const hasLineNumber = item => item.lineNumber === lineNumber;
      const isMember = list.items.some(hasLineNumber);
      const difference = isMember ? -1 : 1;
      const nextItems = isMember
        ? list.items.filter(item => !hasLineNumber(item))
        : [
            ...list.items,
            {
              lineNumber,
              quantity,
            },
          ];

      return update(state, {
        [listId]: {
          items: {
            $set: nextItems,
          },
          numItems: num => num + difference,
        },
      });
    }
    case SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE: {
      const { shoppingListId: listId, lineNumber } = payload;
      const list = state[listId];
      if (!list) return state;

      let isMember = false;
      let difference = 0;
      lineNumber.forEach(ln => {
        if (list.items.some(item => item.lineNumber === ln.lineNumber)) {
          isMember = true;
          difference -= 1;
        } else {
          difference += 1;
        }
      });
      const nextItems = isMember
        ? list.items.filter(item => !lineNumber.some(ln => ln.lineNumber === item.lineNumber))
        : [...list.items, ...lineNumber];

      return update(state, {
        [listId]: {
          items: {
            $set: nextItems,
          },
          numItems: num => num + difference,
        },
      });
    }
    case SHOPPING_LIST_UPDATE_NAME: {
      const { listId, newName } = payload;

      return update(state, {
        [listId]: {
          name: {
            $set: newName,
          },
        },
      });
    }
    default:
      return state;
  }
};

export default shoppingLists;
