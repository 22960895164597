import urls from 'constants/urls';
import { inferModalType } from './schema';

export const description =
  'Errors that could happen when initialising the order at the start of the checkout flow';

export default () =>
  inferModalType(
    {
      id: 'checkout-default-modal',
      webviewId: 'checkout-error-default',
      titleText: "Sorry, there's been a problem",
      messageText: ['Please try again'],
      buttons: [
        {
          buttonText: 'Try again',
          primary: true,
          action: { navigateTo: urls.trolleyPage },
          cta: urls.trolleyPage,
        },
      ],
    },
    [
      {
        httpStatuses: [409],
        errorCodes: ['ORDER_ORCHESTRATION_080'],
        id: 'checkout-amend-order-cutoff-expired',
        webviewId: 'checkout-error-4',
        severity: 'conflict',
        messageText: ["It's now past the cut-off time for amending this order."],
        titleText: "It's too late to amend",
        buttons: [
          {
            buttonText: 'OK',
            primary: true,
            action: { navigateTo: urls.myOrdersPage },
            cta: urls.myOrdersPage,
          },
        ],
      },
      {
        errorCodes: ['TIMEOUT'],
        id: 'checkout-timeout-error',
        webviewId: 'checkout-timeout-error',
        severity: 'error',
        titleText: "Sorry, there's been a problem",
        messageText: ['Please try again'],
        buttons: [
          {
            buttonText: 'Try again',
            primary: true,
            action: 'reload',
          },
        ],
      },
    ],
  );
