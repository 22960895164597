import { getFulfilmentTypeByURL } from 'components/BookSlot/utils/get-fulfilment-type-by-url';

const shouldResetState = (previousFulfilmentType, currentFulfilmentType) => {
  // If the page we're going to is not a slot booking page then reset state
  if (!currentFulfilmentType) {
    return true;
  }

  // If we're coming from a page that is not a slot booking page
  // AND we're going to a slot booking page then reset state
  if (!previousFulfilmentType && !!currentFulfilmentType) {
    return true;
  }

  // If the page we're going to is a slot booking page
  // AND it's a different slot booking page to the last page then true
  if (previousFulfilmentType.id !== currentFulfilmentType.id) {
    return true;
  }

  return false;
};

const reducer = (state, action) => {
  const currentFulfilmentType = getFulfilmentTypeByURL(action.payload.location.pathname);
  const previousFulfilmentType = state.fulfilmentType;

  if (shouldResetState(previousFulfilmentType, currentFulfilmentType)) {
    return {
      ...state,
      selectedAddress: null,
      selectedBranch: null,
      selectedDate: '',
      fulfilmentType: currentFulfilmentType,
      proposedSlot: null,
      hasSelectedNewSlotThisPageVisit: false,
    };
  }

  return {
    ...state,
    fulfilmentType: currentFulfilmentType,
  };
};

export default reducer;
