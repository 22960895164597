import trolleyItems from 'api/definitions/trolleyItems';

import {
  REMOVE_ITEM_FROM_TROLLEY,
  TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
  TROLLEY_QUEUE_ITEM_UPDATED,
  TROLLEY_QUEUE_ITEM_UPDATING,
  UPDATE_TROLLEY_ITEM,
  UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY,
} from 'redux/modules/trolley/actions/types';

import {
  getItemByLineNumber,
  getItemIdByLineNumber,
} from 'redux/modules/trolley/selectors/get-item';
import { getTrolleyHardConflictById } from 'redux/modules/conflicts/selectors';
import { getProductIdByLineNumber } from 'redux/modules/entities/selectors/products';
import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';

export default ({
    lineNumber,
    newQuantity,
    clickContext,
    canSubstitute,
    noteToShopper,
    productPosition,
    searchType,
  }) =>
  (dispatch, getState) => {
    const state = getState();
    let trolleyItem = getItemByLineNumber(state, lineNumber);

    if (!trolleyItem) return;

    if (!trolleyItem.productId) {
      const productId = getProductIdByLineNumber(state, lineNumber);
      trolleyItem = { ...trolleyItem, productId };
    }

    const quantity = formatQuantity(newQuantity);

    const decrement =
      newQuantity.uom === trolleyItem.quantity.uom &&
      newQuantity.amount < trolleyItem.quantity.amount;

    const analytics = {
      decrement,
      lineNumber,
      productPosition,
      searchType,
      uom: newQuantity.uom,
      ...(clickContext && { clickContext }),
    };
    if (newQuantity.amount !== trolleyItem.quantity.amount) {
      if (newQuantity.amount === 0) {
        dispatch({
          trolleyItem,
          hasHardConflict: !!getTrolleyHardConflictById(state, trolleyItem.trolleyItemId),
          type: REMOVE_ITEM_FROM_TROLLEY,
        });
      } else {
        dispatch({
          trolleyItem: {
            lineNumber,
            quantity,
          },
          type: UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY,
        });
      }
    }

    dispatch({
      type: UPDATE_TROLLEY_ITEM,
      lineNumber,
      quantity,
      queue: true,
      queueFunction: () => {
        const trolleyItemId = getItemIdByLineNumber(getState(), lineNumber);
        if (!trolleyItemId) return;
        dispatch({
          analytics,
          apiCall: trolleyItems.updateItem({
            trolleyItemId,
            body: {
              ...trolleyItem,
              quantity,
              trolleyItemId,
              noteToShopper,
              canSubstitute,
            },
          }),
          lineNumber,
          queue: true,
          types: [
            TROLLEY_QUEUE_ITEM_UPDATING,
            TROLLEY_QUEUE_ITEM_UPDATED,
            TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
          ],
          callback: {
            success: response => {
              const { data: { updateTrolleyItem } = {}, errors } = response;

              if (errors || !updateTrolleyItem || updateTrolleyItem.failures) {
                let error = new Error(
                  'Graph call succeeded but returned no data or an unknown error',
                );

                if (errors?.length) {
                  [error] = errors;
                }

                if (updateTrolleyItem?.failures?.length) {
                  const { failures } = updateTrolleyItem;
                  [error] = failures;
                }
                throw error;
              }

              return { ...updateTrolleyItem };
            },
          },
        });
      },
    });
  };
