import queryString from 'query-string';
import flattenDeep from 'lodash/flattenDeep';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';

import linkTypes from 'constants/link-types';
import { ONE_POD_CQRESPONSIVE } from 'constants/podSizing';

import { convertDisplayPriceToNumber } from 'utils/parse-display-price';

const addProductIdToConflicts = ({ conflicts = [], id: productId } = {}) =>
  conflicts.map(conflict => ({ ...conflict, productId }));

const transformProductIntoLegacyFormat = (product = {}) => {
  const { cookingStatus, images, pricing, reviews, weights } = product;
  const { small: thumbnail } = images || {};
  const {
    displayPrice,
    displayPriceEstimated,
    displayPriceQualifier,
    formattedPriceRange,
    promotions = [],
  } = pricing || {};

  const { averageRating, total: reviewCount } = reviews || {};
  const { defaultQuantity, uoms, servings, sizeDescription: size } = weights || {};
  const { amount = {} } = defaultQuantity || {};
  const [uom] = uoms ?? [];

  return {
    cqResponsive: ONE_POD_CQRESPONSIVE,
    searchProduct: {
      ...omit(product, 'cookingStatus'),
      conflicts: addProductIdToConflicts(product),
      contents: {
        cookingStatus,
      },
      currentSaleUnitPrice: {
        quantity: {
          amount,
          uom,
        },
        price: {
          /**
           * TODO: https://www.jlpit.com/jira/browse/WPIP-51796
           *
           * APIs respond with currentSaleUnitPrice set to the "was" price for
           * products, not the current display price, implying that this is
           * incorrect for all cases where there is a discount promotion for
           * the product.
           *
           * We suspect instead we should be using
           * `pricing.currentSaleUnitRetailPrice.price.amount`
           * if it exists and falling back to this if not (i.e. cases where
           * there is no promotion).
           *
           * The risk in changing this is that this data structure is used
           * across a number of pages so care needed in case any page is
           * relying on the fact that this is the current (potentially
           * discounted) display price and not the original "was" price.
           *
           * Making this change may allow for far-reaching refactors to
           * remove various fallbacks throughout the code.
           */
          amount: convertDisplayPriceToNumber(displayPrice),
          currencyCode: 'GBP',
        },
      },
      defaultQuantity: {
        amount,
        uom,
      },
      displayPrice,
      displayPriceEstimated,
      displayPriceQualifier,
      formattedPriceRange,
      promotions: promotions.map(promotion => ({
        ...omit(promotion, 'promotionType'),
        promotionTypeCode: promotion.promotionType || promotion.promotionTypeCode,
      })),
      reviews: {
        averageRating,
        reviewCount,
      },
      servings,
      size,
      thumbnail,
      weights: omit(weights, 'servings'),
    },
  };
};

const parseLinkQueryParams = link => queryString.parse(link.href.replace('/v1/products?', ''));

const extractValuesFromQueryString = link => {
  const omittedProps = ['customersFavouritesOnly', 'view'];
  const properties = parseLinkQueryParams(link);
  const key = Object.keys(properties).filter(property => !omittedProps.includes(property))[0];

  return {
    id: key,
    value: properties[key],
  };
};

const convertGroupToFilter = (data, group) =>
  data.links
    .filter(link => link.metaData.group === group)
    .map(matchingLink => ({
      applied: false,
      filterTag: {
        ...extractValuesFromQueryString(matchingLink),
        count: matchingLink.metaData.expectedResults,
        group,
        text: matchingLink.title,
      },
    }));

const transformLinksIntoCriteria = (data = {}) => {
  const { links = [] } = data;
  const criteria = [];
  const groups = uniq(links.map(({ metaData: { group } = {} } = {}) => group));
  const omittedTypes = [linkTypes.CATEGORY];

  groups
    .filter(group => !omittedTypes.includes(group))
    .forEach(group => {
      criteria.push({
        filters: convertGroupToFilter(data, group),
        group,
      });
    });

  return criteria;
};

const PLACEHOLDER_CRITERIA_ARRAY = [];

/**
 * @typedef {Object} Data API response data
 * @property {any[]} [products] Optional array of products.
 * @property {any[]} [componentsAndProducts] Optional array of components (AEM or Citrus) and products.
 */
/**
 * @typedef {Object} LegacyFormatProperties Shared properties for legacy formats.
 * @property {any[]} conflicts Array of product conflicts.
 * @property {any[]} criteria Array of product criteria, a.k.a filters.
 * @property {number} totalMatches Total count of products.
 * @typedef {Data & LegacyFormatProperties} LegacyFormat
 */
/**
 * @param {Data} data API response data
 * @returns {LegacyFormat} The mapped response using the legacy format.
 */
const mapProductsIntoLegacyFormat = (data = {}) => {
  const { products = [], componentsAndProducts = [] } = data;
  const productsKey = products?.length ? 'products' : 'componentsAndProducts';
  const productsList = products?.length ? products : componentsAndProducts;

  return {
    ...data,
    conflicts: flattenDeep(productsList.map(addProductIdToConflicts)),
    criteria: data.links
      ? transformLinksIntoCriteria(data)
      : data.criteria || PLACEHOLDER_CRITERIA_ARRAY,
    [productsKey]: productsList.map(product => transformProductIntoLegacyFormat(product)),
    totalMatches: data.totalResults || productsList.length,
  };
};

export default mapProductsIntoLegacyFormat;
