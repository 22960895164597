const reducer = state => ({
  ...state,
  active: false,
  error: false,
  loading: false,
  results: {
    terms: [],
  },
  searchTerm: '',
});

export default reducer;
