import { apiCall } from 'api/api-call';
import { handleStatusCodes } from 'api/status/handle-status-codes';
import { setResponseCookies } from 'redux/modules/page/actions';
import { validateArgs } from 'api/utils/validate-args';

export const apiCallback =
  (api, verb, successCb, getErrorFromResponse) =>
  args =>
  /**
   *
   * @param {{dispatch: *; getState: *; timeout?: import('./types').TimeoutDefinition}} param0
   * @returns
   */
  ({ dispatch, getState, timeout }) => {
    validateArgs(args, api, verb);

    const apiOverrides = { timeout };

    const apiToCall = apiCall({
      api,
      args,
      apiOverrides,
      cookieHandler: cookies => dispatch(setResponseCookies(cookies)),
      getState,
      verb,
      successCb: typeof successCb === 'function' ? res => successCb(res, getState()) : undefined,
      getErrorFromResponse,
    });

    const statusCodeHandler = handleStatusCodes({
      api,
      apiToCall,
      args,
      apiOverrides,
      dispatch,
      getState,
      verb,
    });

    return apiToCall().catch(statusCodeHandler);
  };
