import createReducer from 'redux/createReducer';

import { saveExperiments } from 'redux/modules/experiments/reducer/save-experiments';
import { clearExperimentViews } from 'redux/modules/experiments/reducer/clear-experiment-views';
import { recordExperimentView } from 'redux/modules/experiments/reducer/record-experiment-view';
import { deepFreeze } from 'utils/deepFreeze';

import {
  SAVE_EXPERIMENTS,
  RECORD_EXPERIMENT_ID_VIEWED,
} from 'redux/modules/experiments/actions/types';
import { LOCATION_CHANGE } from 'redux-first-history';

export const initialState = deepFreeze({
  hasDecisions: false,
  decisions: {},
  payloads: {},
  impressions: {},
  renderedOnPage: [],
  viewedOnPage: [],
});

const experimentsReducerMap = new Map([
  [SAVE_EXPERIMENTS, saveExperiments],
  [RECORD_EXPERIMENT_ID_VIEWED, recordExperimentView],
  [LOCATION_CHANGE, clearExperimentViews],
]);

export default createReducer(initialState, experimentsReducerMap);
