import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

import urls from 'constants/urls';

const loggedInRoutes = [
  { path: urls.favourites },
  { path: urls.lists },
  { path: urls.interstitials },
  { path: urls.trolleyPage },
  { path: urls.bookslot },
  { path: urls.myAccount },
  { path: urls.registrationConfirmation },
  { path: urls.myDetailsPage },
  { path: urls.orderReplacementCard },
];

const pathInRouteList = (routeList = [], pathname = '') =>
  routeList.filter(({ path }) => pathname.startsWith(path));

export const isAuthorised = ({ location: { pathname }, store: { getState } }) => {
  const matchingPaths = isUserLoggedIn(getState()) ? [] : pathInRouteList(loggedInRoutes, pathname);
  return matchingPaths.length === 0;
};
