/*
Impact integration constants

See the Integration Plan:
https://docs.google.com/document/d/1pBYiR8G7zMIWLHSDim6CbSUVb7ucRj3WuIIJOF-G92M/edit#

Program  Landing Page               Tracking Domain          Event Tracker  ID
12163    www.waitrose.com           waitrose.pxf.io          Online Order   22345
12163    www.waitrose.com NON-PROD  waitrose.pxf.io          Online Order   22892
12344    www.waitrosecellar.com     Waitrose-Cellar.sjv.io   Online Order   22657
12346    www.waitrosegarden.com     www.waitrosegarden.com   Online Order   22661
*/

// Session storage stash keys
export const CLICK_ID_KEY = 'impactClickId';
export const CONVERSION_KEY = 'impactConversion';

// Tracking params
export const CONVERSION_OPTIONS = { verifySiteDefinitionMatch: true };
