import scrollIntoView from 'scroll-into-view';
import root from 'window-or-global';

import { animate, EASING } from 'utils/animate';
import { pageYOffset } from 'utils/dimensions-wrapper';
import { focusTop } from 'utils/focus';
import { scrollTo } from 'utils/scroll-to-wrapper';
import { setTimeout } from 'utils/settimeout-wrapper';

const DEFAULT_SLOW_DURATION = 600;

let duration = DEFAULT_SLOW_DURATION;

const getHorizontalScrollParent = (element: HTMLElement | null): HTMLElement | null => {
  if (!element) return null;
  if (element.scrollWidth > element.clientWidth) return element;
  return getHorizontalScrollParent(element.parentNode as HTMLElement | null);
};

interface AnimationInit {
  actuator: (value: number, target: HTMLElement) => void;
  end?: number;
  start?: number;
  target?: HTMLElement;
}

const startAnimation = ({ actuator, end, start, target }: AnimationInit) =>
  animate({ actuator, duration, easing: EASING.outCubic, end, start, target });

const scrollToElementX = (x: number, element: HTMLElement) =>
  startAnimation({
    actuator: (value: number, target: HTMLElement) => {
      target.scrollLeft = value; // eslint-disable-line
    },
    end: x,
    start: element.scrollLeft,
    target: element,
  });

const scrollToPageY = (y: number) =>
  startAnimation({
    actuator: value => scrollTo(0, value),
    end: y,
    start: pageYOffset(),
  });

export const setDuration = (ms: number) => {
  duration = ms;
};

export const scrollToTop = () => {
  scrollTo(0, 0);
  setTimeout(focusTop, 0);
};

export const scrollToY = (y: number) => {
  scrollTo(0, y || 0);
};

export const slowlyScrollElementHorizontallyIntoView = (element: HTMLElement, padding = 0) => {
  if (element) {
    const scrollParent = getHorizontalScrollParent(element);
    if (!scrollParent) return;

    const elementRect = element.getBoundingClientRect();
    const parentRect = scrollParent.getBoundingClientRect();

    const elementLeft = elementRect.x;
    const elementRight = elementRect.x + elementRect.width;

    const parentLeft = parentRect.x;
    const parentRight = parentRect.x + parentRect.width;

    if (elementLeft < parentLeft) {
      const leftDifference = Math.abs(parentLeft - elementLeft + padding);
      scrollToElementX(scrollParent.scrollLeft - leftDifference, scrollParent);
    } else if (elementRight > parentRight) {
      const rightDifference = Math.abs(parentRight - elementRight - padding);
      scrollToElementX(scrollParent.scrollLeft + rightDifference, scrollParent);
    }
  }
};

export const slowlyScrollElementHorizontallyTo = (element: HTMLElement, x: number) => {
  scrollToElementX(x, element);
};

export const slowlyScrollPageVerticallyTo = (y: number) => {
  scrollToPageY(y);
};

export const slowlyScrollPageVerticallyToTop = () => {
  scrollToPageY(0).then(() => {
    setTimeout(focusTop, 0);
  });
};

export const setScrollRestorationToManual = () => {
  if ('scrollRestoration' in root.history) {
    root.history.scrollRestoration = 'manual';
  }
};

export const setScrollRestorationToAuto = () => {
  if ('scrollRestoration' in root.history) {
    root.history.scrollRestoration = 'auto';
  }
};

interface ScrollInit {
  top?: number | undefined;
  left?: number | undefined;
  topOffset?: number | undefined;
  leftOffset?: number | undefined;
}

export const scrollIntoViewAndFocusElement = (
  element: HTMLElement,
  { top = 0.5, left = 0.5, topOffset = undefined, leftOffset = undefined }: ScrollInit = {
    top: 0.5,
    left: 0.5,
  },
  time = DEFAULT_SLOW_DURATION,
) => {
  if (element) {
    scrollIntoView(element, { time, align: { top, left, topOffset, leftOffset } }, () =>
      element.focus(),
    );
  }
};

export const scrollIntoViewAndFocusById = (
  id: string,
  { top = 0.5, left = 0.5, topOffset = undefined, leftOffset = undefined }: ScrollInit = {
    top: 0.5,
    left: 0.5,
  },
) => {
  const element = document.getElementById(id);

  if (element) {
    scrollIntoViewAndFocusElement(element, { top, left, topOffset, leftOffset });
  }
};

export function setBodyNoScroll(preventScroll: boolean) {
  if (preventScroll) {
    document.body.classList.add('bodyNoScroll');
  } else {
    document.body.classList.remove('bodyNoScroll');
  }
}
