import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { getPDPUrl } from 'utils/product';
import { trackShoppableModal, trackShoppableSwap } from 'analytics/recipes-tracking';
import { C62, weightOptions } from 'constants/weightOptions';
import { useSelector } from 'react-redux'; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { ShoppableItem } from 'redux/modules/recipes/index.d';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import { formatMeasurement, formatQuantity } from 'utils/format-recipe';
import { makeGetProductById } from 'redux/modules/entities/selectors/products';
import { useMakeSelector } from 'hooks/use-make-selector';
import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';
import styles from './index.scss';

import ItemControls, { ItemControlsProps } from '../ItemControls';
import type { SwapProductHandler } from '../../ShoppableHeading';
import ProductDescription from './ProductDescription';
import ProductOffers from './ProductOffers';
import ProductBadges from './ProductBadges';
import { ProductDataType } from '..';
import { ToggleIngredientsRefType } from '../../../../MealPlanner/AddToTrolleyModal/RecipeAccordionItem';

export type TrolleyItem = {
  lineNumber: string;
  quantity: { uom: string };
};

export interface ShoppablePodProps {
  ingredient: ShoppableItem;
  onUpdate: (updatedIngredient: ShoppableItem) => void;
  recipeId: string;
  onUpdatePrice: (updatedIngredient: ShoppableItem) => void;
  showSwapProduct?: ({ payload }: SwapProductHandler) => void;
  shoppableProductIndex: number;
  setCurrentIngredientIndex: (i: number) => void;
  highlight?: boolean;
  toggleIngredientsRef?: ToggleIngredientsRefType;
  forcedHideSwapLink?: boolean;
}

export type ProductTags = { name: string }[];

export const isAllergenUpdate = (productTags: ProductTags) =>
  productTags?.some(({ name }) => name === 'Allergen update');

export const isNewRecipe = (productTags: ProductTags) =>
  productTags?.some(({ name }) => name === 'New recipe');

export const displayYouNeedText = (
  multiplier: string,
  quantity: number,
  unit: string,
  measurement?: string,
) => {
  if (
    !multiplier &&
    (measurement === ' ' || measurement === '' || measurement === 'n/a' || !measurement)
  ) {
    return 'You need:';
  }
  return `You need: ${multiplier} ${formatQuantity(quantity)}${unit} of...`;
};

const ShoppablePod = ({
  ingredient,
  onUpdate,
  recipeId,
  onUpdatePrice,
  showSwapProduct,
  shoppableProductIndex,
  setCurrentIngredientIndex,
  highlight = false,
  toggleIngredientsRef,
  forcedHideSwapLink = false,
}: ShoppablePodProps) => {
  const {
    id: pimsId,
    ingredientId,
    lineNumber,
    amountSelected,
    isSponsored,
    isEnforced,
    duplicateItem,
    /* @ts-expect-error this property does not exist and is usually undefined */
    quantity,
    /* @ts-expect-error this property does not exist and is usually undefined */
    measurement,
    /* @ts-expect-error this property does not exist and is usually undefined */
    quantityMultiplier,
    /* @ts-expect-error this property does not exist and is usually undefined */
    mainIngredientPimsId,
  } = ingredient;

  const trolleyItems = useSelector(getTrolleyItems).map(
    ({ lineNumber: line, quantity: { uom } }: TrolleyItem) => {
      return {
        lineNumber: line,
        uom,
      };
    },
  );

  const productData: ProductDataType = useMakeSelector(makeGetProductById, lineNumber) ?? {};
  const {
    id,
    name,
    thumbnail,
    size,
    promotions,
    productType,
    currentSaleUnitPrice,
    conflicts,
    productTags,
    weights,
    displayPrice,
    pricing,
  } = productData;

  const hasConflicts = conflicts?.length > 0;
  const isDisabled = hasConflicts || duplicateItem;
  const [isChecked, setIsChecked] = useState(!isDisabled && amountSelected > 0);
  const [ingredientIsHighlighted, setIngredientIsHighlighted] = useState(highlight);

  // Keeps checkbox state in line with ItemControl updates
  useEffect(() => {
    setIsChecked(!isDisabled && amountSelected > 0);
  }, [amountSelected, isDisabled]);

  useEffect(() => {
    if (highlight) {
      setIngredientIsHighlighted(true);

      const timeoutId = setTimeout(() => {
        setIngredientIsHighlighted(false);
      }, 2300);

      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [highlight]);

  const unit = formatMeasurement(measurement);
  const productUrl = getPDPUrl(id, name) ?? undefined;
  const multiplier =
    quantityMultiplier && quantityMultiplier !== '1' ? `${quantityMultiplier} x ` : '';

  const matchingTrolleyItem = trolleyItems.find(
    (item: { lineNumber: string }) => item.lineNumber === lineNumber,
  );

  const onChange = useCallback(
    (forcedCheck?: boolean) => {
      const checkedState = isBoolean(forcedCheck) ? forcedCheck : !isChecked;
      setIsChecked(checkedState);

      const updatedIngredient = {
        ...ingredient,
        amountSelected: checkedState
          ? weightOptions[ingredient.uom as keyof typeof weightOptions].default
          : 0,
      };

      if (!checkedState) {
        trackShoppableModal('untick', recipeId, updatedIngredient.lineNumber);
      }

      onUpdatePrice(updatedIngredient);
    },
    [isChecked, ingredient, onUpdatePrice, recipeId],
  );

  useEffect(() => {
    if (toggleIngredientsRef) {
      // eslint-disable-next-line no-param-reassign
      toggleIngredientsRef.current[ingredientId] = onChange;
    }
  }, [toggleIngredientsRef, onChange, ingredientId]);

  if (!lineNumber) return null;

  const itemControlsProps: ItemControlsProps = {
    id,
    productName: name,
    hasConflicts,
    displayPrice,
    ingredient: {
      ...ingredient,
      uom: matchingTrolleyItem?.uom ?? weights?.defaultQuantity?.uom ?? C62,
    },
    onUpdate,
    onUpdatePrice,
    trolleyUom: matchingTrolleyItem?.uom,
    recipeId,
    displayUOMPrice: pricing?.displayUOMPrice,
    focused: highlight,
  };

  const showSwapLink = !isEnforced && !duplicateItem;
  const youNeedText = displayYouNeedText(multiplier, quantity, unit, measurement);

  const handleSwapClick = async () => {
    trackShoppableSwap('click hyperlink', recipeId, lineNumber);

    // We should use the main ingredient PIMS ID if it exists, otherwise use the current ingredient's PIMS ID
    const pimsIdToUse = mainIngredientPimsId || pimsId;
    setCurrentIngredientIndex(shoppableProductIndex);
    if (showSwapProduct) {
      showSwapProduct({
        payload: {
          id,
          name,
          size,
          promotions,
          currentSaleUnitPrice,
          isSponsored,
          isNewRecipe: isNewRecipe(productTags),
          isAllergenUpdate: isAllergenUpdate(productTags),
          productType,
          thumbnail,
          productUrl,
          displayPrice,
          lineNumber,
          weights,
          pimsId: pimsIdToUse,
          displayPriceQualifier: pricing?.displayPriceQualifier,
          displayUOMPrice: pricing?.displayUOMPrice,
          youNeedText,
          ingredientId,
          conflicts,
          pricing,
          productTags,
        },
      });
    }
  };

  return (
    <li
      key={lineNumber + ingredientId}
      className={classNames(styles.pod, {
        [styles.highlightedIngredient]: ingredientIsHighlighted,
      })}
      data-testid="shoppable-pod"
    >
      <div className={styles.productInfo}>
        <div className={styles.productMain}>
          <div>
            <Checkbox
              aria-label={
                isChecked
                  ? `Added ${name} product in selection`
                  : `Removed ${name} product from selection`
              }
              aria-atomic="true"
              aria-live="assertive"
              disabled={isDisabled}
              checked={isChecked}
              onChange={() => onChange()}
            />
          </div>
          <div className={styles.image}>
            <img src={thumbnail} alt={name} />
          </div>
          <div className={styles.details}>
            <ProductBadges
              isSponsored={isSponsored}
              isNewRecipe={isNewRecipe(productTags)}
              isAllergenUpdate={isAllergenUpdate(productTags)}
            />
            <Typography styleAs="paragraphSmall" className={styles.quantity}>
              {youNeedText}
            </Typography>
            <ProductDescription name={name} size={size} productUrl={productUrl} />
            <ProductOffers productType={productType} id={id ?? ''} />
          </div>
        </div>
        {!forcedHideSwapLink && showSwapLink && (
          <Typography styleAs="paragraphHeading" className={styles.productSwap}>
            <TextLink onClick={handleSwapClick} data-testid="swap-ingredient">
              Swap
            </TextLink>
          </Typography>
        )}
      </div>
      <div className={styles.productControls}>
        <ItemControls {...itemControlsProps} />
      </div>
    </li>
  );
};

export default ShoppablePod;
