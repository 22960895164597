import get from 'lodash/get';
import { deepFreeze } from 'utils/deepFreeze';

import { OFFERSELECTED } from 'redux/modules/offers/actions/types';
import {
  CLEAR as CLEAR_OFFERS,
  LOADED,
  LOADING,
  MEAL_DEAL_PRODUCTS_LOADED,
  NEXTLOADED,
  OFFER_PRODUCTS_LOADED,
} from 'redux/modules/search-and-browse/actions/types';

const initialState = deepFreeze({
  activeOffer: null,
  offers: [],
  relatedNavigation: [],
  subCategories: [],
});

export default function offers(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING:
      if (action.refinement || action.start > 0) {
        return state;
      }

      return {
        ...state,
        relatedNavigation: [],
        subCategories: [],
      };
    case LOADED:
    case NEXTLOADED:
    case MEAL_DEAL_PRODUCTS_LOADED:
    case OFFER_PRODUCTS_LOADED:
      return {
        ...state,
        relatedNavigation: get(action, 'result.relatedNavigation', []),
        subCategories: get(action, 'result.subCategories', []),
      };
    case OFFERSELECTED:
      return {
        ...state,
        activeOffer: action.offerId,
      };
    case CLEAR_OFFERS:
      return {
        ...state,
        activeOffer: action.offerId,
        relatedNavigation: [],
        subCategories: [],
      };
    default:
      return state;
  }
}
