export default (found, control) => {
  const { title, firstName, lastName, contactNumber } = control;

  return {
    title,
    firstName,
    lastName,
    contactNumber,
    ...found,
  };
};
