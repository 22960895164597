import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import AnchorLink from 'components/AnchorLink';
import { useNavigation } from 'components/MegaMenu/NavigationProvider';
import { ChevronRight } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './MenuLink.scss';

import { urlParser } from '../utils/urlParser';

const MenuLink = ({
  additionalClasses,
  categoryId,
  emptyOfProducts,
  hasDescendants,
  hiddenInNav,
  isActive,
  label,
  onClick,
  shortName,
  title,
  url,
}) => {
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useNavigation(ref, { id: categoryId });

  const handleClickMenuLink = useCallback(
    event => {
      handleClick();

      if (onClick) onClick(event, categoryId, urlParser(url));
    },
    [categoryId, handleClick, onClick, url],
  );

  if (emptyOfProducts || hiddenInNav) return null;

  const linkProps = {
    'aria-expanded': hasDescendants ? isActive : 'undefined',
    'aria-haspopup': hasDescendants,
    'data-actiontype': hasDescendants ? undefined : 'redirect',
    'data-origincomponent': hasDescendants ? undefined : 'megamenu link',
    'data-shortdescription': hasDescendants ? undefined : title,
    className: classNames(
      styles.mLnk,
      { [styles.active]: isActive && hasDescendants },
      { [styles.more]: hasDescendants },
      styles[shortName],
      additionalClasses,
    ),
    'data-testid': `mm-${shortName}`,
    innerRef: ref,
    onClick: handleClickMenuLink,
    onKeyDown: handleKeyDown,
    tabIndex,
    title,
    to: urlParser(url),
  };

  if (title) {
    const text = label ? null : title;
    const textProps = {
      className: styles.text,
      ...(label && { dangerouslySetInnerHTML: { __html: label } }),
      title: text,
      'data-testid': 'menu-link-text',
    };

    return (
      <AnchorLink {...linkProps}>
        <span {...textProps}>{text}</span>
        {hasDescendants && <ChevronRight className={styles.forwardIcon} size="xsmall" />}
      </AnchorLink>
    );
  }

  return <span className={styles.loading}>loading...</span>;
};

MenuLink.propTypes = {
  additionalClasses: PropTypes.string,
  categoryId: PropTypes.string,
  emptyOfProducts: PropTypes.bool,
  hasDescendants: PropTypes.bool,
  hiddenInNav: PropTypes.bool,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  shortName: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }),
  ]),
};

MenuLink.defaultProps = {
  additionalClasses: undefined,
  categoryId: undefined,
  emptyOfProducts: false,
  hasDescendants: undefined,
  hiddenInNav: false,
  isActive: undefined,
  label: undefined,
  onClick: undefined,
  shortName: undefined,
  title: undefined,
  url: undefined,
};

export default MenuLink;
