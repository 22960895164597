import React from 'react';
import { AllergenTypes } from 'api/definitions/recipes/types';

import { ChipsGroup, Chip } from '@johnlewispartnership/wtr-ingredients/ingredients/Chips';

import styles from './index.scss';

const Allergens = ({ allergens }) => {
  if (!allergens.length) return null;

  return (
    <div data-testid="allergens" className={styles.container}>
      <ChipsGroup>
        {allergens?.map(item => (
          <Chip colour="green" key={item.title}>
            {item.title}
          </Chip>
        ))}
      </ChipsGroup>
    </div>
  );
};
export default Allergens;

Allergens.propTypes = {
  allergens: AllergenTypes.allergens,
};

Allergens.defaultProps = {
  allergens: [],
};
