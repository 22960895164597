export const SET_SELECTED_BRANCH = 'waitrose/slot-booking/SET_SELECTED_BRANCH' as const;
export const SET_SELECTED_SLOT_GRID_TYPE =
  'waitrose/slot-booking/SET_SELECTED_SLOT_GRID_TYPE' as const;
export const CLEAR_SELECTED_BRANCH = 'waitrose/slot-booking/CLEAR_SELECTED_BRANCH' as const;
export const SET_SELECTED_ADDRESS = 'waitrose/slot-booking/SET_SELECTED_ADDRESS' as const;
export const CLEAR_SELECTED_ADDRESS = 'waitrose/slot-booking/CLEAR_SELECTED_ADDRESS' as const;
export const SET_SELECTED_DATE = 'waitrose/slot-booking/SET_SELECTED_DATE' as const;
export const SET_COLLECTION_SLOT_TYPE = 'waitrose/slot-booking/SET_COLLECTION_SLOT_TYPE' as const;
export const SET_FULFILMENT_TYPE = 'waitrose/slot-booking/SET_FULFILMENT_TYPE' as const;
export const SHOW_EXPIRED_OFFERS_MODAL = 'waitrose/slot-booking/SHOW_EXPIRED_OFFERS_MODAL' as const;
export const HIDE_EXPIRED_OFFERS_MODAL = 'waitrose/slot-booking/HIDE_EXPIRED_OFFERS_MODAL' as const;
export const SAVE_PROPOSED_SLOT = 'waitrose/pricing/SAVE_PROPOSED_SLOT' as const;
export const REMOVE_PROPOSED_SLOT = 'waitrose/pricing/REMOVE_PROPOSED_SLOT' as const;
export const SET_NEW_SLOT_SELECTED = 'waitrose/slot-booking/SET_NEW_SLOT_SELECTED' as const;
export const SET_CAROUSEL_START_POSITION =
  'waitrose/slot-booking/SET_CAROUSEL_START_POSITION' as const;
export const OPEN_LEAD_TIME_MODAL = 'waitrose/slot-booking/OPEN_LEAD_TIME_MODAL' as const;
export const CLOSE_LEAD_TIME_MODAL = 'waitrose/slot-booking/CLOSE_LEAD_TIME_MODAL' as const;
export const DATE_PICKER_OPENED = 'waitrose/slot-booking/DATE_PICKER_OPENED' as const;
export const DATE_PICKER_NEXT_MONTH = 'waitrose/slot-booking/DATE_PICKER_NEXT_MONTH' as const;
export const DATE_PICKER_PREV_MONTH = 'waitrose/slot-booking/DATE_PICKER_PREV_MONTH' as const;
export const DATE_PICKER_SELECTED_DATE = 'waitrose/slot-booking/DATE_PICKER_SELECTED_DATE' as const;
export const SHOW_RESTRICTED_PRODUCTS_MODAL =
  'waitrose/slot-booking/SHOW_RESTRICTED_PRODUCTS_MODAL' as const;
export const HIDE_RESTRICTED_PRODUCTS_MODAL =
  'waitrose/slot-booking/HIDE_RESTRICTED_PRODUCTS_MODAL' as const;
export const SET_SLOT_BOOKING_REDIRECT_PATH =
  'waitrose/slot-booking/SET_SLOT_BOOKING_REDIRECT_PATH' as const;
export const TOGGLE_GRID_LENGTH = 'waitrose/slot-booking/TOGGLE_GRID_LENGTH' as const;
