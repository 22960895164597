import { weightOptions } from 'constants/weightOptions';

export function formatAmount(desiredUom, value) {
  const [integerPart, fraction = ''] = value.split('.');
  const truncatedValue = integerPart
    ? `${integerPart}${weightOptions[desiredUom].allowFractional ? `.${fraction.slice(0, 1)}` : ''}`
    : '';
  const skipParseFloat =
    truncatedValue === '' ||
    (weightOptions[desiredUom].allowFractional && value[value.length - 1] === '.');

  return skipParseFloat ? truncatedValue : parseFloat(truncatedValue);
}
