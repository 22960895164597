import { hasCustomerAccountsInitialLoadCompleted } from 'redux/modules/accounts/selectors';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import { hasGetMembershipStatusInitialLoadCompleted } from 'redux/modules/marketing-preferences/selectors/get-membership-status';
import { hasCustomerOrderStatsInitialLoadCompleted } from 'redux/modules/orders/selectors';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';
import { withBlockingApis } from 'components/withBlockingApis';
import { type LoadableComponent } from '@loadable/component';
/**
 * Includes selectors matching to all apis src/redux/modules/sessions/get-customer-data-if-logged-in.js
 * migrated so far. At time of writing, it's been wrapped around most page level component to reduce risk
 * as much as possible for a first change in the area. It's unlikely that any page needs to wait for all
 * of these apis before first render, and teams for each of their pages should either:
 * 1. Remove the use of the higher order component (HOC) all together (no api responses are needed).
 * 2. Replace with call to withBlockingApis and some selectors (some api responses are needed)
 * 3. No2, but move the HOC to exactly where it is needed in the component tree, including multiple
 *    uses of it.
 * 4. Provide alternate implementation so the page component continues to work in all scenarios in
 *    conjunction with the various api loading states.
 */
export const withLegacyBlockingApis = <P extends object>(
  WrappedComponent: React.ComponentType<P> | LoadableComponent<P>,
  fallbackOverride?: JSX.Element | null,
) =>
  withBlockingApis(
    WrappedComponent,
    [
      hasCustomerAccountsInitialLoadCompleted,
      hasCustomerSlotInitialLoadCompleted,
      hasGetMembershipStatusInitialLoadCompleted,
      hasTrolleyLoadCompleted,
      hasCustomerOrderStatsInitialLoadCompleted,
    ],
    fallbackOverride,
  );
