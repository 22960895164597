import queryString from 'query-string';
import locator from 'utils/locator';
import { SHOW_MYWAITROSE_CHECKBOX, SHOW_DIVISIONS_CHECKBOX } from '../constants';

export const getMarketingConsents = state => state.marketingPreferences.marketingConsents;

export const getConsentsFormValues = state => {
  const valuesObject = {};
  const schemes = state.marketingPreferences.schemes;
  Object.keys(schemes).map(scheme => {
    if (schemes[scheme]) {
      const hasAllowedChannel = Object.values(schemes[scheme].consent || {}).includes(true);
      valuesObject[scheme] = hasAllowedChannel;
    } else {
      valuesObject[scheme] = undefined;
    }
  });
  return valuesObject;
};

export const getConsentsFormDefaultValues = val => {
  const queryKeys = queryString.parse(locator.search);

  let getDivisions = SHOW_DIVISIONS_CHECKBOX;
  if (queryKeys.dv && queryKeys.dv === '0') {
    getDivisions = false;
  }

  let getMyWaitrose = SHOW_MYWAITROSE_CHECKBOX;
  if (queryKeys.myw && queryKeys.myw === '1') {
    getMyWaitrose = true;
  }

  const valuesObject = {};

  if (getDivisions) {
    valuesObject.waitrose = val;
    valuesObject.johnlewis = val;
    valuesObject.johnlewisfinancialservices = val;
  }

  if (getMyWaitrose) {
    valuesObject.mywaitrose = val;
  }

  return valuesObject;
};
