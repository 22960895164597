import order from 'api/definitions/order';
import handleErrors from 'redux/errors/handle-errors';
import {
  handleAmendingSlotExpired,
  handleDeliveryDateUnavailable,
  handleGiftCardError,
  handleInvalidSlot,
  handleMinimumSpendConflict,
  handleOrderInitialiseError,
  handleOrderStateConfictError,
  handleSlotConfirmationConflict,
  handleSlotExpiredError,
  handleSlotMismatch,
  handleTrolleyHardConflict,
} from 'redux/modules/order/actions/errors';
import { INITIALISE_ORDER } from 'redux/modules/order/actions/types';
import handleGiftVoucherError from './errors/handle-gift-voucher-error';

const initialiseOrder = customerOrderId => async dispatch => {
  try {
    const result = await dispatch({
      types: INITIALISE_ORDER.triplet,
      apiCall: order.patch({
        body: {}, // Body is required for PATCH calls
        customerOrderId,
        iHandleStatusCodes: [400, 404, 409, 500, 502, 503, 504], // Handle generic errors but allow 401/403 to redirect to login
      }),
    });
    return result;
  } catch (errorResponse) {
    dispatch(
      handleErrors(errorResponse, [
        handleOrderStateConfictError, //   ORDER_ORCHESTRATION_008
        handleAmendingSlotExpired, //      ORDER_ORCHESTRATION_058 isAmending
        handleDeliveryDateUnavailable, //  ORDER_ORCHESTRATION_111
        handleGiftCardError, //            response?.body?.type in ['ADD_GIFT_CARD_ERROR', 'REMOVE_GIFT_CARD_ERROR']
        handleGiftVoucherError, //         response?.body?.type in ['ADD_GIFT_VOUCHER_ERROR', 'REMOVE_GIFT_VOUCHER_ERROR']
        handleInvalidSlot, //              ORDER_ORCHESTRATION_017
        handleMinimumSpendConflict, //     ORDER_ORCHESTRATION_042
        handleSlotConfirmationConflict, // ORDER_ORCHESTRATION_044
        handleSlotExpiredError, //         ORDER_ORCHESTRATION_058, ORDER_ORCHESTRATION_029
        handleSlotMismatch, //             ORDER_ORCHESTRATION_043
        handleTrolleyHardConflict, //      ORDER_ORCHESTRATION_041
        handleOrderInitialiseError, //     all other or unknown errors
      ]),
    );

    throw errorResponse;
  }
};

export default initialiseOrder;
