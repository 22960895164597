import { apiCallback } from 'api';
import { ApiCallArgs } from 'api/types';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';
import {
  Address,
  EstimatedTotals,
  OrderWarning,
  Price,
  Slot,
} from 'redux/modules/order/state/initial-state';

type Timestamp = string;
type PhoneNumber = string;
type PositiveInteger = number;

export interface ActualTotals {
  pickedPrice?: Price;
  carrierBagCharge?: Price;
  deliveryCharge?: Price;
  depositCharge?: Price;
  offerSavings?: Price;
  membershipSavings?: Price;
  partnerDiscountSavings?: Price;
  savings?: Price;
  paid?: Price;
}

type OrderTotals = {
  actual?: ActualTotals;
  estimated?: EstimatedTotals;
};

type CardPayment = {
  billingAddress: Address;
  id: string;
  cardType: string;
  cardHolderName: string;
  maskedCardNumber?: string;
  startDate?: string;
  expiryDate: string;
  businessAccount?: boolean;
  cardVerificationRequired?: boolean;
};

type GiftVoucher = {
  serialNumber: string;
  status: 'VALIDATED' | 'REDEEMED';
  value: Price;
};

type GiftCard = {
  serialNumber: string;
  amountToDeduct?: Price;
  remainingBalance?: Price;
};

type PaymentInfo = {
  cardPayment: CardPayment;
  giftVouchers: GiftVoucher[];
  giftCards: GiftCard[];
};

type AdjustmentType =
  | 'PROMOTION'
  | 'OFFER'
  | 'INCENTIVE'
  | 'PARTNER_DISCOUNT'
  | 'MEMBERSHIP_DISCOUNT';

type OrderAdjustment = {
  id: string;
  displayText?: string;
  marketingCampaignId?: string;
  adjustmentType: AdjustmentType;
  amount: Price;
  savings?: Price;
  status?: string;
  voucherId?: string;
  applicationMethod?: 'AUTO' | 'MANUAL';
};

export type OrderResponse = {
  customerOrderId: string;
  status:
    | 'PENDING'
    | 'PLACED'
    | 'COMPLETED'
    | 'AMENDING'
    | 'CANCELLED'
    | 'FULFIL'
    | 'PICKED'
    | 'PAID'
    | 'PAYMENT_FAILED'
    | 'REFUND_PENDING';
  created: Timestamp;
  placedAt?: 'HOME' | 'PHONE' | 'BRANCH';
  lastUpdated: Timestamp;
  bagless: boolean;
  substitutionsAllowed: boolean;
  paperStatement: boolean;
  alerts?: 'SMS' | 'PUSH' | 'EMAIL';
  alcoholPresent: boolean;
  deliveryNote?: string;
  contactNumber?: PhoneNumber;
  // orderLines?: OrderLine[];
  adjustments: OrderAdjustment[];
  partnerDiscountCardNumber?: string;
  paymentInfo?: PaymentInfo;
  slots?: Slot[];
  totals?: OrderTotals;
  shopNumber?: PositiveInteger;
  userType?: 'ADMIN' | 'CUSTOMER' | 'UNKNOWN';
  links?: { rel: string; title: string; href: string }[];
  numberOfItems: PositiveInteger;
  containsEntertainingLines?: boolean;
  orderWarnings?: OrderWarning[];
};

const get = {
  service: 'order-orchestration',
  endpoint: 'orders/${orderId}',
  verbs: ['get'],
  defaultVersion: 1,
  timeout: TIMEOUT_CONFIG,
};

const initialise = {
  service: 'order-orchestration',
  endpoint: 'orders/${customerOrderId}/initialise',
  verbs: ['patch'],
  defaultVersion: 1,
  timeout: TIMEOUT_CONFIG,
};

const getOrder: (args: { orderId: string } & ApiCallArgs) => WtrApiCall<OrderResponse> =
  apiCallback(get, 'get');

export default {
  get: getOrder,
  patch: apiCallback(initialise, 'patch'),
};
