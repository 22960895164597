export const ONE_POD_SIZING = [
  'col-xs-12of12',
  'col-sm-4of12',
  'col-md-4of12',
  'col-lg-3of12',
  'col-xl-2of12',
];

export const ONE_POD_CQRESPONSIVE = {
  deviceBreakpoints: [
    {
      name: 'xs',
      visible: true,
      width: '12',
    },
    {
      name: 'sm',
      visible: true,
      width: '4',
    },
    {
      name: 'md',
      visible: true,
      width: '4',
    },
    {
      name: 'lg',
      visible: true,
      width: '3',
    },
    {
      name: 'default',
      visible: true,
      width: '2',
    },
  ],
};
