import { getCustomerId } from 'redux/modules/sessions/selectors';
import {
  getCustomerSlotBranchId,
  getCustomerSlotDate,
  getSlotType,
} from 'redux/modules/customer-slot/selectors';
import { getCustomerOrderTotal } from 'redux/modules/accounts/selectors/get-customer-account';
import { marketingPreferencesAcknowledged } from 'redux/modules/my-account/selectors';

export const monetateCustomVariables = state => [
  { name: 'branchId', value: String(getCustomerSlotBranchId(state)) },
  { name: 'customerId', value: String(getCustomerId(state)) },
  {
    name: 'marketingPreferencesAcknowledged',
    value: String(marketingPreferencesAcknowledged(state)),
  },
  { name: 'slotDate', value: String(getCustomerSlotDate(state)) },
  { name: 'slotType', value: String(getSlotType(state)) },
  { name: 'totalOrderCount', value: String(getCustomerOrderTotal(state)) },
];
