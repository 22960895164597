import {
  ADD_ALL_ITEMS_FULFILLED,
  ADD_ALL_ITEMS_PENDING,
  ADD_ALL_ITEMS_REJECTED,
} from 'redux/modules/add-all-items/actions/types';
import { BUILD_PROMOTIONS } from 'redux/transforms';
import { TROLLEY_SCHEMA } from 'redux/schemas';

import addAllItems from 'api/definitions/addAllItems';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';

export default ({ merge = false, orderId, orderItems }) =>
  (dispatch, getState) => {
    // ingredientType and searchType are only needed for add_to_cart GA Event from Recipes page so removing from query body
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newOrderItems = orderItems.map(({ ingredientType, searchType, ...rest }) => rest);
    dispatch({ type: ADD_ALL_ITEMS_PENDING });
    return addAllItems
      .patch({
        merge,
        orderId,
        body: [...newOrderItems],
      })({ dispatch, getState })
      .then(response => {
        const { data: { updateTrolleyItems } = {}, errors } = response;

        if (errors || !updateTrolleyItems || updateTrolleyItems.failures) {
          let error = new Error('Graph call succeeded but returned no data or an unknown error');
          dispatch({
            error: 'Graph call succeeded but returned no data or an unknown error',
            type: ADD_ALL_ITEMS_REJECTED,
          });

          if (errors?.length) {
            [error] = errors;
          }

          if (updateTrolleyItems?.failures?.length) {
            const { failures } = updateTrolleyItems;
            [error] = failures;
          }
          return handleUpdateTrolleyErrors(dispatch, getState)(error);
        }

        return dispatch({
          type: ADD_ALL_ITEMS_FULFILLED,
          result: updateTrolleyItems,
          schema: TROLLEY_SCHEMA,
          transform: BUILD_PROMOTIONS,
          itemsToAdd: orderItems,
        });
      })
      .catch(err => {
        dispatch({
          error: 'Update trolley items call to graph failed',
          type: ADD_ALL_ITEMS_REJECTED,
        });
        return handleUpdateTrolleyErrors(dispatch, getState)(err);
      });
  };
