import {
  SET_INCENTIVE_LOADING_STATUS,
  LOAD_INCENTIVE,
} from 'redux/modules/incentives/actions/types';
import { IncentivesState } from 'redux/modules/incentives/state';

import { ApiError } from 'constants/errors';

const loadIncentiveRequest = (state: IncentivesState): IncentivesState => ({
  ...state,
  serverError: null,
});

const loadIncentiveSuccess = (state: IncentivesState): IncentivesState => ({
  ...state,
  serverError: null,
});

const loadIncentiveFailure = (
  state: IncentivesState,
  action: { payload: ApiError },
): IncentivesState => ({
  ...state,
  serverError: action.payload,
  isProcessingIncentiveSelection: false,
});

const setIncentiveLoadingStatus = (
  state: IncentivesState,
  action: { payload: { isProcessingIncentiveSelection: boolean } },
): IncentivesState => ({
  ...state,
  isProcessingIncentiveSelection: action.payload.isProcessingIncentiveSelection,
});

export default () =>
  [
    [LOAD_INCENTIVE.request, loadIncentiveRequest],
    [LOAD_INCENTIVE.success, loadIncentiveSuccess],
    [LOAD_INCENTIVE.failure, loadIncentiveFailure],
    [SET_INCENTIVE_LOADING_STATUS, setIncentiveLoadingStatus],
  ] as const;
