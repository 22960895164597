import classNames from 'classnames';
import { KEY_ESCAPE } from 'constants/keys';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import * as timeoutWrapper from 'utils/settimeout-wrapper';
import root from 'window-or-global';
import styles from './PortalModal.scss';

const addBodyClass = () => {
  root.document.body.classList.add(styles.modalOpen);
};

const removeBodyClass = () => {
  // If this is the last/only PortalModal, remove the body class
  const modals = root.document.querySelectorAll('[data-modal]');
  if (modals.length <= 1) {
    root.document.body.classList.remove(styles.modalOpen);
  }
};

export default class PortalModal extends Component {
  static propTypes = {
    anchoredBottomSmall: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    closeOnEscapeKey: PropTypes.bool,
    closeOnOverlayClick: PropTypes.bool,
    fullHeightSmall: PropTypes.bool,
    fullScreenTransitions: PropTypes.bool,
    inline: PropTypes.bool,
    modalDescriptionID: PropTypes.string,
    modalHeadingID: PropTypes.string,
    onClose: PropTypes.func,
    parentSelector: PropTypes.func,
    show: PropTypes.bool,
    spaceAroundLarge: PropTypes.bool,
    testName: PropTypes.string,
    transitionSpeed: PropTypes.number,
    useOverflowProp: PropTypes.bool,
    webViewId: PropTypes.string,
    webViewIdForOverlay: PropTypes.string,
    whiteOverlay: PropTypes.bool,
  };

  static defaultProps = {
    anchoredBottomSmall: false,
    children: null,
    className: null,
    closeOnEscapeKey: true,
    closeOnOverlayClick: true,
    fullHeightSmall: false,
    fullScreenTransitions: false,
    inline: false,
    modalDescriptionID: null,
    modalHeadingID: null,
    onClose: () => {},
    parentSelector: () => root.document.body,
    show: false,
    spaceAroundLarge: false,
    testName: 'modal',
    transitionSpeed: 200,
    useOverflowProp: true,
    webViewId: null,
    webViewIdForOverlay: null,
    whiteOverlay: false,
  };

  constructor(props) {
    super(props);

    const opacity = props.show || props.fullScreenTransitions ? 1 : 0;

    this.state = {
      opacity,
      show: props.show || false,
      transitionDuration: `${props.transitionSpeed / 1000}s`,
    };
  }

  componentDidMount() {
    const { parentSelector, show } = this.props;

    this.element = root.document.createElement('div');

    const rootElement = parentSelector();
    rootElement.appendChild(this.element);

    if (show) {
      addBodyClass();
      this.saveBodyScroll();
    }

    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps) {
    const { show, fullScreenTransitions } = this.props;

    if (prevProps.show !== show) {
      if (fullScreenTransitions) {
        if (show) {
          this.saveBodyScroll();
          this.slideUp();
        } else {
          this.restoreBodyScroll();
          this.slideDown();
        }
      } else if (show) {
        this.saveBodyScroll();
        this.fadeIn();
      } else {
        this.restoreBodyScroll();
        this.fadeOut();
      }
    }
  }

  componentWillUnmount() {
    const { parentSelector } = this.props;
    removeBodyClass();

    const rootElement = parentSelector();
    rootElement.removeChild(this.element);

    this.restoreBodyScroll();
  }

  fadeIn = () => {
    this.setState(
      {
        opacity: 0,
        show: true,
      },
      () => {
        this.setState({ opacity: 1 });
        addBodyClass();
      },
    );
  };

  fadeOut = () => {
    const { transitionSpeed } = this.props;

    removeBodyClass();
    this.setState(
      {
        opacity: 0,
      },
      () => {
        timeoutWrapper.setTimeout(() => {
          this.setState({ show: false });
        }, transitionSpeed);
      },
    );
  };

  slideUp = () => {
    this.setState({ show: true });
    timeoutWrapper.setTimeout(() => {
      addBodyClass();
    }, 1);
  };

  slideDown = () => {
    const { transitionSpeed } = this.props;
    removeBodyClass();

    timeoutWrapper.setTimeout(() => {
      this.setState({ show: false });
    }, transitionSpeed);
  };

  hideOnEscapeKey = event => {
    const { closeOnEscapeKey, onClose } = this.props;

    if (closeOnEscapeKey === false || event.keyCode !== KEY_ESCAPE) return;

    if (typeof onClose === 'function') {
      event.preventDefault();
      onClose(event);
    }
  };

  hideOnOverlayClick = event => {
    const { closeOnOverlayClick, onClose } = this.props;

    if (closeOnOverlayClick === false) return;

    if (typeof onClose === 'function') onClose(event);
  };

  saveBodyScroll() {
    this.setState({
      rootScrollY: root.scrollY,
    });
  }

  restoreBodyScroll() {
    const { rootScrollY } = this.state;

    if (rootScrollY) {
      root.scrollTo({ top: rootScrollY });
    }
  }

  render() {
    const { mounted, show } = this.state;
    const {
      anchoredBottomSmall,
      children,
      className,
      closeOnOverlayClick,
      fullHeightSmall,
      fullScreenTransitions,
      inline,
      modalDescriptionID,
      modalHeadingID,
      spaceAroundLarge,
      testName,
      useOverflowProp,
      webViewId,
      webViewIdForOverlay,
      whiteOverlay,
    } = this.props;

    if (!show) return null;
    if (!inline && !mounted) return null;

    const overlayProps = {
      'aria-describedby': modalDescriptionID,
      'aria-labelledby': modalHeadingID,
      'aria-modal': true,
      'data-modal': true,
      'data-test': testName,
      'data-webviewid': webViewIdForOverlay,
      className: classNames(styles.overlay, className, {
        [styles.bottom]: anchoredBottomSmall,
        [styles.closeOnClick]: closeOnOverlayClick,
        [styles.fullScreenTransitions]: fullScreenTransitions,
        [styles.spaceAroundLarge]: spaceAroundLarge,
        [styles.whiteOverlay]: whiteOverlay,
      }),
      onClick: this.hideOnOverlayClick,
      onKeyDown: this.hideOnEscapeKey,
      role: 'dialog',
      style: omit(this.state, 'mounted', 'show'),
    };

    const containerStyle = useOverflowProp ? styles.container : styles.containerNoOverflow;

    const modal = (
      <div {...overlayProps}>
        <div
          className={classNames(containerStyle, {
            [styles.fullHeightSmall]: fullHeightSmall,
          })}
          data-webviewid={webViewId ?? 'popup'}
        >
          {children}
        </div>
      </div>
    );

    if (inline) {
      return modal;
    }

    return createPortal(modal, this.element);
  }
}
