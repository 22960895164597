import {
  SIGNED_ON_AS_ANONYMOUS_CUSTOMER,
  WAS_SIGNED_ON_AS_ANONYMOUS_CUSTOMER,
} from 'redux/modules/sessions/actions/types';

import * as soacActions from './soac';

export default {
  [SIGNED_ON_AS_ANONYMOUS_CUSTOMER]: soacActions.signnedOnAsAnonymousCustomer,
  [WAS_SIGNED_ON_AS_ANONYMOUS_CUSTOMER]: soacActions.signnedOnAsAnonymousCustomerCreated,
};
