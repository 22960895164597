import { createStorage } from 'utils/local-storage-polyfill';

const localStore = createStorage('localStorage');
const sessionStore = createStorage('sessionStorage');

/**
 * Get a value from local storage by key.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise previous storage will
 * not be available.
 *
 * @param {string} key Local storage key.
 * @returns {string|null} Local storage value.
 */
function getFromLocalStorage(key: string): string | null {
  return localStore.getItem(key);
}

/**
 * Get a JSON value from local storage by key.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise previous storage will
 * not be available.
 *
 * @param {string} key Local storage key.
 * @returns {any} Local storage value.
 */
function getJsonFromLocalStorage<T>(key: string): T | undefined {
  const item = getFromLocalStorage(key);

  if (typeof item === 'string') {
    return JSON.parse(item);
  }

  return undefined;
}

/**
 * Set a value in local storage by key.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * persisted.
 *
 * **Note:** Server-side storage set using this method is not currently sent to
 * the client. This **cannot** be relied upon as a server -> client state
 * transfer mechanism.
 *
 * @param {string} key Local storage key.
 * @param {string} value Local storage value.
 */
function setInLocalStorage(key: string, value: string) {
  localStore.setItem(key, value);
}

/**
 * Set a JSON value in local storage by key.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * persisted.
 *
 * **Note:** Server-side storage set using this method is not currently sent to
 * the client. This **cannot** be relied upon as a server -> client state
 * transfer mechanism.
 *
 * @param {string} key Local storage key.
 * @param {string} value Local storage value.
 */
function setJsonInLocalStorage(key: string, value: unknown) {
  setInLocalStorage(key, JSON.stringify(value));
}

/**
 * Remove a value in local storage by key.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * removed.
 *
 * @param {string} key Local storage key.
 */
function removeFromLocalStorage(key: string) {
  localStore.removeItem(key);
}

/**
 * Clear local storage.
 *
 * If local storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * removed.
 */
function clearLocalStorage() {
  localStore.clear();
}

/**
 * Get a value from session storage by key.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise previous storage will
 * not be available.
 *
 * @param {string} key Session storage key.
 * @returns {string|null} Session storage value.
 */
function getFromSessionStorage(key: string): string | null {
  return sessionStore.getItem(key);
}

/**
 * Get a JSON value from session storage by key.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise previous storage will
 * not be available.
 *
 * @param {string} key Session storage key.
 * @returns {any} Session storage value.
 */
function getJsonFromSessionStorage<T>(key: string): T | undefined {
  const item = getFromSessionStorage(key);

  if (typeof item === 'string') {
    return JSON.parse(item);
  }

  return undefined;
}

/**
 * Set a value in session storage by key.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * persisted.
 *
 * **Note:** Server-side storage set using this method is not currently sent to
 * the client. This **cannot** be relied upon as a server -> client state
 * transfer mechanism.
 *
 * @param {string} key Session storage key.
 * @param {string} value Session storage value.
 */
function setInSessionStorage(key: string, value: string) {
  sessionStore.setItem(key, value);
}

/**
 * Set a JSON value in session storage by key.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * persisted.
 *
 * **Note:** Server-side storage set using this method is not currently sent to
 * the client. This **cannot** be relied upon as a server -> client state
 * transfer mechanism.
 *
 * @param {string} key Session storage key.
 * @param {string} value Session storage value.
 */
function setJsonInSessionStorage(key: string, value: unknown) {
  setInSessionStorage(key, JSON.stringify(value));
}

/**
 * Remove a value in session storage by key.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * removed.
 *
 * @param {string} key Session storage key.
 */
function removeFromSessionStorage(key: string) {
  sessionStore.removeItem(key);
}

/**
 * Clear session storage.
 *
 * If session storage is natively available then it is used, otherwise a
 * polyfilled store is used.
 *
 * **Note:** All server-side use must originate from the frontend controller in
 * `src/server/controllers/frontend/index.ts` otherwise the storage will not be
 * removed.
 */
function clearSessionStorage() {
  sessionStore.clear();
}

export const session = {
  get: getFromSessionStorage,
  getJson: getJsonFromSessionStorage,
  set: setInSessionStorage,
  setJson: setJsonInSessionStorage,
  remove: removeFromSessionStorage,
  clear: clearSessionStorage,
};

export const local = {
  get: getFromLocalStorage,
  getJson: getJsonFromLocalStorage,
  set: setInLocalStorage,
  setJson: setJsonInLocalStorage,
  remove: removeFromLocalStorage,
  clear: clearLocalStorage,
};
