import { connect } from 'react-redux';

import { getProductConflictById, getTrolleyConflictById } from 'redux/modules/conflicts/selectors';

import ConflictComponent from 'components/Conflict/Conflict';

const Conflict = connect((state, { entityId, entityType }) => {
  let conflict;

  switch (entityType) {
    case 'products': {
      conflict = getProductConflictById(state, entityId);
      break;
    }
    case 'trolley': {
      conflict = getTrolleyConflictById(state, entityId);
      break;
    }
    default:
      break;
  }

  return { conflict };
})(ConflictComponent);

export default Conflict;
