import { apiCallback } from 'api';

const generateSessionDefinition = {
  graph: true,
  query: () => `mutation($session: SessionInput) {
      generateSession(session: $session) {
        accessToken
        customerId
        customerOrderId
        customerOrderState
        defaultBranchId
        expiresIn
        permissions
        principalId
        failures{
          type
          message
        }
      }
    }`,
  queryKey: 'retailSessions',
  variables: ({ password, username, customerId }) => ({
    session: {
      username,
      password,
      customerId,
      clientId: 'WEB_APP',
    },
  }),
  queryString: { tag: 'new-session' },
  verbs: ['post'],
};

const destroySessionDefinition = {
  graph: true,
  query: () => `mutation($session: DeleteSessionInput) {
      deleteSession(session: $session)
    }`,
  variables: ({ signedOnAsCustomer }) => ({
    session: { signedOnAsCustomer },
  }),
  verbs: ['post'],
  queryString: { tag: 'delete-session' },
};

export default {
  generateSession: apiCallback(generateSessionDefinition, 'post'),
  destroySession: apiCallback(destroySessionDefinition, 'post'),
};
