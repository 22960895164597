import { ADD_PURCHASE_ROWS_KEY } from 'constants/monetate';
import {
  BROWSE,
  BUNDLES,
  CUSTOM_AISLE,
  ENTERTAINING,
  FAVOURITES,
  FEATURED,
  INTERSTITIALS,
  MEAL_DEALS,
  MISSED_OFFERS,
  MULTI_SEARCH,
  OFFERS,
  ORDER_CONFIRMATION,
  PRODUCTS,
  SEARCH,
  SHOPPING_LISTS,
  SHOP_FROM_ORDER,
  VIEW_ORDER,
  getPageType,
} from 'utils/get-page-type';
import { monetateQ } from 'utils/monetate-q';
import { unstashObject } from 'utils/stash';

import {
  getMonetateCombinedProducts,
  getMonetateBreadcrumbs,
  getMonetateCartItems,
  getMonetateCombinedFavourites,
  getMonetateCombinedOrderProducts,
  getMonetateBundleProductLineNumbers,
  getMonetateCombinedMealDealLineNumbers,
} from 'redux/modules/monetate/selectors';
import { getMissedOffersItems } from 'redux/modules/missed-offers/selectors';
import { getMonetateCategories } from 'redux/modules/monetate/selectors/get-monetate-categories';
import { getSelectedLineNumber } from 'redux/modules/product-details/selectors';
import getTrolleyLoaded from 'redux/modules/trolley/selectors/get-loaded';
import locator from 'utils/locator';

import { monetateCustomVariables } from './monetate-custom-variables';

const plpPages = [
  BROWSE,
  BUNDLES,
  CUSTOM_AISLE,
  ENTERTAINING,
  FAVOURITES,
  FEATURED,
  INTERSTITIALS,
  MEAL_DEALS,
  MISSED_OFFERS,
  MULTI_SEARCH,
  OFFERS,
  SEARCH,
  SHOP_FROM_ORDER,
  SHOPPING_LISTS,
  VIEW_ORDER,
];

const getProductLineNumbers = (pageType, state) => {
  switch (pageType) {
    case BUNDLES:
      return getMonetateBundleProductLineNumbers(state);
    case MEAL_DEALS:
      return getMonetateCombinedMealDealLineNumbers(state);
    case FAVOURITES:
      return getMonetateCombinedFavourites(state);
    case SHOP_FROM_ORDER:
      return getMonetateCombinedOrderProducts(state);
    case MISSED_OFFERS:
      return getMissedOffersItems(state);
    default:
      return getMonetateCombinedProducts(state);
  }
};

export const createPayload = (state = {}, location) => {
  const props = [];
  const isTrolleyLoaded = getTrolleyLoaded(state);
  const pageType = getPageType(location?.pathname);
  const purchaseRows = pageType === ORDER_CONFIRMATION && unstashObject(ADD_PURCHASE_ROWS_KEY);

  // breadcrumbs
  props.push([
    'addBreadcrumbs',
    getMonetateBreadcrumbs(state, {
      location,
    }),
  ]);

  if (purchaseRows) {
    // purchases - incompatible with addCartRows
    props.push(['addPurchaseRows', purchaseRows]);
  } else if (isTrolleyLoaded) {
    // cart items - incompatible with addPurchaseRows
    props.push(['addCartRows', getMonetateCartItems(state)]);
  }

  // categories
  props.push(['addCategories', getMonetateCategories(state, locator?.pathname)]);
  // custom variables
  props.push(['setCustomVariables', monetateCustomVariables(state)]);

  if (pageType === PRODUCTS) {
    // product details
    props.push(['addProductDetails', [String(getSelectedLineNumber(state))]]);
  }

  if (plpPages.includes(pageType)) {
    // products
    props.push(['addProducts', getProductLineNumbers(pageType, state)]);
  }

  // type
  props.push(['setPageType', pageType]);
  // retrack
  props.push(['trackData']);

  props.forEach(prop => monetateQ.push(prop));
};
