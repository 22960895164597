import { CampaignName, SlotGridType } from 'api/definitions/slotdates/types';
import { SlotCharge, SlotStatus } from 'api/definitions/slotdays/types';
import { deepFreeze } from 'utils/deepFreeze';

// TODO: merge Slot and SlotsGridCell
export type Slot = {
  branchId: string;
  charge: SlotCharge;
  date: string;
  endDateTime: string;
  startDateTime: string;
  shopByDateTime: string;
  slotId: string;
  slotStatus: SlotStatus;
  slotRangeCopy: string;
  slotGridType: SlotGridType;
  greenSlot?: boolean;
};

export type SlotsGridCell = {
  id: string;
  date: string;
  branchId: string;
  time?: string;
  charge?: SlotCharge;
  campaign?: CampaignName;
  slotRangeCopy: string;
  slotGridType: SlotGridType;
  greenSlot?: boolean;
};

export type SlotsGridRow = {
  label: string;
  slots: SlotsGridCell[];
  start: string;
};

export type SlotsGrid = SlotsGridRow[];

export type SlotsGridHeader = {
  id: string;
  header: string;
  ariaLabel: string;
  campaign?: CampaignName;
};

export enum SlotsGreenVariant {
  UNKNOWN = 'UNKNOWN',
  GREEN_SLOT = 'GREEN_SLOT',
  CONTROL = 'CONTROL',
}

export interface SlotsState {
  loading: boolean;
  bookingSlotId: string | null;
  slots: Slot[];
  error: string | null;
  slotsDisabled: boolean;
  greenVariant: SlotsGreenVariant;
}

const initialState: Readonly<SlotsState> = deepFreeze({
  loading: false,
  bookingSlotId: null, // TODO: is is required here? Move to slot-booking
  slots: [],
  error: null,
  slotsDisabled: false,
  greenVariant: SlotsGreenVariant.UNKNOWN,
});

export default initialState;
