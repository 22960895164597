import update from 'immutability-helper';

import {
  FAILED,
  FOOTER_FAILED,
  FOOTER_LOADED,
  FOOTER_LOADING,
  LOADED,
  LOADING,
} from 'redux/modules/taxonomy/actions/types';

import initialState from './initial-state';

// NOTE: The Experince Fragment fetcher already has the base of the path set, so it needs to be stripped off to avoid errors.
const transformMegaMenuBannerString = megaMenuBanner => {
  if (!megaMenuBanner) return megaMenuBanner;
  return megaMenuBanner.replace('/content/experience-fragments/waitrosegroceriescms/', '');
};

const normalizeSubCategoriesToFlatStructure = (category, convertedTaxonomy) => {
  if (category.childCategories?.length) {
    category.childCategories.forEach(subCategory => {
      // eslint-disable-next-line no-param-reassign
      convertedTaxonomy[subCategory.id] = {
        id: subCategory.id,
        emptyOfProducts: subCategory.emptyOfProducts,
        hiddenInNav: subCategory.hiddenInNav,
        name: subCategory.shortName,
        pageTitle: subCategory.longName,
        banner: transformMegaMenuBannerString(subCategory.megaMenuBanner),
        ...(subCategory.childCategories.length && {
          categoryIds: subCategory.childCategories.map(sub => sub.id),
        }),
      };

      normalizeSubCategoriesToFlatStructure(subCategory, convertedTaxonomy);
    });
  }
};

export default function taxonomy(state = initialState(), action = {}) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOADED: {
      const { childCategories } = action.result;
      const convertedTaxonomy = {};

      childCategories?.forEach(category => {
        if (!category.hiddenInNav) {
          convertedTaxonomy[category.id] = {
            id: category.id,
            emptyOfProducts: category.emptyOfProducts,
            hiddenInNav: category.hiddenInNav,
            name: category.shortName,
            pageTitle: category.longName,
            banner: transformMegaMenuBannerString(category.megaMenuBanner),
            ...(category.childCategories.length && {
              categoryIds: category.childCategories.map(subCat => subCat.id),
            }),
          };

          normalizeSubCategoriesToFlatStructure(category, convertedTaxonomy);
        }
      });

      return {
        ...state,
        data: update(state.data, {
          $merge: convertedTaxonomy,
        }),
        depth: action.depth,
        level: `G${action.depth ? action.depth - 1 : 0}`,
        loaded: true,
        loading: false,
        rootCategories: update(state.rootCategories, {
          $set: childCategories.map(el => ({
            id: el.id,
            name: el.shortName,
            parentId: el.id,
            emptyOfProducts: el.emptyOfProducts,
            hiddenInNav: el.hiddenInNav,
          })),
        }),
      };
    }
    case FAILED: {
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
      };
    }
    case FOOTER_LOADING: {
      return {
        ...state,
        footer: {
          ...state.footer,
          loading: true,
        },
      };
    }
    case FOOTER_LOADED: {
      return {
        ...state,
        footer: {
          ...state.footer,
          data: action.result,
          loading: false,
        },
      };
    }
    case FOOTER_FAILED: {
      return {
        ...state,
        footer: {
          ...state.footer,
          data: null,
          errorResponse: action.error,
          loading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
}
