import interpolate from 'utils/interpolate';
import { buildPath } from './build-path';
import { buildApiUrlOfType, buildUrl as buildUrlForApi } from './build-api-url-of-type';

const buildQuery = ({ query = {} }, args = {}) =>
  Object.keys(query).reduce((acc, current) => {
    const interpolation = interpolate(query[current], args);

    if (interpolation) acc[current] = interpolation;

    return acc;
  }, {});

const buildUrl = (api, args) => {
  const path = buildPath(api, args);
  const query = buildQuery(api, args);
  const url = buildUrlForApi(api, path, query);
  const rtn = buildApiUrlOfType(api, url);
  return rtn;
};

export default buildUrl;
