import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const GET = apiPromiseTypes('address', 'get');
export const POST = apiPromiseTypes('address', 'post');
export const GET_BY_ID = apiPromiseTypes('addressbyid', 'get');
export const DELETE_BY_ID = apiPromiseTypes('address', 'delete');
export const OPTIONS_BY_ID = apiPromiseTypes('addressbyid', 'options');
export const PUT_BY_ID = apiPromiseTypes('addressbyid', 'put');
export const SET_CONTACT_ADDRESS = 'waitrose/address/SET_CONTACT_ADDRESS' as const;
export const PATCH_CONTACT_ADDRESS = apiPromiseTypes('accountsProfile', 'patch');
export const SET_SELECTED_ADDRESS = 'waitrose/address/SET_SELECTED_ADDRESS' as const;
export const SET_CHOOSEN_ADDRESS = 'waitrose/address/SET_CHOOSEN_ADDRESS' as const;
export const CLEAR_CHOSEN_ADDRESS = 'waitrose/address/CLEAR_CHOSEN_ADDRESS' as const;
export const PUT_ADDRESSEE = apiPromiseTypes('addressee', 'put');
export const SHOW_ADD_MODAL = 'waitrose/address/SHOW_ADD_MODAL' as const;
export const HIDE_ADD_MODAL = 'waitrose/address/HIDE_ADD_MODAL' as const;
export const CANCEL_ADD_MODAL = 'waitrose/address/CANCEL_ADD_MODAL' as const;
export const SHOW_EDIT_MODAL = 'waitrose/address/SHOW_EDIT_MODAL' as const;
export const HIDE_EDIT_MODAL = 'waitrose/address/HIDE_EDIT_MODAL' as const;
export const CANCEL_EDIT_MODAL = 'waitrose/address/CANCEL_EDIT_MODAL' as const;
export const REPORT_DELETE_SUCCESS = 'waitrose/address/REPORT_DELETE_SUCCESS' as const;
export const REPORT_ADDRESS_PROPERTIES_EDITED =
  'waitrose/address/REPORT_ADDRESS_PROPERTIES_EDITED' as const;
export const SHOW_CHOOSE_ADDRESS_MODAL = 'waitrose/address/SHOW_CHOOSE_ADDRESS_MODAL' as const;
export const HIDE_CHOOSE_ADDRESS_MODAL = 'waitrose/address/HIDE_CHOOSE_ADDRESS_MODAL' as const;
export const CANCEL_CHOOSE_ADDRESS_MODAL = 'waitrose/address/CANCEL_CHOOSE_ADDRESS_MODAL' as const;
export const SET_DISPLAYED_ADDRESS_ID = 'waitrose/address/SET_DISPLAYED_ADDRESS_ID' as const;
export const EXPAND_ADDRESS_BOOK = 'waitrose/address/EXPAND_ADDRESS_BOOK' as const;
export const SHOW_EDIT_DELETE_SPINNER = 'waitrose/address/SHOW_EDIT_DELETE_SPINNER' as const;
export const HIDE_EDIT_DELETE_SPINNER = 'waitrose/address/HIDE_EDIT_DELETE_SPINNER' as const;
export const UPDATE_DELIVERY_NOTE = apiPromiseTypes('addressDeliveryNote', 'patch');
export const ADDRESS_API_ERROR = 'waitrose/address/ADDRESS_API_ERROR' as const;
export const CONTACT_ADDRESS_API_ERROR = 'waitrose/address/CONTACT_ADDRESS_API_ERROR' as const;
export const REPORT_CHANGE_ADDRESS_CLICK = 'waitrose/address/REPORT_CHANGE_ADDRESS_CLICK' as const;
export const RESET_UPDATE_CONTACT_ADDRESS_ERROR =
  'waitrose/mywaitrose/RESET_UPDATE_CONTACT_ADDRESS_ERROR' as const;
