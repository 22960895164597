import { apiCallback } from 'api';

const definition = {
  service: 'taxonomy-entity',
  endpoint: 'taxonomy/waitrose-ecomm-groceries',
  verbs: ['get'],
  defaultVersion: 1,
  memoryCacheKey: 'taxonomy',
  memoryCacheMaxAge: 300000,
};

export default {
  get: apiCallback(definition, 'get'),
};
