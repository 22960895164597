import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import {
  AMEND_COMPLETE,
  CANCEL_AMEND,
  CHANGE_SLOT_AMEND,
  START_AMEND,
} from 'redux/modules/amend-order/actions/types';

import amendComplete from './amend-complete';
import startOrCancelAmendOrderSuccess from './start-or-cancel-amend-order-success';
import startOrCancelAmendOrderRequest from './start-or-cancel-amend-order-request';
import startOrCancelAmendOrderFailure from './start-or-cancel-amend-order-failure';

const initialState = deepFreeze({
  error: null,
  inProgress: false,
  pending: false,
});

const actionTypeReducerMap = new Map([
  [AMEND_COMPLETE, amendComplete],
  [CANCEL_AMEND.request, startOrCancelAmendOrderRequest],
  [CANCEL_AMEND.success, startOrCancelAmendOrderSuccess],
  [CANCEL_AMEND.failure, startOrCancelAmendOrderFailure],
  [CHANGE_SLOT_AMEND.request, startOrCancelAmendOrderRequest],
  [CHANGE_SLOT_AMEND.success, startOrCancelAmendOrderSuccess],
  [CHANGE_SLOT_AMEND.failure, startOrCancelAmendOrderFailure],
  [START_AMEND.request, startOrCancelAmendOrderRequest],
  [START_AMEND.success, startOrCancelAmendOrderSuccess],
  [START_AMEND.failure, startOrCancelAmendOrderFailure],
]);

export default createReducer(initialState, actionTypeReducerMap);
