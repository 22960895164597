import { OPTIMISTICALLY_UPDATE_TROLLEY_ITEM } from 'redux/modules/trolley/actions/types';

import { getItemByLineNumber } from 'redux/modules/trolley/selectors/get-item';
import { formatQuantity } from 'redux/modules/trolley/utils/format-quantity';

export default ({
    batchId,
    lineNumber,
    productId,
    quantity: rawQuantity,
    clickContext,
    processing = false,
    canSubstitute = true,
    noteToShopper,
    personalisedMessage,
    productPosition,
    searchType,
  }) =>
  (dispatch, getState) => {
    const oldTrolleyItem = getItemByLineNumber(getState(), lineNumber);
    const newQuantity = rawQuantity ? formatQuantity(rawQuantity) : oldTrolleyItem?.quantity;
    const newItem = {
      ...oldTrolleyItem,
      batchId,
      canSubstitute: canSubstitute !== undefined ? canSubstitute : oldTrolleyItem?.canSubstitute,
      lineNumber,
      productId: productId || oldTrolleyItem?.productId,
      quantity: newQuantity,
      trolleyItemId: oldTrolleyItem
        ? oldTrolleyItem.trolleyItemId
        : -Number.parseInt(lineNumber, 10),
      analytics: {
        quantity: newQuantity,
        clickContext,
        productPosition,
        searchType,
      },
      personalisedMessage:
        personalisedMessage !== undefined
          ? personalisedMessage
          : oldTrolleyItem?.personalisedMessage,
      processing,
      noteToShopper: noteToShopper !== undefined ? noteToShopper : oldTrolleyItem?.noteToShopper,
    };

    dispatch({
      trolleyItem: newItem,
      type: OPTIMISTICALLY_UPDATE_TROLLEY_ITEM,
    });
  };
