import React from 'react';
import PropTypes from 'prop-types';

import PopOver from 'components/PopOver/PopOver';

import styles from './MyWaitrose.scss';

const MyWaitrosePopover = ({ promotion }) => {
  if (!promotion?.myWaitrosePromotion) return null;

  return (
    <div className={styles.myWaitrose}>
      <PopOver promotion={promotion} />
    </div>
  );
};

MyWaitrosePopover.displayName = 'MyWaitrosePopover';

MyWaitrosePopover.propTypes = {
  promotion: PropTypes.shape({
    myWaitrosePromotion: PropTypes.bool,
  }),
};

MyWaitrosePopover.defaultProps = {
  promotion: null,
};

export default MyWaitrosePopover;
