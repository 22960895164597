import { connect } from 'react-redux';

import { isSignedOnAsCustomer } from 'redux/modules/sessions/selectors';
import { returnToAdmin } from 'redux/modules/returnToAdmin/actions';

import ReturnToAdmin from './ReturnToAdmin';

const mapStateToProps = state => ({
  isSignedOnAsCustomer: isSignedOnAsCustomer(state),
});

const mapDispatchToProps = {
  onClick: returnToAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnToAdmin);
