import { deepFreeze } from 'utils/deepFreeze';

const initialState = deepFreeze({
  addAllToBasket: false,
  cmsMenus: null,
  locations: {},
  locationsLoaded: false,
});

export default initialState;
