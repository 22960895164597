/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { KEY_DOWN, KEY_UP, KEY_ENTER, KEY_DELETE, KEY_TAB } from 'constants/keys';
import { refType } from 'constants/types/ref';

import {
  deleteSearchHistory,
  getSavedSearchTerms,
  removeSavedSearchTerm,
} from 'components/Search/SearchForm/save-search-term';
import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import { Bin } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { dataLayer } from 'analytics/data-layer';

import styles from 'components/Search/SearchHistory/SearchHistory.scss';

const NUMBER_OF_TERMS_TO_SHOW = 6;

class SearchHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerms: [],
      selectedIndex: null,
    };
  }

  componentDidMount() {
    const { inputRef, searchType } = this.props;

    this.setState({
      searchTerms: getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType),
    });

    if (inputRef && inputRef.current && inputRef.current.addEventListener) {
      inputRef.current.addEventListener('keydown', this.handleTermKeyDown);
    }
  }

  componentWillUnmount() {
    const { inputRef } = this.props;
    if (inputRef && inputRef.current && inputRef.current.removeEventListener) {
      inputRef.current.removeEventListener('keydown', this.handleTermKeyDown);
    }
  }

  handleTermClick = term => {
    const { onSelectTerm } = this.props;
    onSelectTerm(term);

    dataLayer.push({
      event: 'search_history_item_click',
      eventAction: 'SearchType:SearchHistory',
      eventCategory: 'Search',
      eventLabel: `SearchTerm:${term}`,
      eventValue: undefined,
    });
  };

  handleTermKeyDown = event => {
    const { searchTerms, selectedIndex } = this.state;
    const { onSelectedChange } = this.props;

    switch (event.keyCode) {
      case KEY_DOWN: {
        if (selectedIndex === null) {
          this.setState({ selectedIndex: 0 });
          onSelectedChange(0);
          break;
        }

        const newSelectedIndex = selectedIndex + 1;
        if (newSelectedIndex < searchTerms.length) {
          this.setState({ selectedIndex: newSelectedIndex });
          onSelectedChange(newSelectedIndex);
        }
        break;
      }
      case KEY_UP: {
        const newSelectedIndex = selectedIndex - 1;
        if (newSelectedIndex >= 0) {
          this.setState({ selectedIndex: newSelectedIndex });
          onSelectedChange(newSelectedIndex);
        } else {
          this.setState({ selectedIndex: null });
          onSelectedChange(null);
        }
        break;
      }
      case KEY_ENTER: {
        if (selectedIndex !== null) {
          this.handleTermClick(searchTerms[selectedIndex]);
        }
        break;
      }
      case KEY_TAB: {
        this.setState({ searchTerms: [] });
        break;
      }
      case KEY_DELETE: {
        if (selectedIndex !== null) {
          this.handleTermRemove(event, searchTerms[selectedIndex]);
        }
        break;
      }
      default:
    }
  };

  handleTermRemove = (event, term) => {
    const { searchType } = this.props;

    event.stopPropagation();
    removeSavedSearchTerm(term, searchType);

    this.setState({
      searchTerms: getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType),
    });

    dataLayer.push({
      event: 'search_history_item_remove',
      eventAction: 'SearchType:RemoveSearchHistoryTerm',
      eventCategory: 'Search',
      eventLabel: `SearchTerm:${term}`,
      eventValue: undefined,
    });
  };

  handleClearAllClick = () => {
    const { searchType } = this.props;

    deleteSearchHistory(searchType);

    this.setState({
      searchTerms: getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType),
    });

    dataLayer.push({
      event: 'search_history_clear_all',
      eventAction: 'SearchType:SearchHistoryReset',
      eventCategory: 'Search',
      eventLabel: `SearchHistoryReset`,
      eventValue: undefined,
    });
  };

  render() {
    const { searchTerms = [], selectedIndex } = this.state;
    const { homepage, className } = this.props;

    if (searchTerms.length) {
      return (
        <div
          className={classNames(styles.searchHistoryContainer, {
            [styles.homepage]: homepage,
            [className]: !!className,
          })}
        >
          <div className={styles.label} id="search-history-label">
            <span>Your recent searches</span>
            <button
              className={styles.clearAll}
              data-testid="search-history-clear-all"
              onClick={this.handleClearAllClick}
              type="button"
            >
              Clear all
            </button>
          </div>
          <ul aria-labelledby="search-history-label" id="search-history" role="listbox">
            {searchTerms.map((term, index) => (
              <li
                aria-selected={index === selectedIndex}
                className={classNames({ [styles.selected]: index === selectedIndex })}
                data-testid="search-history-term"
                id="search-history-item"
                key={term}
                onClick={() => this.handleTermClick(term)}
                role="option"
              >
                <span className={styles.searchTerm}>{term}</span>

                <button
                  className={styles.removeButton}
                  data-testid="search-history-term-remove"
                  onClick={event => this.handleTermRemove(event, term)}
                  type="button"
                >
                  <Bin size="medium" />
                </button>
              </li>
            ))}
          </ul>
          <ScreenReaderAnnouncement
            polite
            message={`You have: ${searchTerms.length} previous search terms found.`}
          />
        </div>
      );
    }
    return null;
  }
}

SearchHistory.displayName = 'SearchHistory';

SearchHistory.propTypes = {
  onSelectedChange: PropTypes.func,
  onSelectTerm: PropTypes.func,
  homepage: PropTypes.bool,
  className: PropTypes.string,
  inputRef: refType,
  searchType: PropTypes.string,
};

SearchHistory.defaultProps = {
  onSelectedChange: () => {},
  onSelectTerm: () => {},
  homepage: false,
  className: null,
  inputRef: null,
  searchType: null,
};

export default SearchHistory;
