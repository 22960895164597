const mockAddress = {
  line1: 'line 1',
  line2: 'line 2',
  line3: 'line 3',
  town: 'A town',
  region: 'A region',
  country: 'GB',
  postalCode: 'PO12 12H',
  isContactAddress: false,
};

const mockAddressee = {
  title: 'Dr',
  firstName: 'Emmett',
  lastName: 'Brown',
  contactNumber: '0118 999 881 999 119 725 3',
};

const mockList = [
  {
    ...mockAddress,
    addressee: mockAddressee,
    id: '1',
  },
  {
    ...mockAddress,
    addressee: mockAddressee,
    id: '2',
  },
  {
    ...mockAddress,
    addressee: mockAddressee,
    id: '3',
  },
];

const buildAddressNameScenarios = [
  {
    title: 'Dr',
    firstName: 'Emmett',
    lastName: 'Brown',
    expected: 'Dr Emmett Brown',
  },
  {
    title: '',
    firstName: 'Emmett',
    lastName: 'Brown',
    expected: 'Emmett Brown',
  },
  {
    title: null,
    firstName: 'Emmett',
    lastName: 'Brown',
    expected: 'Emmett Brown',
  },
  {
    title: undefined,
    firstName: 'Emmett',
    lastName: 'Brown',
    expected: 'Emmett Brown',
  },
  {
    title: 'dr',
    firstName: 'emmett',
    lastName: 'brown',
    expected: 'Dr Emmett Brown',
  },
  {
    noData: true,
    expected: null,
  },
];

const contactNumberScenarios = [
  {
    describe: 'Address with an addressee and contact number displays contact number section',
    addressee: mockAddressee,
    happy: true,
    number: '0118 999 881 999 119 725 3',
  },
  {
    describe:
      'Address with and addressee but without a contact number does not display contact number section',
    addressee: { firstName: 'foo', lastName: 'bar' },
  },
  {
    describe: 'Address without an addressee does not display contact number section',
    addressee: {},
  },
];

const missingAddressFieldScenarios = [
  {
    address: {
      line1: 'line 1',
      line3: 'line 3',
      town: 'A town',
      region: 'A region',
      country: 'GB',
      postalCode: 'PO12 12H',
    },
    expected: {
      line1: 'line 1',
      line3: 'line 3',
      town: 'A town',
      region: 'A region',
      country: 'United Kingdom',
      postalCode: 'PO12 12H',
    },
    missing: ['line2'],
    describe: 'line2 is not rendered',
  },
  {
    address: {
      line1: 'line 1',
      line2: 'line 2',
      town: 'A town',
      region: 'A region',
      country: 'GB',
      postalCode: 'PO12 12H',
    },
    expected: {
      line1: 'line 1',
      line2: 'line 2',
      town: 'A town',
      region: 'A region',
      country: 'United Kingdom',
      postalCode: 'PO12 12H',
    },
    missing: ['line3'],
    describe: 'line3 is not rendered',
  },
  {
    address: {
      line1: 'line 1',
      line2: 'line 2',
      line3: 'line 3',
      region: 'A region',
      country: 'GB',
      postalCode: 'PO12 12H',
    },
    expected: {
      line1: 'line 1',
      line2: 'line 2',
      line3: 'line 3',
      region: 'A region',
      country: 'United Kingdom',
      postalCode: 'PO12 12H',
    },
    missing: ['town'],
    describe: 'town is not rendered',
  },
  {
    address: {
      line1: 'line 1',
      line2: 'line 2',
      line3: 'line 3',
      town: 'A town',
      country: 'GB',
      postalCode: 'PO12 12H',
    },
    expected: {
      line1: 'line 1',
      line2: 'line 2',
      line3: 'line 3',
      town: 'A town',
      country: 'United Kingdom',
      postalCode: 'PO12 12H',
    },
    missing: ['region'],
    describe: 'region is not rendered',
  },
  {
    address: {
      line1: 'line 1',
      country: 'GB',
      postalCode: 'PO12 12H',
    },
    expected: {
      line1: 'line 1',
      country: 'United Kingdom',
      postalCode: 'PO12 12H',
    },
    missing: ['line2', 'line3', 'town', 'region'],
    describe: 'only required fields are rendered',
  },
];

export {
  mockList,
  buildAddressNameScenarios,
  mockAddress,
  mockAddressee,
  contactNumberScenarios,
  missingAddressFieldScenarios,
};
