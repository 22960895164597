import { getFormSyncErrors } from 'redux-form';

function flatten(obj, value) {
  if (obj) {
    const props = Object.getOwnPropertyNames(obj);

    if (props.some(item => item === 'syncErrors')) {
      return value;
    }

    if (props && props.length > 0 && value.split('.').length < 4) {
      const mapped = props.map(p => flatten(obj[p], `${value === '' ? `${p}` : `${value}.${p}`}`));
      return mapped.reduce((acc, val) => acc.concat(val), []);
    }
  }

  return [];
}

const formValidationErrors = state => {
  const { form = {} } = state;
  const formsInError = flatten(form, '');
  const errors = getFormSyncErrors(formsInError[0])(state);
  const validationErrors = Object.keys(errors);
  if (validationErrors && validationErrors.length > 0) {
    return {
      event: 'form_validation_error',
      form: formsInError[0] || '',
      validationErrors,
    };
  }
  return { event: 'form_error' };
};

export { formValidationErrors };
